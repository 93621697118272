<template>
  <div class="recipiente" v-if="showModal && showModal.show">
    <IncluirAtivo
      class="conteudo"
      :showModal="showModal"
      :items="items"
      :botaoCompraClicado="botaoCompraClicado"
      :currentPropertie="currentPropertie"
      @closeModal="closeModal"
      :respGetAtiTipUserId_prop="respGetAtiTipUserId"
      @onChange="onChange"
      @execCompraAtivo="execCompraAtivo"
      @opCtrlState="opCtrlChanged"

      v-if="showModal && showModal.view == compra"
    />

    <ExcluirAtivo
      class="conteudo"
      :showModal="showModal"
      :items="items"
      :currentPropertie="currentPropertie"
      @closeModal="closeModal"
      :respGetAtiTipUserId_prop="respGetAtiTipUserId"
      @onChange="onChange"
      @execVenda="execVenda"
      @deleteCompraAtivo="deleteCompraAtivo"
      v-if="showModal && showModal.view == venda"
    />
    <EditarEstrategia class="conteudo"
                      :showModal="showModal"
                      :tipos="items"
                      :ativos="loadedItems"
                      @closeModal="closeModalNovoUsuario"
                      @onChange="onChange"
                      v-if="showModal && showModal.view === estrategia" />
    <!-- ACESSSOR -->
    <NovoUsuarioComponent
      class="conteudo"
      v-if="showModal && showModal.view == novoUser"
      :currentPropertie="currentPropertie"
      :loading="loading"
      :botaoRegistrarUsuarioClicado="botaoRegistrarUsuarioClicado"
      @closeModal="closeModal"
      @execRegisterUser="execRegisterUser"
    />

    <EditarUsuarioComponent
      class="conteudo"
      v-if="showModal && showModal.view == editarUser"
      :botaoSalvarAlteracoesUsuarioClicado="botaoSalvarAlteracoesUsuarioClicado"
      :loading="loading"
      :currentPropertie="currentPropertie"
      :userToEditar="userToEditar"
      @closeModal="closeModal"
      @execUpdateUser="execUpdateUser"
    />

    <!-- ACESSSOR -->
    <div class="conteudo" v-if="showModal && showModal.view === compraAcessor">
      <div class="title">
        <span>Incluir <span style="color: #b5e546">Ativo</span></span>
      </div>
      <div class="tipo-ativo">
        <v-row class="flex-column mt-7" align="start">
          <v-col class="d-flex flex-column align-left py-0">
            <span class="text-filelds">Carteira</span>
          </v-col>
          <v-col>
            <v-select
              attach
              @change="onChange($event)"
              return-object
              :items="items"
              item-text="name"
              :menu-props="{ bottom: true, offsetY: true }"
              placeholder="Selecione a carteira/usuario"
              color="secondary"
              required
              outlined
              rounded
              dense
              class="input"
            ></v-select>
          </v-col>
        </v-row>
      </div>

      <keep-alive>
        <component
          :is="showModal.view"
          :respGetAtiTipUserId_prop="respGetAtiTipUserId"
          v-bind:tipoAtivo="currentPropertie"
          v-bind:ativoNovoOuExistente="ativoNovoOuExistente"
        ></component>
      </keep-alive>

      <div class="btn-comprar" v-if="!objetoEhVazio(currentPropertie)">
        <span @click="exec">Incluir</span>
      </div>
    </div>

    <div class="conteudo" v-if="showModal && showModal.view === vendaAcessor">
      <div class="title">
        <span>Excluir <span style="color: #f25a5a">Ativo</span></span>
      </div>

      <div class="tipo-ativo">
        <v-row class="flex-column mt-7" align="start">
          <v-col class="d-flex flex-column align-left py-0">
            <span class="text-filelds">Carteira</span>
          </v-col>
          <v-col>
            <v-select
              @change="onChangeAcessor($event)"
              :items="userList"
              item-text="userName"
              item-value="id"
              :menu-props="{ bottom: true, offsetY: true }"
              placeholder="Selecione a carteira/usuario"
              color="secondary"
              required
              outlined
              rounded
              dense
              class="input"
            ></v-select>
          </v-col>
        </v-row>
      </div>

      <div class="btn-close" @click="closeModal">
        <v-img src="@/assets/icons/clear_icon.svg" />
      </div>

      <div class="tipo-ativo" v-if="userSelected">
        <v-row class="flex-column mt-7" align="start">
          <v-col class="d-flex flex-column align-left py-0">
            <span class="text-filelds">Tipo de ativo 2</span>
          </v-col>
          <v-col>
            <v-select
              @change="onChange($event)"
              :items="items"
              item-text="name"
              :menu-props="{ bottom: true, offsetY: true }"
              placeholder="Selecione"
              color="secondary"
              required
              outlined
              rounded
              dense
              class="input"

            ></v-select>
          </v-col>
        </v-row>
      </div>

      <keep-alive>
        <component
          :is="showModal.view"
          v-bind:tipoAtivo="currentPropertie"
          v-bind:botaoCompraClicado="botaoCompraClicado"
          v-bind:userToEnviarParaAtivos="userToEnviarParaAtivos"
          v-bind:ativoNovoOuExistente="ativoNovoOuExistente"
        ></component>
      </keep-alive>

      <div class="btn-venda" v-if="!objetoEhVazio(currentPropertie)">
        <span @click="execVenda">Confirmar venda</span>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import EventBus from "../../Bus/bus";

import IncluirAtivo from "./actions/IncluirAtivo.vue";
import ExcluirAtivo from "./actions/ExcluirAtivo.vue";
import NovoUsuarioComponent from "./actions/NovoUsuario.vue";
import EditarUsuarioComponent from "./actions/EditarUsuario.vue";
import EditarEstrategia from "./actions/EditarEstrategia.vue";

//Acessor
import CadastrarCompraAcessor from "./components/Acessor/CadastrarCompraAcessor.vue";
import CadastrarVendaAcessor from "./components/Acessor/CadastrarVendaAcessor.vue";
import ApiService from "../../../services/ApiService";

export default {
  name: "Recipiente",
  components: {
    IncluirAtivo,
    ExcluirAtivo,
    NovoUsuarioComponent,
    EditarUsuarioComponent,
    EditarEstrategia,
    CadastrarCompraAcessor,
    CadastrarVendaAcessor
},

  data() {
    return {
      api: new ApiService(),
      respGetAtiTipUserId:[],
      botaoCompraClicado: false,
      botaoVendaClicado: false,
      botaoRegistrarUsuarioClicado: false,
      botaoSalvarAlteracoesUsuarioClicado: false,
      state: 0,
      ativoNovoOuExistente: true,
      items: [
        { header: "Renda Fixa" },
        { name: "CDB/RDB", value: "CdbRdb", id: 1 },
        { name: "CRA", value: "Cra", id: 10 },
        { name: "CRI", value: "Cri", id: 2 },
        { name: "Debêntures", value: "Debentures", id: 3 },
        { name: "LCI/LCA", value: "LciLca", id: 4 },
        { name: "Títulos Públicos", value: "TitulosPublicos", id: 5 },
        { header: "Renda Variavel" },
        { name: "Ações", value: "Acoes", id: 6 },
        { name: "Fundos Imobiliários", value: "FundosImobiliarios", id: 7 },
        { header: "Fundos de Investimento" },
        { name: "Fundos de Investimento", value: "FundosInvestimento", id: 8, tipo: '!FIPREV' },
        { header: "Previdência" },
        { name: "Previdência", value: "Previdencia", id: 8, tipo: 'FIPREV' },
        { header: "Outros" },
        { name: "Outros", value: "Outros", id: 13 }
      ],
      itemsCarteira: [],
      userList: [],
      currentPropertie: {},
      novoUsuario: undefined,
      userToEditar: undefined,
      userSelected: false,
      userToEnviarParaAtivos: {},
      loading: false,
      ativos: [],
      compra: "CadastrarCompra",
      venda: "CadastrarVenda",
      estrategia: "EditarEstrategia",
      novoUser: "novoUsuario",
      editarUser: "editarUsuario",
      compraAcessor: "CadastrarCompraAcessor",
      vendaAcessor: "CadastrarVendaAcessor",
      ctrlState: undefined
    };
  },
  created() {
    /*try {
    var t =this.api.getEntity(1);
    console.log('teste:'+ JSON.stringify(t) )
     }
        catch (error) {
        this.$toast.error("Error ao getEntity");
      }*/

    this.api.getListEntity(this.$store,null);
    EventBus.$on("closeModalOnCreateUser", this.ebClean);
    EventBus.$on("ListaDeUsuarios", this.ebList);
    EventBus.$on("zerarModalCompraAcessor", this.ebClear);
  },
  beforeDestroy () {
    EventBus.$off("closeModalOnCreateUser", this.ebClean);
    EventBus.$off("ListaDeUsuarios", this.ebList);
    EventBus.$off("zerarModalCompraAcessor", this.ebClear);
  },
  computed : {
    loadedItems () {
      return this.respGetAtiTipUserId.length ?
        this.respGetAtiTipUserId[0].usersActive :
        []
    }
  },
  props: {
    showModal: { type: Object },
    userToEdit: { type: Object },
  },

  methods: {
    opCtrlChanged (state) {
      this.ctrlState = state;
    },
    ebClean (data) {
      if (!data) { return; }
      this.closeModal();
    },
    ebList  (data) {
      this.userList = data;
    },
    ebClear (data) {
      if (!data) { return; }
      this.userSelected = false;
    },
    closeModal() {
      //let valor = !this.showModal?.show
      let value = { valor: !this.showModal?.show, estado: this.state++ };
      this.userSelected = false;
      this.ativoNovoOuExistente = true;
      this.$emit("closeModal", value);
    },
    closeModalNovoUsuario() {
      let valor = !this.showModal.show;
      let value = { valor: valor, estado: this.state++ };
      this.$emit("closeModal", value);
    },
    onChange(e) {
      this.loading = true;
      this.api.getUsersActive(this.$store,e.id, e.tipo)
        .then((resp) => {
          this.respGetAtiTipUserId=[{usersActive:resp}] ;
        })
        .catch ((error)=> {
          this.botaoCompraClicado = false;
          this.$toast.error(error.Error);
        });
      this.currentPropertie = { value: e };
    },
    onImported (e) {
      this.loading = true;
      this.api.getUsersActive(this.$store,e.id)
        .then((resp) => {
          this.respGetAtiTipUserId=[{usersActive:resp}] ;
        })
        .catch ((error)=> {
          this.botaoCompraClicado = false;
          this.$toast.error(error.Error);
        });
    },
    onChangeAcessor(e) {
      let user = this.userList.filter((user) => {
        return user.id == e;
      });
      if (user) {
        this.userSelected = true;
      } else {
        this.userSelected = false;
      }
      this.userToEnviarParaAtivos = {};
      this.userToEnviarParaAtivos = user[0];
    },
    async deleteCompraAtivo(dadosDoAtivo) {
      try {
        const aid = dadosDoAtivo.ativoId;
        const usr = this.$store.getters.$tokenUserSelected;
        const req = await this.api.delete(`userToAtivo/${aid}/${usr}`);
              this.botaoCompraClicado = false;

        req.statusCode == 200
          ? this.$toast.success("Ativo excluido na carteira")
          : "";
        this.$store.commit("SET_USERTOATIVOUSERProduto", null);
        this.$store.commit("SET_USERTOATIVOUSEREstrategia", null);
        this.$store.commit("SET_USERTOATIVOUSERRentabilidade", null);
        this.$store.commit("SET_USERTOATIVOUSERLiquidez", null);

        EventBus.$emit("closeModalOnCreateUser", true);
        EventBus.$emit("reloadPagesForCallActivesAgain", true);
      }
      catch (error) {
        this.botaoCompraClicado = false;
        this.$toast.error("Erro ao Excluir ativo");
      }
    },
    async execCompraAtivo(dados) {
      //debugger
      var dadosDoAtivo=dados.dado || dados;
      if (this.ctrlState === "resgate" && dadosDoAtivo.TpMovimento !== 8) {
        dadosDoAtivo.TpMovimento = 8;
      }
      var tipoAtivo=dados.tipoAtivo || 0;
      var _msg=dados.msg;

      this.$emit("btnCompraClicado", true);
      this.botaoCompraClicado = true;
      if (tipoAtivo === 1 || tipoAtivo === 4 || tipoAtivo === 13) {
        if (dadosDoAtivo.TpMovimento == 1) {
          if (dadosDoAtivo.InvestedValue == undefined || dadosDoAtivo.InvestedValue == 0 || dadosDoAtivo.InvestedValue.length == 0) {
            _msg = (_msg ? _msg + "\n" : "") + "* Verifique o valor da aplicação";
          }
          if (dadosDoAtivo.investmentDate == undefined || dadosDoAtivo.investmentDate.length == 0) {
            _msg = (_msg ? _msg + "\n" : "") + "* Verifique a data da aplicação";
          }
          if (dadosDoAtivo.ativo == undefined) {
            _msg = (_msg ? _msg + "\n" : "") + "* Verifique as informações lançadas";
          } else {
            if (dadosDoAtivo.ativo.codigo == undefined || dadosDoAtivo.ativo.codigo.length < 4 || dadosDoAtivo.ativo.codigo.length > 19) {
              _msg = (_msg ? _msg + "\n" : "") + "*Verifique o nome do ativo";
            }
            if (dadosDoAtivo.ativo.descricao == undefined || dadosDoAtivo.ativo.descricao.length < 4 || dadosDoAtivo.ativo.descricao.length > 100) {
              _msg = (_msg ? _msg + "\n" : "") + "* Verifique a descrição do ativo";
            }
            if (dadosDoAtivo.ativo.idEntidade == undefined || dadosDoAtivo.ativo.idEntidade == 0) {
              _msg = (_msg ? _msg + "\n" : "") + "* Verifique o Emissor (Risco de crédito)";
            }
            if (dadosDoAtivo.ativo.idEstrategia == undefined || dadosDoAtivo.ativo.idEstrategia == 0) {
              _msg = (_msg ? _msg + "\n" : "") + "* Verifique a Estratégia ";
            } else {
              if ((tipoAtivo === 1 || tipoAtivo === 13) && dadosDoAtivo.ativo.idEstrategia !== 2 && dadosDoAtivo.ativo.cdbRdb !== undefined && (dadosDoAtivo.ativo.cdbRdb.taxa === undefined || dadosDoAtivo.ativo.cdbRdb.taxa === 0 || dadosDoAtivo.ativo.cdbRdb.taxa.length === 0)) {
                _msg = (_msg ? _msg + "\n" : "") + "* Verifique Taxa";
              }
              if ((tipoAtivo === 1 || tipoAtivo === 13) && dadosDoAtivo.ativo.idEstrategia === 2 && dadosDoAtivo.ativo.cdbRdb !== undefined && (dadosDoAtivo.ativo.cdbRdb.percentualIndexador === undefined || dadosDoAtivo.ativo.cdbRdb.percentualIndexador === 0 || dadosDoAtivo.ativo.cdbRdb.percentualIndexador.length === 0)) {
                _msg = (_msg ? _msg + "\n" : "") + "* Percentual do Indexador";
              }
              if (tipoAtivo === 2 && dadosDoAtivo.ativo.idEstrategia !== 2 && dadosDoAtivo.ativo.lciLca !== undefined && (dadosDoAtivo.ativo.lciLca.taxa === undefined || dadosDoAtivo.ativo.lciLca.taxa === 0 || dadosDoAtivo.ativo.lciLca.taxa.length === 0)) {
                _msg = (_msg ? _msg + "\n" : "") + "* Verifique Taxa";
              }
              if (tipoAtivo === 2 && dadosDoAtivo.ativo.idEstrategia === 2 && dadosDoAtivo.ativo.lciLca !== undefined && (dadosDoAtivo.ativo.lciLca.percentualIndexador === undefined || dadosDoAtivo.ativo.lciLca.percentualIndexador === 0 || dadosDoAtivo.ativo.lciLca.percentualIndexador.length === 0)) {
                _msg = (_msg ? _msg + "\n" : "") + "* Percentual do Indexador";
              }
            }
            if (dadosDoAtivo.ativo.idIndexador == undefined || dadosDoAtivo.ativo.idIndexador == 0) {
              _msg = (_msg ? _msg + "\n" : "") + "* Verifique o Indexador";
            }
            if (dadosDoAtivo.ativo.fgc == undefined || dadosDoAtivo.ativo.fgc == 0) {
              _msg = (_msg ? _msg + "\n" : "") + "* Verifique FGC";
            }
            if (dadosDoAtivo.ativo.liquidez == undefined) {
              _msg = (_msg ? _msg + "\n" : "") + "* Verifique Liquidez";
            }
            if ((tipoAtivo === 1 || tipoAtivo === 13) && dadosDoAtivo.ativo.cdbRdb !== undefined && (dadosDoAtivo.ativo.cdbRdb.vencimento === undefined || dadosDoAtivo.ativo.cdbRdb.vencimento.length === 0)) {
              _msg = (_msg ? _msg + "\n" : "") + "* Verifique data vencimento";
            }
            if (tipoAtivo === 4 && dadosDoAtivo.ativo.lciLca && (!dadosDoAtivo.ativo.lciLca.vencimento)) {
              _msg = (_msg ? _msg + "\n" : "") + "* Data Vencimento está vazia";
            }
          }
        } else if (dadosDoAtivo.TpMovimento === 3 || dadosDoAtivo.TpMovimento === 8) {
          if (dadosDoAtivo.ativoId == undefined) {
            _msg = (_msg ? _msg + "\n" : "") + "* Ativo não selecionado";
          } else {
            if (dadosDoAtivo.TpMovimento === 3) {
              if (dadosDoAtivo.InvestedValue == undefined ||
                  dadosDoAtivo.InvestedValue == 0 ||
                  dadosDoAtivo.InvestedValue.length == 0) {
                _msg = (_msg ? _msg + "\n" : "") + "* Verifique o valor da aplicação";
              }
            }
            if (dadosDoAtivo.investmentDate == undefined || dadosDoAtivo.investmentDate.length == 0) {
              _msg = (_msg ? _msg + "\n" : "") + "* Verifique a data da aplicação";
            }
          }
        }
      } else if (dadosDoAtivo.ativoId == undefined) {
        _msg = (_msg ? _msg + "\n" : "") + "* Ativo não selecionado";
      } else {
        if (dadosDoAtivo.investmentDate == undefined || dadosDoAtivo.investmentDate.length == 0) {
          _msg = (_msg ? _msg + "\n" : "") + "* Verifique a data da aplicação";
        }
        if (dadosDoAtivo.TpMovimento !== 8) {
          if (dadosDoAtivo.InvestedValue == undefined || dadosDoAtivo.InvestedValue == 0 || dadosDoAtivo.InvestedValue.length == 0) {
            _msg = (_msg ? _msg + "\n" : "") + "* Verifique o total";
          }
          if (dadosDoAtivo.ShareQuantity == undefined || dadosDoAtivo.ShareQuantity == 0 || dadosDoAtivo.ShareQuantity.length == 0) {
            _msg = (_msg ? _msg + "\n" : "") + "* Verifique a quantidade";
          }
          if (dadosDoAtivo.ShareValue == undefined || dadosDoAtivo.ShareValue == 0 || dadosDoAtivo.ShareValue.length == 0) {
            _msg = (_msg ? _msg + "\n" : "") + "* Verifique o valor ";
          }
        }
      }

      if (_msg !=undefined){
          this.$toast.error(_msg);
          this.botaoCompraClicado = false;
          this.$emit("btnCompraClicado", false);
      }
      if(dadosDoAtivo !=undefined && _msg ==undefined){
        try {
          dadosDoAtivo.userId = this.$store.getters.$tokenUserSelected;
          let req;
          if (dadosDoAtivo.ativoId == null || dadosDoAtivo.ativoId == 0) {
            req = await this.api.post("UserToAtivo/newAtivo", dadosDoAtivo);
          }else {
            req = await this.api.post("UserToAtivo", dadosDoAtivo);
          }
          this.botaoCompraClicado = false;

          req.statusCode == 200
            ? this.$toast.success("Ativo incluso na carteira")
            : "";
            this.$store.commit("SET_USERTOATIVOUSERProduto", null);
            this.$store.commit("SET_USERTOATIVOUSEREstrategia", null);
            this.$store.commit("SET_USERTOATIVOUSERRentabilidade", null);
            this.$store.commit("SET_USERTOATIVOUSERLiquidez", null);

          EventBus.$emit("closeModalOnCreateUser", true);
          EventBus.$emit("reloadPagesForCallActivesAgain", true);
        } catch (error) {
          this.botaoCompraClicado = false;
          this.$toast.error(error.body.message || error.body.title || "Error ao incluir ativo");
        }
      }else{
          this.botaoCompraClicado = false;
          this.$emit("btnCompraClicado", false);
      }
    },
    execVenda() {

      this.$emit("btnVendaClicado", true);
      this.botaoVendaClicado = true;
      setTimeout(() => {
        this.botaoVendaClicado = false;
      }, 1000);
    },
    execRegisterUser() {
      this.loading = true;
      this.$emit("btnRegistrarUsuario", true);
      this.botaoRegistrarUsuarioClicado = true;
      setTimeout(() => {
        this.botaoRegistrarUsuarioClicado = false;
        this.loading = false;
      }, 1000);
    },
    execUpdateUser() {
      this.loading = true;
      this.$emit("btnUpdateUsuario", true);
      this.botaoSalvarAlteracoesUsuarioClicado = true;
      setTimeout(() => {
        this.botaoSalvarAlteracoesUsuarioClicado = false;
        this.loading = false;
      }, 1000);
    },
    objetoEhVazio(e) {
      return Object.keys(e).length === 0;
    },
    novoOuExistente() {
      this.ativoNovoOuExistente == true
        ? (this.ativoNovoOuExistente = false)
        : (this.ativoNovoOuExistente = true);
      // console.log('se true, ativo é novo se não é existente, mandar essa info para o tipo de compra de ativo que ele vai querer', this.ativoNovoOuExistente)
    },
  },
  watch: {
    showModal(nv) {
      if (nv != this.currentPropertie) {
        this.currentPropertie = {};
      }
    },
    userToEdit(nv) {
      this.userToEditar = nv;
    },
  },
};
</script>

<style scoped>
.recipiente {
  position: fixed;
  right: 0;
  bottom: 0;
  top: 70px;
  width: 497px;
  background: #ffffff;
  box-shadow: -4px 0px 10px rgba(0, 0, 0, 0.05);
  z-index: 10;
  overflow-y: auto;
}
.recipiente::-webkit-scrollbar {
  width: 10px;
  border-radius: 20px;
}
.recipiente::-webkit-scrollbar-track {
  border-radius: 20px;
  background: #f1f1f1;
}

/* Handle */
.recipiente::-webkit-scrollbar-thumb {
  background: #9f9f9f;
  border-radius: 20px;
}
.conteudo {
  position: relative;
  height: 100%;
  padding: 1.5rem;
}
.btn-cadastrar-usuario {
  position: fixed;
  width: 100%;
  max-width: 497px;
  right: 0;
  bottom: 0;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  -webkit-box-shadow: 3px 2px 17px -1px rgba(0, 0, 0, 0.3);
  box-shadow: 3px 2px 17px -1px rgba(0, 0, 0, 0.3);
  background: white;
}
.btn-cadastrar-usuario span {
  border-radius: 20px;
  padding: 0.5rem 1rem;
  color: white;
  background: var(--principal-azul);
  cursor: pointer;
}
.btn-cadastrar-usuario span:hover {
  background: #6fa9f5;
}
.btn-cadastrar-usuario .v-btn {
  background: var(--principal-azul);
  border: 1px solid red;
}
.btn-comprar {
  position: fixed;
  width: 100%;
  max-width: 497px;
  right: 0;
  bottom: 0;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  -webkit-box-shadow: 3px 2px 17px -1px rgba(0, 0, 0, 0.3);
  box-shadow: 3px 2px 17px -1px rgba(0, 0, 0, 0.3);
  background: white;
}
.btn-comprar span {
  border-radius: 20px;
  padding: 0.5rem 1rem;
  color: white;
  background: rgb(141, 211, 37);
  cursor: pointer;
}
.btn-venda {
  position: fixed;
  width: 100%;
  max-width: 497px;
  right: 0;
  bottom: 0;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  -webkit-box-shadow: 3px 2px 17px -1px rgba(0, 0, 0, 0.3);
  box-shadow: 3px 2px 17px -1px rgba(0, 0, 0, 0.3);
  background: white;
}
.btn-venda span {
  border-radius: 20px;
  padding: 0.5rem 1rem;
  color: white;
  background: #f25a5a;
  cursor: pointer;
}
.btn-close {
  cursor: pointer;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 25px;
  top: 40px;
}
.btn-close img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.title span {
  font-weight: 300;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.15px;
}
.bnts-tipo-ativo {
  margin-top: 1.5rem;
  width: 100%;
  display: flex;
}
.bnts-tipo-ativo > div {
  margin-right: 0.8rem;
  border-radius: 30px;
  padding: 0.3rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.btn-novo-ativo {
  border: 2px solid rgba(73, 73, 73, 0.5);
  color: rgba(73, 73, 73, 0.5);
}
.btn-ativo-existente {
  border: 2px solid rgba(73, 73, 73, 0.5);
  color: rgba(73, 73, 73, 0.5);
}
.clicado {
  background: rgba(73, 73, 73, 0.5);
  border: none;
  color: white;
}
.input .v-select .dropdown-toggle {
  border: 1px solid red;
}
.input .v-select-select option {
  border: 1px solid red !important;
}
@media screen and (max-width: 480px) {
  .recipiente {
    width: 100%;
  }
}
</style>