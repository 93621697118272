import  Vue     from    "vue";
import  Vuex    from    "vuex";

Vue.use(Vuex);

function prepare (value) {
    return typeof value === 'string' ?
        value :
        value === null || value === undefined ?
        'null' :
        JSON.stringify(value);
}

export default new Vuex.Store({
  state                         : {
    entiy                       : undefined,
    userToAtivoUserEstrategia   : undefined,
    userToAtivoUserProduto      : undefined,
    userToAtivoUserRentabilidade: undefined,
    userToAtivoUserLiquidez     : undefined,
    listAtivos                  : undefined,
    listAtivosFunInv            : undefined,
    listAtivosFunInvTp          : undefined,
    currentUser                 : undefined,
    currentExam                 : {},
    barColor                    : "rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)",
    barImage                    : "https://demos.creative-tim.com/material-dashboard-pro/assets/img/sidebar-1.jpg",
    drawer                      : null,
    user                        : undefined,
    successOnEditUser           : undefined,
    switchEvolution             : false,
    rentabillidade              : undefined,
    profileType                 : undefined,
    preUserRegister             : undefined,
    currentPlan                 : undefined,
    token                       : undefined,
    tokenUserSelected           : undefined,
    userSelected                : undefined,
    planType                    : undefined,
    userChoosedByAcessor        : undefined,
    estrategia                  : undefined
  },
  mutations                     : {
    SET_USERTOATIVOUSEREstrategia     (state, payload) {
      state.userToAtivoUserEstrategia = payload;
    },
    SET_USERTOATIVOUSERProduto        (state, payload) {
      state.userToAtivoUserProduto    = payload;
    },
    SET_USERTOATIVOUSERRentabilidade  (state, payload) {
      state.userToAtivoUserRentabilidade = payload;
    },
    SET_USERTOATIVOUSERLiquidez       (state, payload) {
      state.userToAtivoUserLiquidez   = payload;
    },
    SET_LISTAATIVOSFUNINV             (state, payload) {
      state.listAtivosFunInv          = payload;
    },
    SET_LISTAATIVOSFUNINVTP           (state, payload) {
      state.listAtivosFunInvTp        = payload;
    },
    SET_ENTIY                         (state, payload) {
      state.entiy = payload;
    },
    SET_CURRENT_EXAMS                 (state, payload) {
      localStorage.removeItem("exams");
      const content                   = prepare(payload);
      localStorage.setItem("exams", content);
      state.currentExam               = content;
    },
    SET_CURRENT_EXAM                  (state, payload) {
      localStorage.removeItem("exam");
      const content                   = prepare(payload);
      localStorage.setItem("exam", content);
      state.currentExam               = content;
    },
    SET_BAR_IMAGE                     (state, payload) {
      state.barImage                  = payload;
    },
    SET_DRAWER                        (state, payload) {
      state.drawer                    = payload;
    },
    SET_SCRIM                         (state, payload) {
      state.barColor                  = payload;
    },
    SET_TOKEN_USER_SELECTED           (state, payload) {
      sessionStorage.removeItem("tokenUserSelected");
      const content                   = prepare(payload);
      sessionStorage.setItem("tokenUserSelected", content);
      state.userToAtivoUserEstrategia = null;
      state.userToAtivoUserProduto    = null;
      state.userToAtivoUserRentabilidade = null;
      state.userToAtivoUserLiquidez   = null;
      state.tokenUserSelected         = content;

    },
    SET_USER_SELECTED                 (state, payload) {
      localStorage.removeItem("userSelected");
      let content                     = prepare(payload);
      localStorage.setItem("userSelected", content);
      state.userSelected              = content;
    },
    SET_LOGGED_USER                   (state, payload) {
      sessionStorage.removeItem("user");
      const content                   = prepare(payload);
      sessionStorage.setItem("user", content);
      state.currentUser               = content;
    },
    SET_EXPIRES_TOKEN                 (state, payload) {
      sessionStorage.removeItem("expires");
      const content                   = prepare(payload);
      sessionStorage.setItem("expires", content);
      state.expires                   = content;
    },
    SET_LANGUAGE                      (state, payload) {
      localStorage.removeItem("lang");
      const content                   = prepare(payload);
      localStorage.setItem("lang", content);
      state.lang                      = content;
    },
    SET_CURRENT_TOKEN                 (state, payload) {
      const content                   = prepare(payload);
      sessionStorage.removeItem("token");
      sessionStorage.setItem("token", content);
      state.token                     = content;
    },
    SET_SUCCESS_ON_EDIT_USER          (state, payload) {
      state.successOnEditUser         = payload
    },
    SET_SWITCH_EVOLUTION              (state, payload) {
      state.switchEvolution           = payload
    },
    RENTABILIDADE                     (state, payload) {
      state.rentabillidade            = payload
    },
    SET_PROFILE_TYPE                  (state, payload) {
      state.profileType               = payload
    },
    SET_PRE_USER_REGISTER             (state, payload) {
      state.preUserRegister           = payload
    },
    SET_CURRENT_PLAN                  (state, payload) {
      state.currentPlan               = payload
    },
    SET_PLAN_TYPE                     (state, payload) {
      state.planType                  = payload
    },
    SET_USER_CHOOSED_BY_ACESSOR       (state, payload) {
      state.userChoosedByAcessor      = payload
    }
  },
  actions: {
    SUCCESS_ON_LISTAATIVOSFUNINV      ({commit}, payload) {
      commit('SET_LISTAATIVOSFUNINV', payload)
    },
    SUCCESS_ON_ENTIY                  ({commit}, payload) {
      commit('SET_ENTIY', payload)
    },
    SUCCESS_ON_EDIT_USER              ({commit}, payload) {
      commit('SET_SUCCESS_ON_EDIT_USER', payload)
    },
    CHANGE_SWITCH_EVOLUTIONS          ({commit}, payload) {
      commit('SET_SWITCH_EVOLUTION', payload)
    },
    RENTABILIDADE_ITEM                ({commit}, payload) {
      commit('RENTABILIDADE', payload)
    },
    PUT_PROFILE_TYPE                  ({commit}, payload) {
      commit('SET_PROFILE_TYPE', payload)
      sessionStorage.setItem('profileType', payload)
    },
    PUT_PRE_USER_REGISTER             ({commit}, payload) {
      commit('SET_PRE_USER_REGISTER', payload)
      sessionStorage.setItem('preUserRegister', payload)
    },
    PUT_CURRENT_PLAN                  ({commit}, payload) {
      commit('SET_CURRENT_PLAN', payload)
    },
    PUT_PLAN_TYPE                     ({commit}, payload) {
      commit('SET_PLAN_TYPE', payload)
      sessionStorage.setItem('planType', payload)
    },
    PUT_USER_CHOOSED_BY_ACESSOR       ({commit}, payload) {
      commit('SET_USER_CHOOSED_BY_ACESSOR', payload)
    },
    PUT_TOKEN_USER_SELECTED           ({commit}, payload) {
      commit('SET_TOKEN_USER_SELECTED', payload)
    },
    PUT_USER_SELECTED                 ({commit}, payload) {
      commit('SET_USER_SELECTED', payload)
    }
  },
  getters:{
    $userToAtivoUserEstrategia    (state) {
      if (state.userToAtivoUserEstrategia != undefined){
        return JSON.parse(state.userToAtivoUserEstrategia);
      }else{
        return {content:[],evolucao:[]}

      }

    },
    $userToAtivoUserProduto       (state) {
      if (state.userToAtivoUserProduto != undefined){
        return JSON.parse(state.userToAtivoUserProduto);
      }else{
        return {content:[],evolucao:[]}

      }

    },
    $userToAtivoUserRentabilidade (state) {
      if (state.userToAtivoUserRentabilidade != undefined){
        return JSON.parse(state.userToAtivoUserRentabilidade);
      }else{
        return {calculos:[],dozeMeses:[]}

      }

    },
    $userToAtivoUserLiquidez      (state) {
      if (state.userToAtivoUserLiquidez != undefined){
        return JSON.parse(state.userToAtivoUserLiquidez);
      }else{

        return {content:[],evolucao:[]};
      }

    },
    $listAtivosFunInv             (state) {
      if (state.listAtivosFunInv){
        return JSON.parse(state.listAtivosFunInv);
      } else {
        return [];
      }
    },
    $listAtivosFunInvTp           (state) {
      if (state.listAtivosFunInvTp){
        return JSON.parse(state.listAtivosFunInvTp);
      } else {
        return "";
      }
    },
    $listEntity                   (state) {
      if (state.entiy != undefined){
        return JSON.parse(state.entiy);
      }else{
        return [];
      }

    },
    $tokenCurrentUser             (state) {
      if (state.currentUser != undefined && state.currentUser.id != undefined ){
          return state.currentUser.id ;
      }else{
        return JSON.parse(sessionStorage.getItem('user')).id;
      }

    },
    $tokenUserSelected            (state) {

      return state.tokenUserSelected  || sessionStorage.getItem('tokenUserSelected') || JSON.parse(state.currentUser || sessionStorage.getItem('user')).id ;
    },
    $userSelected                 (state) {

      return JSON.parse(state.userSelected || localStorage.getItem('userSelected')) || JSON.parse(state.currentUser || sessionStorage.getItem('user')) ;
    },
    $token                        (state) {
      return state.token
    },
    $expiresToken                 (state) {
      return state.expires;
    },
    $userLogged                   (state) {
      return JSON.parse(state.currentUser || sessionStorage.getItem('user'))
    },
    $loggedIn                     (state) {
      return state.currentUser != undefined
    },
    $userType                     (state) {
      let resp = JSON.parse(state.currentUser || sessionStorage.getItem('user'))
      let userType = resp.user.userType
      return userType
    },
    $currentUser                  (state) {
      return JSON.parse(state.currentUser|| sessionStorage.getItem('user'))
    },
    $successOnEditUser            (state) {
      return state.successOnEditUser
    },
    $switchEvolutionState         (state) {
      return state.switchEvolution
    },
    $rentabillidade               (state) {
      return state.rentabillidade
    },
    $profileType                  (state) {
      return state.profileType || sessionStorage.getItem('profileType')
    },
    $preUserRegister              (state) {
      return state.preUserRegister || sessionStorage.getItem('preUserRegister')
    },
    $currentPlan                  (state) {
      return state.currentPlan
    },
    $planType                     (state) {
      return state.planType || sessionStorage.getItem('planType')
    },
    $userChoosedByAcessor         (state) {
      return state.userChoosedByAcessor
    }
  },
});
