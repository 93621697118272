<template>
  <v-container class="container" fluid v-if="!objetoEhVazio(tipoAtivo)">
    <div v-if="ativoExistenteOuNovo">
      <component
        :is="currentForm"
        v-bind:btnCompraClicado="btnCompraClicado"
        v-bind:userToAtivos="userToAtivos"
      >
      </component>
    </div>
    <div v-else>
       <div class="mock">
          <v-row class="d-flex flex-wrap pb-16 row">
        <div class="bloco">
          <v-col class="d-flex flex-column align-left py-0">
            <span class="text-filelds">Estratégia</span>
          </v-col>
          <v-col cols="1" class="column">
            <span class="btn"> Automático </span>
          </v-col>
        </div>

        <div class="bloco">
          <v-col class="d-flex flex-column align-left py-0">
            <span class="text-filelds">Indice referência</span>
          </v-col>
          <v-col cols="1" class="column">
            <span class="btn"> Automático </span>
          </v-col>
        </div>

        <div class="bloco">
          <v-col class="d-flex flex-column align-left py-0">
            <span class="text-filelds">Forma de Tributação</span>
          </v-col>
          <v-col cols="1" class="column">
            <span class="btn">SIM </span>
          </v-col>
        </div>
        <div class="bloco">
          <v-col class="d-flex flex-column align-left py-0">
            <span class="text-filelds">Classe ANBIMA</span>
          </v-col>
          <v-col cols="1" class="column">
            <span class="btn"> Automático </span>
          </v-col>
        </div>

        <div class="bloco">
          <v-col class="d-flex flex-column align-left py-0">
            <span class="text-filelds">Formas de Gestão</span>
          </v-col>
          <v-col cols="1" class="column">
            <span class="btn"> Automático </span>
          </v-col>
        </div>
        <div class="bloco">
          <v-col class="d-flex flex-column align-left py-0">
            <span class="text-filelds">FGC</span>
          </v-col>
          <v-col cols="1" class="column">
            <span class="btn"> Automático </span>
          </v-col>
        </div>


        <div class="bloco">
          <v-col class="d-flex flex-column align-left py-0">
            <span class="text-filelds">Total</span>
          </v-col>
          <v-col cols="1" class="column">
            <span class="btn"> R$ 500,00 </span>
          </v-col>
        </div>
      </v-row>
      </div>
    </div>
  </v-container>
</template>

<script>
import CdbRdb from "../../tiposAtivos/RendaFixa/cdb-rdb.vue";
import Cri from "../../tiposAtivos/RendaFixa/cri.vue";
import Cra from "../../tiposAtivos/RendaFixa/cra.vue";
import Debentures from "../../tiposAtivos/RendaFixa/debentures.vue";
import LciLca from "../../tiposAtivos/RendaFixa/lci-lca.vue";
import TitulosPublicos from "../../tiposAtivos/RendaFixa/titulos-publicos.vue";
import Acoes from "../../tiposAtivos/RendaVariavel/acoes.vue";
import FundosImobiliarios from "../../tiposAtivos/RendaVariavel/fundos-imobiliarios.vue";
import FundosInvestimento from "../../tiposAtivos/FundosInvestimento/fundos-investimento.vue";

export default {
  data() {
    return {
      currentForm: "",
      btnCompraClicado: false,
      ativoExistenteOuNovo: true,
      userToAtivos: {},
    };
  },
  components: {
    CdbRdb,
    Cri,
    Cra,
    Debentures,
    LciLca,
    TitulosPublicos,
    Acoes,
    FundosImobiliarios,
    FundosInvestimento,
  },
  props: {
    tipoAtivo: { type: Object },
    botaoCompraClicado: { type: Boolean },
    userToEnviarParaAtivos: { type: Object },
    ativoNovoOuExistente: { type: Boolean },
  },
  watch: {
    tipoAtivo(nv, ov) {
      this.currentForm = nv.value;
    },
    botaoCompraClicado(nv, ov) {
      if (nv == true) {
        this.btnCompraClicado = true;
        setTimeout(() => {
          this.btnCompraClicado = false;
        }, 100);
      }
    },
    userToEnviarParaAtivos(nv, ov) {
      //   console.log(`alterado, valor novo ${nv.email}, cara antigo ${ov.email}`)
      this.userToAtivos = nv;
    },
    ativoNovoOuExistente(nv) {
      this.ativoExistenteOuNovo = nv;
    },
  },
  methods: {
    objetoEhVazio(e) {
      return Object.keys(e).length === 0;
    },
  },
};
</script>

<style scoped>
.container {
  padding: 0;
}


.bloco {
  flex: 1 0 180px;
  width: 100%;
  /* border: 1px solid red; */
  /* margin-top: ; */
}
.bloco .btn {
  height: 40px;
  background: #f6f6f6;
  border-radius: 30px;
  color: #9f9f9f;
  padding: 0.5rem 3rem 0.5rem 1rem;
  width: 100%;
  display: block;
  /* border: 1px solid red; */
}
.column {
  min-width: 100% !important;
}
.text-filelds {
  font-size: 14px;
}
.row{
  padding-bottom: 5rem !important;
}
</style>