<template>
    <div>
        <span>Em breve</span>
        <h1>Página de configurações</h1>

    </div>
</template>

<script>
    export default {
        
    }
</script>

<style  scoped>
div{
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

</style>