const Checkout = () => import('./Checkout.vue');
const Layout = () => import("../../External/Layout.vue");

const CheckoutRoutes = {
    component: Layout,
    children:[
        {
            path: '/',
            name: 'checkout',
            component: Checkout,
            meta: {
                requiresAuth: false,
               //requiresAdmin: false,
              },
        },
    ]
   
}

export default CheckoutRoutes