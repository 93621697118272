<template>
  <div class="Report">
    <div class="Report-topo">
    </div>

    <div v-if="loading" class="d-flex justify-center align-center pa-8">
      <v-progress-circular :size="35"
                           color="primary"
                           indeterminate />
    </div>

    <v-card width="100vw">
      <div class="divImagemPrinting">
      <button class="buttonImagemPrinting" @click="downloadCsv">
        <v-img src="../../../../assets/icons/printing.svg" class="imagem_printing"></v-img>
      </button>
      </div>

      <v-container fluid fill-height class="container">
        <v-row class="top" justify="space-between">
          <v-col cols="12" sm="6" md="3" class="col-2 coluna-input">
            <v-text-field
              rounded
              outlined
              dense
              :disabled="loading"
              type="date"
              name=""
              id=""
              v-model="dataInicial"
              @input="dataInicialChange"
            ></v-text-field>
          </v-col>

        </v-row>

      </v-container>
    </v-card>

    <br> <br>

    <v-card width="100vw">
      <v-col cols="12" sm="6" md="3">
            <span class="title-data">Custodiantes</span>
      </v-col>
      <v-data-table :headers="headers3"
                    :items="custodiantes"
                    class="elevation-1">
          <template v-slot:[`item.ativo`]="{ item }">
            <div :class="getHeader(item)" @click="editItem(item)">
                <span>{{ item.ativo }}</span>
            </div>
          </template>

           <template v-slot:[`item.valor`]="{ item }">
            <div>
                <span>{{formatCurrency(item.valor)}}</span>
            </div>
          </template>

       </v-data-table>
    </v-card>


  </div>
</template>

<script>
import { mapActions } from "vuex";
import ApiService from "../../../../services/ApiService";
import EventBus from "../../../Bus/bus";
import moment from "moment";
import CalculadorAtivo from "../../../../services/Calculador";

export default {
  data() {
    return {
      loading: false,
      dialog: false,
      controller: false,
      apiService: new ApiService(),
      mostrarModal: false,
      element: undefined,
      search: "",
      ativos : [],
      calculador: new CalculadorAtivo().setDefaultLoaders(),
      reloadUsers: false,
      headers3: [
      { text: "Produto", value: "ativo",  align: "left", true: "ativo",  sortable: false},
        { text: "Saldo", value: "valor", align: "right", sortable: false},
      ],
      custodiantes : [],
      custodians : [],
      user: undefined,
      dataInicial: undefined,
      bloco: undefined,
      rate: ''
    };
  },
  created() {
    this.callClientes();
    EventBus.$on("reloadUsers", this.ebReload);
    EventBus.$on("reloadPagesForCallActivesAgain", this.ebReload);
    this.user = this.$store.getters.$userLogged;
    this.dataInicial = new Date().toISOString().substring(0, 10);
  },
  beforeDestroy () {
    EventBus.$off("reloadUsers", this.ebReload);
    EventBus.$off("reloadPagesForCallActivesAgain", this.ebReload);
  },
  methods: {
    ...mapActions(["PUT_CURRENT_USER"]),
    handleClick(e) {
      if (!this.controller) {
        this.$store.commit("SET_TOKEN_USER_SELECTED", e.userId || e.id);
        this.$store.commit("SET_USER_SELECTED", e);
        EventBus.$emit('newUserSelection', e);
        this.$router.push(`/visao-geral`);
      }
    },
    formatDate(date) {
      return moment(date).format("DD/MM/yyyy");
    },
    formatCurrency (valor) {
      return "R$ " + this.calculador.numberToLocale(valor);
    },
    closeDialog() {
    },
    async callClientes() {
      this.loading = true;
      this.apiService.getListEntity(this.$store,8)
        .then((resp) => {
             this.custodians= resp;
        })
      try {
        let model = {
          userId: this.$store.getters.$tokenUserSelected,
          byStrategy: false,
          brief: false
        };
        let resp        = await this.apiService.post("userToAtivo/user", model);
        this.ativos     = resp.content.all.items;;
        this.bloco      = this.calculador.gerarDados(this.ativos);
        const full      = this.calculador.reportRentabilidade(this.bloco, this.dataInicial, this.custodians);
        this.custodiantes = full && full.custodiantes ? full.custodiantes : [];
//        EventBus.$emit("ListaDeUsuarios", this.custodians);

      } catch (reason) {
        window["console"].error(reason);
        this.$toast.error("Erro ao calcular Ativos");
      } finally {
        this.loading = false;
      }
    },
    editItem (item) {
      if (item.isParent) { return; }
      const call = {
        width: 400,
        height: 500,
        view: 'EditarAtivo',
        after: (data) => { data && this.callClientes(); }
      };
      const args = {
        item,
        custodiantes: this.custodians
      };
      EventBus.$emit('openFloatModal', call, args);
    },
    getHeader(item) {
      return item && item.isParent > 0 ? 'parent-item' : 'child-item';
    },
    savefile                (blob, name) {
        let link            = document.createElement('a');
        document.body.appendChild(link);
        link.style          = "display: none";
        const url           = URL.createObjectURL(blob);
        link.href           = url;
        link.download       = name;
        setTimeout(() => {
            link.click();
            URL.revokeObjectURL(url);
        }, 0);
    },
    downloadCsv() {
      let   lines     = [];
      lines.push('usuario');
      const { email, name } = this.$store.getters.$userSelected;
      if (!name) {
        this.$toast.error("Falha ao carregar usuário");
        return;
      }
      lines.push(`"${name}<${email}>"`);
      const propNames = ['ativo', 'valor', 'quantidade','emissor','estrategia','percentual','vencimento','liquidez','fgc'];
      lines.push(propNames.join(','));
      this.custodiantes.forEach((i) => {
          let line = propNames.map((p) => `${JSON.stringify(i[p])}`).join(',');
          lines.push(line);
      });
      const body  = lines.join('\n');
      const blob  = new Blob([body], {type:'text/csv; charset=utf-8'});
      this.savefile(blob, `${name.trim()+'_custodiante' || 'report_monthly'}.csv`);
    },
    showModal(e, evt, item) {
      this.controller = true;
      this.mostrarModal = true;

      if (e === evt.currentTarget.id) {
        if (!this.element) {
          this.element = evt.currentTarget;
        } else {
          this.element = evt.currentTarget;
        }
      }
      let obj = { show: this.mostrarModal, view: e };


        EventBus.$emit("mostrarModal", obj);

      setTimeout(() => {
        this.controller = false;
      }, 1000);
    },
    dataInicialChange(value) {
      return new Promise((acc, rej) => {
        if (!value) { acc(); }
        try {
          this.loading    = true;
          const parsed    = new Date(value + 'T00:00:00');
          const full      = this.calculador.reportRentabilidade(this.bloco, parsed, this.custodians);
          this.custodiantes = full && full.custodiantes ? full.custodiantes : [];
          acc();
        } catch (reason) {
          window["console"].error(reason);
          this.$toast.error("Erro ao calcular Ativos");
          rej(reason);
        } finally {
          this.loading = false;
        }
      });
    },
    ebReload (data) {
      if (!data) { return; }
      this.callClientes();
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  computed: {
  },
};
</script>

<style scoped>
.Report-topo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  flex-wrap: wrap;
}
.top {
  padding: 1.5rem;
}
.v-card {
  border-radius: 20px;
  margin-top: 0.5rem;
}
.container {
  padding: 0;
}
.Report {
  padding: 1.8rem;
}
.Report .title h1 {
  font-weight: 300;
  font-size: 25px;
  line-height: 37px;
  letter-spacing: 0.15px;
  color: #000000;
}
.divImagemPrinting{
width:100%;
height:0px;
}
.buttonImagemPrinting{
width:50px;
margin-top: 14px;
margin-left: 96%;
}
.parent-item {
  background-color: rgba(96,96,96,0.5);
  cursor: default;
}
.child-item {
  margin-left: 20px;
  cursor: pointer;
}
</style>
