<template>
  <v-alert
    transition="scroll-x-reverse-transition"
    class="alert"
    :type="type"
    border="left"
    elevation="3"
  >
    {{ message }}
  </v-alert>
</template>

<style scoped>
.alert {
  position: absolute;
  right: 5%;
  top: 5%;
  z-index: 10;
}
</style>

<script>
export default {
  name: "Alert",

  props: {
    message: String,
    type: String,
  },
};
</script>

<style></style>
