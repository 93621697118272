import  Layout              from    "../../External/Layout.vue";
import  TermsAndConditions  from    "./TermsAndConditions.vue";

const   TermsRoutes         = {
    component               : Layout,
    children                : [
        {
            name            : 'terms-and-conditions',
            path            : '/terms-and-conditions',
            component       : TermsAndConditions,
            meta            : {}
        }
    ]
};

export default              TermsRoutes;