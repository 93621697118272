import Layout from "../../Internal/Layout.vue";
import Base from "./Base.vue";

const ImportacaoRoutes  = {
    component: Layout,
    children: [
        {
            name: 'importacao',
            path: '/',
            component: Base,
            meta: {
                requiresAuth: true,
                requiresBasic: true,
                requiresPattern: true,
                requiresSenior: true,
                requiresExpert: true,
                requiresProfessional: true,
            }
        }
    ]
};

export default ImportacaoRoutes;
