<template>
  <div class="debentures">
    <v-row class="flex-column" align="start">
      <v-col class="d-flex flex-column align-left py-0">
        <span class="text-filelds">Risco de Crédito</span>
      </v-col>
      <v-col>
        <v-autocomplete
          :items="entityCreditRisk"
            :disabled=loading
          :loading="loading"
          item-text="fullName"
          :return-object="true"
          v-model="entityCR"
          placeholder="Insira Risco de Crédito"
          color="secondary"
          required
          outlined
          dense
          rounded
          height="10"
          @change="selectEntityCreditRisk"
          class="select"
          :filter="filterObject"
          clearable
          attach=""
        ></v-autocomplete>
      </v-col>

      <v-col class="d-flex flex-column align-left py-0">
        <span class="text-filelds">Custodiante</span>
      </v-col>
      <v-col>
        <v-autocomplete
          :items="custodians"
            :disabled=loading
          :loading="loading"
          item-text="fullName"
          item-value="entityId"
          v-model="custodiante"
          placeholder="Insira o custodiante"
          color="secondary"
          required
          outlined
          dense
          rounded
          height="10"
          @change="saveCustodiante"
          class="select"
          :filter="filterObject"
          clearable
          attach=""
          :rules="[(v) => v != null || 'Campo obrigatório']"
        ></v-autocomplete>
      </v-col>

      <v-col class="d-flex flex-column align-left py-0">
        <span class="text-filelds">Nome do Ativo</span>
      </v-col>
      <v-col>
        <v-autocomplete
          @change="changeActive"
          :loading="loading"
          :items="ativos"
            :disabled=loading

          return-object
          item-text="code"
          :menu-props="{ bottom: true, offsetY: true }"
          v-model="ativo"
          placeholder="Selecione"
          color="secondary"
          required
          dense
          outlined
          rounded
          class="select"
          :filter="filterObject"
          clearable
           attach=""
        ></v-autocomplete>
      </v-col>

      <v-row class="d-flex flex-wrap flexx">
        <div class="bloco">
          <v-col class="d-flex flex-column align-left py-0">
            <span class="text-filelds">Estratégia</span>
          </v-col>
          <v-col cols="1" class="column">
            <span class="btn">
              {{ estrategia ? estrategia : "Automático" }}
            </span>
          </v-col>
        </div>

        <div class="bloco">
          <v-col class="d-flex flex-column align-left py-0">
            <span class="text-filelds">Indice referência</span>
          </v-col>
          <v-col cols="1 " class="column">
            <span class="btn"> {{ indice ? indice : "Automático" }} </span>
          </v-col>
        </div>

        <div class="bloco">
          <v-col class="d-flex flex-column align-left py-0">
            <span class="text-filelds">FGC</span>
          </v-col>
          <v-col cols="1" class="column">
            <span class="btn">{{FGCDesc}} </span>
          </v-col>
        </div>
        <div class="bloco">
          <v-col class="d-flex flex-column align-left py-0">
            <span class="text-filelds">Isenta de IR (Lei 12.431)</span>
          </v-col>
          <v-col cols="1" class="column">
            <span class="btn"> {{isentaIR}} </span>
          </v-col>
        </div>

        <div class="bloco">
          <v-col class="d-flex flex-column align-left py-0">
            <span class="text-filelds">Indexador</span>
          </v-col>
          <v-col cols="1" class="column">
            <span class="btn">
              {{ indexador ? indexador : "Automático" }}
            </span>
          </v-col>
        </div>

        <div class="bloco">
          <v-col class="d-flex flex-column align-left py-0">
            <span class="text-filelds">{{rateFeesDescription ? rateFeesDescription : "Taxa de Juros"}}</span>
          </v-col>
          <v-col cols="1" class="column">
            <span class="btn"> {{ juros  }} </span>
          </v-col>
        </div>

        <div class="bloco">
          <v-col class="d-flex flex-column align-left py-0">
            <span class="text-filelds">Data Aplicação</span>
          </v-col>
          <v-col class="py-0 flex-grow-0 flex-shrink-1" style="padding: 0">
            <DatePiker @change="saveDataAplicacao" />
          </v-col>
        </div>

        <div class="bloco">
          <v-col class="d-flex flex-column align-left py-0">
            <span class="text-filelds">Data Vencimento</span>
          </v-col>
          <v-col class="py-0" style="padding: 0">
            <DatePiker @change="save" :value="vencimento" :disabled="true" />
          </v-col>
        </div>

        <div class="bloco">
          <v-col class="d-flex flex-column align-left py-0">
            <span class="text-filelds">P. U. da aplicação</span>
          </v-col>
          <v-col class="py-0" style="padding: 0">
            <ValorMonetario :value="vPrecoUnitario"  @change="saveShareValue" />
          </v-col>
        </div>

        <div class="bloco">
          <v-col class="d-flex flex-column align-left py-0">
            <span class="text-filelds">Quantidade</span>
          </v-col>
          <v-col class="py-0" style="padding: 0">
            <ValorMonetario :value="vQtd"  @change="saveShareQuantity" />
          </v-col>
        </div>

        <div class="bloco">
          <v-col class="d-flex flex-column align-left py-0">
            <span class="text-filelds">Total Investido (R$)</span>
          </v-col>
          <v-col class="py-0" style="padding: 0">
            <ValorMonetario :value="vPrecoUnitarioQtd" @change="saveInvestedValue" />
          </v-col>
        </div>
      </v-row>
    </v-row>
  </div>
</template>

<script lang="js">
import ApiService from "../../../../../services/ApiService";
import DatePiker from "../componentes/Date-Piker.vue";
import ValorMonetario from "../componentes/Valor-Monetario.vue";
import textUtil from "../../../../../Utils/TextUtil.js"

export default {
  data() {
    return {
      items: [],
      textUtil,
      date: "",
      menu: "",
      user: "",
      tipoInvestimento: "",
      // userToAtivo: this.userToAtivos,
      userToAtivo: {},
      emissor: "",
      custodiante: "",
      estrategia: "",
      indice: "",
      indexador: "",
      juros: "",
      vencimento: "",
      entityCreditRisk:[],
      entityCR:null,
      ativos: [],
      ativo: {},
      loading: true,
      totaInvestido: "",
      api: new ApiService(),
      description:"",
      FGCDesc:"",
      isentaIR:"",
      custodians: [],
      vPrecoUnitarioQtd :0,
      vPrecoUnitario:0,
      vQtd:0,
      rateFeesDescription:undefined,
    };
  },

  components: {
    DatePiker,
    ValorMonetario,
  },

  props: {
    respGetAtiTipUserId_prop: { type: Array },
    userToAtivos: { type: Object },
    closing: { type: Boolean }
  },
  created() {

    this.api.getListEntity(this.$store,8)
      .then((resp) => {
        this.custodians= resp;
      })
      .catch ((error)=> {
        this.botaoCompraClicado = false;
        this.$toast.error(error.Error);
      });
    this.api.getListAtivos(3)
    .then((resp) => {
      this.ativosFull= resp;
      this.ativos=this.ativosFull;
      this.api.getListEntity(this.$store,4)
        .then((resp) => {
            var _entityCreditRisk= resp;
            this.ativosFull.map((a) =>{
               _entityCreditRisk.filter((ef) => ( ef.entityId == a.entityCreditRiskId)).map(eef=>{
                    if (this.entityCreditRisk.filter(ff=>(ff.entityId == eef.entityId)).length==0){
                      this.entityCreditRisk.push(eef)
                    }
                  })
            })
          })
          .catch ((error)=> {
            this.botaoCompraClicado = false;
            this.$toast.error(error.Error);
          });
      })
      .catch ((error)=> {
        this.botaoCompraClicado = false;
        this.$toast.error(error.Error);
      })




    this.userToAtivo.ShareValue = 0.00;
    this.userToAtivo.ShareQuantity= 0.00;
    this.userToAtivo.InvestedValue = 0.00;

    this.save();
  },

  methods: {
    filterObject(item, queryText, itemText) {
      if (!queryText|| queryText === '') {
        return true;
      }
      return this.textUtil.includesCIAI(item.code||item.fullName,queryText);
    },
    selectEntityCreditRisk(){

      this.ativos=[];
      if (this.entityCR != undefined && this.entityCR.entityId != undefined ){
          this.ativos=this.ativosFull.filter(af=>(af.entityCreditRiskId == this.entityCR.entityId ));
          if ( this.ativos.length==1){
            this.ativo=this.ativos[0];
            this.changeActive(this.ativo);
          }
        }else{
          this.ativos=this.ativosFull;
        }
    },
    save() {
    },
    saveDescription() {
      this.userToAtivo.Description=this.description;

      this.$emit("save", this.userToAtivo);
    },
    saveCustodiante() {
      this.userToAtivo.CustodianteId  = this.custodiante;
      this.$emit("save", this.userToAtivo);
    },
    saveDataAplicacao(data) {
      this.userToAtivo.investmentDate = data;
      this.$emit("save", this.userToAtivo);
    },
    changeActive(data) {
      if (data) {
        this.$emit('buy:activeId', data.activeId);
        this.userToAtivo.ativoId  = data.activeId;
        this.estrategia           = data.strategyDescription;
        this.indice               = data.referenceDescription;
        this.indexador            = data.indexerDescription;
        this.FGCDesc              = data.creditGuaranteeFundDescription;
        this.rateFeesDescription  = data.rateFeesDescription;
        this.juros                = data.rateFees;//indexerPercentage;
        this.juros                = data.indexerPercentage ||  data.rateFees;
        this.isentaIR             = data.incomeTaxDescription;
        this.entityCR             = this.entityCreditRisk.filter(e=>(e.entityId == data.entityCreditRiskId))[0];
        this.vencimento           = data.dueDate.split("T")[0];
        //if   ( this.usersActive.length>0 && this.usersActive.filter(f=>(f.activeId == data.activeId)).length>0){
        //  this.userToAtivo.TpMovimento =2;
        // }else{
          this.userToAtivo.TpMovimento =1;
        // }
        this.userToAtivo.ShareValue = 0.00;
        this.userToAtivo.ShareQuantity= 0.00;
        this.userToAtivo.InvestedValue = 0.00;
      } else {
        this.$emit('buy:activeId', undefined);
        this.userToAtivo.ativoId  = undefined;
        this.estrategia           = undefined;
        this.indice               = undefined;
        this.indexador            = undefined;
        this.FGCDesc              = undefined;
        this.rateFeesDescription  = undefined;
        this.juros                = undefined;
        this.juros                = undefined;
        this.isentaIR             = undefined;
        this.entityCR             = undefined;
        this.vencimento           = undefined;
      }
    },
    saveInvestedValue(InvestedValue){
       this.userToAtivo.InvestedValue=parseFloat(InvestedValue);
     if( this.userToAtivo.ShareQuantity>0){
        this.userToAtivo.ShareValue=0;
      }
     this.saveTotalValue();
    },
    saveShareValue(ShareValue) {
      this.userToAtivo.ShareValue=parseFloat(ShareValue);
      if( this.userToAtivo.ShareQuantity>0){
        this.userToAtivo.InvestedValue=0;
      }
      this.saveTotalValue()
    },
    saveShareQuantity(ShareQuantity) {

      this.userToAtivo.ShareQuantity=parseFloat(ShareQuantity);
      if(this.userToAtivo.ShareValue >0 ){
        this.userToAtivo.InvestedValue=0;
      }
       this.saveTotalValue()
    },
    saveTotalValue() {

      if (this.userToAtivo.ShareValue >0 && this.userToAtivo.ShareQuantity >0){
        this.userToAtivo.InvestedValue= this.userToAtivo.ShareValue  *  this.userToAtivo.ShareQuantity;
        this.$emit("save", this.userToAtivo);
      }else if (this.userToAtivo.InvestedValue >0 &&  this.userToAtivo.ShareQuantity >0){
        this.userToAtivo.ShareValue = this.userToAtivo.InvestedValue /  this.userToAtivo.ShareQuantity;
        this.$emit("save", this.userToAtivo);
      }else if (this.userToAtivo.ShareValue >0 && this.userToAtivo.InvestedValue >0){
        this.userToAtivo.ShareQuantity=(this.userToAtivo.InvestedValue / this.userToAtivo.ShareValue)
        this.$emit("save", this.userToAtivo);
      }

      this.vPrecoUnitario=this.userToAtivo.ShareValue;
      this.vQtd= this.userToAtivo.ShareQuantity;
      this.vPrecoUnitarioQtd=this.userToAtivo.InvestedValue;
    },

  },

  watch: {
      respGetAtiTipUserId_prop(nv){

      this.loading = true;

      //this.ativos=nv[0].listActive;
      this.usersActive=nv[0].usersActive;
      //this.ativosFull=nv[0].listActive;
      //this.entityCreditRisk= nv[0].entityCreditRisk;
     // this.entitySecuritization=nv[0].entitySecuritization;
      //this.entitySecuritizationFull=nv[0].entitySecuritization;
      //this.custodians= nv[0].custodian;
       this.loading = false;
    },

    userToAtivos(nv, ov) {
      if (nv) {
        this.userToAtivo = nv;
      }
    },
  },

  computed: {
    userFormated() {
      return this.$store.getters.$userLogged;
    },
    typeUser() {
      return this.$store.getters.$userType;
    },
  },
};
</script>

<style  scoped>
.debentures {
  margin-top: -20px !important;
  width: 100%;
  padding-top: 0 !important;
  padding-bottom: 6rem;
}
.flexx {
  margin: 0 auto;
  width: 95%;
}
.bloco {
  flex: 1 0 180px;
  width: 100%;
}
.bloco .btn {
  height: 40px;
  background: #f6f6f6;
  border-radius: 30px;
  color: #9f9f9f;
  padding: 0.5rem 3rem 0.5rem 1rem;
  width: 100%;
  font-size: clamp(0.45em, 0.9em, 1em);
  display: flex;
  align-items: center;
}
.column {
  min-width: 100% !important;
}
.text-filelds {
  font-size: 14px;
}
.date-piker input {
  width: 100%;
  border: 1px solid #e6e6e6;
  color: #a4a4a4;
  border-radius: 40px;
  padding: 0.5rem 0.5rem 0.5rem 2.5rem;
  outline: none;
  position: relative;
}
</style>