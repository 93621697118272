<template>
  <div class="top-movimentacao">
    <div class="title">
      <span>Consultar movimentação de ativo</span>
    </div>
    <div class="subtitle">
      <span>Escolha um ativo para visualizar a movimentação</span>
    </div>

    <div class="select">
      <v-autocomplete
        :items="userList"
        no-data-text="Sem registros"
        v-model="ativo"
        @change="changeAtivo($event)"
        item-text="title2"
        return-object
        item-value="id"
        :menu-props="{ bottom: true, offsetY: true }"
        placeholder="Selecione um ativo existente"
        color="secondary"
        required
        outlined
        rounded
        class="input"
      ></v-autocomplete>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    userList: Array,
    ativo_prop: { type: Object },
    ativoId: { type: Number },
  },
  data() {
    return {
      ativo: {  },
      userListAux:[]

    }
  },
  beforeCreate: function() {
  },

  methods: {
    changeAtivo(e) {
      this.ativo = e;
      this.$emit("onChangeAtivo", e);
    },
    genUpdate (id) {
      this.userList.forEach((a) => {
        switch (a.idTProduto) {
          case 1:
          case 4:
          case 13:
          case 7: a.title2 = `${a.title} - ${a.descricao}`; break;
          case 8: a.title2 = `${a.descricao} - ${a.title}`; break;
          default: a.title2 = a.title; break;
        }
      });
      let match = this.userList.filter((u) => u.ativoId === id);
      match.length && this.changeAtivo(match[0]);
    }
  },

  watch : {
    ativoId: {
      handler: function(nval, oval) {
        this.genUpdate(nval);
      }
    },
    userList: {
      handler: function (nval, oval) {
        this.genUpdate(this.ativoId);
      }
    }
  }
};
</script>

<style  scoped>
.title {
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.15px;
  color: #494949;
  margin: 1rem 0 0 0;
}
.select {
  margin-top: 0.5rem;
  max-width: 50vw;
}
</style>