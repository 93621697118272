<template>
    <v-container class="panel">
        <v-row>
            <v-col class="cell-full title">{{
                instance.ativo
            }}</v-col>
        </v-row>
        <v-row>
            <v-col class="cell-full label">
                Custodiante:
            </v-col>
            <v-col class="cell-full ctrl">
                <v-autocomplete v-model="newEntityId"
                                :items="entities"
                                :disabled="loading"
                                :loading="loading"
                                item-text="fullName"
                                item-value="entityId"
                                placeholder="Entidade custodiante"
                                color="secondary"
                                attach=""
                                :filter="filterEntity"
                                :rules="[(v) => v != null || 'Campo obrigatório']"
                                clearable
                                required
                                outlined
                                rounded
                                dense></v-autocomplete>
            </v-col>
        </v-row>
        <v-row class="option">
            <v-col class="cell-50 ctrl">
                <button type="button" class="btn-confirm"
                        @click="updateEntity"
                        :disabled="notfilled">Alterar</button>
            </v-col>
            <v-col class="cell-50 ctrl">
                <button type="button" class="btn-cancel"
                        @click="dismiss">Cancelar</button>
            </v-col>
        </v-row>
    </v-container>
</template>

<style scoped>
.panel {
    padding: 1em;
}
.panel .row {
    margin: 0.25em 0;
}
.panel .row .cell-full {
    flex-basis: 100%;
    min-width: 100%;
    width: 100%;
}
.panel .row .cell-50 {
    flex-basis: 50%;
    min-width: 50%;
    width: 50%;
}
.title, .label {
    padding: 0.25em;
}
.ctrl input {
    width: 100%;
    border: 1px solid rgba(32, 32, 32, 0.5);
    border-radius: 28px;
    font-size: 1.25em;
    padding: 0.25em 1em;
}
.cell-50.ctrl {
    text-align: center;
}
.ctrl button {
    border-radius: 28px;
    padding: 1em;
    margin: 0.5em;
    font-weight: bold;
    cursor: pointer;
    width: 70%;
}
.btn-confirm {
    background-color: rgb(164, 164, 164);
    color: white;
}
.btn-confirm:disabled {
    cursor: default;
}
.btn-cancel {
    background-color: rgb(255, 128, 128);
    color: white;
}
.select-entity {
    width: 100%;
}
@media screen and (max-width: 750px) {
    .panel .row.option {
        flex-direction: column;
    }
    .panel .row .cell-50 {
        flex-basis: 100%;
        min-width: 100%;
        width: 100%;
    }
}
</style>

<script lang="js">
import ApiService from '../../../../services/ApiService';
import textUtil from "../../../../Utils/TextUtil.js"

export default {
    props: {
        args    : { type: Object }
    },
    data () {
        return {
            textUtil,
            loading     : false,
            apiService  : undefined,
            instance    : undefined,
            newEntityId : 0,
            entities    : []
        };
    },
    methods: {
        filterEntity (item, query, _) {
            if (!query) { return true; }
            return this.textUtil.includesCIAI(item.code||item.fullName,query);
        },
        updateEntity () {
            if (!this.$store.getters.$tokenUserSelected) {
                this.$toast.error('Usuário não identificado.');
                return;
            }
            if (this.instance == null || !this.instance.aid) {
                this.$toast.error('Ativo inválido]].');
                return;
            }

            this.loading = true;
            const model = {
                userId: this.$store.getters.$tokenUserSelected,
                ativoId: this.instance.aid,
                custodianteId: this.newEntityId,
            };
            return this.apiService.updateCustodiante(
                    model,
                    this.instance.aid,
                    this.$store.getters.$tokenUserSelected)
                .then((data) => {
                    try {
                        if (!data.success) {
                            this.$toast.error(data.message);
                            return;
                        }
                        this.$toast.info(data.message);
                        this.$emit('onResult', true);
                        this.instance.eid = this.newEntityId;
                    } finally {
                        this.newEntityId = 0;
                        this.instance    = undefined;
                    }
                });
        },
        dismiss   () {
            this.$emit('onResult', false);
        }
    },
    computed : {
        notfilled () {
            return !this.instance ||
                (this.newEntityId === this.instance.eid);
        }
    },
    created () {
        this.apiService  = new ApiService();
        this.instance    = this.args.item;
        this.entities    = this.args.custodiantes;
        this.newEntityId = this.instance ? this.instance.eid : 0;
    },
    mounted () {
    }
}
</script>