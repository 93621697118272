<template>
  <v-card id="exam" min-width="256" elevation="5">
    <v-card-title class="headline">
      <h6 style="color: #f78129">{{ exam.codigoCompra }}</h6>
    </v-card-title>
    <v-card-subtitle style="font-weight: bold; font-size: 0.65rem" class="pb-1">
      {{ exam.status | toUpperCase }} - {{ switchDate(exam.codigoStatus) }}
    </v-card-subtitle>

    <v-card-text class="pb-0">
      <v-row class="px-3 pt-5">
        <span class="payment"> {{ exam.paymentForm | formatMode }} </span>
        <v-spacer></v-spacer>
        <span class="payment"> R${{ exam.examValue }} </span>
      </v-row>

      <v-divider class="mt-6 mb-2"></v-divider>

      <div class="d-flex px-1 pb-1" v-if="exam.codigoStatus != 6">
        <p>
          Sua NOTA FISCAL ficará disponivel até 24h úteis após o resultado do
          seu exame ser disponibilizado
        </p>
      </div>

      <div class="d-flex px-1 pb-3" v-if="exam.codigoStatus == 6">
        <span> NOTA FISCAL </span>
        <v-spacer></v-spacer>
        <v-btn icon :href="receipt.linkNfe">
          <v-icon large>mdi-printer</v-icon>
        </v-btn>
        <v-btn icon @click="share" class="mx-3">
          <v-icon large class="mx-3">mdi-share</v-icon>
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<style scoped>
#exam {
  border-radius: 6px;
}

.payment {
  font-size: 12px;
}

.text {
  font-size: 0.6rem;
}
</style>

<script>
import ApiService from "../../../../services/ApiService";
export default {
  name: "FinanceExam",

  props: {
    exam: Object,
  },

  created() {
    if (this.exam.codigoStatus == 6) {
      this.getNotaFiscal();
    }
  },

  data: () => ({
    receipt: {},
    receiptLoading: false,
    apiService: new ApiService(),
  }),

  methods: {
    getNotaFiscal() {
      this.receiptLoading = true;

      this.apiService
        .get(`user/nota/${parseInt(this.exam.codigoCompra)}`)
        .then((response) => {
          this.receipt = response.data;
          this.receiptLoading = false;
        })
        .catch(() => {
          this.receiptLoading = false;
        });
    },

    switchDate(val) {
      switch (val) {
        case 1:
          return this.exam.dataCadastro;

        case 2:
          return this.exam.dataCadastro;

        case 3:
          return this.exam.dataColeta;

        case 4:
          return this.exam.dataAnalise;

        case 5:
          return this.exam.dataLaudo;

        case 6:
          return this.exam.dataLaudo;
        default:
          break;
      }
    },

    download() {
      const response = "data:application/pdf;base64," + this.receipt.linkNfe;
      const link = document.createElement("a");
      link.href = response;
      link.setAttribute("download", "Relatório");
      document.body.appendChild(link);
      link.click();
    },

    share() {
      if (navigator.share !== undefined) {
        navigator.share({
          title: "LABET - NotaFiscal",
          text: "Nota Fiscal do seu exame LABET",
          url: this.receipt.linkNfe,
        });
      }
    },
  },

  filters: {
    toUpperCase(val) {
      return val.toUpperCase();
    },

    formatMode(val) {
      switch (val) {
        case "E":
          return "BOLETO";
        case "R":
          return "CARTÃO DE CRÉDITO";
        case "X":
          return "PIX";
        case "P":
          return "PAYMEE";
        case "T":
          return "CARTÃO VIA LINK";
        case "C":
          return "BOLETO CONSOLIDADO";

        default:
          break;
      }
    },

    formatTitle(val) {
      switch (val) {
        case 1:
          return "AGUARDANDO PAGAMENTO";
        case 2:
          return "COLETA LIBERADA";
        case 3:
          return "COLETA REALIZADA";
        case 4:
          return "COLETA CHEGOU AO LABORATÓRIO";
        case 5:
          return "RESULTADO DISPONIVEL";
        case 6:
          return "RESULTADO INSERIDO NO RENACH";

        default:
          break;
      }
    },
  },
};
</script>

<style></style>
