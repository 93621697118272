import Layout from "../../Internal/Layout.vue";
import configuracoes from "./Configuracoes.vue";

const ConfiguracoesRoutes = {
  component: Layout,
  children: [
    {
      name: "configuracoes",
      path: "/",
      component: configuracoes,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
      },
    },
  ],
};

export default ConfiguracoesRoutes;
