<template>
  <div class="cdb-rdb">
    <v-row class="flex-column content" align="start">
      <v-form ref="form" lazy-validation class="formulario">
        <v-row class="flex-column line" align="start">
          <v-col class="d-flex flex-column align-start py-0">
            <span class="text-filelds">Nome e Sobrenome</span>
          </v-col>
          <v-col class="column">
            <v-text-field
              v-model="userToEdit.name"
              placeholder="Roberto Silva"
              :rules="[(v) => !!v || 'Nome requerido']"
              color="secondary"
              required
              dense
              outlined
              rounded
              class="input"
            />
          </v-col>
        </v-row>

        <v-row class="flex-column line" align="start">
          <v-col class="d-flex flex-column align-start py-0">
            <span class="text-filelds">E-mail</span>
          </v-col>
          <v-col class="column">
            <v-text-field
              v-model="userToEdit.email"
              placeholder="curtis.weave@example.com"
              :rules="[(v) => !!v || 'E-mail requerido']"
              color="secondary"
              required
              dense
              outlined
              rounded
              class="input"
            />
          </v-col>
        </v-row>

        <v-row class="flex-column line" align="start">
          <v-col class="d-flex flex-column align-start py-0">
            <span class="text-filelds">Telefone</span>
          </v-col>
          <v-col class="column">
            <v-text-field
              v-model="userToEdit.phoneNumber"
              placeholder="(22) 5555-9090"
                v-mask="['(##)####-####', '(##)#####-####']"
              :rules="[(v) => !!v || 'Telefone requerido']"
              color="secondary"
              required
              dense
              outlined
              rounded
              class="input"
            />
          </v-col>
        </v-row>
        <v-row class="flex-column line" align="start">
          <v-col class="d-flex flex-column align-left py-0">
            <span class="text-filelds">Tipo de Plano</span>
          </v-col>
          <v-col class="column">
            <v-select
              v-model="userToEdit.tipoPlano"
              :items="items"
              item-text="name"
              :menu-props="{ bottom: true, offsetY: true }"
              placeholder="Selecione"
              color="secondary"
              required
              outlined
              rounded
              dense
              height="0"
              class="select"
            ></v-select>
          </v-col>
        </v-row>
      </v-form>
    </v-row>
  </div>
</template>

<script>
import ApiService from "../../../../services/ApiService";
import EventBus from "../../../Bus/bus"
import {mapActions} from 'vuex'
export default {
  props: {
    btnUpdateClicado: { type: Boolean },
    user: {type: Object}
  },
  data() {
    return {
      items: ["Grátis"],
      userToEdit: {
        id: this.user.id,
        email: this.user.email,
        name: this.user.name,
        phoneNumber: this.user.phoneNumber,
        tipoPlano: "Grátis",
      },
      successOnEditUser: false,
      apiService: new ApiService(),
    };
  },
  components: {},
  methods: {
    ...mapActions(['SUCCESS_ON_EDIT_USER']),
    async submit() {

      try {
        let req = await this.apiService.post("user/edit", this.userToEdit);
        
        if(req.statusCode == 200){
            this.$toast.success("Usuário alterado com sucesso")
            this.successOnEditUser = true
            this.SUCCESS_ON_EDIT_USER(this.successOnEditUser)
             setTimeout(() =>{
                EventBus.$emit('reloadUsers',true)
                EventBus.$emit('closeModalOnCreateUser',true)
                this.successOnEditUser = false
                this.SUCCESS_ON_EDIT_USER(this.successOnEditUser)
            }, 1500)
        }

      } catch (error) {
         this.SUCCESS_ON_EDIT_USER(this.successOnEditUser)
        if (error.body.message) {
          this.$toast.error(error.body.message);
        } else {
          this.$toast.error("Error ao alterar usuário");
        }
      }
    },
  },
  watch: {
    btnUpdateClicado(nv, ov) {
      if (nv == true) {
        this.submit();
      }
    },
  },
};
</script>

<style  scoped>
.cdb-rdb {
  margin-top: 0 !important;
  width: 100%;
  padding-top: 0 !important;
  padding-bottom: 5rem;
}
.formulario {
  margin-top: 2rem;
  width: 100%;
}
.input {
  width: 100% !important;
}
.line {
  padding: 0 !important;
}
.column {
  padding-bottom: 0;
}
.content {
}
</style>