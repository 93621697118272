<template>
  <div class="column-graph">
    <div class="year">
      <span>{{year}}</span>
    </div>
    <div class="dados">
      <slot/>

    </div>
  </div>
</template>

<script>
export default {
  components: {

  },
  props:{
      year: {type: String}
  }

};
</script>

<style  scoped>
.column-graph {
  width: 100%;
  height: 100%;
  text-align: center;
}
.dados {
  display: flex;
  flex-direction: column;
}
.dados .dado {
  margin: 0.2rem 0;
}
</style>