<script>
import { Pie, mixins } from "vue-chartjs";
const { reactiveProp } = mixins

export default {
  extends: Pie,
  mixins:[reactiveProp],
  props: {
    options: { type: Object },
    plugins: {type: Array}

  },
  methods: {
    renderizarChart() {
      this.renderChart(
        this.chartData,
        this.options,
        this.plugins
      );
    },
  },
  mounted() {
    this.renderizarChart();
  },
};
</script>