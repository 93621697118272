<template>
  <div class="desempenho-component">
    <div class="descricoes">
      <!-- <div class="title">
        <h1>Desempenho por carteira</h1>
        <span
          >Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis ullamco
          cillum dolor. Voluptate exercitation incididunt aliquip deserunt
          reprehenderit elit laborum.
        </span>
      </div> -->
    </div>

    <v-card flat class="card-grafico">
      <TopGraph />
      <Graph :dataColumns="dados" />
      <div class="arrow-content">
        <img src="@/assets/icons/arrow_desempenho_carteira_icon.svg" alt="" />
      </div>
    </v-card>
  </div>
</template>

<script>
import * as Mock from "@/components/mock/mock.js";
import Graph from "./box-desempenho-carteira/container-graph.vue";
import TopGraph from "./box-desempenho-carteira/top-graph.vue";
export default {
  components: {
    Graph,
    TopGraph,
  },
  props: ["calculos", "dozeMeses"],
  data() {
    return {
      dados: [],
    };
  },
  methods: {
    loadData() {
      this.dados = this.dozeMeses.map((o,i) => {
        let record = [{
            id    : 5 * i + 1,
            title : o.carteira.label,
            value : o.carteira.taxa,
            year  : o.carteira.rotulo,
            color : "#C3C3C3",
          },{
            id    : 5 * i + 2,
            title : o.cdi.label,
            value : o.cdi.taxa,
            year  : o.cdi.rotulo,
            color : "#35BAFC",
          },{
            id    : 5 * i + 3,
            title : o.ipca.label,
            value : o.ipca.taxa,
            year  : o.ipca.rotulo,
            color : "#29BD1A",
          },{
            id    : 5 * i + 4,
            title : o.dolar.label,
            value : o.dolar.taxa * 100,
            year  : o.dolar.rotulo,
            color : "#C520FF",
          },{
            id    : 5 * i + 5,
            title : o.ibovespa.label,
            value : o.ibovespa.taxa * 100,
            year  : o.ibovespa.rotulo,
            color : "#FA5807",
          },
        ].sort((l, r) => l.value - r.value);
        return {
          id: i + 1,
          year: o.carteira.rotulo,
          actives: record
        };
      });
    }
  },
  created() {
    this.loadData();
  },
  watch: {
    dozeMeses(nv, ov) {
      this.loadData();
    }
  }
};
</script>

<style  scoped>
/* .desempenho-component {
} */
.descricoes {
  margin-bottom: 1.5rem;
}
.descricoes .title {
  max-width: 50%;
}
.descricoes .title h1 {
  font-size: 25px;
  line-height: 37px;
  font-weight: 500;
  letter-spacing: 0.15px;
  color: #000000;
}
.descricoes .title span {
  font-weight: 300;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.15px;
  color: #000000;
}

.card-grafico {
  padding: 1rem 0;
  border-radius: 20px;
  position: relative;
}
.arrow-content{
  position: absolute;
  top: 120px;
  left: 2px;
  height: 75%;
}
.arrow-content img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 890px){
  .arrow-content{
    display: none;
  }
}
@media screen and (min-width: 1400px){
  .arrow-content{
   left: -4px;
  }
}
</style>