<template>
 <!--https://codebrisk.com/blog/html-to-pdf-with-vue-html2pdf-component-->
<div >

	<v-row class="divpdf">
		<div class="divImagemPrinting">
		  <button type="button" class="buttonImagemPrinting" @click="downloadPdf">
			<v-img src="../../../assets/icons/printing.svg" class="imagem_printing"></v-img>
		  </button>
		</div>
    <vue-html2pdf  class="html2pdf"
        :show-layout="false"
        :float-layout="false"
        :enable-download="true"
        :preview-modal="false"
     	filename="filename"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="landscape"
		:paginate-elements-by-height="800"
		pdf-content-width="1100px"
		:html-to-pdf-options="htmlToPdfOptions"
        @progress="onProgress($event)"
        @startPagination="startPagination()"
        @hasPaginated="hasPaginated()"
        @beforeDownload="beforeDownload($event)"
         @hasDownloaded="hasDownloaded($event)"
        ref="html2Pdf">
		<template slot="pdf-content">
        	<section slot="pdf-item" class="section">
				<v-row class="rowHeader">
					<div class="colHeader01">
						<v-img
							class="imagem_Kristal"
							alt=""
							:src="imagemPerfil()"
						></v-img>
					</div>
					<div class="colHeader02" >
						<span class="titleUserName"> {{userSelected.name}}</span>
					</div>
					<div class="colHeader03" >
						<div class="data">
							<div>
								<span> Pagina 1/8 {{ $hoje }}</span>
							</div>
							<div>
								<v-img src="../../../assets/icons/calendar_icon.svg" class="imagem_calendar"></v-img>
							</div>
						</div>
					</div>
				</v-row>
			<v-row class="rowSaldos">
				<div class="card">
					<span class="title">Saldo Bruto</span>
					<span class="valor">{{currencyHelper.formatValue(evolucaoSaldo)}}</span>
				</div>
				<div class="card">
					<span class="title">Saldo Liquido</span>
					<span class="valor">{{currencyHelper.formatValue(evolucaoLiquido)}}</span>
				</div>
				<div class="card">
					<span class="title">Rendimento</span>
					<span class="valor">{{currencyHelper.formatValue(evolucaoRendimento)}}</span>
				</div>
				<div class="card">
					<span class="title">Rendimento Histórico</span>
					<span class="valor">{{percentageHelper.formatPercentage(evolucaoGanho)}}</span>
				</div>
			</v-row>
			<div class="descricaotitulo">
						<span></span>
          				<span> Rendimento do Portifolio </span>
			</div>
			<v-row class="rowPortifolio">
				<div class="card">
					<span class="title">Periodo</span>
					<span class="valor"> Carteira</span>
					<span class="valor"> CDI</span>
					<span class="valor"> IPCA</span>
				</div>
				<div class="card">
					<span class="title">{{ monthLabel }}</span>
					<span class="valor">{{ percentageHelper.formatPercentage(getEvoTotalPath('mensal.fatia')) }}</span>
					<span class="valor">{{ percentageHelper.formatPercentage(getEvoTotalPath('cotacoes.cdi.mensal')) }}</span>
					<span class="valor">{{ percentageHelper.formatPercentage(getEvoTotalPath('cotacoes.ipca.mensal')) }}</span>
				</div>
				<div class="card">
					<span class="title">no Ano</span>
					<span class="valor">{{ percentageHelper.formatPercentage(getEvoTotalPath('ano.fatia')) }}</span>
					<span class="valor">{{ percentageHelper.formatPercentage(getEvoTotalPath('cotacoes.cdi.ano')) }}</span>
					<span class="valor">{{ percentageHelper.formatPercentage(getEvoTotalPath('cotacoes.ipca.ano')) }}</span>
				</div>
				<div class="card">
					<span class="title">12 meses</span>
					<span class="valor">{{ percentageHelper.formatPercentage(getEvoTotalPath('doze.fatia')) }}</span>
					<span class="valor">{{ percentageHelper.formatPercentage(getEvoTotalPath('cotacoes.cdi.doze')) }}</span>
					<span class="valor">{{ percentageHelper.formatPercentage(getEvoTotalPath('cotacoes.ipca.doze')) }}</span>
				</div>
				<div class="card">
					<span class="title">Histórico</span>
					<span class="valor">{{ percentageHelper.formatPercentage(getEvoTotalPath('geral.fatia')) }}</span>
					<span class="valor">{{ percentageHelper.formatPercentage(getEvoTotalPath('cotacoes.cdi.geral')) }}</span>
					<span class="valor">{{ percentageHelper.formatPercentage(getEvoTotalPath('cotacoes.ipca.geral')) }}</span>
				</div>
			</v-row>

			<v-row class="rowSwitch" v-if="!checkDownloadPdf">
				<div class="btns-ativos" ref="btnsAtivos" >
				  <div class="btn-cdi">
					<v-switch checked @change="changeSwitch('CDI')"
							  v-model="switchStateCDI"
							  label="CDI"/>
				  </div>

				  <div class="btn-ipca">
					<v-switch checked @change="changeSwitch('IPCA')"
							  v-model="switchStateIPCA"
							  label="IPCA"/>
				  </div>

				  <div class="btn-dolar">
					<v-switch @change="changeSwitch('DOLAR')"
							  v-model="switchStateDOLAR"
							  label="DÓLAR"/>
				  </div>

				  <div class="btn-ibovespa">
					<v-switch @change="changeSwitch('IBOVESPA')"
							  v-model="switchStateIBOVESPA"
							  label="IBOVESPA"/>
				  </div>

				</div>
			</v-row>

			<div class="html2pdf__page-break" />

			<v-row class="rowHeader">
				<div class="colHeader01">
					<v-img
						class="imagem_Kristal"
						alt=""
						:src="imagemPerfil()"
					></v-img>
				</div>
				<div class="colHeader02" >
					<span class="titleUserName"> {{userSelected.name}}</span>
				</div>
				<div class="colHeader03" >
					<div class="data">
						<div>
							<span> Pagina 2/8 {{ $hoje }}</span>
						</div>
						<div>
							<v-img src="../../../assets/icons/calendar_icon.svg" class="imagem_calendar"></v-img>
						</div>
					</div>
				</div>
			</v-row>
		  	<v-row class="rowLinhaHeader">
				<v-col sm="12" md="2" xs="12" class="coluna">
					<div class="descricao">
          		  		<span> Estretégia por {{descTypeswitchState}}</span>

					</div>
				</v-col>
				<v-col sm="12" md="1" xs="12" class="coluna">
					<div class="descricao">
						<span> Saldo Bruto </span>
					</div>
				</v-col>
      			<v-col sm="12" md="9" xs="12" class="coluna coluna-composta">
        			<div class="descricaotitulo">
          				<span> Rentabilidade por Estrategia </span>
        			</div>
	        		<div class="colunas">
    	      			<v-row>
        	    			<v-col sm="12" md="1" xs="12">
            	  				<div class="mes"><span>{{ monthLabel }}</span></div>
            				</v-col>
							<v-col sm="12" md="1" xs="12">
              					<div><span>%</span></div>
	            			</v-col>
    	        			<v-col sm="12" md="2" xs="12">
        	      				<div><span>R$</span></div>
            				</v-col>
            				<v-col sm="12" md="1" xs="12">
              					<div class="meses-12"><span>12 meses</span></div>
            				</v-col>
	            			<v-col sm="12" md="1" xs="12">
    	          				<div><span>%</span></div>
        	    			</v-col>
				            <v-col sm="12" md="2" xs="12">
              					<div><span>R$</span></div>
            				</v-col>
							<v-col sm="12" md="1" xs="12">
    	          				<div class="inicio"><span>Desde o início</span></div>
        	    			</v-col>
            				<v-col sm="12" md="1" xs="12">
              					<div><span>%</span></div>
            				</v-col>
            				<v-col sm="12" md="2" xs="12">
              					<div><span>R$</span></div>
            				</v-col>
          				</v-row>
        			</div>
      			</v-col>
 			</v-row>


		<v-row class="rowLinha header-carteira">
			<v-col md="1.7" xs="12" class="coluna">
				<div class="descricao">
				<span> Carteira</span>
				</div>
			</v-col>
			<v-col md="1.3" xs="12" class="coluna coluna-composta">
				<div>
				<span>
					{{ currencyHelper.formatValue(getEvoTotalPath('saldo')) }}
				</span>
				</div>
			</v-col>
			<v-col md="9" xs="12" class="coluna coluna-composta">
				<div class="colunas">
				<v-row>
					<v-col md="1" xs="12" class="hide column">
					<div class=""><span>{{percentageHelper.formatPercentage(getEvoTotalPath('mensal.fatia'))}}</span></div>
					</v-col>

					<v-col md="1" xs="12" class="hide column">
					<div><span>{{percentageHelper.formatPercentage(getEvoTotalPath('mensal.percentual'))}}</span></div>
					</v-col>

					<v-col md="2" xs="12"  class="hide column">
					<div><span>{{currencyHelper.formatValue(getEvoTotalPath('mensal.valor'))}}</span></div>
					</v-col>

					<v-col md="1" xs="12" class="hide column">
					<div class=""><span>{{percentageHelper.formatPercentage(getEvoTotalPath('doze.fatia'))}}</span></div>
					</v-col>

					<v-col md="1" xs="12" class="hide column">
					<div><span>{{percentageHelper.formatPercentage(getEvoTotalPath('doze.percentual'))}}</span></div>
					</v-col>

					<v-col md="2" xs="12" class="hide column">
					<div><span>{{currencyHelper.formatValue(getEvoTotalPath('doze.valor'))}}</span></div>
					</v-col>

					<v-col md="1" xs="12" class="hide column">
					<div class=""><span>{{percentageHelper.formatPercentage(getEvoTotalPath('geral.fatia'))}}</span></div>
					</v-col>

					<v-col md="1" xs="12" class="hide column">
					<div><span>{{percentageHelper.formatPercentage(getEvoTotalPath('geral.percentual'))}}</span></div>
					</v-col>

					<v-col md="2" xs="12" class="hide column">
					<div><span>{{currencyHelper.formatValue(getEvoTotalPath('geral.valor'))}}</span></div>
					</v-col>
				</v-row>
				</div>
			</v-col>
		</v-row>

		<v-row class="rowLinha header-precdi">

			<v-col md="1.7" xs="12" class="coluna">
			<div class="descricao">
				<span> Juros Pós(CDI) <br>{{ percentageHelper.formatPercentage(saldoJurosPosCdi.saldo/getEvoTotalPath('saldo'))}}</span>
			</div>
			</v-col>
			<v-col md="1.3" xs="12" class="coluna  coluna-composta">
			<div>
				<span>
				{{ currencyHelper.formatValue(saldoJurosPosCdi.saldo) }}
				</span>
			</div>
			</v-col>
			<v-col md="9" xs="12" class="coluna coluna-composta">
			<div class="colunas">
				<v-row>
				<v-col md="1" xs="12" class="hide column">
					<div class=""><span>{{percentageHelper.formatPercentage(saldoJurosPosCdi.mensal.fatia)}}</span></div>
				</v-col>

				<v-col md="1" xs="12" class="hide column">
					<div><span>{{percentageHelper.formatPercentage(saldoJurosPosCdi.mensal.percentual)}}</span></div>
				</v-col>

				<v-col md="2" xs="12"  class="hide column">
					<div><span>{{currencyHelper.formatValue(saldoJurosPosCdi.mensal.valor)}}</span></div>
				</v-col>

				<v-col md="1" xs="12" class="hide column">
					<div class=""><span>{{percentageHelper.formatPercentage(saldoJurosPosCdi.doze.fatia)}}</span></div>
				</v-col>

				<v-col md="1" xs="12" class="hide column">
					<!-- <div><span>{{percentageHelper.formatPercentage(saldoJurosPosCdi.doze.percentual)}}</span></div> -->
					---
				</v-col>

				<v-col md="2" xs="12" class="hide column">
					<div><span>{{currencyHelper.formatValue(saldoJurosPosCdi.doze.valor)}}</span></div>
				</v-col>

				<v-col md="1" xs="12" class="hide column">
					<div class=""><span>{{percentageHelper.formatPercentage(saldoJurosPosCdi.geral.fatia)}}</span></div>
				</v-col>

				<v-col md="1" xs="12" class="hide column">
					 <!--  <div><span>{{percentageHelper.formatPercentage(saldoJurosPosCdi.geral.percentual)}}</span></div> -->
					 ---
				</v-col>

				<v-col md="2" xs="12" class="hide column">
					<div><span>{{currencyHelper.formatValue(saldoJurosPosCdi.geral.valor)}}</span></div>
				</v-col>
				</v-row>
			</div>
			</v-col>
		</v-row>

		<v-row class="rowLinha header-posinflacao">

			<v-col md="1.7" xs="12" class="coluna">
			<div class="descricao">
				<span> Juros  Pós(Inflacão) <br>{{ percentageHelper.formatPercentage(saldoJurosPosInflacao.saldo/getEvoTotalPath('saldo'))}}</span>
			</div>
			</v-col>
			<v-col md="1.3" xs="12" class="coluna  coluna-composta">
			<div>
				<span>
				{{ currencyHelper.formatValue(saldoJurosPosInflacao.saldo) }}
				</span>
			</div>
			</v-col>
			<v-col md="9" xs="12" class="coluna coluna-composta">
			<div class="colunas">
				<v-row>
				<v-col md="1" xs="12" class="hide column">
					<div class=""><span>{{percentageHelper.formatPercentage(saldoJurosPosInflacao.mensal.fatia)}}</span></div>
				</v-col>

				<v-col md="1" xs="12" class="hide column">
					<div><span>{{percentageHelper.formatPercentage(saldoJurosPosInflacao.mensal.percentual)}}</span></div>
				</v-col>

				<v-col md="2" xs="12"  class="hide column">
					<div><span>{{currencyHelper.formatValue(saldoJurosPosInflacao.mensal.valor)}}</span></div>
				</v-col>

				<v-col md="1" xs="12" class="hide column">
					<div class=""><span>{{percentageHelper.formatPercentage(saldoJurosPosInflacao.doze.fatia)}}</span></div>
				</v-col>

				<v-col md="1" xs="12" class="hide column">
					<!-- <div><span>{{percentageHelper.formatPercentage(saldoJurosPosInflacao.doze.percentual)}}</span></div> -->
					---
				</v-col>

				<v-col md="2" xs="12" class="hide column">
					<div><span>{{currencyHelper.formatValue(saldoJurosPosInflacao.doze.valor)}}</span></div>
				</v-col>

				<v-col md="1" xs="12" class="hide column">
					<div class=""><span>{{percentageHelper.formatPercentage(saldoJurosPosInflacao.geral.fatia)}}</span></div>
				</v-col>

				<v-col md="1" xs="12" class="hide column">
					<!-- <div><span>{{percentageHelper.formatPercentage(saldoJurosPosInflacao.geral.percentual)}}</span></div> -->
					---
				</v-col>

				<v-col md="2" xs="12" class="hide column">
					<div><span>{{currencyHelper.formatValue(saldoJurosPosInflacao.geral.valor)}}</span></div>
				</v-col>
				</v-row>
			</div>
			</v-col>
		</v-row>


		<v-row class="rowLinha header-prefixo">

			<v-col md="1.7" xs="12" class="coluna">
				<div class="descricao">
				<span> Juros Pré(fixo) <br>{{ percentageHelper.formatPercentage(saldoJurosPre.saldo/getEvoTotalPath('saldo'))}}</span>
				</div>
			</v-col>
			<v-col md="1.3" xs="12" class="coluna  coluna-composta">
				<div>
				<span>
					{{ currencyHelper.formatValue(saldoJurosPre.saldo) }}
				</span>
				</div>
			</v-col>
			<v-col md="9" xs="12" class="coluna coluna-composta">
				<div class="colunas">
				<v-row>
					<v-col md="1" xs="12" class="hide column">
					<div class=""><span>{{percentageHelper.formatPercentage(saldoJurosPre.mensal.fatia)}}</span></div>
					</v-col>

					<v-col md="1" xs="12" class="hide column">
					<div><span>{{percentageHelper.formatPercentage(saldoJurosPre.mensal.percentual)}}</span></div>
					</v-col>

					<v-col md="2" xs="12"  class="hide column">
					<div><span>{{currencyHelper.formatValue(saldoJurosPre.mensal.valor)}}</span></div>
					</v-col>

					<v-col md="1" xs="12" class="hide column">
					<div class=""><span>{{percentageHelper.formatPercentage(saldoJurosPre.doze.fatia)}}</span></div>
					</v-col>

					<v-col md="1" xs="12" class="hide column">
					<!-- <div><span>{{percentageHelper.formatPercentage(saldoJurosPre.doze.percentual)}}</span></div> -->
					---
					</v-col>

					<v-col md="2" xs="12" class="hide column">
					<div><span>{{currencyHelper.formatValue(saldoJurosPre.doze.valor)}}</span></div>
					</v-col>

					<v-col md="1" xs="12" class="hide column">
					<div class=""><span>{{percentageHelper.formatPercentage(saldoJurosPre.geral.fatia)}}</span></div>
					</v-col>

					<v-col md="1" xs="12" class="hide column">
					<!-- <div><span>{{percentageHelper.formatPercentage(saldoJurosPre.geral.percentual)}}</span></div> -->
					---
					</v-col>

					<v-col md="2" xs="12" class="hide column">
					<div><span>{{currencyHelper.formatValue(saldoJurosPre.geral.valor)}}</span></div>
					</v-col>
				</v-row>
				</div>
			</v-col>
		</v-row>

		<v-row class="rowLinha header-livres">

			<v-col md="1.7" xs="12" class="coluna">
			<div class="descricao">
				<span>Livres <br>{{ percentageHelper.formatPercentage(saldoLivre.saldo/getEvoTotalPath('saldo'))}}</span>
			</div>
			</v-col>
			<v-col md="1.3" xs="12" class="coluna  coluna-composta">
			<div>
				<span>
				{{ currencyHelper.formatValue(saldoLivre.saldo) }}
				</span>
			</div>
			</v-col>
			<v-col md="9" xs="12" class="coluna coluna-composta">
			<div class="colunas">
				<v-row>
				<v-col md="1" xs="12" class="hide column">
					<div class=""><span>{{percentageHelper.formatPercentage(saldoLivre.mensal.fatia)}}</span></div>
				</v-col>

				<v-col md="1" xs="12" class="hide column">
					<div><span>{{percentageHelper.formatPercentage(saldoLivre.mensal.percentual)}}</span></div>
				</v-col>

				<v-col md="2" xs="12"  class="hide column">
					<div><span>{{currencyHelper.formatValue(saldoLivre.mensal.valor)}}</span></div>
				</v-col>

				<v-col md="1" xs="12" class="hide column">
					<div class=""><span>{{percentageHelper.formatPercentage(saldoLivre.doze.fatia)}}</span></div>
				</v-col>

				<v-col md="1" xs="12" class="hide column">
					<!-- <div><span>{{percentageHelper.formatPercentage(saldoLivre.doze.percentual)}}</span></div> -->
					---
				</v-col>

				<v-col md="2" xs="12" class="hide column">
					<div><span>{{currencyHelper.formatValue(saldoLivre.doze.valor)}}</span></div>
				</v-col>

				<v-col md="1" xs="12" class="hide column">
					<div class=""><span>{{percentageHelper.formatPercentage(saldoLivre.geral.fatia)}}</span></div>
				</v-col>

				<v-col md="1" xs="12" class="hide column">
					<!-- <div><span>{{percentageHelper.formatPercentage(saldoLivre.geral.percentual)}}</span></div> -->
					---
				</v-col>

				<v-col md="2" xs="12" class="hide column">
					<div><span>{{currencyHelper.formatValue(saldoLivre.geral.valor)}}</span></div>
				</v-col>
				</v-row>
			</div>
			</v-col>
		</v-row>

		<v-row class="rowLinha header-rendavariavel">

			<v-col md="1.7" xs="12" class="coluna">
				<div class="descricao">
				<span> Renda variável <br>{{ percentageHelper.formatPercentage(saldoRendaVariavel.saldo/getEvoTotalPath('saldo'))}} </span>
				</div>
			</v-col>
			<v-col md="1.3" xs="12" class="coluna  coluna-composta">
				<div>
				<span>
					{{ currencyHelper.formatValue(saldoRendaVariavel.saldo) }}
				</span>
				</div>
			</v-col>
			<v-col md="9" xs="12" class="coluna coluna-composta">
				<div class="colunas">
				<v-row>
					<v-col md="1" xs="12" class="hide column">
					<div class=""><span>{{percentageHelper.formatPercentage(saldoRendaVariavel.mensal.fatia)}}</span></div>
					</v-col>

					<v-col md="1" xs="12" class="hide column">
					<div><span>{{percentageHelper.formatPercentage(saldoRendaVariavel.mensal.percentual)}}</span></div>
					</v-col>

					<v-col md="2" xs="12"  class="hide column">
					<div><span>{{currencyHelper.formatValue(saldoRendaVariavel.mensal.valor)}}</span></div>
					</v-col>

					<v-col md="1" xs="12" class="hide column">
					<div class=""><span>{{percentageHelper.formatPercentage(saldoRendaVariavel.doze.fatia)}}</span></div>
					</v-col>

					<v-col md="1" xs="12" class="hide column">
					<!-- <div><span>{{percentageHelper.formatPercentage(saldoRendaVariavel.doze.percentual)}}</span></div> -->
					---
					</v-col>

					<v-col md="2" xs="12" class="hide column">
					<div><span>{{currencyHelper.formatValue(saldoRendaVariavel.doze.valor)}}</span></div>
					</v-col>

					<v-col md="1" xs="12" class="hide column">
					<div class=""><span>{{percentageHelper.formatPercentage(saldoRendaVariavel.geral.fatia)}}</span></div>
					</v-col>

					<v-col md="1" xs="12" class="hide column">
					<!-- <div><span>{{percentageHelper.formatPercentage(saldoRendaVariavel.geral.percentual)}}</span></div> -->
					---
					</v-col>

					<v-col md="2" xs="12" class="hide column">
					<div><span>{{currencyHelper.formatValue(saldoRendaVariavel.geral.valor)}}</span></div>
					</v-col>
				</v-row>
				</div>
			</v-col>
		</v-row>

		<v-row class="rowLinha header-cambio">

			<v-col md="1.7" xs="12" class="coluna">
			<div class="descricao">
				<span> Câmbio <br>{{ percentageHelper.formatPercentage(saldoCambio.saldo/getEvoTotalPath('saldo'))}}</span>
			</div>
			</v-col>
			<v-col md="1.3" xs="12" class="coluna  coluna-composta">
			<div>
				<span>
				{{ currencyHelper.formatValue(saldoCambio.saldo) }}
				</span>
			</div>
			</v-col>
			<v-col md="9" xs="12" class="coluna coluna-composta">
			<div class="colunas">
				<v-row>
				<v-col md="1" xs="12" class="hide column">
					<div class=""><span>{{percentageHelper.formatPercentage(saldoCambio.mensal.fatia)}}</span></div>
				</v-col>

				<v-col md="1" xs="12" class="hide column">
					<div><span>{{percentageHelper.formatPercentage(saldoCambio.mensal.percentual)}}</span></div>
				</v-col>

				<v-col md="2" xs="12"  class="hide column">
					<div><span>{{currencyHelper.formatValue(saldoCambio.mensal.valor)}}</span></div>
				</v-col>

				<v-col md="1" xs="12" class="hide column">
					<div class=""><span>{{percentageHelper.formatPercentage(saldoCambio.doze.fatia)}}</span></div>
				</v-col>

				<v-col md="1" xs="12" class="hide column">
					<!-- <div><span>{{percentageHelper.formatPercentage(saldoCambio.doze.percentual)}}</span></div> -->
					---
				</v-col>

				<v-col md="2" xs="12" class="hide column">
					<div><span>{{currencyHelper.formatValue(saldoCambio.doze.valor)}}</span></div>
				</v-col>

				<v-col md="1" xs="12" class="hide column">
					<div class=""><span>{{percentageHelper.formatPercentage(saldoCambio.geral.fatia)}}</span></div>
				</v-col>

				<v-col md="1" xs="12" class="hide column">
					<!-- <div><span>{{percentageHelper.formatPercentage(saldoCambio.geral.percentual)}}</span></div> -->
					---
				</v-col>

				<v-col md="2" xs="12" class="hide column">
					<div><span>{{currencyHelper.formatValue(saldoCambio.geral.valor)}}</span></div>
				</v-col>
				</v-row>
			</div>
			</v-col>
		</v-row>


			</section>

			<div class="html2pdf__page-break" />

			<section slot="pdf-item" class="section">
				<v-row class="rowHeader">
					<div class="colHeader01">
						<v-img
							class="imagem_Kristal"
							alt=""
							:src="imagemPerfil()"
                      	></v-img>
					</div>
					<div class="colHeader02" >
						<span class="titleUserName"> {{userSelected.name}}</span>
					</div>
					<div class="colHeader03" >
						<div class="data">
							<div>
								<span>Pagina 3/8 {{ $hoje }}</span>
							</div>
							<div>
								<v-img src="../../../assets/icons/calendar_icon.svg" class="imagem_calendar"></v-img>
							</div>
						</div>
					</div>
				</v-row>

				<div class="rentabilidadeGraph panel">
					<div class="descricaotitulo">
          				<span> Rentabilidade </span>
        			</div>
        		   <!-- <MixedLine    :class="!checkDownloadPdf ? 'graph': 'graphPrint'" :input="dados" :aplicacao="aplicacao" />
				<MixedLine class="graphPrint" :input="dados" :aplicacao="aplicacao" />
				<MixedLine :style="graphStyles" :input="dados" :aplicacao="aplicacao" />
				-->
				<MixedLine  :input="acumulado" :aplicacao="aplicacao" />

        		</div>
			</section>

			<div class="html2pdf__page-break" />

			<section slot="pdf-item" class="section">
				<v-row class="rowHeader">
					<div class="colHeader01">
						<v-img
							class="imagem_Kristal"
							alt=""
							:src="imagemPerfil()"
                    	></v-img>
					</div>
					<div class="colHeader02" >
						<span class="titleUserName"> {{userSelected.name}}</span>
					</div>
					<div class="colHeader03" >
						<div class="data">
							<div>
								<span>Pagina 4/8 {{ $hoje }}</span>
							</div>
							<div>
								<v-img src="../../../assets/icons/calendar_icon.svg" class="imagem_calendar"></v-img>
							</div>
						</div>
					</div>
				</v-row>

				<div class="descricaotitulo">
          				<span>Liquidez </span>
				</div>
				<div>
					<DataTable
						:emErro="liqError"
						:errorMessage="liqMessage"
						:loading="loading"
						:calculos="liquidez" />
				</div>

				<div class="html2pdf__page-break" />

				<div>
					<v-row class="rowHeader">
						<div class="colHeader01">
							<v-img
								class="imagem_Kristal limit"
								alt=""
								:src="imagemPerfil()"
							></v-img>
						</div>
						<div class="colHeader02" >
							<span class="titleUserName"> {{userSelected.name}}</span>
						</div>
						<div class="colHeader03" >
							<div class="data">
								<div>
									<span> Pagina 5/8 {{ $hoje }}</span>
								</div>
								<div>
									<v-img src="../../../assets/icons/calendar_icon.svg" class="imagem_calendar"></v-img>
								</div>
							</div>
						</div>
					</v-row>

					<AlocacaoTotal :calculos="rentabilidade.calculos"
								   :dozeMeses="rentabilidade.dozeMeses" />
				</div>
			</section>

			<section slot="pdf-item" class="section">
				<v-row class="rowHeader">
					<div class="colHeader01">
						<v-img
							class="imagem_Kristal"
							alt=""
							:src="imagemPerfil()"
                    	></v-img>
					</div>
					<div class="colHeader02" >
						<span class="titleUserName"> {{userSelected.name}}</span>
					</div>
					<div class="colHeader03" >
						<div class="data">
							<div>
								<span> Pagina 6/8 {{ $hoje }}</span>
							</div>
							<div>
								<v-img src="../../../assets/icons/calendar_icon.svg" class="imagem_calendar"></v-img>
							</div>
						</div>
					</div>
				</v-row>

				<div>
					<AtribuicaoEstrategia :calculos="rentabilidade.calculos"
										  :dozeMeses="rentabilidade.dozeMeses" />
				</div>
			</section>

			<div class="html2pdf__page-break" />

			<section slot="pdf-item" class="section">
				<v-row class="rowHeader">
					<div class="colHeader01">
						<v-img
							class="imagem_Kristal limit"
							alt=""
							:src="imagemPerfil()"
                    	></v-img>
					</div>
					<div class="colHeader02" >
						<span class="titleUserName"> {{userSelected.name}}</span>
					</div>
					<div class="colHeader03" >
						<div class="data">
							<div>
								<span> Pagina 7/8 {{ $hoje }}</span>
							</div>
							<div>
								<v-img src="../../../assets/icons/calendar_icon.svg" class="imagem_calendar"></v-img>
							</div>
						</div>
					</div>
				</v-row>

				<div>
					<DesempenhoCarteira :calculos="rentabilidade.calculos"
										:dozeMeses="rentabilidade.dozeMeses" />
				</div>
			</section>

			<div class="html2pdf__page-break" />

			<section slot="pdf-item " class="section">
				<v-row class="rowHeader">
					<div class="colHeader01">
						<v-img
							class="imagem_Kristal"
							alt=""
							:src="imagemPerfil()"
                    	></v-img>
					</div>
					<div class="colHeader02" >
						<span class="titleUserName"> {{userSelected.name}}</span>
					</div>
					<div class="colHeader03" >
						<div class="data">
							<div>
								<span>Pagina 8/8 {{ $hoje }}</span>
							</div>
							<div>
								<v-img src="../../../assets/icons/calendar_icon.svg" class="imagem_calendar"></v-img>
							</div>
						</div>
					</div>
				</v-row>
				<div class="rentabilidadeTable panel">
					<div class="descricaotitulo">
          				<span> Histórico de Rentabilidade </span>
        			</div>

					<div class="rows" :key="atualizacao">
						<div class="row" v-for="item in dados" :key="item.ano">
							<div class="group ano">
								<div class="cell">
									<div>Mês</div>
									<div>{{ item.ano }}</div>
									<div>% {{ indice }}</div>
								</div>
							</div>
							<div class="group data">
								<div class="cell display" v-for="(child, index) in item.items" :key="index">
									<div>{{ months[index] }}</div>
									<div>{{ child && format(child.taxa) || '-' }}</div>
									<div>{{ item.comparado[index] && format(item.comparado[index].taxa) || '-' }}</div>
								</div>
							</div>
							<div class="group total">
								<div class="cell">
									<div>Anual</div>
									<div>{{ format(item.anual.carteira) }}</div>
									<div>{{ format(item.anual.comparado) }}</div>
								</div>
								<div class="cell">
									<div>Acum.</div>
									<div>{{ format(item.total.carteira) }}</div>
									<div>{{ format(item.total.comparado) }}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</template>
    </vue-html2pdf>
	</v-row>
    <!--    <section slot="pdf-content">

             <section class="pdf-item">

    </section>
    <section class="pdf-item">
        <v-row  v-for="item in items"  :key="item.idCotacao">
           {{item.data}}
          {{count}}
           <div v-if="getPageBreak()">
            <div class="html2pdf__page-break"/>
        </div>
        </v-row>
    </section>


     <div class="html2pdf__page-break"/>
     <section class="pdf-item">
        <v-data-table
            dense
            disable-sort

            :items="items"
            :items-per-page="100000"
            group-by="idAtivo"
            class="elevation-1">

        </v-data-table>
        <div v-if="getPageBreak()">
            <div class="html2pdf__page-break"/>
        </div>
    </section> -->
</div>
</template>
<script>
import VueHtml2pdf from 'vue-html2pdf';
import moment from "moment";
import ApiService from '../../../services/ApiService'
import CalculadorAtivo from "../../../services/Calculador";
import DataTable from "../Liquidez/components/DataTable.vue";
import AlocacaoTotal from "../Rentabilidade/components/alocacao-total.vue";
import AtribuicaoEstrategia from "../Rentabilidade/components/atribuicao-estrategia.vue";
import DesempenhoCarteira from "../Rentabilidade/components/desempenho-carteira.vue";

import currencyHelper from "../../../Utils/CurrencyHelper";
import percentageHelper from '../../../Utils/PercentageHelper';
import MixedLine from "../VisaoGeral/components/graph/MixedLine.vue" ;
import EventBus from '../../Bus/bus';
    export default {
      name: "ReportTables",
      data() {
		const now = new Date();
		const bef = new Date(now.getFullYear(), now.getMonth() - 1, 1);
		const trm = this.evolucao && this.evolucao.termino || bef.toISOString().substring(0, 10);
        const yer = +trm.substring(0, 4);
        const mth = +trm.substring(5, 7) - 1;
        const mdp = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"][mth] + `/${yer}`;
        this.monthLabel = mdp;
        return {
          filename:"pdf",
		  dados: [],
		  acumulado: [],
		  comparacao:[],
		  calculos:{},
		  wallet2: {},
		  aplicacao:"cdi",
          apiService: new ApiService(),
          calculador: new CalculadorAtivo().setDefaultLoaders(),
          currencyHelper,
          percentageHelper,
          evolucao: undefined,
          items: [],
          userSelected:{},
          headers2:[],
          searchAtivo: "",
          count:0,
          hasAlreadyParsed: false,
		    	progress: 0,
			    pdfWindow: null,
			    pdfFile: null,
          pdfContentWidth: "800px",
          panel: [0, 1],
          panel2: [0, 1],
          dessertsJurosPre: {items:[]},
          dessertsJurosPosCdi: {items:[]},
          dessertsJurosPosInflacao: {items:[]},
          dessertsLivre: {items:[]},
          dessertsRendaVariavel: {items:[]},
          dessertsCambio: {items:[]},
		  switchStateCDI:true,
   		  switchStateIPCA:false,
   		  switchStateDOLAR:false,
      	  switchStateIBOVESPA:false,
		  descTypeswitchState:'CDI',
		  months: [
                "Jan", "Fev", "Mar", "Abr",
                "Mai", "Jun", "Jul", "Ago",
                "Set", "Out", "Nov", "Dez"
            ],
          headers: [
            {
               text: "Ativo",
               align: "start",
               value: "descricao",
           },
          { text: "Saldo Bruto", value: "value" },
          { text: "", value: "fat" },
          ],
		  checkDownloadPdf:false,
		  widthGraph:"98%",
		   atualizacao: new Date().getTime(),
		  monthLabel: mdp,
		  showAll: false,
		  fillIndex: 0,
		  liqError: false,
		  liqMessage: '',
		  liquidez: [],
		  rentabilidade: {
			calculos: {
				alocacao: undefined
			},
			dozeMeses: []
		  }
        }
      },
      created() {
        //debugger
        EventBus.$emit("ListaDeUsuariosSelected", true);
		this.getInfos();
		EventBus.$on('reloadPagesForCallActivesAgain', this.ebReload);
      },
	  beforeDestroy () {
		EventBus.$off('reloadPagesForCallActivesAgain', this.ebReload);
	  },
      components: {
		VueHtml2pdf,MixedLine, AlocacaoTotal, AtribuicaoEstrategia, DesempenhoCarteira,
		DataTable
	  },
      props: {},
      methods: {
		ebReload (data) {
			if (!data) { return; }
			this.dados		= [];
			this.comparacao = [];
			this.getInfos();
		},
		imagemPerfil() {
			let user = this.$store.getters.$userLogged;
			return user.profileImage != null
				? `data:image/png;base64,${user.profileImage}`
				: require('@/assets/icons/pdfRel.svg');
        },
		process () {

          var _ret=this.apiService.getProcess(this.dados,this.calculos,this.comparacao,this.aplicacao, this.acumulado);
          this.dados = _ret.dados;
          this.comparacao = _ret.comparacao;
		  this.atualizacao = new Date().getTime();
        },
		format (v) {
            return v === null || v == undefined
                ? ""
                : percentageHelper.formatPercentage(v / 100);
        },
		changeSwitch(e) {
		  (e !==      'CDI') && (this.switchStateCDI      = false);
		  (e !==     'IPCA') && (this.switchStateIPCA     = false);
		  (e !==    'DOLAR') && (this.switchStateDOLAR    = false);
		  (e !== 'IBOVESPA') && (this.switchStateIBOVESPA = false);
		  let active = (this.switchStateCDI || this.switchStateIPCA
					|| this.switchStateDOLAR || this.switchStateIBOVESPA);
		  let key = active ? e : '';
		 // this.submitKeyChange(key);
		  this.evolucao.updateTo(e);
		  this.aplicacao = key.toLowerCase();
		  this.descTypeswitchState=e;
		  this.updateCotacoes()
		  this.process();


		  return e;
		},
		updateCotacoes () {
		  this.dessertsJurosPre
			&& this.dessertsJurosPre.items
			&& this.dessertsJurosPre.items.forEach(this.createFiller("estrategia", "jurosPre"));
		  this.dessertsJurosPosCdi
			&& this.dessertsJurosPosCdi.items
			&& this.dessertsJurosPosCdi.items.forEach(this.createFiller("estrategia", "jurosPosCdi"));
		  this.dessertsJurosPosInflacao
			&& this.dessertsJurosPosInflacao.items
			&& this.dessertsJurosPosInflacao.items.forEach(this.createFiller("estrategia", "jurosPosInflacao"));
		  this.dessertsLivre
			&& this.dessertsLivre.items
			&& this.dessertsLivre.items.forEach(this.createFiller("estrategia", "livre"));
		  this.dessertsRendaVariavel
			&& this.dessertsRendaVariavel.items
			&& this.dessertsRendaVariavel.items.forEach(this.createFiller("estrategia", "rendaVariavel"));
		  this.dessertsCambio
			&& this.dessertsCambio.items
			&& this.dessertsCambio.items.forEach(this.createFiller("estrategia", "cambio"));

		  this.dessertsCdbRdb
			&& this.dessertsCdbRdb.items
			&& this.dessertsCdbRdb.items.forEach(this.createFiller("produto", "cdbRdb"));
		  this.dessertsCriCra
			&& this.dessertsCriCra.items
			&& this.dessertsCriCra.items.forEach(this.createFiller("produto", "criCra"));
		  this.dessertsDebentures
			&& this.dessertsDebentures.items
			&& this.dessertsDebentures.items.forEach(this.createFiller("produto", "debentures"));
		  this.dessertsLciLca
			&& this.dessertsLciLca.items
			&& this.dessertsLciLca.items.forEach(this.createFiller("produto", "lciLca"));
		  this.dessertsTitulosPublicos
			&& this.dessertsTitulosPublicos.items
			&& this.dessertsTitulosPublicos.items.forEach(this.createFiller("produto", "titulos"));
		  this.dessertsAcoes
			&& this.dessertsAcoes.items
			&& this.dessertsAcoes.items.forEach(this.createFiller("produto", "acoes"));
		  this.dessertsFundosImobiliarios
			&& this.dessertsFundosImobiliarios.items
			&& this.dessertsFundosImobiliarios.items.forEach(this.createFiller("produto", "fundosImobiliarios"));
		  this.dessertsFundosInvestimento
			&& this.dessertsFundosInvestimento.items
			&& this.dessertsFundosInvestimento.items.forEach(this.createFiller("produto", "fundosInvestimento"));
		  this.dessertsPrevidencia
			&& this.dessertsPrevidencia.items
			&& this.dessertsPrevidencia.items.forEach(this.createFiller("produto", "previdencia"));
		},

        createFiller(tipo, name) {
			return (e, i) => {
				if (!this.evolucao[tipo][name].items.length) { return; }
				let item      = this.evolucao[tipo][name].items[i];
				e.value       = item.evolucao.saldo || 0.0;
				e.percentege  = item.evolucao.participacao || 0.0;
				e.mesAtual    = item.evolucao.mensal.ganho;
				e.percent1    = item.evolucao.mensal.percentual;
				e.valor1      = item.evolucao.mensal.valor;
				e.dozeMeses   = item.evolucao.doze.ganho;
				e.percent2    = item.evolucao.doze.percentual;
				e.valor2      = item.evolucao.doze.valor;
				e.desdeInicio = item.evolucao.geral.ganho;
				e.percent3    = item.evolucao.geral.percentual;
				e.valor3      = item.evolucao.geral.valor;
				const head    = `${++this.fillIndex} - `;
				if (!new RegExp('^' + head).test(e.title)) {
					e.title   = `${head}${e.title}`;
				}
				e.title2      = this.getTitle2(e);
			};
		},
		getTitle2(item){
		  	if (item.idTProduto ==7){
				return item.title+' - '+item.descricao;
			}else   if (item.idTProduto ==8){
				return item.descricao+' - '+item.title ;
			}else{
				return item.title;
			}
        },

		getRentabilidade () {
			return this.apiService.getUserToAtivoUserRentabilidade(false,this.$store)
      			.then((res) => {
					this.rentabilidade.calculos = res.calculos;
					this.rentabilidade.dozeMeses =res.dozeMeses;
					this.loading = false;
				})
				.catch((reason) => {
					this.loading = false;
				});
		},
		getLiquidez () {
			this.liqError = false;
			this.liqMessage = '';
			return this.apiService.getUserToAtivoUserLiquidez(this.$store)
				.then((res) => {
					this.liquidez = res.evolucao.collection;
					this.loading = false;
				})
				.catch((reason) => {
					this.liqError = true;
					this.liqMessage = reason ?
						reason.message || reason.Error || `${reason}` :
						'Erro não identificado';
				});
		},

        getInfos() {
            this.loading = true;
			this.userSelected=this.$store.getters.$userSelected;
		    this.filename= this.userSelected.name;
            this.apiService.getUserToAtivoUser(true,this.$store)
                .then((res) => {
                    this.evolucao = this.calculador.wrap(res.evolucao);
					this.evolucao.updateTo('cdi');
                    try {
						const trm = this.evolucao.termino;
                        const yer = +trm.substring(0, 4);
                        const mth = +trm.substring(5, 7) - 1;
                        const mdp = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"][mth] + `/${yer}`;
                        this.monthLabel = mdp;
					} catch (e) {
						//console.error(e);
					}

					this.fillIndex = 0;
                    if (res.content.byStrategy) {
                        res.content.jurosPre.items.forEach(this.createFiller("estrategia", "jurosPre"));
                        res.content.jurosPosCdi.items.forEach(this.createFiller("estrategia", "jurosPosCdi"));
                        res.content.jurosPosInflacao.items.forEach(this.createFiller("estrategia", "jurosPosInflacao"));
                        res.content.livre.items.forEach(this.createFiller("estrategia", "livre"));
                        res.content.rendaVariavel.items.forEach(this.createFiller("estrategia", "rendaVariavel"));
                        res.content.cambio.items.forEach(this.createFiller("estrategia", "cambio"));

                        this.dessertsJurosPre = res.content.jurosPre;
						this.dessertsJurosPosCdi = res.content.jurosPosCdi;
						this.dessertsJurosPosInflacao = res.content.jurosPosInflacao;
						this.dessertsLivre = res.content.livre;
						this.dessertsRendaVariavel = res.content.rendaVariavel;
						this.dessertsCambio = res.content.cambio;
						this.wallet2 = res.content.all.items;
						this.calculos = this.calculador
										.refresh()
										.rentabilidadeFull(res.content.all.items);

					} else {
						res.content.cdbRdb.items.forEach(this.createFiller("produto", "cdbRdb"));
						res.content.criCra.items.forEach(this.createFiller("produto", "criCra"));
						res.content.debentures.items.forEach(this.createFiller("produto", "debentures"));
						res.content.lciLca.items.forEach(this.createFiller("produto", "lciLca"));
						res.content.titulos.items.forEach(this.createFiller("produto", "titulos"));
						res.content.acoes.items.forEach(this.createFiller("produto", "acoes"));
						res.content.fundosImobiliarios.items.forEach(this.createFiller("produto", "fundosImobiliarios"));
						res.content.fundosInvestimento.items.forEach(this.createFiller("produto", "fundosInvestimento"));
						res.content.previdencia.items.forEach(this.createFiller("produto", "previdencia"));
						this.dessertsCdbRdb = res.content.cdbRdb;
						this.dessertsCriCra = res.content.criCra;
						this.dessertsDebentures = res.content.debentures;
						this.dessertsLciLca = res.content.lciLca;
						this.dessertsTitulosPublicos = res.content.titulos;
						this.dessertsAcoes = res.content.acoes;
						this.dessertsFundosImobiliarios = res.content.fundosImobiliarios;
						this.dessertsFundosInvestimento = res.content.fundosInvestimento;
						this.dessertsPrevidencia = res.content.previdencia;
						this.wallet2 = res.content.all;
						/*	this.calculos = this.calculador
                            .refresh()
                            .rentabilidadeFull(res.content.all);*/
          			}
          // console.log(JSON.stringify(this.evolucao));
		  this.process();
            //this.loading = false;
			return this.getRentabilidade().then(() => this.getLiquidez());
          })
          .catch((error) => {
            //window["console"]["log"](error);
            this.loading = false;
          });
    	},

        generateReport () {

            this.$refs.html2Pdf.generatePdf()
        } ,
        getPageBreak(){
            this.count++;
            if(this.count ==20){
                this.count=0;
                return true;
            }else{
                return false;
            }

        },
		async downloadPdf() {
			this.checkDownloadPdf = true;
			this.widthGraph="65%";
			await this.generatePdf()

        },

		async generatePdf() {
     		 if (!(await this.validateControlValue())) return;
			       this.$refs.html2Pdf.generatePdf();
    	},

		hasGenerated() {
         alert("PDF generated successfully!");
        },
        validateControlValue() {



     /* if (!this.controlValue.paginateElementsByHeight) {
        alert("paginate-elements-by-height value cannot be empty");
        this.controlValue.paginateElementsByHeight = 1400;

        return false;
      }*/

      const paperSizes = [
        "a0",
        "a1",
        "a2",
        "a3",
        "a4",
        "letter",
        "legal",
        "a5",
        "a6",
        "a7",
        "a8",
        "a9",
        "a10",
      ];






      return true;
        },

        onProgress(progress) {
      this.progress = progress;
      //console.log(`PDF generation progress: ${progress}%`)
	     if (progress==100){
			this.checkDownloadPdf=false;
			this.widthGraph="98%";
		 }
        },

        startPagination() {
			[].forEach.apply(document.querySelectorAll('.dados .celulas .dado'),
				[(e) =>e.style.height = '11vh']);
      //console.log(`PDF has started pagination startPagination`)
        },

        hasPaginated () {
      //console.log(`PDF has been paginated hasPaginated`)
        },


		async beforeDownload ({ html2pdf, options, pdfContent }) {
			//console.log(`On Before PDF Generation beforeDownload`)
           /* await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
                const totalPages = pdf.internal.getNumberOfPages()
				console.log(`On Before PDF Generation totalPages:`+totalPages)
                for (let i = 1; i <= totalPages; i++) {
                    pdf.setPage(i)
                    pdf.setFontSize(10)
                    pdf.setTextColor(150)
                    pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
                }
            }).save()*/
        },

        hasDownloaded (blobPdf) {
      //console.log(`PDF has downloaded yehey hasDownloaded`)
      this.pdfDownloaded = true
      //console.log(blobPdf)
        },

        domRendered() {
      //console.log("Dom Has Rendered domRendered");
      this.contentRendered = true;
        },

        onBlobGenerate(blob) {
        //console.log(blob);
        },

		displayGroup (grp) {
			return this.showAll || (
				grp.items &&
				grp.items.length &&
				(grp.items.filter(a => Math.abs(a.value) > 1E-3).length > 0));
		},

		filterGroup (grp) {
			return this.showAll && grp.items || grp.items.filter((a) => Math.abs(a.value) > 1E-3);
		},
		getEvoTotalPath(path) {
			let container = this.evolucao && this.evolucao.total;
			if (!container) { return 0; }
			const parts = path.split(/\./g);
			for (let i =0; i < parts.length; ++i) {
				const part = parts[i];
				container  = container[part];
				if (!container) { return 0; }
			}
			return container || 0;
		}
      },
      computed: {
		graphStyles(){
			return {
                width: `${this.widthGraph}`
      			};

		},
		indice: {
            get () {
                return this.aplicacao && this.aplicacao.toUpperCase() || '';
            }
        },
        $hoje() {
           let hoje = Date.now();
            return moment(hoje).format("DD/MM/YYYY HH:mm");
        },
		evoTotal() {
     		return this.evolucao && this.evolucao.total && this.evolucao.total.mensal || {
        		saldo: 0,
        		mensal: { ganho: 0, fatia: 0, percentual: 0, valor: 0 },
        		doze: { ganho: 0, fatia: 0, percentual: 0, valor: 0 },
        		geral: { ganho: 0, fatia: 0, percentual: 0, valor: 0 },
				ano: { ganho: 0, fatia: 0, percentual: 0, valor: 0 },
				cotacoes: {
					cdi: { mensal: 0, doze: 0, ano: 0, geral: 0 },
					ipca: { mensal: 0, doze: 0, ano: 0, geral: 0 },
					dolar: { mensal: 0, doze: 0, ano: 0, geral: 0 },
					ibovespa: { mensal: 0, doze: 0, ano: 0, geral: 0 }
				}
      		}
    	},

		totalParticipacao() {
		  return this.dessertsJurosPre && this.dessertsJurosPre.total && this.dessertsJurosPre.total.participacao || 0;
		},
		totalSaldo() {
		  return this.dessertsJurosPre && this.dessertsJurosPre.total && this.dessertsJurosPre.total.saldo || 0;
		},
		mensalGanho() {
		  return this.evolucao && this.evolucao.total && this.evolucao.total.mensal.fatia || 0;
		},
		mensalPercent() {
		  return this.evolucao && this.evolucao.total && this.evolucao.total.mensal.percentual || 0;
		},
		mensalValor() {
		  return this.evolucao && this.evolucao.total && this.evolucao.total.mensal.valor || 0;
		},
		dozeGanho() {
		  return this.evolucao && this.evolucao.total && this.evolucao.total.doze.fatia || 0;
		},
		dozePercent() {
		  return this.evolucao && this.evolucao.total && this.evolucao.total.doze.percentual || 0;
		},
		dozeValor() {
		  return this.evolucao && this.evolucao.total && this.evolucao.total.doze.valor || 0;
		},
		geralGanho() {
		  return this.evolucao && this.evolucao.total && this.evolucao.total.geral.fatia || 0;
		},
		geralPercent() {
		  return this.evolucao && this.evolucao.total && this.evolucao.total.geral.percentual || 0;
		},
		geralValor() {
		  return this.evolucao && this.evolucao.total && this.evolucao.total.geral.valor || 0;
    	},
		saldoJurosPre () {
			return this.evolucao
			    && this.evolucao.estrategia
				&& this.evolucao.estrategia.jurosPre
				&& this.evolucao.estrategia.jurosPre.total
				|| {
        		saldo: 0,
        		mensal: { ganho: 0, fatia: 0, percentual: 0, valor: 0 },
        		doze: { ganho: 0, fatia: 0, percentual: 0, valor: 0 },
        		geral: { ganho: 0, fatia: 0, percentual: 0, valor: 0 },
      		};
		},
		saldoJurosPosCdi () {
			return this.evolucao
			    && this.evolucao.estrategia
				&& this.evolucao.estrategia.jurosPosCdi
				&& this.evolucao.estrategia.jurosPosCdi.total
				|| {
        		saldo: 0,
        		mensal: { ganho: 0, fatia: 0, percentual: 0, valor: 0 },
        		doze: { ganho: 0, fatia: 0, percentual: 0, valor: 0 },
        		geral: { ganho: 0, fatia: 0, percentual: 0, valor: 0 },
      		};
		},
		saldoJurosPosInflacao () {
			return this.evolucao
			    && this.evolucao.estrategia
				&& this.evolucao.estrategia.jurosPosInflacao
				&& this.evolucao.estrategia.jurosPosInflacao.total
				|| {
        		saldo: 0,
        		mensal: { ganho: 0, fatia: 0, percentual: 0, valor: 0 },
        		doze: { ganho: 0, fatia: 0, percentual: 0, valor: 0 },
        		geral: { ganho: 0, fatia: 0, percentual: 0, valor: 0 },
      		};
		},
		saldoRendaVariavel () {
			return this.evolucao
			    && this.evolucao.estrategia
				&& this.evolucao.estrategia.rendaVariavel
				&& this.evolucao.estrategia.rendaVariavel.total
				|| {
        		saldo: 0,
        		mensal: { ganho: 0, fatia: 0, percentual: 0, valor: 0 },
        		doze: { ganho: 0, fatia: 0, percentual: 0, valor: 0 },
        		geral: { ganho: 0, fatia: 0, percentual: 0, valor: 0 },
      		};
		},
		saldoCambio () {
			return this.evolucao
			    && this.evolucao.estrategia
				&& this.evolucao.estrategia.cambio
				&& this.evolucao.estrategia.cambio.total
				|| {
        		saldo: 0,
        		mensal: { ganho: 0, fatia: 0, percentual: 0, valor: 0 },
        		doze: { ganho: 0, fatia: 0, percentual: 0, valor: 0 },
        		geral: { ganho: 0, fatia: 0, percentual: 0, valor: 0 },
      		};
		},
		saldoLivre () {
			return this.evolucao
			    && this.evolucao.estrategia
				&& this.evolucao.estrategia.livre
				&& this.evolucao.estrategia.livre.total
				|| {
        		saldo: 0,
        		mensal: { ganho: 0, fatia: 0, percentual: 0, valor: 0 },
        		doze: { ganho: 0, fatia: 0, percentual: 0, valor: 0 },
        		geral: { ganho: 0, fatia: 0, percentual: 0, valor: 0 },
      		};
		},
		evolucaoSaldo () {
			return this.evolucao
				&& this.evolucao.total
				&& this.evolucao.total.saldo
				|| 0;
		},
		evolucaoLiquido () {
			return this.evolucao
				&& this.evolucao.total
				&& this.evolucao.total.liquido
				|| 0;
		},
		evolucaoGanho () {
			return this.evolucao
				&& this.evolucao.total
				&& this.evolucao.total.geral.ganho
				|| 0;
		},
		evolucaoRendimento () {
			return this.evolucao
				&& this.evolucao.total
				&& this.evolucao.total.geral
				&& this.evolucao.total.geral.valor
				|| 0;
		},
        htmlToPdfOptions() {
                return {
					margin: 0.1,
                    filename: this.filename,

                    image: {
                        type: "jpeg",
                        quality: 0.98,
                    },

                    enableLinks: true,

                    html2canvas: {
                        scale: 2,
                        useCORS: true,
                    },

                    jsPDF: {
                        unit: "in",
                        format: "a4",
                        orientation: "landscape",
                    },
                };
        },
      },
      watch: {}
    }
</script>
<style  scoped>
	.html2pdf{
	  margin-top: 1%;
	  margin-bottom: 1%;
	}
	 .divpdf{
		/*border: 2px solid  red;*/
		width: 100% !important;

		margin: 0 0 0 0 ;
		padding: 0 0 0 0 ;
		padding-left:calc((100% - 1103px)/2) ;

	 }
	.section{
		border: 1px solid #d7d7d7;
		background: white;
		border-radius: 20px;
		margin: 0 0 0 0 ;
		padding: 0 0 0 0 ;
		padding-top: 1%;
		margin-bottom: 2%;
		padding-bottom: 1%;
	}
	.section .rowHeader{
	width: 100% !important;
	margin: 0 0 0 0 ;
	}
	.section .rowHeader .colHeader01{
		width: 20% !important;
	}
	.section .rowHeader .colHeader02{
		width: 70% !important;
		display: flex;
		align-items: center;
		justify-content: center;
		max-width: 100vw;
		flex-wrap: wrap;
		margin-top: 1rem;
		margin-bottom: 0.5rem;
		font-size: 25px;
	}
	.section .rowHeader .colHeader03{
		width: 10% !important;
	}
	.section .rowSaldos{
		width: 100% !important;
		margin: 0 0 0 0 ;
	}
	.section .rowSaldos .card{
		width: 24% !important;
		border: 2px solid  rgba(0, 0, 0, 0.05);
		margin: 0 0 0 0 ;
		background: white;
		padding: 0.8rem;
		border-radius: 15px;
		display: flex;
		flex-direction: column;
		margin: 0.5%;
		box-shadow: -4px 0px 10px rgba(0, 0, 0, 0.05);
	}
	.section .rowSaldos .card .title {
		font-size: 15px !important;
		line-height: 22px;
		letter-spacing: 0.15px;
		padding-bottom: 0.5rem;
	}
	.section .rowSaldos .card .valor {
		font-weight: normal;
		font-size: 25px;
		line-height: 37px;
	}
	.section .rowPortifolio{
		width: 100% !important;
		margin: 0 0 0 0 ;
	}
	.section .rowPortifolio .card{
		width: 19% !important;
		border: 2px solid  rgba(0, 0, 0, 0.05);
		margin: 0 0 0 0 ;
		background: white;
		padding: 0.8rem;
		border-radius: 15px;
		display: flex;
		flex-direction: column;
		margin: 0.5%;
		box-shadow: -4px 0px 10px rgba(0, 0, 0, 0.05);
		align-items: center;
		justify-content: center;
	}
	.section .rowPortifolio .card .title {
		font-size: 15px !important;
		line-height: 16px;
		letter-spacing: 0.15px;
		padding-bottom: 0.5rem;
	}
	.section .rowPortifolio .card .valor {
		font-weight: normal;
		font-size: 20px;
		line-height: 45px;;
	}
	.section .rowSwitch{
		/*border: 2px solid  red;*/
		width: 99% !important;
		margin: 0 0 0 0 ;
		margin-left: 0.5%;
		margin-right: 0.5%;
	}
	.rowSwitch .btns-ativos {
	  display: flex;
	  flex-wrap: wrap;
	}
	.rowSwitch .btns-ativos > div {
	  margin: 0 0.3rem;
	}
	.rowSwitch .btns-ativos > div.switch-view-all {
	  margin: 1.33rem;
	}
	.rowSwitch .btns-ativos > div span {
	  padding: 0 0.8rem;
	}

	@media screen and (max-width: 950px) {
		.rowSwitch  .btns-ativos > div {
		flex: 1 1 150px;
	  }
	  .rowSwitch  .btn-evolucao {
		margin-bottom: 0.5rem;
	  }
	}
	.section .rowLinha {
		width: 98% !important;
		margin: 0 0 0 0 ;
		margin-top: 0.2%;
		margin-left: 0.5%;
		margin-right: 0.5%;
		border-radius: 10px;
	}
	.rowLinha .coluna {
		margin: 0 !important;
		padding: 0.3rem !important;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
	}
	.rowLinha .coluna > div {
		width: 100%;
		height: 100%;
		padding: 0.3rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.rowLinha .coluna > div span {
		font-size: 0.9rem;
	}
	.rowLinha .coluna .descricao{
		font-weight: bold;
		font-size: 35px !important;
	}

	.descricaotitulo{
		font-weight: 600;
	    font-size: 22px;
		line-height: 33px;
		letter-spacing: 0.15px;
		color: #000000;
		padding-left: 1rem;
		padding-top: 2rem;
		padding-bottom: 2rem;
	}
	.rowLinhaHeader{
		width: 98% !important;
		margin: 0 0 0 0 ;

		margin-left: 0%;
		margin-right: 0%;
		border-radius: 10px;
	}
	.rowLinhaHeader .coluna {
		margin: 0 !important;
		padding: 0.5rem !important;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
	}
	.rowLinhaHeader .coluna > div {
		background: white;
		border-radius: 10px;
		width: 100%;
		height: 100%;
		padding: 0.3rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.rowLinhaHeader .coluna-composta {
		display: flex;
		flex-direction: column;
	}
	.rowLinhaHeader .coluna-composta .descricao {
		border-radius: 5px 5px 0 0 !important;
	}
	.rowLinhaHeader .coluna-composta .descricao span {
		border-bottom: 1px solid #a4a4a4;
		width: 98%;
	}
	.rowLinhaHeader .coluna-composta .colunas {
		border-radius: 0 0 10px 10px !important;
	}
	.rowLinhaHeader .colunas {
		font-weight: bold;
	}
	.rowLinhaHeader .colunas .mes {
		color: #a9d08e;
	}
	.rowLinhaHeader .colunas .meses-12 {
		color: #f3a03c;
	}
	.rowLinhaHeader .colunas .inicio {
		color: var(--principal-azul);
	}

	.row-expansion{
		width: 98% !important;
		margin: 0 0 0 0 ;

		margin-left: 0.5%;
		margin-right: 0.5%;
		border-bottom: 2px solid #e4e4e4;
	}
	.row-expansion .col {
	  padding: 0 0.6rem !important;
	  display: flex;
	  align-items: center;
	  justify-content: space-between;
	  text-align: center;
	}

	.row-expansion .col > div {
	  background: white;
	  width: 100% !important;
	  height: 100%;
	  padding: 0.3rem;
	  display: flex;
	  align-items: center;
	  justify-content: center;


	}
	.row-expansion .col > div span {
	  font-size: 0.79rem;
	}
	.row-expansion .col:first-child {
	  justify-content: flex-start !important;
	  padding-left: 0.2rem !important;
	}
	.row-expansion .col:last-child {
	  padding-right: 0 !important;
	}
	.row-expansion:last-child .col > div {
	  border-radius: 0 0 20px 20px;
	}

	.row-expansion:last-child .coluna .colunas .row-expansion-2 .coluna-2 {
	  overflow: hidden;
	}
	.row-expansion:last-child .coluna .colunas .row-expansion-2 .coluna-2 > div {
	  border: none !important;
	  border-radius: none !important;
	}

	.tborder { border: 2px solid red;}


	.v-data-table {
		border-radius: 20px;
		width:100%;
		height:100%;

		/*color: red;
		text-align: center ;
		padding: 1rem;*/
		box-shadow: none !important;
	}
	.v-data-table::v-deep .v-data-table__wrapper::-webkit-scrollbar {
		width: 8px !important;
		border-radius: 20px !important;
	}
	.v-data-table::v-deep .v-data-table__wrapper::-webkit-scrollbar-track {
		/* background: #f1f1f1 !important;*/
		border-radius: 20px;
	}
	.v-data-table::v-deep .v-data-table__wrapper::-webkit-scrollbar-thumb {
		/* background: rgb(197, 196, 196) !important;*/
		border-radius: 20px;
	}
	.titleUserName{
		display: flex;
		align-items: center;
		justify-content: space-between;
		max-width: 100vw;
		flex-wrap: wrap;
		margin-top: 1rem;
		margin-bottom: 0.5rem;
		font-size: 25px;
	}
	.carteira {
		padding: 1rem;
	}
	.search-bar .v-text-field {
		padding: 0 0.8rem;
	}
	.v-text-field >>> fieldset {
		border-color: transparent !important;
		box-shadow: -4px 0px 10px rgba(0, 0, 0, 0.05);
	}
	.titles span {
		font-weight: 600;
		font-size: 20px;
		line-height: 30px;
		letter-spacing: 0.15px;
	}
	.teste .valor .saldo {
		font-weight: 300 !important;
		font-size: 15px !important;
		line-height: 22px !important;
		/* identical to box height */

		letter-spacing: 0.15px !important;
	}
	.teste .valor .value {
		font-weight: 600 !important;
		font-size: 20px !important;
		line-height: 30px !important;
		letter-spacing: 0.15px !important;
	}

	.v-expansion-panel {
		/* border-radius: 20px 20px 20px 20px !important; */
		color: black !important;
		border-radius: 15px !important;
		box-shadow: -4px 0px 10px rgba(0, 0, 0, 0.05);
		/* border: 1px solid red !important; */
	}
	.v-expansion-panel--active {
		border-radius: 15px !important;
		/* border: 1px solid blue !important; */
	}

	.v-expansion-panel-header {
		border-radius: 15px !important;
		transition: 0.2s ease-in-out;
	}
	.v-expansion-panel-header--active {
		border-radius: 15px 15px 0 0 !important;
	}
	/*.v-expansion-panel {
	margin-top: 5rem !important;
	}*/
	.v-expansion-panel-content {
		padding: 0 !important;
		margin: 0 !important;
	}
	#content-expansion::v-deep .v-expansion-panel-content__wrap {
		padding: 0 !important;
	}
	.slot-btns {
		display: flex;
	}
	.slot-btns .btn-icon {
		width: 5px;
		margin-right: 0.7rem;
		object-fit: cover;
		cursor: pointer;
	}

	.v-expansion-panel-header .teste {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.table {
		width: 100% !important;
		padding: 0 !important;
		margin: 0 !important;
	}
	.teste .valor {
		font-size: 12px;
		font-weight: 200;
	}
	.teste .valor .value {
		font-weight: 500;
		font-size: 14px;
	}
	/*.panel {
	border: 1px solid red;
	}*/
	.header-carteira .coluna {
		background: #1296E3;
		border-radius: 10px;
	}
	.header-carteira .coluna-composta {
		background: white;
	}

	.header-prefixo .coluna {
		background: #29BD1A;
		border-radius: 10px;
	}
	.header-prefixo .coluna-composta {
		background: white;
	}
	.header-posinflacao .coluna {
		background: #2679ED;
		border-radius: 10px;
	}
	.header-posinflacao .coluna-composta {
		background: white;
	}
	.header-cambio .coluna{
		background: #C520FF;
		border-radius: 10px;
	}
	.header-cambio .coluna-composta{
		background: white;
	}
	.header-precdi .coluna {
		background: #35BAFC;
		border-radius: 10px;
	}
	.header-precdi .coluna-composta{
		background: white;
	}
	.header-rendavariavel .coluna {
		background: #FA5807;
		border-radius: 10px;
	}
	.header-rendavariavel .coluna-composta{
		background: white;
	}
	.header-livres .coluna{
		background: #FAA90F;
		border-radius: 10px;
	}
	.header-livres .coluna-composta{
		background: white;
	}
	.v-data-table {
		overflow-y: auto;
		padding-bottom: 0.5rem !important;
		border-radius: 0 0 15px 15px !important;
	}
	.v-data-table::v-deep .v-data-table__wrapper::-webkit-scrollbar {
		width: 8px !important;
		border-radius: 20px !important;
	}
	.v-data-table::v-deep .v-data-table__wrapper::-webkit-scrollbar-track {
		background: #f1f1f1 !important;
	}
	.v-data-table::v-deep .v-data-table__wrapper::-webkit-scrollbar-thumb {
		background: rgb(197, 196, 196) !important;
		border-radius: 20px;
	}
	.v-data-table >>> th {
		padding-top: 1.5rem !important;
		padding-bottom: 1.5rem !important;
	}
	.v-data-table >>> td {
		/* padding: .5rem 0 !important; */
		padding-top: 1rem !important;
		padding-bottom: 1rem !important;
	}
	.card-dialog {
		text-align: center;
		padding: 2rem 0;
		position: relative;
	}
	.btn-close-dialog {
		position: absolute;
		width: 20px;
		height: 20px;
		right: 25px;
		top: 25px;
		cursor: pointer;
	}
	.card-dialog .text-h5 {
		text-align: center !important;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.descricao-dialog {
		margin: 0.5rem 0;
	}
	.btn-delete-dialog {
		background: #f25a5a;
		border-radius: 20px;
		padding: 0 1rem !important;
	}
	.btn-delete-dialog span {
		color: white;
	}
	.btn-cancel-dialog {
		border-radius: 20px;
		padding: 0 1rem !important;
		border: 1px solid #e4e4e4;
	}
	.btn-cancel-dialog span {
		color: #e4e4e4;
	}

	@media screen and (max-width: 585px) {
		.btn-icon {
			width: 20px !important;
		}
	}

	@media screen and (min-width: 945px) {
		.btn-icon {
			width: 10px !important;
		}
	}

	@media screen and (max-width: 700px) {
		.panel .titles span {
			font-size: 1rem !important;
		}
		.panel .valor .value {
			font-size: 1.2rem !important;
		}
	}
	.data {
		display: flex;
	}
	.data >div {
		margin:  0 .2rem;
	}
	.data .imagem_calendar{
		filter: invert(70%) sepia(26%) saturate(8645%) hue-rotate(195deg) brightness(105%) contrast(100%);
	}
	.imagem_Kristal{
		width:7em;
	}
	.imagem_Kristal.limit {
		margin-top: 10px;
		width: 4em;
	}

	.divImagemPrinting{
		width:100%;
		height:0px;
	}
	.buttonImagemPrinting{
		position: absolute;
		width: 50px;
		top: 60px;
		right: 100px;
	}

	.rentabilidadeTable,
.rentabilidadeGraph{
	width: 98% !important;
		margin: 0 0 0 0 ;

		margin-left: 0.5%;
		margin-right: 0.5%;

}
.rentabilidadeGraph .graph{
	width: 100% !important;

}
.rentabilidadeGraph .graphPrint{
	width: 65% !important;

}
.rentabilidade .controls {
    clear: both;
}
.rentabilidade .controls .btns-ativos {
    display: flex;
    flex-direction: row;
    clear: both;
}
.rentabilidadeTable .rows {
    display: flex;
    flex-direction: column;
    margin: 1em 0.5em;
    padding: 0;
}
.rentabilidadeTable .btns-ativos {
    display: flex;
    flex-direction: row;
}
.rentabilidadeTable .rows .row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin: 0;
    padding: 0;
    border: 1px solid black;
}
.rentabilidadeTable .rows .row .group {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    flex: 1;

}
.rentabilidadeTable .rows .row .group.data .cell {
    flex: 1;

}
.rentabilidadeTable .rows .row .group .cell {
    display: flex;
    flex-direction: column;
    margin: 0.25em 0.5em;
    padding: 0;
    font-size: 0.75em;
    flex: 1;

}
.rentabilidadeTable .rows .row .group .cell div:nth-child(1) {
    font-weight: bold;
}
.rentabilidadeTable .rows .row .group:nth-child(1),
.rentabilidadeTable .rows .row .group.ano {
    flex-direction: row;
    flex: 1.3;

}
.rentabilidadeTable .rows .row .group.total {
    flex-direction: row;
    flex: 2;

}
.rentabilidadeTable .rows .row .group.data {
    border-style: solid;
    border-color: black;
    border-width: 0px 1px;
    flex: 12;

}

</style>