<template>
  <div>
    <div
      class="text--secondary d-flex justify-start align-center my-3"
      style="width: 100%"
    >
      <v-btn class="ml-4" icon href="/exams">
        <v-icon color="#ccc">mdi-chevron-left</v-icon>
        <span style="font-size: 0.7rem; color: #999">inicio</span>
      </v-btn>
    </div>
    <div class="mt-5 mx-5">
      <h2 style="color: #f78129">INFORMAÇÕES FINANCEIRAS</h2>
    </div>
    <v-row class="mx-2 my-4" v-if="loading">
      <v-col class="my-5 d-flex justify-center align-center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-col>
    </v-row>
    <v-row class="mx-2 my-4">
      <v-col
        class="mb-5 mx-1 pa-0"
        justify="center"
        v-for="exam in exams"
        :key="exam.code"
      >
        <FinanceExam :exam="exam" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import FinanceExam from "./FinanceExam.vue";
import ApiService from "../../../../services/ApiService";

export default {
  name: "FinanceExamList",

  data: () => ({
    exams: [],
    apiService: new ApiService(),
    loading: false,
  }),

  created() {
    this.searchExams();
  },

  components: {
    FinanceExam,
  },

  methods: {
    searchExams() {
      this.loading = true;

      const user = JSON.parse(sessionStorage.getItem("user"));

      this.apiService
        .post("user/exams", user)
        .then((response) => {
          this.loading = false;
          this.exams = response;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
