<template>
  <v-hover>
    <template v-slot:default="{ hover }">
      <v-card
        id="exam"
        min-width="256"
        min-height="180"
        :elevation="hover ? 15 : 5"
        class="ma-0 transition-swing pt-2"
      >
        <v-card-title class="headline mb-2">
          <h6 style="color: #f78129">{{ exam.codigoCompra }}</h6>
          <v-spacer></v-spacer>
          <v-icon color="primary" small>mdi-plus</v-icon>
        </v-card-title>
        <v-card-subtitle
          style="font-weight: bold; font-size: 0.65rem"
          class="pb-1"
        >
          {{ exam.codigoStatus | formatTitle }} -
          {{ switchDate(exam.codigoStatus) }}
        </v-card-subtitle>

        <v-card-text>
          <span v-if="exam.aviso" class="text">
            {{ exam.aviso | toUpperCase }}
          </span>
          <span v-else-if="exam.codigoStatus === 8" class="text">
            Seu Exame esta em Analise em nosso Laboratório, aguarde o resultado
          </span>
        </v-card-text>
      </v-card>
    </template>
  </v-hover>
</template>

<style scoped>
#exam {
  border-radius: 6px;
  cursor: pointer;
}

.text {
  font-size: 0.6rem;
}
</style>

<script>
export default {
  name: "Exam",

  props: {
    exam: Object,
  },

  filters: {
    toUpperCase(val) {
      return val.toUpperCase();
    },
    formatMode(val) {
      switch (val) {
        case "E":
          return "Boleto";
        case "R":
          return "Cartão de Crédito";
        case "X":
          return "PIX";
        case "P":
          return "Paymee";
        case "T":
          return "Cartão Via Link";
        case "C":
          return "Boleto Consolidado";
        case "L":
          return "Boleto";

        default:
          break;
      }
    },

    formatTitle(val) {
      switch (val) {
        case 1:
          return "AGUARDANDO PAGAMENTO";
        case 2:
          return "COLETA LIBERADA";
        case 3:
          return "COLETA REALIZADA";
        case 4:
          return "COLETA CHEGOU AO LABORATÓRIO";
        case 5:
          return "RESULTADO DISPONIVEL";
        case 6:
          return "RESULTADO INSERIDO NO RENACH";
        case 7:
          return "RECOLETA";
        case 8:
          return "COLETA CHEGOU AO LABORATÓRIO";
        default:
          break;
      }
    },
  },

  methods: {
    switchDate(val) {
      switch (val) {
        case 1:
          return this.exam.dataCadastro;

        case 2:
          return this.exam.dataCadastro;

        case 3:
          return this.exam.dataColeta;

        case 4:
          return this.exam.dataAnalise;

        case 5:
          return this.exam.dataLaudo;

        case 6:
          return this.exam.dataRenach;

        case 8:
          return this.exam.dataAnalise;
        default:
          break;
      }
    },
  },
};
</script>

<style></style>
