<template>
    <v-container class="container" fluid v-if="!objetoEhVazio(tipoAtivo)">
        <div v-show="ativoExistenteOuNovo">
            <component @save="save"
                       @buy:activeId="purchaseChange"
                       :is="currentForm"
                       :formTitle="formTitle"
                       :formType="formType"
                       :closing="closing"
                       :respGetAtiTipUserId_prop=respGetAtiTipUserId></component>
        </div>

        <div v-show="!ativoExistenteOuNovo">
            <div class="mock">
                <v-row class="d-flex flex-wrap pb-16 row">
                    <div class="bloco">
                        <v-col class="d-flex flex-column align-left py-0">
                            <span class="text-filelds">Nome do Ativo</span>
                        </v-col>
                        <v-col>
                            <v-select @change="changeActive"
                                      :loading="loading"
                                      :items="ativos"
                                      attach=""
                                      :menu-props="{ bottom: true, offsetY: true }"
                                      v-model="ativo"
                                      placeholder="Selecione"
                                      color="secondary"
                                      :return-object="true"
                                      required counter dense outlined rounded
                                      :disabled=loading
                                      class="select">
                                <template slot="selection"
                                          slot-scope="data">{{
                                    data.item.$index$ }} - {{ data.item.code
                                }}</template>
                                <template slot="item"
                                          slot-scope="data">{{
                                    data.item.$index$ }} - {{ data.item.code
                                }}</template>
                            </v-select>
                        </v-col>
                    </div>

                    <v-row class="d-flex flex-wrap pb-16 row">
                        <div class="bloco">
                            <v-col class="d-flex flex-column align-left py-0">
                                <span class="text-filelds">Estratégia</span>
                            </v-col>
                            <v-col cols="1" class="column">
                                <span class="btn"> {{ estrategia ? estrategia : "" }} </span>
                            </v-col>
                        </div>

                        <div class="bloco" v-if="typeId !== 6 && typeId !== 5 && typeId !== 7 && typeId !== 8">
                            <v-col class="d-flex flex-column align-left py-0">
                                <span class="text-filelds">Indice referência</span>
                            </v-col>
                            <v-col cols="1" class="column">
                                <span class="btn"> {{ indice ? indice : "" }} </span>
                            </v-col>
                        </div>

                        <div class="bloco" v-if="typeId !== 6 && typeId !== 7 && typeId !== 8">
                            <v-col class="d-flex flex-column align-left py-0">
                                <span class="text-filelds">Indexador</span>
                            </v-col>
                            <v-col cols="1" class="column">
                                <span class="btn">{{ indexador ? indexador : "" }}</span>
                            </v-col>
                        </div>

                        <div class="bloco">
                            <v-col class="d-flex flex-column align-left py-0">
                                <span class="text-filelds">FGC</span>
                            </v-col>
                            <v-col cols="1" class="column">
                                <span class="btn"> {{ fgc ? fgc : "" }} </span>
                            </v-col>
                        </div>

                        <div class="bloco">
                            <v-col class="d-flex flex-column align-left py-0">
                                <span class="text-filelds">Data Aplicação Inicial</span>
                            </v-col>
                            <v-col cols="1" class="column">
                                <span class="btn">{{ dateHelper.displayValue(viewModel.purchaseDate) }}</span>
                            </v-col>
                        </div>

                        <div class="bloco" v-if="viewModel.expiration != undefined">
                            <v-col class="d-flex flex-column align-left py-0">
                                <span class="text-filelds">Data Vencimento</span>
                            </v-col>
                            <v-col cols="1" class="column">
                                <span class="btn">{{ dateHelper.displayValue(viewModel.expiration) }}</span>
                            </v-col>
                        </div>

                        <div class="bloco">
                            <v-col class="d-flex flex-column align-left py-0">
                                <span class="text-filelds">Data </span>
                            </v-col>
                            <v-col cols="1" class="column">
                                <DatePiker @change="saveVencimento"
                                           @edit:enter="emitEnter"
                                           @edit:leave="emitLeave"
                                           :value="viewModel.investmentDate" />
                            </v-col>
                        </div>

                        <div class="bloco">
                            <v-col class="d-flex flex-column align-left py-0">
                                <span class="text-filelds">Saldo Líquido ($) </span>
                            </v-col>
                            <v-col cols="1" class="column">
                                <span class="btn"> {{ currencyHelper.formatValue(viewModel.netAmount) }} </span>
                            </v-col>
                        </div>

                        <div class="bloco" v-if="typeId !== 1 && typeId !== 4">
                            <v-col class="d-flex flex-column align-left py-0">
                                <span class="text-filelds">Quantidade disponível </span>
                            </v-col>
                            <v-col cols="1" class="column">
                                <span class="btn">{{ percentageHelper.formatQuota(viewModel.availableUnits) }}</span>
                            </v-col>
                        </div>

                        <div class="bloco" v-if="(!closing) && (typeId === 1 || typeId === 4)">
                            <v-col class="d-flex flex-column align-left py-0">
                                <span class="text-filelds">Valor(R$) </span>
                            </v-col>
                            <v-col cols="1" class="column">
                                <ValorMonetario @change="saveValorAplicacao"
                                                :valor="viewModel.investedValue" />
                            </v-col>
                        </div>

                        <div class="bloco" v-if="([1, 4].indexOf(typeId) < 0) || ((!closing) && typeId === 5)">
                            <v-col class="d-flex flex-column align-left py-0">
                                <span class="text-filelds" v-if="typeId === 6">Valor da Ação (R$)</span>
                                <span class="text-filelds" v-if="typeId === 7 || typeId === 8">Valor da Cota (R$)</span>
                                <span class="text-filelds" v-if="typeId !== 6 && typeId !== 7 && typeId !== 8">P. U. da aplicação</span>
                            </v-col>
                            <v-col cols="1" class="column" v-if="typeId !== 8">
                                <ValorMonetario :value="viewModel.ppUnit"
                                                @change="saveShareValue"
                                                :disabled="updatingData" />
                            </v-col>
                            <v-col cols="1" class="column" v-if="typeId === 8">
                                <span class="btn">{{ currencyHelper.formatValue(viewModel.ppUnit) }}</span>
                            </v-col>
                        </div>

                        <div class="bloco" v-if="(([1, 4, 5].indexOf(typeId) < 0) && (([7].indexOf(typeId) >= 0)) && !closing) || ((!closing) && typeId === 5)">
                            <v-col class="d-flex flex-column align-left py-0">
                                <span class="text-filelds">Quantidade</span>
                            </v-col>
                            <v-col cols="1" class="column">
                                <ValorMonetario :value="viewModel.units"
                                                @change="saveShareQuantity"
                                                :disabled="updatingData" />
                            </v-col>
                        </div>

                        <div class="bloco" v-if="(!closing) && (typeId !== 1 && typeId !== 4)">
                            <v-col class="d-flex flex-column align-left py-0">
                                <span class="text-filelds">Total à retirar (R$)</span>
                            </v-col>
                            <v-col cols="1" class="column">
                                <ValorMonetario :value="viewModel.vTotal"
                                                @change="saveInvestedValue"
                                                :disabled="updatingData" />
                            </v-col>
                        </div>
                    </v-row>
                </v-row>
            </div>
        </div>
    </v-container>
</template>

<style scoped>
.container {
  padding: 0;
}
.bloco {
  flex: 1 0 180px;
  width: 100%;
}
.bloco .btn {
  height: 40px;
  background: #f6f6f6;
  border-radius: 30px;
  color: #9f9f9f;
  padding: 0.5rem 3rem 0.5rem 1rem;
  width: 100%;
  display: block;
}
.column {
  min-width: 100% !important;
}
.text-filelds {
  font-size: 14px;
}
.row {
  padding-bottom: 5rem !important;
}
.date-piker input {
  width: 100%;
  border: 1px solid #e6e6e6;
  color: #a4a4a4;
  border-radius: 40px;
  padding: 0.5rem 0.5rem 0.5rem 2.5rem;
  outline: none;
  position: relative;
}
</style>

<script lang="js">
import ApiService           from "../../../../services/ApiService";
import DatePiker            from "../tiposAtivos/componentes/Date-Piker.vue";
import ValorMonetario       from "../../Modal/tiposAtivos/componentes/Valor-Monetario.vue";
import CdbRdb               from "../tiposAtivos/RendaFixa/cdb-rdb.vue";
import Cri                  from "../tiposAtivos/RendaFixa/cri.vue";
import Cra                  from "../tiposAtivos/RendaFixa/cra.vue";
import Debentures           from "../tiposAtivos/RendaFixa/debentures.vue";
import LciLca               from "../tiposAtivos/RendaFixa/lci-lca.vue";
import TitulosPublicos      from "../tiposAtivos/RendaFixa/titulos-publicos.vue";
import Acoes                from "../tiposAtivos/RendaVariavel/acoes.vue";
import FundosImobiliarios   from "../tiposAtivos/RendaVariavel/fundos-imobiliarios.vue";
import FundosInvestimento   from "../tiposAtivos/FundosInvestimento/fundos-investimento.vue";
import Outros               from "../tiposAtivos/Outros/outros.vue";
import currencyHelper       from "../../../../Utils/CurrencyHelper";
import percentageHelper     from "../../../../Utils/PercentageHelper";
import CalculadorAtivo      from "../../../../services/Calculador";
import dateHelper           from "../../../../Utils/DateHelper";

export default {
    props: {
        closing                 : { type: Boolean },
        respGetAtiTipUserId_prop: { type: Array },
        tipoAtivo               : { type: Object },
        ativoNovoOuExistente    : { type: Boolean },
        state                   : { type: String }
    },
    data() {
        return {
            api                 : new ApiService(),
            calculador          : new CalculadorAtivo().setDefaultLoaders(),
            currencyHelper,
            dateHelper,
            percentageHelper,
            loading             : true,
            updatingData        : false,
            ativoExistenteOuNovo: true,
            respGetAtiTipUserId : [],
            currentForm         : "",
            formTitle           : "",
            formType            : "",
            typeId              : 0,
            ativos              : [],
            estrategia          : "",
            indice              : "",
            indexador           : "",
            fgc                 : "",
            // ====================
            ativo: {},
            // ====================
            viewModel           : {
                purchaseDate    : undefined,    // dataAplicacao
                expiration      : undefined,    // dataVencimento
                investmentDate  : undefined,    // #dataMovimento
                investedValue   : undefined,    // #valorMovimento
                ppUnit          : 0.0,          // vPrecoUnitario
                units           : 0.0,          // vQtd
                vTotal          : 0.0,          // vPrecoUnitarioQtd
                netAmount       : 0.0,          // sumInvestedValue
                availableUnits  : 0.0,          // sumShareQuantity
                unitPrice       : 0.0           // sumShareValue
            },
            // ====================
            userToAtivo         : {
                userId          : undefined,
                ativoId         : undefined,
                investmentDate  : undefined,
                InvestedValue   : undefined,
                TpMovimento     : undefined,
                ShareValue      : undefined,
                ShareQuantity   : undefined,
                codigo          : undefined,
                descricao       : undefined,
                $netValue$      : undefined
            },
            dessertsCalc        : []
        };
    },
    components: {
        CdbRdb, Cri, Cra, Debentures, LciLca,
        TitulosPublicos, Acoes, FundosImobiliarios,
        FundosInvestimento, Outros,
        DatePiker, ValorMonetario
    },
    methods: {
        roundShares(value) {
            const   intVal  = +(value.toFixed(0));
            const   valErr  = Math.abs(value - intVal);
            return (valErr < 1E-6) ? value : intVal;
        },
        emitEnter() {
            this.$emit("edit:working", true);
        },
        emitLeave() {
            this.$emit("edit:working", false);
        },
        cleanDados() {
            this.userToAtivo.ativoId = undefined;
            this.userToAtivo.codigo = undefined;
            this.userToAtivo.descricao = undefined;
            this.viewModel.netAmount = 0;
            this.viewModel.availableUnits = 0;
            this.viewModel.unitPrice = 0;
            this.descricao = undefined;
            this.estrategia = undefined;
            this.indice = undefined;
            this.indexador = undefined;
            this.fgc = undefined;
            this.userToAtivo.TpMovimento = 3;
            this.userToAtivo.ShareValue = 0.00;
            this.userToAtivo.ShareQuantity = 0.00;
            this.userToAtivo.InvestedValue = 0.00;
            this.viewModel.ppUnit = 0;
            this.viewModel.units = 0;
            this.viewModel.vTotal = 0;
            this.viewModel.investmentDate = null;
            this.userToAtivo.investmentDate = undefined;
            this.viewModel.purchaseDate = null;
            this.viewModel.expiration = null;
        },
        save(dadosDoAtivo) {
            var _msg = undefined;
            var _newDate = new Date();
            const mind = this.ativo &&
                this.ativo.movements &&
                this.ativo.movements.length &&
                this.ativo.movements.reduce((v, m) => {
                    let val = m.investmentDate;
                    if (val && (val.length > 10)) { val = val.substring(0,10); }
                    if (!v) { return val; }
                    return v > val ? v : val;
                }, undefined) ||
                undefined;
            if (mind && dadosDoAtivo.investmentDate < mind) {
                const dv = new Date(`${mind}T00:00:00`);
                const df = dv.toLocaleDateString();
                const tm = `* Data de vencimento precisa maior ou igual à data da última operação (${df})`;
                _msg = _msg ? `${_msg}\n${tm}` : tm;
            }

            if ((this.typeId !== 1 && this.typeId !== 4) && dadosDoAtivo.TpMovimento !== 3 && dadosDoAtivo.investmentDate != undefined && this.ativos.filter(a => (a.activeId == dadosDoAtivo.ativoId)).length > 0) {
                var _dataAplicacao = this.ativos.filter(a => (a.activeId == dadosDoAtivo.ativoId))[0].movements.filter(m => (m.tpMovimento === 1))[0].investmentDate;
                if (new Date(dadosDoAtivo.investmentDate) < new Date(_dataAplicacao)) {
                    _msg = (_msg ? _msg + "\n" : "") + "* Data de aplicação tem que ser maior que a data de aplicação inicial (" + this.dateHelper.formatDate(new Date(_dataAplicacao)) + ").";
                } else {
                    dadosDoAtivo.TpMovimento = 2;
                }
            } else if (dadosDoAtivo.ativo != undefined) {
                dadosDoAtivo.TpMovimento = 1;
            }

            if (dadosDoAtivo.investmentDate) {
                if (!this.api.getValidarData(dadosDoAtivo.investmentDate)) {
                    _msg = (_msg ? _msg + "\n" : "") + "* No momento não existe cotação para esta data de aplicação.";
                }
                if (new Date(dadosDoAtivo.investmentDate) > _newDate) {
                    _msg = (_msg ? _msg + "\n" : "") + "* Data de aplicação não pode ser data futura.";
                }
            }
            if (dadosDoAtivo.TpMovimento == 3 && (dadosDoAtivo.investmentDate != undefined && this.viewModel.purchaseDate !== undefined && new Date(dadosDoAtivo.investmentDate) < new Date(this.viewModel.purchaseDate))) {
                _msg = (_msg ? _msg + "\n" : "") + "* Data de aplicação tem que ser maior que a data de aplicação inicial.";
            }
            if (dadosDoAtivo.TpMovimento == 3 && (dadosDoAtivo.investmentDate != undefined && this.viewModel.expiration != undefined && new Date(dadosDoAtivo.investmentDate) >= new Date(this.viewModel.expiration))) {
                _msg = (_msg ? _msg + "\n" : "") + "* Data de aplicação tem que ser menor que a data Vencimento.";
            }

            if ((this.typeId !== 5 && this.typeId !== 7 && this.typeId !== 8) && ((dadosDoAtivo.ShareQuantity != undefined && dadosDoAtivo.ShareQuantity != "") && dadosDoAtivo.ShareQuantity != parseInt(dadosDoAtivo.ShareQuantity.toFixed(0)))) {
                _msg = (_msg ? _msg + "\n" : "") + "* A quantidade só pode ser inteira. ";
            }
            if ((this.typeId === 1 && (dadosDoAtivo.ativo != undefined && dadosDoAtivo.ativo.cdbRdb != undefined && (dadosDoAtivo.ativo.cdbRdb.vencimento != undefined && new Date(dadosDoAtivo.ativo.cdbRdb.vencimento) < _newDate))) ||
                (this.typeId === 4 && (dadosDoAtivo.ativo != undefined && dadosDoAtivo.ativo.lciLca != undefined && (dadosDoAtivo.ativo.lciLca.vencimento != undefined && new Date(dadosDoAtivo.ativo.lciLca.vencimento) < _newDate)))) {
                _msg = (_msg ? _msg + "\n" : "") + "* Data de vencimento tem que ser futura.";
            }
            if ((this.typeId === 1 || this.typeId === 4 || this.typeId === 7 || this.typeId === 8) && (dadosDoAtivo.TpMovimento != undefined && dadosDoAtivo.TpMovimento == 3 && dadosDoAtivo.InvestedValue != undefined && dadosDoAtivo.InvestedValue > 0 && this.viewModel.netAmount > 0 && (this.viewModel.netAmount - dadosDoAtivo.InvestedValue) < 0)) {
                _msg = (_msg ? _msg + "\n" : "") + "* Valor total da retirada não pode ser superior ao Saldo Liquido. ";
            }
            if (this.state === 'resgate') {
                // não verificar, solicitado via Skype.
            } else {
                if ((this.typeId !== 1 && this.typeId !== 4 && this.typeId !== 7 && this.typeId !== 8) &&
                    (dadosDoAtivo.TpMovimento == 3 && dadosDoAtivo.ShareQuantity != undefined &&
                        ((dadosDoAtivo.ShareQuantity > 0 && this.viewModel.availableUnits > 0 &&
                        (this.viewModel.availableUnits - dadosDoAtivo.ShareQuantity) < 0) ||
                        (dadosDoAtivo.ShareQuantity < 0 && this.viewModel.availableUnits > 0 &&
                        (this.viewModel.availableUnits + dadosDoAtivo.ShareQuantity) < 0)))) {
                    _msg = (_msg ? _msg + "\n" : "") + "* Quantidade da retirada não pode ser superior ao Quantidade disponivel. ";
                }
            }
            if ((this.typeId === 1 || this.typeId === 4) && dadosDoAtivo.TpMovimento == 3 && dadosDoAtivo.InvestedValue != undefined && dadosDoAtivo.InvestedValue > 0) {
                dadosDoAtivo.InvestedValue = dadosDoAtivo.InvestedValue * (-1);
            }
            if (this.closing) {
                if (dadosDoAtivo.ShareValue == 0) { dadosDoAtivo.ShareValue = this.viewModel.unitPrice; }
                dadosDoAtivo.ShareQuantity = this.roundShares(this.viewModel.availableUnits);
                dadosDoAtivo.InvestedValue =
                    this.state !== 'resgate'          ? this.viewModel.availableUnits   *
                                                        dadosDoAtivo.ShareValue         :
                    [1,4,8].indexOf(this.typeId) >= 0 ? this.viewModel.netAmount        :
                                                        dadosDoAtivo.ShareQuantity      *
                                                        dadosDoAtivo.ShareValue;

            }
            this.$emit("save", { dado: dadosDoAtivo, tipoAtivo: this.typeId, msg: _msg });
        },
        saveInvestedValue(InvestedValue) {
            this.userToAtivo.InvestedValue = parseFloat(InvestedValue);
            if (this.userToAtivo.ShareQuantity > 0) {
                if (this.userToAtivo.ShareValue > 0) {
                    this.userToAtivo.ShareQuantity = this.roundShares(this.userToAtivo.InvestedValue / this.userToAtivo.ShareValue);
                    this.userToAtivo.InvestedValue = this.userToAtivo.ShareQuantity * this.userToAtivo.ShareValue;
                }
                this.userToAtivo.ShareValue = 0;
            }
            this.saveTotalValue();
        },
        saveShareValue(ShareValue) {
            this.userToAtivo.ShareValue = parseFloat(ShareValue);
            if (this.state === 'resgate') {
                if ((this.userToAtivo.ShareQuantity > 0) && (this.userToAtivo.ShareValue > 0) &&
                        (this.typeId !== 1) && (this.typeId !== 4) && (this.typeId !== 8)) {
                    this.viewModel.netAmount = this.userToAtivo.ShareQuantity *
                                               this.userToAtivo.ShareValue;
                }
            } else {
                if (this.userToAtivo.ShareQuantity > 0 && this.typeId !== 1 && this.typeId !== 4 && this.typeId !== 8) {
                    if (this.userToAtivo.ShareValue > 0) {
                        this.userToAtivo.ShareQuantity = this.roundShares(this.userToAtivo.InvestedValue / this.userToAtivo.ShareValue);
                    }
                    this.userToAtivo.InvestedValue = 0;
                } else {
                    this.userToAtivo.ShareQuantity = 0;
                }
            }
            this.saveTotalValue()
        },
        saveShareQuantity(ShareQuantity) {
            this.userToAtivo.ShareQuantity = this.roundShares(parseFloat(ShareQuantity));
            if (this.userToAtivo.ShareValue > 0 && this.typeId !== 7 && this.typeId !== 8) {
                this.userToAtivo.InvestedValue = 0;
            }
            this.saveTotalValue();
        },
        saveTotalValue() {
            if (this.userToAtivo.ShareValue > 0 && this.userToAtivo.ShareQuantity > 0) {
                this.userToAtivo.InvestedValue = (this.userToAtivo.ShareValue * this.userToAtivo.ShareQuantity);
            } else if (this.userToAtivo.InvestedValue > 0 && this.userToAtivo.ShareQuantity > 0 && ((this.state !== 'resgate') || (this.typeId !== 7 && this.typeId !== 8))) {
                this.userToAtivo.ShareValue = (this.userToAtivo.InvestedValue / this.userToAtivo.ShareQuantity);
            } else if (this.userToAtivo.ShareValue > 0 && this.userToAtivo.InvestedValue > 0) {
                this.userToAtivo.ShareQuantity = this.roundShares(this.userToAtivo.InvestedValue / this.userToAtivo.ShareValue);
                this.userToAtivo.InvestedValue = this.userToAtivo.ShareQuantity * this.userToAtivo.ShareValue;
            }
            this.userToAtivo.TpMovimento = 3;
            setTimeout(() => {
                this.viewModel.ppUnit = this.userToAtivo.ShareValue || 0;
                this.viewModel.units = this.userToAtivo.ShareQuantity || 0;
                this.viewModel.vTotal = this.userToAtivo.InvestedValue || 0;
                this.save(this.userToAtivo);
            }, 0);
        },

        purchaseChange(id) {
          if (!(id && this.ativos && this.ativos.length)) {
            this.ativo = {};
            this.cleanDados();
            return;
          }
          const match = this.ativos.filter((a) => a.activeId === id);
          if (!match.length) {
            this.ativo = {};
            this.cleanDados();
            return;
          }
          this.ativo = match[0];
          return this.changeActive();
        },
        async changeActive() {
            await new Promise((acc) => setTimeout(() => {
                this.loading                    = true;
                this.viewModel.investmentDate   = "";
                this.userToAtivo.investmentDate = undefined;
                this.dessertsCalc               = [];
                acc();
            }, 0));
            let model = {
                userId: this.$store.getters.$tokenUserSelected,
                ativoId: this.ativo.activeId,
            };
            try {
                const res   = await this.api.post(`userToAtivo/detalhed`, model);
                await new Promise((acc, rej) => {
                    setTimeout(() => {
                        try {
                            this.viewModel.expiration = res.content.vencimento;
                            let sl = this.ativo.movements && this.ativo.movements.length || 0;
                            let sr = res.content.movimentos && res.content.movimentos.length || 0;
                            if (sl < sr) {
                                this.ativo.movements = res.content.movimentos;
                            }
                            const calc = this.calculador.calcular(res.content, false);
                            let _length = calc.dessertsCalc.length - 1;
                            if (_length >= 0) {
                                this.dessertsCalc       = calc.dessertsCalc;
                                const tail              = calc.dessertsCalc[_length];
                                this.ativo.$netValue$   = tail.saldoLiquido;
                                this.userToAtivo.$netValue$ = this.ativo.$netValue$;
                                this.viewModel.netAmount = tail.saldoLiquido;
                                this.viewModel.availableUnits = tail.quotas;
                                this.viewModel.unitPrice = tail.cotacao;
                            }
                            this.userToAtivo.ativoId    = this.ativo.activeId;
                            this.userToAtivo.codigo     = this.ativo.code;
                            this.userToAtivo.descricao  = this.ativo.description;
                            this.userToAtivo.$netValue$ = this.ativo.$netValue$;
                            this.viewModel.purchaseDate = this.ativo.movements.filter(m => (m.tpMovimento === 1))[0].investmentDate;
                            this.descricao              = this.ativo.description;

                            this.estrategia             = this.ativo.strategyDescription;
                            this.indice                 = this.ativo.referenceDescription;
                            this.indexador              = this.ativo.indexerDescription;
                            this.fgc                    = this.ativo.creditGuaranteeFundDescription
                            this.userToAtivo.TpMovimento = 3;
                            this.userToAtivo.ShareValue = 0.00;
                            this.userToAtivo.ShareQuantity = 0.00;
                            this.userToAtivo.InvestedValue = 0.00;
                            this.viewModel.ppUnit = 0;
                            this.viewModel.units = 0;
                            this.viewModel.vTotal = 0;
                            this.viewModel.investmentDate = null;
                            this.userToAtivo.investmentDate = undefined;
                            this.save(this.userToAtivo);
                            acc();
                        } catch (error) {
                            rej(error);
                        }
                    }, 0);
                });
            } finally {
                this.loading = false;
            }
        },
        changeActiveType() {
            this.userToAtivo.TpMovimento = 3;
        },
        saveVencimento(data) {
            if (!(data && this.userToAtivo && this.userToAtivo.ativoId && data !== this.userToAtivo.investmentDate)) { return; }
            if (!this.viewModel.ppUnit) { this.viewModel.ppUnit = 0; }
            this.userToAtivo.investmentDate = data;
            const lim                       = `${data}T00:00:00`;
            const target                    = this.dessertsCalc.filter((c) => c.date <= lim);
            if (this.typeId === 7 || this.typeId === 8) {
                this.updatingData = true;
                this.api.getCotacao(this.userToAtivo.ativoId, data)
                    .then((resp) => {
                        setTimeout(() => {
                            this.updatingData               = false;
                            const actualSV                  = resp.valor;
                            this.userToAtivo.ShareValue     = actualSV;
                            this.viewModel.unitPrice        = actualSV;
                            if (target.length) {
                                this.viewModel.netAmount    = target[target.length - 1].saldoLiquido;
                                this.ativo.$netValue$       = this.viewModel.netAmount;
                            }
                            this.saveShareValue(actualSV);
                            this.viewModel.ppUnit           = this.userToAtivo.ShareValue;
                            this.viewModel.vTotal           = this.viewModel.netAmount;
                            this.userToAtivo.InvestedValue  = this.viewModel.vTotal;
                            if (this.viewModel.vTotal !== 0) {
                                this.userToAtivo.ShareQuantity  = this.roundShares(this.userToAtivo.InvestedValue / this.userToAtivo.ShareValue);
                                this.userToAtivo.InvestedValue  = this.userToAtivo.ShareQuantity * this.userToAtivo.ShareValue;
                                this.viewModel.units            = this.userToAtivo.ShareQuantity;
                            }
                            this.save(this.userToAtivo);
                        }, 0);
                    })
                    .catch((error) => {
                        this.updatingData = false;
                        this.botaoCompraClicado = false;
                        this.$toast.error(error.Error);
                    })
            } else {
                if (target.length) {
                    this.viewModel.netAmount        = target[target.length - 1].saldoLiquido;
                    this.ativo.$netValue$           = this.viewModel.netAmount;
                    this.userToAtivo.InvestedValue  = this.viewModel.netAmount;
                }
                this.save(this.userToAtivo);
            }
        },
        saveValorAplicacao(valorAplicacao) {
            this.userToAtivo.InvestedValue = valorAplicacao;
            this.save(this.userToAtivo);
        },
        objetoEhVazio(e) {
            return Object.keys(e).length === 0;
        }
    },
    watch: {
        respGetAtiTipUserId_prop(nv) {
            this.loading = true;
            this.respGetAtiTipUserId = [nv[0]];
            nv[0].usersActive &&
                nv[0].usersActive.length &&
                nv[0].usersActive.forEach((o, i) => o.$index$ = i + 1);
            this.ativos = nv[0].usersActive;
            this.loading = false;
        },
        tipoAtivo(nv, ov) {
            this.cleanDados();
            this.currentForm    = nv.value.value === 'Previdencia' ? 'FundosInvestimento' : nv.value.value;
            this.formTitle      = nv.value.value;
            this.formType       = nv.value.tipo;
            this.typeId         = nv.value.id;
        },
        ativoNovoOuExistente(nv) {
            this.ativoExistenteOuNovo = nv;
        },
        updatingData(nv) {
            this.$emit("edit:updating", nv);
        },
        ativo(nv, ov) {
          if (ov.activeId) {
          }
        }
    },
    computed: {
        userFormated() {
            return this.$store.getters.$userLogged;
        },

        typeUser() {
            return this.$store.getters.$userType;
        },
    },
    created() {
        this.userToAtivo.ShareValue = 0.00;
        this.userToAtivo.ShareQuantity = 0.00;
        this.userToAtivo.InvestedValue = 0.00;
        this.viewModel.ppUnit = 0;
        this.viewModel.units = 0;
        this.viewModel.vTotal = 0;
    },
}
</script>