<template>
  <div class="accordion">
    <div
      :class="[opened ? 'opened_class' : 'closed_class', 'header']"
      :style="color"
    >
      <div class="btn" @click="openOrClose">
        <img src="@/assets/icons/chevron_right_icon.svg" v-if="!opened" />
        <img src="@/assets/icons/chevron_down_icon.svg" v-else />
      </div>
      <div class="conteudo-header">
        <slot name="header" />
      </div>
    </div>
    <div class="body" v-if="opened">
      <slot name="body" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    cor: { type: String, default: "" },
  },
  data() {
    return {
      opened: false,
      color: {
        backgroundColor: this.cor,
      },
      windowWidth: window.innerWidth,
    };
  },
  methods: {
    openOrClose() {
      this.opened == false ? (this.opened = true) : (this.opened = false);
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  watch: {
    windowWidth(newHeight, oldHeight) {
      this.txt = `it changed to ${newHeight} from ${oldHeight}`;
    },
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style scoped>
.accordion {
  width: 100%;
  color: #202020;
}
/* CABECALHO */
.header {
  padding: 0.2rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 5vh;
}
.conteudo-header {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  font-weight: 600;
}
.btn {
  position: absolute;
  left: 0.6rem;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0.3rem;
}
/* FIM CABECALHO */

/* CLASSES DINAMICAS */
.closed_class {
  border-radius: 25px;
}
.opened_class {
  border-radius: 20px 20px 0 0;
}
/* FIM CLASSES DINAMICAS */

/* BODY */
.body {
  padding-bottom: 0 0 0.5rem 0;
  overflow: hidden;
  background: transparent !important;
  border: 1px solid red;
  border: none !important;
}
/* FIM DO BODY */

@media screen and (max-width: 1200px) {
  .conteudo-header {
    font-size: 13px !important;
  }
}
</style>