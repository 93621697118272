<template>
  <v-card class="d-flex flex-column align-center pa-6">
    <v-btn
      color="primary"
      tile
      elevation="0"
      style="position: absolute; top: 0; right: 0"
      @click="$emit('close')"
    >
      <v-icon color="white">mdi-close</v-icon>
    </v-btn>
    <v-card-title
      class="d-flex justify-center main-title pt-5 mb-5"
      style="word-break: keep-all"
    >
      <h2>Queremos ouvir você!</h2>
    </v-card-title>

    <v-card-text class="text-center" style="color: #979696; font-size: 14px">
      <span
        >Buscando sempre melhorar nossos serviços, gostaríamos de contar com a
        sua opinião.</span
      >
      <br />
      <span>Responda abaixo para avaliar sua experiência conosco.</span>
    </v-card-text>

    <v-card-title
      class="d-flex justify-center main-title pt-5 mb-4"
      style="word-break: keep-all"
    >
      <h4>Em uma escala de 1 a 10, quanto você recomendaria a LABET?</h4>
    </v-card-title>

    <div class="rating">
      <v-item-group v-model="ratingCircle">
        <div class="d-flex justify-space-around" style="width: 100%">
          <div v-for="rating in ratings" :key="rating">
            <v-item v-slot="{ active, toggle }">
              <v-card
                :color="active || ratingCircle == null ? 'primary' : '#ffe9d8'"
                class="mr-1 d-flex justify-center align-center"
                elevation="0"
                tile
                dark
                @click="toggle"
                width="50"
                height="50"
              >
                <v-scroll-y-transition>
                  <div class="d-flex justify-center align-center">
                    {{ rating }}
                  </div>
                </v-scroll-y-transition>
              </v-card>
            </v-item>
          </div>
        </div>
      </v-item-group>
    </div>

    <v-card-text style="max-width: 496px">
      <div class="rating-field">
        <v-select
          append-icon="mdi-chevron-down"
          :items="ratings"
          :rules="[(v) => !!v || 'Campo Obrigatório']"
          v-model="ratingField"
          placeholder="Selecione sua nota"
        >
        </v-select>
      </div>

      <div
        class="text-center mt-8"
        v-if="ratingCircle != null || ratingField != null"
      >
        <h2 style="color: #707070">O que te motivou a dar essa nota?</h2>
      </div>
    </v-card-text>

    <v-item-group
      v-model="observation"
      v-if="ratingCircle != null || ratingField != null"
    >
      <v-row>
        <v-col
          v-for="(title, index) in buttonTitles"
          :key="index"
          cols="12"
          sm="2"
          md="2"
          lg="2"
        >
          <v-item v-slot="{ active, toggle }">
            <v-card
              :color="active ? 'primary' : '#f7d2b0'"
              class="d-flex justify-center align-center text-center"
              elevation="8"
              @click="submit(toggle)"
              height="70"
            >
              <strong
                :style="
                  active
                    ? 'color: white; font-size: 12px'
                    : 'color: #707070; font-size: 12px'
                "
              >
                {{ title }}
              </strong>
            </v-card>
          </v-item>
        </v-col>
      </v-row>
    </v-item-group>

    <v-item-group
      style="width: 100%"
      v-if="observation == 5"
      v-model="otherObservation"
    >
      <v-row class="mt-2">
        <v-col v-for="title in buttonTitles2" :key="title">
          <v-item v-slot="{ active, toggle }">
            <v-card
              class="d-flex justify-center align-center text-center"
              :color="active ? 'primary' : '#f7d2b0'"
              elevation="8"
              @click="submit(toggle)"
              height="70"
              min-width="100"
            >
              <strong
                :style="
                  active
                    ? 'color: white; font-size: 12px'
                    : 'color: #707070; font-size: 12px'
                "
              >
                {{ title }}
              </strong>
            </v-card>
          </v-item>
        </v-col>
      </v-row>
    </v-item-group>
  </v-card>
</template>

<style scoped>
.main-title {
  color: #979696;
}
.numbers {
  display: flex;
  justify-content: space-around;
  width: 100%;
  font-weight: bold;
  font-size: 1rem;
}

.rating-field {
  display: none;
}

@media (max-width: 590px) {
  .rating {
    display: none;
  }

  .rating-field {
    display: block;
  }
}
</style>

<script>
import ApiService from "../../../../services/ApiService";

export default {
  name: "NPS",

  props: {
    pce_id: Number,
  },

  data: () => ({
    ratings: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    ratingCircle: null,
    ratingField: null,
    observation: null,
    otherObservation: null,
    apiService: new ApiService(),
    loading: false,
    buttonTitles: [
      "Procedimento de coleta",
      "Atendimento no Laboratório de Coleta",
      "Tempo para cadastrar seus dados",
      "Atendimento pelo Telefone",
      "Acesso ao Laudo",
      "Outros",
    ],
    buttonTitles2: [
      "Experiência no site",
      "Experiência no laboratório",
      "Experiência na central de atendimento",
      "Prazo do Laudo",
      "Não encontrei o motivo",
    ],
  }),

  methods: {
    submit(toggle) {
      toggle();
      this.loading = true;

      let obs;
      if (this.observation == 5 && this.otherObservation == null) {
        return;
      } else if (this.observation == 5 && this.otherObservation != null) {
        obs = this.buttonTitles2[this.otherObservation];
      } else if (this.observation != 5 && this.observation != null) {
        obs = this.buttonTitles[this.observation];
      }

      let rating;

      if (this.ratingCircle != null) {
        rating = this.ratingCircle;
      } else if (this.ratingField != null) {
        rating = this.ratingField;
      }

      const nps = {
        CODIGO_COMPRA: String(this.pce_id),
        NOTA: rating,
        SITE_ORIGEM: "Portal_Laudo",
        EXPERIENCIA: obs,
        ID_CAMPANHA: 1,
      };

      this.apiService
        .post("NPS/add", nps)
        .then(() => {
          this.$toast.success("Sua avaliação foi salva!");
          this.loading = false;
          this.$emit("close");
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
