<template>
  <div class="card" @click="goCarteira">
    <div class="carteira">
      <span class="titulo">Carteira T01</span>
      <span class="descricao">{{ data.title }}</span>
    </div>
    <div class="participacao" :title="data.percentege * 100">
      <span class="titulo">Participação carteira</span>
      <span class="descricao">{{
        bound ? "---" : percentageHelper.formatPercentage(data.percentege)
      }}</span>
    </div>
    <div class="emissor">
      <span class="titulo">Emissor/Gestor</span>
      <span class="descricao">{{ data.emissor }}</span>
    </div>

    <div class="custodiante">
      <span class="titulo">Custodianate</span>
      <span class="descricao">{{ data.custodiante }}</span>
    </div>

    <div class="liquidez">
      <span class="titulo">Liquidez em dias</span>
      <span class="descricao">{{ data.liquidez }}</span>
    </div>
    <div class="vencimento">
      <span class="titulo">Vencimento</span>
      <span class="descricao">{{
        dateHelper.formatDate(data.vencimento)
      }}</span>
    </div>
    <div class="saldo">
      <span class="titulo">Saldo Bruto</span>
      <span class="descricao">{{ data.value }}</span>
    </div>
  </div>
</template>

<script>
import percentageHelper from "../../../../../Utils/PercentageHelper";
import dateHelper from "../../../../../Utils/DateHelper";

export default {
  props: {
    dataRecieved: { type: Object, default: undefined },
    bound: { type: Boolean }
  },
  data() {
    return {
      percentageHelper,
      dateHelper,
      dessertsCdbRdb: [],
      data: this.dataRecieved,
    };
  },
  methods: {
    goCarteira() {
      this.$emit("cardClicked");
    },
  },
};
</script>

<style scoped>
.cards {
  width: 100%;
}
.card {
  width: 100%;
  background: white;
  padding: 0.5rem;
  margin-bottom: 0.4rem;
}
.card span {
  display: block;
}
.card > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.2rem 0;
  padding: 0.8rem 0;
}

.card > div:not(:last-child) {
  border-bottom: 1px solid #f6f6f6;
}
.card > div .titulo {
  font-weight: 600;
}
</style>