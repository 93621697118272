<template>
  <div class="alocacao-total">
    <div class="descricoes">
      <!-- <div class="title">
        <h1>Alocação estratégica</h1>
        <span>Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis ullamco
          cillum dolor. Voluptate exercitation incididunt aliquip deserunt
          reprehenderit elit laborum.
        </span>
      </div> -->
    </div>

    <v-card flat class="grafico-wrapper">
      <div class="grafico-desempenho">
        <div class="top-grafico">
          <div class="top">
            <div class="title-grafico">
              <span>Alocação Atual</span>
            </div>
          </div>
        </div>

        <div class="grafico">
          <Doughnut
            class="grafico-area"
            :chartData="testeObj"
            :chartDataCDI="arrCDI"
            :options="options"
            :plugins="plugins"
            :dataSetCDI="dadosCDI"
            label="Carteira"
            label2="Rentabilidade mensal"
          />
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import moment from "moment";
import ChartDataLabels from "chartjs-plugin-datalabels";
import outlabels from "chartjs-plugin-piechart-outlabels";
import * as Mock from "@/components/mock/mock.js";
import SwitchButton from "./SwitchButton.vue";
import Doughnut from "./graficos/Pie-alocacaoTotal.vue";
export default {
  components: {
    Doughnut
  },
  props: ["calculos", "dozeMeses"],
  data() {
    return {
      testeDatas: undefined,
      testeValores: undefined,
      testeObj: {},
      arrPrincipal: [],
      dadosGraficos: [],
      arrCDI: [],
      arrIPCA: [],
      arrDOLAR: [],
      arrIBOVESPA: [],
      dadosCDI: undefined,
      plugins: [ChartDataLabels],
      options: {
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage : 20, // tamanho percentual do meio do Doughnut. Se fosse 0 seria um gráfico Pie
        rotation: 3.14/4,
        series: [
          {
            type: 'pie',
            angleKey: 'value',
            labelKey: 'label',
          },
        ],
        animation: {  // animação no carregamento do gráfico
          duration: 1000,
          easing: 'linear',
          from: 1,
          to: 0,
          loop: true,
        },

        layout: {
          padding: {
            // para o plugin outlabels, evitando o corte das legendas
            left: 0,
            right: 0,
            top: 110,
            bottom: 110
          },
        },
        legend: {
          display: false,
          position: "top",
          align: "start",
          labels: {
            fontSize: 18,
          },
        },
        plugins: {
          datalabels: {
            font: {
              weight: "bold",
              size: 18,
            },
          },
          outlabels: {
            text: "%l: %p",
            padding: 4,
            stretch: 80,
            font: {
              resizable: true,
              minSize: 14,
              maxSize: 20
            }
          }
        },
        tooltips: {
          callbacks: {
            label: function(tooltip, context) {
              let value = context.datasets[0].data[tooltip.index];
              return context.labels[tooltip.index]  + ':' + (value
                  && (100 * value).toLocaleString(
                    "pt-BR", {
                      style: "decimal",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    }) + "%"
                  || "0.00%");
            }
          }
        },
      },
    };
  },
  methods: {
    initDataGrahph() {
      this.testeDatas = this.arrPrincipal.map((d) => d.valores.value);
      this.testeValores = this.arrPrincipal.map((d) => d.valores.title);
      let cores = this.arrPrincipal.map((d) => d.valores.color);

      this.dadosGraficos = [{
        data: this.testeDatas,
        backgroundColor: cores,
        borderWidth: 10, // Largura da separação do itens
        datalabels: {
          anchor: "center",
          display: true,
          formatter: (value, ctx) => {
              let sum = 0;
                let dataArr = ctx.chart.data.datasets[0].data;
                dataArr.map((data) => {
                  sum += data;
                });
                let percentage = sum && ((value * 100) / sum).toFixed(0) + "%" || "0,00%";
                return  percentage;

          },
        },
      }];
      this.initDefault();
    },
    initDefault() {
      this.testeObj = {
        labels: this.testeValores,
        datasets: this.dadosGraficos,
      };
    },
    loadGraph () {
      this.arrPrincipal  = [
        {
          valores : {
            title: "Juros Pré (fixo)",
            value: this.calculos && this.calculos.alocacao && this.calculos.alocacao.jurosPre || 0,
            color: "#29BD1A",
          }
        },
        {
          valores : {
            title: "Juros Pós (CDI)",
            value: this.calculos && this.calculos.alocacao && this.calculos.alocacao.jurosPosCdi || 0,
            color: "#35BAFC",
          }
        },
        {
          valores : {
            title: "Juros Pós (inflação)",
            value: this.calculos && this.calculos.alocacao && this.calculos.alocacao.jurosPosInflacao || 0,
            color: "#2679ED",
          }
        },
        {
          valores : {
            title: "Renda Variável",
            value: this.calculos && this.calculos.alocacao && this.calculos.alocacao.rendaVariavel || 0,
            color: "#FA5807",
          }
        },
        {
          valores : {
            title: "Câmbio",
            value: this.calculos && this.calculos.alocacao && this.calculos.alocacao.cambio || 0,
            color: "#C520FF",
          }
        },
        {
          valores : {
            title: "Livres",
            value: this.calculos && this.calculos.alocacao && this.calculos.alocacao.livre || 0,
            color: "#FAA90F"
          }
        },
      ].filter((el) => el.valores.value !== 0.0);
      this.arrPrincipal.sort((l, r) => (r.valores.value || 0) - (l.valores.value || 0));
      if (window.innerWidth < 1000) {
        this.options.layout.padding = 0;
        this.options.plugins.outlabels = undefined;
        this.options.legend.display = true;
        this.options.plugins.datalabels.font.size = 23;
      } else {
        this.options.layout.padding = { left: 0, right: 0, top: 110, bottom: 110 };
        this.options.legend.display = false;
        this.options.plugins.outlabels = {
          text: "%l: %p",
          padding: 4,
          stretch: 80,
          font: {
            resizable: true,
            minSize: 14,
            maxSize: 20
          }
        };
      }
      this.initDataGrahph();
    }
  },
  created() {
    this.loadGraph();
  },
  watch: {
    calculos(nv, ov) {
      this.loadGraph();
    }
  }
};
</script>

<style  scoped>
/* .alocacao-total {
} */

.descricoes {
  margin-bottom: 1.5rem;
}
.descricoes .title {
  max-width: 50%;
}
.descricoes .title h1 {
  font-size: 25px;
  line-height: 37px;
  font-weight: 500;
  letter-spacing: 0.15px;
  color: #000000;
}
.descricoes .title span {
  font-weight: 300;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.15px;
  color: #000000;
}
.grafico-wrapper {
  border-radius: 20px;
  padding-bottom: 0.5rem;
}

.grafico-wrapper .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 1rem 0 0 1rem;
}
.grafico-area {
  position: relative;
  height: 600px;
  margin: auto;
}

.title-grafico span {
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.15px;
  color: #000000;
}
.grafico .descricao-grafico {
  transform: rotate(-90deg);
  max-width: 150px;
  position: absolute;
  top: 50%;
  left: -35px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  letter-spacing: 0.15px;

  color: #000000;
}
.grafico-wrapper .btns-ativos {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin: 0.5rem 0;
}
.grafico-wrapper .btns-ativos > div {
  margin: 0 0.8rem;
}
.grafico-wrapper .btns-ativos > div span {
  padding: 0 0.2rem;
}
/* .grafico-wrapper .title-grafico {
} */
@media screen and (max-width: 700px) {
  .descricoes .title {
    max-width: 100% !important;
  }
}
</style>