const NONSPACE_MARK_POINTS = [
    1471, 1479, 1648, 1809, 2045, 2362,2364, 2381];
const NONSPACE_MARK_RANGES = [
    [ 768,  879], [1155, 1159], [1425, 1469], [1473, 1474], [1476, 1477],
    [1552, 1562], [1611, 1631], [1750, 1756], [1759, 1764], [1767, 1768],
    [1770, 1773], [1840, 1866], [1958, 1968], [2027, 2035], [2070, 2073],
    [2075, 2083], [2085, 2087], [2089, 2093], [2137, 2139], [2259, 2273],
    [2275, 2306], [2369, 2376]];
const WHITESPACE_POINTS = [
    32, 133, 160, 5760, 8232, 8233, 8239, 8287, 12288];
const WHITESPACE_RANGES = [
    [9, 13], [8192, 8202]];

function isVoid(v) {
    return v === null || v === undefined;
}
const STD_COMPARISON = (l, r) => {
    let lVoid = isVoid(l);
    let rVoid = isVoid(r);
    if (lVoid && rVoid) {
        return 0;
    } else if (lVoid) {
        return -1;
    } else if (rVoid) {
        return 1;
    }
    return l === r ? 0 : l < r ? -1 : 1;
}
function binarySearch(sequence, item, compare){
    let comp    = compare ?? STD_COMPARISON;
    let start   = 0;
    let finish  = sequence.length - 1;
    while (start <= finish) {
        let mid = start !== finish ?
            (start + finish) >> 1 :
            start;
        let res = comp(item, sequence[mid]);
        if (res === 0) {
            return mid;
        } else if (res < 0) {
            finish = mid - 1;
        } else {
            start  = mid + 1;
        }
    }
    return ~start;
}
function binaryMixedSearch(sequence, item, compare) {
    let start   = 0;
    let finish  = sequence.length - 1;
    while (start <= finish) {
        let mid = start !== finish ?
            (start + finish) >> 1 :
            start;
        let res = compare(item, sequence[mid]);
        if (res === 0) {
            return mid;
        } else if (res < 0) {
            finish = mid - 1;
        } else {
            start   = mid + 1;
        }
    }
    return ~start;
}
function isWhitespace (codePoint) {
    return binarySearch(WHITESPACE_POINTS, codePoint) >= 0 ||
        binaryMixedSearch(WHITESPACE_RANGES,
            codePoint,
            (point, range) => point < range[0] ? -1 : point > range[1] ? 1 : 0) >= 0;
}
function isNonSpacingMark (codePoint) {
    return binarySearch(NONSPACE_MARK_POINTS, codePoint) >= 0 ||
        binaryMixedSearch(
            NONSPACE_MARK_RANGES,
            codePoint,
            (point, range) => point < range[0] ? -1 : point > range[1] ? 1 : 0) >= 0;
}
function prepare (val, intact)  {
    let input = isVoid(val) ? ''
        : typeof val === 'string' ? val.normalize("NFC")
        : `${val}`;
    return intact ? input : input.trim();
}
function capitalize (val, lowerRest) {
    let input = prepare(val);
    if (!input.length) { return val; }
    return input.substring(0, 1).toUpperCase() + (lowerRest ? input.substring(1).toLowerCase() : input.substring(1));
}
function clean (val) {
    let text   = val;
    let result = undefined;
    for (let i = 0; i < text.length; ++i) {
        let target      = text.charAt(i);
        let expansion   = target.normalize('NFD');
        if (expansion.length > 1) {
            isVoid(result) && (result = text.substring(0, i));
            for (let j = 0; j < expansion.length; ++j) {
                let point = expansion.charCodeAt(j);
                if (!isNonSpacingMark(point)) {
                    result += String.fromCharCode(point);
                }
            }
        } else if (!isVoid(result)) {
            result += target;
        }
    }
    return result || text;
}
function format (val, config) {
    let cap = config && config.capitalize;
    let lwr = config && config.lowerRest;
    let trm = config && config.trim;
    let cln = config && config.clean;
    let res = prepare(val, !trm);
    if (cln) { res = clean(res); }
    if (cap) { res = capitalize(res, lwr); }
    return res;
}
function hasContent (val, output) {
    let input = prepare(val);
    let limit = input.length - 1;
    let left  = 0;
    let right = limit;
    let sL    = true;
    let sR    = true;
    for (let i = 0; i < input.length; ++i) {
        if (sL) {
            let point = input.charCodeAt(i);
            if (!isWhitespace(point)) {
                left = i;
                sL   = false;
            }
        }
        if (sR) {
            let j = limit - i;
            let point = input.charCodeAt(j);
            if (!isWhitespace(point)) {
                right = j;
                sR    = false;
            }
        }
        if (!(sL || sR)) { break; }
    }
    if (!sL) {
        output && output.push(input.substring(left, right + 1));
        return true;
    }
    return false;
}
function compareCI (l, r) {
    let a  = prepare(l, true);
    let b  = prepare(r, true);
    let nL = isVoid(l) || !hasContent(a);
    let nR = isVoid(r) || !hasContent(b);
    return nL && nR ?  0
         : nL       ? -1
         : nR       ?  1
         : a.toLowerCase().localeCompare(b.toLowerCase());
}
function equalsCI (l, r) { return compareCI(l, r) === 0; }
function compareAI (l, r) {
    let a  = clean(l, true);
    let b  = clean(r, true);
    let nL = isVoid(l) || !hasContent(a);
    let nR = isVoid(r) || !hasContent(b);
    return nL && nR ?  0
         : nL       ? -1
         : nR       ?  1
         : a.localeCompare(b);
}
function equalsAI (l, r) { return compareAI(l, r) === 0; }
function compareCIAI (l, r) {
    let a  = clean(l, true);
    let b  = clean(r, true);
    let nL = isVoid(l) || !hasContent(a);
    let nR = isVoid(r) || !hasContent(b);
    return nL && nR ?  0
         : nL       ? -1
         : nR       ?  1
         : a.toLowerCase().localeCompare(b.toLowerCase());
}
function equalsCIAI (l, r) { return compareCIAI(l, r) === 0; }
function containsCI (v, s) {
    return (v || '').toLowerCase().indexOf(s || '') >= 0;
}
function containsAI (v, s) {
    return clean(v).indexOf(clean(s)) >= 0;
}
function containsCIAI (v, s) {
    return clean(v).toLowerCase().indexOf(clean(s).toLowerCase()) >= 0
}
function includesCIAI (v, s) {
    return clean(v).toLowerCase().includes(clean(s).toLowerCase()) 
}
const Service  = {
    // normaliza e apara.
    prepare,
    // converte a primeira letra em maíuscula.
    capitalize,
    // format a texto usando trim, capitalize e clean.
    format,
    // remove acentos.
    clean,
    // testa se há caracteres que não sejam espaços e formatação.
    hasContent,
    // comparação ignorando maiúsculas/minúsculas.
    compareCI,
    // igualdade ignorando maiúsculas/minúsculas.
    equalsCI,
    // comparação ignorando acentos.
    compareAI,
    // igualdade ignorando acentos.
    equalsAI,
    // comparação ignorando maiúsculas/minúsculas e acentos.
    compareCIAI,
    // igualdade ignorando maiúsculas/minúsculas e acentos.
    equalsCIAI,
    // verifica se o termo existe no valor, ignorando maiúsc./minúsc.
    containsCI,
    // verifica se o termo existe no valor, ignorando acentuação.
    containsAI,
    // verifica se o termo existe no valor, ignorando maiúsc./minúsc e acentos.
    containsCIAI,
    // verifica se o termo contem no valor, ignorando maiúsc./minúsc e acentos.
    includesCIAI
}
export default Service;
