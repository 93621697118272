import Layout from "../../Internal/Layout.vue";
import MyExamsPage from "./MyExams.vue";

const MyExamsRoutes = {
  component: Layout,
  children: [
    {
      name: "exams",
      path: "/",
      component: MyExamsPage,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
      },
    },
  ],
};

export default MyExamsRoutes;
