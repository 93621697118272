<template>
  <div class="cdb-rdb">
    <v-alert
      v-show="showSuccess"
      transition="scroll-x-reverse-transition"
      class="alert"
      type="success"
      border="left"
      elevation="3">
      {{ message }}
    </v-alert>
    <v-row class="flex-column" align="start">
      <v-col class="d-flex flex-column align-left py-0">
        <span class="text-filelds">Nome do ativo</span>
      </v-col>
      <v-col>
        <v-text-field
          :loading="loading"
          v-model="codigo"
          placeholder="Insira o nome do ativo"
          color="secondary"
          required
          outlined
          dense
          rounded
          height="10"
          class="select"
          @keyup="saveCodigo"
          :rules="[v => !!v || 'Campo obrigatório',
                   v => (v &&  v.length>3) || 'O codigo deve conter de 4 a 19 caracteres.',
                   v => (v &&  v.length<20) || 'O codigo deve conter de 4 a 19 caracteres.',]"
        ></v-text-field>
      </v-col>

      <v-col class="d-flex flex-column align-left py-0">
        <span class="text-filelds">Descrição do ativo</span>
      </v-col>
      <v-col>
        <v-text-field
          :loading="loading"
          v-model="description"
          placeholder="Insira a descrição do ativo"
          color="secondary"
          required
          outlined
          dense
          rounded
          height="10"
          @keyup="saveDescription"
          class="select"
          :rules="[v => !!v || 'Campo obrigatório',
                   v => (v &&  v.length>3) || 'O codigo deve conter de 4 a 100 caracteres.',
                   v => (v &&  v.length<101) || 'O codigo deve conter de 4 a 100 caracteres.',]"
        ></v-text-field>
      </v-col>

      <v-col class="d-flex flex-column align-left py-0">
        <span class="text-filelds">Emissor</span>
      </v-col>
      <v-col>
        <v-autocomplete
          :items="entityCreditRisk"
          :loading="loading"
          item-text="fullName"
          item-value="entityId"
          v-model="emissor"
          placeholder="Insira o Emissor"
          color="secondary"
          required
          outlined
          rounded
          dense
          @change="saveEmissor"
          clearable
          attach=""
          class="select"
          :filter="filterObject"
          :rules="[(v) => v != null || 'Campo obrigatório']"
        ></v-autocomplete>
      </v-col>

      <v-col class="d-flex flex-column align-left py-0">
        <span class="text-filelds">Custodiante</span>
      </v-col>
      <v-col>
        <v-autocomplete
          :items="custodians"
          :loading="loading"
          item-text="fullName"
          item-value="entityId"
          v-model="custodiante"
          placeholder="Insira o custodiante"
          color="secondary"
          required
          outlined
          dense
          rounded
          height="10"
          @change="saveCustodiante"
          class="select"
          clearable
          attach=""
          :filter="filterObject"
          :rules="[(v) => v != null || 'Campo obrigatório']"
        ></v-autocomplete>
      </v-col>

      <v-row class="d-flex flex-wrap flexx">
        <div class="bloco">
          <v-col class="d-flex flex-column align-left py-0">
            <span class="text-filelds">Estratégia</span>
          </v-col>

          <v-col cols="1" class="column">
            <v-autocomplete
              :items="estrategias"
              :loading="loading"
              item-text="descricao"
                item-value="idEstrategia"
                v-model="estrategia"
              placeholder="Estrategia"
              color="secondary"
              required
              outlined
              background-color="#f6f6f6"
              :return-object="true"
              rounded
              dense
              @change="saveEstrategia"
              class="select"
              attach=""
              :rules="[(v) => !!v || 'Campo obrigatório']"
            ></v-autocomplete>
          </v-col>
        </div>

       <!-- <div class="bloco">
          <v-col class="d-flex flex-column align-left py-0">
            <span class="text-filelds">Indice referência</span>
          </v-col>
          <v-col cols="1" class="column">
            <v-autocomplete
              attach
              :items="indices"
              :loading="loading"
              item-text="descricao"
              item-value="idIReferencia"
              v-model="indice"
              placeholder="Indice"
              color="secondary"
              background-color="#f6f6f6"
              :return-object="true"
              outlined
              rounded
              dense
              @change="saveIndice"
              class="select"
              :rules="[(v) => !!v || 'Campo obrigatório']"
            ></v-autocomplete>
          </v-col>
        </div>-->

        <div class="bloco">
          <v-col class="d-flex flex-column align-left py-0">
            <span class="text-filelds">Indexador</span>
          </v-col>
          <v-col cols="1" class="column">
            <v-autocomplete

              :items="indexadores"
              :loading="loading"
              item-text="descricao"
              item-value="idIndexador"
              v-model="indexador"
              placeholder="Indexador"
              color="secondary"
              background-color="#f6f6f6"
              :return-object="true"
              outlined
              rounded
              dense
              @change="saveIndexador"
              class="select"
              attach=""
              :rules="[(v) => !!v || 'Campo obrigatório']"
            ></v-autocomplete>
          </v-col>
        </div>

        <div class="bloco">
          <v-col class="d-flex flex-column align-left py-0">
            <span class="text-filelds">FGC</span>
          </v-col>
          <v-col cols="1" class="column">
            <v-autocomplete
               attach=""
              :items="fgcOptions"
              :loading="loading"
              item-text="descricao"
              item-value="id"
              v-model="fgc"
              placeholder="FGC"
              color="secondary"
              background-color="#f6f6f6"
              :return-object="true"
              outlined
              rounded
              dense
              @change="saveFgc"
              class="select"

              :rules="[(v) => v != null || 'Campo obrigatório']"
            ></v-autocomplete>
          </v-col>
        </div>

        <div class="bloco">
          <v-col class="d-flex flex-column align-left py-0">
            <span class="text-filelds">Data Aplicação </span>
          </v-col>
          <v-col class="py-0 flex-grow-0 flex-shrink-1" style="padding: 0">
            <DatePiker @change="saveDataAplicacao"  />

          </v-col>
        </div>

        <div class="bloco">
          <v-col class="d-flex flex-column align-left py-0">
            <span class="text-filelds">Data Vencimento </span>
          </v-col>
          <v-col class="py-0" style="padding: 0">
              <DatePiker @change="saveVencimento" />

          </v-col>
        </div>

        <div class="bloco" v-if="estrategia.idEstrategia !== 2">
          <v-col class="d-flex flex-column align-left py-0">
            <span class="text-filelds">{{indice.rateFeesDescription ? indice.rateFeesDescription : "Taxa"}}</span>
          </v-col>
          <v-col class="py-0" style="padding: 0"   >
            <ValorMonetario @save="saveTaxa" />
          </v-col>
        </div>

        <div class="bloco">
          <v-col class="d-flex flex-column align-left py-0">
            <span class="text-filelds">Valor Aplicação(R$)</span>
          </v-col>
          <v-col class="py-0" style="padding: 0">
            <ValorMonetario @save="saveValorAplicacao" />
          </v-col>
        </div>

        <div class="bloco">
          <v-col class="d-flex flex-column align-left py-0">
            <span class="text-filelds">Tipo Remuneração</span>
          </v-col>
          <v-col cols="1" class="column">
            <v-autocomplete
               attach=""
              :items="tiposRemuneracao"
              :loading="loading"
              item-text="descricao"
              item-value="idTremuneracao"
              v-model="remuneracao"
              placeholder="Tipo Remuneração"
              color="secondary"
              background-color="#f6f6f6"
              :return-object="true"
              outlined
              rounded
              dense
              @change="saveRemuneracao"
              class="select"
              :rules="[(v) => !!v || 'Campo obrigatório']"
            ></v-autocomplete>
          </v-col>
        </div>

        <div class="bloco" v-if="estrategia.idEstrategia === 2">
          <v-col class="d-flex flex-column align-left py-0">
            <span class="text-filelds">Percentual do Indexador</span>
          </v-col>
           <v-col class="py-0" style="padding: 0" >
            <ValorMonetario @save="savePercentual" />
          </v-col>
        </div>

        <div class="bloco">
          <v-col class="d-flex flex-column align-left py-0">
            <span class="text-filelds">Liquidez</span>
          </v-col>
          <v-col>
            <v-select
              v-model="liquidez"
              :items="liquidezOptions"
              item-text="name"
              item-value="value"
              placeholder="Selecione o tipo de liquidez"
              color="secondary"
              required
              outlined
              rounded
              dense
              height="0"
              @change="saveLiquidez"
              class="select"
              attach=""
              :rules="[(v) => v != null || 'Campo obrigatório']"
            ></v-select>
          </v-col>
        </div>
      </v-row>
    </v-row>
  </div>
</template>

<script lang="js">
import ApiService from "../../../../../services/ApiService";
import DatePiker from "../componentes/Date-Piker.vue";
import dateHelper from "../../../../../Utils/DateHelper";
import ValorMonetario from "../componentes/Valor-Monetario.vue";
import textUtil from "../../../../../Utils/TextUtil.js"

export default {
  name: "cdbRdb",

  props: {
    respGetAtiTipUserId_prop: { type: Array },
    userToAtivos: { type: Object },
    closing: { type: Boolean }
  },

  data() {
    return {
      dateHelper,
      textUtil,
      api: new ApiService(),
      entityCreditRisk: [],
      custodians:[],
      estrategias: [],
      estrategiaAux: [],
      indices: [],
      tiposRemuneracao: [],
      items: [],
      indexadores: [],
      date: "",
      menu: "",
      user: "",
      taxa: "",
      ativos: [],
      userToAtivo: {ativoId:0,
        userId:0, investmentDate:undefined,InvestedValue:undefined,
        ativo: { codigo:undefined,idTproduto:1,descricao:undefined,idEntidade:undefined,idCustodiante:undefined,idEstrategia:undefined,idIreferencia:undefined,idIndexador:undefined,fgc:undefined,idTremuneracao:undefined,liquidez:undefined,
       cdbRdb:{vencimento:undefined,taxa:undefined,percentualIndexador:undefined}
        },
      },
      ativo: {},
      codigo: "",
      description: "",
      emissor: null,
      custodiante: null,
      estrategia: "",
      indice: "",
      indexador: "",
      vencimento: "",
      remuneracao: "",
      percentual: null,
      fgc: null,
      fgcOptions:[],
      fgcOption: [
        { descricao: "Não", id: 0 },
        { descricao: "Sim", id: 1 },
      ],
      liquidezOptions: [
        { name: "Diária", value: 0 },
        { name: "No vencimento", value: 1 },
      ],
      liquidez: null,
      loading: false,
       message: null,
    showSuccess: false,
    usersActive:[],
    dataVencimento:"",
    dataAplicacao:"",
    checkDataAplicacao:false,
    checkDataVencimento:false,
    };
  },

  created() {
    this.api.getListEntity(this.$store,4)
      .then((resp) => {
        this.entityCreditRisk= resp;
      })
      .catch ((error)=> {
        this.botaoCompraClicado = false;
        this.$toast.error(error.Error);
      });
    this.api.getListEntity(this.$store,8)
      .then((resp) => {
        this.custodians= resp;
      })
      .catch ((error)=> {
        this.botaoCompraClicado = false;
        this.$toast.error(error.Error);
      });
    this.api.getEstrategias(1)
      .then((resp) => {
        this.estrategias= resp;
      })
      .catch ((error)=> {
        this.botaoCompraClicado = false;
        this.$toast.error(error.Error);
      })

    this.save(this.userToAtivo);
  },
  mounted() {
    this.$emit('buy:activeId', undefined);
  },

  components: {
    DatePiker,
   // DatePikerRules,

    ValorMonetario,
  },

  methods: {
    filterObject(item, queryText, itemText) {
      if (!queryText|| queryText === '') {
        return true;
      }
      return this.textUtil.includesCIAI(item.code||item.fullName,queryText);
    },
    getEstrategias2(){

      var _return= this.api.getEstrategias2(this.$store,1);
      return _return;
    },

    save(dadosDoAtivo) {
      this.userToAtivo.TpMovimento=1;
    },

    saveCodigo() {
      this.userToAtivo.ativo.codigo = this.codigo;
      this.$emit("save", this.userToAtivo);
    },

    saveDescription() {
      this.userToAtivo.Description=this.description;
      this.userToAtivo.ativo.descricao = this.description;
      this.$emit("save", this.userToAtivo);
    },

    saveEmissor() {
      this.userToAtivo.ativo.idEntidade = this.emissor;
      //this.$emit("save", this.userToAtivo);
    },

    saveCustodiante() {
      this.userToAtivo.CustodianteId  = this.custodiante;
      this.userToAtivo.ativo.idCustodiante = this.custodiante;
     // this.$emit("save", this.userToAtivo);
    },

    saveEstrategia() {

      this.loading = true;
      this.userToAtivo.ativo.idEstrategia = this.estrategia.idEstrategia;
      this.indices=this.estrategia.indiceReferencia;
      if (this.estrategia.idEstrategia == 2){
        this.userToAtivo.ativo.cdbRdb.taxa = 0;
        this.taxa=0;
      }
      if (this.indices.length ==1){
        this.indice=this.indices[0];
        this.saveIndice();
      }else{
         this.indice=undefined;
         this.indexador=undefined;
         this.fgc=undefined;
         this.remuneracao=undefined;
      }
      //this.$emit("save", this.userToAtivo);
      this.loading = false;
    },

    saveIndice() {

      this.userToAtivo.ativo.idIreferencia = this.indice.idIReferencia;
      this.indexadores= this.indice.indexador;
      if (this.indexadores.length ==1){
        this.indexador=this.indexadores[0];

        this.saveIndexador();
      }else{
         this.indexador=undefined;
         this.fgc=undefined;
         this.remuneracao=undefined;
      }

      this.$emit("save", this.userToAtivo);
    },

    saveIndexador() {
      this.userToAtivo.ativo.idIndexador = this.indexador.idIndexador;
      this.fgcOptions= this.indexador.fgc;
      if (this.fgcOptions.length ==1){
        this.fgc=this.fgcOptions[0];
        this.saveFgc();
      }else{

         this.fgc=undefined;

      }
     this.tiposRemuneracao=this.indexador.tipoRemuneracao;
      if (this.tiposRemuneracao.length ==1){
        this.remuneracao=this.tiposRemuneracao[0];
        this.saveRemuneracao();
      }else{
          this.remuneracao=undefined;
      }
      this.$emit("save", this.userToAtivo);
    },

    saveFgc() {
      this.userToAtivo.ativo.fgc = this.fgc.id;
      this.$emit("save", this.userToAtivo);
    },

    saveRemuneracao() {
      this.userToAtivo.ativo.idTremuneracao = this.remuneracao.idTRemuneracao;
      this.$emit("save", this.userToAtivo);
    },

    saveLiquidez() {
      this.userToAtivo.ativo.liquidez = this.liquidez;

      this.$emit("save", this.userToAtivo);

    },

    saveDataAplicacao(data) {
      this.userToAtivo.investmentDate = data;
      this.$emit("save", this.userToAtivo);
    },

    saveVencimento(data) {


     // if (data>= moment(new Date()).format("YYYY-MM-DD")){
        this.userToAtivo.ativo.cdbRdb.vencimento  = data;
        // this.checkDataVencimento=false;
     /*  }else{
         this.$toast.error("Data de vencimento "+this.dateHelper.formatDate(data)+" tem que ser futura.");
          this.dataVencimento=undefined;
          this.botaoCompraClicado = false;
          this.$emit("btnCompraClicado", false);

         this.userToAtivo.ativo.cdbRdb.vencimento  = undefined;
         this.checkDataVencimento=true;
       }
        */
        this.$emit("save", this.userToAtivo);
    },

    saveValorAplicacao(valorAplicacao) {
      this.userToAtivo.InvestedValue = valorAplicacao;
      this.$emit("save", this.userToAtivo);
    },

    saveTaxa(taxa) {
      if (this.estrategia.idEstrategia != 2){

        this.userToAtivo.ativo.cdbRdb.taxa = taxa;
         this.userToAtivo.ativo.cdbRdb.percentualIndexador=100;
      this.$emit("save", this.userToAtivo);


      }
    },

    savePercentual(percentual) {
       if (this.estrategia.idEstrategia == 2){
      this.userToAtivo.ativo.cdbRdb.percentualIndexador = percentual;
       this.userToAtivo.ativo.cdbRdb.taxa =percentual;

       this.$emit("save", this.userToAtivo);
    }
  },
  },
  watch: {
     respGetAtiTipUserId_prop(nv){

     },
    userToAtivos(nv, ov) {

      if (nv) {

        this.userToAtivo = nv;
      }
    },
  },

  computed: {
    userFormated() {
      return this.$store.getters.$userLogged;
    },

    typeUser() {
      return this.$store.getters.$userType;
    },
  },
};
</script>

<style  scoped>
.alert {
  position: absolute;
  right: 5%;
  top: 15%;

  z-index: 10;
}
.cdb-rdb {
  margin-top: 0 !important;
  width: 100%;
  padding-top: 0 !important;
  padding-bottom: 5rem;
}
.flexx {
  margin: 0 auto;
  width: 95%;
}
.bloco {
  flex: 1 0 180px;
  width: 100%;
}
.bloco .btn {
  height: 40px;
  background: #f6f6f6;
  border-radius: 30px;
  color: #9f9f9f;
  padding: 0.5rem 3rem 0.5rem 1rem;
  min-width: 100% !important;
  font-size: clamp(0.45em, 0.9em, 1em);
  display: flex;
  align-items: center;
}
.column {
  min-width: 100% !important;
  white-space: nowrap;
}
.text-filelds {
  font-size: 14px;
}
</style>