<template>
  <v-container class="liquidez" fluid>

    <DataTable
      :loading="loading"
      :emErro="emErro"
      :errorMessage="errorMessage"
      :calculos="calculos.collection" />

    <v-card class="card">
      <div class="title"></div>

      <div class="grafico">
        <Bar
          :dates="dates"
          :chartData="arrTeste"
          :chartData2="arrTeste2"
          :options="options"
          :plugins="plugins"
          :colors="colors"
          label="Rentabilidade acumulada"
          label2="Rentabilidade mensal"
          class="grafico-teste"
        />
      </div>
    </v-card>
  </v-container>
</template>

<script>
import moment from "moment";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Bar from "@/components/graph/BarAndLine-liquidez.vue";
// import * as Mock from "@/components/mock/mock.js";
import ApiService from "../../../services/ApiService";
import CalculadorAtivo from "../../../services/Calculador";
import currencyHelper from "../../../Utils/CurrencyHelper";
import percentageHelper from "../../../Utils/PercentageHelper";
import dateHelper from "../../../Utils/DateHelper";
import EventBus from '../../Bus/bus';
import DataTable from "./components/DataTable.vue";

export default {
  data() {
    return {
      currencyHelper,
      percentageHelper,
      dateHelper,
      api: new ApiService(),
      calculador: new CalculadorAtivo().setDefaultLoaders(),
      loading: false,
     // user: {},
      chartData: [],
      dates: [],
      arrTeste: [],
      arrTeste2: [],
      colors: [
        "#D9E1F2",
        "#FCE4D6",
        "#FFF2CC",
        "#E2EFDA",
        "#F8CBAD",
        "#C9C9C9",
      ],
      plugins: [ChartDataLabels],
      options: {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            top: 30,
          },
        },
        title: {
          display: false,
        },
        elements: {
          line: {
            fill: false,
            tension: 0,
          },
        },
        legend: {
          display: false,
          labels: {
            usePointStyle: true,
          },
          position: "top",
          padding: 10,
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
                drawBorder: false,
              },

              ticks: {
                callback: (value, index, ticks) => {
                  return this.$numberToLocale2(value);
                },
                stepSize: 0, // aqui eu faço o intervalo entre os números
                max: 10000, // valor máximo, porem melhorar
              },
            },
            {
              type: "linear",
              display: true,
              position: "right",
              id: "percentage",
              gridLines: {
                display: false,
                drawOnChartArea: false,
                drawBorder: false,
              },
              ticks: {
                callback: (value, index, values) => {
                  return `${value},00%`;
                },
                beginAtZero: true,
                max: 110,
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            formatter: function(value, context) {
              return value !== null
                  && value !== undefined
                  && value.toLocaleString("pt-BR", { style: "currency", currency: "BRL" });
            },
          },
        },
        tooltips: {
          callbacks: {
            label: function(tooltip, context) {
              return tooltip.yLabel
                  && tooltip.yLabel
                      .toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
                  || "0.00";;
            }
          }
        },
      },
      headers: [
        {
          text: "Período",
          align: "center",
          filterable: false,
          sortable: false,
          value: "title",
        },
        { text: "Valor Bruto", value: "saldo", sortable: false, align: "right" },
        { text: "Valor Líquido ", value: "liquido", sortable: false, align: "right" },
        {
          text: "% da Carteira",
          value: "participacao",
          sortable: false,
          align: "right"
        },
        { text: "Valor acumulado bruto", value: "valor", sortable: false, align: "right" },
        { text: "% da carteira acumulado", value: "percent", sortable: false, align: "right" },
        { text: "Valor acumulado liquido", value: "acumulado", sortable: false, align: "right" },
      ],
      desserts: [],
      calculos: {},
      emErro: false,
      errorMessage: "",
    };
  },
  created() {
    EventBus.$emit("ListaDeUsuariosSelected", true);
    this.getInfos();

    EventBus.$on('reloadPagesForCallActivesAgain', this.ebReload);
  },
  beforeDestroy () {
    EventBus.$off('reloadPagesForCallActivesAgain', this.ebReload);
  },

  components: {
    Bar,
    DataTable
  },

  methods: {
    ebReload (data) {
      if (!data) { return; }
      this.getInfos();
    },
    setError (reason) {
      this.emErro = !!reason;
      if (!reason) {
        this.errorMessage = undefined;
        return;
      }
      if (reason.body) {
        this.errorMessage = reason.body.Message ||
          (reason.body.content && reason.body.content.Message) ||
          undefined;
      }
      this.errorMessage = reason.message || reason.Error || `${reason}`;
    },
    getInfos() {
      this.setError();
      this.loading = true;

      this.api.getUserToAtivoUserLiquidez(this.$store)
        .then((res) => {
          this.desserts.push(
            res.content.to0Days,
            res.content.to30Days,
            res.content.to90Days,
            res.content.to180Days,
            res.content.to360Days,
            res.content.moreOf360Days,
            res.content.all
          );
          this.calculos = res.evolucao;
          this.calculador.echo(this.calculos);
          this.setChartData(this.calculos);
          this.renderChart();

          this.loading = false;
        })
        .catch((reason) => {
          this.loading = false;
          this.setError(reason);
        });
    },
    roundP10 (qtty, down) {
      let val = Math.log10(qtty);
      let pow = Math.floor(val);
      let mul = down
        ? Math.floor(Math.pow(10, val - pow))
        : Math.ceil(Math.pow(10, val - pow));
      return mul * Math.pow(10, pow);
    },
    setChartData (calc) {
      for (let i = this.chartData.length - 1; i >= 0; --i) {
        this.chartData.pop();
      }
      let root = calc.collection.slice(0, calc.collection.length - 1);
      let { min, max } = root.reduce((r, e) => {
        if (e.saldo < r.min) { r.min = e.saldo; }
        if (e.saldo > r.max) { r.max = e.saldo; }
        return r;
      }, { min: Number.MAX_VALUE, max: -Number.MAX_VALUE });
      let target = this.options.scales.yAxes[0];
      target.ticks.max = max === 0 ? 0 : this.roundP10(max);
      target.ticks.min = min >= 0 ? 0 : this.roundP10(min, true);
      root.map((c, i) => {
        return {
          data: moment(new Date(), "DDMMYYYY").format("MMM/YYYY"),
          valors: {
            id: i + 1,
            date: dateHelper.formatDate(new Date),
            percent: (c.percent * 100).toFixed(2),
            slug: c.title,
            valor: c.saldo
          }
        }
      }).forEach((e) => this.chartData.push(e));
    },

    $numberToLocale(val) {
      let formato = { minimumFractionDigits: 2, currency: "BRL" };
      return val.toLocaleString("pt-BR", formato);
    },

    $numberToLocale2(val) {
      let formato = { minimumFractionDigits: 2, currency: "BRL" };
      return val.toLocaleString("pt-BR", formato);
    },

    getColor(valor) {
      switch (valor) {
        case "0 dias":
          return "#D9E1F2";
        case "de 1 a 30 dias":
          return "#FCE4D6";
        case "de 31 a 90 dias":
          return "#fff2cc";
        case "de 91 a 180 dias":
          return "#e2efda";
        case "de 181 a 360 dias":
          return "#f8cbad";
        case "acima de 361 dias":
          return "#c9c9c9";
        case "Total":
          return "transparent";
      }
    },

    rowBackground(valor) {
      if (valor.title === "Total") {
        return "row-total";
      }
    },

    renderChart() {
      this.dates = this.chartData.map((d) => d.valors.slug);

      this.arrTeste = this.chartData.map((d) => d.valors.valor);
      this.arrTeste2 = this.chartData.map((d) => d.valors.percent);
    },
  },
};
</script>

<style scoped>
.liquidez {
  padding: 0 1rem;
}
.total:hover::before {
  opacity: 0 !important;
}
.chip {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
}
.custom_table_class thead th:lsat-child {
  border-radius: 20px !important;
}
.card {
  border-radius: 20px;
  padding: 1rem;
  margin: 1.5rem 0;
  box-shadow: none !important;
}
.title {
  margin-bottom: 1.5rem;
  padding: 1rem 0;
}
/* .grafico-teste {
  border: 1px solid;
} */
</style>