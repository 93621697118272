<template>
    <div class="date-range">
        <v-row class="date-component">
            <span>Interv. de:</span>
            <input type="date"
                    title="Data inicial"
                    placeholder="Data inicial"
                    name="from-date"
                    v-model="fromValue"
                    @change="changeRange" />
            <span>até</span>
            <input type="date"
                    title="Data final"
                    placeholder="Data final"
                    name="to-date"
                    v-model="toValue"
                    @change="changeRange" />
            <button type="button" @click="clearRange" :disabled="!(this.toValue || this.toValue)">X</button>
        </v-row>
    </div>
</template>

<script lang="js">
import debounce from '../../../../Utils/debounce';
export default {
    props: {
        'require-from': { type: Boolean },
        'require-to': { type: Boolean }
    },
    data() {
        return {
            fromValue: undefined,
            toValue: undefined,
            fromDate: undefined,
            toDate: undefined,
            doChange: undefined,
        }
    },
    methods: {
        validate   () {
            if (this.fromValue && this.toValue && (this.fromValue >= this.toValue)) {
                this.$toast('Intervalo inválido!', { variant: 'erro' });
                return false;
            }
            return true;
        },
        clearRange () {
            if (!(this.fromValue || this.toValue)) { return; }
            this.fromValue = this.toValue = undefined;
            this.$emit('date-range-changed', [this.fromValue, this.toValue]);
        },
        changeRange () {
            this.doChange();
        },
    },
    mounted() {
        this.doChange = debounce(
                () => this.validate() &&
                    this.$emit('date-range-changed', [this.fromValue, this.toValue]),
                500);
    }
}
</script>

<style scoped>
.date-range {
    display: flex;
    flex-direction: row;
    min-width: 54vw;
    max-width: 54vw;
}
.date-range .date-component {
    display: flex;
    flex-direction: row;
    margin: 10px;
}
.date-range .date-component > span {
    padding: 0.75rem;
}
.date-range .date-component > input,
.date-range .date-component > button {
    border: 1px solid rgba(0, 0, 0, 0.38);
    color: rgba(0, 0, 0, 0.38);
    border-radius: 28px;
    padding: 0.5rem;
    margin: 0.125rem;
}
.date-range .date-component > div > button {
    margin-left: 4px;
}
.date-range .date-component .label {
    color: rgba(0, 0, 0, 0.38);
    flex-basis: 10px;
    max-width: 80px;
}
.date-range .date-component .date {
    flex-basis: 220px;
    max-width: 220px;
}
@media screen and (max-width: 1010px) {
    .itens-1, .date-range, .date-range .date-component {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
    }
}
@media screen and (max-width: 592px) {
    .date-range .date-component {
        flex-direction: column;
    }
}
</style>