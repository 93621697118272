<template>
  <div class="gestao-usuario">
    <TreeModal :items="tree" :spec="modalDim" v-if="showTree" />
    <div class="gestao-topo">
      <div class="title">
        <v-btn color="primary" @click="showUserTree">Carteira</v-btn>
      </div>
      <div class="carteiras-usuarios">
        <div class="icon-carteira">
          <figure>
            <img src="@/assets/icons/wallet_icon.svg" alt="" />
          </figure>
        </div>
        <div class="info-carteira">
          <span class="title-carteira"> {{ totalUsuarios }}/{{ maxUsers }}</span>
          <span class="descricao-carteira">Carteira/Usuários</span>
        </div>
      </div>
    </div>

    <!-- COMPONENTES QUE PODEM SER SEPARADOS -->
    <!-- <div class="cards-gestao">
      <div class="card ray">
        <div class="img">
          <img src="@/assets/icons/raio_icon.svg" alt="" />
        </div>
        <div class="descricao">
          <div class="numero">100</div>
          <div class="texto">Usuários ativos</div>
        </div>
      </div>

      <div class="card check">
        <div class="img">
          <img src="@/assets/icons/check_icon.svg" alt="" />
        </div>
        <div class="descricao">
          <div class="numero">100</div>
          <div class="texto">Usuários ativos</div>
        </div>
      </div>

      <div class="card dollar">
        <div class="img">
          <img src="@/assets/icons/dollar_icon.svg" alt="" />
        </div>
        <div class="descricao">
          <div class="numero">100</div>
          <div class="texto">Usuários ativos</div>
        </div>
      </div>

      <div class="card warnin">
        <div class="img">
          <img src="@/assets/icons/warning_icon.svg" alt="" />
        </div>
        <div class="descricao">
          <div class="numero">100</div>
          <div class="texto">Usuários ativos</div>
        </div>
      </div>
    </div> -->

    <!-- COMPONENTE DE LISTA DE USUÁRIOS QUE VAI SE SEPARADO TAMBEM -->
    <v-card width="100vw">
      <v-container fluid fill-height class="container">
        <v-row class="top" justify="space-between">
          <v-col cols="12" sm="6" md="3">
            <span class="title-usuarios">Usuários</span>
          </v-col>

          <v-col cols="12" sm="6" md="3" class="col-2 coluna-input">
            <v-tooltip bottom color="blue">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  id="novoUsuario"
                  class="tooltip"
                  @click="showModal('novoUsuario', $event)"
                >
                  <img
                    src="@/assets/icons/add_circle_icon.svg"
                    alt="dashboard"
                    class="icon"
                  />
                </v-btn>
              </template>
              <span>Novo usuário</span>
            </v-tooltip>

            <v-text-field
              placeholder="Pesquisar"
              outlined
              rounded
              dense
              prepend-inner-icon="mdi-magnify"
              v-model="searchUser"
            ></v-text-field>
          </v-col>
        </v-row>

        <!-- TABELA PODE SER SEPARADA TAMBAM -->
        <v-data-table
          :headers="headers"
          :items="desserts"
          :custom-filter="filterUser"
          :search="searchUser"
          class="elevation-1"
          @click:row="handleClick($event)"
        >
          <template v-slot:[`item.name`]="{ item }">
            <div class="profile">
              <div class="name-profile">
                <span>{{ item.name }}</span>
              </div>
            </div>
          </template>

           <template v-slot:[`item.email`]="{ item }">
            <div class="profile">
              <div class="name-profile">
                <span>{{ item.email }}</span>
              </div>
            </div>
          </template>

          <template v-slot:[`item.phoneNumber`]="{ item }">
            <div class="profile">
              <div class="name-profile">
                <span>{{ item.phoneNumber }}</span>
              </div>
            </div>
          </template>

          <template v-slot:[`item.created_at`]="{ item }">
            <div class="profile">
              <div class="name-profile">
                <span>{{ formatDate(item.created_at) }}</span>
              </div>
            </div>
          </template>

          <template v-slot:[`item.actions`]="{ item }" class="slot-btns">
            <div class="slot-btns">
              <v-img
                class="btn-icon"
                src="@/assets/icons/edit_icon.svg"
                @click="showModal('editarUsuario', $event, item)"
              />
              <v-img
                class="btn-icon"
                src="@/assets/icons/delete_outline_icon.svg"
                @click="deletItem(item)"
              />
            </div>
          </template>
          <template v-slot:top>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card class="card-dialog">
                <v-card-title class="text-h5"
                  ><span>Deletar usuário?</span></v-card-title
                >
                <v-card-text class="descricao-dialog">
                  Isso irá apagar permanentemente todas as informações e
                  transações anteriormente cadastradas
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="blue darken-1"
                    class="btn-cancel-dialog"
                    text
                    @click="closeDelete"
                    ><span>Cancelar</span></v-btn
                  >
                  <v-btn
                    color="blue darken-1"
                    class="btn-delete-dialog"
                    text
                    @click="deleteUsuario"
                    ><span>Deletar</span></v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
                <div class="btn-close-dialog" @click="closeDialog">
                  <v-img src="@/assets/icons/clear_icon.svg" />
                </div>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:no-data>
            <v-btn class="btn-tentar-de-novo" color="error">
              Nenhum usuário encontrado
            </v-btn>
          </template>
        </v-data-table>
      </v-container>
    </v-card>

    <v-dialog
      v-model="dialog"
      width="500"
      v-if="totalUsuarios > 1000000 ? (dialog = true) : (dialog = false)"
    >
      <v-card class="card-dialog-plano">
        <div class="img-notification">
          <img src="../../../assets/icons/notification_icon.svg" />
        </div>
        <v-card-title class="text-h5 title-limite-plano">
          Você atingiu o limite de carteiras do seu plano!
        </v-card-title>

        <v-card-text>
          Para continuar cadastrando novas carteiras, dê um upgrade no seu
          plano.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="btn-gerenciar-plano" text @click="dialog = false">
            Gerenciar Plano
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
@media screen and (max-width: 610px) {
  .carteiras-usuarios {
    width: 100% !important;
  }
  .gestao-topo .title .v-btn {
    margin: 1em;
  }
}
.card-dialog-plano {
  padding: 2rem 0;
  text-align: center;
}
.img-notification {
  /* border: 1px solid red; */
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #f25a5a;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img-notification img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.title-limite-plano {
  font-size: 15px !important;
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 27px !important;
  /* identical to box height */

  letter-spacing: 0.15px !important;

  color: #000000 !important;
}
.btn-gerenciar-plano {
  border: 1px solid;
  width: 100%;
  border-radius: 20px;
  padding: 1.4rem 0 !important;
}
.btn-tentar-de-novo {
  padding: 1.2rem 1rem !important;
  border-radius: 20px;
  color: white !important;
  transition: 0.2s ease-in;
  cursor: default;
}
.gestao-topo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  flex-wrap: wrap;
}
.carteiras-usuarios {
  padding: 1rem;
  background: #ffffff;
  box-shadow: -4px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  width: 304px;
  height: 100px;
  display: flex;
  align-items: center;
}
.icon-carteira figure {
  background: #e4e4e4;
  width: 58px;
  height: 58px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon-carteira figure img {
  filter: invert(67%) sepia(7%) saturate(19%) hue-rotate(26deg) brightness(88%)
    contrast(92%);
}
.info-carteira {
  margin-left: 0.5rem;
}
.info-carteira span {
  display: block;
}
.info-carteira .title-carteira {
  font-size: 25px;
  line-height: 37px;
  letter-spacing: 0.15px;
  color: #000000;
}
.info-carteira .descricao-carteira {
  font-weight: 300;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.15px;
  color: #000000;
}
.v-text-field {
  width: 100px !important;
}
.v-tooltip__content {
  border-radius: 30px !important;
  z-index: 20 !important;
}
.v-tooltip__content::after {
  content: " ";
  position: absolute;
  bottom: 100%; /* At the top of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent var(--principal-azul) transparent;
}
.top {
  padding: 1.5rem;
}
.col-2 {
  display: flex;
}
.v-text-field {
  width: 200px;
}
.col-2 .teste {
  padding-top: 10px;
  margin-right: 25px;
}
.v-card {
  border-radius: 20px;
  margin-top: 0.5rem;
}
.container {
  padding: 0;
}
.title-usuarios {
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 0.15px;
  color: #494949;
}
.v-data-table {
  width: 100%;
}
.btn-icon {
  cursor: pointer;
  width: 20px;
  display: inline-block;
  margin: 0 0.5rem 0;
}
.componentes-top {
  /* border: 1px solid red !important; */
  width: 100% !important;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.inputs-card {
  display: flex;
  align-items: center;
  justify-content: center;
}
.v-text-field {
  display: flex;
  align-items: center;
  justify-content: center;
}
.gestao-usuario {
  padding: 1.8rem;
}
.gestao-usuario .title h1 {
  font-weight: 300;
  font-size: 25px;
  line-height: 37px;
  letter-spacing: 0.15px;
  color: #000000;
}
.cards-gestao {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.cards-gestao .card {
  border-radius: 20px;
  padding: 1.8rem;
  background: white;
  flex: 1 0 300px;
  display: flex;
  align-items: center;
  margin: 0.3rem;
}
.card .img {
  width: 68px;
  height: 68px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1.5rem;
}
.ray .img {
  background: #b4e54c;
}
.check .img {
  background: #f2ab57;
}
.dollar .img {
  background: var(--principal-azul);
}
.dollar .img img {
  filter: invert(0%) sepia(0%) saturate(7490%) hue-rotate(353deg)
    brightness(115%) contrast(99%);
}
.warnin .img {
  background: #f25a5a;
}
.descricao .numero {
  font-size: 25px;
  line-height: 37px;
  letter-spacing: 0.15px;
}
.descricao .texto {
  font-weight: 300;
  font-size: 15px;
  line-height: 22px;
}

/* TABELAAAAA */
.profile {
  display: flex !important;
  align-items: center !important;
}
.profile .img-profile {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  border: 1px solid;
  overflow: hidden;
  margin-right: 0.3rem;
}
.img-profile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.v-data-table >>> th {
  /* border-spacing: 0 0.5rem; */
  /* border-top:  none !important; */
  border-top: none;
}
.v-data-table >>> td {
  /* padding: .5rem 0 !important; */
  padding-top: 0.8rem !important;
  padding-bottom: 0.8rem !important;
}
.card-dialog {
  text-align: center;
  padding: 2rem 0;
  position: relative;
}
.btn-close-dialog {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 25px;
  top: 25px;
  cursor: pointer;
}
.card-dialog .text-h5 {
  text-align: center !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.descricao-dialog {
  margin: 0.5rem 0;
}
.btn-delete-dialog {
  background: #f25a5a;
  border-radius: 20px;
  padding: 0 1rem !important;
}
.btn-delete-dialog span {
  color: white;
}
.btn-cancel-dialog {
  border-radius: 20px;
  padding: 0 1rem !important;
  border: 1px solid #e4e4e4;
}
.btn-cancel-dialog span {
  color: #e4e4e4;
}
/* FIM TABELAAA */
</style>

<script>
import { mapActions } from "vuex";
import ApiService from "../../../services/ApiService";
import EventBus from "../../Bus/bus";
import moment from "moment";
import TreeModal from "./components/TreeModal.vue";

export default {
  data() {
    return {
      dialog: false,
      controller: false,
      apiService: new ApiService(),
      dialogDelete: false,
      userToBeDeleted: undefined,
      mostrarModal: false,
      element: undefined,
      searchUser: "",
      search: "",
      reloadUsers: false,
      headers: [
        { text: "Nome", align: "start", true: "name", value: "name" },
        { text: "E-mail", value: "email", sortable: false },
        { text: "Telefone", value: "phoneNumber", sortable: false },
        { text: "Data de cadastro", value: "created_at" },
        { text: "", value: "actions" },
      ],
      desserts: [],
      user: undefined,
      maxUsers: null,
      showTree: false,
      tree: [],
      modalDim: { width: 0.8, height: 0.8 }
    };
  },
  components: { TreeModal },
  created() {
    this.callClientes();
    EventBus.$on("reloadUsers", this.ebReload);
    EventBus.$on("closeTreeModal", this.closeUserTree);
    this.user = this.$store.getters.$userLogged;
    this.maxUsers = (!(this.user
                  &&   this.user.planUse
                  &&   this.user.planUse.hasActivePlan)) ?
                       0 :
                       +this.user.planUse.maxLogins || 0;
  },
  beforeDestroy () {
    EventBus.$off("reloadUsers", this.ebReload);
  },
  methods: {
    ...mapActions(["PUT_CURRENT_USER"]),
    handleClick(e) {
      if (!this.controller) {
        this.$store.commit("SET_TOKEN_USER_SELECTED", e.userId || e.id);
        this.$store.commit("SET_USER_SELECTED", e);
        EventBus.$emit('newUserSelection', e);
        this.$router.push(`/visao-geral`);
      }
    },
    getColor(calories) {
      if (calories > 50) return "red";
      else if (calories > 100) return "orange";
      else return "green";
    },
    filterUser(value, search, item) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1
      );
    },
    formatDate(date) {
      return moment(date).format("DD/MM/yyyy");
    },
    deletItem(e) {
      this.controller = true;
      this.userToBeDeleted = e;
      this.dialogDelete = true;

      setTimeout(() => {
        this.controller = false;
      }, 1000);
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    deleteItemConfirm() {
      this.closeDelete();
    },
    closeDialog() {
      this.dialogDelete = false;
    },
    async callClientes() {
      let id = this.$store.getters.$userLogged.id
      try {
        let req = await this.apiService.get(`admin/entities/tree/${id}`);
        this.desserts.length = 0;
        this.desserts.push(...req.content);
        this.tree.length = 0;
        this.tree.push(...req.content);
        EventBus.$emit("ListaDeUsuarios", this.desserts);

      } catch (error) {
        this.$toast.error("Erro ao consultar usuários no banco de dados");
      }
    },
    async deleteUsuario() {
      try {
        let req = await this.apiService.delete("user/delete", this.userToBeDeleted);

        if (req.statusCode == 200) {
          this.$toast.success('Usuário deletado com sucesso')
          this.callClientes();
          this.dialogDelete = false;
        }
      } catch (error) {
        this.dialogDelete = false;
      }
    },
    showModal(e, evt, item) {
      this.controller = true;
      this.mostrarModal = true;

      if (e != "novoUsuario")
        EventBus.$emit("editarUsuario", item);

      if (e === evt.currentTarget.id) {
        if (!this.element) {
          this.element = evt.currentTarget;
        } else {
          this.element = evt.currentTarget;
        }
      }
      let obj = { show: this.mostrarModal, view: e };

      let showError = false;
      if (e == 'novoUsuario') {
        switch (this.user.planType) {
          case 0:
            showError = this.desserts.length == 2;
            break;
          case 1:
            showError = this.desserts.length == 5;
            break;
          case 2:
            showError = this.desserts.length == 10;
            break;
          case 3:
            showError = this.desserts.length == 20;
            break;
          case 4:
            showError = this.desserts.length == 250;
            break;
        }
      }

      if (showError) {
        this.$toast.error("Limite de carteira/usuários atingido");
      } else {
        EventBus.$emit("mostrarModal", obj);
      }

      setTimeout(() => {
        this.controller = false;
      }, 1000);
    },
    ebReload (data) {
      if (!data) { return; }
      this.callClientes();
    },
    getUserTreeList () {
      let result = this.desserts.map((u) => JSON.parse(JSON.stringify(u)));
      result.sort((l, r) => {
        let result = l.path.localeCompare(r.path);
        if (result !== 0) { return result; }
        return l.level === r.level ? 0 : l.level < r.level ? -1 : 1;
      });
      return result;
    },
    buildTree       () {
        const source        = this.getUserTreeList();
        const root          = source.length ? source[0] : undefined;
        const stack         = root && [root] || [];

        let   tree          = root && [root] || [];

        for (let i = 1; i < source.length; ++i) {
            let  parent     = stack.pop();
            let  next       = source[i];

            while (next.level > parent.level + 1) {
                const cand  = parent.children.filter((e) => next.path.startsWith(e.path));
                if (cand.length === 0) {
                    if (stack.length > 0) {
                        parent  = stack.pop();
                    } else {
                        throw new Error('Elemento sem pai');
                    }
                }
                stack.push(...(parent.children || []));
                parent      = cand[0];
            }

            const children  = parent.children || (parent.children = []);
            children.push(next);
            stack.push(parent);
        }
        return tree;
    },
    showUserTree () {
      this.showTree = true;
    },
    closeUserTree () {
      this.showTree = false;
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  computed: {
    totalUsuarios() {
      return  (!(this.user
              && this.user.planUse
              && this.user.planUse.hasActivePlan)) ?
              (this.desserts.length || 0) :
              this.user.planUse.activeLogins;
    }
  },
};
</script>