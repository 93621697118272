<template>
  <div>
    <FinanceExamList />
  </div>
</template>

<script>
import FinanceExamList from "./components/FinanceExamList.vue";

export default {
  name: "FinanceInfo",

  data: () => ({}),

  components: {
    FinanceExamList,
  },
};
</script>

<style></style>
