<template>
  <div class="top-container">
    <div class="itens-1">
      <div class="select">
        <v-select
          :items="caregoryList"
          item-text="name"
          item-value="value"
          :menu-props="{ bottom: true, offsetY: true }"
          @change="onChange($event)"
          v-model="valueToModel"
          placeholder="Selecione"
          color="secondary"
          dense
          required
          outlined
          rounded
          class="input"
        ></v-select>
      </div>

      <!--div class="btn-evolucao item">
        <div class="descricao-evolucao">
          <span>Visualizar Evolução</span>
        </div>
        <div class="btn-switch">
          <SwitchButton @changeSwitch="submitEventFromSwitch" :state="switchState" />
        </div>
      </div-->

      <div class="btn-rentabilidade" @click="cycle">Rentabilidade</div>

      <DateRange @date-range-changed="changeInterval" />
    </div>

    <div class="btns-ativos" ref="btnsAtivos" v-if="switchState">
      <div class="btn-cdi">
        <v-switch checked @change="changeSwitch('CDI')"
                  v-model="switchStateCDI"
                  label="CDI"/>
      </div>

      <div class="btn-ipca">
        <v-switch checked @change="changeSwitch('IPCA')"
                  v-model="switchStateIPCA"
                  label="IPCA"/>
      </div>

      <div class="btn-dolar">
        <v-switch @change="changeSwitch('DOLAR')"
                  v-model="switchStateDOLAR"
                  label="DÓLAR"/>
      </div>

      <div class="btn-ibovespa">
        <v-switch @change="changeSwitch('IBOVESPA')"
                  v-model="switchStateIBOVESPA"
                  label="IBOVESPA"/>
      </div>

      <div class="btn-view">
        <div id="csv-view-link"></div>
      </div>

      <div class="btn-cart">
        <div id="csv-cart-link"></div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from "@/views/Bus/bus";
import DateRange from "@/views/pages/VisaoGeral/components/DateRange.vue";
export default {
  props: {
    switchState: Boolean,
    caregoryList: Array,
    defaultSelected: [String, Object],
  },
  components: {
    //SwitchButton,
    DateRange
  },
  data() {
    return {
      valueToModel: this.defaultSelected,
      switchStateCDI:true,
      switchStateIPCA:false,
      switchStateDOLAR:false,
      switchStateIBOVESPA:false
    };
  },

  methods: {
    cycle () {
      EventBus.$emit("SwitchesTop.ToggleRentabilidade", true);
    },
    submitEventFromSwitch(val) {
      this.$emit("changeSwitchVisualizacao", val);
    },
    submitKeyChange(val) {
      this.$emit("changeKey", val);
    },
    submitEventFromSwitchCoins(val) {
      this.$emit("switchsMoedas", val);
    },
    onChange(evt) {
      this.$emit("onChangeEstrategy", evt);
    },
    changeSwitch(e) {
      (e !==      'CDI') && (this.switchStateCDI      = false);
      (e !==     'IPCA') && (this.switchStateIPCA     = false);
      (e !==    'DOLAR') && (this.switchStateDOLAR    = false);
      (e !== 'IBOVESPA') && (this.switchStateIBOVESPA = false);
      let active = (this.switchStateCDI || this.switchStateIPCA
                || this.switchStateDOLAR || this.switchStateIBOVESPA);
      let key = active ? e : '';
      this.submitKeyChange(key);
      this.submitEventFromSwitchCoins(e);
      return e;
    },
    changeInterval(range) {
      this.$emit('date-range-changed', range);
    }
  },
};
</script>

<style scoped>
.itens-1 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.itens-1 .select {
  min-width: 20vw;
  max-width: 20vw;;
  margin: 1.5rem 1rem 0 0;
}
.itens-1 .btn-evolucao {
  display: flex;
  align-items: center;
  border: 1px solid var(--principal-azul);
  justify-content: center;
  padding: 0.5rem 1.5rem;
  border-radius: 25px;
}
.itens-1 .btn-rentabilidade {
  border: 1px solid var(--principal-azul);
  justify-content: center;
  padding: 0.5rem 1.5rem;
  border-radius: 25px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  color: var(--principal-azul);
}
.descricao-evolucao {
  margin: 0 1.5rem 0 0;
}
.descricao-evolucao span {
  font-weight: bold;
  font-size: 18px;
  line-height: 19px;
  letter-spacing: 0.15px;
  color: var(--principal-azul);
}
.item span {
  font-weight: bold;
}
.btns-ativos {
  display: flex;
  flex-wrap: wrap;
}
.btns-ativos > div {
  margin: 0 0.3rem;
}
.btns-ativos > div span {
  padding: 0 0.8rem;
}

@media only screen and (max-width: 950px) {
  .btns-ativos > div {
    flex: 1 1 150px;
  }
  .btn-evolucao {
    margin-bottom: 0.5rem;
  }
  .itens-1 .select {
    max-width: 43vw;;
  }
}
@media only screen and (max-width: 600px) {
  .itens-1 .select {
    max-width: 35vw;
  }
}
</style>