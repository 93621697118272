import Vue from "vue";
import VueRouter from "vue-router";
import AuthRoutes from "./views/pages/Auth/router";
import MyDataRoutes from "./views/pages/MyData/router";
import ExamStatusRoutes from "./views/pages/ExamStatus/router";
import FinanceInfoRoutes from "./views/pages/FinanceInfo/router";
import MyExamsRoutes from "./views/pages/MyExams/router";
import QuestionsRoutes from "./views/pages/Questions/router";
import HomeRoutes from './views/pages/Home/router';
import ConfiguracoesRoutes from './views/pages/configuracoes/router';
import PageNotFoundRoutes from './views/pages/ForaDoAr/router';
import CarteiraMovimentacaoRoutes from './views/pages/Carteira/router';
import LiquidezRoutes from './views/pages/Liquidez/router';
import GestaoUsuariosRoutes from './views/pages/GestaoUsuarios/router';
import DashboardRoutes from './views/pages/Dashborard/router';
import SelecaoAssinaturaRoutes from './views/pages/SelecaoAssinatura/router';
import VisaoGeralRoutes from './views/pages/VisaoGeral/router';
import RentabilidadeRoutes from './views/pages/Rentabilidade/router';
import EscolhaPerfilRoutes from './views/pages/EscolhaPerfil/router';
import CheckoutRoutes from './views/pages/Checkout/router';
import PerfilRoutes from "./views/pages/Perfil/router";
import moment from "moment";
import EventBus from './views/Bus/bus';
import ReportTablesRoutes from './views/pages/Report/router';
import ReportMonthRoutes from './views/pages/ReportMonth/router';
import ImportacaoRoutes from "./views/pages/Importacao/router";
import TermsRoutes from "./views/pages/TermsAndConditions/router";
import ApiService from "./services/ApiService";

Vue.use(VueRouter);

const routes = [
  {
    ...PageNotFoundRoutes
  },
  {
    path: '/perfil',
    ...PerfilRoutes
  },
  {
    path: "/rentabilidade",
    ...RentabilidadeRoutes
  },
  {
    path: "/escolha-de-perfil",
    ...EscolhaPerfilRoutes
  },
  {
    path: "/selecao-de-assinatura",
    ...SelecaoAssinaturaRoutes
  },
  {
    path: "/report-tables",
    ...ReportTablesRoutes
  },
  {
    path: "/ReportMonth",
    ...ReportMonthRoutes
  },
  {
    path: "/importacao",
    ...ImportacaoRoutes
  },
  {
    path: '/checkout',
    ...CheckoutRoutes
  },
  {
    path: "/visao-geral",
    ...VisaoGeralRoutes
  },

  {
    path: "/gestao-usuario",
    ...GestaoUsuariosRoutes
  },
  {
    path: "/dashboard",
    ...DashboardRoutes
  },
  {
    path: "/liquidez",
    ...LiquidezRoutes
  },
  {
    path: "/carteira",
    ...CarteiraMovimentacaoRoutes
  },
  {
    path: "/auth",
    ...AuthRoutes
  },
  {
    path: "/",
    //redirect: "/auth",
    /* solução para mode: "history", tem que adicionar redirect no Web.config */
    beforeEnter: (to, _, next) => {
      next({
        path: to.query.path ? decodeURIComponent(to.query.path) : "/auth",
        query: to.query.path ? {} : to.query,
        hash: to.hash
      });
    }
  },
  {
    path: '/home',
    ...HomeRoutes
  },
  {
    path: "/exams",
    ...MyExamsRoutes
  },
  {
    path: "/exam",
    ...ExamStatusRoutes
  },
  {
    path: "/data",
    ...MyDataRoutes
  },
  {
    path: "/finance",
    ...FinanceInfoRoutes
  },
  {
    path: "/questions",
    ...QuestionsRoutes
  },
  {
    path: "/configuracoes",
    ...ConfiguracoesRoutes
  },
  {
    path: "/termsAndCoditions",
    ...TermsRoutes
  }
];

const router = new VueRouter({
  mode: "history",
  /* solução para redirecionamento padrão */
  //mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  let token = sessionStorage.getItem("token");
  let expiresToken = new Date(sessionStorage.getItem("expires"));
  const user = JSON.parse(sessionStorage.getItem("user"));

  if (user && !user.acceptTerms) {
    if (from.path !== '/terms-and-conditions' && to.path !== '/terms-and-conditions') {
      next(TermsRoutes.children[0]);
      return;
    }
  }

  if (to.meta.requiresAuth) {
    if(token) {
      if (moment().isBefore(moment(expiresToken))) {
        if (user.planType === 0 && to.meta.requiresBasic) {
          next();
        } else if (user.planType === 1 && to.meta.requiresPattern) {
          next();
        } else if (user.planType === 2 && to.meta.requiresSenior) {
          next();
        } else if (user.planType === 3 && to.meta.requiresExpert) {
          next();
        } else if (user.planType === 4 && to.meta.requiresProfessional) {
          next();
        } else {
          if (from.path != "/auth") {
             next(from.path);
            //console.log(from.path, to.path)
            EventBus.$emit('abrirModalPlanos', true)
          }
         // alert("Acesso Restrito");
        }
      } else {
        new ApiService().logout(router);
      }
    } else if ((from.path !== to.path) && (to.path !== '/auth')) {
      next('/auth');
    }
  } else {
    next();
  }
});

export default router;
