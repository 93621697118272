export const mock1 =
    [
        {
            date: '19/04/2021',
            valor: 4567.23
            , percent: 10
            , id: 1
        },
        {
            date: '05/05/2021',
            valor: 4567.23
            , percent: 20
            , id: 2
        },
        {
            date: '24/06/2021',
            valor: 4423.55
            , percent: 100
            , id: 3
        },
        {
            date: '07/07/2021',
            valor: 2345.34
            , percent: 40
            , id: 4
        },
        {
            date: '12/08/2021',
            valor: 345.77
            , percent: 35
            , id: 5
        },
        {
            date: '16/09/2021',
            valor: 456.0
            , percent: 80
            , id: 6
        },
        {
            date: '22/12/2020',
            valor: 34.0
            , percent: 78
            , id: 7
        },
        {
            date: '03/05/2020',
            valor: 4656.8
            , percent: 66
            , id: 8
        },
        {
            date: '16/09/2020',
            valor: 678.0
            , percent: 30
            , id: 9
        },
        {
            date: '23/01/2020',
            valor: 24.0
            , percent: 10
            , id: 10
        },
        {
            date: '16/10/2021',
            valor: 222.0
            , percent: 10
            , id: 11
        },
        {
            date: '07/09/2021',
            valor: 463.0
            , percent: 9
            , id: 12
        },
        {
            date: '09/09/2021',
            valor: 454.0
            , percent: 34
            , id: 13
        }  

    ]
export const mock2 = [
    {
        date: '19/04/2021',
        valor: 500.23
        , percent: 55
        , id: 14
    },
    {
        date: '05/05/2021',
        valor: 456.23
        , percent: -58
        , id: 15
    },
    {
        date: '24/06/2021',
        valor: 678.55
        , percent: 67
        , id: 16
    },
    {
        date: '07/07/2021',
        valor: 4567.34
        , percent: 18
        , id: 17
    },
    {
        date: '12/08/2021',
        valor: 78.77
        , percent: 68
        , id: 18
    },
    {
        date: '16/09/2021',
        valor: 678.0
        , percent: 50
        , id: 19
    },
    {
        date: '16/09/2020',
        valor: 678.0
        , percent: -30
        , id: 20
    },
    {
        date: '23/01/2020',
        valor: 24.0
        , percent: 40
        , id: 21
    },
    {
        date: '16/10/2021',
        valor: 222.0
        , percent: 100
        , id: 22
    },
    {
        date: '07/09/2021',
        valor: 463.0
        , percent: -10
        , id: 23
    },
    {
        date: '09/09/2021',
        valor: 454.0
        , percent: 33
        , id: 24
    }
]
export const mock3 = [
    {
        date: '20/04/2020',
        valor: 600.23
        , percent: 40
        , id: 25
    },
    {
        date: '24/06/2020',
        valor: 678.55
        , percent: 67
        , id: 26
    },
    {
        date: '07/07/2020',
        valor: 4567.34
        , percent: 18
        , id: 27
    },
    {
        date: '12/08/2020',
        valor: 78.77
        , percent: 68
        , id: 28
    },
    {
        date: '16/09/2020',
        valor: 678.0
        , percent: 50
        , id: 29
    },
    {
        date: '16/09/2020',
        valor: 678.0
        , percent: 30
        , id: 30
    },
    {
        date: '16/10/2020',
        valor: 222.0
        , percent: 80
        , id: 32
    },
    {
        date: '07/09/2020',
        valor: 463.0
        , percent: 70
        , id: 33
    },
    {
        date: '09/09/2020',
        valor: 454.0
        , percent: 33
        , id: 34
    }
]

export const mock4 = [
    {
        date: '20/04/2020',
        valor: 600.23
        , percent: 40
        , id: 25,
        slug: '0 dias'
    },
    {
        date: '24/06/2020',
        valor: 678.55
        , percent: 67
        , id: 26,
        slug: 'de 1 a 30 dias'
    },
    {
        date: '07/07/2020',
        valor: 4567.34
        , percent: 18
        , id: 27,
        slug: 'de 31 a 90 dias'
    },
    {
        date: '12/08/2020',
        valor: 78.77
        , percent: 68
        , id: 28,
        slug: 'de 91 a 180 dias'
    },
    {
        date: '16/09/2020',
        valor: 678.0
        , percent: 50
        , id: 29,
        slug: 'de 181 a 360 dias'
    },
    {
        date: '16/09/2020',
        valor: 678.0
        , percent: 30
        , id: 30,
        slug: 'acima de 361 dias'
    }
]

//mocks tela de liquidez

export const mock5 = [
    {
        date: '19/04/2021',
        valor: 500.23
        , percent: 55
        , id: 14
    },
    {
        date: '05/05/2021',
        valor: 456.23
        , percent: 58
        , id: 15
    },
    {
        date: '24/06/2021',
        valor: 678.55
        , percent: 67
        , id: 16
    },
    {
        date: '07/07/2021',
        valor: 4567.34
        , percent: 18
        , id: 17
    },
    {
        date: '12/08/2021',
        valor: 78.77
        , percent: 68
        , id: 18
    },
    {
        date: '16/09/2021',
        valor: 678.0
        , percent: 50
        , id: 19
    },
    {
        date: '16/09/2020',
        valor: 678.0
        , percent: 30
        , id: 20
    },
    {
        date: '23/01/2020',
        valor: 24.0
        , percent: 40
        , id: 21
    },
    {
        date: '16/10/2021',
        valor: 222.0
        , percent: 70
        , id: 22
    },
    {
        date: '07/09/2021',
        valor: 463.0
        , percent: 5
        , id: 26
    },
    {
        date: '07/09/2021',
        valor: 463.0
        , percent: 10
        , id: 23
    },
    {
        date: '09/09/2021',
        valor: 454.0
        , percent: 33
        , id: 24
    }
]


export const mock6 = [
    {
        date: '20/04/2020',
        valor: 600.23
        , percent: 1423487.69
        , id: 25,
        slug: '0 dias'
    },
    {
        date: '24/06/2020',
        valor: 678.55
        , percent: 4963364.87
        , id: 26,
        slug: 'de 1 a 30 dias'
    },
    {
        date: '07/07/2020',
        valor: 4567.34
        , percent: 330715.42
        , id: 27,
        slug: 'de 31 a 90 dias'
    },
    {
        date: '12/08/2020',
        valor: 78.77
        , percent: 0
        , id: 28,
        slug: 'de 91 a 180 dias'
    },
    {
        date: '16/09/2020',
        valor: 678.0
        , percent: 0
        , id: 29,
        slug: 'de 181 a 360 dias'
    },
    {
        date: '16/09/2020',
        valor: 678.0
        , percent: 93445.35
        , id: 30,
        slug: 'acima de 361 dias'
    }
]

export const mock7 = [
    {
        date: '19/04/2021',
        valor: 500.23
        , percent: 20.85
        , id: 14
    },
    {
        date: '05/05/2021',
        valor: 456.23
        , percent: 93.55
        , id: 15
    },
    {
        date: '24/06/2021',
        valor: 678.55
        , percent: 98.39
        , id: 16
    },
    {
        date: '07/07/2021',
        valor: 4567.34
        , percent: 98.39
        , id: 17
    },
    {
        date: '12/08/2021',
        valor: 78.77
        , percent: 98.39
        , id: 18
    },
    {
        date: '16/09/2021',
        valor: 678.0
        , percent: 100
        , id: 19
    },

]

export const mockVisaoGeral = [
    {
        slug: 'cbdRdb',
        valors: [
            {
                title: 'CDB DI Banco  Itaú CD1 100%',
                participacao: '1,46%',
                emissor: 'Banco itaú',
                custodiante: 'Banco itaú',
                liquidez: 0,
                vencimento: '15/08/2024',
                saldoBruto: '141.754,85',
                mesAtual: '0,45%',
                percent1: '501,85%',
                valor1: '30.945,09',
                dozeMeses: '0,00%',
                percent2: '',
                valor2: '',
                desdeInicio: '9,81%',
                percent3: '151%',
                valor3: '',
                slug: 'cbdRdb'
            },
            {
                title: 'CDB DI Banco Pine 6,00%  a.a',
                participacao: '0,25%',
                emissor: 'Banco Pine',
                custodiante: 'XP Investimentos',
                liquidez: 763,
                vencimento: '10/08/2023',
                saldoBruto: '23.897,52',
                mesAtual: '0,45%',
                percent1: '501,85%',
                valor1: '30.945,09',
                dozeMeses: '0,00%',
                percent2: '',
                valor2: '',
                desdeInicio: '9,81%',
                percent3: '151%',
                valor3: '',
                slug: 'cbdRdb'
            }
        ]

    },
    {
        slug: 'criCra',
        valors: [
            {
                title: 'Raízen IPCA + 4,76% a.a',
                participacao: '1,13%',
                emissor: 'Raízen Energia S.A',
                custodiante: 'Guide',
                liquidez: 1405,
                vencimento: '16/12/2024',
                saldoBruto: '109.935,71',
                mesAtual: '0,45%',
                percent1: '501,85%',
                valor1: '30.945,09',
                dozeMeses: '0,00%',
                percent2: '0,00%',
                valor2: '',
                desdeInicio: '9,81%',
                percent3: '151%',
                valor3: '30.945,09',
                slug: 'criCra'
            },
        ]
    },
    {
        slug: 'debentures',
        valors: [
            {
                title: 'Debêntures COELBA IPCA + 6,755% a.a',
                participacao: '1,46%',
                emissor: 'Cia De Eletricidade do Estado da Bahia - COELBA',
                custodiante: 'XP Investimentos',
                liquidez: 1405,
                vencimento: '16/12/2024',
                saldoBruto: '109.935,71',
                mesAtual: '0,45%',
                percent1: '501,85%',
                valor1: '30.945,09',
                dozeMeses: '0,00%',
                percent2: '0,00%',
                valor2: '',
                desdeInicio: '9,81%',
                percent3: '151%',
                valor3: '',
                slug: 'debentures'
            },
        ]

    },
    {
        slug: 'lciLca',
        valors: [
            {
                title: 'CDB DI Banco  Itaú CD1 100%',
                participacao: '1,46%',
                emissor: 'Banco Itaú',
                custodiante: 'Banco Itaú',
                liquidez: 0,
                vencimento: '15/08/2024',
                saldoBruto: '141.754,85',
                mesAtual: '0,45%',
                percent1: '501,85%',
                valor1: '30.945,09',
                dozeMeses: '0,00%',
                percent2: '0,00%',
                valor2: '',
                desdeInicio: '9,81%',
                percent3: '151%',
                valor3: '',
                slug: 'lciLca'
            },
            {
                title: 'CDB DI Banco  Pine 6,00% a.a',
                participacao: '0,25%',
                emissor: 'Banco Pine',
                custodiante: 'XP Investimentos',
                liquidez: 763,
                vencimento: '15/08/2024',
                saldoBruto: '23.897,52',
                mesAtual: '0,45%',
                percent1: '501,85%',
                valor1: '30.945,09',
                dozeMeses: '0,00%',
                percent2: '0,00%',
                valor2: '',
                desdeInicio: '9,81%',
                percent3: '151%',
                valor3: '',
                slug: 'lciLca'
            },
        ]

    },
    {
        slug: 'titulosPublicos',
        valors: [
            {
                title: 'CDB DI Banco  Itaú CD1 100%',
                participacao: '1,46%',
                emissor: 'Banco Itaú',
                custodiante: 'Banco Itaú',
                liquidez: 0,
                vencimento: '15/08/2024',
                saldoBruto: '141.754,85',
                mesAtual: '0,45%',
                percent1: '501,85%',
                valor1: '30.945,09',
                dozeMeses: '0,00%',
                percent2: '0,00%',
                valor2: '',
                desdeInicio: '9,81%',
                percent3: '151%',
                valor3: '',
                slug: 'titulosPublicos'
            },
            {
                title: 'CDB DI Banco Pine 6,00% a.a',
                participacao: '0,25%',
                emissor: 'Banco Pine',
                custodiante: 'XP Investimentos',
                liquidez: 763,
                vencimento: '10/08/2024',
                saldoBruto: '23.897,52',
                mesAtual: '0,45%',
                percent1: '501,85%',
                valor1: '30.945,09',
                dozeMeses: '0,00%',
                percent2: '0,00%',
                valor2: '',
                desdeInicio: '9,81%',
                percent3: '151%',
                valor3: '',
                slug: 'titulosPublicos'
            },
        ]

    },
    {
        slug: 'acoes',
        valors: [
            {
                title: 'CDB DI Banco  Itaú CD1 100%',
                participacao: '1,46%',
                emissor: 'Banco Itaú',
                custodiante: 'Banco Itaú',
                liquidez: 0,
                vencimento: '15/08/2024',
                saldoBruto: '141.754,85',
                mesAtual: '0,45%',
                percent1: '501,85%',
                valor1: '30.945,09',
                dozeMeses: '0,00%',
                percent2: '0,00%',
                valor2: '',
                desdeInicio: '9,81%',
                percent3: '151%',
                valor3: '',
                slug: 'acoes'
            },
            {
                title: 'CDB DI Banco   Pine 6,00% a.a',
                participacao: '0,25%',
                emissor: 'Banco Pine',
                custodiante: 'XP Investimentos',
                liquidez: 763,
                vencimento: '10/08/2024',
                saldoBruto: '23.897,52',
                mesAtual: '0,45%',
                percent1: '501,85%',
                valor1: '30.945,09',
                dozeMeses: '0,00%',
                percent2: '0,00%',
                valor2: '',
                desdeInicio: '9,81%',
                percent3: '151%',
                valor3: '',
                slug: 'acoes'
            },
        ]

    },
    {
        slug: 'fundosImobiliarios',
        valors: [
            {
                title: 'CDB DI Banco  Itaú CD1 100%',
                participacao: '1,46%',
                emissor: 'Banco Itaú',
                custodiante: 'Banco Itaú',
                liquidez: 0,
                vencimento: '15/08/2024',
                saldoBruto: '141.754,85',
                mesAtual: '0,45%',
                percent1: '501,85%',
                valor1: '30.945,09',
                dozeMeses: '0,00%',
                percent2: '0,00%',
                valor2: '',
                desdeInicio: '9,81%',
                percent3: '151%',
                valor3: '',
                slug: 'fundosImobiliarios'
            },
            {
                title: 'CDB DI  Banco Pine 6,00% a.a',
                participacao: '0,25%',
                emissor: 'Banco Pine',
                custodiante: 'XP Investimentos',
                liquidez: 763,
                vencimento: '10/08/2024',
                saldoBruto: '23.897,52',
                mesAtual: '0,45%',
                percent1: '501,85%',
                valor1: '30.945,09',
                dozeMeses: '0,00%',
                percent2: '0,00%',
                valor2: '',
                desdeInicio: '9,81%',
                percent3: '151%',
                valor3: '',
                slug: 'fundosImobiliarios'
            },
            {
                title: 'CDB DI Banco Pine  6,00% a.a',
                participacao: '0,25%',
                emissor: 'Banco Pine',
                custodiante: 'XP Investimentos',
                liquidez: 763,
                vencimento: '10/08/2024',
                saldoBruto: '23.897,52',
                mesAtual: '0,45%',
                percent1: '501,85%',
                valor1: '30.945,09',
                dozeMeses: '0,00%',
                percent2: '0,00%',
                valor2: '',
                desdeInicio: '9,81%',
                percent3: '151%',
                valor3: '',
                slug: 'fundosImobiliarios'
            },
            {
                title: 'CDB  DI Banco Pine 6,00% a.a',
                participacao: '0,25%',
                emissor: 'Banco Pine',
                custodiante: 'XP Investimentos',
                liquidez: 763,
                vencimento: '10/08/2024',
                saldoBruto: '23.897,52',
                mesAtual: '0,45%',
                percent1: '501,85%',
                valor1: '30.945,09',
                dozeMeses: '0,00%',
                percent2: '0,00%',
                valor2: '',
                desdeInicio: '9,81%',
                percent3: '151%',
                valor3: '',
                slug: 'fundosImobiliarios'
            },
        ]

    },
    {
        slug: 'fundosInvestimento',
        valors: [
            {
                title: 'Debêntures COELBA IPCA + 6,755% a.a',
                participacao: '1,46%',
                emissor: 'Cia De Eletricidade do Estado da Bahia - COELBA',
                custodiante: 'XP Investimentos',
                liquidez: 1405,
                vencimento: '16/12/2024',
                saldoBruto: '109.935,71',
                mesAtual: '0,45%',
                percent1: '501,85%',
                valor1: '30.945,09',
                dozeMeses: '0,00%',
                percent2: '0,00%',
                valor2: '',
                desdeInicio: '9,81%',
                percent3: '151%',
                valor3: '',
                slug: 'fundosInvestimento'
            },
        ]

    },

]

// mocks da tela de rentabilidade

export const mokcRentabilidade = [
    {
        date: '20/08/2020',
        valor: 15,
        id: 1
    },
    {
        date: '20/08/20200',
        valor: -2,
        id: 2
    },
    {
        date: '20/09/2020',
        valor: 11,
        id: 3
    },
    {
        date: '20/10/2020',
        valor: 5,
        id: 4
    },
    {
        date: '20/09/2020',
        valor: 2,
        id: 5
    },
    {
        date: '20/09/2020',
        valor: -3,
        id: 6
    },
    {
        date: '20/11/2020',
        valor: 20,
        id: 7
    }
    ,
    {
        date: '20/01/2021',
        valor: 5,
        id: 8
    },
    {
        date: '20/01/2021',
        valor: 5,
        id: 9
    }
    ,
    {
        date: '20/01/2021',
        valor: 4,
        id: 10
    },
    {
        date: '20/01/2021',
        valor: 5,
        id: 11
    }
    ,
    {
        date: '20/01/2021',
        valor: 4,
        id: 12
    }

]
export const mokcRentabilidadeCdi = [
    {
        date: '20/08/2020',
        valor: 3,
        id: 1,
        slug: 'cdi'
    },
    {
        date: '20/08/20200',
        valor: 2,
        id: 2,
        slug: 'cdi'
    },
    {
        date: '20/09/2020',
        valor: 2,
        id: 3,
        slug: 'cdi'
    },
    {
        date: '20/09/2020',
        valor: 2,
        id: 4,
        slug: 'cdi'
    },
    {
        date: '20/09/2020',
        valor: 2,
        id: 5,
        slug: 'cdi'
    },
    {
        date: '20/09/2020',
        valor: 2,
        id: 6,
        slug: 'cdi'
    },
    {
        date: '20/11/2020',
        valor: 2,
        id: 7,
        slug: 'cdi'
    },
    {
        date: '20/01/2021',
        valor: 2,
        id: 8,
        slug: 'cdi'
    },
    {
        date: '20/01/2021',
        valor: 2,
        id: 9,
        slug: 'cdi'
    }
    ,
    {
        date: '20/01/2021',
        valor: 2,
        id: 10,
        slug: 'cdi'
    },
    {
        date: '20/01/2021',
        valor: 2,
        id: 11,
        slug: 'cdi'
    },
    {
        date: '20/01/2021',
        valor: 2,
        id: 12,
        slug: 'cdi'
    }

]
export const mokcRentabilidadeIpca = [
    {
        date: '20/08/2020',
        valor: 3,
        id: 1,
        slug: 'ipca'
    },
    {
        date: '20/08/20200',
        valor: 2,
        id: 2,
        slug: 'ipca'
    },
    {
        date: '20/09/2020',
        valor: 2,
        id: 3,
        slug: 'ipca'
    },
    {
        date: '20/09/2020',
        valor: 2,
        id: 4,
        slug: 'ipca'
    },
    {
        date: '20/09/2020',
        valor: 2,
        id: 5,
        slug: 'ipca'
    },
    {
        date: '20/09/2020',
        valor: 2,
        id: 6,
        slug: 'ipca'
    },
    {
        date: '20/11/2020',
        valor: 2,
        id: 7,
        slug: 'ipca'
    },
    {
        date: '20/01/2021',
        valor: 2,
        id: 8,
        slug: 'ipca'
    },
    {
        date: '20/01/2021',
        valor: 2,
        id: 9,
        slug: 'ipca'
    }
    ,
    {
        date: '20/01/2021',
        valor: 2,
        id: 10,
        slug: 'ipca'
    },
    {
        date: '20/01/2021',
        valor: 2,
        id: 11,
        slug: 'ipca'
    },
    {
        date: '20/01/2021',
        valor: 2,
        id: 12,
        slug: 'ipca'
    }

]
export const mokcRentabilidadeDolar = [
    {
        date: '20/08/2020',
        valor: 3,
        id: 1,
        slug: 'dolar'
    },
    {
        date: '20/08/20200',
        valor: 2,
        id: 2,
        slug: 'dolar'
    },
    {
        date: '20/09/2020',
        valor: 2,
        id: 3,
        slug: 'dolar'
    },
    {
        date: '20/09/2020',
        valor: 2,
        id: 4,
        slug: 'dolar'
    },
    {
        date: '20/09/2020',
        valor: 2,
        id: 5,
        slug: 'dolar'
    },
    {
        date: '20/09/2020',
        valor: 2,
        id: 6,
        slug: 'dolar'
    },
    {
        date: '20/11/2020',
        valor: 2,
        id: 7,
        slug: 'dolar'
    },
    {
        date: '20/01/2021',
        valor: 2,
        id: 8,
        slug: 'dolar'
    },
    {
        date: '20/01/2021',
        valor: 2,
        id: 9,
        slug: 'dolar'
    }
    ,
    {
        date: '20/01/2021',
        valor: 2,
        id: 10,
        slug: 'dolar'
    },
    {
        date: '20/01/2021',
        valor: 2,
        id: 11,
        slug: 'dolar'
    },
    {
        date: '20/01/2021',
        valor: 2,
        id: 12,
        slug: 'dolar'
    }

]
export const mokcRentabilidadeIbovespa = [
    {
        date: '20/08/2020',
        valor: 3,
        id: 1,
        slug: 'ibovespa'
    },
    {
        date: '20/08/20200',
        valor: 2,
        id: 2,
        slug: 'ibovespa'
    },
    {
        date: '20/09/2020',
        valor: 2,
        id: 3,
        slug: 'ibovespa'
    },
    {
        date: '20/09/2020',
        valor: 2,
        id: 4,
        slug: 'ibovespa'
    },
    {
        date: '20/09/2020',
        valor: 2,
        id: 5,
        slug: 'ibovespa'
    },
    {
        date: '20/09/2020',
        valor: 2,
        id: 6,
        slug: 'ibovespa'
    },
    {
        date: '20/11/2020',
        valor: 2,
        id: 7,
        slug: 'ibovespa'
    },
    {
        date: '20/01/2021',
        valor: 2,
        id: 8,
        slug: 'ibovespa'
    },
    {
        date: '20/01/2021',
        valor: 2,
        id: 9,
        slug: 'ibovespa'
    }
    ,
    {
        date: '20/01/2021',
        valor: 2,
        id: 10,
        slug: 'ibovespa'
    },
    {
        date: '20/01/2021',
        valor: 2,
        id: 11,
        slug: 'ibovespa'
    },
    {
        date: '20/01/2021',
        valor: 2,
        id: 12,
        slug: 'ibovespa'
    }

]



export const mokcResultadoFinanceiro = [
    {
        date: '20/01/2021',
        valor: 100000.00,
        id: 1
    },
    {
        date: '20/01/2021',
        valor: 1000000.00,
        id: 2
    },
    {
        date: '20/01/2021',
        valor: 1200000.00,
        id: 3
    },
    {
        date: '20/01/2021',
        valor: 1800000.00,
        id: 4
    },
    {
        date: '20/01/2021',
        valor: 1800000.00,
        id: 5
    },
    {
        date: '20/01/2021',
        valor: 10000000.00,
        id: 6
    },
    {
        date: '20/01/2021',
        valor: 11000000.00,
        id: 7
    }
    ,
    {
        date: '20/01/2021',
        valor: 15000000.00,
        id: 8
    },
    {
        date: '20/01/2021',
        valor: 12000000.00,
        id: 9
    }
    ,
    {
        date: '20/01/2021',
        valor: 13000000.00,
        id: 10
    },
    {
        date: '20/01/2021',
        valor: 14000000.00,
        id: 11
    }
    ,
    {
        date: '20/01/2021',
        valor: 15000000.00,
        id: 12
    }

]
export const mokcResultadoFinanceiroCdi = [
    {
        date: '20/01/2021',
        valor: 1000000,
        id: 1,
        bg: '#fff'
    },
    {
        date: '20/01/2021',
        valor: 1100000,
        id: 2,
        bg: '#fff'
    },
    {
        date: '20/01/2021',
        valor: 1100000,
        id: 3,
        bg: '#fff'
    },
    {
        date: '20/01/2021',
        valor: 1500000,
        id: 4,
        bg: '#fff'
    },
    {
        date: '20/01/2021',
        valor: 1500000,
        id: 5,
        bg: '#fff'
    },
    {
        date: '20/01/2021',
        valor: 9000000,
        id: 6,
        bg: '#fff'
    },
    {
        date: '20/01/2021',
        valor: 10000000,
        id: 7,
        bg: '#fff'
    }
    ,
    {
        date: '20/01/2021',
        valor: 14000000,
        id: 8,
        bg: '#fff'
    },
    {
        date: '20/01/2021',
        valor: 10000000,
        id: 9,
        bg: '#fff'
    }
    ,
    {
        date: '20/01/2021',
        valor: 11000000,
        id: 10,
        bg: '#fff'
    },
    {
        date: '20/01/2021',
        valor: 12000000,
        id: 11,
        bg: '#fff'
    }
    ,
    {
        date: '20/01/2021',
        valor: 13000000,
        id: 12,
        bg: '#fff'
    }

]
export const mokcResultadoFinanceiroIpca = [
    {
        date: '20/01/2021',
        valor: 1000000,
        id: 1,
        bg: '#fff'
    },
    {
        date: '20/01/2021',
        valor: 1100000,
        id: 2,
        bg: '#fff'
    },
    {
        date: '20/01/2021',
        valor: 1100000,
        id: 3,
        bg: '#fff'
    },
    {
        date: '20/01/2021',
        valor: 1500000,
        id: 4,
        bg: '#fff'
    },
    {
        date: '20/01/2021',
        valor: 1500000,
        id: 5,
        bg: '#fff'
    },
    {
        date: '20/01/2021',
        valor: 9000000,
        id: 6,
        bg: '#fff'
    },
    {
        date: '20/01/2021',
        valor: 10000000,
        id: 7,
        bg: '#fff'
    }
    ,
    {
        date: '20/01/2021',
        valor: 14000000,
        id: 8,
        bg: '#fff'
    },
    {
        date: '20/01/2021',
        valor: 10000000,
        id: 9,
        bg: '#fff'
    }
    ,
    {
        date: '20/01/2021',
        valor: 11000000,
        id: 10,
        bg: '#fff'
    },
    {
        date: '20/01/2021',
        valor: 12000000,
        id: 11,
        bg: '#fff'
    }
    ,
    {
        date: '20/01/2021',
        valor: 13000000,
        id: 12,
        bg: '#fff'
    }
]
export const mokcResultadoFinanceiroDolar = [
    {
        date: '20/01/2021',
        valor: 1000000,
        id: 1,
        bg: '#fff'
    },
    {
        date: '20/01/2021',
        valor: 1100000,
        id: 2,
        bg: '#fff'
    },
    {
        date: '20/01/2021',
        valor: 1100000,
        id: 3,
        bg: '#fff'
    },
    {
        date: '20/01/2021',
        valor: 1500000,
        id: 4,
        bg: '#fff'
    },
    {
        date: '20/01/2021',
        valor: 1500000,
        id: 5,
        bg: '#fff'
    },
    {
        date: '20/01/2021',
        valor: 9000000,
        id: 6,
        bg: '#fff'
    },
    {
        date: '20/01/2021',
        valor: 10000000,
        id: 7,
        bg: '#fff'
    }
    ,
    {
        date: '20/01/2021',
        valor: 14000000,
        id: 8,
        bg: '#fff'
    },
    {
        date: '20/01/2021',
        valor: 10000000,
        id: 9,
        bg: '#fff'
    }
    ,
    {
        date: '20/01/2021',
        valor: 11000000,
        id: 10,
        bg: '#fff'
    },
    {
        date: '20/01/2021',
        valor: 12000000,
        id: 11,
        bg: '#fff'
    }
    ,
    {
        date: '20/01/2021',
        valor: 13000000,
        id: 12,
        bg: '#fff'
    }

]
export const mokcResultadoFinanceiroIbovespa = [
    {
        date: '20/01/2021',
        valor: 1000000,
        id: 1,
        bg: '#fff'
    },
    {
        date: '20/01/2021',
        valor: 1100000,
        id: 2,
        bg: '#fff'
    },
    {
        date: '20/01/2021',
        valor: 1100000,
        id: 3,
        bg: '#fff'
    },
    {
        date: '20/01/2021',
        valor: 1500000,
        id: 4,
        bg: '#fff'
    },
    {
        date: '20/01/2021',
        valor: 1500000,
        id: 5,
        bg: '#fff'
    },
    {
        date: '20/01/2021',
        valor: 9000000,
        id: 6,
        bg: '#fff'
    },
    {
        date: '20/01/2021',
        valor: 10000000,
        id: 7,
        bg: '#fff'
    }
    ,
    {
        date: '20/01/2021',
        valor: 14000000,
        id: 8,
        bg: '#fff'
    },
    {
        date: '20/01/2021',
        valor: 10000000,
        id: 9,
        bg: '#fff'
    }
    ,
    {
        date: '20/01/2021',
        valor: 11000000,
        id: 10,
        bg: '#fff'
    },
    {
        date: '20/01/2021',
        valor: 12000000,
        id: 11,
        bg: '#fff'
    }
    ,
    {
        date: '20/01/2021',
        valor: 13000000,
        id: 12,
        bg: '#fff'
    }
]


export const mockAlocacaoEstrategicaPreFixo = [
    {
        title: 'Juros Pré(fixo)',
        value: 25,
        id: 1,
        color: '#F9EB9E',
    },
    {
        title: 'Livres',
        value: 10,
        id: 6,
        color: '#F2A9BB'
    },
    {
        title: 'Câmbio',
        value: 15,
        id: 5,
        color: '#D5D8DC'
    },
    {
        title: 'Renda Variável',
        value: 15,
        id: 4,
        color: '#FBDFD0'
    },
    {
        title: 'Juros Pós(Inflação)',
        value: 10,
        id: 3,
        color: '#D0E2FB'
    },
    {
        title: 'Juros Pós(CDI)',
        value: 10,
        id: 2,
        color: '#ECF8D3'
    },
]



export const mockAtribuicaoPorEstrategia = [
    {
        title: 'Juros Pré(fixo)',
        valuePre: 0,
        value: 0.228,
        id: 1,
        color: '#F9EB9E',
        color2: '#B5E54C'
    },
    {
        title: 'Juros Pós(CDI)',
        valuePre: 0.228,
        value: 0.448,
        id: 2,
        color: '#ECF8D3',
        color2: '#B5E54C'
    },
    {
        title: 'Juros Pós(Inflação)',
        valuePre: 0.448,
        value: 0.558,
        id: 3,
        color: '#D0E2FB',
        color2: '#B5E54C'
    },
    {
        title: 'Renda Variável',
        valuePre: 0.558,
        value: 0.668,
        id: 4,
        color: '#FBDFD0',
        color2: '#B5E54C'
    },
    {
        title: 'Câmbio',
        valuePre: 0.558,
        value: 0.668,
        id: 5,
        color: '#D5D8DC',
        color2: '#F25A5A'
    },
    {
        title: 'Livres',
        valuePre: 0.558,
        value: 0.420,
        id: 6,
        color: '#F2A9BB',
        color2: '#F25A5A'
    },
    {
        title: 'Minha carteira',
        valuePre: 0,
        value: 0.420,
        id: 2,
        color: '#ECF8D3',
        color2: '#579AF2'
    },


]


export const mockDesempehoPorCarteira = [
    {
        year: '2009',
        id: 1,
        actives: [
            {
                title: 'IPCA',
                value: 553.78,
                color: '#F3D63C',
                id: 1
            },
            {
                title: 'PORTFÓLIO',
                value: 173.58,
                color: '#C3C3C3',
                id: 2
            },
            {
                title: 'CDI',
                value: 157.58,
                color: '#E55275',
                id: 3
            },
            {
                title: 'DÓLAR',
                value: 153.58,
                color: '#B6E552',
                id: 4
            },
            {
                title: 'IBOVESPA',
                value: 153.58,
                color: '#F37458',
                id: 5
            },


        ]
    },
    {
        year: '2010',
        id: 2,
        actives: [
            {
                title: 'IPCA',
                value: 753.58,
                color: '#F3D63C',
                id: 1
            },
            {
                title: 'PORTFÓLIO',
                value: 173.58,
                color: '#C3C3C3',
                id: 2
            },
            {
                title: 'CDI',
                value: 153.58,
                color: '#E55275',
                id: 3
            },
            {
                title: 'DÓLAR',
                value: 157.58,
                color: '#B6E552',
                id: 4
            },
            {
                title: 'IBOVESPA',
                value: 153.78,
                color: '#F37458',
                id: 5
            },


        ]
    },
    {
        year: '2011',
        id: 3,
        actives: [
            {
                title: 'IPCA',
                value: 153.58,
                color: '#F3D63C',
                id: 1
            },
            {
                title: 'PORTFÓLIO',
                value: 153.58,
                color: '#C3C3C3',
                id: 2
            },
            {
                title: 'CDI',
                value: 173.58,
                color: '#E55275',
                id: 3
            },
            {
                title: 'DÓLAR',
                value: 153.58,
                color: '#B6E552',
                id: 4
            },
            {
                title: 'IBOVESPA',
                value: 173.58,
                color: '#F37458',
                id: 5
            },


        ]
    },
    {
        year: '2012',
        id: 4,
        actives: [
            {
                title: 'IPCA',
                value: 153.58,
                color: '#F3D63C',
                id: 1
            },
            {
                title: 'PORTFÓLIO',
                value: 153.58,
                color: '#C3C3C3',
                id: 2
            },
            {
                title: 'CDI',
                value: 163.58,
                color: '#E55275',
                id: 3
            },
            {
                title: 'DÓLAR',
                value: 153.58,
                color: '#B6E552',
                id: 4
            },
            {
                title: 'IBOVESPA',
                value: 153.58,
                color: '#F37458',
                id: 5
            },


        ]
    },
    {
        year: '2013',
        id: 5,
        actives: [
            {
                title: 'IPCA',
                value: 153.58,
                color: '#F3D63C',
                id: 1
            },
            {
                title: 'PORTFÓLIO',
                value: 153.58,
                color: '#C3C3C3',
                id: 2
            },
            {
                title: 'CDI',
                value: 153.58,
                color: '#E55275',
                id: 3
            },
            {
                title: 'DÓLAR',
                value: 163.58,
                color: '#B6E552',
                id: 4
            },
            {
                title: 'IBOVESPA',
                value: 153.58,
                color: '#F37458',
                id: 5
            },


        ]
    },
    {
        year: '2014',
        id: 6,
        actives: [
            {
                title: 'IPCA',
                value: 153.58,
                color: '#F3D63C',
                id: 1
            },
            {
                title: 'PORTFÓLIO',
                value: 153.88,
                color: '#C3C3C3',
                id: 2
            },
            {
                title: 'CDI',
                value: 153.58,
                color: '#E55275',
                id: 3
            },
            {
                title: 'DÓLAR',
                value: 253.58,
                color: '#B6E552',
                id: 4
            },
            {
                title: 'IBOVESPA',
                value: 153.58,
                color: '#F37458',
                id: 5
            },


        ]
    },
    {
        year: '2015',
        id: 7,
        actives: [
            {
                title: 'IPCA',
                value: 153.58,
                color: '#F3D63C',
                id: 1
            },
            {
                title: 'PORTFÓLIO',
                value: 153.58,
                color: '#C3C3C3',
                id: 2
            },
            {
                title: 'CDI',
                value: 193.58,
                color: '#E55275',
                id: 3
            },
            {
                title: 'DÓLAR',
                value: 153.58,
                color: '#B6E552',
                id: 4
            },
            {
                title: 'IBOVESPA',
                value: 153.58,
                color: '#F37458',
                id: 5
            },


        ]
    },
    {
        year: '2016',
        id: 8,
        actives: [
            {
                title: 'IPCA',
                value: 153.58,
                color: '#F3D63C',
                id: 1
            },
            {
                title: 'PORTFÓLIO',
                value: 155.58,
                color: '#C3C3C3',
                id: 2
            },
            {
                title: 'CDI',
                value: 153.58,
                color: '#E55275',
                id: 3
            },
            {
                title: 'DÓLAR',
                value: 153.58,
                color: '#B6E552',
                id: 4
            },
            {
                title: 'IBOVESPA',
                value: 553.58,
                color: '#F37458',
                id: 5
            },


        ]
    },
    {
        year: '2017',
        id: 99,
        actives: [
            {
                title: 'IPCA',
                value: 153.58,
                color: '#F3D63C',
                id: 1
            },
            {
                title: 'PORTFÓLIO',
                value: 253.58,
                color: '#C3C3C3',
                id: 2
            },
            {
                title: 'CDI',
                value: 153.58,
                color: '#E55275',
                id: 3
            },
            {
                title: 'DÓLAR',
                value: 153.58,
                color: '#B6E552',
                id: 4
            },
            {
                title: 'IBOVESPA',
                value: 153.98,
                color: '#F37458',
                id: 5
            },


        ]
    },
    {
        year: '2018',
        id: 10,
        actives: [
            {
                title: 'IPCA',
                value: 153.58,
                color: '#F3D63C',
                id: 1
            },
            {
                title: 'PORTFÓLIO',
                value: 153.58,
                color: '#C3C3C3',
                id: 2
            },
            {
                title: 'CDI',
                value: 153.58,
                color: '#E55275',
                id: 3
            },
            {
                title: 'DÓLAR',
                value: 163.58,
                color: '#B6E552',
                id: 4
            },
            {
                title: 'IBOVESPA',
                value: 193.58,
                color: '#F37458',
                id: 5
            },


        ]
    }
    
    
]