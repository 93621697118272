<template>
  <v-container fluid>
   <div v-if="!objetoEhVazio(tipoAtivo)" class="mock">
    <div >
      <div class="mock">
        <v-row class="d-flex flex-wrap pb-16 row">
          <div class="bloco">
            <v-row class="d-flex flex-wrap pb-16 row">
          <div class="bloco">
             <v-col class="d-flex flex-column align-left py-0">
              <span class="text-filelds">Nome do Ativo {{detMov.length}}</span>
            </v-col>
             <v-col>
                <v-select @change="changeActive"
                          :loading="loading"
                          :items="ativos2"
                          attach=""
                          item-text="code"
                          :menu-props="{ bottom: true, offsetY: true }"
                          v-model="ativo"
                          placeholder="Selecione"
                          color="secondary"
                          :return-object="true"
                          required
                          dense
                          :disabled="loading"
                          outlined
                          rounded
                          class="select">
                 </v-select>
            </v-col>
          </div>

            </v-row>
            <v-row class="d-flex flex-wrap pb-16 row">
           <div class="bloco">
              <v-col class="d-flex flex-column align-left py-0">
                <span class="text-filelds">Descrição</span>
              </v-col>
              <v-col cols="1" class="column">
                <span class="btn">  {{ descricao ? descricao : "" }} </span>
              </v-col>
            </div>
            </v-row>
            <v-row class="d-flex flex-wrap pb-16 row">
            <div class="bloco">
              <v-col class="d-flex flex-column align-left py-0">
                <span class="text-filelds">Estratégia</span>
              </v-col>
              <v-col cols="1" class="column">
                <span class="btn">  {{ estrategia ? estrategia : "" }} </span>
              </v-col>
            </div>

            <div class="bloco">
              <v-col class="d-flex flex-column align-left py-0">
                <span class="text-filelds">Indice referência</span>
              </v-col>
              <v-col cols="1" class="column">
                <span class="btn"> {{ indice ? indice : "" }} </span>
              </v-col>
            </div>
            </v-row>
            <v-row class="d-flex flex-wrap pb-16 row">
            <div class="bloco">
              <v-col class="d-flex flex-column align-left py-0">
                <span class="text-filelds">Indexador</span>
              </v-col>
              <v-col cols="1" class="column">
                <span class="btn">
                  {{ indexador ? indexador : "" }}
                </span>
              </v-col>
            </div>
            <div class="bloco">
              <v-col class="d-flex flex-column align-left py-0">
                <span class="text-filelds">FGC</span>
              </v-col>
              <v-col cols="1" class="column">
                <span class="btn"> {{ fgc ? fgc : "" }} </span>
              </v-col>
            </div>
            </v-row>
            <div  v-if="listMovimento.length>0 && detMov.id == 0">
            <v-row class="d-flex flex-wrap pb-16 row rowTitMov">

             <span>Excluir <span style="color: #f25a5a">Movimentação</span></span>

            </v-row>
            <v-row class="d-flex flex-wrap pb-16 row ml">
            <v-row class="bloco rowtitItList">
              <v-col>  Data </v-col>
              <v-col > V. Unitário </v-col>
              <v-col v-if="productId ==5" > Taxa </v-col>
               <v-col ></v-col>
            </v-row>
            </v-row>
            <v-row class="d-flex flex-wrap pb-16 row br ml">
              <div class="bloco">
                <v-row class="d-flex flex-wrap pb-16 row rowItList" v-for="(event,index) in listMovimento" v-bind:key="event.id">

                    <v-col >  {{dateHelper.formatDate(event.investmentDate)}}</v-col>
                    <v-col v-if="Math.abs(event.investedValue) >  1E-3">  {{currencyHelper.formatValue(event.investedValue)}}</v-col>
                    <v-col v-if="Math.abs(event.investedValue) <= 1E-3"> Resgate total</v-col>
                    <!--<v-col >  {{currencyHelper.formatValue(event.investedValue)}}</v-col>-->
                    <v-col  v-if="productId ==5">  {{percentageHelper.formatStandard(event.rate)}}</v-col>
                   <!--<v-col >  <img  @click="deleteListMov(event)" class="icon"  src="@/assets/icons/remove_circle_icon.svg" /></v-col>-->
                   <v-col> <div class="btn-venda" v-if="event.tpMovimento != 1 && (index ==(listMovimento.length-1))">  <span  @click="deleteListMov(event)">Excluir</span> </div> </v-col>
               </v-row>
              </div>

            </v-row>

            </div>
             <div class="teste" v-if="listMovimento.length>0 && detMov.id > 0">
              <v-row class="d-flex flex-wrap pb-16 row rowTitMov">

             <span>Excluir <span style="color: #f25a5a">Movimentação</span></span>

            </v-row>
            <div class="bloco">
                        <v-row class="d-flex flex-wrap pb-16 row rowItExluir">
             <span> Deseja excutar Item do movimento Data:{{this.dateHelper.formatDate(detMov.investmentDate)}}  Valor unitário {{this.currencyHelper.formatValue(detMov.investedValue)}}</span>
                <v-row class="d-flex flex-wrap pb-16 row rowItExluir">
                   <v-col> <div class="btn-venda" >  <span class="span-ok" @click="deleteListMov2(detMov)">Sim  </span> </div> </v-col>
                   <v-col></v-col>
                   <v-col> <div class="btn-venda" >  <span  @click="limparDetMov()">Não  </span> </div> </v-col>
                </v-row>
            </v-row>

            </div>

             </div>
          </div>



        </v-row>
      </div>
    </div>

   </div>

  </v-container>
</template>
<script>

import ApiService from "../../../../services/ApiService";

//import DatePiker from "../tiposAtivos/componentes/Date-Piker.vue";
//import ValorMonetario from "../../Modal/tiposAtivos/componentes/Valor-Monetario.vue";

import dateHelper from "../../../../Utils/DateHelper";
import currencyHelper from "../../../../Utils/CurrencyHelper";
import percentageHelper from "../../../../Utils/PercentageHelper";

export default {
  data() {
    return {
         dateHelper,
      currencyHelper,
      percentageHelper,
       api: new ApiService(),
      ativos: [],
      ativos2: [],
      ativo: {},
      productId:0,
      detMov:{id:0,investmentDate:undefined,InvestedValue:undefined},
      listMovimento:[],
      types :[{ID_TPMOVIMENTO:1,	DESCRICAO:"Aplicação"},{ID_TPMOVIMENTO:2,	DESCRICAO:"Retirada"}],
      userToAtivo2: {userId:undefined,ativoId:undefined,investmentDate:undefined,InvestedValue:undefined,TpMovimento:undefined},

      type:{},
      emissor: "",
      custodiante: "",
      estrategia: "",
      indice: "",
      vencimento: "",
      remuneracao: "",
      percentual: "",
      indexador: "",
      descricao:"",
      fgc:"",
      juros: "",
      loading: true,
      totaInvestido: "",
      currentForm: "",
      btnCompraClicado: false,
      ativoExistenteOuNovo: true,
       respGetAtiTipUserId:[],
    };
  },

  components: {


  },

  props: {
    respGetAtiTipUserId_prop: { type: Array },
    tipoAtivo: { type: Object },
    ativoNovoOuExistente: { type: Boolean },
  },

  watch: {
     respGetAtiTipUserId_prop(nv){
      this.loading = true;
      this.ativos2=nv[0].usersActive;
      this.loading = false;
      this.userToAtivo2.ativoId="";
      this.userToAtivo2.codigo="";
      this.userToAtivo2.descricao="";
      this.listMovimento=[];
      this.descricao="";
      this.estrategia="";
      this.indexador = "";
      this.fgc = "";
      this.indice = "";
     },


    tipoAtivo(nv, ov) {

      this.currentForm = nv.value.value;

    },

    ativoNovoOuExistente(nv) {

      this.ativoExistenteOuNovo = nv;
    },
  },

  methods: {
    save(dadosDoAtivo) {
      this.$emit("save", dadosDoAtivo);
    },
    formatValue2(val){
      if (val != undefined){
        return val.toFixed(2);
      }else{
        return "";
      }

    },

    changeActive(){
       this.userToAtivo2.ativoId=this.ativo.activeId;
      this.userToAtivo2.codigo=this.ativo.code;
      this.userToAtivo2.descricao=this.ativo.description;
      this.listMovimento=this.ativo.movements.sort((a, b) => new Date (a.investmentDate) - new Date(b.investmentDate));//.filter((m) => {return m.tpMovimento == 2 || m.tpMovimento == 3; });
      this.descricao=this.ativo.description;
      this.productId = this.ativo.productId;
      this.estrategia = this.ativo.strategyDescription;
            this.indice = this.ativo.referenceDescription;
            this.indexador = this.ativo.indexerDescription;
            this.fgc=this.ativo.creditGuaranteeFundDescription

             this.types=[{ID_TPMOVIMENTO:3,	DESCRICAO:"Retirada"}]
          this.type={ID_TPMOVIMENTO:3,	DESCRICAO:"Retirada"}
           this.userToAtivo2.TpMovimento=3;
     // }
   this.$emit("save", this.userToAtivo2);

    },
    /*save(dadosAtivo) {
      this.$emit("save", dadosAtivo);
    },*/
    changeActiveType(){
      this.userToAtivo2.TpMovimento=this.type.ID_TPMOVIMENTO;
    },
    saveVencimento(data) {
      this.userToAtivo2.InvestmentDate = data;
      this.$emit("save", this.userToAtivo2);
    },
    limparDetMov(){
      this.detMov={id:0,investmentDate:undefined,InvestedValue:undefined};
    },
    async deleteListMov2(mov) {
        try {
            let index = this.listMovimento.indexOf(mov);
            let req;
            req = await this.api.delete("userToAtivo/movimento/"+mov.id);
                  this.botaoCompraClicado = false;
            req.statusCode == 200
              ? this.$toast.success("Item do movimento excluido")
              : "";
              this.$store.commit("SET_USERTOATIVOUSERProduto", null);
              this.$store.commit("SET_USERTOATIVOUSEREstrategia", null);
              this.$store.commit("SET_USERTOATIVOUSERRentabilidade", null);
              this.$store.commit("SET_USERTOATIVOUSERLiquidez", null);
            this.listMovimento.splice(index, 1);
            this.limparDetMov();
        }
        catch (error) {
        this.botaoCompraClicado = false;

        this.$toast.error("Error ao Excluir Item do Movimento");
      }

    },

    deleteListMov(mov) {
        this.detMov=mov;
       //if (confirm('Deseja excutar Item do movimento Data '+this.dateHelper.formatDate(mov.investmentDate)+' Valor unitário' +this.currencyHelper.formatValue(mov.InvestedValue))) {this.deleteListMov2(mov)} else (alert('alert não'));
    },
    objetoEhVazio(e) {
      return Object.keys(e).length === 0;
    },
  },
  computed: {
    userFormated() {
      return this.$store.getters.$userLogged;
    },

    typeUser() {
      return this.$store.getters.$userType;
    }

    /*  deleteListMov(mov){
     this.loading = true;
       let index = this.listMovimento.indexOf(mov);
      this.api.delete(`userToAtivo/movimento/${mov.id}`)
        .then((resp) => {

          this.listMovimento.splice(index, 1);
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },*/
  },
};
</script>

<style scoped>
.btn-venda span {
  border-radius: 20px;
  padding: 0.5rem 1rem;
  color: white;
  background: #f25a5a;
  cursor: pointer;

}
.btn-venda .span-ok {

  border-radius: 20px;
  padding: 0.5rem 1rem;
   margin-right:0.5rem;
  color: white;
  background: rgb(141, 211, 37);
  cursor: pointer;

}
.rowtitItList{
   width: 90%;
    height: 40px;
    margin: 0 0 0 0;

}
.teste{/*border: 2px solid red;*/
   height: 70%;
}
.rowItList{
   width: 90%;
    height: 40px;
    margin: 0 0 0 0;
  border-top: 1px solid #9f9f9f;
}
.rowItExluir{
  width: 90%;
    height: 40px;

    margin: 0 0 0 0;
    margin-top: 2rem;
    /*border: 2px solid blue;*/
}
.rowTitMov{
   width: 95%;
    margin-left: 0.2rem;
    margin-top: 0.2rem;
    padding-top: 0.2rem;
 border-top: 2px solid #9f9f9f;
}

.rowTitMov span {
  font-weight: 300;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.15px;

}
.container {
  padding: 0;
}
.br{


  margin-bottom: 5rem !important;
}
.ml{
  margin-left: 0.5rem;
}
.bloco {
  flex: 1 0 180px;
  width: 100%;
 /*  border: 1px solid red; */
  /* margin-top: ; */
}

.bloco .btn {
  height: 40px;
  background: #f6f6f6;
  border-radius: 30px;
  color: #9f9f9f;
  padding: 0.5rem 3rem 0.5rem 1rem;
  width: 100%;
  display: block;
  /* border: 1px solid red; */
}

.column {
  min-width: 100% !important;
}

.text-filelds {
  font-size: 14px;
}

.row {
  padding-bottom: 0rem !important;
}

</style>
