<script>
import { Bar } from "vue-chartjs";
export default {
  extends: Bar,
  props: {
    label: { type: String },
    label2: { type: String },
    label3: { type: String },
    label4: { type: String },
    chartData: { type: Array },
    chartData2: { type: Array },
    chartData3: { type: Array },
    chartData4: { type: Array },
    options: { type: Object },
    plugins: {type: Array},
    dates: {type: Array}
  },
  computed: {
    $dados4() {
      return this.chartData4;
    },
    $dados3() {
      return this.chartData3;
    },
    $dados() {
      return this.chartData2;
    },
     myStyles () {
      return {
          background: '#af8542',
   height: '100px',
   width: '300px',
   position: relative,
   top: '20px',
   left: '45px'
      }
    },
  },
  methods: {
    renderizarChart() {

      const dates = this.chartData.map((d) => d.data);//.reverse();
      const valors = this.chartData.map((d) => d.valors.percent);//.reverse();
      const valors2 = this.$dados.map((d) => d.valors.percent);//.reverse();
      const valors3 = this.$dados3.map((d) => d.valors.percent);//.reverse();
      const valors4 = this.$dados4.map((d) => d.valors.percent);//.reverse();

      this.renderChart(
        {
          labels: dates,
          datasets: [
            {
              type: "line",
              label: this.label4,
              data: valors4,
              backgroundColor: "#F3A03C",
              borderColor: "#F3A03C",
              pointRadius: 2,
              yAxisID : 'rightAxis'
            },
            {
              type: "line",
              label: this.label3,
              data: valors3,
              backgroundColor: "#579AF2",
              borderColor: "#579AF2",
              pointRadius: 2,
              yAxisID : 'rightAxis'
            },
            {
              label: this.label,
              data: valors2,
              backgroundColor: "#F3A03C",
              borderColor: "#F3A03C",
              borderRadius:5,
              yAxisID : 'leftAxis'
            },
            {
              label: this.label2,
              data: valors,
              backgroundColor: "#579AF2",
              borderRadius: 5,
              yAxisID : 'leftAxis',
              datalabels:{

              }
            },
          ],
        },
        this.options,
        this.plugins
      );
    },
  },
  mounted() {
    this.renderizarChart();
  },
  watch:{
    chartData2(){
      this.renderizarChart()
    },
    chartData3(){
      this.renderizarChart()
    },
    chartData4(){
      this.renderizarChart()
    }
  }
};
</script>