import Layout from "../../Internal/Layout.vue";
import FinanceInfoPage from "./FinanceInfo.vue";

const FinanceInfoRoutes = {
  component: Layout,
  children: [
    {
      name: "finance",
      path: "/",
      component: FinanceInfoPage,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
      },
    },
  ],
};

export default FinanceInfoRoutes;
