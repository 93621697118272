<template>
  <div class="barra-carteira">
    <v-row class="linha line-barra-carteira" v-if="!switchVisualizacao">
      <v-col md="2" xs="12" class="coluna">
        <div class="descricao title-desc">
          <span> Carteira </span>
          <div>
            <label title="gross-up" aria-details="gross-up">
              <input type="checkbox" id="mobGUCB" @click="changeGrossUp" />&nbsp; gross up
            </label>
          </div>
        </div>
      </v-col>
      <v-col md="1" xs="12" class="coluna">
        <div class="descricao hide">
          <span> 100% </span>
        </div>
      </v-col>
      <v-col md="2" xs="12" class="coluna">
        <div class="descricao hide">
          <span> </span>
        </div>
      </v-col>
      <v-col md="2" xs="12" class="coluna">
        <div class="descricao hide">
          <span> </span>
        </div>
      </v-col>
      <v-col md="1" xs="12" class="coluna">
        <div class="descricao hide">
          <span> </span>
        </div>
      </v-col>
      <v-col md="2" xs="12" class="coluna">
        <div class="descricao hide">
          <span> </span>
        </div>
      </v-col>
      <v-col md="2" xs="12" class="coluna" :title="evoTotal.saldo">
        <div class="descricao">
          <span>
           {{ currencyHelper.formatValue(evoTotal.saldo) }}
          </span>
        </div>
      </v-col>
    </v-row>

    <v-row class="linha-2" v-if="switchVisualizacao">
      <v-col md="2" xs="12" class="coluna">
        <div class="descricao">
          <span> Carteira</span>
          <div>
            <label title="gross-up" aria-details="gross-up">
              <input type="checkbox" id="screenGUCB" @click="changeGrossUp" />&nbsp;gross up
            </label>
          </div>
        </div>
      </v-col>
      <v-col md="1" xs="12" class="coluna" :title="evoTotal.saldo">
        <div>
          <span>
            {{ currencyHelper.formatValue(evoTotal.saldo) }}
          </span>
        </div>
      </v-col>
      <v-col md="3" xs="12" class="coluna coluna-composta">
        <div class="colunas">
          <v-row>
            <v-col md="3" xs="12" class="hide column" :title="evoTotal.mensal.ganho * 100">
              <div class=""><span>{{bound ? "---" : percentageHelper.formatPercentage(evoTotal.mensal.ganho)}}</span></div>
            </v-col>

            <v-col md="3" xs="12" class="hide column" :title="evoTotal.mensal.percentual * 100">
              <div><span>{{bound ? "---" : percentageHelper.formatPercentage(evoTotal.mensal.percentual)}}</span></div>
            </v-col>

            <v-col md="6" xs="12"  class="hide column" :title="evoTotal.mensal.valor">
              <div><span>{{bound ? "---" : currencyHelper.formatValue(evoTotal.mensal.valor)}}</span></div>
            </v-col>
          </v-row>
        </div>
      </v-col>

      <v-col md="3" xs="12" class="coluna coluna-composta">
        <div class="colunas">
          <v-row>
            <v-col md="3" xs="12" class="hide column" :title="evoTotal.doze.ganho * 100">
              <div class=""><span>{{bound ? "---" : percentageHelper.formatPercentage(evoTotal.doze.ganho)}}</span></div>
            </v-col>

            <v-col md="3" xs="12" class="hide column" :title="evoTotal.doze.percentual * 100">
              <div><span>{{bound ? "---" : percentageHelper.formatPercentage(evoTotal.doze.percentual)}}</span></div>
            </v-col>

            <v-col md="6" xs="12" class="hide column" :title="evoTotal.doze.valor">
              <div><span>{{bound ? "---" : currencyHelper.formatValue(evoTotal.doze.valor)}}</span></div>
            </v-col>
          </v-row>
        </div>
      </v-col>

      <v-col md="3" xs="12" class="coluna coluna-composta">
        <div class="colunas">
          <v-row>
            <v-col md="3" xs="12" class="hide column" :title="evoTotal.geral.ganho * 100">
              <div class=""><span>{{percentageHelper.formatPercentage(evoTotal.geral.ganho)}}</span></div>
            </v-col>

            <v-col md="3" xs="12" class="hide column" :title="evoTotal.geral.percentual * 100">
              <div><span>{{percentageHelper.formatPercentage(evoTotal.geral.percentual)}}</span></div>
            </v-col>

            <v-col md="6" xs="12" class="hide column" :title="evoTotal.geral.valor">
              <div><span>{{currencyHelper.formatValue(evoTotal.geral.valor)}}</span></div>
            </v-col>
          </v-row>
        </div>
      </v-col>


    </v-row>
  </div>
</template>

<script>
import currencyHelper from "../../../../Utils/CurrencyHelper";
import percentageHelper from "../../../../Utils/PercentageHelper";
import EventBus from '../../../Bus/bus';

export default {
  props: {
    switchVisualizacao: Boolean,
    wallet: Object,
    evolucao: Object,
    bound: Boolean
  },
  methods : {
    changeGrossUp (ev) {
      EventBus.$emit('grossUpChanged', ev.target.checked);
    }
  },
  data() {
    return {
      currencyHelper,
      percentageHelper
    };
  },
  computed: {
    evoTotal() {
      return this.evolucao && this.evolucao.total || {
        saldo: 0,
        mensal: { ganho: 0, fatia: 0, percentual: 0, valor: 0 },
        doze: { ganho: 0, fatia: 0, percentual: 0, valor: 0 },
        geral: { ganho: 0, fatia: 0, percentual: 0, valor: 0 },
      }
    }
  }
};
</script>

<style  scoped>

.linha {
  margin-top: 1rem;
}
.linha .coluna {
  margin: 0 !important;
  padding: 0.2rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.linha .coluna > div {
  background: white;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  padding: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.linha .coluna > div span {
  font-size: 0.9rem;
}
.linha-2 {
  margin-top: 1rem;
}
.linha-2 .coluna {
  margin: 0 !important;
  padding: 0.2rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.linha-2 .coluna > div {
  background: white;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  padding: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.linha-2 .coluna > div span {
  font-size: 0.9rem;
}

.barra-carteira {
  border-radius: 0;
  color: white;
  margin-top: 1rem !important;
  margin-bottom: 0 !important;
  margin-left: -12px !important;
  height: 6dvh;
  width: 101.5%;
}
.barra-carteira .linha .coluna > div {
  background: white;
  font-weight: 600;
  padding: 0 !important;
}
.barra-carteira .linha-2 {
  max-width: 100%;
  max-height: 100%;
}
/* .barra-carteira .linha-2 .coluna {
} */
.barra-carteira .linha-2 .coluna > div {
  background: #1296E3;
  font-weight: 600;
  padding: 0 !important;
}
.barra-carteira > div {
  display: flex;
  align-items: center;
}

@media screen and (min-width: 1300px) {
  .linha {
    margin: 0 !important;
  }
  .linha-2 {
    margin: 0 !important;
  }
  .barra-carteira {
    display: flex !important;
    align-items: center !important;
    justify-content: right !important;
  }
}

@media screen and (max-width: 950px) {
  /** barra de carteira */
  .barra-carteira {
    font-size: 0.75rem;
    width: 100%;
    margin-left: unset;
  }
  .hide {
    display: none !important;
  }
  .title-desc {
    padding-left: 1.8em !important;
  }
  .line-barra-carteira {
    padding: 0 1.8em;
  }
}

@media screen and (max-width: 450px) {
  .descricao span {
    font-size: clamp(0.4em, 1em, 1.2em) !important;
  }

  .linha {
    margin: 0 !important;
  }
  .linha-2 {
    margin: 0 !important;
  }
  .barra-carteira {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
}
.descricao label {
  margin-left: 0;
  font-size: 0.75em;
  font-weight: normal;
}
</style>