<template>
  <div>
    <v-container class="container" fluid>
      <EditarUsuario
        :user="usuario"
        v-bind:btnUpdateClicado="btnUpdateClicado"
      />
    </v-container>
  </div>
</template>

<script>
import EditarUsuario from "../editarUsuario/editar-usuario.vue";
export default {
  data() {
    return {
      currentForm: "",
      btnUpdateClicado: false,
      usuario: this.userToEdit,
    };
  },
  props: {
    botaoSalvarAlteracoesUsuario: { type: Boolean },
    userToEdit: { type: Object },
  },
  created() {
  },
  components: {
    EditarUsuario,
  },
  watch: {
    botaoSalvarAlteracoesUsuario(nv, ov) {
      if (nv == true) {
        this.btnUpdateClicado = true;
        setTimeout(() => {
          this.btnUpdateClicado = false;
        }, 100);
      }
    },
    userToEdit(nv, ov) {
      this.usuario = nv;
    },
  },
};
</script>

<style  scoped>
</style>