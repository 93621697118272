<script>
import { Line } from "vue-chartjs";

export default {
  extends: Line,
  props: {
    label: { type: String },
    chartData: { type: Array },
    chartData2: { type: Array },
    options: { type: Object },
  },
  mounted() {
    const dates = this.chartData.map((d) => d.data).reverse();
    const valors = this.chartData.map((d) => d.valors.valor).reverse();
    const valors2 = this.chartData2.map(d => d.valors.valor).reverse()

    this.renderChart(
      {
        labels: dates,
        datasets: [
          {
            label: "Compra",
            data: valors,
            backgroundColor: 'transparent',
            borderColor: '#579AF2',
          },
          {
              label: 'Venda',
              data: valors2,
              backgroundColor: 'transparent',
              borderColor: '#F3A03C'
          }
        ],
      },
      this.options
    );
  },
};
</script>