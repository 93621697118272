<template>
  <label class="switch">
    <input type="checkbox" checked v-model="clicked" @change="changeSwitch" />
    <span class="slider round" :style="[clicked ? {backgroundColor: bgColor} : '']"></span>
  </label>
</template>

<script>
export default {
  data() {
    return {
      clicked: false,
    };
  },
  props:{
      value:{type: String, default: ''},
      bgColor:{type: String, default: '#c9c9c9'}
  },
  methods: {
    changeSwitch() {
      this.$emit("changeSwitch", {state: this.clicked, valor: this.value});
    },
  },
};
</script>

<style  scoped>
/* SLIDER */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #c9c9c9;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(18px);
  -ms-transform: translateX(18px);
  transform: translateX(18px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 60px;
  /* border: 1px solid red; */
}

.slider.round:before {
  border-radius: 50%;
}
/* FIM SLIDER */
</style>