<template>
  <v-row v-if="!switchView">
    <v-col sm="12" md="2" xs="12" cols="12" class="col">
      <div class="descricao" :title="getTitleFor(ativo)">
        <span>  {{ getTitleFor(ativo) }}</span>
      </div>
    </v-col>
    <v-col sm="12" md="1" xs="12" cols="12" class="col" :title="ativo.percentege * 100">
      <div class="descricao">
        <span>{{ percentageHelper.formatPercentage(ativo.percentege) }}</span>
      </div>
    </v-col>
    <v-col sm="12" md="2" xs="12" cols="12" class="col">
      <div class="descricao">
        <span> {{ ativo.emissor }}</span>
      </div>
    </v-col>
    <v-col sm="12" md="2" xs="12" cols="12" class="col">
      <div class="descricao">
        <span> {{ ativo.custodiante }}</span>
      </div>
    </v-col>
    <v-col sm="12" md="1" xs="12" cols="12" class="col">
      <div class="descricao">
        <span>{{ ativo.liquidez }} </span>
      </div>
    </v-col>
    <v-col sm="12" md="2" xs="12" cols="12" class="col">
      <div class="descricao">
        <span> {{ dateHelper.formatDate(ativo.vencimento) }} </span>
      </div>
    </v-col>
    <v-col sm="12" md="2" xs="12" cols="12" class="col" :title="ativo.value">
      <div class="descricao">
        <span>
          {{ currencyHelper.formatValue(ativo.value) }}
        </span>
      </div>
    </v-col>
  </v-row>

  <v-row v-else>
    <v-col sm="12" md="2" xs="12" class="coluna">
      <div class="descricao" :title="getTitleFor(ativo)">
        <span>  {{ getTitleFor(ativo) }}</span>
      </div>
    </v-col>
    <v-col sm="12" md="1" xs="12" class="coluna" :title="ativo.value">
      <div class="descricao">
        <span> {{ currencyHelper.formatValue(ativo.value) }}</span>
      </div>
    </v-col>

    <v-col sm="12" md="9" xs="12" class="coluna">
      <div class="colunas">
        <v-row class="row-expansion-2">
          <v-col sm="12" md="1" xs="12" class="coluna-2" :title="ativo.mesAtual * 100">
            <div class="">
              <span>{{ bound ? "---" : percentageHelper.formatPercentage(ativo.mesAtual) }}</span>
            </div>
          </v-col>

          <v-col sm="12" md="1" xs="12" class="coluna-2" :title="ativo.percent1 * 100">
            <div>
              <span>{{ bound ? "---" : percentageHelper.formatPercentage(ativo.percent1) }}</span>
            </div>
          </v-col>

          <v-col sm="12" md="2" xs="12" class="coluna-2" :title="ativo.valor1">
            <div>
              <span>{{ bound ? "---" : currencyHelper.formatValue(ativo.valor1) }}</span>
            </div>
          </v-col>

          <v-col sm="12" md="1" xs="12" class="coluna-2" :title="ativo.dozeMeses * 100">
            <div class="">
              <span>{{ bound ? "---" : percentageHelper.formatPercentage(ativo.dozeMeses) }}</span>
            </div>
          </v-col>

          <v-col sm="12" md="1" xs="12" class="coluna-2" :title="ativo.percent2 * 100">
            <div>
              <span>{{ bound ? "---" : percentageHelper.formatPercentage(ativo.percent2) }}</span>
            </div>
          </v-col>

          <v-col sm="12" md="2" xs="12" class="coluna-2" :title="ativo.valor2">
            <div>
              <span>{{ bound ? "---" : currencyHelper.formatValue(ativo.valor2) }}</span>
            </div>
          </v-col>

          <v-col sm="12" md="1" xs="12" class="coluna-2" :title="ativo.desdeInicio * 100">
            <div class="">
              <span>{{ percentageHelper.formatPercentage(ativo.desdeInicio) }}</span>
            </div>
          </v-col>

          <v-col sm="12" md="1" xs="12" class="coluna-2" :title="ativo.percent3 * 100">
            <div>
              <span>{{ percentageHelper.formatPercentage(ativo.percent3) }}</span>
            </div>
          </v-col>

          <v-col sm="12" md="2" xs="12" class="coluna-2" :title="ativo.valor3">
            <div>
              <span>{{ currencyHelper.formatValue(ativo.valor3) }}</span>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import currencyHelper from "../../../../Utils/CurrencyHelper";
import percentageHelper from "../../../../Utils/PercentageHelper";
import dateHelper from "../../../../Utils/DateHelper";

export default {
  name: "Body",
  props: {
    ativo: { type: Object },
    switchView: { type: Boolean },
    bound: { type: Boolean }
  },

  data() {
    return {
      currencyHelper,
      percentageHelper,
      dateHelper,
    };
  },

  methods: {
    getTitleFor(ativo) {
     switch(ativo.idTProduto) {
       case 7: return `${ativo.title} - ${ativo.descricao}`;
       case 8:
          const parts = (ativo.title || '').split('-');
          const head  = parts[0].trim();
          const tail  = parts.length > 1 ? parts.slice(1).join("-").trim() : "";
          const lead  = tail && head || "";
          if (ativo.descricao === tail || ativo.descricao.startsWith(tail)) { return `${head} - ${ativo.descricao}` };
          return lead ? `${head} - ${ativo.descricao} - ${tail}` : `${ativo.descricao} - ${ativo.title}`;
        default: return ativo.title;
      }
    }
  }
};
</script>

<style scoped>
.descricao{
 min-height: 16vh !important;
 padding: .5rem 0 !important;
}
.descricao span{
  font-size: clamp(.5em, .75em, .9em) !important;
}
.accordions {
  padding: 0 !important;
  margin: 1.5rem 0;
  width: 100% !important;
}
.linha {
  margin-top: 1rem;
}
.linha .coluna {
  margin: 0 !important;
  padding: 0.5rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.linha .coluna > div {
  background: white;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  padding: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.linha .coluna > div span {
  font-size: 0.9rem;
}
.linha-2 {
  margin-top: 1rem;
}
.linha-2 .coluna {
  margin: 0 !important;
  padding: 0.5rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.linha-2 .coluna > div {
  background: white;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  padding: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.linha-2 .coluna > div span {
  font-size: 0.9rem;
}

/* FIM DO TESTE DO ROW */

.itens-1 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.itens-1 .select {
  min-width: 40vw;
  margin: 1.5rem 2rem 0 0;
}
.itens-1 .btn-evolucao {
  display: flex;
  align-items: center;
  border: 1px solid var(--principal-azul);
  justify-content: center;
  padding: 0.5rem 1.5rem;
  border-radius: 25px;
}
.descricao-evolucao {
  margin: 0 1.5rem 0 0;

}
.descricao-evolucao span {
  font-weight: bold;
  font-size: 18px;
  line-height: 19px;
  letter-spacing: 0.15px;
  color: var(--principal-azul);
}
.btns-ativos {
  display: flex;
  flex-wrap: wrap;
}
.btns-ativos > div {
  margin: 0 0.3rem;
}
.btns-ativos > div span {
  padding: 0 0.8rem;
}
/* CARDS DO TOPO */

.cards > div {
  padding: 0.5rem 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 10px;
}
.carteira-produto {
  grid-area: carteira-produto;
}
.participacao {
  grid-area: participacao;
}
.emissor-gestor {
  grid-area: emissor-gestor;
}
.resultado {
  grid-area: resultado;
  display: flex;
  flex-direction: column;
  padding: 0.5rem !important;
}
.custodiante {
  grid-area: custodiante;
}
.liqui-em-dias {
  grid-area: liqui-em-dias;
}
.vencimento {
  grid-area: vencimento;
}
.saldo-bruto {
  grid-area: saldo-bruto;
}
.resultado .items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.resultado .title-result {
  border-bottom: 3px solid #c6c6c6;
  width: 100%;
  padding: 0.2rem 0;
}
.items .mes {
  color: #a9d08e;
}
.items .meses {
  color: #f3a03c;
}
.items .inicio {
  color: var(--principal-azul);
}
.item span {
  font-weight: bold;
}

/* FIM DOS CARDS DO TOPO */

/* CONTEUDOS DO BODY DOS ACCORDIONS */

.row-expansion {
  margin-top: 1rem;
  padding: 0 !important;
  width: 100% !important;
  margin: 0 auto !important;
}
.row-expansion .col {
  padding: 0 0.6rem !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}

.row-expansion .col > div {
  background: white;
  width: 100% !important;
  height: 100%;
  padding: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 10vh;
  border-bottom: 1px solid rgba(73, 73, 73, 0.1);
}
.row-expansion .col > div span {
  font-size: 0.79rem;
}
.row-expansion .col:first-child {
  justify-content: flex-start !important;
  padding-left: 0.2rem !important;
}
.row-expansion .col:last-child {
  padding-right: 0 !important;
}
.row-expansion:last-child .col > div {
  border-radius: 0 0 20px 20px;
}

.row-expansion:last-child .coluna .colunas .row-expansion-2 .coluna-2 {
  overflow: hidden;
}
.row-expansion:last-child .coluna .colunas .row-expansion-2 .coluna-2 > div {
  border: none !important;
  border-radius: none !important;
}

/* FIM DOS CONTEUDOS DO BODY DOS ACCORDIONS */

/** COMPONETNS ED ACCORDIONS DINACIMOS*/
.accordion {
  margin: 0.8rem 0;
}
/**FIM DOS COMPONENTES DE ACCORDIOS DINAMICOS */
/*  ********** PAINEIS ************** */
.header-cdb-rdb {
  background: #B7DF42;
  font-weight: bold !important;
}
.header-cri-cra {
  background: #FFC000;
  font-weight: bold !important;
}
.header-debentures {
  background: #FFC000;
  font-weight: bold !important;
}
.header-lci-lca {
  background: #B7DF42;
  font-weight: bold !important;
}
.header-titulos-publicos {
  background: #fce4d6;
  font-weight: bold !important;
}
.header-acoes {
  background: #8ea9db;
  font-weight: bold !important;
}
.header-fundos-imobiliarios {
  background: #ffd966;
  font-weight: bold !important;
}
.header-fundos-investimento {
  background: #a9d08e;
  font-weight: bold !important;
}
.header-panel {
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  grid-template-rows: 1fr;
  grid-template-areas: "carteira carteira porcentagem porcentagem porcentagem porcentagem porcentagem porcentagem valor";
}
.header-panel .titles {
  grid-area: carteira;
}
.header-panel .percent {
  grid-area: porcentagem;
}
.header-panel .valor {
  grid-area: valor;
}
/*.header-cdb-rdb {
}*/
.header-cdb-rdb .linha {
  margin-top: -0.1%;
  max-height: 100% !important;
}
.header-cdb-rdb .linha .coluna-header {
  text-align: center;
}

/*  ********** FIM PAINEIS ************** */
.cards-top .result-line {
  position: relative;
}
.cards-top {
  position: relative;
}
@media screen and (max-width: 950px) {
  .btns-ativos > div {
    flex: 1 1 150px;
  }
  .elements .cards-top,
  .cards-top-2 {
    display: none;
  }
  .btn-evolucao {
    margin-bottom: 0.5rem;
  }

  .cards-top {
    display: none;
  }
  /** fim barra de carteira */

  /**itens dos accordions */
  .row-expansion .col {
    padding: 0 !important;
  }
  /*.row-expansion .col > div {
  }*/
  /**fim dos itens dos accordions */
}
</style>