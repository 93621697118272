<template>
    <div class="rentabilidade container-fluid" v-if="!this.loading">
        <div class="btn-visaogeral" @click="cycle">Visão Geral</div>

        <div class="card panel">
            <label>Período</label>
            <div>{{ this.calculos.periodo }}</div>
        </div>
        <div class="card panel">
            <label>Carteira</label>
            <div>{{ this.calculos.carteira }}</div>
        </div>

        <div class="controls panel">
            <label>Índice</label>
            <div class="btns-ativos">
                <div class="btn-cdi">
                    <v-switch checked @change="changeSwitch('CDI')"
                            v-model="switchStateCDI"
                            label="CDI"/>
                </div>

                <div class="btn-ipca">
                    <v-switch checked @change="changeSwitch('IPCA')"
                            v-model="switchStateIPCA"
                            label="IPCA"/>
                </div>

                <div class="btn-dolar">
                    <v-switch @change="changeSwitch('DOLAR')"
                            v-model="switchStateDOLAR"
                            label="DÓLAR"/>
                </div>

                <div class="btn-ibovespa">
                    <v-switch @change="changeSwitch('IBOVESPA')"
                            v-model="switchStateIBOVESPA"
                            label="IBOVESPA"/>
                </div>
            </div>
        </div>

        <div class="graph panel">
            <label id="graph-panel-label">Rentabilidade</label>
            <MixedLine :input="acumulado" :aplicacao="aplicacao" />
        </div>

        <div class="table panel">
            <label id="table-panel-label">Acumulada</label>
            <div class="rows" :key="atualizacao">
                <div class="row" v-for="item in dados" :key="item.ano">
                    <div class="group ano">
                        <div class="cell">
                            <div>Mês</div>
                            <div>{{ item.ano }}</div>
                            <div>% {{ indice }}</div>
                        </div>
                    </div>
                    <div class="group data">
                        <div class="cell display" v-for="(child, index) in item.items" :key="index">
                            <div>{{ months[index] }}</div>
                            <div :title="`${child && child.taxa || '-'} (${item.indice && item.indice[index] && item.indice[index].taxa || '-'})`">
                                {{ child && format(child.taxa) || '-' }}
                            </div>
                            <div :title="item.comparado[index] && item.comparado[index].taxa || '-'">
                                {{ item.comparado[index] && format(item.comparado[index].taxa) || '-' }}
                            </div>
                        </div>
                    </div>
                    <div class="group total">
                        <div class="cell">
                            <div>Anual</div>
                            <div :title="`${item.anual.carteira} (${item.anual.indice})`">
                                {{ format(item.anual.carteira) }}
                            </div>
                            <div :title="item.anual.comparado">
                                {{ format(item.anual.comparado) }}
                            </div>
                        </div>
                        <div class="cell">
                            <div>Acum.</div>
                            <div :title="`${item.total.carteira} (${item.total.indice})`">
                                {{ format(item.total.carteira) }}
                            </div>
                            <div :title="item.total.comparado">
                                {{ format(item.total.comparado) }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MixedLine from "./components/graph/MixedLine.vue";
import ApiService from '../../../services/ApiService'
import EventBus from "@/views/Bus/bus";
import percentageHelper from "@/Utils/PercentageHelper";
import CsvHelper from "@/Utils/CsvHelper";
export default {
    props : {
        ativos: { type: Array },
        calculos: { type: Object }
    },
    data () {
        return {
            apiService: new ApiService(),
            loading: false,
            aplicacao: "cdi",
            comparacao: [],
            dados: [],
            acumulado: [],
            months: [
                "Jan", "Fev", "Mar", "Abr",
                "Mai", "Jun", "Jul", "Ago",
                "Set", "Out", "Nov", "Dez"
            ],
            switchStateCDI:true,
            switchStateIPCA:false,
            switchStateDOLAR:false,
            switchStateIBOVESPA:false,
            atualizacao: new Date().getTime()
        };
    },
    methods : {
        process () {
          var _ret=this.apiService.getProcess(this.dados,this.calculos,this.comparacao,this.aplicacao, this.acumulado);
          this.dados = _ret.dados;
          this.comparacao = _ret.comparacao;
          this.atualizacao = new Date().getTime();
        },
        changeSwitch(e) {
            (e !==      'CDI') && (this.switchStateCDI      = false);
            (e !==     'IPCA') && (this.switchStateIPCA     = false);
            (e !==    'DOLAR') && (this.switchStateDOLAR    = false);
            (e !== 'IBOVESPA') && (this.switchStateIBOVESPA = false);
            let active = (this.switchStateCDI || this.switchStateIPCA
                || this.switchStateDOLAR || this.switchStateIBOVESPA);
            let key = active ? e : '';
            this.aplicacao = key.toLowerCase();
            return e;
        },
        format (v) {
            return v === null || v == undefined
                ? ""
                : percentageHelper.formatPercentage(v/100);
        },
        cycle () {
            EventBus.$emit("SwitchesTop.ToggleRentabilidade", false);
        }
    },
    computed: {
        indice: {
            get () {
                return this.aplicacao && this.aplicacao.toUpperCase() || '';
            }
        }
    },
    components: {
        MixedLine
    },
    watch: {
        aplicacao () {
            this.process();
        }
    },
    mounted () {
        this.process();
        // let glabel = document.querySelector("#graph-panel-label");
        // if (glabel && !glabel.dataset.updated) {
        //     glabel.dataset.updated = new Date().getTime().toString();
        //     let gbtn   = document.createElement("div");
        //     gbtn.appendChild(document.createTextNode("Salvar"));
        //     gbtn.className = this.$style["btn-csv-rent"];
        //     gbtn.addEventListener("click", () => {
        //         let { _, link } = CsvHelper.formatCsv(
        //             this.acumulado, {
        //                 name: "grafico.csv",
        //                 title   : {},
        //                 exclude	: [],
        //                 format  : (path) => {
        //                     let secs = path && path.split('.') || [""];
        //                     let tail = secs[secs.length - 1];
        //                     switch (tail) {
        //                         case "items": return "Itens";
        //                         case "mes": return "Mês";
        //                         case "indice": return this.aplicacao && this.aplicacao.toUpperCase() || "Índice";
        //                     }
        //                     return tail && tail.substring(0, 1).toUpperCase() + tail.substring(1) || "";
        //                 },
        //                 filter  : (path) => {
        //                     return !!path;
        //                 }
        //             });
        //         link.click();
        //     });
        //     glabel.after(gbtn);
        // }
        // let tlabel = document.querySelector("#table-panel-label");
        // if (tlabel && !tlabel.dataset.updated) {
        //     tlabel.dataset.updated = new Date().getTime().toString();
        //     let tbtn   = document.createElement("div");
        //     tbtn.appendChild(document.createTextNode("Salvar"));
        //     tbtn.className = this.$style["btn-csv-rent"];
        //     tbtn.addEventListener("click", () => {
        //         let { _, link } = CsvHelper.formatCsv(
        //             { dados: this.dados, comparacao: this.comparacao }, {
        //                 name : "rentabilidade.csv",
        //                 title   : {},
        //                 exclude	: [],
        //                 format  : (path) => {
        //                     let secs = path && path.split('.') || [""];
        //                     //let [grupo, tipo] = secs;
        //                     let head = secs.length > 1 ? secs[secs.length - 2] : "";
        //                     let tail = secs[secs.length - 1];
        //                     switch (tail) {
        //                         case "items": return "Itens";
        //                         case "mes": return "Mês";
        //                         case "indice": return "Índice";
        //                         case "acum": return "Acumulado";
        //                         case "rotulo": return "Rótulo";
        //                         case "calc": return "Calculado";
        //                         case "label":
        //                             if (head !== "items") { return "Tipo"; }
        //                             break;
        //                     }
        //                     return tail && tail.substring(0, 1).toUpperCase() + tail.substring(1) || "";
        //                 },
        //                 filter  : (path) => {
        //                     let secs = path && path.split('.') || [""];
        //                     let head = secs.length > 1 ? secs[secs.length - 2] : "";
        //                     let tail = secs[secs.length - 1];
        //                     if ((head !== "items") && (tail === "label")) { return true; }
        //                     return ["label"].indexOf(tail) < 0;
        //                 }
        //             });
        //         link.click();
        //     });
        //     tlabel.after(tbtn);
        // }
    }
}
</script>

<style scoped>
.rentabilidade {
    padding: 1em;
}
.rentabilidade div.panel:has(label) {
    padding: 1em;
    margin: 1em;
    border-radius: 1ch;
    border: 1px solid #CCCCCC;
    border-top: 6px solid #8080FF;
}
.rentabilidade div > label {
    font-weight: bold;
    clear: both;
}
.rentabilidade .card {
    width: 24%;
    height: 100px;
    float: left;
}
.rentabilidade .table,
.rentabilidade .graph,
.rentabilidade .controls {
    clear: both;
}
.rentabilidade .controls .btns-ativos {
    display: flex;
    flex-direction: row;
    clear: both;
}
.rentabilidade .table .rows {
    display: flex;
    flex-direction: column;
    margin: 1em 0.5em;
    padding: 0;
}
.rentabilidade .table .btns-ativos {
    display: flex;
    flex-direction: row;
}
.rentabilidade .table .rows .row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin: 0;
    padding: 0;
    border: 1px solid black;
}
.rentabilidade .table .rows .row .group {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    flex: 1;
}
.rentabilidade .table .rows .row .group.data .cell {
    flex: 1;
}
.rentabilidade .table .rows .row .group .cell {
    display: flex;
    flex-direction: column;
    margin: 0.25em 0.5em;
    padding: 0;
    font-size: 0.75em;
    flex: 1;
}
.rentabilidade .table .rows .row .group .cell div:nth-child(1) {
    font-weight: bold;
}
.rentabilidade .table .rows .row .group:nth-child(1),
.rentabilidade .table.rows .row .group.ano {
    flex-direction: row;
    flex: 1.3;

}
.rentabilidade .table .rows .row .group.total {
    flex-direction: row;
    flex: 2;
}
.rentabilidade .table .rows .row .group.data {
    border-style: solid;
    border-color: black;
    border-width: 0px 1px;
    flex: 12;
}
.rentabilidade .btn-visaogeral {
  border: 1px solid var(--principal-azul);
  justify-content: center;
  padding: 0.5rem 1.5rem;
  border-radius: 25px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  color: var(--principal-azul);
  margin: 1em;
  width: 9em;
}
@media screen and (max-width: 950px) {
    .rentabilidade .controls .btns-ativos {
        flex-wrap: wrap;
    }

    .rentabilidade .card {
        width: 40%;
        height: 160px;
        float: left;
    }
    .rentabilidade .table .rows .row {
        height: unset;
        flex-direction: column;
    }
    .rentabilidade .table .rows .row .group {
        display: flex;
        flex-wrap: row;
        flex-direction: row;
        height: unset;
        width: unset;
    }
    .rentabilidade .table .rows .row .group:nth-child(1),

    .rentabilidade .table .rows .row .group.total {
        width: unset;
        height: 4em;
        flex-direction: row;
    }
    .rentabilidade .table .rows .row .group.data {
        width: unset;
        border-width: 1px 0px;
    }
    .rentabilidade .table .rows .row .group.data .cell {
        width: 22%;
        height: unset;
    }
    .rentabilidade .table .rows .row .group:nth-child(1) .cell,
    .rentabilidade .table .rows .row .group.total .cell {
        width: 38%;
    }
}
</style>

<style module>
.btn-csv-rent {
    border: 1px solid var(--principal-azul);
    justify-content: center;
    padding: 0.5rem 1.5rem;
    border-radius: 25px;
    font-weight: bold;
    cursor: pointer;
    color: var(--principal-azul);
    margin-left: 30px;
    width: 100px;
    display: inline;
}
</style>