const Layout = () => import("../../External/Layout.vue");
const Auth = () => import("./Auth.vue");
const ForgotPassword = () => import("./ForgotPassword.vue");
const Register = () => import("./Register.vue");
const UpdatePassword = () => import("./UpdatePassword.vue");

const AuthRoutes = {
  component: Layout,
  children: [
    {
      path: "",
      name: "Login",
      component: Auth,
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "register",
      name: "Register",
      component: Register,
      meta: {
        requiresAuth: false,
      }
    },
    {
      path: "forgot-password",
      name: "ForgotPassword",
      component: ForgotPassword,
      meta: {
        requiresAuth: false,
      },
    }, {
      path: "update-password",
      name: "UpdatePassword",
      component: UpdatePassword,
      meta: {
        requiresAuth: false
      }
    },
    { path: "*", redirect: "/auth" },
  ],
};

export default AuthRoutes;
