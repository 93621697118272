<template>
    <div class="base container-fluid">
        <div v-if="loading" class="d-flex justify-center align-center pa-8">
            <v-progress-circular
                :size="35"
                color="primary"
                indeterminate />
        </div>

        <VisaoGeral v-if="!index" />

        <RentabilidadeAnual v-if="index"
                            :ativos="wallet"
                            :calculos="calculos" />
    </div>
</template>

<script>
import moment from 'moment';
import CalculadorAtivo from "../../../services/Calculador";
import RentabilidadeAnual from './RentabilidadeAnual.vue';
import VisaoGeral from './VisaoGeral.vue';
import EventBus from "../../Bus/bus";
export default      {
    data() {
        return {
            calculador: new CalculadorAtivo().setDefaultLoaders(),
            index: false,
            wallet: [],
            calculos: {},
            loading: false
        };
    },
    methods: {
        cycle() {
            this.loading = false;
            if (this.index) {
                this.index = false;
            } else {
                this.loading = true;
                setTimeout(() => {
                    try {
                        this.calculos = this.calculador
                            .refresh()
                            .rentabilidadeFull(this.wallet);
                    } finally {
                        this.index = true;
                        this.loading = false;
                    }
                }, 0);
            }
        },
        getTitle (idx) {
            switch (idx) {
                case true: return "Rentabilidade";
                default: return "Visão Geral";
            }
        },
        doCalc () {
            return new Promise((acc, rej) => {
                try {
                    acc(this.calculador
                        .refresh()
                        .rentabilidadeFull(this.wallet));
                } catch (reason) {
                    rej(reason);
                } finally {
                    this.loading = false;
                }
            });
        },
        ebWallet  (data) { this.wallet  = data; },
        ebLoading (data) { this.loading = data; },
        ebIndex   (data) {
            if (!data) { return; }
            this.index = false;
        },
        ebToggle  (_)    { return this.cycle(); }
    },
    computed: {
        title: {
            get() { return this.getTitle(this.index) }
        },
        toggle: {
            get() { return this.getTitle(!this.index); }
        },
        today: {
            get() {
                return moment().format("DD/MM/YYYY");
            }
        }
    },
    components: { RentabilidadeAnual, VisaoGeral },
    created () {
        EventBus.$on('VisaoGeral.GotAtivos', this.ebWallet);
        EventBus.$on("VisaoGeral.LoadingChanged", this.ebLoading);
        EventBus.$on("reloadPagesForCallActivesAgain", this.ebIndex);
        EventBus.$on("SwitchesTop.ToggleRentabilidade", this.ebToggle);
    },
    beforeDestroy () {
        EventBus.$off('VisaoGeral.GotAtivos', this.ebWallet);
        EventBus.$off("VisaoGeral.LoadingChanged", this.ebLoading);
        EventBus.$off("reloadPagesForCallActivesAgain", this.ebIndex);
        EventBus.$off("SwitchesTop.ToggleRentabilidade", this.ebToggle);
    }
}
</script>

<style scoped>
.base {
    padding: 1em;
}
.top {
    width: 90%;
    height: 4em;
    margin: auto;
}
.title {
    float: left;
    padding: 1em;
}
.button {
    float: right;
    padding: 1em;
    font-weight: bold;
    background: #CCCCCC;
    border-radius: 10ch;
    cursor: pointer;
}
</style>