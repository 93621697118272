<template>
    <div class="outros">
        <v-row class="flex-column" align="start">
            <v-col class="d-flex flex-column align-left py-0">
                <span class="text-fields">Nome do ativo</span>
            </v-col>
            <v-col>
                <v-text-field :loading="loading" v-model="codigo" placeholder="Insira o nome do ativo"
                            color="secondary" required outlined dense rounded height="10"
                            class="select" @keyup="saveCodigo"
                            :rules="[
                                v => !!v || 'Campo obrigatório',
                                v => (v &&  v.length>3) || 'O codigo deve conter de 4 a 19 caracteres.',
                                v => (v &&  v.length<20) || 'O codigo deve conter de 4 a 19 caracteres.'
                            ]">
                </v-text-field>
            </v-col>

            <v-col class="d-flex flex-column align-left py-0">
                <span class="text-fields">Descrição do ativo</span>
            </v-col>
            <v-col>
                <v-text-field :loading="loading" v-model="description" placeholder="Insira a descrição do ativo"
                            color="secondary" required outlined dense rounded height="10"
                            @keyup="saveDescription" class="select"
                            :rules="[
                                v => !!v || 'Campo obrigatório',
                                v => (v &&  v.length>3) || 'O codigo deve conter de 4 a 100 caracteres.',
                                v => (v &&  v.length<101) || 'O codigo deve conter de 4 a 100 caracteres.'
                            ]">
                </v-text-field>
            </v-col>

            <v-col class="d-flex flex-column align-left py-0">
                <span class="text-fields">Emissor</span>
            </v-col>
            <v-col>
                <v-autocomplete :items="entityCreditRisk" :loading="loading" item-text="fullName"
                                item-value="entityId" v-model="emissor" placeholder="Insira o Emissor"
                                color="secondary" required outlined rounded dense
                                @change="saveEmissor" clearable attach="" class="select"
                                :filter="filterObject" :rules="[(v) => v != null || 'Campo obrigatório']">
                </v-autocomplete>
            </v-col>

            <v-col class="d-flex flex-column align-left py-0">
                <span class="text-fields">Custodiante</span>
            </v-col>
            <v-col>
                <v-autocomplete :items="custodians" :loading="loading" item-text="fullName"
                                item-value="entityId" v-model="custodiante" placeholder="Insira o custodiante"
                                color="secondary" required outlined dense rounded height="10"
                                @change="saveCustodiante" class="select" clearable attach=""
                                :filter="filterObject" :rules="[(v) => v != null || 'Campo obrigatório']">
                </v-autocomplete>
            </v-col>

            <v-row class="d-flex flex-wrap flexx">
                <div class="bloco">
                    <v-col class="d-flex flex-column align-left py-0">
                        <span class="text-fields">Data Investimento</span>
                    </v-col>
                    <v-col class="py-0 flex-grow-0 flex-shrink-1" style="padding: 0">
                        <DatePiker @change="saveDataInvestimento" />
                    </v-col>
                </div>

                <div class="bloco">
                    <v-col class="d-flex flex-column align-left py-0">
                        <span class="text-fields">Valor Ação(R$)</span>
                    </v-col>
                    <v-col class="py-0" style="padding: 0">
                        <ValorMonetario :value="vPrecoUnitario"  @change="saveShareValue" />
                    </v-col>
                </div>

                <div class="bloco">
                    <v-col class="d-flex flex-column align-left py-0">
                        <span class="text-fields">Nº de Ações</span>
                    </v-col>
                    <v-col class="py-0" style="padding: 0">
                        <ValorMonetario :value="vQtd"  @change="saveShareQuantity" />
                    </v-col>
                </div>

                <div class="bloco">
                    <v-col class="d-flex flex-column align-left py-0">
                        <span class="text-fields">Total Investido(R$)</span>
                    </v-col>
                    <v-col class="py-0" style="padding: 0">
                        <ValorMonetario :value="vPrecoUnitarioQtd" @change="saveInvestedValue" />
                    </v-col>
                </div>
            </v-row>
        </v-row>
    </div>
</template>

<script lang="js">
import ApiService from "../../../../../services/ApiService";
import textUtil from "../../../../../Utils/TextUtil.js"
import DatePiker from "../componentes/Date-Piker.vue";
import ValorMonetario from "../componentes/Valor-Monetario.vue";

const UND = void 0;
export default {
    name: "outros",

    props: {
        respGetAtiTipUserId_prop: { type: Array },
        userToAtivos: { type: Object },
        closing: { type: Boolean }
    },

    components: {
        DatePiker,
        ValorMonetario,
    },

    data () {
        return {
            // utility
            api: new ApiService(),
            textUtil,
            loading: false,

            // lists
            entityCreditRisk: [],
            custodians: [],

            // campos
            vPrecoUnitario: UND,
            vQtd: UND,
            vPrecoUnitarioQtd: UND,
            codigo: UND,
            description: UND,
            CustodianteId: UND,
            emissor: UND,
            custodiante: UND,
            userToAtivo: {
                Description: UND,
                investmentDate: UND,
                InvestedValue: UND,
                ShareValue: UND,
                ShareQuantity: UND,
                ativo: {
                    idTproduto: 13,
                    idEntidade: UND,
                    idCustodiante: UND,
                    idIreferencia: 1,
                    idIndexador: 1,
                    fgc: 1,
                    descricao: UND,
                    idEstrategia: 6,
                    idTremuneracao: 6,
                    liquidez: 0,
                    // isento_ir: 1, ???
                    codigo: UND
                }
            }
        }
    },

    async created () {
        try {
            this.entityCreditRisk = await this.api.getListEntity(this.$store,4);
        } catch (e) {
            this.$toast.error(error.Error);
        }
        try {
            this.custodians = await this.api.getListEntity(this.$store,8);
        } catch (e) {
            this.$toast.error(error.Error);
        }
        this.userToAtivo.TpMovimento = 1;
    },
    mounted() {
        this.$emit('buy:activeId', undefined);
    },

    methods: {
        filterObject(item, queryText, _) {
            if (!queryText || queryText === '') {
                return true;
            }
            return this.textUtil.includesCIAI(item.code || item.fullName, queryText);
        },

        saveDescription() {
            this.userToAtivo.Description=this.description;
            this.userToAtivo.ativo.descricao = this.description;
            this.$emit("save", this.userToAtivo);
        },
        saveCodigo() {
            this.userToAtivo.ativo.codigo = this.codigo;
            this.$emit("save", this.userToAtivo);
        },
        saveEmissor() {
            this.userToAtivo.ativo.idEntidade = this.emissor;
            this.$emit("save", this.userToAtivo);
        },
        saveCustodiante() {
            this.userToAtivo.CustodianteId  = this.custodiante;
            this.userToAtivo.ativo.idCustodiante = this.custodiante;
            this.$emit("save", this.userToAtivo);
        },
        saveDataInvestimento(data) {
            this.userToAtivo.investmentDate = data;
            this.$emit("save", this.userToAtivo);
        },
        saveInvestedValue(InvestedValue){
            this.userToAtivo.InvestedValue = parseFloat(InvestedValue);
            if( this.userToAtivo.ShareQuantity>0){
                this.userToAtivo.ShareValue=0;
            }
            this.saveTotalValue();
        },
        saveShareValue(ShareValue) {
            this.userToAtivo.ShareValue = parseFloat(ShareValue);
            if( this.userToAtivo.ShareQuantity>0){
                this.userToAtivo.InvestedValue=0;
            }
            this.saveTotalValue()
        },
        saveShareQuantity(ShareQuantity) {
            this.userToAtivo.ShareQuantity = parseFloat(ShareQuantity);
            if(this.userToAtivo.ShareValue > 0){
                this.userToAtivo.InvestedValue=0;
            }
            this.saveTotalValue()
        },
        saveTotalValue() {
            if (this.userToAtivo.ShareValue > 0 && this.userToAtivo.ShareQuantity > 0){
                this.userToAtivo.InvestedValue = this.userToAtivo.ShareValue  *
                                                 this.userToAtivo.ShareQuantity;
                this.$emit("save", this.userToAtivo);
            }else if (this.userToAtivo.InvestedValue > 0 &&  this.userToAtivo.ShareQuantity > 0){
                this.userToAtivo.ShareValue = this.userToAtivo.InvestedValue /
                                              this.userToAtivo.ShareQuantity;
                this.$emit("save", this.userToAtivo);
            }else if (this.userToAtivo.ShareValue > 0 && this.userToAtivo.InvestedValue > 0){
                this.userToAtivo.ShareQuantity = this.userToAtivo.InvestedValue /
                                                 this.userToAtivo.ShareValue;
                this.$emit("save", this.userToAtivo);
            }

            this.vPrecoUnitario    = this.userToAtivo.ShareValue;
            this.vQtd              = this.userToAtivo.ShareQuantity;
            this.vPrecoUnitarioQtd = this.userToAtivo.InvestedValue;
        }
    },

    watch: {
        userToAtivos (n, _) {
            if (!n) { return; }
            this.userToAtivo = n;
        },
        respGetAtiTipUserId_prop(nv){

        },
    }
}
</script>

<style scoped>
.outros {
  margin-top: 0 !important;
  width: 100%;
  padding-top: 0 !important;
  padding-bottom: 5rem;
}
.flexx {
  margin: 0 auto;
  width: 95%;
}
.bloco {
  flex: 1 0 180px;
  width: 100%;
}
.bloco .btn {
  height: 40px;
  background: #f6f6f6;
  border-radius: 30px;
  color: #9f9f9f;
  padding: 0.5rem 3rem 0.5rem 1rem;
  min-width: 100% !important;
  font-size: clamp(0.45em, 0.9em, 1em);
  display: flex;
  align-items: center;
}
.text-fields {
  font-size: 14px;
}
</style>