import Layout from "../../Internal/Layout.vue";
import QuestionsPage from "./Questions.vue";

const QuestionsRoutes = {
  component: Layout,
  children: [
    {
      name: "questions",
      path: "/",
      component: QuestionsPage,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
      },
    },
  ],
};

export default QuestionsRoutes;
