<template>
  <div class="home">
    <div class="banner">
      <v-img
        contain
        max-height="400px"
        src="../../../assets/mobile.png"
        style="cursor: pointer"
        @click="redirect"
        alt="Banner"
        class="hidden-md-and-up"
      ></v-img>

      <v-img
        contain
        max-height="400px"
        src="../../../assets/desktop.png"
        @click="redirect"
        style="cursor: pointer"
        alt="Banner"
        class="hidden-sm-and-down"
      ></v-img>

      <div class="bannerLine">
        <span class="text">
          TODAS AS INFORMAÇÕES <br />
          SOBRE SEU EXAME AQUI
        </span>
        <v-divider color="#fff" vertical class="mx-2"></v-divider>
        <span class="text">
          LAUDO INSERIDO NO <br />
          RENACH AUTOMATICAMENTE
        </span>
      </div>

      <div>
        <ExamList />
      </div>

      <v-btn
        href="https://api.whatsapp.com/send?phone=5511945022666"
        target="_blank"
        color="green"
        style="position: fixed; bottom: 4rem; right: 1rem"
        dark
        fab
      >
        <v-icon color="white" x-large>mdi-whatsapp</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<style scoped>
.home {
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner {
  width: 100%;
}

.bannerLine {
  display: flex;
  justify-content: space-around;

  background-color: #f78129;

  width: 100%;
  padding: 5px;
}

.text {
  font-size: 0.7rem;
  color: #fff;
  text-align: center;
}
</style>

<script>
import ExamList from "./components/ExamList.vue";

export default {
  name: "MyExams",

  components: {
    ExamList,
  },

  methods: {
    redirect() {
      window.open("https://calculadora.labet.com.br/", "_blank");
    },
  },
};
</script>
