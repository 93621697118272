<template>
  <div class="carteira container-fluid">
    <div class="d-flex justify-center align-center pa-4" v-if="loading">
      <v-progress-circular
        :size="35"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <div v-else>
      <div class="cards">
        <div class="card">
          <span class="title">Saldo Bruto</span>
          <span class="valor">{{
            currencyHelper.formatValue(evolucaoSaldo)
          }}</span>
        </div>
        <div class="card">
          <span class="title">Saldo Liquido</span>
          <span class="valor">{{currencyHelper.formatValue(evolucaoLiquido)}}</span>
        </div>
        <div class="card">
          <span class="title">Rendimento</span>
          <span class="valor">{{currencyHelper.formatValue(evolucaoRendimento)}}</span>
        </div>
        <div class="card">
          <span class="title">Rendimento Histórico</span>
          <span class="valor">{{percentageHelper.formatPercentage(evolucaoGanho)}}</span>
        </div>
      </div>

      <div class="search-bar">
        <v-text-field
          class="text-field"
          placeholder="Pesquisar"
          outlined
          rounded
          dense
          background-color="white"
          prepend-inner-icon="mdi-magnify"
          v-model="searchAtivo"
        ></v-text-field>
      </div>

      <div class="acorddions">
        <v-row>
          <v-col sm="12" md="6" xs="12">
            <v-expansion-panels v-model="panel">
              <v-expansion-panel>
                <v-expansion-panel-header class="header-prefixo panel">
                  <div class="teste">
                    <div class="titles">
                      <span>Juros Pré(fixo)</span>
                    </div>
                    <div class="valor">
                      <span class="saldo">Saldo bruto </span>
                      <span class="value">{{
                        currencyHelper.formatValue(saldoJurosPre)
                      }}</span>
                    </div>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content id="content-expansion">
                  <v-data-table
                    height="40vh"
                    :search="searchAtivo"
                    :headers="headers"
                    no-data-text="Não encontrado"
                    :items="dessertsJurosPre.items"
                    :items-per-page="100000"
                    hide-default-footer
                    class="elevation-1 table"
                  >
                    <template
                      v-slot:[`item.value`]="{ item }"
                      class="slot-btns"
                    >
                      <span>{{ currencyHelper.formatValue(item.value) }}</span>
                    </template>

                    <template v-slot:[`item.fat`]="{ item }" class="slot-btns">
                      <div class="slot-btns">
                        <v-img
                          class="btn-icon"
                          src="@/assets/icons/graph_bar_icon.svg"
                          @click="
                            showMovimentacao('editarUsuario', $event, item)
                          "
                        />
                        <v-img
                          class="btn-icon"
                          src="@/assets/icons/add_circle_icon.svg"
                          @click="showModal('editarUsuario', $event, item)"
                        />
                        <v-img
                          class="btn-icon"
                          src="@/assets/icons/remove_circle_icon.svg"
                          @click="deletItem(item)"
                        />
                      </div>
                    </template>

                    <template v-slot:top>
                      <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card class="card-dialog">
                          <v-card-title class="text-h5"
                            ><span>Vender ativo?</span></v-card-title
                          >
                          <v-card-text class="descricao-dialog">
                            Isso irá apagar permanentemente todas as informações
                            e transações anteriormente cadastradas
                          </v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              class="btn-cancel-dialog"
                              text
                              @click="closeDelete"
                              ><span>Cancelar</span></v-btn
                            >
                            <v-btn
                              color="blue darken-1"
                              class="btn-delete-dialog"
                              text
                              @click="deletarAtivo"
                              ><span>Vender</span></v-btn
                            >
                            <v-spacer></v-spacer>
                          </v-card-actions>
                          <div class="btn-close-dialog" @click="closeDialog">
                            <v-img src="@/assets/icons/clear_icon.svg" />
                          </div>
                        </v-card>
                      </v-dialog>
                    </template>
                  </v-data-table>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header class="header-posinflacao panel">
                  <div class="teste">
                    <div class="titles">
                      <span>Juros Pós(Inflação)</span>
                    </div>
                    <div class="valor">
                      <span class="saldo">Saldo bruto </span>
                      <span class="value">{{
                        currencyHelper.formatValue(
                          saldoJurosPosInflacao
                        )
                      }}</span>
                    </div>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content id="content-expansion">
                  <v-data-table
                    :search="searchAtivo"
                    height="40vh"
                    :headers="headers"
                    no-data-text="Não encontrado"
                    :items="dessertsJurosPosInflacao.items"
                    :items-per-page="100000"
                    hide-default-footer
                    class="elevation-1 table"
                  >
                    <template
                      v-slot:[`item.value`]="{ item }"
                      class="slot-btns"
                    >
                      <span>{{ currencyHelper.formatValue(item.value) }}</span>
                    </template>

                    <template v-slot:[`item.fat`]="{ item }" class="slot-btns">
                      <div class="slot-btns">
                        <v-img
                          class="btn-icon"
                          src="@/assets/icons/graph_bar_icon.svg"
                          @click="
                            showMovimentacao('editarUsuario', $event, item)
                          "
                        />
                        <v-img
                          class="btn-icon"
                          src="@/assets/icons/add_circle_icon.svg"
                          @click="showModal('editarUsuario', $event, item)"
                        />
                        <v-img
                          class="btn-icon"
                          src="@/assets/icons/remove_circle_icon.svg"
                          @click="deletItem(item)"
                        />
                      </div>
                    </template>
                  </v-data-table>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header class="header-cambio panel">
                  <div class="teste">
                    <div class="titles">
                      <span>Câmbio</span>
                    </div>
                    <div class="valor">
                      <span class="saldo">Saldo bruto </span>
                      <span class="value">{{
                        currencyHelper.formatValue(saldoCambio)
                      }}</span>
                    </div>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content id="content-expansion">
                  <v-data-table
                    :search="searchAtivo"
                    height="40vh"
                    :headers="headers"
                    no-data-text="Não encontrado"
                    :items="dessertsCambio.items"
                    :items-per-page="100000"
                    hide-default-footer
                    class="elevation-1 table"
                  >
                    <template
                      v-slot:[`item.value`]="{ item }"
                      class="slot-btns"
                    >
                      <span>{{ currencyHelper.formatValue(item.value) }}</span>
                    </template>

                    <template v-slot:[`item.fat`]="{ item }" class="slot-btns">
                      <div class="slot-btns">
                        <v-img
                          class="btn-icon"
                          src="@/assets/icons/graph_bar_icon.svg"
                          @click="
                            showMovimentacao('editarUsuario', $event, item)
                          "
                        />
                        <v-img
                          class="btn-icon"
                          src="@/assets/icons/add_circle_icon.svg"
                          @click="showModal('editarUsuario', $event, item)"
                        />
                        <v-img
                          class="btn-icon"
                          src="@/assets/icons/remove_circle_icon.svg"
                          @click="deletItem(item)"
                        />
                      </div>
                    </template>
                  </v-data-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>

          <v-col sm="12" md="6" xs="12">
            <v-expansion-panels v-model="panel2">
              <v-expansion-panel>
                <v-expansion-panel-header class="header-precdi panel">
                  <div class="teste">
                    <div class="titles">
                      <span>Juros Pós(CDI)</span>
                    </div>
                    <div class="valor">
                      <span class="saldo">Saldo bruto </span>
                      <span class="value">{{
                        currencyHelper.formatValue(
                          saldoJurosPosCdi
                        )
                      }}</span>
                    </div>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content id="content-expansion">
                  <v-data-table
                    :search="searchAtivo"
                    height="40vh"
                    :headers="headers"
                    no-data-text="Não encontrado"
                    :items="dessertsJurosPosCdi.items"
                    :items-per-page="100000"
                    hide-default-footer
                    class="elevation-1 table"
                  >
                    <template
                      v-slot:[`item.value`]="{ item }"
                      class="slot-btns"
                    >
                      <span>{{ currencyHelper.formatValue(item.value) }}</span>
                    </template>

                    <template v-slot:[`item.fat`]="{ item }" class="slot-btns">
                      <div class="slot-btns">
                        <v-img
                          class="btn-icon"
                          src="@/assets/icons/graph_bar_icon.svg"
                          @click="
                            showMovimentacao('editarUsuario', $event, item)
                          "
                        />
                        <v-img
                          class="btn-icon"
                          src="@/assets/icons/add_circle_icon.svg"
                          @click="showModal('editarUsuario', $event, item)"
                        />
                        <v-img
                          class="btn-icon"
                          src="@/assets/icons/remove_circle_icon.svg"
                          @click="deletItem(item)"
                        />
                      </div>
                    </template>
                  </v-data-table>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header class="header-rendavariavel panel">
                  <div class="teste">
                    <div class="titles">
                      <span>Renda variável</span>
                    </div>
                    <div class="valor">
                      <span class="saldo">Saldo bruto </span>
                      <span class="value">{{
                        currencyHelper.formatValue(
                          saldoRendaVariavel
                        )
                      }}</span>
                    </div>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content id="content-expansion">
                  <v-data-table
                    :search="searchAtivo"
                    height="40vh"
                    :headers="headers"
                    no-data-text="Não encontrado"
                    :items="dessertsRendaVariavel.items"
                    :items-per-page="100000"
                    hide-default-footer
                    class="elevation-1 table"
                  >
                    <template
                      v-slot:[`item.value`]="{ item }"
                      class="slot-btns"
                    >
                      <span>{{ currencyHelper.formatValue(item.value) }}</span>
                    </template>

                    <template v-slot:[`item.fat`]="{ item }" class="slot-btns">
                      <div class="slot-btns">
                        <v-img
                          class="btn-icon"
                          src="@/assets/icons/graph_bar_icon.svg"
                          @click="
                            showMovimentacao('editarUsuario', $event, item)
                          "
                        />
                        <v-img
                          class="btn-icon"
                          src="@/assets/icons/add_circle_icon.svg"
                          @click="showModal('editarUsuario', $event, item)"
                        />
                        <v-img
                          class="btn-icon"
                          src="@/assets/icons/remove_circle_icon.svg"
                          @click="deletItem(item)"
                        />
                      </div>
                    </template>
                  </v-data-table>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel>
                <v-expansion-panel-header class="header-livres panel">
                  <div class="teste">
                    <div class="titles">
                      <span>Livres</span>
                    </div>
                    <div class="valor">
                      <span class="saldo">Saldo bruto </span>
                      <span class="value">{{
                        currencyHelper.formatValue(saldoLivre)
                      }}</span>
                    </div>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content id="content-expansion">
                  <v-data-table
                    :search="searchAtivo"
                    height="40vh"
                    :headers="headers"
                    no-data-text="Não encontrado"
                    :items="dessertsLivre.items"
                    :items-per-page="100000"
                    hide-default-footer
                    class="elevation-1 table"
                  >
                    <template
                      v-slot:[`item.value`]="{ item }"
                      class="slot-btns"
                    >
                      <span>{{ currencyHelper.formatValue(item.value) }}</span>
                    </template>

                    <template v-slot:[`item.fat`]="{ item }" class="slot-btns">
                      <div class="slot-btns">
                        <v-img
                          class="btn-icon"
                          src="@/assets/icons/graph_bar_icon.svg"
                          @click="
                            showMovimentacao('editarUsuario', $event, item)
                          "
                        />
                        <v-img
                          class="btn-icon"
                          src="@/assets/icons/add_circle_icon.svg"
                          @click="showModal('editarUsuario', $event, item)"
                        />
                        <v-img
                          class="btn-icon"
                          src="@/assets/icons/remove_circle_icon.svg"
                          @click="deletItem(item)"
                        />
                      </div>
                    </template>
                  </v-data-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../../../services/ApiService";
import CalculadorAtivo from "../../../services/Calculador";
import currencyHelper from "../../../Utils/CurrencyHelper";
import percentageHelper from '../../../Utils/PercentageHelper';
import EventBus from "../../Bus/bus";

export default {
  data() {
    return {
      api: new ApiService(),
      calculador: new CalculadorAtivo().setDefaultLoaders(),
      loading: false,
     // user: {},
      wallet: {},
      evolucao: {},
      currencyHelper,
      percentageHelper,
      searchAtivo: "",
      dialogDelete: false,
      userToBeDeleted: undefined,
      panel: [0, 1],
      panel2: [0, 1],
      dessertsJurosPre: {},
      dessertsJurosPosCdi: {},
      dessertsJurosPosInflacao: {},
      dessertsLivre: {},
      dessertsRendaVariavel: {},
      dessertsCambio: {},
      headers: [
        {
          text: "Ativo",
          align: "start",
          value: "descricao",
        },
        { text: "Saldo Bruto", value: "value" },
        { text: "", value: "fat" },
      ],
    };
  },

  created() {

    EventBus.$emit("ListaDeUsuariosSelected", true);
    this.getInfo();
    EventBus.$on('reloadPagesForCallActivesAgain', this.ebReload);
  },

  breforeDestroy () {
    EventBus.$off('reloadPagesForCallActivesAgain', this.ebReload);
  },

  methods: {
    ebReload (data) {
      if (!data) { return; }
      this.getInfo();
    },
    createFiller(tipo, name) {
      return (e, i) => {
        let item      = this.evolucao[tipo][name].items[i];
        e.value       = item.evolucao.saldo || 0.0;
        e.percentege  = item.evolucao.participacao || 0.0;
        e.mesAtual    = item.evolucao.mensal.ganho;
        e.percent1    = item.evolucao.mensal.percentual;
        e.valor1      = item.evolucao.mensal.valor;
        e.dozeMeses   = item.evolucao.doze.ganho;
        e.percent2    = item.evolucao.doze.percentual;
        e.valor2      = item.evolucao.doze.valor;
        e.desdeInicio = item.evolucao.geral.ganho;
        e.percent3    = item.evolucao.geral.percentual;
        e.valor3      = item.evolucao.geral.valor;
        e.title2      = this.getTitle2(e);
      };
    },
    getTitle2(item){
      if (item.idTProduto ==7){
        return item.title+' - '+item.descricao;
          }else   if (item.idTProduto ==8){
            return item.descricao+' - '+item.title ;
          }else{
            return item.title;
          }

    },
    getInfo() {
      this.loading = true;
 /*

      if (i.idTProduto ==7){
           i.title2= i.title+' - '+i.descricao;
            // i.title= i.title+' - '+i.descricao
          }else   if (i.idTProduto ==8){
            i.title2= i.descricao+' - '+i.title ;
           // i.title= i.descricao+' - '+i.title
          }else{
            i.title2= i.title;
          }
          });
      let model = {
        userId: this.$store.getters.$tokenUserSelected,
        byStrategy: true,

      };

     this.api
        .post("userToAtivo/user", model)
        .then((res) => {

          this.evolucao = this.calculador.calcularCarteira(res.content.all.items, true);*/
      this.api.getUserToAtivoUser(true,this.$store)
      .then((res) => {

          this.evolucao =this.calculador.wrap(res.evolucao);
          res.content.jurosPre.items.forEach(this.createFiller("estrategia", "jurosPre"));
          res.content.jurosPosCdi.items.forEach(this.createFiller("estrategia", "jurosPosCdi"));
          res.content.jurosPosInflacao.items.forEach(this.createFiller("estrategia", "jurosPosInflacao"));
          res.content.livre.items.forEach(this.createFiller("estrategia", "livre"));
          res.content.rendaVariavel.items.forEach(this.createFiller("estrategia", "rendaVariavel"));
          res.content.cambio.items.forEach(this.createFiller("estrategia", "cambio"));

          this.wallet = res.content.all;
          this.dessertsJurosPre = res.content.jurosPre;
          this.dessertsJurosPosCdi = res.content.jurosPosCdi;
          this.dessertsJurosPosInflacao = res.content.jurosPosInflacao;
          this.dessertsLivre = res.content.livre;
          this.dessertsRendaVariavel = res.content.rendaVariavel;
          this.dessertsCambio = res.content.cambio;

          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    deletItem(e) {
      this.userToBeDeleted = e;
      this.dialogDelete = true;
    },
    showMovimentacao(e, evt, item) {
      let obj = {
        item: item,
        show: true,
      };
      this.$emit("showMovimentacao", obj);
    },
    showModal(e, evt, item) {
      EventBus.$emit("showModalCarteira", item);
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    closeDialog() {
      this.closeDelete();
    },
    deletarAtivo() {},
    $formatedNumber(val) {
      let formato = {
        minimumFractionDigits: 2,
        style: "currency",
        currency: "BRL",
      };
      return `${val.toLocaleString("pt-BR", formato)}`;
    },
  },

  computed: {
    saldoJurosPre () {
      return this.evolucao.estrategia
          && this.evolucao.estrategia.jurosPre
          && this.evolucao.estrategia.jurosPre.total
          && this.evolucao.estrategia.jurosPre.total.saldo
          || 0;
    },
    saldoJurosPosCdi () {
      return this.evolucao.estrategia
          && this.evolucao.estrategia.jurosPosCdi
          && this.evolucao.estrategia.jurosPosCdi.total
          && this.evolucao.estrategia.jurosPosCdi.total.saldo
          || 0;
    },
    saldoJurosPosInflacao () {
      return this.evolucao.estrategia
          && this.evolucao.estrategia.jurosPosInflacao
          && this.evolucao.estrategia.jurosPosInflacao.total
          && this.evolucao.estrategia.jurosPosInflacao.total.saldo
          || 0;
    },
    saldoRendaVariavel () {
      return this.evolucao.estrategia
          && this.evolucao.estrategia.rendaVariavel
          && this.evolucao.estrategia.rendaVariavel.total
          && this.evolucao.estrategia.rendaVariavel.total.saldo
          || 0;
    },
    saldoCambio () {
      return this.evolucao.estrategia
          && this.evolucao.estrategia.cambio
          && this.evolucao.estrategia.cambio.total
          && this.evolucao.estrategia.cambio.total.saldo
          || 0;
    },
    saldoLivre () {
      return this.evolucao.estrategia
          && this.evolucao.estrategia.livre
          && this.evolucao.estrategia.livre.total
          && this.evolucao.estrategia.livre.total.saldo
          || 0;
    },
    evolucaoSaldo () {
      return this.evolucao
          && this.evolucao.total
          && this.evolucao.total.saldo
          || 0;
    },
    evolucaoLiquido () {
      return this.evolucao
          && this.evolucao.total
          && this.evolucao.total.liquido
          || 0;
    },
    evolucaoGanho () {
      return this.evolucao
          && this.evolucao.total
          && this.evolucao.total.geral.ganho
          || 0;
    },
    evolucaoRendimento () {
      return this.evolucao
          && this.evolucao.total
          && this.evolucao.total.geral
          && this.evolucao.total.geral.valor
          || 0;
    }
  }
};
</script>

<style scoped>
.carteira {
  padding: 1rem;
}
.search-bar .v-text-field {
  padding: 0 0.8rem;
}
.v-text-field >>> fieldset {
  border-color: transparent !important;

  box-shadow: -4px 0px 10px rgba(0, 0, 0, 0.05);
}
.titles span {
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.15px;
}
.teste .valor .saldo {
  font-weight: 300 !important;
  font-size: 15px !important;
  line-height: 22px !important;
  /* identical to box height */

  letter-spacing: 0.15px !important;
}
.teste .valor .value {
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 30px !important;
  letter-spacing: 0.15px !important;
}

.v-expansion-panel {
  /* border-radius: 20px 20px 20px 20px !important; */
  color: black !important;
  border-radius: 15px !important;
  box-shadow: -4px 0px 10px rgba(0, 0, 0, 0.05);
  /* border: 1px solid red !important; */
}
.v-expansion-panel--active {
  border-radius: 15px !important;
  /* border: 1px solid blue !important; */
}

.v-expansion-panel-header {
  border-radius: 15px !important;
  transition: 0.2s ease-in-out;
}
.v-expansion-panel-header--active {
  border-radius: 15px 15px 0 0 !important;
}
/*.v-expansion-panel {
  margin-top: 5rem !important;
}*/
.v-expansion-panel-content {
  padding: 0 !important;
  margin: 0 !important;
}
#content-expansion::v-deep .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
.slot-btns {
  display: flex;
}
.slot-btns .btn-icon {
  width: 5px;
  margin-right: 0.7rem;
  object-fit: cover;
  cursor: pointer;
}

.v-expansion-panel-header .teste {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.table {
  width: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
}
.teste .valor {
  font-size: 12px;
  font-weight: 200;
}
.teste .valor .value {
  font-weight: 500;
  font-size: 14px;
}
/*.panel {
  border: 1px solid red;
}*/
.header-prefixo {
  background: #f9eb9e;
}
.header-posinflacao {
  background: #d0e2fb;
}
.header-cambio {
  background: #d5d8dc;
}
.header-precdi {
  background: #ecf8d3;
}
.header-rendavariavel {
  background: #fbdfd0;
}
.header-livres {
  background: #f2a9bb;
}
.cards {
  /* padding: 1rem; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100vw;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
}
.cards .card {
  background: white;
  padding: 0.8rem;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  /* width: 100%; */
  flex: 1 1 300px;
  margin: 0.5rem;
  box-shadow: -4px 0px 10px rgba(0, 0, 0, 0.05);
}
.card .title {
  font-size: 15px !important;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0.15px;
  padding-bottom: 0.5rem;
}
.card .valor {
  font-weight: normal;
  font-size: 25px;
  line-height: 37px;
}
.v-data-table {
  overflow-y: auto;
  padding-bottom: 0.5rem !important;
  border-radius: 0 0 15px 15px !important;
}
.v-data-table::v-deep .v-data-table__wrapper::-webkit-scrollbar {
  width: 8px !important;
  border-radius: 20px !important;
}
.v-data-table::v-deep .v-data-table__wrapper::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}
.v-data-table::v-deep .v-data-table__wrapper::-webkit-scrollbar-thumb {
  background: rgb(197, 196, 196) !important;
  border-radius: 20px;
}
.v-data-table >>> th {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}
.v-data-table >>> td {
  /* padding: .5rem 0 !important; */
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.card-dialog {
  text-align: center;
  padding: 2rem 0;
  position: relative;
}
.btn-close-dialog {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 25px;
  top: 25px;
  cursor: pointer;
}
.card-dialog .text-h5 {
  text-align: center !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.descricao-dialog {
  margin: 0.5rem 0;
}
.btn-delete-dialog {
  background: #f25a5a;
  border-radius: 20px;
  padding: 0 1rem !important;
}
.btn-delete-dialog span {
  color: white;
}
.btn-cancel-dialog {
  border-radius: 20px;
  padding: 0 1rem !important;
  border: 1px solid #e4e4e4;
}
.btn-cancel-dialog span {
  color: #e4e4e4;
}

@media screen and (max-width: 585px) {
  .btn-icon {
    width: 20px !important;
  }
}

@media screen and (min-width: 945px) {
  .btn-icon {
    width: 10px !important;
  }
}

@media screen and (max-width: 700px) {
  .panel .titles span {
    font-size: 1rem !important;
  }
  .panel .valor .value {
    font-size: 1.2rem !important;
  }
}
</style>