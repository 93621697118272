<template>
  <div class="layout">
    <AppBar @dadosUsuarioEditar="editUser" @mostrarModal="open" :closeModal="closeModal" />
    <Recipiente :showModal="showModal" :userToEdit="userToEdit" @closeModal="close"/>
    <FloatModal :spec="floatContent" :args="floatArgs" @onModalResult="refreshFloat" />
    <v-main class="background">
      <router-view />
    </v-main>
  </div>
</template>

<script>
import AppBar from "./AppBar.vue";
import Recipiente from '../pages/Modal/Recipiente.vue';
import FloatModal from "../pages/Modal/FloatModal.vue";
import EventBus from '../Bus/bus';

export default {
  name: "InternalLayout",

  components: {
    AppBar,
    Recipiente,
    FloatModal
  },
  data(){
    return{
      showModal: undefined,
      closeModal: undefined,
      userToEdit: undefined,
      floatContent: {
        width  : 600,
        height : 600,
        view   : undefined,
        display: 'none'
      },
      floatArgs: undefined
    }
  },
  methods:{
    open(e){
       this.showModal = e;
    },
    close(e){
      this.closeModal = e;
    },
    editUser(e){
      this.userToEdit = e

    },
    ebModal (spec, args) {
      spec.display      = 'block';
      this.floatArgs    = args;
      this.floatContent = spec;
    },
    refreshFloat () {
      this.floatContent = {
          width  : 100,
          height : 100,
          view   : 'EmptyAction',
          display: 'none'
      };
      this.floatArgs = undefined;
    }
  },
  created () {
    EventBus.$on('openFloatModal', this.ebModal);
  },
  beforeDestroy () {
    EventBus.$off('openFloatModal', this.ebModal);
  }
};
</script>

<style scoped>
.layout{
  position: relative;
}
.background{
  background: #f6f6f6;
  min-height: calc(100vh - 70px);
  padding: 0;
}
@media screen and (max-width:650px){
  .background{
    max-height: auto;
  }
}
</style>