<template>
  <div class="card-caracteristica-produto">
    <div class="title-caracteristica">
      <span>Caracteristicas do produto</span>
    </div>

    <div class="caracteristicas">
      <div>
        <span class="title-item">Produto</span>
        <span class="descricao-item">{{ ativo_prop.title }}</span>
      </div>

      <div v-if="ativo_prop.idTProduto != 5 ">
        <span class="title-item" v-if="ativo_prop.idTProduto == 1 || ativo_prop.idTProduto == 4">Emissor</span>
        <span class="title-item" v-if="ativo_prop.idTProduto == 2 || ativo_prop.idTProduto == 10 || ativo_prop.idTProduto == 3">Risco de Crédito</span>
        <span class="title-item" v-if="ativo_prop.idTProduto == 7 || ativo_prop.idTProduto == 8">Gestor</span>
        <span class="title-item" v-if="ativo_prop.idTProduto == 6 || ativo_prop.idTProduto == 11">Empresa</span>
        <span class="descricao-item" v-if="ativo_prop.idTProduto != 8 ">{{ ativo_prop.emissor }}</span>
        <span class="descricao-item"  v-if="ativo_prop.idTProduto == 8 ">{{ ativo_prop.gestor }}</span>
      </div>

      <div>
        <span class="title-item">Estrátegia</span>
        <span class="descricao-item">{{ ativo_prop.estrategia }}</span>
      </div>

      <div>
        <span class="title-item">Índice de Referência</span>
        <span class="descricao-item">{{ ativo_prop.referencia }}</span>
      </div>

      <div v-if="ativo_prop.taxa>0">
        <span class="title-item">Taxa</span>
        <span class="descricao-item">{{
          ativo_prop.taxa>0 ? ativo_prop.taxa : "100"
        }}</span>
      </div>

      <div>
        <span class="title-item">FGC</span>
        <span class="descricao-item">{{
          ativo_prop.fgcDescricao ? ativo_prop.fgcDescricao : "Sem registros"
        }}</span>
      </div>

      <div>
        <span class="title-item">Indexador</span>
        <span class="descricao-item">{{ ativo_prop.indexador }}</span>
      </div>

      <div>
        <span class="title-item">Data Aplicação</span>
        <span class="descricao-item">{{
          dateHelper.formatDate(ativo_prop.dataInvestimento)
        }}</span>
      </div>

      <div  v-if="ativo_prop.vencimento != undefined">
        <span class="title-item">Vencimento</span>
        <span class="descricao-item">{{
          dateHelper.formatDate(ativo_prop.vencimento)
        }}</span>
      </div>
    </div>
  </div>
</template>


<script>
import dateHelper from "../../../../Utils/DateHelper";
export default {
  name: "CaracteristicasProduto",

  props: {
    ativo_prop: { type: Object },
  },
created: function() {
  //console.log (JSON.stringify(this.ativo_prop));
    //CadPro"+JSON.stringify( this.ativo_prop))
  },
  data() {
    return {
      dateHelper,
    };
  },
};
</script>

<style  scoped>
.card-caracteristica-produto {
  border-radius: 20px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
}
.title-caracteristica {
  /*margin-bottom: 1.5rem;*/
  font-weight: 600;
  font-size: 20px;
  line-height: 33px;
  letter-spacing: 0.15px;
  justify-content: top;
  color: #494949;
  text-align: center;

}
/*.title-caracteristica span {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.15px;
  color: #494949;
}*/

.caracteristicas {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.caracteristicas > div {
  margin: 0.05rem 0;
  display: flex;
  flex-direction: column;
}
.title-item {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: #494949;
}
.descricao-item {
  font-weight: 300;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.15px;
  color: #494949;
}
@media screen and (max-width: 1000px) {
  .caracteristicas > div {
    flex: 1 1 300px;
  }
}
</style>