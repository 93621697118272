const Layout = () => import("../../External/Layout.vue");
const SelecaoAssinatura = () => import("./SelecaoAssinatura.vue");

const SelecaoAssinaturaRoutes = {
 component: Layout,
  children: [
    {
      name: "selecaoAssinatura",
      path: "/",
      component: SelecaoAssinatura,
      meta: {
        requiresAuth: false,
       requiresAdmin: false,
      },
    }
 ],
};

export default SelecaoAssinaturaRoutes;
