<template>
  <div class="Report">
    <div class="Report-topo">
    </div>

    <div v-if="loading" class="d-flex justify-center align-center pa-8">
      <v-progress-circular :size="35"
                           color="primary"
                           indeterminate />
    </div>

    <v-card width="100vw">
      <div class="divImagemPrinting">
      <button class="buttonImagemPrinting" @click="downloadCsv">
        <v-img src="../../../assets/icons/printing.svg" class="imagem_printing"></v-img>
      </button>
    </div>

      <v-container fluid fill-height class="container">
        <v-row class="top" justify="space-between">
          <v-col cols="12" sm="6" md="3" class="col-2 coluna-input">
            <v-text-field
              rounded
              outlined
              dense
              :disabled="loading"
              type="date"
              name=""
              id=""
              v-model="dataInicial"
              @input="dataInicialChange"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" md="4" class="top-rate">
            <span class="title-data">{{ rate }}</span>
          </v-col>
        </v-row>

        <v-data-table
          :headers="headers"
          :items="desserts"
          class="elevation-1"
          @click:row="handleClick($event)"
        >
          <template v-slot:[`item.ativo`]="{ item }">
            <div :class="getHeader(item)">
                <span>{{ item.ativo }}</span>
            </div>
          </template>

           <template v-slot:[`item.valor`]="{ item }">
            <div>
                <span>{{formatCurrency(item.valor)}}</span>
            </div>
          </template>

          <template v-slot:[`item.quantidade`]="{ item }">
            <div>
                <span>{{ item.quantidade }}</span>
            </div>
          </template>

        </v-data-table>
      </v-container>
    </v-card>

    <br> <br>

    <v-card width="100vw">
      <v-col cols="12" sm="6" md="3">
            <span class="title-data">Movimentação</span>
          </v-col>
      <v-data-table
          :headers="headers2"
          :items="movimentos"
          class="elevation-1"
          >
          <template v-slot:[`item.ativo`]="{ item }">
            <div>
                <span>{{ item.ativo }}</span>
            </div>
          </template>

           <template v-slot:[`item.valor`]="{ item }">
            <div>
                <span>{{formatCurrency(item.valor)}}</span>
            </div>
          </template>

          <template v-slot:[`item.valor1`]="{ item }">
            <div>
                <span>{{formatCurrency(item.valor1)}}</span>
            </div>
          </template>

          <template v-slot:[`item.quantidade`]="{ item }">
            <div>
                <span>{{ item.quantidade }}</span>
            </div>
          </template>
       </v-data-table>
    </v-card>

  </div>
</template>

<script>
import { mapActions } from "vuex";
import ApiService from "../../../services/ApiService";
import EventBus from "../../Bus/bus";
import moment from "moment";
import CalculadorAtivo from "../../../services/Calculador";

export default {
  data() {
    return {
      loading: false,
      dialog: false,
      controller: false,
      apiService: new ApiService(),
      mostrarModal: false,
      element: undefined,
      search: "",
      ativos : [],
      calculador: new CalculadorAtivo().setDefaultLoaders(),
      reloadUsers: false,
      headers: [
        { text: "Produto", value: "ativo",  align: "left", true: "ativo",  sortable: false},
        { text: "Saldo", value: "valor", align: "right", sortable: false},
        { text: "Ativos", value: "quantidade", align: "right", sortable: false },
      ],
      headers2: [
        { text: "Produto", value: "ativo",  align: "left", true: "ativo",  sortable: false},
        { text: "Aportes", value: "valor", align: "right", sortable: false},
        { text: "Resgates", value: "valor1", align: "right", sortable: false},
        { text: "Quantidade", value: "quantidade", align: "right", sortable: false },
      ],
      desserts: [],
      movimentos: [],
      user: undefined,
      dataInicial: undefined,
      bloco: undefined,
      rate: ''
    };
  },
  created() {
    this.callClientes();
    EventBus.$on("reloadUsers", this.ebReload);
    EventBus.$on("reloadPagesForCallActivesAgain", this.ebReload);
    this.user = this.$store.getters.$userLogged;
    this.dataInicial = new Date().toISOString().substring(0, 10);
  },
  beforeDestroy () {
    EventBus.$off("reloadUsers", this.ebReload);
    EventBus.$off("reloadPagesForCallActivesAgain", this.ebReload);
  },
  methods: {
    ...mapActions(["PUT_CURRENT_USER"]),
    ebReload (data) {
      if (!data) { return; }
      this.callClientes();
    },
    handleClick(e) {
      if (!this.controller) {
        this.$router.push(`/visao-geral`);
      }
    },
    formatDate(date) {
      return moment(date).format("DD/MM/yyyy");
    },
    formatCurrency (valor) {
      return "R$ " + this.calculador.numberToLocale(valor);
    },
    closeDialog() {
    },
    getRates (full) {
      const asrc    = full.anual;
      const atail   = asrc[asrc.length - 1];
      const arate   = this.calculador.valorInPercent(atail.geral.carteira);
      const msrc    = full.dados;
      const mtail   = msrc[msrc.length - 1];
      const mrate   = this.calculador.valorInPercent(mtail.carteira.taxa);
      return `${arate} (ano)   ${mrate} (mês)`;
    },
    async callClientes() {
      this.loading = true;
      try {
        let model = {
          userId: this.$store.getters.$tokenUserSelected,
          byStrategy: false,
          brief: false
        };
        let custodians = [];
        let resp        = await this.apiService.post("userToAtivo/user", model);
        this.ativos     = resp.content.all.items;;
        this.bloco      = this.calculador.gerarDados(this.ativos);
        const full      = this.calculador.reportRentabilidade(this.bloco, this.dataInicial, custodians);
        this.rate       = this.getRates(full);
        this.desserts = full && full.report ? full.report : [];
        this.movimentos = full && full.movimentos ? full.movimentos : [];
        EventBus.$emit("ListaDeUsuarios", this.desserts);

      } catch (reason) {
        window["console"].error(reason);
        this.$toast.error("Erro ao calcular Ativos");
      } finally {
        this.loading = false;
      }
    },
    getHeader(item) {
      return item && item.isParent > 0 ? 'parent-item' : 'child-item';
    },
    savefile                (blob, name) {
        let link            = document.createElement('a');
        document.body.appendChild(link);
        link.style          = "display: none";
        const url           = URL.createObjectURL(blob);
        link.href           = url;
        link.download       = name;
        setTimeout(() => {
            link.click();
            URL.revokeObjectURL(url);
        }, 0);
    },
    downloadCsv() {
      let   lines     = [];
      lines.push('usuario');
      const { email, name } = this.$store.getters.$userSelected;
      if (!name) {
        this.$toast.error("Falha ao carregar usuário");
        return;
      }
      lines.push(`"${name}<${email}>"`);
      const propNames = ['ativo', 'valor', 'quantidade','emissor','estrategia','percentual','vencimento','liquidez','fgc'];
      lines.push(propNames.join(','));
      this.desserts.forEach((i) => {
          let line = propNames.map((p) => `${JSON.stringify(i[p])}`).join(',');
          lines.push(line);
      });
      const body  = lines.join('\n');
      const blob  = new Blob([body], {type:'text/csv; charset=utf-8'});
      this.savefile(blob, `${name.trim()+'_tipos' || 'report_monthly'}.csv`);
    },

    showModal(e, evt, item) {
      this.controller = true;
      this.mostrarModal = true;

      if (e === evt.currentTarget.id) {
        if (!this.element) {
          this.element = evt.currentTarget;
        } else {
          this.element = evt.currentTarget;
        }
      }
      let obj = { show: this.mostrarModal, view: e };


        EventBus.$emit("mostrarModal", obj);

      setTimeout(() => {
        this.controller = false;
      }, 1000);
    },
    dataInicialChange(value) {
      return new Promise((acc, rej) => {
        if (!value) { acc(); }
        try {
          let custodians = [];
          this.loading    = true;
          const parsed    = new Date(value + 'T00:00:00');
          const full      = this.calculador.reportRentabilidade(this.bloco, parsed, custodians);
          this.rate       = this.getRates(full);
          this.desserts = full && full.report ? full.report : [];
          this.movimentos = full && full.movimentos ? full.movimentos : [];
          acc();
        } catch (reason) {
          window["console"].error(reason);
          this.$toast.error("Erro ao calcular Ativos");
          rej(reason);
        } finally {
          this.loading = false;
        }
      });
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  computed: {
  },
};
</script>

<style scoped>
@media screen and (max-width: 610px) {
  .carteiras-usuarios {
    width: 100% !important;
  }
}
.card-dialog-plano {
  padding: 2rem 0;
  text-align: center;
}
.img-notification {
  /* border: 1px solid red; */
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #f25a5a;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.img-notification img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.title-limite-plano {
  font-size: 15px !important;
  display: flex;
  align-items: center;
  justify-content: center;

  font-weight: 500 !important;
  font-size: 18px !important;
  line-height: 27px !important;
  /* identical to box height */

  letter-spacing: 0.15px !important;

  color: #000000 !important;
}
.btn-gerenciar-plano {
  border: 1px solid;
  width: 100%;
  border-radius: 20px;
  padding: 1.4rem 0 !important;
}
.btn-tentar-de-novo {
  padding: 1.2rem 1rem !important;
  border-radius: 20px;
  color: white !important;
  transition: 0.2s ease-in;
  cursor: default;
}
.Report-topo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  flex-wrap: wrap;
}
.carteiras-usuarios {
  padding: 1rem;
  background: #ffffff;
  box-shadow: -4px 0px 10px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  width: 304px;
  height: 100px;
  display: flex;
  align-items: center;
}
.icon-carteira figure {
  background: #e4e4e4;
  width: 58px;
  height: 58px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon-carteira figure img {
  filter: invert(67%) sepia(7%) saturate(19%) hue-rotate(26deg) brightness(88%)
    contrast(92%);
}
.info-carteira {
  margin-left: 0.5rem;
}
.info-carteira span {
  display: block;
}
.info-carteira .title-carteira {
  font-size: 25px;
  line-height: 37px;
  letter-spacing: 0.15px;
  color: #000000;
}
.info-carteira .descricao-carteira {
  font-weight: 300;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.15px;
  color: #000000;
}
.v-text-field {
  width: 100px !important;
}
.v-tooltip__content {
  border-radius: 30px !important;
  z-index: 20 !important;
}
.v-tooltip__content::after {
  content: " ";
  position: absolute;
  bottom: 100%; /* At the top of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent var(--principal-azul) transparent;
}
.top {
  padding: 1.5rem;
}
.col-2 {
  display: flex;
}
.v-text-field {
  width: 200px;
}
.col-2 .teste {
  padding-top: 10px;
  margin-right: 25px;
}
.v-card {
  border-radius: 20px;
  margin-top: 0.5rem;
}
.container {
  padding: 0;
}
.title-data {
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 0.15px;
  color: #494949;
}
.v-data-table {
  width: 100%;
}
.btn-icon {
  cursor: pointer;
  width: 20px;
  display: inline-block;
  margin: 0 0.5rem 0;
}
.componentes-top {
  /* border: 1px solid red !important; */
  width: 100% !important;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.inputs-card {
  display: flex;
  align-items: center;
  justify-content: center;
}
.v-text-field {
  display: flex;
  align-items: center;
  justify-content: center;
}
.Report {
  padding: 1.8rem;
}
.Report .title h1 {
  font-weight: 300;
  font-size: 25px;
  line-height: 37px;
  letter-spacing: 0.15px;
  color: #000000;
}
.cards-gestao {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.cards-gestao .card {
  border-radius: 20px;
  padding: 1.8rem;
  background: white;
  flex: 1 0 300px;
  display: flex;
  align-items: center;
  margin: 0.3rem;
}
.card .img {
  width: 68px;
  height: 68px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1.5rem;
}
.ray .img {
  background: #b4e54c;
}
.check .img {
  background: #f2ab57;
}
.dollar .img {
  background: var(--principal-azul);
}
.dollar .img img {
  filter: invert(0%) sepia(0%) saturate(7490%) hue-rotate(353deg)
    brightness(115%) contrast(99%);
}
.warnin .img {
  background: #f25a5a;
}
.descricao .numero {
  font-size: 25px;
  line-height: 37px;
  letter-spacing: 0.15px;
}
.descricao .texto {
  font-weight: 300;
  font-size: 15px;
  line-height: 22px;
}

/* TABELAAAAA */
.profile {
  display: flex !important;
  align-items: center !important;
}
.profile .img-profile {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  border: 1px solid;
  overflow: hidden;
  margin-right: 0.3rem;
}
.img-profile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.v-data-table >>> th {
  /* border-spacing: 0 0.5rem; */
  /* border-top:  none !important; */
  border-top: none;
}
.v-data-table >>> td {
  /* padding: .5rem 0 !important; */
  padding-top: 0.8rem !important;
  padding-bottom: 0.8rem !important;
}
.card-dialog {
  text-align: center;
  padding: 2rem 0;
  position: relative;
}
.btn-close-dialog {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 25px;
  top: 25px;
  cursor: pointer;
}
.card-dialog .text-h5 {
  text-align: center !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.descricao-dialog {
  margin: 0.5rem 0;
}
.top-rate {
  text-align: left;
}

.divImagemPrinting{
width:100%;
height:0px;
}
.buttonImagemPrinting{
width:50px;
margin-top: 14px;
margin-left: 96%;
}
.parent-item {
  background-color: rgba(96,96,96,0.5);
}
.child-item {
  margin-left: 20px
}
</style>
