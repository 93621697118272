import { render, staticRenderFns } from "./TermsAndConditions.vue?vue&type=template&id=3f280fe9&scoped=true"
import script from "./TermsAndConditions.vue?vue&type=script&lang=js"
export * from "./TermsAndConditions.vue?vue&type=script&lang=js"
import style0 from "./TermsAndConditions.vue?vue&type=style&index=0&id=3f280fe9&prod&scoped=true&lang=css"
import style1 from "./TermsAndConditions.vue?vue&type=style&index=1&id=3f280fe9&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f280fe9",
  null
  
)

export default component.exports