<script>
import { Bar, mixins } from "vue-chartjs";
const { reactiveProp } = mixins
const dadosGraficos = [];

export default {
  extends: Bar,
  mixins:[reactiveProp],
  props: {
    options: { type: Object },

  },
  methods: {
    renderizarChart() {
      this.renderChart(
        this.chartData,
        this.options
      );
    },
  },
  mounted() {
    this.renderizarChart();
  },
};
</script>