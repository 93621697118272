<template>
  <v-container fluid class="container">
    <SwitchesTop
      :switchState="switchState"
      :caregoryList="caregoryList"
      :defaultSelected="defaultSelected"
      @changeSwitchVisualizacao="changeSwitchVisualizacao"
      @switchsMoedas="switchsMoedas"
      @changeKey="changeKey"
      @onChangeEstrategy="onChange"
      @date-range-changed="changeRange"
    />

    <div class="switch-view-all">
      <label>
        <input type="checkbox" v-model="showAll" />
        Exibir ativos encerrados
      </label>
    </div>

    <CardTopInformacoes
      class="cards-top"
      :switchVisualizacao="switchVisualizacao"
      :selectedType="selectedType"
      :indice="indice"
      :monthLabel="monthLabel"
      :timeLabel="timeLabel"
    />

    <BarraDeCarteira
      :switchVisualizacao="switchVisualizacao"
      :wallet="wallet"
      :evolucao="evolucao"
      :bound="evolucao && evolucao.bound || false"
    />

    <div v-if="!loading">
      <AccordionGroupClasseEProduto
        v-if="selectedType == 'porClasseEProduto'"
        :switchVisualizacao="switchVisualizacao"
        :telaMobile="telaMobile"
        :dessertsCdbRdb="dessertsCdbRdb"
        :dessertsCriCra="dessertsCriCra"
        :dessertsDebentures="dessertsDebentures"
        :dessertsLciLca="dessertsLciLca"
        :dessertsTitulosPublicos="dessertsTitulosPublicos"
        :dessertsAcoes="dessertsAcoes"
        :dessertsFundosImobiliarios="dessertsFundosImobiliarios"
        :dessertsFundosInvestimento="dessertsFundosInvestimento"
        :dessertsPrevidencia="dessertsPrevidencia"
        :dessertsOutros="dessertsOutros"
        :evolucao="evolucao"
        :showAll="showAll"
        :bound="evolucao && evolucao.bound || false" />
      <AccordionGroupPorEstrategia
        v-if="selectedType == 'porEstrategia'"
        :switchVisualizacao="switchVisualizacao"
        :telaMobile="telaMobile"
        :dessertsJurosPre="dessertsJurosPre"
        :dessertsJurosPosCdi="dessertsJurosPosCdi"
        :dessertsJurosPosInflacao="dessertsJurosPosInflacao"
        :dessertsLivre="dessertsLivre"
        :dessertsRendaVariavel="dessertsRendaVariavel"
        :dessertsCambio="dessertsCambio"
        :evolucao="evolucao"
        :showAll="showAll"
        :bound="evolucao && evolucao.bound || false" />
    </div>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import SwitchesTop from "./internalTemplates/SwitchesTop.vue";
import CardTopInformacoes from "./internalTemplates/CardsTopInformacoes.vue";
import BarraDeCarteira from "./internalTemplates/BarraDeCarteria.vue";
import AccordionGroupClasseEProduto from "./internalTemplates/AccordionGroupClasseEProduto.vue";
import AccordionGroupPorEstrategia from "./internalTemplates/AccordionGroupPorEstrategia.vue";
import ApiService from "../../../services/ApiService";
import CalculadorAtivo from "../../../services/Calculador";
import EventBus from '../../Bus/bus'
import CsvHelper from "@/Utils/CsvHelper";
export default {
  data() {
    return {
      api: new ApiService(),
      calculador: new CalculadorAtivo().setDefaultLoaders(),
      loading: false,
      //user: {},
      switchState: true,
      windowWidth: window.innerWidth,
      panel: [0, 1],
      btnsAtivosMounted: undefined,
      indice: "CDI",
      elemento: "",
      dados: [],
      defaultSelected: {
        name: "POR PRODUTO",
        value: "porClasseEProduto",
      },
      selectedType: "",
      byStrategy: false,
      switchVisualizacao: true,
      caregoryList: [
        { name: "POR PRODUTO", value: "porClasseEProduto" },
        { name: "POR ESTRATÉGIA", value: "porEstrategia" },
      ],
      dessertsCdbRdb: {},
      dessertsCriCra: {},
      dessertsDebentures: {},
      dessertsLciLca: {},
      dessertsTitulosPublicos: {},
      dessertsAcoes: {},
      dessertsFundosImobiliarios: {},
      dessertsFundosInvestimento: {},
      dessertsPrevidencia: {},
      dessertsOutros: {},

      dessertsJurosPre: {},
      dessertsJurosPosCdi: {},
      dessertsJurosPosInflacao: {},
      dessertsLivre: {},
      dessertsRendaVariavel: {},
      dessertsCambio: {},

      wallet: {},
      evolucao: {},
      monthLabel: '',
      timeLabel: '',
      key: 'CDI',
      showAll: false,
		  fillIndex: 0,
      dateRange: undefined
    };
  },
  created() {
    EventBus.$emit("ListaDeUsuariosSelected", true);
    this.getInfos();
    EventBus.$on('reloadPagesForCallActivesAgain', this.ebReload);
    EventBus.$on('grossUpChanged', this.ebGrossUp);
  },
  components: {
    SwitchesTop,
    CardTopInformacoes,
    BarraDeCarteira,
    AccordionGroupClasseEProduto,
    AccordionGroupPorEstrategia,
  },
  methods: {
    ...mapActions(["RENTABILIDADE_ITEM"]),

    ebReload (data) {
      if (!data) { return; }
      this.getInfos();
    },
    ebGrossUp (state) {
      if (!this.evolucao) { return; }
      this.evolucao.updateTo(this.key, state);
      this.updateCotacoes();
    },

    createFiller(tipo, name) {
      return (e, i) => {
        if (!this.evolucao[tipo][name].items.length) { return; }
        let item      = this.evolucao[tipo][name].items[i];
        e.value       = item.evolucao.saldo || 0.0;
        e.percentege  = item.evolucao.participacao || 0.0;
        e.mesAtual    = item.evolucao.mensal.ganho;
        e.percent1    = item.evolucao.mensal.percentual;
        e.valor1      = item.evolucao.mensal.valor;
        e.dozeMeses   = item.evolucao.doze.ganho;
        e.percent2    = item.evolucao.doze.percentual;
        e.valor2      = item.evolucao.doze.valor;
        e.desdeInicio = item.evolucao.geral.ganho;
        e.percent3    = item.evolucao.geral.percentual;
        e.valor3      = item.evolucao.geral.valor;
				const head    = `[${++this.fillIndex}] - `;
				if (!/^\[\d+\]/.test(e.title)) {
					e.title     = `${head}${e.title}`;
				}
      };
    },
    getInfos() {
      this.monthLabel = '___/____';
      this.timeLabel = '__/__/____ a __/__/____';
      this.evolucao = this.calculador.wrap();
      [ "CdbRdb","CriCra","Debentures","LciLca","TitulosPublicos","Acoes",
        "FundosImobiliarios","FundosInvestimento","Previdencia","Outros","JurosPre",
        "JurosPosCdi","JurosPosInflacao","Livre","RendaVariavel","Cambio"]
      .reduce((a, part) => {
        a.push(this[`desserts${part}`]);
        return a;
      }, [this.wallet])
      .forEach((part) => {
        if (!part.items) { return; }
        part.items.length = 0;
        part.participationPercentage = 0;
        part.totalValue = 0;
      });
      EventBus.$emit(
        "VisaoGeral.LoadingChanged",
        this.loading = true);
      this.fillIndex = 0;
      this.api.getUserToAtivoUser(this.byStrategy,this.$store, this.dateRange)
      .then((res) => {
          this.evolucao =this.calculador.wrap(res.evolucao);
          let cont = document.querySelector("#csv-view-link");
          const trm = this.evolucao.termino;
          const yer = +trm.substring(0, 4);
          const mth = +trm.substring(5, 7) - 1;
          const mdp = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"][mth] + `/${yer}`;
          this.monthLabel = mdp;
          this.timeLabel = this.evolucao.periodo && `(${this.evolucao.periodo})` || '';
          if (cont && !cont.dataset.updated) {
            cont.dataset.updated = new Date().getTime().toString();
          }
          let calt = document.querySelector("#csv-cart-link");
          if (calt && !calt.dataset.updated) {
            calt.dataset.updated = new Date().getTime().toString();
            // ====================================================
            // #region exportBlock
            /*
            let btn = document.createElement("div");
            btn.className = "btn-csv";
            btn.appendChild(document.createTextNode("Salvar Carteira"));
            btn.addEventListener("click", () => {
              let { _, link } = CsvHelper.formatCsv(
                this.wallet, {
                  name    : "carteira.csv",
                  title   : {},
                  exclude	: [],
                  format: (path) => {
                    let secs = path && path.split('.') || [""];
                    let [grupo, tipo] = secs;
                    let head = secs.length > 1 ? secs[secs.length - 2] : "";
                    let tail = secs[secs.length - 1];
                    switch (tail) {
                      case "title": return "Nome";
                      case "totalValue": return '"Saldo bruto"';
                      case "items": return "Itens";
                      case "idAtivo": return '"Id ativo"';
                      case "idCotacao": return "Id";
                      case "ativoId":
                        if (head === "movimentos") { return '"Id ativo"'; }
                        return "Id";
                      case "percentege": return "Percentual";
                      case "estrategia": return "Estratégia";
                      case "referencia": return "Referência";
                      case "fgcDescricao": return "Fgc";
                      case "dataInvestimento": return '"Data inicial"';
                      case "value": return "Valor";
                      case "descricao": return "Descrição";
                      case "classeAmbima": return '"Classe AMBIMA"';
                      case "formaTributacao": return '"Forma de tributação"';
                      case "investedValue": return '"Investimento inicial"';
                      case "shareQuantity": return '"Cotas/Quantidade"';
                      case "shareValue": return '"Valor unitário"';
                      case "investmentDate": return "Data";
                      case "tpMovimentoDesc": return '"Tipo movimentação"';
                      case "rate": return "taxa";
                      case "cotacoes": return "Cotações";
                      case "cdi": return "CDI";
                      case "ipca": return "IPCA";
                      case "igpm": return "IGPM";
                      case "dolar": return "Dólar";
                      case "ibovespa": return "IBovespa";
                      case "mensal": return `"${mdp}"`;
                      case "doze": return '"12 meses"';
                      case "saldo": return '"Saldo bruto"';
                      case "liquido": return '"Saldo líquido"';
                      case "ganho": return '"Ganho percentual"';
                      case "percentual": return '"% índice"';
                      case "valor": return '"Ganho R$"';
                      case "geral": return '"Desde o início"';
                    }
                    return tail && tail.substring(0, 1).toUpperCase() + tail.substring(1) || "";
                  },
                  filter: (path) => {
                    let secs = path && path.split('.') || [""];
                    let tail = secs[secs.length - 1];
                    return [
                      "participationPercentage", "fgc", "userId", "idGestor",
                      "idTProduto", "idEstrategia", "tpMovimento"
                      //"fgc", "userId", "fatia", "participacao",
                    ].indexOf(tail) < 0;
                  }
                });
              link.click();
            });
            calt.appendChild(btn);
            */
            // #endregion
            // ====================================================
          }
          this.evolucao.updateTo(this.key, false);

          if (res.content.byStrategy) {
            res.content.jurosPre.items.forEach(this.createFiller("estrategia", "jurosPre"));
            res.content.jurosPosCdi.items.forEach(this.createFiller("estrategia", "jurosPosCdi"));
            res.content.jurosPosInflacao.items.forEach(this.createFiller("estrategia", "jurosPosInflacao"));
            res.content.livre.items.forEach(this.createFiller("estrategia", "livre"));
            res.content.rendaVariavel.items.forEach(this.createFiller("estrategia", "rendaVariavel"));
            res.content.cambio.items.forEach(this.createFiller("estrategia", "cambio"));
            this.dessertsJurosPre = res.content.jurosPre;
            this.dessertsJurosPosCdi = res.content.jurosPosCdi;
            this.dessertsJurosPosInflacao = res.content.jurosPosInflacao;
            this.dessertsLivre = res.content.livre;
            this.dessertsRendaVariavel = res.content.rendaVariavel;
            this.dessertsCambio = res.content.cambio;
            this.wallet = res.content.all;
          } else {
            res.content.cdbRdb.items.forEach(this.createFiller("produto", "cdbRdb"));
            res.content.criCra.items.forEach(this.createFiller("produto", "criCra"));
            res.content.debentures.items.forEach(this.createFiller("produto", "debentures"));
            res.content.lciLca.items.forEach(this.createFiller("produto", "lciLca"));
            res.content.titulos.items.forEach(this.createFiller("produto", "titulos"));
            res.content.acoes.items.forEach(this.createFiller("produto", "acoes"));
            res.content.fundosImobiliarios.items.forEach(this.createFiller("produto", "fundosImobiliarios"));
            res.content.fundosInvestimento.items.forEach(this.createFiller("produto", "fundosInvestimento"));
            res.content.previdencia.items.forEach(this.createFiller("produto", "previdencia"));
            res.content.outros.items.forEach(this.createFiller("produto", "outros"));
            this.dessertsCdbRdb = res.content.cdbRdb;
            this.dessertsCriCra = res.content.criCra;
            this.dessertsDebentures = res.content.debentures;
            this.dessertsLciLca = res.content.lciLca;
            this.dessertsTitulosPublicos = res.content.titulos;
            this.dessertsAcoes = res.content.acoes;
            this.dessertsFundosImobiliarios = res.content.fundosImobiliarios;
            this.dessertsFundosInvestimento = res.content.fundosInvestimento;
            this.dessertsPrevidencia = res.content.previdencia;
            this.dessertsOutros = res.content.outros;
            this.wallet = res.content.all;
          }
          EventBus.$emit(
            "VisaoGeral.LoadingChanged",
            this.loading = false);
          EventBus.$emit('VisaoGeral.GotAtivos', this.wallet && this.wallet.items || []);
        })
        .catch((error) => {
          //window["console"]["log"](error);
          EventBus.$emit(
            "VisaoGeral.LoadingChanged",
            this.loading = false);
        });
    },
    switchsMoedas(e) {
      this.indice = e;
/*    if (!this.indice) {
        this.dados.push(e);
        let posicao = this.dados.length - 1;
        this.indice = this.dados[posicao].value;
      } else if (this.indice != e.value && e.state == true) {
        if (!this.dados.some((x) => x.value == e.value)) {
          this.dados.push(e);
          this.indice = e.value;
        } else {
          for (let i = 0; i < this.dados.length; i++) {
            if (this.dados[i].value == e.value) {
              this.dados[i].state = e.value;
              this.indice = e.value;
            }
          }
        }
      }

      if (e.state == false) {
        for (let i = 0; i < this.dados.length; i++) {
          if (this.dados[i].value == e.value) {
            this.dados[i].state = false;
          }
        }
      }
      let allFalse = this.dados.every((x) => x.state == false);

      if (allFalse) {
        this.indice = "";
      }*/
    },
    closeDelete() {},
    deletarAtivo() {},
    closeDialog() {},
    dialogDelete() {},
    $formatedNumber(val) {
      let formato = {
        minimumFractionDigits: 2,
        style: "currency",
        currency: "BRL",
      };
      return `${val.toLocaleString("pt-BR", formato)}`;
    },
    changeSwitchVisualizacao(val) {
      this.switchVisualizacao = val.state;
      this.switchState = val.state;
    },
    updateCotacoes () {
      this.dessertsJurosPre
        && this.dessertsJurosPre.items
        && this.dessertsJurosPre.items.forEach(this.createFiller("estrategia", "jurosPre"));
      this.dessertsJurosPosCdi
        && this.dessertsJurosPosCdi.items
        && this.dessertsJurosPosCdi.items.forEach(this.createFiller("estrategia", "jurosPosCdi"));
      this.dessertsJurosPosInflacao
        && this.dessertsJurosPosInflacao.items
        && this.dessertsJurosPosInflacao.items.forEach(this.createFiller("estrategia", "jurosPosInflacao"));
      this.dessertsLivre
        && this.dessertsLivre.items
        && this.dessertsLivre.items.forEach(this.createFiller("estrategia", "livre"));
      this.dessertsRendaVariavel
        && this.dessertsRendaVariavel.items
        && this.dessertsRendaVariavel.items.forEach(this.createFiller("estrategia", "rendaVariavel"));
      this.dessertsCambio
        && this.dessertsCambio.items
        && this.dessertsCambio.items.forEach(this.createFiller("estrategia", "cambio"));

      this.dessertsCdbRdb
        && this.dessertsCdbRdb.items
        && this.dessertsCdbRdb.items.forEach(this.createFiller("produto", "cdbRdb"));
      this.dessertsCriCra
        && this.dessertsCriCra.items
        && this.dessertsCriCra.items.forEach(this.createFiller("produto", "criCra"));
      this.dessertsDebentures
        && this.dessertsDebentures.items
        && this.dessertsDebentures.items.forEach(this.createFiller("produto", "debentures"));
      this.dessertsLciLca
        && this.dessertsLciLca.items
        && this.dessertsLciLca.items.forEach(this.createFiller("produto", "lciLca"));
      this.dessertsTitulosPublicos
        && this.dessertsTitulosPublicos.items
        && this.dessertsTitulosPublicos.items.forEach(this.createFiller("produto", "titulos"));
      this.dessertsAcoes
        && this.dessertsAcoes.items
        && this.dessertsAcoes.items.forEach(this.createFiller("produto", "acoes"));
      this.dessertsFundosImobiliarios
        && this.dessertsFundosImobiliarios.items
        && this.dessertsFundosImobiliarios.items.forEach(this.createFiller("produto", "fundosImobiliarios"));
      this.dessertsFundosInvestimento
        && this.dessertsFundosInvestimento.items
        && this.dessertsFundosInvestimento.items.forEach(this.createFiller("produto", "fundosInvestimento"));
      this.dessertsPrevidencia
        && this.dessertsPrevidencia.items
        && this.dessertsPrevidencia.items.forEach(this.createFiller("produto", "previdencia"));
      this.dessertsOutros
        && this.dessertsOutros.items
        && this.dessertsOutros.items.forEach(this.createFiller("produto", "outros"));
    },
    changeKey(key) {
      this.key = key;
      if (this.evolucao) {
        this.evolucao.updateTo(key, false);
        this.updateCotacoes();
      }
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    onChange(e) {
      this.selectedType = e;
      this.byStrategy = !this.byStrategy;
      this.getInfos();
    },
    goCarteira(e) {
      let obj = {
        item: e,
        show: true,
      };
      this.$router.push({ name: "carteira", params: obj });
    },
    changeRange(range) {
      const nl = (a) => a === null || a === undefined;
      const eq = (l, r) => {
        const [ln, rn] = [nl(l), nl(r)];
        if (ln && rn) { return true; }
        if (ln || rn) { return false; }
        return l === r;
      }
      if (range && this.dateRange && eq(range[0], this.dateRange[0]) && eq(range[1], this.dateRange[1])) { return; }
      if (range[0] && range[1]) {
        this.dateRange = range;
      } else {
        if (this.dateRange && !nl(this.dateRange[0])) {
          this.dateRange = [undefined, undefined];
        } else {
          return;
        }
      }
      this.getInfos();
    }
  },
  mounted() {
    this.selectedType = this.defaultSelected.value;
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    this.btnsAtivosMounted = this.$refs.btnsAtivos;
    this.changeSwitchVisualizacao({state: true});
  },
  beforeDestroy() {
    EventBus.$off('reloadPagesForCallActivesAgain', this.ebReload);
    EventBus.$off('grossUpChanged', this.ebGrossUp);
    window.removeEventListener("resize", this.onResize);
  },
  computed: {
    telaMobile() {
      return this.windowWidth > 950 ? false : true;
    },
  },
  watch: {
    switchState(nv, ov) {
      if (nv) {
        this.indice = "CDI";
      }
    },
  },
};
</script>

<style scoped>
/*  ********** FIM PAINEIS ************** */
.cards-top .result-line {
  position: relative;
}
.cards-top {
  position: relative;
}
@media screen and (max-width: 950px) {
  .elements .cards-top,
  .cards-top-2 {
    display: none;
  }

  .cards-top {
    display: none;
  }
}
</style>