<template>
  <v-container fluid class="rentabilidade">
    <div class="d-flex justify-center align-center pa-4" v-if="loading || emErro">
      <v-progress-circular
        :size="35"
        color="primary"
        indeterminate
        v-if="loading"></v-progress-circular>
      <div style="color: red; font-weight: bold;" v-if="emErro">{{ this.errorMessage }}</div>
    </div>
    <div class="tabs" ref="tabs">
      <div
        class="tab active"
        id="rentabilidade"
        @click="changeTab('rentabilidade', $event)"
      >
        <span class="title-tab">Rentabilidade</span>
      </div>
      <div
        class="tab"
        id="resultadoFinanceiro"
        @click="changeTab('resultadoFinanceiro', $event)"
      >
        <span class="title-tab">Resultado Financeiro</span>
      </div>
      <div
        class="tab"
        id="atribuicaoEstrategia"
        @click="changeTab('atribuicaoEstrategia', $event)"
      >
        <span class="title-tab">Atribuição por estratégia</span>
      </div>
      <div class="tab" id="alocacaoTotal" @click="changeTab('alocacaoTotal', $event)">
        <span class="title-tab">Alocação Total</span>
      </div>
      <div
        class="tab"
        id="desempenhoCarteira"
        @click="changeTab('desempenhoCarteira', $event)"
      >
        <span class="title-tab">Desempenho por carteira</span>
      </div>
      <div
        class="tab"
        id="Custodiante"
        @click="changeTab('Custodiante', $event)"
      >
        <span class="title-tab">Custodiante</span>
      </div>
    </div>
    <div class="telas">
      <component :is="currentView" :calculos="calculos" :dozeMeses="dozeMeses"></component>
    </div>
  </v-container>
</template>
<script>
import EventBus from "../../Bus/bus";
import ApiService from "../../../services/ApiService";
import CalculadorAtivo from "../../../services/Calculador";
import rentabilidade from "./components/rentabilidade.vue";
import resultadoFinanceiro from "./components/resultado-financeiro.vue";
import atribuicaoEstrategia from "./components/atribuicao-estrategia.vue";
import alocacaoTotal from "./components/alocacao-total.vue";
import desempenhoCarteira from "./components/desempenho-carteira.vue";
import Custodiante from "./components/custodiante.vue";
export default {
  components: {
    Custodiante,
    rentabilidade,
    resultadoFinanceiro,
    atribuicaoEstrategia,
    alocacaoTotal,
    desempenhoCarteira,
  },
  data() {
    return {
      currentView: "rentabilidade",
      tabs: "",
      api: new ApiService(),
      calculador: new CalculadorAtivo().setDefaultLoaders(),
      loading: false,
      //user: {},
      calculos: {},
      dozeMeses: [],
      emErro: false,
      errorMessage: "",
    };
  },
  methods: {
    setError (reason) {
      this.emErro = !!reason;
      this.errorMessage = reason
        && ((reason.body && reason.body.Message || (reason.body && reason.body.content && reason.body.content.Message))
        || (reason.message || reason.Error)
        || `${reason}`)
        || undefined;
    },
    load () {
      let model = {
        //userId: this.user.id,
         userId:this.$store.getters.$tokenUserSelected,
        byStrategy: false,
      };
      this.loading = true;
      let self = this;
     /*  this.api
       .post("userToAtivo/user", model)
        .then((res) => {
          self.calculos = this.calculador.calcularRentabilidade(res.content.all.items);
          self.dozeMeses = this.calculador.calcular12UltCarteira(res.content.all.items);
          self.loading = false;
        })*/
        this.api.getUserToAtivoUserRentabilidade(false,this.$store)
      .then((res) => {
          self.calculos = res.calculos;
          self.dozeMeses =res.dozeMeses;
          self.loading = false;
        })
        .catch((reason) => {
          self.loading = false;
          self.setError(reason);
        });
    },
    changeTab(e, evt) {
      this.currentView = e;

      for (let data of this.tabs.children) {
        if (data.classList.contains("active")) {
          data.classList.remove("active");

          if (e === evt.currentTarget.id) {
            evt.currentTarget.classList.add("active");
          }
        }
      }
    },
    ebReload (data) {
      if (!data || this.loading) { return; }
      this.loading = true;
      this.load();
    }
  },
  mounted() {
    this.load();
    this.tabs = this.$refs.tabs;
  },
  created () {
    EventBus.$emit("ListaDeUsuariosSelected", true);
    EventBus.$on('reloadPagesForCallActivesAgain', this.ebReload);
  },
  beforeDestroy () {
    EventBus.$off('reloadPagesForCallActivesAgain', this.ebReload);
  }
};
</script>
<style  scoped>
.rentabilidade {
  padding: 1rem;
}

.tabs {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}
.tabs .tab {
  flex: 0 0 225px;
  padding: 0.3rem .7rem;
  border: 1px solid rgba(73, 73, 73, 0.3);
  box-sizing: border-box;
  border-radius: 30px;
  margin: 0.5rem;
  color: rgba(73, 73, 73, 0.3);
  cursor: pointer;
  text-align: center;
}
.active {
  background: rgba(73, 73, 73, 0.3);
  color: white !important;
  border: none !important;
}
</style>