import Layout from "../../Internal/Layout.vue";
import ReportMonth from "./ReportMonth.vue";

const ReportMonthRoutes = {
    component: Layout,
    children: [
        {
            name: "reportmonth",
            path: "/",
            component: ReportMonth,
            meta: {
                requiresAuth: true,
                requiresBasic: true,
                requiresPattern: true,
                requiresSenior: true,
                requiresExpert: true,
                requiresProfessional: true,
            },
        },
    ],
};

export default ReportMonthRoutes;
