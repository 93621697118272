<template>
    <div class="base container-fluid">
        <div class="title">
            <span><span style="color: #b5e546">Investimento(s)</span> importados</span>
            <button type="button" @click="reloadInvestments">Recarregar</button>
        </div>

        <div v-if="loading" class="d-flex justify-center align-center pa-8">
            <v-progress-circular :size="35"
                                 color="primary"
                                 indeterminate />
        </div>
        <div>&nbsp;</div>

        <div class="row">
            <div class="block" v-for="item in items" :key="item.id">
                <div class="item-row item-info-row" @click="toggleVisible(item)">
                    <div class="item-full">Importação</div>
                    <div class="item-33">Importação: {{ formatDate(item.createdAt) }}</div>
                    <div class="item-33">Atualizado: {{ formatDate(item.updatedAt) }}</div>
                    <div class="item-33">
                        De: {{ item && item.connector && item.connector.name || '???' }}
                    </div>
                    <div class="btn-update" @click="importItem($event, item)">Importar</div>
                </div>
                <div class="block" v-if="item.visible && item.investments && item.investments.length">
                    <div class="children" v-for="investment in item.investments" :key="investment.investmentId">
                        <div class="item-row investment-info-row" @click="toggleVisible(investment)">
                            <div class="item-full">{{ investment.name }}</div>
                            <div class="item-33">Status: {{ investment.status_imp }}</div>
                            <div class="item-33">{{
                                optionMessage(investment.message_imp, 'Mensagem:')
                            }} {{
                                investment.message_imp
                            }}</div>
                            <div class="item-33">{{
                                optionMessage(investment.date_imp, 'Data:')
                            }} {{
                                formatDate(investment.date_imp)
                            }}</div>
                            <div class="item-33 hlt" v-if="investment.visible"
                                 @click="editCode($event, investment)"
                                 title="Clique para editar o código">Código: {{ investment.code }}</div>
                            <div class="item-33" v-if="investment.visible">Isin: {{ investment.isin }}</div>
                            <div class="item-33" v-if="investment.visible">No.: {{ investment.number }}</div>
                            <div class="item-33" v-if="investment.visible">Propr.: {{ investment.owner }}</div>
                            <div class="item-33" v-if="investment.visible">Moeda: {{ investment.currencyCode }}</div>
                            <div class="item-33" v-if="investment.visible">Tipo: {{ investment.type }}</div>
                            <div class="item-33" v-if="investment.visible">Subt.: {{ investment.subtype }}</div>
                            <div class="item-33" v-if="investment.visible">T. últ. mês: {{ percentageHelper.formatStandard(investment.lastMonthRate) }}</div>
                            <div class="item-33" v-if="investment.visible">T. anual: {{ percentageHelper.formatStandard(investment.annualRate) }}</div>
                            <div class="item-33" v-if="investment.visible">Data: {{ formatDate(investment.date) }}</div>
                            <div class="item-33" v-if="investment.visible">Valor: {{ currencyHelper.formatValue(investment.value) }}</div>
                            <div class="item-33" v-if="investment.visible">Quantidade: {{ investment.quantity }}</div>
                            <div class="item-33" v-if="investment.visible">Total: {{ investment.amount }}</div>
                            <div class="item-33" v-if="investment.visible">Taxas: {{ investment.taxes }}</div>
                            <div class="item-33" v-if="investment.visible">Tx. adic.: {{ investment.taxes2 }}</div>
                            <div class="item-33" v-if="investment.visible">Saldo: {{ currencyHelper.formatValue(investment.balance) }}</div>
                            <div class="item-33" v-if="investment.visible">Venc.: {{ formatDate(investment.dueDate) }}</div>
                            <div class="item-33" v-if="investment.visible">Taxa: {{ percentageHelper.formatStandard(investment.rate) }}</div>
                            <div class="item-33" v-if="investment.visible">Tp. Taxa: {{ investment.rateType }}</div>
                            <div class="item-33" v-if="investment.visible">T. fix. anual: {{ percentageHelper.formatStandard(investment.fixedAnnualRate) }}</div>
                            <div class="item-33" v-if="investment.visible">Emissor: {{ investment.issuer }}</div>
                            <div class="item-33" v-if="investment.visible">Emissão: {{ formatDate(investment.issueDate) }}</div>
                            <div class="item-33" v-if="investment.visible">Lucro: {{ investment.amountProfit }}</div>
                            <div class="item-33" v-if="investment.visible">Saque: {{ currencyHelper.formatValue(investment.amountWithdrawal) }}</div>
                            <div class="item-33" v-if="investment.visible">Original: {{ investment.amountOriginal }}</div>
                            <div class="item-33" v-if="investment.visible">Status: {{ investment.status }}</div>
                            <div class="item-33" v-if="investment.visible">Nome b.: {{ investment.institution && investment.institution.name }}</div>
                            <div class="item-33" v-if="investment.visible">No. b.: {{ investment.institution && investment.institution.number }}</div>
                        </div>
                        <div class="block" v-if="investment.visible && investment.transactions && investment.transactions.length">
                            <div class="item-row transaction-info-row">
                                <div class="item-full">Transações</div>
                            </div>
                            <div class="item-row transaction-info-row" v-for="transaction in investment.transactions" :key="transaction.transactionId">
                                <div class="item-33">Neg.: {{ formatDate(transaction.tradeDate) }}</div>
                                <div class="item-33">Data: {{ formatDate(transaction.date) }}</div>
                                <div class="item-33">Quant.: {{ transaction.quantity }}</div>
                                <div class="item-33">Valor: {{ currencyHelper.formatValue(transaction.value) }}</div>
                                <div class="item-33">Total: {{ transaction.amount }}</div>
                                <div class="item-33">Tipo: {{ transaction.type }}</div>
                                <div class="item-33">Descr.: {{ transaction.description }}</div>
                                <div class="item-33">N. corr.: {{ transaction.brokerageNumber }}</div>
                            </div>
                        </div>
                        <div class="block empty" v-if="investment.visible && !(investment.transactions && investment.transactions.length)">
                            Nenhuma transação encontrada
                        </div>
                    </div>
                </div>
                <div class="item-row empty" v-if="!(item.investments && item.investments.length)">
                    Nenhum investimento encontrado.
                </div>
            </div>
            <div class="block empty" v-if="!(items && items.length)">
                Nenhuma importação encontrada.
            </div>
        </div>
    </div>
</template>

<style scoped>
.base {
    margin: 1em 0;
}
.title span {
  font-weight: 300;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.15px;
}
.title button {
    cursor: pointer;
    margin-left: 2em;
    color: rgba(128, 128, 128, 0.5);
    border: 1px solid rgba(128, 128, 128, 0.5);
    border-radius: 10px;
    padding: 0 0.5em;
}
.block {
    display: flex;
    flex-direction: column;
}
.row > .block {
    width: 100%;
}
.item-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    cursor: pointer;
}
.children {
    display: flex;
    flex-direction: column;
}
.item-row .item-33 {
    width: 33.333%;
    flex-basis: 33.333%;
    min-width: 33.333%;
}
.item-row .item-33.hlt {
    background-color: rgba(128,128,128,0.75);
    border-radius: 8px;
    padding-left: 8px;
}
.item-row .item-full {
    width: 100%;
    flex-basis: 100%;
    min-width: 100%;
    font-weight: bold;
}
.item-row .children {
    width: 100%;
    flex-basis: 100%;
    min-width: 100%;
}
.item-info-row {
    border-top: 2px solid rgba(128,0,0,0.75);
    padding: 0.25em;
}
.investment-info-row {
    border-top: 2px solid rgba(0,128,0,0.75);
    padding: 0.25em;
}
.transaction-info-row {
    border-top: 2px solid rgba(0,0,128,0.75);
    padding: 0.25em;
    background-color: rgba(192, 192, 192, 0.5);
}
.transaction-info-row:last-child {
    border-bottom: 2px solid rgba(128,0,0,0.75);
    margin-bottom: 2em;
}
.empty {
    border-top: 2px solid rgba(128,128,128,0.75);
    border-bottom: 2px solid rgba(128,0,0,0.75);
    padding: 0.25em;
    margin-bottom: 2em;
    background-color: rgba(192, 192, 192, 0.5);
}
.btn-update {
    position: absolute;
    right: 30px;
    border-radius: 16px;
    background-color: rgba(0,64,0,0.5);
    color: white;
    font-weight: bold;
    padding: 0.25em;
    max-width: 10%;
    width: 10%;
    text-align: center;
    margin-top: 0.5em;
}
@media screen and (max-width: 750px) {
    .btn-update {
        position: inherit;
        width: 100%;
        max-width: 100%;
    }
}
</style>

<script lang="js">
import ApiService from '../../../../services/ApiService';
import currencyHelper from '../../../../Utils/CurrencyHelper';
import percentageHelper from '../../../../Utils/PercentageHelper';
import EventBus from '../../../Bus/bus';

export default      {
    data            () {
        return {
            currencyHelper,
            percentageHelper,
            loading     : false,
            apiService  : undefined,
            items       : []
        }
    },
    methods         : {
        readInvestments () {
            const userId = this.$store.getters.$tokenUserSelected;
            if (!userId) {
                this.$toast.error('Usuário não identificado.');
                return;
            }
            this.loading        = true;
            this.items          = [];
            var self            = this;
            return this.apiService.getStoredInvestments(userId)
                .then(
                    (data)   => {
                        self.loading = false;
                        if (!data.success) {
                            if (data.reason.status !== 404) {
                                self.$toast.error(data.reason.body.Message);
                            }
                            return;
                        }
                        const items = data.content.map((bag) =>
                        {
                            const { parent, investments } = bag;
                            parent.investments  = investments;
                            parent.visible = false;
                            investments.forEach((inv) => {
                                inv.visible = false;
                            });
                            return parent
                        });
                        self.items          = items;
                    },
                    (reason) => {
                        self.loading = false;
                        self.$toast.error(reason.message);
                    }
                );
        },
        formatDate (value) {
            if (!value) { return '' }
            var dt = new Date(value);
            return `${dt.toLocaleDateString()} ${dt.toLocaleTimeString()}`;
        },
        toggleVisible (item) {
            item.visible = !item.visible;
        },
        getInvestmentTitle (item) {
            let head = item.status_imp;
            let body = item.date_imp ? ` - importado: ${this.formatDate(item.date_imp)}` : '';
            let tail = item.message_imp ? ` [${item.message_imp}]` : '';
            return `${head}${body}${tail}`;
        },
        ebReload (data) {
            if (!data) { return; }
            this.loading         = false;
            this.items           = [];
        },
        optionMessage(value, content) {
            return value ? content : '';
        },
        importItem(e, item) {
            e.cancelBubble = true;
            this.loading = true;
            return this.apiService.importPluggyItem(item.id)
                .then((data) => {
                    this.loading = false;
                    if (!data.success) {
                        this.$toast.error(data.message);
                        return;
                    }
                    this.$toast.info(data.message);
                    return this.readInvestments();
                });
        },
        editCode (e, investment) {
            e.cancelBubble = true;
            const call = {
                width  : 400,
                height : 300,
                view   : 'EditarCodigoInvestimento'
            };
            EventBus.$emit('openFloatModal', call, investment);
        },
        reloadInvestments () {
            return this.readInvestments();
        }
    },
    created         () {
        this.apiService = new ApiService();
        EventBus.$on('reloadPagesForCallActivesAgain', this.ebReload);
    },
    mounted         () {
        this.readInvestments();
    },
    beforeDestroy   () {
        EventBus.$off('reloadPagesForCallActivesAgain', this.ebReload);
    }
}
</script>