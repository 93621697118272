<script>
import { Line } from "vue-chartjs";

export default {
  extends: Line,
  props: {
    label: { type: String },
    label2: { type: String },
    chartData: { type: Array },
    chartData2: { type: Array },
    options: { type: Object },
    plugins: {type: Array},
    dates: {type: Array}
  },
  computed: {
    $dados2() {
      return this.chartData2;
    },
     myStyles () {
      return {
          background: '#af8542',
   height: '100px',
   width: '300px',
   position: relative,
   top: '20px',
   left: '45px'
      }
    },
  },
  methods: {
    renderizarChart() {

      const dates = this.chartData.map((d) => d.data);//.reverse();
      const valors = this.chartData.map((d) => d.valors.percent);//.reverse();
      const valors2 = this.$dados2.map((d) => d.valors.percent);//.reverse();

      this.renderChart(
        {
          labels: dates,
          datasets: [
            {
              type: "line",
              label: this.label2,
              data: valors2,
              backgroundColor: "#AED1E6",
              borderColor: "#AED1E6",
              pointRadius: 2
            },
            {
              type: "line",
              label: this.label,
              data: valors,
              backgroundColor: "#2DA6D5",
              borderColor: "#2DA6D5",
              pointRadius: 2
            },
          ],
        },
        this.options,
        this.plugins
      );
    },
  },
  mounted() {
    this.renderizarChart();
  },
  watch:{
    chartData2(){
      this.renderizarChart()
    }
  }
};
</script>