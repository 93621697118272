import Vue from "vue";
import VueResource from "vue-resource";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueTheMask from "vue-the-mask";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import "@/style/global/global.css"
import VueHtml2pdf from 'vue-html2pdf'
//import * as Sentry from "@sentry/vue";
//import { Integrations } from "@sentry/tracing";

Vue.use(VueTheMask);

const options = {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true,
  position: "top-right",
  timeout: 5000,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: true,
  hideProgressBar: false,
  icon: true,
};

Vue.use(Toast, options);

Vue.config.productionTip = false;
Vue.use(VueResource);
Vue.use(VueHtml2pdf);
/*
Sentry.init({
  Vue,
 // dsn: "http://39febfde00074ccc94f34d9159ad16c0@20.206.132.74:9000/17",
 dsn: "https://748b204e837046bdae0f47122b01bbaa@o1305843.ingest.sentry.io/6547724",
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "https://topazio-front.azurewebsites.net", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});*/

Vue.$globalEvent = new Vue();


new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
