import Layout from "../../Internal/Layout.vue";
import Perfil from "./Perfil.vue";

const PerfilRoutes = {
  component: Layout,
  children: [
    {
      path: "/",
      component: Perfil,
      children:[
        {
          name:'cadastro',
          path: '',
          component: () => import('./internalPages/Cadastro.vue')
        },
        {
          name: 'alterarSenhaPerfil',
          path: 'alterar-senha',
          component: () => import('./internalPages/AlterarSenha.vue')
        },
        {
          name: 'cancelarPlano',
          path: 'cancelar-plano',
          component: () => import('./internalPages/CancelarPlano.vue'),
          meta:{
            requiresAuth: true,
            requiresPattern: true,
            requiresSenior: true,
            requiresExpert: true,
            requiresProfessional: true,
          }
        }
        ,
        {
          name: 'cancelarPlanoConfirmacao',
          path: 'confirmacao-cancelamento',
          component: () => import('./internalPages/ConfirmacaoCancelamento.vue'),
          meta:{
            requiresAuth: true,
            requiresPattern: true,
            requiresSenior: true,
            requiresExpert: true,
            requiresProfessional: true,
          }
        }
      ],
      meta: {
        requiresAuth: true,
        requiresBasic: true,
        requiresPattern: true,
        requiresSenior: true,
        requiresExpert: true,
        requiresProfessional: true,
      },
    },
  ],
};

export default PerfilRoutes;
