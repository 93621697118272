import Layout from "../../Internal/Layout.vue";
import Rentabilidade from "./Rentabilidade.vue";

const RentabilidadeRoutes = {
  component: Layout,
  children: [
    {
      name: "rentabilidade",
      path: "/",
      component: Rentabilidade,
      meta: {
        requiresAuth: true,
        requiresBasic: false,
        requiresPattern: true,
        requiresSenior: true,
        requiresExpert: true,
        requiresProfessional: true,
      },
    },
  ],
};

export default RentabilidadeRoutes;
