<template>
  <div class="container-fluid base">
    <div class="top-base">
      <div class="btns">
        <div class="bnts-tipo-ativo">
          <div class="btn-novo-ativo"
               :class="{ clicado: selection === CARTEIRA }"
               @click="novoOuExistente($event, CARTEIRA)">
            <span>Carteira</span>
          </div>
          <div class="btn-ativo-existente"
               :class="{ clicado: selection === MOVIMENTACAO }"
               @click="novoOuExistente($event, MOVIMENTACAO)">
            <span>Movimentação</span>
          </div>
          <div class="btn-ativo-extras"
               :class="{ clicado: selection === COTACOES }"
               @click="novoOuExistente($event, COTACOES)">
            <span>Cotações</span>
          </div>
        </div>
      </div>
      <div class="data">
        <div>
          <span>{{ $hoje }}</span>
        </div>
        <div>
           <v-img src="../../../assets/icons/calendar_icon.svg" class="imagem_calendar"></v-img>
        </div>

      </div>
    </div>

    <div class="componentes">
        <div class="carteira" v-if="selection === CARTEIRA">
            <Carteira @showMovimentacao="showMovimentacao" />
        </div>
        <div class="movimentacao" v-if="selection === MOVIMENTACAO">
            <Movimentacao :movimentacao="movimentacao" />
        </div>
        <div class="cotacoes" v-if="selection === COTACOES">
          <PgCotacoes />
        </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import moment from "moment";
import Carteira from './Carteira.vue'
import Movimentacao from './Movimentacao.vue'
import PgCotacoes from './Cotacoes.vue';
import EventBus from "../../Bus/bus";

const CARTEIRA      = "carteira";
const MOVIMENTACAO  = "movimentacao";
const COTACOES      = "cotacoes";

export default {
  data() {
    return {
      CARTEIRA,
      MOVIMENTACAO,
      COTACOES,
      today: "",
      data: this.$route.params,
      selection: CARTEIRA,
      movimentacao: {}
    };
  },
  created() {
    EventBus.$emit("ListaDeUsuariosSelected", true);
    EventBus.$on('reloadPagesForCallActivesAgain', this.ebReload);
  },
  components:{
      Carteira,
      Movimentacao,
      PgCotacoes
  },
  methods:{
    novoOuExistente (e, item) {
      this.selection = item && item.toLowerCase() || CARTEIRA;
    },
    showMovimentacao(e){
      //aqui eu recebo um objeto contendo o valor do o obj clicado na página de carteira e um valor para poder mudar de página
      // aqui eu terei acesso ao id, com isso eu irei enviar para a página de movimentação e fazer uma requisição para esse id
      // preenchendo assim a página de movimentação
      this.selection = MOVIMENTACAO;

      //aqui é preciso emitir o ativo que foi escolhido na página de movimentação
      this.movimentacao = e;


      setTimeout(() => {
         this.movimentacao = {}
      }, 1000); //necessario zerar a movimentação... para fazer o fluxo de entrada e saida das telas

    },
    ebReload (data) {
      if (!data || (this.selection === CARTEIRA)) { return; }
      this.novoOuExistente(new Event('click'), this.selection);
    }
  },
  computed: {
    ...mapGetters(['$rentabillidade']),
    $hoje() {
      let hoje = Date.now();
      return moment(hoje).format("DD/MM/YYYY");
    },
  },
  watch:{
    // '$store.getters.$rentabillidade'(nv, ov){
    //   this.showMovimentacao(ov)
    // }
  },
  beforeMount(){
    this.showMovimentacao(this.$route.params)
  },
  beforeDestroy () {
    EventBus.$off('reloadPagesForCallActivesAgain', this.ebReload);
  },
  mounted(){

  }
};
</script>

<style scoped>
.top-base {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  flex-wrap: wrap;
}
.data {
  display: flex;
}
.data >div {
  margin:  0 .2rem;
}
.data .imagem_calendar{
    filter: invert(70%) sepia(26%) saturate(8645%) hue-rotate(195deg) brightness(105%) contrast(100%);
}
.bnts-tipo-ativo {
  margin-top: 1.5rem;
  width: 100%;
  display: flex;
}
.bnts-tipo-ativo > div {
  margin-right: 0.8rem;
  border-radius: 30px;
  padding: 0.3rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.btn-novo-ativo {
   border: 2px solid rgba(73, 73, 73, 0.5);
   color: rgba(73, 73, 73, 0.5);
}
.btn-ativo-existente {
  border: 2px solid rgba(73, 73, 73, 0.5);
  color: rgba(73, 73, 73, 0.5);
}
.btn-ativo-extras {
  border: 2px solid rgba(73, 73, 73, 0.5);
  color: rgba(73, 73, 73, 0.5);
}
.clicado{
  background: rgba(73, 73, 73, 0.5);
  border: none;
  color: white;
}
</style>