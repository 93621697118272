export default {
    format      (value) {
        const input = this.clear(value);
        const size  = input && input.length || 0;
        if (size === 0) { return ''; }
        const fmt  = size <= 11 ? 0 : 1;
        switch (fmt) {
            case 0:
                let cpf = [input.substring(0, 3)];
                if (size <= 2) { return cpf[0]; }
                cpf.push('.', input.substring(3, 6));
                if (size <= 5) { return cpf.join(''); }
                cpf.push('.', input.substring(6, 9));
                if (size <= 8) { return cpf.join(''); }
                cpf.push('-', input.substring(9));
                return cpf.join('');
            case 1:
                let cnpj = [input.substring(0, 2)];
                if (size <= 1) { return cnpj[0]; }
                cnpj.push('.', input.substring(2, 5));
                if (size <= 5) { return cnpj.join(''); }
                cnpj.push('.', input.substring(5, 8));
                if (size <= 8) { return cnpj.join(''); }
                cnpj.push('/', input.substring(8, 12));
                if (size <= 12) { return cnpj.join(''); }
                cnpj.push('-', input.substring(12));
                return cnpj.join('');
            default:
                return input;
        }
    },

    clear       (input) {
        return !input ? '' : input.replace(/[^0-9]/g, '');
    },
}