<template>
  <div class="rentabilidade-component">
    <div class="descricoes">
      <!-- <div class="title">
        <h1>Atribuição por Estratégia</h1>
        <span
          >Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis ullamco
          cillum dolor. Voluptate exercitation incididunt aliquip deserunt
          reprehenderit elit laborum.
        </span>
      </div> -->
    </div>

    <v-card flat class="grafico-wrapper">
      <div class="grafico-desempenho">
        <div class="top-grafico">
          <div class="top">
            <div class="title-grafico">
              <span>Atribuição por Estratégia</span>
            </div>
          </div>
        </div>

        <div class="grafico">
          <Bar
            :chartData="testeObj"
            :chartDataCDI="arrCDI"
            :options="options"
            :dataSetCDI="dadosCDI"
            :plugins="plugins"
            label="Carteira"
            label2="Rentabilidade mensal"
          />
        </div>

      </div>
    </v-card>
  </div>
</template>

<script>
import moment from "moment";
import ChartDataLabels from "chartjs-plugin-datalabels";
import * as Mock from "@/components/mock/mock.js";
import Bar from "./graficos/Bar-atribuicaoEstrategia.vue";
export default {
  components: {
    Bar,
  },
  props: ["calculos", "dozeMeses"],
  data() {
    return {
      testeDatas: undefined,
      testeValores: undefined,
      testeObj: {},
      arrPrincipal: [],
      dadosGraficos: [],
      arrCDI: [],
      arrIPCA: [],
      arrDOLAR: [],
      arrIBOVESPA: [],
      plugins: [ChartDataLabels],
      dadosCDI: undefined,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 15,
            right: 15,
            top: 40,
          },
        },
        legend: {
          display: false,
          labels: {
            usePointStyle: true,
          },
          position: "top",
          padding: 10,
        },
        scales: {
          xAxes: [
            {
              display: true,
              gridLines: {
                display: false,
              },

              ticks:{
              }
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
                drawBorder: false,
              },
              ticks: {
                display: false,
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            font: {
              weight: "bold",
              size: 16,
            },
          },
        },
        tooltips: {
          callbacks: {
            label: function(tooltip, context) {
              let value = context.datasets[0].data[tooltip.index];
              return value
                  && value.length
                  && value.length > 1
                  && value[1].toLocaleString(
                    "pt-BR", {
                      style: "decimal",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    }) + "%"
                  || "0,00%";
            }
          }
        },
      },
    };
  },
  methods: {
    initDataGrahph() {
      this.testeDatas = this.arrPrincipal.map((d) => d.valores.title);
      this.testeValores = this.arrPrincipal.map((d) => d.valores.value);
      let cores = this.arrPrincipal.map((d) => d.valores.color2);

      let datas = this.arrPrincipal.map((d) => [
        d.valores.valuePre,
        d.valores.value,
        d.valores.content || 0.0
      ]);

      this.dadosGraficos = [{
        label: "Carteira",
        data: datas,
        backgroundColor: cores,
        borderRadius: 10,
        barPercentage: 0.8,
        datalabels: {
          anchor: "end",
          align: "top",

          display: true,
          formatter: (val) => {
            return `${this.relpacer(val[2])}%`;
          },
        },
      }];
      this.initDefault();
    },
    relpacer(e) {
      return e.toFixed(2).toString().replace(".", ",");
    },
    initDefault() {
      this.testeObj = {
        labels: this.testeDatas,
        datasets: this.dadosGraficos,
      };
    },
    loadGraph() {
      const attr = this.calculos && this.calculos.atribuicao;
      this.arrPrincipal = [
        {
          valores : {
            title: "Juros Pré (fixo)",
            value: attr && attr.jurosPre || 0,
            color: "#29BD1A",
          }
        },
        {
          valores : {
            title: "Juros Pós (CDI)",
            value: attr && attr.jurosPosCdi || 0,
            color: "#35BAFC",
          }
        },
        {
          valores : {
            title: "Juros Pós (inflação)",
            value: attr && attr.jurosPosInflacao || 0,
            color: "#2679ED",
          }
        },
        {
          valores : {
            title: "Renda Variável",
            value: attr && attr.rendaVariavel || 0,
            color: "#FA5807",
          }
        },
        {
          valores : {
            title: "Câmbio",
            value: attr && attr.cambio || 0,
            color: "#C520FF",
          }
        },
        {
          valores : {
            title: "Livres",
            value: attr && attr.livre || 0,
            color: "#FAA90F"
          }
        },
      ]
        .filter((a) => a.valores.value !== 0)
        .sort((a, b) => {
          let x = a.valores.value;
          let y = b.valores.value;
          let rankA  = x > 0;
          let rankB  = y > 0;
          return rankA && rankB ? (y - x)
              : rankA          ? -1
              : rankB          ?  1
              :                  (x - y);
        });
      let total = 0;
      this.arrPrincipal.reduce((r, e) => {
        total += e.valores.value;
        e.valores.content = e.valores.value;
        e.valores.value += r;
        e.valores.valuePre = r;
        e.valores.color2 = e.valores.color;
        return total;
      }, 0);
      this.arrPrincipal.push({
        valores   : {
          title   : "Minha carteira",
          value   : attr && attr.total || 0,
          valuePre: 0,
          content : attr && attr.total || 0,
          color   : "#C3C3C3",
          color2  : "#C3C3C3"
        }
      });
      //console.log(this.arrPrincipal);
      this.initDataGrahph();
    }
  },
  created() {
    this.loadGraph();
  },
  watch: {
    calculos(ov, nv) {
      this.loadGraph();
    }
  }
};
</script>

<style  scoped>
/* .rentabilidade-component {
} */

.descricoes {
  margin-bottom: 1.5rem;
}
.descricoes .title {
  max-width: 50%;
}
.descricoes .title h1 {
  font-size: 25px;
  line-height: 37px;
  font-weight: 500;
  letter-spacing: 0.15px;
  color: #000000;
}
.descricoes .title span {
  font-weight: 300;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.15px;
  color: #000000;
}
.grafico-wrapper {
  border-radius: 20px;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
}

.grafico-wrapper .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 1rem 0 0 1rem;
}
.grafico {
  position: relative;
}
.title-grafico span {
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.15px;
  color: #000000;
}
.grafico .descricao-grafico {
  transform: rotate(-90deg);
  max-width: 150px;
  position: absolute;
  top: 50%;
  left: -35px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  letter-spacing: 0.15px;

  color: #000000;
}
.grafico-wrapper .btns-ativos {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin: 0.5rem 0;
}
.grafico-wrapper .btns-ativos > div {
  margin: 0 0.8rem;
}
.grafico-wrapper .btns-ativos > div span {
  padding: 0 0.2rem;
}
/* .grafico-wrapper .title-grafico {
} */

/** BARRA DO BOTTOM DO GRAFICO */
.card-bars {
  margin-top: 1.5rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: auto;
  border-radius: 20px;
  padding: 0.5rem 0;
}
.card-bars > div {
  flex: 1 1 50px;

  font-size: 10px;
  text-align: center;
}
.card-bars > div .top {
  padding: 0.4rem;
  width: 60%;
  margin: 0 auto;
}
.card-bars > div:nth-child(1) {
  /* border: 1px solid red; */
  margin-left: 20px;
}
/* .card-bars > div:nth-child(2) {
  border: 1px solid red;
} */
.j-pre .top {
  background: #f9eb9e;
}
.j-pos-cdi .top {
  background: #ecf8d3;
}
.j-pos-inflacao .top {
  background: #d0e2fb;
}
.r-variavel .top {
  background: #fbdfd0;
}
.cambio .top {
  background: #d5d8dc;
}
.livres .top {
  background: #f2a9bb;
}
/**FIM BARRA DO BOTTOM DO GRAFICO */
@media screen and (max-width: 700px) {
  .descricoes .title {
    max-width: 100% !important;
  }
}
</style>