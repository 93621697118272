<template>
    <div class="panel">
        <div class="row">
            <div class="cell-full title">{{
                investment ? investment.name : 'Sem investimento selecionado.'
            }}</div>
        </div>
        <div class="row">
            <div class="cell-full label">
                Código:
            </div>
            <div class="cell-full ctrl">
                <input type="text" v-model="newCode" />
            </div>
        </div>
        <div class="row option">
            <div class="cell-50 ctrl">
                <button type="button" class="btn-confirm"
                        @click="updateCode"
                        :disabled="notfilled">Alterar</button>
            </div>
            <div class="cell-50 ctrl">
                <button type="button" class="btn-cancel"
                        @click="dismiss">Cancelar</button>
            </div>
        </div>
    </div>
</template>

<style scoped>
.panel {
    padding: 1em;
    display: flex;
    flex-direction: column;
}
.panel .row {
    display: flex;
    flex-direction: row;
    margin: 0.25em 0;
}
.panel .row .cell-full {
    flex-basis: 100%;
    min-width: 100%;
    width: 100%;
}
.panel .row .cell-50 {
    flex-basis: 50%;
    min-width: 50%;
    width: 50%;
}
.title, .label {
    padding: 0.25em;
}
.ctrl input {
    width: 100%;
    border: 1px solid rgba(32, 32, 32, 0.5);
    border-radius: 28px;
    font-size: 1.25em;
    padding: 0.25em 1em;
}
.cell-50.ctrl {
    text-align: center;
}
.ctrl button {
    border-radius: 28px;
    padding: 1em;
    margin: 0.5em;
    font-weight: bold;
    cursor: pointer;
    width: 70%;
}
.btn-confirm {
    background-color: rgb(164, 164, 164);
    color: white;
}
.btn-confirm:disabled {
    cursor: default;
}
.btn-cancel {
    background-color: rgb(255, 128, 128);
    color: white;
}
@media screen and (max-width: 750px) {
    .panel .row.option {
        flex-direction: column;
    }
    .panel .row.option .cell-50 {
        flex-basis: 100%;
        min-width: 100%;
        width: 100%;
    }
}
</style>

<script lang="js">
import ApiService from '../../../../services/ApiService';

export default {
    props : {
        args: { type: Object }
    },
    data () {
        return {
            apiService: undefined,
            loading   : false,
            investment: undefined,
            newCode   : ''
        }
    },
    methods : {
        updateCode () {
            if (this.investment.code === this.newCode) {
                return;
            }
            this.loading = true;
            let model = {
                itemId      : this.investment.itemId,
                investmentId: this.investment.investmentId,
                code        : this.newCode
            };
            return this.apiService.updateInvestmentCode(model)
                .then((data) => {
                    try {
                        if (!data.success) {
                            this.$toast.error(data.message);
                            this.$emit('onResult', false)
                            return;
                        }
                        this.$toast.info(data.message);
                        this.$emit('onResult', true);
                        this.investment.code = this.newCode;
                    } finally {
                        this.newCode    = '';
                        this.investment = undefined;
                    }
                });
        },
        dismiss   () {
            this.$emit('onResult', false);
        }
    },
    computed : {
        notfilled () {
            return !this.newCode || !this.investment || (this.newCode === this.investment.code);
        }
    },
    watch : {
        args (nv) {
            this.newCode = nv ? nv.code : '';
        }
    },
    mounted () {
        this.apiService = new ApiService();
        this.investment = this.args;
        this.newCode    = this.investment ? this.investment.code : '';
    },
    beforeDestroy () {
        this.newCode    = '';
    }
}
</script>