<template>
  <v-container fluid class="perfil">
    <div class="data">
      <div>
        <span>{{ $hoje }}</span>
      </div>
      <div>
        <v-img
          src="../../../assets/icons/calendar_icon.svg"
          class="imagem_calendar"
        ></v-img>
      </div>
    </div>
    <router-view />
  </v-container>
</template>

<script>
import moment from "moment";
export default {
  computed: {
    $hoje() {
      let hoje = Date.now();
      return moment(hoje).format("DD/MM/YYYY");
    },
  },
};
</script>

<style scoped>
.perfil {
  padding: 1.5rem;
}
.data {
  display: flex;
  align-items: center;
  justify-content: flex-end;
   padding: 1rem;
}
.data >div {
  margin:  0 .2rem;
}
.data .imagem_calendar{
    filter: invert(70%) sepia(26%) saturate(8645%) hue-rotate(195deg) brightness(105%) contrast(100%);
}
</style>