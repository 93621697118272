<template>
  <div>
    <div class="title">
      <span>Movimentar  <span style="color: #b5e546">Ativo</span></span>
    </div>

    <div class="bnts-tipo-ativo">
      <div
        class="btn-novo-ativo"
        :class="{ clicado: state === tipos.NOVO }"
        @click="changeState(tipos.NOVO)">
        <span>Incluir Ativo</span>
      </div>
      <div
        class="btn-ativo-existente"
        :class="{ clicado: state === tipos.EXISTENTE }"
        @click="changeState(tipos.EXISTENTE)" >
        <span>Retirada</span>
      </div>
      <div class="btn-resgate-ativo"
        :class="{ clicado: state === tipos.RESGATE }"
        @click="changeState(tipos.RESGATE)">
        <span>Resgate Total</span>
      </div>
    </div>

    <div class="btn-close" @click="$emit('closeModal')">
      <v-img src="@/assets/icons/clear_icon.svg" />
    </div>

    <div class="tipo-ativo">
      <v-row class="flex-column mt-7" align="start">
        <v-col class="d-flex flex-column align-left py-0">
          <span class="text-filelds">Tipo de ativo </span>
        </v-col>
        <v-col>
          <v-select
            @change="$emit('onChange', $event)"
            return-object
            :items="includeItems"
            item-text="name"
            :menu-props="{ bottom: true, offsetY: true }"
            placeholder="Selecione"
            color="secondary"
            required
            outlined
            rounded
            dense
            class="input"
            attach=""
          ></v-select>
        </v-col>
      </v-row>
    </div>

    <keep-alive>
      <v-form ref="form">
        <component
          :is="compra"
          v-bind:tipoAtivo="currentPropertie"
          v-bind:botaoCompraClicado="botaoCompraClicado"
          v-bind:ativoNovoOuExistente="ativoNovoOuExistente()"
          v-bind:closing="closing()"
          v-bind:state="state"
          :respGetAtiTipUserId_prop=respGetAtiTipUserId
          @save="save"
          @edit:working="updateWorking"
          @edit:updating="updateUpdating"
        ></component>
      </v-form>
    </keep-alive>

    <div class="btn-venda" v-if="!objetoEhVazio(currentPropertie)">
      <v-btn
        rounded
        class="btn"
        :disabled="working || updating"
        :loading="botaoCompraClicado"
        @click="executarCompra"
      >
        Incluir
      </v-btn>
    <!--<v-btn  v-if="!ativoNovoOuExistente"
        rounded
        class="btnExluir"
        :loading="botaoCompraClicado"
        @click="deleteAtivo"
      >
       Excluir
      </v-btn>-->
    </div>
  </div>
</template>

<style scoped>
.btn-venda {
  position: fixed;
  width: 100%;
  max-width: 497px;
  right: 0;
  bottom: 0;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  -webkit-box-shadow: 3px 2px 17px -1px rgba(0, 0, 0, 0.3);
  box-shadow: 3px 2px 17px -1px rgba(0, 0, 0, 0.3);
  background: white;
}
.btnExluir {
  border-radius: 20px;
  padding: 0.5rem 1rem;
  color: white;
  background: #f25a5a;
  cursor: pointer;
}
.title span {
  font-weight: 300;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.15px;
}
.bnts-tipo-ativo {
  margin-top: 1.5rem;
  width: 100%;
  display: flex;
}
.bnts-tipo-ativo > div {
  margin-right: 0.8rem;
  border-radius: 30px;
  padding: 0.3rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.btn-novo-ativo,
.btn-ativo-existente,
.btn-resgate-ativo {
  border: 2px solid rgba(73, 73, 73, 0.5);
  color: rgba(73, 73, 73, 0.5);
}

.btn-close {
  cursor: pointer;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 25px;
  top: 40px;
}
.btn-close img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.btn-comprar {
  position: fixed;
  width: 100%;
  max-width: 497px;
  right: 0;
  bottom: 0;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  -webkit-box-shadow: 3px 2px 17px -1px rgba(0, 0, 0, 0.3);
  box-shadow: 3px 2px 17px -1px rgba(0, 0, 0, 0.3);
  background: white;
}
.btn-comprar span {
  border-radius: 20px;
  padding: 0.5rem 1rem;
  color: white;
  background: rgb(141, 211, 37);
  cursor: pointer;
}
.btnExluir {
  padding: 0.5rem 1rem;
  color: white;
  background: #f25a5a !important;
  cursor: pointer;
}
.btn{
  padding: 0.5rem 1rem;
  color: white;
  background: rgb(141, 211, 37) !important;
  cursor: pointer;
}
.clicado {
  background: rgba(73, 73, 73, 0.5);
  border: none;
  color: white;
}

.input .v-select .dropdown-toggle {
  border: 1px solid red;
}
.input .v-select-select option {
  border: 1px solid red !important;
}
</style>

<script lang="js">
import CadastrarCompra from "../components/CadastrarCompra.vue";
export default {
  props: {
    showModal: { type: Object },
    currentPropertie: { type: Object },
    items: { type: Array },
    botaoCompraClicado: { type: Boolean },
    //ativos_prop: { type: Array },
    respGetAtiTipUserId_prop: { type: Array },
  },
  components: {
    CadastrarCompra,
  },
  data() {
    return {
      compra: "CadastrarCompra",
      dadosDoAtivo: {},
      respGetAtiTipUserId: [],
      state: "novo",
      tipos: {
        NOVO: "novo",
        EXISTENTE: "existente",
        RESGATE: "resgate"
      },
      working: false,
      updating: false
    };
  },
  methods: {
    updateWorking(v) {
      this.working = v;
    },
    updateUpdating(v) {
      this.updating = v;
    },
    objetoEhVazio(e) {
      return Object.keys(e).length === 0;
    },

    changeState(toState) {
      this.$emit("opCtrlState", toState);
      this.state = toState;
    },

    ativoNovoOuExistente () {
      return this.state === this.tipos.NOVO;
    },

    closing () {
      return this.state === this.tipos.RESGATE;
    },

    executarCompra() {
      //if (this.$refs.form.validate()) {
        switch (this.state) {
          case this.tipos.RESGATE:
            if (!this.dadosDoAtivo.dado) {
              this.$toast.error('Falha na leitura dos dados do ativo.');
              return;
            }
            if (!this.dadosDoAtivo.dado.$netValue$) {
              alert('Ativo com saldo zerado!');
              return;
            }
            this.dadosDoAtivo.dado.TpMovimento = 8;
            break;
        }
        this.$emit("execCompraAtivo", this.dadosDoAtivo);
      //}
    },

    save(dadosDoAtivo) {
      this.dadosDoAtivo = dadosDoAtivo;
    }
  },
  watch: {
    respGetAtiTipUserId_prop(nv) {

      this.respGetAtiTipUserId=[nv[0]];

    }
  },
  computed: {
    includeItems () {
      return this.items;
      // return this.state === this.tipos.NOVO ?
      //   this.items.filter((e) =>
      //     !(e.header === 'Previdência' || (e.id === 8 && e.tipo === 'FIPREV'))) :
      //   this.items;
    }
  }
};
</script>