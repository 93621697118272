<template>
    <v-row class="linha-2" >
      <v-col sm="12" md="2" xs="12" class="coluna">
        <v-col sm="12" md="10" xs="12" class="coluna">
            <span> Estretégia</span>
        </v-col>  
        <v-col sm="12" md="2" xs="12" class="coluna">
            <span> Part.</span>
        </v-col>          
      </v-col>
      <v-col sm="12" md="1" xs="12" class="coluna">
        <div class="descricao">
          <span> Saldo Bruto </span>
        </div>
      </v-col>
      <v-col sm="12" md="3" xs="12" class="coluna coluna-composta">
        <div class="descricao">
          <span> Resultado {{ timeLabel }} </span>
        </div>
        <div class="colunas">
          <v-row>
            <v-col sm="12" md="3" xs="12">
              <div class="mes"><span>{{monthLabel}}</span></div>
            </v-col>

            <v-col sm="12" md="3" xs="12">
              <div><span>% {{ indice }}</span></div>
            </v-col>

            <v-col sm="12" md="6" xs="12">
              <div><span>R$</span></div>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col sm="12" md="3" xs="12" class="coluna coluna-composta">
        <div class="colunas">
          <v-row>
            <v-col sm="12" md="3" xs="12">
              <div class="meses-12"><span>12 meses</span></div>
            </v-col>

            <v-col sm="12" md="3" xs="12">
              <div><span>% {{ indice }}</span></div>
            </v-col>

            <v-col sm="12" md="6" xs="12">
              <div><span>R$</span></div>
            </v-col>

          </v-row>
        </div>
      </v-col>
      <v-col sm="12" md="3" xs="12" class="coluna coluna-composta">
        <div class="colunas">
          <v-row>
            <v-col sm="12" md="3" xs="12">
              <div class="inicio"><span>Desde o início</span></div>
            </v-col>

            <v-col sm="12" md="3" xs="12">
              <div><span>% {{ indice }}</span></div>
            </v-col>

            <v-col sm="12" md="6" xs="12">
              <div><span>R$</span></div>
            </v-col>
          </v-row>
        </div>
      </v-col>
  </v-row>
</template>

<script>
    export default {
        props: {
          monthLabel: String,
          timeLabel: String,
          indice: { type: String }
}
    }
</script>

<style  scoped>
.linha-2 {
  margin-top: 1rem;
}
.linha-2 .coluna {
  margin: 0 !important;
  padding: 0.2rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.linha-2 .coluna > div {
  background: white;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  padding: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.linha-2 .coluna-composta {
  display: flex;
  flex-direction: column;
}
.coluna-composta .descricao {
  border-radius: 10px 10px 0 0 !important;
}
.coluna-composta .descricao span {
  border-bottom: 1px solid #a4a4a4;
  width: 98%;
}
.coluna-composta .colunas {
  border-radius: 0 0 10px 10px !important;
}
.colunas {
  font-weight: bold;
}
.colunas .mes {
  color: #a9d08e;
}
.colunas .meses-12 {
  color: #f3a03c;
}
.colunas .inicio {
  color: var(--principal-azul);
}
</style>