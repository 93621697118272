import Layout from "../../Internal/Layout.vue";


const DashboardRoutes = {
  component: Layout,
  children: [
    {
      name: "dashboard",
      path: "/",
      component: () => import('./Dashboard.vue'),
      meta: {
        requiresAuth: true,
       // requiresAdmin: true,
      },
    },
  ],
};

export default DashboardRoutes;
