<template>
  <div class="movimentacao container-fluid">
    <ConsultarMovimentacao
      :userList="userList"
      :ativoId="ativoId"
      @onChangeAtivo="onChangeAtivo"
    />

    <div class="container-fluid infos-movimentacao" v-if="ativoSelected">
      <CaracteristicasProduto :ativo_prop="currentAtivo" />
      <MovimentacaoProduto :headers="headers"  :headers2="headers2" :desserts="desserts" :ativo_prop="currentAtivoMovimento" />

      <div class="grafico-desempenho">
        <div class="top-grafico">
          <div class="top">
            <div class="title">
              <span>Gráfico de desempenho</span>
            </div>
            <div class="btns-ativos">
              <div class="btn-cdi">
                <v-switch checked @change="changeSwitch('CDI')"
                v-model="switchStateCDI"
                label="CDI"/>
              </div>

              <div class="btn-ipca">
                <v-switch @change="changeSwitch('IPCA')"
                v-model="switchStateIPCA"
                label="IPCA"
                />
              </div>

              <div class="btn-dolar">
                <v-switch @change="changeSwitch('DOLAR')"
                v-model="switchStateDOLAR"
                label="DÓLAR"/>
              </div>

              <div class="btn-ibovespa">
                <v-switch @change="changeSwitch('IBOVESPA')"
                v-model="switchStateIBOVESPA"
                label="IBOVESPA"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="flex-between">
          <div class="grafico" style="overflow-x: hidden;">
            <gBar
              :chartData="arrTeste"
              :chartData2="arrTeste2"
              :options="options"
              label="Indice"
              label2="Ativo"
            />
          </div>
          <div class="grafico" style=" overflow-x: hidden; ">
            <gLine
              :chartData="arrTeste3"
              :chartData2="arrTeste4"
              :options="options"
              label="Ativo Acumulado"
              label2="Índice Acumulado"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import * as Mock from "@/components/mock/mock.js";
import Bar from "@/components/graph/BarAndLine.vue";
import gBar from "@/components/graph/gBar.vue";
import gLine from "@/components/graph/gLine.vue";
// import SwitchButton from "./components/SwitchButton.vue";
import ConsultarMovimentacao from "./InternalTemplates/ConsultarMovimentacao.vue";
import CaracteristicasProduto from "./InternalTemplates/CaracteristicasProduto.vue";
import MovimentacaoProduto from "./InternalTemplates/MovimentacaoProduto.vue";
import ApiService from "../../../services/ApiService";
import CalculadorAtivo from "../../../services/Calculador";
import dateHelper from "../../../Utils/DateHelper";
import currencyHelper from "../../../Utils/CurrencyHelper";
import percentageHelper from "../../../Utils/PercentageHelper";
import EventBus from "../../Bus/bus";

export default {
  data() {
    return {
      api: new ApiService(),
      calculador: new CalculadorAtivo().setDefaultLoaders(),
      desempenho:{data:null,valor:0},
      valcalculado:0,
      dessertsCalc:[],
      switchStateCDI:true,
      switchStateIPCA:false,
      switchStateDOLAR:false,
      switchStateIBOVESPA:false,
      user: {},
      switch1: undefined,
      userList: [],
      ativoSelected: false,
      arrTeste: [],
      arrTeste2: [],
      arrTeste3: [],
      arrTeste4: [],
      options: {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: false,
        },
        elements: {
          line: {
            fill: false,
            tension: 0.4,
          },
        },
        legend: {
          labels: {
            usePointStyle: true,
          },
          position: "top",
          padding: 10,
        },
        scales: {
          xAxes: [
            {
              ticks: { beginAtZero: true, },
              stacked: true,
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              id: 'leftAxis',
              gridLines: {
                display: true,
                drawBorder: false,
              },
              ticks: {
                beginAtZero: true,
                min: 0,
                max: 100,
                callback: function (value) {
                  return value + "%";
                },
              },
            },
          ],
        },
      },
      headers: [],
      headers2: [],
      desserts: [],
      currentAtivo: {},
      currentAtivoMovimento: [],
      ativoId: 0
    };
  },

  components: {
    gBar,
    gLine,
    // SwitchButton,
    CaracteristicasProduto,
    MovimentacaoProduto,
    ConsultarMovimentacao,
  },

  methods: {
    getAtivos() {
      this.loading = true;

      let model = {
        userId: this.$store.getters.$tokenUserSelected,
        byStrategy: false,
        brief: true
      };

      this.api
        .post("userToAtivo/user", model)
        .then((res) => {
          this.userList = res.content.all.items;
          this.ativoId = this.postedId;
          this.loading = false;
        })
        .catch((r) => {
          this.loading = false;
        });
    },

    async getAtivoDetalhado(idAtivo) {
      if (idAtivo) {
        this.loading = true;

        let model = {
           userId: this.$store.getters.$tokenUserSelected,
          ativoId: idAtivo,
        };
      this.arrTeste= [];
      this.arrTeste2=[];
      this.arrTeste3=[];
      this.arrTeste4=[];
      this.desserts=[];
      this.dessertsCalc=[];
      this.currentAtivoMovimento=[];

        await this.api
          .post(`userToAtivo/detalhed`, model)
          .then((res) => {
                this.currentAtivo = res.content;
                this.calcAtivo();
            })
          .catch((r) => {
            this.loading = false;
          })

      }
    },
    calcAtivo() {
      this.switchStateCDI=true;
      this.switchStateIPCA=false;
      this.switchStateDOLAR=false;
      this.switchStateIBOVESPA=false;
      let calc = this.calculador.calcular(this.currentAtivo, false);
      if ((this.currentAtivo.idTProduto  == 1) || (this.currentAtivo.idTProduto  == 4))  {
        this.headers= [
        { text: "Data", align: "center", value: "name", sortable : false},
        { text: "Saldo bruto ($)", value: "calories" , align: "right", sortable : false},
        { text: "Ganho ($)", value: "fat" , align: "right", sortable : false},
        { text: "Ganho acumulado ($)", value: "carbs" , align: "right", sortable : false},
        { text: "Rent. (%)", value: "protein" , align: "right", sortable : false},
        { text: "Rent. Acumulada (%)", value: "iron" , align: "right", sortable : false},
        { text: "IOF ($)", value: "iof" , align: "right", sortable : false},
        { text: "IR ($)", value: "ir" , align: "right", sortable : false},
        { text: "Saldo Líquido ($)", value: "saldoLiquido" , align: "right", sortable : false},
        ];
        this.headers2 = [
        { text: "Data", align: "center", value: "investmentDate", sortable : true},
        { text: "Histórico", value: "tpMovimentoDesc" , align: "center", sortable : false},
        { text: "Valor total", value: "investedValue" , align: "right", sortable : false},
      ];
      }
      else {
        this.headers= [
        { text: "Data", align: "center", value: "name", sortable : false},
        { text: "Saldo bruto ($)", value: "calories" , align: "right", sortable : false},
        { text: "Ganho ($)", value: "fat" , align: "right", sortable : false},
        { text: "Ganho acumulado ($)", value: "carbs" , align: "right", sortable : false},
        { text: "Rent. (%)", value: "protein" , align: "right", sortable : false},
        { text: "Rent. Acumulada (%)", value: "iron" , align: "right", sortable : false},
        { text: "IOF ($)", value: "iof" , align: "right", sortable : false},
        { text: "IR ($)", value: "ir" , align: "right", sortable : false},
        { text: "Saldo Líquido ($)", value: "saldoLiquido" , align: "right", sortable : false},
        { text: "Cotas", value: "quotas" , align: "right", sortable : false},
        ];
        this.headers2 = [
        { text: "Data", align: "center", value: "investmentDate", sortable : true},
        { text: "Histórico", value: "tpMovimentoDesc" , align: "center", sortable : false},
        { text: "Quantidade", value: "shareQuantity"  , align: "right", sortable : false},
        { text: "Valor unitário", value: "shareValue" , align: "right", sortable : false},
        { text: "Valor total", value: "investedValue" , align: "right", sortable : false},
        ];
      }
      this.desserts     = calc.desserts;
      this.dessertsCalc = calc.dessertsCalc;
      this.currentAtivoMovimento = calc.currentAtivoMovimento;
      this.getGraficoCDI('CDI');
    },
     getiof(count){
          return 0
     },


     getIR(count){
          return 0
     },
    getCotacoesGrafico(tipo,start,stop,idTProduto){
        var  i=0;
        this.arrTeste4=[{
              data: "", valors: {date: "", valor: 0, percent: 0, id: 1}
            }];
        var arrt= this.api.getCotacoesMes(tipo,start,stop,idTProduto);
        var c2=0;
        var p2=0;

        //let first = arrt.length >= 12 ? arrt.length-12 : 0;
        arrt.slice().map(g=>{
        let data = moment(g.data).format("MMM/YYYY");
        let d2=moment(g.data).format("DD/MM/YYYY");
        let c=g.valor;
        let p=(g.percent);
        if (i == 0){
            c2=g.valor;
            p2=g.percent;
        }
        else
        {
            c2=c;
            p2=(((p2/100)+1)*((g.percent/100)+1) - 1)*100;
        }
        i+=1;
        this.arrTeste2.push({ data, valors:{date:d2 , valor:c , percent:p, id:200+i} });
        this.arrTeste4.push({ data, valors:{date:d2 , valor:c2 , percent:p2, id:300+i} });
        });
        this.getOptionsGrafico();
    },

       getGraficoCDI(tipo){

      this.arrTeste=[];
      this.arrTeste2=[];
      this.arrTeste4=[];
      this.arrTeste3=[];
      //let  i=0;
      let _vencimento;
      let p1=0;

      this.arrTeste3=[{
              data: "", valors: {date: "", valor: 0, percent: 0, id: 1}
            }];
      //let first = this.dessertsCalc.length >= 12 ? this.dessertsCalc.length-12 : 0;
      this.dessertsCalc.slice().map((g, i)=>{
        let  dessert = this.desserts.filter(a => a.name === g.name);
        if (dessert.length)
        {
          let data = moment(g.date).format("MMM/YYYY");
          _vencimento = g.date;
          let d2=moment(g.date).format("DD/MM/YYYY");
          let c=g.fat;
          let c1=g.carbs;
          let p= g.protein;
          p1= (g.iron === 0) ? 0 : (g.iron-1)* 100;
          this.arrTeste.push({ data, valors:{date:d2 , valor:c , percent:p, id:i} });
          this.arrTeste3.push({ data, valors:{date:d2 , valor:c1 , percent:p1, id:100+i} });
          i+=1;
        }
      })
      this.getCotacoesGrafico(tipo,this.currentAtivo.dataInvestimento, _vencimento,this.currentAtivo.idTProduto);
    },


    getOptionsGrafico(){

      var fat=0;
      var fat2=0;
      this.options= {
        responsive: true,
        barValueSpacing: 20,
        maintainAspectRatio: false,
        title: {
          display: false,
        },
        elements: {
          line: {
            fill: false,
            tension: fat,
          },
        },
        legend: {
          labels: {
            usePointStyle: true,
          },
          position: "top",
          padding: fat,
        },
        scales: {
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              //stacked: true,
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              id: 'leftAxis',
              gridLines: {
                display: true,
                drawBorder: false,
              },
              ticks: {
                beginAtZero: true,
                callback: function (value) {
                  return value + "%";
                },
              },
            },
          ],
        },
        tooltips: {
            callbacks: {
                label: (val, data) => {
                    let title = data.datasets[val.datasetIndex].label || '';
                    return `${title}: ${percentageHelper.formatPercentage(val.yLabel / 100)}`;
                }
            }
        }
      }

    },
    changeSwitch(e) {

      this.switchStateCDI=e=='CDI';
      this.switchStateIPCA=e=='IPCA';
      this.switchStateDOLAR=e=='DOLAR';
      this.switchStateIBOVESPA=e=='IBOVESPA';
      this.getGraficoCDI(e);
      this.switch1 = e;
      return e;
    },
    async onChangeAtivo(e) {
      await this.getAtivoDetalhado(e.ativoId);
      if (e) {
        this.ativoSelected = true;
      } else {
        this.ativoSelected = false;
      }
    },
    objetoEhVazio(e) {
      return Object.entries(e).length === 0;
    },
    teste() {
      if (this.objetoEhVazio(this.movimentacao)) {
        //aqui o objeto chega vazio, logo não tem informação
      } else {
        this.ativoSelected = true;
      }
    },
    calculair(dias) {
       if (dias <= 180) {return 0.225}
       else  if (dias <= 360)  {return 0.20}
       else if  (dias <= 720)  {return 0.175}
       else {return 0.15};
    },
    currentDate() {
      const current = new Date();
      const date = current.toISOString();
      //const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
      return date;
    },

    calculaiof(dias) {
       if (dias == 1) {return 0.96}
       else if (dias == 2)  {return 0.93}
       else if (dias == 3)  {return 0.90}
       else if (dias == 4)  {return 0.86}
       else if (dias == 5)  {return 0.83}
       else if (dias == 6)  {return 0.80}
       else if (dias == 7)  {return 0.76}
       else if (dias == 8)  {return 0.73}
       else if (dias == 9)  {return 0.70}
       else if (dias == 10)  {return 0.66}
       else if (dias == 11)  {return 0.63}
       else if (dias == 12)  {return 0.60}
       else if (dias == 13)  {return 0.56}
       else if (dias == 14)  {return 0.53}
       else if (dias == 15)  {return 0.50}
       else if (dias == 16)  {return 0.46}
       else if (dias == 17)  {return 0.43}
       else if (dias == 18)  {return 0.40}
       else if (dias == 19)  {return 0.36}
       else if (dias == 20)  {return 0.33}
       else if (dias == 21)  {return 0.30}
       else if (dias == 22)  {return 0.26}
       else if (dias == 23)  {return 0.23}
       else if (dias == 24)  {return 0.20}
       else if (dias == 25)  {return 0.16}
       else if (dias == 26)  {return 0.13}
       else if (dias == 27)  {return 0.10}
       else if (dias == 28)  {return 0.06}
       else if (dias == 29)  {return 0.03}
       else {return 0};
    },
    $numberToLocale(val) {
      let formato = { minimumFractionDigits: 2, maximumFractionDigits: 2, currency: "BRL" };
      return val.toLocaleString("pt-BR", formato);
    },
    $valorInPercent(val) {
      let formato = { minimumFractionDigits: 2, maximumFractionDigits: 2, percent: "BRL" };
      return val.toLocaleString("pt-BR", formato) + " %";
    },
    $valorInPercentSemPe(val) {
      let formato = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
      return val.toLocaleString( formato) ;
    },
    $DateFormatMonth(d) {
      moment.locale('pt-br');
      return moment(d).format("MMM/YYYY");
    },
    $DateFormatComplete(d) {
      moment.locale('pt-br');
      return moment(d).format("DD/MM/YYYY");
    },
    ebReload (data) {
      if (!data) { return; }
      this.user = JSON.parse(sessionStorage.getItem("user"));
      this.teste();
      this.getAtivos();
    }
  },
  created() {
    this.user = JSON.parse(sessionStorage.getItem("user"));
    this.postedId = this.movimentacao
      && this.movimentacao.item
      && this.movimentacao.item.ativoId
      || undefined;

    this.teste();
    this.getAtivos();
    EventBus.$on('reloadPagesForCallActivesAgain', this.ebReload);
  },
  beforeDestroy () {
    EventBus.$off('reloadPagesForCallActivesAgain', this.ebReload);
  },
  destroyed() {
    this.ativoSelected = false;
  },

  props: {
    movimentacao: { type: Object },
  },
};
</script>

<style  scoped>
.grafico {
  border: 0px solid blue;
  /* width: 50% !important;   max-width: 50% ; min-width: 50%;
  height: 100% !important;    max-height: 100% ; min-height: 100%; */
  flex: 1 0 40%;

}
.infos-movimentacao > div {
  margin: 1rem 0;
}
.title {
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.15px;
  color: #494949;
  margin: 1rem 0 0 0;
  /* border: 1px solid red; */
}
.card-caracteristica-produto {
  border-radius: 20px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
}
.title-caracteristica {
  margin-bottom: 1.5rem;
}
.title-caracteristica span {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.15px;
  color: #494949;
}
.caracteristicas {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.caracteristicas > div {
  margin: 0.5rem 0;
  display: flex;
  flex-direction: column;
}
.title-item {
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: #494949;
}
.descricao-item {
  font-weight: 300;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.15px;
  color: #494949;
}
.movimentacao {
  padding: 1rem;
}
.card-movimentacao {
  border-radius: 20px;
  background: white;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  width: 100%;
}
.card-movimentacao > div {
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0;
  flex: 1 1 200px;
}
.title-movimentacao {
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: #494949;
}
.descricao-movimentacao {
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.15px;
  color: #494949;
}
.select {
  margin-top: 0.5rem;
  max-width: 50vw;
}
.v-data-table {
  border-radius: 20px;
  padding: 1rem;
  box-shadow: none !important;
}

.tabela-movimentacao {
  border-radius: 20px;
  background: white;
}
.v-data-table::v-deep .v-data-table__wrapper::-webkit-scrollbar {
  width: 8px !important;
  border-radius: 20px !important;
}
.v-data-table::v-deep .v-data-table__wrapper::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
  border-radius: 20px;
}
.v-data-table::v-deep .v-data-table__wrapper::-webkit-scrollbar-thumb {
  background: rgb(197, 196, 196) !important;
  border-radius: 20px;
}
.tabela-movimentacao .title {
  padding: 1rem 0 0 1rem;
}
.grafico-desempenho {
  background: white;
  width: 100%;

  height: 75vh;
  border-radius: 20px;
}
.btns-ativos {
  display: flex;
  flex-wrap: wrap;
}
.btns-ativos > div {
  margin: 0 0.3rem;
}
.btns-ativos > div span {
  padding: 0 1rem;
}
/* .top-grafico {
} */
.top-grafico > div {
  padding: 1rem;
}
.top-grafico .top {
  display: flex;
  align-items: center;
  justify-content: space-between;

  flex-wrap: wrap;
}
/* .top-grafico .top .title {
} */
.top-grafico .bottom {
  display: flex;
  flex-wrap: wrap;
}
.top-grafico .bottom > div {
  margin: 0.2rem;
  display: flex;
  align-items: center;
}
.rent-mensal .dot {
  background: var(--warning);
}
.rent-acc .dot {
  background: var(--principal-azul);
}
.dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin: 0 0.2rem 0 0;
}
/* .btns-ativos {
} */

.flex-between {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

@media screen and (max-width: 650px) {
  .select {
    max-width: 100vw !important;
  }
  .btns-ativos > div {
    flex: 1 1 150px;
  }
  .flex-between {
    display: block;
  }
  .grafico {
    clear: both;
    width: 100%;
  }
}
@media screen and (max-width: 1000px) {
  .caracteristicas > div {
    flex: 1 1 300px;
  }
  .flex-between {
    display: block;
  }
  .grafico {
    clear: both;
    width: 100%;
  }
}
</style>