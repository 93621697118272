<template>
  <div class="fundo-accordion">
    <Accordion :cor="'none'" class="accordion">
      <template v-slot:header>
        <Header :switchView="switchVisualizacao"
                :item="dessertsCdbRdb"
                :cor="'#2679ED'"
                :evolucao="evolucao.produto.cdbRdb"
                :saldototal="evolucao.total.saldo"
                :bound="bound" />
        <!-- <LinhaCdbRdb v-if="!switchVisualizacao" />
        <LinhaCdbRdbEvolucao v-if="switchVisualizacao" /> -->
      </template>

      <template v-slot:body>
        <div class="text-center pa-4" v-if="dessertsCdbRdb.items.length == 0">
          Nenhum investimento encontrado para este tipo de produto
        </div>
        <div v-if="telaMobile">
          <div v-if="!switchVisualizacao">
            <card1
              v-for="ativo in dessertsCdbRdb.items"
              :key="ativo.title"
              :dataRecieved="ativo"
              v-show="showItem(ativo)"
              @cardClicked="goCarteira(ativo)"
              :bound="bound" />
          </div>
          <div v-else>
            <card2
              v-for="ativo in dessertsCdbRdb.items"
              :key="ativo.title"
              :dataRecieved="ativo"
              @cardClicked="goCarteira(ativo)"
              v-show="showItem(ativo)"
              :monthLabel="monthLabel"
              :bound="bound" />
          </div>
        </div>
        <div v-if="!telaMobile">
          <div
            class="row-expansion"
            v-for="ativo in dessertsCdbRdb.items"
            :key="ativo.title"
            @click="goCarteira(ativo)"
          >
            <Body :switchView="switchVisualizacao"
                  :ativo="ativo"
                  v-show="showItem(ativo)"
                  :bound="bound" />
          </div>
        </div>
      </template>
    </Accordion>

    <Accordion :cor="'none'" class="accordion">
      <template v-slot:header>
        <Header :switchView="switchVisualizacao"
                :item="dessertsLciLca"
                :cor="'#2679ED'"
                :evolucao="evolucao.produto.lciLca"
                :saldototal="evolucao.total.saldo"
                :bound="bound" />

        <!-- <LinhaLciLca v-if="!switchVisualizacao" /> -->
        <!-- <LinhaLciLcaEvolucao v-if="switchVisualizacao" /> -->
      </template>

      <template v-slot:body>
        <div class="text-center pa-4" v-if="dessertsLciLca.items.length == 0">
          Nenhum investimento encontrado para este tipo de produto
        </div>
        <div v-if="telaMobile">
          <div v-if="!switchVisualizacao">
            <card1
              v-for="ativo in dessertsLciLca.items"
              :key="ativo.title"
              :dataRecieved="ativo"
              v-show="showItem(ativo)"
              @cardClicked="goCarteira(ativo)"
              :bound="bound" />
          </div>
          <div v-else>
            <card2
              v-for="ativo in dessertsLciLca.items"
              :key="ativo.title"
              :dataRecieved="ativo"
              v-show="showItem(ativo)"
              @cardClicked="goCarteira(ativo)"
              :bound="bound" />
          </div>
        </div>
        <div v-if="!telaMobile">
          <div
            class="row-expansion"
            v-for="ativo in dessertsLciLca.items"
            :key="ativo.title"
            @click="goCarteira(ativo)"
          >
            <Body :switchView="switchVisualizacao"
                  :ativo="ativo"
                  v-show="showItem(ativo)"
                  :bound="bound"/>
          </div>
        </div>
      </template>
    </Accordion>

    <Accordion :cor="'none'" class="accordion">
      <template v-slot:header>
        <Header :switchView="switchVisualizacao"
                :item="dessertsCriCra"
                :cor="'#29BD1A'"
                :evolucao="evolucao.produto.criCra"
                :saldototal="evolucao.total.saldo"
                :bound="bound" />
        <!-- <LinhaCriCra v-if="!switchVisualizacao" />
        <LinhaCriCraEvolucao v-if="switchVisualizacao" /> -->
      </template>

      <template v-slot:body>
        <div class="text-center pa-4" v-if="dessertsCriCra.items.length == 0">
          Nenhum investimento encontrado para este tipo de produto
        </div>

        <div v-if="telaMobile">
          <div v-if="!switchVisualizacao">
            <card1
              v-for="ativo in dessertsCriCra.items"
              :key="ativo.title"
              :dataRecieved="ativo"
              v-show="showItem(ativo)"
              @cardClicked="goCarteira(ativo)"
              :bound="bound" />
          </div>

          <div v-else>
            <card2
              v-for="ativo in dessertsCriCra.items"
              :key="ativo.title"
              :dataRecieved="ativo"
              v-show="showItem(ativo)"
              @cardClicked="goCarteira(ativo)"
              :bound="bound" />
          </div>
        </div>
        <div v-if="!telaMobile">
          <div
            class="row-expansion"
            v-for="ativo in dessertsCriCra.items"
            :key="ativo.title"
            @click="goCarteira(ativo)"
          >
            <Body :switchView="switchVisualizacao"
                  :ativo="ativo"
                  v-show="showItem(ativo)"
                  :bound="bound"/>
          </div>
        </div>
      </template>
    </Accordion>

    <Accordion :cor="'none'" class="accordion">
      <template v-slot:header>
        <Header :switchView="switchVisualizacao"
                :item="dessertsDebentures"
                :cor="'#29BD1A'"
                :evolucao="evolucao.produto.debentures"
                :saldototal="evolucao.total.saldo"
                :bound="bound" />
        <!-- <LinhaDebentures v-if="!switchVisualizacao" />
        <LinhaDebenturesEvolucao v-if="switchVisualizacao" /> -->
      </template>

      <template v-slot:body>
        <div
          class="text-center pa-4"
          v-if="dessertsDebentures.items.length == 0"
        >
          Nenhum investimento encontrado para este tipo de produto
        </div>
        <div v-if="telaMobile">
          <div v-if="!switchVisualizacao">
            <card1
              v-for="ativo in dessertsDebentures.items"
              :key="ativo.title"
              :dataRecieved="ativo"
              v-show="showItem(ativo)"
              @cardClicked="goCarteira(ativo)"
              :bound="bound" />
          </div>

          <div v-else>
            <card2
              v-for="ativo in dessertsDebentures.items"
              :key="ativo.title"
              :dataRecieved="ativo"
              v-show="showItem(ativo)"
              @cardClicked="goCarteira(ativo)"
              :bound="bound" />
          </div>
        </div>
        <div v-if="!telaMobile">
          <div
            class="row-expansion"
            v-for="ativo in dessertsDebentures.items"
            :key="ativo.title"
            @click="goCarteira(ativo)"
          >
            <Body :switchView="switchVisualizacao"
                  :ativo="ativo"
                  v-show="showItem(ativo)"
                  :bound="bound" />
          </div>
        </div>
      </template>
    </Accordion>

    <Accordion :cor="'none'" class="accordion">
      <template v-slot:header>
        <Header
          :switchView="switchVisualizacao"
          :item="dessertsTitulosPublicos"
          :cor="'#FAA90F'"
          :evolucao="evolucao.produto.titulos"
          :saldototal="evolucao.total.saldo"
          :bound="bound" />

        <!-- <LinhaTituloss v-if="!switchVisualizacao" />
        <LinhaTitulosEvolucao v-if="switchVisualizacao" /> -->
      </template>

      <template v-slot:body>
        <div
          class="text-center pa-4"
          v-if="dessertsTitulosPublicos.items.length == 0"
        >
          Nenhum investimento encontrado para este tipo de produto
        </div>
        <div v-if="telaMobile">
          <div v-if="!switchVisualizacao">
            <card1
              v-for="ativo in dessertsTitulosPublicos.items"
              :key="ativo.title"
              :dataRecieved="ativo"
              v-show="showItem(ativo)"
              @cardClicked="goCarteira(ativo)"
              :bound="bound" />
          </div>

          <div v-else>
            <card2
              v-for="ativo in dessertsTitulosPublicos.items"
              :key="ativo.title"
              :dataRecieved="ativo"
              v-show="showItem(ativo)"
              @cardClicked="goCarteira(ativo)"
              :bound="bound" />
          </div>
        </div>
        <div v-if="!telaMobile">
          <div
            class="row-expansion"
            v-for="ativo in dessertsTitulosPublicos.items"
            :key="ativo.title"
            @click="goCarteira(ativo)"
          >
            <Body :switchView="switchVisualizacao"
                  :ativo="ativo"
                  v-show="showItem(ativo)"
                  :bound="bound" />
          </div>
        </div>
      </template>
    </Accordion>

    <Accordion :cor="'none'" class="accordion">
      <template v-slot:header>
        <Header
          :switchView="switchVisualizacao"
          :item="dessertsFundosInvestimento"
          :cor="'#FAA90F'"
          :evolucao="evolucao.produto.fundosInvestimento"
          :saldototal="evolucao.total.saldo"
          :bound="bound" />
        <!-- <LinhaFundosInvestimento v-if="!switchVisualizacao" />
        <LinhaFundosInvestimentoEvolucaoto v-if="switchVisualizacao" /> -->
      </template>

      <template v-slot:body>
        <div
          class="text-center pa-4"
          v-if="dessertsFundosInvestimento.items.length == 0"
        >
          Nenhum investimento encontrado para este tipo de produto
        </div>
        <div v-if="telaMobile">
          <div v-if="!switchVisualizacao">
            <card1
              v-for="ativo in dessertsFundosInvestimento.items"
              :key="ativo.title"
              :dataRecieved="ativo"
              v-show="showItem(ativo)"
              @cardClicked="goCarteira(ativo)"
              :bound="bound" />
          </div>
          <div v-else>
            <card2
              v-for="ativo in dessertsFundosInvestimento.items"
              :key="ativo.title"
              :dataRecieved="ativo"
              v-show="showItem(ativo)"
              @cardClicked="goCarteira(ativo)"
              :bound="bound" />
          </div>
        </div>
        <div v-if="!telaMobile">
          <div
            class="row-expansion"
            v-for="ativo in dessertsFundosInvestimento.items"
            :key="ativo.title"
            @click="goCarteira(ativo)"
          >
            <Body :switchView="switchVisualizacao"
                  :ativo="ativo"
                  v-show="showItem(ativo)"
                  :bound="bound" />
          </div>
        </div>
      </template>
    </Accordion>

    <Accordion :cor="'none'" class="accordion">
      <template v-slot:header>
        <Header
          :switchView="switchVisualizacao"
          :item="dessertsPrevidencia"
          :cor="'#FAA90F'"
          :evolucao="evolucao.produto.previdencia"
          :saldototal="evolucao.total.saldo"
          :bound="bound" />
      </template>

      <template v-slot:body>
        <div
          class="text-center pa-4"
          v-if="dessertsPrevidencia.items.length == 0"
        >
          Nenhum investimento encontrado para este tipo de produto
        </div>
        <div v-if="telaMobile">
          <div v-if="!switchVisualizacao">
            <card1
              v-for="ativo in dessertsPrevidencia.items"
              :key="ativo.title"
              :dataRecieved="ativo"
              v-show="showItem(ativo)"
              @cardClicked="goCarteira(ativo)"
              :bound="bound" />
          </div>
          <div v-else>
            <card2
              v-for="ativo in dessertsPrevidencia.items"
              :key="ativo.title"
              :dataRecieved="ativo"
              v-show="showItem(ativo)"
              @cardClicked="goCarteira(ativo)"
              :bound="bound" />
          </div>
        </div>
        <div v-if="!telaMobile">
          <div
            class="row-expansion"
            v-for="ativo in dessertsPrevidencia.items"
            :key="ativo.title"
            @click="goCarteira(ativo)"
          >
            <Body :switchView="switchVisualizacao"
                  :ativo="ativo"
                  v-show="showItem(ativo)"
                  :bound="bound" />
          </div>
        </div>
      </template>
    </Accordion>

    <Accordion :cor="'none'" class="accordion">
      <template v-slot:header>
        <Header :switchView="switchVisualizacao"
                :item="dessertsAcoes"
                :cor="'#FA5807'"
                :evolucao="evolucao.produto.acoes"
                :saldototal="evolucao.total.saldo"
                :bound="bound" />
        <!-- <LinhaAcoes v-if="!switchVisualizacao" />
        <LinhaAcoesEvolucao v-if="switchVisualizacao" /> -->
      </template>

      <template v-slot:body>
        <div class="text-center pa-4" v-if="dessertsAcoes.items.length == 0">
          Nenhum investimento encontrado para este tipo de produto
        </div>
        <div v-if="telaMobile">
          <div v-if="!switchVisualizacao">
            <card1
              v-for="ativo in dessertsAcoes.items"
              :key="ativo.title"
              :dataRecieved="ativo"
              v-show="showItem(ativo)"
              @cardClicked="goCarteira(ativo)"
              :bound="bound" />
          </div>

          <div v-else>
            <card2
              v-for="ativo in dessertsAcoes.items"
              :key="ativo.title"
              :dataRecieved="ativo"
              v-show="showItem(ativo)"
              @cardClicked="goCarteira(ativo)"
              :bound="bound" />
          </div>
        </div>
        <div v-if="!telaMobile">
          <div
            class="row-expansion"
            v-for="ativo in dessertsAcoes.items"
            :key="ativo.title"
            @click="goCarteira(ativo)"
          >
            <Body :switchView="switchVisualizacao"
                  :ativo="ativo"
                  v-show="showItem(ativo)"
                  :bound="bound" />
          </div>
        </div>
      </template>
    </Accordion>

    <Accordion :cor="'none'" class="accordion">
      <template v-slot:header>
        <Header
          :switchView="switchVisualizacao"
          :item="dessertsFundosImobiliarios"
          :cor="'#FA5807'"
          :evolucao="evolucao.produto.fundosImobiliarios"
          :saldototal="evolucao.total.saldo"
          :bound="bound" />
        <!-- <LinhaFundosImobiliarios v-if="!switchVisualizacao" />
        <LinhaFundosImobiliariosEvolucao v-if="switchVisualizacao" /> -->
      </template>

      <template v-slot:body>
        <div
          class="text-center pa-4"
          v-if="dessertsFundosImobiliarios.items.length == 0"
        >
          Nenhum investimento encontrado para este tipo de produto
        </div>
        <div v-if="telaMobile">
          <div v-if="!switchVisualizacao">
            <card1
              v-for="ativo in dessertsFundosImobiliarios.items"
              :key="ativo.title"
              :dataRecieved="ativo"
              v-show="showItem(ativo)"
              @cardClicked="goCarteira(ativo)"
              :bound="bound" />
          </div>

          <div v-else>
            <card2
              v-for="ativo in dessertsFundosImobiliarios.items"
              :key="ativo.title"
              :dataRecieved="ativo"
              v-show="showItem(ativo)"
              @cardClicked="goCarteira(ativo)"
              :bound="bound" />
          </div>
        </div>

        <div v-if="!telaMobile">
          <div
            class="row-expansion"
            v-for="ativo in dessertsFundosImobiliarios.items"
            :key="ativo.title"
            @click="goCarteira(ativo)"
          >
            <Body :switchView="switchVisualizacao"
                  :ativo="ativo"
                  v-show="showItem(ativo)"
                  :bound="bound" />
          </div>
        </div>
      </template>
    </Accordion>


    <Accordion :cor="'none'" class="accordion">
      <template v-slot:header>
        <Header
          :switchView="switchVisualizacao"
          :item="dessertsOutros"
          :cor="'#FA5807'"
          :evolucao="evolucao.produto.outros"
          :saldototal="evolucao.total.saldo"
          :bound="bound" />
      </template>

      <template v-slot:body>
        <div
          class="text-center pa-4"
          v-if="dessertsOutros.items.length === 0">
          Nenhum investimento encontrado para este tipo de produto
        </div>
        <div v-if="telaMobile">
          <div v-if="!switchVisualizacao">
            <card1 v-for="ativo in dessertsOutros.items"
                   :key="ativo.title"
                   :dataRecieved="ativo"
                   v-show="showItem(ativo)"
                   @cardClicked="goCarteira(ativo)"
                   :bound="bound" />
          </div>
          <div v-else>
            <card2 v-for="ativo in dessertsOutros.items"
                   :key="ativo.title"
                   :dataRecieved="ativo"
                   v-show="showItem(ativo)"
                   @cardClicked="goCarteira(ativo)"
                   :bound="bound" />
          </div>
        </div>
        <div v-if="!telaMobile">
          <div class="row-expansion"
               v-for="ativo in dessertsOutros.items"
               :key="ativo.title"
               @click="goCarteira(ativo)">
            <Body :switchView="switchVisualizacao"
                  :ativo="ativo"
                  v-show="showItem(ativo)"
                  :bound="bound" />
          </div>
        </div>
      </template>
    </Accordion>
  </div>
</template>

<script>
import Accordion from "../components/Accordion.vue";
import card1 from "../components/cards/card1.vue";
import card2 from "../components/cards/card2.vue";
import Header from "../components/Header.vue";
// import LinhaCdbRdb from "../components/porClasseEProduto/cdb-rdb/Linha-acc.vue";
// import LinhaCdbRdbEvolucao from "../components/porClasseEProduto/cdb-rdb/Linha2-acc.vue";
// import LinhaCriCra from "../components/porClasseEProduto/cri-cra/Linha-acc.vue";
// import LinhaCriCraEvolucao from "../components/porClasseEProduto/cri-cra/Linha2-acc.vue";
// import LinhaDebentures from "../components/porClasseEProduto/debentures/Linha-acc.vue";
// import LinhaDebenturesEvolucao from "../components/porClasseEProduto/debentures/Linha2-acc.vue";
// import LinhaLciLca from "../components/porClasseEProduto/lci-lca/Linha-acc.vue";
// import LinhaLciLcaEvolucao from "../components/porClasseEProduto/lci-lca/Linha2-acc.vue";
// import LinhaTituloss from "../components/porClasseEProduto/titulos-publicos/Linha-acc.vue";
// import LinhaTitulosEvolucao from "../components/porClasseEProduto/titulos-publicos/Linha2-acc.vue";
// import LinhaAcoes from "../components/porClasseEProduto/acoes/Linha-acc.vue";
// import LinhaAcoesEvolucao from "../components/porClasseEProduto/acoes/Linha2-acc.vue";
// import LinhaFundosImobiliarios from "../components/porClasseEProduto/fundos-imobiliarios/Linha-acc.vue";
// import LinhaFundosImobiliariosEvolucao from "../components/porClasseEProduto/fundos-imobiliarios/Linha2-acc.vue";
// import LinhaFundosInvestimento from "../components/porClasseEProduto/fundos-investimento/Linha-acc.vue";
// import LinhaFundosInvestimentoEvolucaoto from "../components/porClasseEProduto/fundos-investimento/Linha2-acc.vue";
import Body from "../components/Body.vue";
import dateHelper from "../../../../Utils/DateHelper";
import percentageHelper from "../../../../Utils/PercentageHelper";
import currencyHelper from "../../../../Utils/CurrencyHelper";

export default {
  data() {
    return {
      percentageHelper,
      dateHelper,
      currencyHelper,
    };
  },

  components: {
    Accordion,
    card1,
    card2,
    Header,
    Body,
    // LinhaCdbRdb,
    // LinhaCdbRdbEvolucao,
    // LinhaCriCra,
    // LinhaCriCraEvolucao,
    // LinhaDebentures,
    // LinhaDebenturesEvolucao,
    // LinhaLciLca,
    // LinhaLciLcaEvolucao,
    // LinhaTituloss,
    // LinhaTitulosEvolucao,
    // LinhaAcoes,
    // LinhaAcoesEvolucao,
    // LinhaFundosImobiliarios,
    // LinhaFundosImobiliariosEvolucao,
    // LinhaFundosInvestimento,
    // LinhaFundosInvestimentoEvolucaoto,
  },

  props: {
    switchVisualizacao: Boolean,
    telaMobile: Boolean,
    dessertsCdbRdb: Object,
    dessertsCriCra: Object,
    dessertsDebentures: Object,
    dessertsLciLca: Object,
    dessertsTitulosPublicos: Object,
    dessertsAcoes: Object,
    dessertsFundosImobiliarios: Object,
    dessertsFundosInvestimento: Object,
    dessertsPrevidencia: Object,
    dessertsOutros: Object,
    evolucao: Object,
    monthLabel: String,
    showAll: Boolean,
    bound: Boolean
  },

  methods: {
    goCarteira(e) {

      let obj = {
        item: e,
        show: true,
      };
      this.$router.push({ name: "carteira", params: obj });
    },
    showItem (item) {
      return this.showAll || Math.abs(item.value) > 1E-3;
    }
  },
};
</script>

<style scoped>
/*.fundo-accordion {
}*/
.accordions {
  padding: 0 !important;
  margin: 0rem 0 0 0;
  width: 100% !important;
}
.linha {
  margin-top: 1rem;
}
.linha .coluna {
  margin: 0 !important;
  padding: 0.5rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.linha .coluna > div {
  background: white;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  padding: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.linha .coluna > div span {
  font-size: 0.9rem;
}
.linha-2 {
  margin-top: 1rem;
}
.linha-2 .coluna {
  margin: 0 !important;
  padding: 0.0rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.linha-2 .coluna > div {
  background:#ebedf0;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  padding: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.linha-2 .coluna > div span {
  font-size: 0.9rem;
}

/* FIM DO TESTE DO ROW */

.itens-1 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.itens-1 .select {
  min-width: 40vw;
  margin: 1.5rem 2rem 0 0;
}
.itens-1 .btn-evolucao {
  display: flex;
  align-items: center;
  border: 1px solid var(--principal-azul);
  justify-content: center;
  padding: 0.5rem 1.5rem;
  border-radius: 25px;
}
.descricao-evolucao {
  margin: 0 1.5rem 0 0;
}
.descricao-evolucao span {
  font-weight: bold;
  font-size: 18px;
  line-height: 19px;
  letter-spacing: 0.15px;
  color: var(--principal-azul);
}
.btns-ativos {
  display: flex;
  flex-wrap: wrap;
}
.btns-ativos > div {
  margin: 0 0.3rem;
}
.btns-ativos > div span {
  padding: 0 0.8rem;
}
/* CARDS DO TOPO */

.cards > div {
  padding: 0.5rem 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 10px;
}
.carteira-produto {
  grid-area: carteira-produto;
}
.participacao {
  grid-area: participacao;
}
.emissor-gestor {
  grid-area: emissor-gestor;
}
.resultado {
  grid-area: resultado;
  display: flex;
  flex-direction: column;
  padding: 0.5rem !important;
}
.custodiante {
  grid-area: custodiante;
}
.liqui-em-dias {
  grid-area: liqui-em-dias;
}
.vencimento {
  grid-area: vencimento;
}
.saldo-bruto {
  grid-area: saldo-bruto;
}
.resultado .items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.resultado .title-result {
  border-bottom: 3px solid #c6c6c6;
  width: 100%;
  padding: 0.2rem 0;
}
.items .mes {
  color: #a9d08e;
}
.items .meses {
  color: #f3a03c;
}
.items .inicio {
  color: var(--principal-azul);
}
.item span {
  font-weight: bold;
}

/* FIM DOS CARDS DO TOPO */

/* CONTEUDOS DO BODY DOS ACCORDIONS */

.row-expansion {
  margin-top: 1rem;
  padding: 0 !important;
  width: 100% !important;
  margin: 0 auto !important;
}
.row-expansion .col {
  padding: 0 0.6rem !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}

.row-expansion .col > div {
  background: white;
  width: 100% !important;
  height: 100%;
  padding: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 6vh;
  border-bottom: 1px solid rgba(73, 73, 73, 0.1);
}
.row-expansion .col > div span {
  font-size: 0.79rem;
}
.row-expansion .col:first-child {
  justify-content: flex-start !important;
  padding-left: 0.2rem !important;
}
.row-expansion .col:last-child {
  padding-right: 0 !important;
}
.row-expansion:last-child .col > div {
  border-radius: 0 0 20px 20px;
}

.row-expansion:last-child .coluna .colunas .row-expansion-2 .coluna-2 {
  overflow: hidden;
}
.row-expansion:last-child .coluna .colunas .row-expansion-2 .coluna-2 > div {
  border: none !important;
  border-radius: none !important;
}

/* FIM DOS CONTEUDOS DO BODY DOS ACCORDIONS */

/** COMPONETNS ED ACCORDIONS DINACIMOS*/
.accordion {
  margin-bottom: 0.2rem;
  margin-right: 2rem;
}
/**FIM DOS COMPONENTES DE ACCORDIOS DINAMICOS */
/*  ********** PAINEIS ************** */
.header-cdb-rdb {
  background: none;
  /*font-weight:  !important;*/
}
.header-cri-cra {
  background: none;
  font-weight: bold !important;
}
.header-debentures {
  background: none;
  font-weight: bold !important;
}
.header-lci-lca {
  background: none;
  font-weight: bold !important;
}
.header-titulos-publicos {
  background: none;
  font-weight: bold !important;
}
.header-acoes {
  background: none;
  font-weight: bold !important;
}
.header-fundos-imobiliarios {
  background: none;
  font-weight: bold !important;
}
.header-fundos-investimento {
  background: none;
  font-weight: bold !important;
}
.header-panel {
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  grid-template-rows: 1fr;
  grid-template-areas: "carteira carteira porcentagem porcentagem porcentagem porcentagem porcentagem porcentagem valor";
}
.header-panel .titles {
  grid-area: carteira;
}
.header-panel .percent {
  grid-area: porcentagem;
}
.header-panel .valor {
  grid-area: valor;
}
/*.header-cdb-rdb {
}*/
.header-cdb-rdb .linha {
  margin-top: -0.1%;
  max-height: 100% !important;
}
.header-cdb-rdb .linha .coluna-header {
  text-align: center;
}

/*  ********** FIM PAINEIS ************** */
.cards-top .result-line {
  position: relative;
}
.cards-top {
  position: relative;
}
@media screen and (max-width: 950px) {
  .btns-ativos > div {
    flex: 1 1 150px;
  }
  .elements .cards-top,
  .cards-top-2 {
    display: none;
  }
  .btn-evolucao {
    margin-bottom: 0.5rem;
  }

  .cards-top {
    display: none;
  }
  /** fim barra de carteira */

  /**itens dos accordions */
  .row-expansion .col {
    padding: 0 !important;
  }
  /*.row-expansion .col > div {
  }*/
  /**fim dos itens dos accordions */
}
</style>