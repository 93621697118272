<template>
  <v-container class="d-flex flex-column justify-center align-center">
    <v-dialog v-model="npsDialog" max-width="700">
      <NPS :pce_id="exam.codigoCompra" @close="close" />
    </v-dialog>

    <div
      class="text--secondary d-flex justify-start align-center mb-3"
      style="width: 100%"
    >
      <v-btn class="ml-4" icon href="/exams">
        <v-icon color="#ccc">mdi-chevron-left</v-icon>
        <span style="font-size: 0.7rem; color: #999">inicio</span>
      </v-btn>
    </div>

    <v-alert
      v-show="showSuccess"
      transition="scroll-x-reverse-transition"
      class="alert"
      type="success"
      border="left"
      elevation="3"
    >
      {{ message }}
    </v-alert>

    <v-card class="pa-3 transition-swing" max-width="800" min-height="400">
      <v-window v-model="step">
        <v-container class="d-flex">
          <div class="my-2 mx-5">
            <h2 class="section-title">{{ exam.codigoCompra }}</h2>
            <strong class="subtitle">{{
              exam.codigoStatus | formatTitle
            }}</strong>
            <span class="subtitle">{{ switchDate(exam.codigoStatus) }}</span>
            <p class="text" v-if="step !== 8">{{ exam.avisoB2BB2C }}</p>

            <div
              class="d-flex justify-center align-center"
              style="width: 100%"
              v-if="step === 7"
            >
              <v-btn
                color="primary"
                class="butt"
                small
                height="25"
                width="160"
                href="https://api.whatsapp.com/send?phone=5511945022666"
                target="_blank"
                >Entre em contato
              </v-btn>
            </div>

            <p class="text" v-else-if="step === 8">
              Seu Exame esta em Analise em nosso Laboratório, aguarde o
              resultado
            </p>

            <p class="text" v-if="step == 0">Coleta em análise</p>
            <strong class="text" v-if="step === 1"
              >Valor do Exame: R${{ exam.examValue }}
            </strong>
          </div>
        </v-container>

        <v-window-item :value="1">
          <h4 class="ml-5 mt-2" style="color: #555">FORMA DE PAGAMENTO</h4>

          <v-card-text class="px-6 d-flex justify-center align-center">
            <v-img
              v-if="
                exam.paymentForm == 'E' ||
                exam.paymentForm == 'C' ||
                exam.paymentForm == 'L'
              "
              src="../../../../assets/Boleto.png"
              alt="Forma de Pagamento"
              max-width="80"
            ></v-img>

            <strong class="subtitle ml-3">{{
              exam.paymentForm | formatMode
            }}</strong>
          </v-card-text>

          <v-btn
            class="copy-button mx-auto"
            text
            v-if="
              exam.paymentForm == 'E' ||
              exam.paymentForm == 'C' ||
              exam.paymentForm == 'L'
            "
            @click="copyBoleto"
          >
            COPIAR CÓDIGO DE BARRAS
          </v-btn>

          <div
            style="
              width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              margin: 30px 0;
            "
          >
            <v-btn
              v-if="
                exam.paymentForm == 'E' ||
                exam.paymentForm == 'C' ||
                exam.paymentForm == 'L'
              "
              color="primary"
              class="butt mx-auto"
              small
              width="140"
              target="_blank"
              :href="boleto.linK_BOLETO"
              >Acesse seu boleto
            </v-btn>
          </div>

          <v-divider></v-divider>

          <div
            class="pa-5"
            v-if="
              exam.paymentForm == 'E' ||
              exam.paymentForm == 'C' ||
              exam.paymentForm == 'L'
            "
          >
            <p class="text">
              Boletos Bancários levam até 24 horas úteis para compensar.
            </p>

            <p class="text">
              Sua nota fiscal estará disponivel até 24 horas úteis após o
              resultado ser liberado.
            </p>
          </div>
        </v-window-item>

        <v-window-item :value="2">
          <v-row class="mx-2 my-4" v-if="loading">
            <v-col class="my-5 d-flex justify-center align-center">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-col>
          </v-row>
          <div class="pa-3" v-if="!loading">
            <div class="ml-2">
              <p class="orange-title">{{ address.nomE_FANTASIA }}</p>
            </div>

            <div class="d-flex pa-3">
              <v-divider vertical class="mx-3 mb-12"></v-divider>
              <div>
                <p class="text">
                  {{ address.tipO_LOGRADOURO }} {{ address.logradouro }}
                  {{ address.enD_NUMERO }} - {{ address.enD_COMPLEMENTO }}
                </p>
                <p class="text">{{ address.bairro }}, {{ address.estado }}</p>
                <p class="text">Cep: {{ address.cep }}</p>
                <p class="text" v-if="address.telefone">
                  Telefone:
                  {{
                    address.telefone != null ? address.telefone : "Sem registro"
                  }}
                </p>
              </div>
            </div>
          </div>

          <div class="px-8 text d-flex">
            Caso tenha feito uso de medicamentos que possam ter afetado o
            resultado seu exame toxicológico, envia a prescrição médica.
          </div>

          <v-form ref="form">
            <v-row
              v-if="showForm"
              class="transition-swing flex-column mt-2"
              justify="center"
              align="center"
            >
              <v-col class="d-flex justify-center pa-0">
                <v-text-field
                  class="doctor-field"
                  outlined
                  :rules="[(v) => !!v || 'Campo Obrigatório']"
                  v-model="doctorCrm"
                  label="CRM do Médico"
                >
                </v-text-field>
              </v-col>
              <v-col class="d-flex justify-center pa-0">
                <v-text-field
                  class="doctor-field"
                  outlined
                  v-mask="['(##)####-####', '(##)#####-####']"
                  :rules="[(v) => !!v || 'Campo Obrigatório']"
                  v-model="doctorPhoneNumber"
                  label="Telefone do Médico"
                >
                </v-text-field>
              </v-col>
              <v-col class="d-flex justify-center pa-0">
                <v-file-input
                  class="doctor-field mb-8"
                  outlined
                  v-model="file"
                  @change="setFile"
                  accept=".pdf"
                  label="Prescrição"
                  :rules="[(v) => !!v || 'Campo Obrigatório']"
                  prepend-inner-icon="mdi-paperclip"
                  prepend-icon=""
                  persistent-hint
                  hint="Insira aqui o arquivo da sua prescrição (PDF)"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-form>

          <div class="d-flex justify-center mb-4">
            <v-btn
              color="primary"
              class="butt"
              small
              height="25"
              width="160"
              :loading="loading"
              @click="sendPrescription"
              >Enviar prescrição
            </v-btn>
          </div>
        </v-window-item>

        <v-window-item :value="3">
          <v-divider class="mx-3"></v-divider>

          <div class="pa-8 text">
            Caso faça uso de medicamentos que possam afetar o resultado do seu
            exame toxicológico, preencha os dados abaixo para o envio da
            prescrição médica
          </div>

          <v-form ref="form">
            <v-row
              v-if="showForm"
              class="transition-swing flex-column mt-2"
              justify="center"
              align="center"
            >
              <v-col class="d-flex justify-center pa-0">
                <v-text-field
                  class="doctor-field"
                  outlined
                  :rules="[(v) => !!v || 'Campo Obrigatório']"
                  v-model="doctorCrm"
                  label="CRM do Médico"
                >
                </v-text-field>
              </v-col>
              <v-col class="d-flex justify-center pa-0">
                <v-text-field
                  class="doctor-field"
                  outlined
                  :rules="[(v) => !!v || 'Campo Obrigatório']"
                  v-mask="['(##)####-####', '(##)#####-####']"
                  v-model="doctorPhoneNumber"
                  label="Telefone do Médico"
                >
                </v-text-field>
              </v-col>
              <v-col class="d-flex justify-center pa-0">
                <v-file-input
                  class="doctor-field mb-8"
                  outlined
                  v-model="file"
                  @change="setFile"
                  accept=".pdf"
                  label="Prescrição"
                  :rules="[(v) => !!v || 'Campo Obrigatório']"
                  prepend-inner-icon="mdi-paperclip"
                  prepend-icon=""
                  persistent-hint
                  hint="Insira aqui o arquivo da sua prescrição (PDF)"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-form>

          <div class="d-flex justify-center mb-4">
            <v-btn
              color="primary"
              class="butt"
              small
              height="25"
              width="160"
              :loading="loading"
              @click="sendPrescription"
              >Enviar prescrição
            </v-btn>
          </div>
        </v-window-item>

        <v-window-item :value="4">
          <v-divider class="mx-3"></v-divider>

          <div class="pa-8 text">
            Caso faça uso de medicamentos que possam afetar o resultado do seu
            exame toxicológico, preencha os dados abaixo para o envio da
            prescrição médica
          </div>

          <v-form ref="form">
            <v-row
              v-if="showForm"
              class="transition-swing flex-column mt-2"
              justify="center"
              align="center"
            >
              <v-col class="d-flex justify-center pa-0">
                <v-text-field
                  class="doctor-field"
                  outlined
                  :rules="[(v) => !!v || 'Campo Obrigatório']"
                  v-model="doctorCrm"
                  label="CRM do Médico"
                >
                </v-text-field>
              </v-col>
              <v-col class="d-flex justify-center pa-0">
                <v-text-field
                  class="doctor-field"
                  outlined
                  :rules="[(v) => !!v || 'Campo Obrigatório']"
                  v-mask="['(##)####-####', '(##)#####-####']"
                  v-model="doctorPhoneNumber"
                  label="Telefone do Médico"
                >
                </v-text-field>
              </v-col>
              <v-col class="d-flex justify-center pa-0">
                <v-file-input
                  class="doctor-field mb-8"
                  outlined
                  v-model="file"
                  @change="setFile"
                  :rules="[(v) => !!v || 'Campo Obrigatório']"
                  accept=".pdf"
                  label="Prescrição"
                  prepend-inner-icon="mdi-paperclip"
                  prepend-icon=""
                  persistent-hint
                  hint="Insira aqui o arquivo da sua prescrição (PDF)"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-form>

          <div class="d-flex justify-center mb-4">
            <v-btn
              color="primary"
              class="butt"
              small
              height="25"
              width="160"
              :loading="loading"
              @click="sendPrescription"
              >Enviar prescrição
            </v-btn>
          </div>
        </v-window-item>

        <v-window-item :value="0">
          <v-divider class="mx-3"></v-divider>

          <div class="pa-8 text">
            Caso faça uso de medicamentos que possam afetar o resultado do seu
            exame toxicológico, preencha os dados abaixo para o envio da
            prescrição médica
          </div>

          <v-form ref="form">
            <v-row
              v-if="showForm"
              class="transition-swing flex-column mt-2"
              justify="center"
              align="center"
            >
              <v-col class="d-flex justify-center pa-0">
                <v-text-field
                  class="doctor-field"
                  outlined
                  :rules="[(v) => !!v || 'Campo Obrigatório']"
                  v-model="doctorCrm"
                  label="CRM do Médico"
                >
                </v-text-field>
              </v-col>
              <v-col class="d-flex justify-center pa-0">
                <v-text-field
                  class="doctor-field"
                  outlined
                  :rules="[(v) => !!v || 'Campo Obrigatório']"
                  v-mask="['(##)####-####', '(##)#####-####']"
                  v-model="doctorPhoneNumber"
                  label="Telefone do Médico"
                >
                </v-text-field>
              </v-col>
              <v-col class="d-flex justify-center pa-0">
                <v-file-input
                  class="doctor-field mb-8"
                  outlined
                  v-model="file"
                  @change="setFile"
                  :rules="[(v) => !!v || 'Campo Obrigatório']"
                  accept=".pdf"
                  label="Prescrição"
                  prepend-inner-icon="mdi-paperclip"
                  prepend-icon=""
                  persistent-hint
                  hint="Insira aqui o arquivo da sua prescrição (PDF)"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-form>

          <div class="d-flex justify-center mb-4">
            <v-btn
              color="primary"
              class="butt"
              small
              height="25"
              width="160"
              :loading="loading"
              @click="sendPrescription"
              >Enviar prescrição
            </v-btn>
          </div>
        </v-window-item>

        <v-window-item :value="5">
          <div class="mx-6 py-3">
            Seu resultado será inserido no RENACH e sua nota fiscal estará
            disponivel em até 24h úteis.
          </div>

          <div class="d-flex justify-center mb-4">
            <v-btn
              color="#555"
              class="butt"
              small
              @click="getReportB2C"
              :loading="resultLoading"
              >Ver resultado
            </v-btn>
          </div>

          <div class="d-flex justify-center mb-4">
            <v-btn
              color="#555"
              class="butt"
              small
              v-if="exam.hasContraProva"
              @click="getReportContraProva"
              :loading="resultLoading"
              >Ver resultado Contraprova
            </v-btn>
          </div>

          <div class="d-flex justify-center mb-2">
            <v-btn
              v-if="
                exam.origem == 'B2B' ||
                exam.pce_motivo_exame == 'E' ||
                exam.haveMedicalReport
              "
              color="#555"
              class="butt"
              small
              @click="getReportB2B"
              :loading="resultRMLoading"
              >Relatório Médico
            </v-btn>
          </div>

          <v-divider class="ma-3"></v-divider>

          <div class="d-flex flex-column justify-center mb-4 butt" v-if="receipt && receipt.linkNfe">
            <span
              class="text text-center"
              style="font-weight: bold; font-size: 0.9rem"
              >Sua nota fiscal está disponivel.</span
            >
            <v-btn
              color="primary"
              class="butt mx-auto"
              small
              width="140"
              :loading="receiptLoading"
              target="_blank"
              :href="receipt.linkNfe"
              >Ver nota fiscal
            </v-btn>
          </div>

          <div class="px-8 text d-flex">
            Caso seu exame seja positivo e deseje contestar o resultado é
            possivel solicitar contraprova.
          </div>

          <div class="d-flex justify-center mb-4 butt">
            <v-btn
              color="primary"
              class="butt"
              small
              :loading="proofLoading"
              @click="requestAgainsProof"
              target="_blank"
              :href="downloadPDF"
              >Solicitar Contraprova
            </v-btn>
          </div>

          <div class="px-8 text d-flex">
            Caso tenha feito uso de medicamentos que possam ter afetado o
            resultado seu exame toxicológico, envia a prescrição médica.
          </div>

          <v-form ref="form">
            <v-row
              v-if="showForm"
              class="transition-swing flex-column mt-2"
              justify="center"
              align="center"
            >
              <v-col class="d-flex justify-center pa-0">
                <v-text-field
                  class="doctor-field"
                  outlined
                  :rules="[(v) => !!v || 'Campo Obrigatório']"
                  v-model="doctorCrm"
                  label="CRM do Médico"
                >
                </v-text-field>
              </v-col>
              <v-col class="d-flex justify-center pa-0">
                <v-text-field
                  class="doctor-field"
                  outlined
                  v-mask="['(##)####-####', '(##)#####-####']"
                  :rules="[(v) => !!v || 'Campo Obrigatório']"
                  v-model="doctorPhoneNumber"
                  label="Telefone do Médico"
                >
                </v-text-field>
              </v-col>
              <v-col class="d-flex justify-center pa-0">
                <v-file-input
                  class="doctor-field mb-8"
                  outlined
                  v-model="file"
                  @change="setFile"
                  accept=".pdf"
                  label="Prescrição"
                  :rules="[(v) => !!v || 'Campo Obrigatório']"
                  prepend-inner-icon="mdi-paperclip"
                  prepend-icon=""
                  persistent-hint
                  hint="Insira aqui o arquivo da sua prescrição (PDF)"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-form>

          <div class="d-flex justify-center mb-4">
            <v-btn
              color="primary"
              class="butt"
              small
              height="25"
              width="160"
              :loading="loading"
              @click="sendPrescription"
              >Enviar prescrição
            </v-btn>
          </div>
        </v-window-item>

        <v-window-item :value="6">
          <div class="d-flex justify-center mb-2">
            <v-btn
              color="#555"
              class="butt"
              small
              @click="getReportB2C"
              :loading="resultLoading"
              >Ver resultado
            </v-btn>
          </div>

          <div class="d-flex justify-center mb-4">
            <v-btn
              color="#555"
              class="butt"
              small
              v-if="exam.hasContraProva"
              @click="getReportContraProva"
              :loading="resultLoading"
              >Ver resultado Contraprova
            </v-btn>
          </div>

          <div class="d-flex justify-center mb-2">
            <v-btn
              v-if="
                exam.origem == 'B2B' ||
                exam.pce_motivo_exame == 'E' ||
                exam.haveMedicalReport
              "
              color="#555"
              class="butt"
              small
              @click="getReportB2B"
              :loading="resultRMLoading"
              >Relatório Médico
            </v-btn>
          </div>

          <div class="px-8 text d-flex">
            Seu exame toxicológico tem validade até
            {{ formatDate(exam.dataColeta) }}.
          </div>

          <v-divider class="mx-5"></v-divider>

          <div class="d-flex flex-column justify-center mb-4 butt" v-if="receipt && receipt.linkNfe">
            <span
              class="text text-center"
              style="font-weight: bold; font-size: 0.9rem"
              >Sua nota fiscal está disponivel.</span
            >
            <v-btn
              color="primary"
              class="butt mx-auto"
              small
              width="140"
              :loading="receiptLoading"
              target="_blank"
              :href="receipt.linkNfe"
              >Ver nota fiscal
            </v-btn>
          </div>

          <div class="px-8 text d-flex">
            Caso seu exame seja positivo e deseje contestar o resultado é
            possivel solicitar contraprova.
          </div>

          <div class="d-flex justify-center mb-4 butt">
            <v-btn
              color="primary"
              class="butt"
              small
              :loading="proofLoading"
              @click="requestAgainsProof"
              target="_blank"
              :href="downloadPDF"
              >Solicitar Contraprova
            </v-btn>
          </div>

          <div class="px-8 text d-flex">
            Caso tenha feito uso de medicamentos que possam ter afetado o
            resultado seu exame toxicológico, envia a prescrição médica.
          </div>

          <v-form ref="form">
            <v-row
              v-if="showForm"
              class="transition-swing flex-column mt-2"
              justify="center"
              align="center"
            >
              <v-col class="d-flex justify-center pa-0">
                <v-text-field
                  class="doctor-field"
                  outlined
                  :rules="[(v) => !!v || 'Campo Obrigatório']"
                  v-model="doctorCrm"
                  label="CRM do Médico"
                >
                </v-text-field>
              </v-col>
              <v-col class="d-flex justify-center pa-0">
                <v-text-field
                  class="doctor-field"
                  outlined
                  v-mask="['(##)####-####', '(##)#####-####']"
                  :rules="[(v) => !!v || 'Campo Obrigatório']"
                  v-model="doctorPhoneNumber"
                  label="Telefone do Médico"
                >
                </v-text-field>
              </v-col>
              <v-col class="d-flex justify-center pa-0">
                <v-file-input
                  class="doctor-field mb-8"
                  outlined
                  v-model="file"
                  @change="setFile"
                  accept=".pdf"
                  label="Prescrição"
                  :rules="[(v) => !!v || 'Campo Obrigatório']"
                  prepend-inner-icon="mdi-paperclip"
                  prepend-icon=""
                  persistent-hint
                  hint="Insira aqui o arquivo da sua prescrição (PDF)"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-form>

          <div class="d-flex justify-center mb-4">
            <v-btn
              color="primary"
              class="butt"
              small
              height="25"
              width="160"
              :loading="loading"
              @click="sendPrescription"
              >Enviar prescrição
            </v-btn>
          </div>
        </v-window-item>

        <v-window-item :value="7">
          <v-divider class="mx-3"></v-divider>

          <div class="pa-8 text">
            Caso faça uso de medicamentos que possam afetar o resultado do seu
            exame toxicológico, preencha os dados abaixo para o envio da
            prescrição médica
          </div>

          <v-form ref="form">
            <v-row
              v-if="showForm"
              class="transition-swing flex-column mt-2"
              justify="center"
              align="center"
            >
              <v-col class="d-flex justify-center pa-0">
                <v-text-field
                  class="doctor-field"
                  outlined
                  :rules="[(v) => !!v || 'Campo Obrigatório']"
                  v-model="doctorCrm"
                  label="CRM do Médico"
                >
                </v-text-field>
              </v-col>
              <v-col class="d-flex justify-center pa-0">
                <v-text-field
                  class="doctor-field"
                  outlined
                  :rules="[(v) => !!v || 'Campo Obrigatório']"
                  v-mask="['(##)####-####', '(##)#####-####']"
                  v-model="doctorPhoneNumber"
                  label="Telefone do Médico"
                >
                </v-text-field>
              </v-col>
              <v-col class="d-flex justify-center pa-0">
                <v-file-input
                  class="doctor-field mb-8"
                  outlined
                  v-model="file"
                  @change="setFile"
                  :rules="[(v) => !!v || 'Campo Obrigatório']"
                  accept=".pdf"
                  label="Prescrição"
                  prepend-inner-icon="mdi-paperclip"
                  prepend-icon=""
                  persistent-hint
                  hint="Insira aqui o arquivo da sua prescrição (PDF)"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-form>

          <div class="d-flex justify-center mb-4">
            <v-btn
              color="primary"
              class="butt"
              small
              height="25"
              width="160"
              :loading="loading"
              @click="sendPrescription"
              >Enviar prescrição
            </v-btn>
          </div>
        </v-window-item>

        <v-window-item :value="8">
          <v-divider class="mx-3"></v-divider>

          <div class="pa-8 text">
            Caso faça uso de medicamentos que possam afetar o resultado do seu
            exame toxicológico, preencha os dados abaixo para o envio da
            prescrição médica
          </div>

          <v-form ref="form">
            <v-row
              v-if="showForm"
              class="transition-swing flex-column mt-2"
              justify="center"
              align="center"
            >
              <v-col class="d-flex justify-center pa-0">
                <v-text-field
                  class="doctor-field"
                  outlined
                  :rules="[(v) => !!v || 'Campo Obrigatório']"
                  v-model="doctorCrm"
                  label="CRM do Médico"
                >
                </v-text-field>
              </v-col>
              <v-col class="d-flex justify-center pa-0">
                <v-text-field
                  class="doctor-field"
                  outlined
                  :rules="[(v) => !!v || 'Campo Obrigatório']"
                  v-mask="['(##)####-####', '(##)#####-####']"
                  v-model="doctorPhoneNumber"
                  label="Telefone do Médico"
                >
                </v-text-field>
              </v-col>
              <v-col class="d-flex justify-center pa-0">
                <v-file-input
                  class="doctor-field mb-8"
                  outlined
                  v-model="file"
                  @change="setFile"
                  :rules="[(v) => !!v || 'Campo Obrigatório']"
                  accept=".pdf"
                  label="Prescrição"
                  prepend-inner-icon="mdi-paperclip"
                  prepend-icon=""
                  persistent-hint
                  hint="Insira aqui o arquivo da sua prescrição (PDF)"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-form>

          <div class="d-flex justify-center mb-4">
            <v-btn
              color="primary"
              class="butt"
              small
              height="25"
              width="160"
              :loading="loading"
              @click="sendPrescription"
              >Enviar prescrição
            </v-btn>
          </div>
        </v-window-item>
      </v-window>

      <v-divider></v-divider>
    </v-card>
  </v-container>
</template>

<style scoped>
.alert {
  position: absolute;
  right: 5%;
  top: 15%;

  z-index: 10;
}

.section-title {
  display: block;

  color: #f78129;
  font-weight: 900;
}

.subtitle {
  display: block;

  margin: 0;

  font-size: 0.9rem;

  color: #555;
}

.orange-title {
  color: #f78129;

  font-size: 0.9rem;
  font-weight: bold;

  margin: 0 10px;
}

.text {
  font-size: 0.8rem;

  margin: 10px 0px;
  color: #666;
}

.butt {
  font-size: 0.6rem;
  color: #fff;
}

.copy-button {
  display: block;

  border: 1px solid #aaa;
  color: #555;

  font-size: 0.8rem;
}

.doctor-field {
  margin: auto 0;

  max-width: 250px;

  font-size: 0.8rem;
}
</style>

<script>
import ApiService from "../../../../services/ApiService";
import moment from "moment";
import NPS from "./NPS.vue";

export default {
  name: "DetalhedExam",

  async created() {
    this.exams = JSON.parse(localStorage.getItem("exams"));
    this.exam = JSON.parse(localStorage.getItem("exam"));
    this.user = JSON.parse(sessionStorage.getItem("user"));
    this.getNPS();

    this.step = this.exam.codigoStatus;

    this.examIndex = this.exams.findIndex(
      (exam) => exam.codigoCompra == this.exam.codigoCompra
    );

    if (this.examIndex == 0) {
      this.disableBack = true;
    }

    if (this.examIndex >= this.exams.length - 1) {
      this.disableNext = true;
    }

    if (
      this.exam.paymentForm == "E" ||
      this.exam.paymentForm == "C" ||
      this.exam.paymentForm == "L"
    ) {
      if (this.step == 1) {
        await this.getBoleto();
      }
    }

    if (this.step == 2) {
      await this.getAddress();
    }

     if (this.step == 5) {
      this.getNotaFiscal();
    }

    if (this.step == 6) {
      this.getNotaFiscal();
    }
  },

  data: () => ({
    npsDialog: false,
    exam: {},
    examIndex: 0,
    disableBack: false,
    disableNext: false,
    examIndex: {},
    exams: {},
    user: {},
    address: {},
    boleto: {},
    receipt: {},
    step: 0,
    file: null,
    doctorCrm: null,
    doctorPhoneNumber: null,
    showForm: false,
    loading: false,
    receiptLoading: false,
    proofLoading: false,
    resultLoading: false,
    resultRMLoading: false,
    prescriptionFile: null,
    extension: null,
    message: null,
    showSuccess: false,
    apiService: new ApiService(),
    hasNPS: false,
  }),

  components: {
    NPS,
  },

  computed: {
    downloadPDF() {
      return (
        process.env.VUE_APP_BASE_URL +
        "Downloads/TERMO_DE_SOLICITACAO_CONTRAPROVA_LABET.pdf"
      );
    },
  },

  filters: {
    formatMode(val) {
      switch (val) {
        case "E":
          return "Boleto";
        case "R":
          return "Cartão de Crédito";
        case "X":
          return "PIX";
        case "P":
          return "Paymee";
        case "T":
          return "Cartão Via Link";
        case "C":
          return "Boleto Consolidado";
        case "L":
          return "Boleto";

        default:
          break;
      }
    },

    formatTitle(val) {
      switch (val) {
        case 0:
          return "EM ANÁLISE";
        case 1:
          return "AGUARDANDO PAGAMENTO";
        case 2:
          return "COLETA LIBERADA";
        case 3:
          return "COLETA REALIZADA";
        case 4:
          return "COLETA CHEGOU AO LABORATÓRIO";
        case 5:
          return "RESULTADO DISPONIVEL";
        case 6:
          return "RESULTADO INSERIDO NO RENACH";
        case 7:
          return "RECOLETA";

        default:
          break;
      }
    },
  },

  methods: {
    close() {
      this.npsDialog = false;
    },

    Back() {
      if (this.examIndex > 0) {
        this.disableNext = false;
        this.examIndex -= 1;
      } else {
        this.disableBack = true;
        return;
      }
      this.exam = this.exams[this.examIndex];
      this.step = this.exam.codigoStatus;
    },

    Next() {
      if (this.examIndex < this.exams.length - 1) {
        this.disableBack = false;
        this.examIndex += 1;
      } else {
        this.disableNext = true;
        return;
      }
      this.exam = this.exams[this.examIndex];
      this.step = this.exam.codigoStatus;
    },

    copyBoleto() {
      navigator.clipboard.writeText(this.boleto.coD_BARRAS);

      this.message = "Boleto copiado";
      this.showSuccess = true;

      setTimeout(() => {
        this.showSuccess = false;
      }, 4000);
    },

    formatDate(date) {
      let newdate = moment(date, "DD-MM-YYYY").add(
        this.exam.origem == "B2B" ? 60 : 90,
        "days"
      );
      return newdate.format("DD/MM/yyyy");
    },

    async getNPS() {
      await this.apiService
        .get(`NPS/consult/${this.exam.codigoCompra}`)
        .then((response) => {
          this.hasNPS = response.data;
        });
    },

    getNotaFiscal() {
      this.receiptLoading = true;

      this.apiService
        .get(`user/nota/${parseInt(this.exam.codigoCompra)}`)
        .then((response) => {
          this.receipt = response.data;
          this.receiptLoading = false;
        })
        .catch(() => {
          this.receiptLoading = false;
        });
    },

    async getBoleto() {
      this.loading = true;
      await this.apiService
        .get(`user/boleto/${parseInt(this.exam.codigoCompra)}`)
        .then((response) => {
          this.boleto = response.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    async getAddress() {
      this.loading = true;
      await this.apiService
        .get(`user/address/${parseInt(this.exam.codigoCompra)}`)
        .then((response) => {
          this.address = response.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    requestAgainsProof() {
      this.proofLoading = true;
      const againstProof = {
        Code: this.exam.codigoCompra,
        ClientEmail: this.user.paC_Email,
      };

      this.apiService
        .post("user/against-proof", againstProof)
        .then(() => {
          this.proofLoading = false;

          this.message = "Contra-Prova solicitada com sucesso";
          this.showSuccess = true;

          setTimeout(() => {
            this.showSuccess = false;
          }, 4000);
        })
        .catch(() => {
          this.proofLoading = false;
        });
    },

    sendPrescription() {
      if (this.$refs.form.validate()) {
        if (this.showForm == false) {
          this.showForm = true;
        } else {
          this.loading = true;

          const prescription = {
            Path: this.prescriptionFile,
            Code: this.exam.codigoCompra.toString(),
            CRM: this.doctorCrm,
            Phone: this.doctorPhoneNumber,
          };

          const model = {
            Extension: this.extension,
            Prescription: prescription,
          };

          this.apiService
            .post("user/send-prescription", model)
            .then(() => {
              this.message = "Prescrição enviada com sucesso";
              this.showForm = false;
              this.showSuccess = true;

              setTimeout(() => {
                this.showSuccess = false;
              }, 4000);
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        }
      }
    },

    async setFile(file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.extension = file.type.split("/")[1];
        this.prescriptionFile = reader.result;
      };
    },

    switchDate(val) {
      switch (val) {
        case 1:
          return moment(this.exam.dataCadastro, "DD/MM/YYYY hh:mm:ss").format(
            "DD/MM/YYYY"
          );
          "DD/MM/YYYY";

        case 2:
          return moment(this.exam.dataCadastro, "DD/MM/YYYY hh:mm:ss").format(
            "DD/MM/YYYY"
          );
          "DD/MM/YYYY";

        case 3:
          return moment(this.exam.dataColeta, "DD/MM/YYYY hh:mm:ss").format(
            "DD/MM/YYYY"
          );
          "DD/MM/YYYY";

        case 4:
          return moment(this.exam.dataAnalise, "DD/MM/YYYY hh:mm:ss").format(
            "DD/MM/YYYY"
          );
          "DD/MM/YYYY";

        case 5:
          return (
            "Laudo emitido em " +
            moment(this.exam.dataLaudo, "DD/MM/YYYY hh:mm:ss").format(
              "DD/MM/YYYY"
            )
          );

        case 6:
          return (
            "Inserido no renach em " +
            moment(this.exam.dataRenach, "DD/MM/YYYY hh:mm:ss").format(
              "DD/MM/YYYY"
            )
          );
        default:
          break;
      }
    },

    async getReportB2C() {
      this.resultLoading = true;
      const model = {
        BuyCode: this.exam.codigoCompra,
        CPF: this.user.paC_NUMCPF.replace(" ", ""),
      };

      this.message = "Download Solicitado";
      this.showForm = false;
      this.showSuccess = true;

      setTimeout(() => {
        this.showSuccess = false;
      }, 4000);

      await this.apiService.downLoadFile(
        `report/B2C?buyCode=${model.BuyCode}&CPF=${model.CPF}`,
        "Relatorio.pdf",
        "application/pdf; charset=UTF-8"
      );

      if (!this.hasNPS) {
        this.npsDialog = true;
      }
      this.resultLoading = false;
    },

    async getReportContraProva() {
      this.resultLoading = true;

      const model = {
        BuyCode: this.exam.codigoCompra,
        CPF: this.user.paC_NUMCPF.replace(" ", ""),
      };

      this.message = "Download Solicitado";
      this.showForm = false;
      this.showSuccess = true;

      setTimeout(() => {
        this.showSuccess = false;
      }, 4000);

      await this.apiService
        .get(`report/Contraprova?buyCode=${model.BuyCode}&CPF=${model.CPF}`)
        .then((response) => {
          this.resultRMLoading = false;
          response = "data:application/pdf;base64," + response;
          const link = document.createElement("a");
          link.href = response;
          link.setAttribute("download", "Relatório");
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          this.resultRMLoading = false;
        });
      this.resultLoading = false;
    },

    async getReportB2B() {
      this.resultRMLoading = true;

      const model = {
        BuyCode: this.exam.codigoCompra,
        CPF: this.user.paC_NUMCPF.replace(" ", ""),
      };

      this.message = "Download Solicitado";
      this.showForm = false;
      this.showSuccess = true;

      setTimeout(() => {
        this.showSuccess = false;
      }, 4000);

      this.apiService
        .post("report/B2B", model)
        .then((response) => {
          this.resultRMLoading = false;
          response = "data:application/pdf;base64," + response;
          const link = document.createElement("a");
          link.href = response;
          link.setAttribute("download", "Relatório");
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          this.resultRMLoading = false;
        });
    },
  },
};
</script>

<style></style>
