import ForaDoAr from "./ForaDoAr.vue";

const PageNotFoundRoutes = {
    name: "NotFound",
    // path: "/:NotFound(.*)*",
    path: "*",
    component: ForaDoAr,
};

export default PageNotFoundRoutes;
