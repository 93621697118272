/*

calories:    Saldo Bruto -  Total da Aplicação corrigida
fat:      Ganho mensal
protein:  Percentual Ganho mensal
carbs:    Ganho Total
iron:     Percentual desde o início
saldoLiquido : Saldo Liquido

Cálculo índices da visão geral (Ibovespa/dolar/ipca/cdi) com média ponderada. Será usado no Header e na Barra de carteira.

Para cada ativo fazer o seguinte cálculo, e no final somar todos.
     Último mês                              Desde o início                                     12 meses
(fat/calories) * indice                (carbs / calories)) * indice           (último carbs - Primeiro carbs)/calories) * indice    ou  (soma dos fat/calories) * indice
(fat/calories) * indice                (carbs / calories)) * indice           (último carbs - Primeiro carbs)/calories) * indice   ou  (soma dos fat/calories) * indice
(fat/calories) * indice                (carbs / calories)) * indice            (último carbs - Primeiro carbs)/calories) * indice  ou  (soma dos fat/calories) * indice
        .                                         .                                                 .
        .                                         .                                                 .
        .                                         .                                                 .
     somátorio                             somátorio                                           somátorio


gross-up
LCI/LCA (idTProduto === 4)
Cálculo: taxa = 1/(1 - taxa_ir).

*/

//#region Constantes

const CURRENCY_FORMAT   = "BRL";
const DEFAULT_LOCALE    = "pt-br";
const NUMBER_FORMAT     = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    currency: CURRENCY_FORMAT,
    percent: CURRENCY_FORMAT
};
const DEFAULT_IOF       = [
    {
        start   : undefined,
        finish  : undefined,
        rates   : [ 1.00, 0.96, 0.93, 0.90, 0.86, 0.83,
                    0.80, 0.76, 0.73, 0.70, 0.66, 0.63,
                    0.60, 0.56, 0.53, 0.50, 0.46, 0.43,
                    0.40, 0.36, 0.33, 0.30, 0.26, 0.23,
                    0.20, 0.16, 0.13, 0.10, 0.06, 0.03 ]
    }
];
const DEFAULT_IR        = [
    {
        year    : undefined,
        rates   : [ { days:  180, rate: 0.225 },
                    { days:  360, rate: 0.200 },
                    { days:  720, rate: 0.175 },
                    { days: null, rate: 0.150 } ]
    }
];
const STOCK_IR_RATE     = 0.150;
const FUNDS_IR_RATE     = 0.200;
const TPRODUTOS         = [
    undefined, "cdbRdb", "criCra", "debentures",
    "lciLca", "titulos", "acoes",
    "fundosImobiliarios", "fundosInvestimento", undefined, undefined, undefined, undefined, "outros"];
const NPRODUTOS         = [
    "cdbRdb", "criCra", "debentures",
    "lciLca", "titulos", "acoes",
    "fundosImobiliarios", "fundosInvestimento",
    "previdencia", "outros"
];
const ESTRATEGIAS       = [
    undefined, "jurosPre", "jurosPosCdi",
    "jurosPosInflacao", "rendaVariavel",
    "cambio", "livre"];
const _FORMAT           = {
    minimumFractionDigits: 6,
    maximumFractionDigits: 6,
    currency: CURRENCY_FORMAT,
    percent: CURRENCY_FORMAT
};
const COMPS = ["mensal", "doze", "geral"];
const PARTS = ["ganho", "percentual", "fatia", "valor"];
const DAYS_MUL = 1000 * 60 * 60 * 24;
const MONTH_ABBREV = [
    "Jan", "Fev", "Mar", "Abr",
    "Mai", "Jun", "Jul", "Ago",
    "Set", "Out", "Nov", "Dez"];

//#endregion

//#region Utility

class               BinarySearchHandler {
    _props          = {
        name        : undefined,
        formatter   : undefined
    };

    get propName    () { return this._props.name; }
    get formatter   () { return this._props.formatter; }

    getValue        (obj) {
        const data  = obj[this.propName];
        return this.formatter ? this.formatter(data) : data;
    }
    compare         (l, r) {
        return l === r ? 0 : l < r ? -1 : 1;
    }
    getBound        (items, tstamp, leftmost) {
        if (!(tstamp && items && items.length)) { return -1; }
        const input = formatISO(tstamp);
        let left    = 0;
        let right   = items.length - 1;
        while (left < right) {
            const middle = (left + right) >> 1;
            const item   = items[middle];
            const comp   = this.compare(this.getValue(item), input);
            if (comp === 0) {
                return middle > 0 && leftmost ? middle-1 : middle;
            } else if (comp > 0) {
                right = middle - 1;
            } else {
                left = middle + 1;
            }
        }
        const final = this.compare(this.getValue(items[left]), input);
        return (left > 0) && ((leftmost && final === 0) || (final > 0)) ? left - 1 : left;
    }

    constructor     (name, fmt) {
        this._props.name        = name;
        this._props.formatter   = fmt;
    }
}

function getDateValue (input) {
    if (input === null || input === undefined || input === '') { return new Date; }
    switch (typeof input) {
        case 'number': return new Date(input);
        case 'string':
            const spec = input.length > 10 ? input : `${input}T00:00:00`;
            return new Date(spec);
        default: return input;
    }
}
function formatISO  (data) {
    if (!data) { return new Date().toISOString().substring(0, 10); }
    return data.length > 10 ? data.substring(0, 10) : data;
}
function addDays    (data, days) {
    const input = data && data.length === 10 ? data + "T00:00:00" : data;
    let val = new Date(input).getTime();
    return formatISO(new Date(val + (DAYS_MUL * days)).toISOString());
}
function addMonths  (data, months) {
    let day     = +(data.substring(8, 10).replace(/^0+/g, ''));
    let month   = +(data.substring(5,  7).replace(/^0+/g, '')) - 1;
    let year    = +(data.substring(0,  4).replace(/^0+/g, ''));
    let nMont   = month + months;
    let aMont   = nMont % 12;
    let years   = Math.floor(nMont / 12);
    return new Date(year + years, aMont, day, 0, 0, 0).toISOString().substring(0, 10);
}
function diffAsDays (l, r) {
    const a = l && l.length === 10 ? l + "T00:00:00" : l;
    const b = r && r.length === 10 ? r + "T00:00:00" : r;
    let x = new Date(a).getTime();
    let y = new Date(b).getTime();
    return Math.floor((y - x) / DAYS_MUL);
}
function getBound (cotacoes, data, getPrevious) {
    let left        = 0;
    let right       = cotacoes.length;
    let middle      = 0;
    let input       = formatISO(data);
    let adjust      = getPrevious
        ? (v) => v > 0 ? v - 1 : v
        : (v) => v;
    while (left < right) {
        middle      = (left + right) >> 1;
        let item    = cotacoes[middle];
        let test    = formatISO(item.data);
        if (test === input) {
            return adjust(middle);
        } else if (test.localeCompare(input) > 0) {
            right   = middle - 1;
        } else {
            left    = middle  + 1;
        }
    }
    return adjust(left);
}
const QUOTEFLT  = new BinarySearchHandler('data', (v) => v.substring(0, 10));
const CALCFLT   = new BinarySearchHandler('date', (v) => v.substring(0, 10));
function filterQuotes (cotacoes, left, right, getPrevious) {
    if (!cotacoes.length) { return cotacoes; }
    let x = QUOTEFLT.getBound(cotacoes,  left, getPrevious);
    let y = QUOTEFLT.getBound(cotacoes, right,       false);
    return cotacoes.slice(x, y + 1);
}
function findOrPlaceTStamp (coll, tstamp) {
    let l = 0, r = coll.length - 1, m = 0;
    if (r === 0) {
        coll.push(tstamp);
        return true;
    }
    while (l <= r) {
        m = (l + r) >> 1;
        const v = coll[m];
        if (v === tstamp) {
            return false;
        } else if (v.localeCompare(tstamp) > 0) {
            r = m - 1;
        } else {
            l = m + 1;
        }
    }
    coll.splice(l, 0, tstamp);
    return true;
}

//#endregion

class Carteira {
    calcPercentage (cart, index) {
        let taxa;
        if  ((cart === 0) && (index === 0)) { return 0 };
        if  (index === 0) { return 0; }
        if  (cart === 0) { return 0; }
        taxa = Math.abs(cart / index);
        taxa = (cart > 0) ?  taxa : - taxa;
        return taxa;
    }

    updateTo (tipo, sug) {
        this.gustate = sug || false;
        let sel     = (tipo || '').toLowerCase();
        let self    = this;
        switch (sel) {
            case 'cdi':
            case 'ipca':
            case 'dolar':
            case 'ibovespa':
                const ug = this.gustate;
                const up = this.total.grossup?.grupo;
                const dn = this.total.saldo || 0;
                let   gm = { mensal: 0, doze: 0, geral: 0 };
                COMPS.forEach((n) => {
                    self.total[n].percentual = 0;
                    Object.getOwnPropertyNames(self.produto).forEach((w, p) => {
                        let target = self.produto[w];
                        if (!target.items) { return; }
                        const ix     = target.total.saldo || 0;
                        let   im     = { mensal: 0, doze: 0, geral: 0 };
                        const pup    = target.total.grossup?.grupo;
                        const gup    = target.total.grossup?.items;
                        target.items.forEach((item, iitm) => {
                            if (ug && gup && (item.evolucao[n].vganho > 0)) {
                                im[n] += item.evolucao.saldo;
                                gm[n] += item.evolucao.saldo;
                            }
                            const iganho = ug && gup && (item.evolucao[n].vganho > 0) ?
                                item.evolucao[n].vganho * gup[iitm] :
                                item.evolucao[n].vganho;
                            item.evolucao[n].percentual = this.calcPercentage(
                                item.evolucao[n].ganho = item.evolucao[n].fatia = iganho,
                                item.evolucao.cotacoes[sel][n])
                        });
                        const mul    = Math.abs(ix) > 1E-3 ? im[n] / ix : 1;
                        const eganho = ug && pup && (target.total[n].vganho > 0) ?
                            target.total[n].vganho * (pup * mul + 1 - mul) :
                            target.total[n].vganho;
                        target.total[n].percentual = this.calcPercentage(
                            target.total[n].ganho = target.total[n].fatia = eganho,
                            target.total.cotacoes[sel][n]);
                    });
                    Object.getOwnPropertyNames(self.estrategia).forEach((w) => {
                        let target = self.estrategia[w];
                        if (!target.items) { return; }
                        const ix     = target.total.saldo || 0;
                        let   im     = { mensal: 0, doze: 0, geral: 0 };
                        const pup    = target.total.grossup?.grupo;
                        const gup    = target.total.grossup?.items;
                        target.items.forEach((item, iitm) => {
                            if (ug && gup && (item.evolucao[n].vganho > 0)) {
                                im[n] += item.evolucao.saldo;
                            }
                            const iganho = ug && gup && (item.evolucao[n].vganho > 0) ?
                                item.evolucao[n].vganho * gup[iitm] :
                                item.evolucao[n].vganho;
                            item.evolucao[n].percentual = this.calcPercentage(
                                item.evolucao[n].ganho = item.evolucao[n].fatia = iganho,
                                item.evolucao.cotacoes[sel][n])
                        });
                        const mul    = Math.abs(ix) > 1E-3 ? im[n] / ix : 1;
                        const eganho = ug && pup && (target.total[n].vganho > 0) ?
                            target.total[n].vganho * (pup * mul + 1 - mul) :
                            target.total[n].vganho;
                        target.total[n].percentual = this.calcPercentage(
                            target.total[n].ganho = target.total[n].fatia = eganho,
                            target.total.cotacoes[sel][n]);
                    });
                    const mul = Math.abs(dn) > 1E-3 ? gm[n] / dn : 1;
                    const tganho = ug && up && (self.total[n].vganho > 0) ?
                        self.total[n].vganho * (up * mul + 1 - mul) :
                        self.total[n].vganho;
                    self.total[n].percentual = this.calcPercentage(
                        self.total[n].ganho = self.total[n].fatia = tganho,
                        self.total.cotacoes[sel][n]);
                });
                break;
            default:
                COMPS.forEach((n) => {
                    self.total[n].percentual = 0;
                    Object.getOwnPropertyNames(self.produto).forEach((w) => {
                        let tgt = self.produto[w];
                        if (tgt.total) {
                            tgt.total[n].percentual = 0;
                        }
                        tgt.items && (tgt.items.forEach((itm) => {
                            itm.evolucao[n].percentual = 0;
                        }));
                    });
                    Object.getOwnPropertyNames(self.estrategia).forEach((w) => {
                        let tgt = self.estrategia[w];
                        if (tgt.total) {
                            tgt.total[n].percentual = 0;
                        }
                        tgt.items && (tgt.items.forEach((itm) => {
                            itm.evolucao[n].percentual = 0;
                        }));
                    });
                });
        }
    }

    getItemPart () {
        return {
            ganho       : 0,
            percentual  : 0,
            fatia       : 0,
            valor       : 0,
            participacao: 1
        }
    }

    getDefaultPart () {
        return {
            total           : {
                saldo       : 0,
                liquido     : 0,
                mensal      : this.getItemPart(),
                doze        : this.getItemPart(),
                geral       : this.getItemPart(),
                cotacoes    : {
                    cdi     : { mensal: 0, doze: 0, geral: 0 },
                    ipca    : { mensal: 0, doze: 0, geral: 0 },
                    dolar   : { mensal: 0, doze: 0, geral: 0 },
                    ibovespa: { mensal: 0, doze: 0, geral: 0 }
                }
            },
            items: []
        }
    }

    constructor (data) {
        this.produto            = data && data.produto || {
            cdbRdb              : this.getDefaultPart(),
            criCra              : this.getDefaultPart(),
            debentures          : this.getDefaultPart(),
            lciLca              : this.getDefaultPart(),
            titulos             : this.getDefaultPart(),
            acoes               : this.getDefaultPart(),
            fundosImobiliarios  : this.getDefaultPart(),
            fundosInvestimento  : this.getDefaultPart(),
            previdencia         : this.getDefaultPart(),
            outros              : this.getDefaultPart()
        };
        this.estrategia         = data && data.estrategia || {
            jurosPre            : this.getDefaultPart(),
            jurosPosCdi         : this.getDefaultPart(),
            jurosPosInflacao    : this.getDefaultPart(),
            rendaVariavel       : this.getDefaultPart(),
            cambio              : this.getDefaultPart(),
            livre               : this.getDefaultPart()
        };
        this.total              = data && data.total || {
            saldo               : 0,
            liquido             : 0,
            mensal              : this.getItemPart(),
            doze                : this.getItemPart(),
            geral               : this.getItemPart(),
            ano                 : this.getItemPart(),
            cotacoes            : {
                cdi             : { mensal: 0, doze: 0, geral: 0, ano: 0 },
                ipca            : { mensal: 0, doze: 0, geral: 0, ano: 0 },
                dolar           : { mensal: 0, doze: 0, geral: 0, ano: 0 },
                ibovespa        : { mensal: 0, doze: 0, geral: 0, ano: 0 }
            }
        };
        this.termino            = data && data.termino ||
            new Date().toISOString().substring(0, 10);
        this.periodo            = data && data.periodo || '';
        this.gustate            = data && data.gustate || false;
        this.bound              = data && data.bound;
    }
}

export default class CalculadorAtivo {
    //#region Props

    _props              = {
        _cdi            : [],
        _igpm           : [],
        _ipca           : [],
        _dolar          : [],
        _bolsa          : [],
        _iof            : [],
        _ir             : [],
        _cdiCall        : () => [],
        _igpmCall       : () => [],
        _ipcaCall       : () => [],
        _dolarCall      : () => [],
        _bolsaCall      : () => [],
        _iofCall        : () => [],
        _irCall         : () => []
    };

    get cdi  () { return this._props._cdi;    }
    get igpm () { return this._props._igpm;   }
    get ipca () { return this._props._ipca;   }
    get dolar() { return this._props._dolar;  }
    get bolsa() { return this._props._bolsa;  }
    get iof  () { return this._props._iof;    }
    get ir   () { return this._props._ir;     }

    //#endregion

    //#region Loaders

    setCdiLoader(f) {
        this._props._cdiCall = f;
        return this;
    }
    setIgpmLoader(f) {
        this._props._igpmCall = f;
        return this;
    }
    setIpcaLoader(f) {
        this._props._ipcaCall = f;
        return this;
    }
    setDolarLoader(f) {
        this._props._dolarCall = f;
        return this;
    }
    setBolsaLoader(f) {
        this._props._bolsaCall = f;
        return this;
    }
    setIofLoader(f) {
        this._props._iofCall = f;
        return this;
    }
    setIrLoader(f) {
        this._props._irCall = f;
        return this;
    }
    setDefaultLoaders () {
        let store = window && window['localStorage'];
        return this
            .setCdiLoader(()   => JSON.parse(store.getItem('CDI')))
            .setIpcaLoader(()  => JSON.parse(store.getItem('IPCA')))
            .setIgpmLoader(()  => JSON.parse(store.getItem('IGPM')))
            .setDolarLoader(() => JSON.parse(store.getItem('DOLAR')))
            .setBolsaLoader(() => JSON.parse(store.getItem('IBOVESPA')))
            .setIofLoader(()   => DEFAULT_IOF)
            .setIrLoader(()    => DEFAULT_IR);
    }
    refresh() {
        ["cdi", "ipca", "igpm", "dolar", "iof", "ir", "bolsa"].forEach((n) => {
            let call = this._props[`_${n}Call`];
            this._props[`_${n}`] = call && call() || [];
        });
        return this;
    }

    //#endregion

    //#region Format

    dateToLocaleMonth (d) {
        let year  = +d.substring(0, 4);
        let month = (+d.substring(5, 7)) - 1;
        return `${MONTH_ABBREV[month]}/${year}`;
    }
    dateFormat(d) {
        let year  = d.substring(0, 4);
        let month = d.substring(5, 7);
        let day   = d.substring(8, 10);
        return `${day}/${month}/${year}`;
    }
    numberToLocale(val) {
        return val.toLocaleString(DEFAULT_LOCALE, NUMBER_FORMAT);
    }
    valorInPercent(val) {
        return val.toLocaleString(DEFAULT_LOCALE, NUMBER_FORMAT) + " %";
    }
    formatQuota(val) {
        return val.toLocaleString(DEFAULT_LOCALE, _FORMAT);
    }

    //#endregion

    //#region Utility

    currentDate = () => formatISO(new Date().toISOString());
    echo = function () {
        //window["console"]["log"]([].slice.apply(arguments));
    }

    isVoid(v) { return v === null || v === undefined; }
    isNumber(v) { return (typeof v === 'number') && !Number.isNaN(v) && Number.isFinite(v); }
    calculaIOF(ativo, dias, opDate) {
        let idTProduto = ativo && ativo.idTProduto || 0;
        switch (idTProduto) {
            case 1 :  // CDB/RDB
            case 3 :  // DEBÊNTURES
            case 5 :  // TÍTULOS PÚBLICOS
            case 8 :  // FUNDOS DE INVESTIMENTOS & PREVIDÊNCIA
            case 13:  // OUTROS
              let src = this.iof.filter((q) =>
                       this.isVoid(q.start)
                  ||   (q.start <= opDate && q.finish <= opDate));
              let iof = src.length ? src[0].rates : [];
              return this.isNumber(dias) && (dias >= 0) && (dias < iof.length)
                ? iof[dias]
                : 0;
            default: return 0;
        }
    }
    calculaIR(ativo, dias, opDate) {
        let idTProduto = ativo && ativo.idTProduto || 0;
        switch (idTProduto) {
            case  2: // CRI
            case 10: // CRA
            case  4: return 0; // LCI/LCA
            case  6:
            case 11: return STOCK_IR_RATE; // AÇÕES
            case  7: return FUNDS_IR_RATE; // FUNDOS IMOBILIÁRIOS & PREVIDÊNCIA
            default:
            if ((ativo.classeAmbima === 'Fundo de Ações') && (idTProduto === 8)) {
                return STOCK_IR_RATE;
            }
            else {
                let year  = opDate.substring(0, 4);
                let src   = this.ir.filter((q) => this.isVoid(q.year) || q.year === year);
                let ir    = src.length && src[0].rates || [];
                let match = ir.filter((r) => this.isNumber(dias) ? dias <= r.days : this.isVoid(r.days));
                let taxa = match.length ? match[0].rate
                : ir.length ? ir[ir.length - 1].rate
                : 0.0;
                if ((ativo.formaTributacao === 'N') && (idTProduto === 8) && (taxa < STOCK_IR_RATE)) {
                    return FUNDS_IR_RATE;
                } else {return taxa;}
            }
        }
    }
    calculaGrossUp (ativo, dias, opDate) {
        let idTProduto = ativo && ativo.idTProduto || 0;
        switch (idTProduto) {
            case  2:
            case  4:
            case 10:
            case  8:
                if ((idTProduto != 8)  || ((idTProduto == 8) && (ativo.tipo == 'FIDEBICENT'))  )
                {
                    let year  = opDate.substring(0, 4);
                    let src   = this.ir.filter((q) => this.isVoid(q.year) || q.year === year);
                    let ir    = src.length && src[0].rates || [];
                    let match = ir.filter((r) => this.isNumber(dias) ? dias <= r.days : this.isVoid(r.days));
                    let taxa = match.length ? match[0].rate :
                        ir.length ? ir[ir.length - 1].rate :
                        0.0;
                    return 1/(1 - taxa);
                }
                else  return undefined;
        }
        return undefined;
    }
    getIndexador(tipo) {
        return tipo === 'CDI'      ? this.cdi
        :      tipo === 'DOLAR'    ? this.dolar
        :      tipo === 'IBOVESPA' ? this.bolsa
        :      tipo !== 'IGP-M'    ? this.ipca
        :      this.igpm;
    }
    calculaCotacao(ativo, dia) {
        let result = 1.0;
        let src    = this.getIndexador(ativo.indexador);
        let day    = dia.substring(0, 7);
        src
            .filter((listF) => listF.data.substring(0, 7) === day)
            .forEach((i) => {
                if (ativo.indexador === 'IPCA') {
                    result = (i.valor / 100.0) + 1.0;
                } else {
                    result = i.valor + 1.0;
                }
            });
        return this.isNumber(result) ? result : 1.0;
    }
    calculaCotacaoMes(ativo, comp, addFirst, addPrevious) {
        let cotacoes = this.getIndexador(comp);
        let mes      = "00";
        let start    = formatISO(ativo.dataInvestimento);
        let stop     = formatISO(ativo.vencimento);
        let atvqts   = filterQuotes(ativo.cotacoes, start, stop, addPrevious);
        if (atvqts.length > 0) { stop = atvqts[atvqts.length - 1].data; }

        let prev     = undefined;
        switch (comp) {
            case "CDI":
                let fatCorrAcuCDI    = 1;
                let fatCorrAcuCDIMes = 1;
                let CDI              = [];
                let cdiData          = filterQuotes(cotacoes, start, stop, true);
                cdiData
                    .every((q, i) => {
                        if ((q.data === ativo.vencimento) && (ativo.indexador === 'CDI')) {
                            return false;    // ÚLtimo dia não calcula
                        }
                        fatCorrAcuCDI *= ((q.valor / 100.0) + 1.0);
                        let dta = q.data.substring(5, 7);
                        if (mes === "00") {
                            mes = dta;
                        } else if (mes !== dta) {
                            mes                = dta;
                            prev.fatCorrAcuMes = (prev.fatCorrAcu / fatCorrAcuCDIMes);
                            prev.percent       = ((100.0 * prev.fatCorrAcu / fatCorrAcuCDIMes) - 100.0);
                            fatCorrAcuCDIMes   = prev.fatCorrAcu;
                            if (!prev.fimMes) {
                                prev.fimMes    = true;
                                CDI.push(prev);
                            }
                        }
                        prev = {
                            data             : q.data,
                            idAtivo          : q.idAtivo,
                            idAtivoNavigation: q.idAtivoNavigation,
                            idCotacao        : q.idCotacao,
                            tipo             : q.tipo,
                            valor            : q.valor,
                            fatCorrAcu       : fatCorrAcuCDI,
                            fimMes           : !!addFirst && (i === 0),
                            fatCorrAcuMes    : 0,
                            percent          : 0
                        };
                        if (prev.fimMes) { CDI.push(prev); }
                        return true;
                    });
                if (prev) {
                    prev.fatCorrAcuMes  = prev.fatCorrAcu / fatCorrAcuCDIMes;
                    prev.percent        = ((100.0 * prev.fatCorrAcu / fatCorrAcuCDIMes) - 100.0);
                    if (!prev.fimMes) {
                        prev.fimMes     = true;
                        CDI.push(prev);
                    }
                }
                return CDI;

            case "DOLAR":
            case "IBOVESPA":
                let valorDolarMes   = 1;
                let DOLAR           = [];
                let first           = undefined;
                let vslice          = filterQuotes(cotacoes, start, stop, true);
                vslice //.slice(0, vslice.length - 1)
                    .forEach((q, i) => {
                        let dta = q.data.substring(5, 7);
                        if (mes === "00") {
                            mes = dta;
                            valorDolarMes = q.valor;
                        } else if (mes !== dta) {
                            mes             = dta;
                            prev.percent    = (prev.valor / valorDolarMes) - 1.0;
                            valorDolarMes   = prev.fatCorrAcu;
                            if (!prev.fimMes) {
                                prev.fimMes = true;
                                DOLAR.push(prev);
                            }
                        }
                        prev    = {
                            data             : q.data,
                            idAtivo          : q.idAtivo,
                            idAtivoNavigation: q.idAtivoNavigation,
                            idCotacao        : q.idCotacao,
                            tipo             : q.tipo,
                            valor            : q.valor,
                            fatCorrAcu       : q.valor,
                            fimMes           : !!addFirst && (i === 0),
                            fatCorrAcuMes    : 0,
                            percent          : q.valor
                        };
                        if (!first) { first = prev; }
                        if (prev.fimMes) { DOLAR.push(prev); }
                    });
                if (prev) {
                    prev.percent    = (prev.valor / valorDolarMes) - 1.0;
                    //prev.fatCorrAcu = ((prev.valor / first.valor) - 1.0);
                    if (!prev.fimMes) {
                        prev.fimMes = true;
                        DOLAR.push(prev);
                    }
                }
                return DOLAR;

            case "IPCA":
                let diasMes     = 0;
                let IPCA        = [];
                let _fatCorrAcuCDI = 1;
                let startday    = +(start.substring(8, 10));
                let stopday     = +(stop.substring(8, 10));
                let _start      = addDays(start, 1 - startday);
                let _stop       = addMonths(addDays(stop, -stopday), 1);

                let ipcaData    = filterQuotes(cotacoes, _start, _stop);
                let _stopmonth  = stop.substring(0, 7);
                var _datamonth  = ipcaData.length ?
                    ipcaData[ipcaData.length - 1].data.substring(0, 7) :
                    _stopmonth;
                ipcaData //.slice(0, ipcaData.length - 1)
                    .forEach((q, i) => {
                        diasMes = +(addMonths(addDays(q.data, -1), 1).substring(8, 10).replace(/^0+/g, ''));
                        _fatCorrAcuCDI = ((((i === 0) && (startday < diasMes)
                            ? (q.valor / diasMes) * (diasMes - startday+1)
                            : q.valor) / 100.0) + 1.0) * _fatCorrAcuCDI;
                        IPCA.push({
                            data             : q.data,
                            idAtivo          : q.idAtivo,
                            idAtivoNavigation: q.idAtivoNavigation,
                            idCotacao        : q.idCotacao,
                            tipo             : q.tipo,
                            valor            : q.valor,
                            fatCorrAcu       : _fatCorrAcuCDI,
                            fimMes           : true,
                            fatCorrAcuMes    : 0,
                            percent          : q.valor
                        });
                    });
                if ((stopday < diasMes) && (_stopmonth === _datamonth)) { //VSC este calculo somente é valido se for no mesmo mês
                    let size = IPCA.length - 1;
                    IPCA[size].percent = ativo.idTProduto === 1 || ativo.idTProduto === 4 ?
                        (IPCA[size].percent/diasMes)*(stopday-1) :
                        (IPCA[size].percent/diasMes)*stopday;
                }
                return IPCA;

            default:
                return cotacoes;
        }
    }
    calculaIndice (from, to, indice) {
        const source    = this.getIndexador(indice);
        const start     = formatISO(from);
        const finish    = formatISO(to);
        switch (indice) {
            case 'CDI':
                let cdata   = filterQuotes(source, start, finish);
                let cdi     = [{
                    tstamp  : start,
                    value   : 1 + cdata[0].valor / 100,
                    rate    : cdata.length ? cdata[0].valor / 100 : 0
                }];
                cdata.slice(1).every((q) => {
                    const qdata = formatISO(q.data);
                    /* removido porque não bate com o cálculo presente em dessertsCalc */
                    //if (qdata === finish) { return false; }
                    const prev  = cdi[cdi.length - 1];
                    cdi.push({
                        tstamp  : qdata,
                        value   : prev.value * (1 + q.valor/100),
                        rate    : q.valor / 100
                    });
                    return true;
                });
                return cdi;

            case "DOLAR":
            case "IBOVESPA":
                let qdata = filterQuotes(source, start, finish);
                let index = [{
                    tstamp  : start,
                    value   : 1,
                    rate    : 0
                }];
                const pval  = qdata[0].valor;
                let   mval  = qdata[0].valor;
                qdata.slice(1).forEach((q) => {
                    const tstamp = formatISO(q.data);
                    const rate = q.valor / mval - 1;
                    mval       = q.valor;
                    index.push({
                        tstamp,
                        value   : q.valor / pval,
                        rate
                    });
                });
                return index;

            case "IPCA":
                let sday        = +(start.substring(8, 10));
                let fday        = +(finish.substring(8, 10));
                let left        = addDays(start, 1 - sday);
                let right       = addMonths(addDays(finish, -fday), 1);
                let idata       = filterQuotes(source, left, right);

                let first       = idata[0];
                const dayc      = +(addMonths(addDays(first.data, -1), 1).substring(8, 10));
                const fmul      = sday < dayc ?
                    first.valor - (first.valor * (sday - 1) / dayc) :
                    first.valor;
                let ipca        = [{
                    tstamp      : start,
                    value       : 1 + fmul / 100,
                    rate        : fmul / 100
                }];
                idata.slice(1).forEach((q, i) => {
                    const tstamp = formatISO(q.data);
                    const prev   = ipca[ipca.length - 1];
                    ipca.push({
                        tstamp,
                        value   : prev.value * (1 + q.valor / 100),
                        rate    : q.valor / 100
                    });
                });

                return ipca;

            default:
                return source;
        }
    }
    getLast (arr) {
        return arr && arr.length ? arr[arr.length - 1] : undefined;
    }
    get12Cut(arr, cut) {
        let index = arr && arr.length - 1;
        if (index < 0) { return { fatCorrAcu: 1, valor: 1.0 } }
        index = QUOTEFLT.getBound(arr, cut, true);
        return arr[index];
    }
    getMin(arr) {
        return arr && arr.length ? arr[0] :  { fatCorrAcu: 1, valor: 1.0 };
    }
    getRatesByFact (coll, dlim, d12, dy) {
        let top     = coll[0];
        let bottom  = coll[coll.length - 1];
        if (!coll.length) {
            return { mensal: 0, doze: 0, ano: 0, geral: 0 };
        } else if (coll.length === 1) {
            const rate = top.fatCorrAcu - 1;
            return { mensal: rate, doze: rate, ano: rate, geral: rate };
        }
        let e12     = this.get12Cut(coll, d12);
        let ey      = this.get12Cut(coll, dy);
        let left    = top.data.substring(0, 10);
        return {
            mensal  :   bottom.percent / 100,
            doze    :   left >= d12  ? bottom.fatCorrAcu - 1 :
                        bottom.fatCorrAcu / e12.fatCorrAcu - 1,
            ano     :   left >= dy   ? bottom.fatCorrAcu - 1 :
                        bottom.fatCorrAcu / ey.fatCorrAcu - 1,
            geral   :   left >= dlim ? bottom.fatCorrAcu - 1 :
                        bottom.fatCorrAcu / top.fatCorrAcu - 1
        };
    }
    getRatesByVal  (coll, dlim, d12, dy) {
        let top     = coll[0];
        let bottom  = coll[coll.length - 1];
        if (!coll.length) {
            return { mensal: 0, doze: 0, ano: 0, geral: 0 };
        } else if (coll.length === 1) {
            const rate = top.percent;
            return { mensal: rate, doze: rate, ano: rate, geral: rate };
        }
        let e12     = this.get12Cut(coll, d12);
        let ey      = this.get12Cut(coll, dy);
        let left    = top.data.substring(0, 10);
        return {
            mensal  :   bottom.percent,
            doze    :   left >= d12  ? bottom.valor / top.valor - 1 :
                        bottom.valor / e12.valor - 1,
            ano     :   left >= dy   ? bottom.valor / top.valor - 1 :
                        bottom.valor / ey.valor - 1,
            geral   :   bottom.valor / top.valor - 1
        };
    }
    getEmptyQuotes () {
        return {
            cdi         : { mensal  : 0, doze    : 0, ano     : 0, geral   : 0 },
            ipca        : { mensal  : 0, doze    : 0, ano     : 0, geral   : 0 },
            dolar       : { mensal  : 0, doze    : 0, ano     : 0, geral   : 0 },
            ibovespa    : { mensal  : 0, doze    : 0, ano     : 0, geral   : 0 }
        };
    }
    extractQuotes (calcs, start, limit) {
        const size      = calcs && calcs.length || 0;
        if (size === 0) { return this.getEmptyQuotes(); }

        const ldate     = getDateValue(limit);
        const mdate     = new Date(ldate.getFullYear(), ldate.getMonth(), 1).toISOString().substring(0, 10);
        const cdate     = new Date(ldate.getFullYear() - 1, ldate.getMonth() + 1, 1).toISOString().substring(0, 10);
        const ydate     = new Date(ldate.getFullYear(), 0, 1).toISOString().substring(0, 10);
        const mleft     = CALCFLT.getBound(calcs, mdate, true);
        const mitem     = calcs[mleft].date < mdate ? calcs[mleft] : undefined;
        const cleft     = CALCFLT.getBound(calcs, cdate, true);
        const citem     = calcs[cleft].date < cdate ? calcs[cleft] : undefined;
        const yleft     = CALCFLT.getBound(calcs, ydate, true);
        const yitem     = calcs[yleft].date < ydate ? calcs[yleft] : undefined;

        const last      = calcs[size - 1]
        return {
            cdi         : {
                mensal  : mitem ? last.indexCdi / mitem.indexCdi - 1 : last.indexCdi - 1,
                doze    : citem ? last.indexCdi / citem.indexCdi - 1 : last.indexCdi - 1,
                ano     : yitem ? last.indexCdi / yitem.indexCdi - 1 : last.indexCdi - 1,
                geral   : last.indexCdi - 1
            },
            ipca        : {
                mensal  : mitem ? last.indexIpca / mitem.indexIpca - 1 : last.indexIpca - 1,
                doze    : citem ? last.indexIpca / citem.indexIpca - 1 : last.indexIpca - 1,
                ano     : yitem ? last.indexIpca / yitem.indexIpca - 1 : last.indexIpca - 1,
                geral   : last.indexIpca - 1
            },
            dolar       : {
                mensal  : mitem ? last.indexDolar / mitem.indexDolar - 1 : last.indexDolar - 1,
                doze    : citem ? last.indexDolar / citem.indexDolar - 1 : last.indexDolar - 1,
                ano     : yitem ? last.indexDolar / yitem.indexDolar - 1 : last.indexDolar - 1,
                geral   : last.indexDolar - 1
            },
            ibovespa    : {
                mensal  : mitem ? last.indexBolsa / mitem.indexBolsa - 1 : last.indexBolsa - 1,
                doze    : citem ? last.indexBolsa / citem.indexBolsa - 1 : last.indexBolsa - 1,
                ano     : yitem ? last.indexBolsa / yitem.indexBolsa - 1 : last.indexBolsa - 1,
                geral   : last.indexBolsa - 1
            }
        };
    }
    extractGenericQuotes (start, finish, bound) {
        if (!this.cdi.length) {
            return {
                cdi         : { mensal: 0, doze: 0, ano: 0, geral: 0 },
                ipca        : { mensal: 0, doze: 0, ano: 0, geral: 0 },
                dolar       : { mensal: 0, doze: 0, ano: 0, geral: 0 },
                ibovespa    : { mensal: 0, doze: 0, ano: 0, geral: 0 }
            };
        }
        const fmt   = (v) => v && v.length <= 10 ? v + 'T00:00:00' : v;
        const left  = fmt(start);
        const right = fmt(finish);
        const lpt   = QUOTEFLT.getBound(this.cdi, left, true);
        const fdat  = this.cdi[lpt + 1].data;
        const ldat  = bound ? right : undefined;
        const fcal  = this.calcular({
            ativoId             : 0,
            idTProduto          : 4,
            idEstrategia        : 2,
            percentege          : 1,
            liquidez            : 0,
            referencia          : 'CDI',
            taxa                : 100,
            fgc                 : true,
            indexador           : 'CDI',
            value               : 100,
            dataInvestimento    : fdat,
            vencimento          : ldat,
            movimentos          : [{
                id              : 0,
                investedValue   : 100,
                shareQuantity   : 0,
                shareValue      : 0,
                investmentDate  : fdat,
                tpMovimento     : 1,
                custodianteId   : 4
            }],
            cotacoes            : [],
            eventos             : []
        });
        return this.extractQuotes(fcal.dessertsCalc, left, right);
    }

    //#endregion

    //#region Original

    calcularCdb(ativo, brief) {
        let result = {
            dessertsCalc: [],
            desserts: [],
            currentAtivoMovimento :[]
        };
        let itemDessert = {};
        let itemDessertCalc = {};

        let mes = 0;
        let _datainicial = '';

        let _fatCorrAcu = 1;
        let _fatCorrAcuant = 1;
        let _fatCorrMensal = 1;
        let _fatorcorrinicial = 1;
        let _fatCorrIGPM = 1;
        let _redCapAcu = 0;
        let _investedValue = 0;
        let _saldoAnt = 0;
        let _carbsAnt = 0;
        let _ironAnt = 1;
        let _date;
        let redResgAcum = 0;

        let _saldo = 0;
        let dta;
        let _start;
        let _stop;
        let _days;
        let _isdebit = 0;
        let _debitvalue = 0;
        let _debitacumulated = 0;
        let _calories = 0;
        let _calories2 = 0;
        let _fat = 0;
        let _protein = 0;
        let _carbs = 0;
        let _iron = 0;
        let _iof = 0;
        let _ir = 0;
        let _saldoLiquido = 0;
        let _vencimento = formatISO(ativo.vencimento);
        let _investimento = formatISO(ativo.dataInvestimento);
        let stopped = false;
        let movimentacao = undefined;
        let grossup = undefined;
        let grossupcalories = undefined;
        let indexCdi = 1;
        let indexBolsa = 1;
        let indexDolar = 1;
        let indexIpca = 1;
        let indexIpcaant = 1;
        let indexcotacaobolsa = 0;
        let indexcotacaodolar = 0;
        let indexcotacaoipca = 0;
        let _bolsa = filterQuotes(this.bolsa, _investimento, _vencimento);
        let _dolar = filterQuotes(this.dolar, _investimento, _vencimento);
        let idt = new Date(ativo.dataInvestimento);
        let firstidt = formatISO(new Date(idt.getFullYear() , idt.getMonth(), 1).toISOString());
        let _ipca  = filterQuotes(this.ipca,firstidt, _vencimento);

        result.currentAtivoMovimento = this.mountMovimentos(ativo);
        let fats = ativo.idTProduto === 1 || ativo.idTProduto === 4
            ? this.cdi
            : ativo.cotacoes;
        let cotacao = filterQuotes(fats, _investimento, _vencimento);
        if (cotacao.length <= 0) {
            // Se a cotação do cdi não existir encerra o calculo
            stopped = true;
            brief   = true;
        } else {
            cotacao.every((list, idx) => {
                dta         = new Date(list.data).getMonth()+1;
                _date       = this.dateFormat(list.data);
                indexCdi   *= (1 + (Number(list.valor) / 100));
                if (_ipca[indexcotacaoipca] )
                {
                    indexIpca =  indexcotacaoipca == 0 ?
                        indexIpcaant *  (1 + (Number((_ipca[indexcotacaoipca].valor/30)*(diffAsDays(_investimento, list.data)+1)) / 100)) :
                        indexIpcaant *  (1 + (Number((_ipca[indexcotacaoipca].valor/30)*(diffAsDays(_ipca[indexcotacaoipca].data, list.data)+1)) / 100));
                }
                if (_bolsa[indexcotacaobolsa] && (list.data == _bolsa[indexcotacaobolsa].data))
                {
                    indexBolsa =   _bolsa[indexcotacaobolsa].valor / _bolsa[0].valor;
                    ++indexcotacaobolsa;
                }
                if (_dolar[indexcotacaodolar] && (list.data == _dolar[indexcotacaodolar].data))
                {
                    indexDolar =   _dolar[indexcotacaodolar].valor / _dolar[0].valor;
                    ++indexcotacaodolar;
                }
                if ((ativo.idTProduto === 1) || (ativo.idTProduto === 4)) {
                    if (ativo.indexador === 'CDI') {
                        _fatCorrAcu *= (1 + ((Number(list.valor) * Number(ativo.taxa / 100)) / 100));
//                        if (formatISO(list.data) === _vencimento) {
//                            return false;  // Último dia não calcula
//                        }
                    } else if (ativo.indexador === 'PRÉ') {
                        _fatCorrAcu = Math.pow(Math.pow(((ativo.taxa / 100) + 1), (1 / 252)), (idx + 1));
                    } else {
                        if (dta !== mes) {
                            _fatCorrIGPM *= this.calculaCotacao(ativo, list.data);
                        }
                        if (this.isVoid(_fatCorrIGPM)) { _fatCorrIGPM = 1; }
                        _fatCorrAcu = Math.pow(
                                Math.pow(((ativo.taxa / 100) + 1), (1 / 252)), (idx + 1))
                            * _fatCorrIGPM;
                    }
                }
                if (idx === 0) { _datainicial = list.data; }

                let movimentos = ativo.movimentos.filter(m => m.investmentDate === list.data);
                if (idx === 0) {
                    if (movimentos.length <= 0) {
                            // Se o movimento inicial não existir encerra o calculo
                            brief = true;
                            stopped  = true;
                            return false;
                    } else {
                        mes = dta;
                        _datainicial = list.data;
                    }
                }
                if ((dta !== mes) || !this.isVoid(itemDessertCalc.movimentacao)) {
                    if (!result.dessertsCalc.length) {
                        itemDessertCalc.capital = ativo.movimentos[0].investedValue;
                    }
                }
                movimentacao = undefined;
                if (dta !== mes) {
                    (!brief) && result.desserts.push(itemDessert);
                    itemDessertCalc.isendOfMonth = true;
                    _carbsAnt = itemDessertCalc.carbs;
                    _ironAnt = itemDessertCalc.iron;
                    _saldoAnt = itemDessertCalc.calories;
                    _fatCorrMensal = _fatCorrAcuant;
                    if (_ipca[indexcotacaoipca] )
                    {
                        indexIpca =  indexcotacaoipca == 0 ?
                            indexIpcaant *  (1 + (Number((_ipca[indexcotacaoipca].valor/30)*(diffAsDays(_investimento, itemDessertCalc.date)+1)) / 100)) :
                            indexIpcaant *  (1 + (Number(_ipca[indexcotacaoipca].valor)) / 100);
                        indexIpcaant = indexIpca;
                        ++indexcotacaoipca;
                    }
                }
                if (itemDessertCalc.name) {
                    result.dessertsCalc.push(itemDessertCalc);
                }
                _debitvalue = 0;
                movimentos.filter(m => m.investmentDate === list.data)
                    .forEach((i) => {
                        if (i.tpMovimento === 1) { _calories2 = i.investedValue}
                        if (i.tpMovimento === 3) {
                            _isdebit = 1;
                            _debitvalue += i.investedValue;
                        } else if ((i.tpMovimento === 1) && (_datainicial === i.investmentDate)) {
                            mes = dta;
                            _investedValue = i.investedValue;
                            _saldoAnt = _investedValue;
                            _fatorcorrinicial = _fatCorrAcu;
                            _fatCorrMensal = 1;
                        }
                        if ((i.tpMovimento > 0) && (i.tpMovimento < 4)) {
                            if (this.isVoid(movimentacao)) { movimentacao = 0; }
                            movimentacao += i.investedValue;
                        }
                        if (i.tpMovimento == 8) {
                            if (this.isVoid(movimentacao)) { movimentacao = 0; }
                            _isdebit = 1;
                            result.currentAtivoMovimento.filter(cm => cm.investmentDate === list.data && cm.tpMovimentoId === 8 && _saldo > 0.01)
                            .forEach((j) =>
                            { j.investedValue = -_saldo; });
                            i.investedValue = -_saldo;
                            movimentacao -= _saldo;
                            _debitvalue -= _saldo;
                        }
                    });

                if (_isdebit === 1) {
                    _date = this.dateFormat(list.data);
                    _start = _datainicial;
                    _stop = list.data;
                    _days = diffAsDays(_start, _stop);
                    if ((- _debitvalue - (-_debitvalue / (_fatCorrAcuant / _fatorcorrinicial))) > 0) {
                        _iof = (- _debitvalue - (-_debitvalue / (_fatCorrAcuant / _fatorcorrinicial))) * this.calculaIOF(ativo, _days, _stop);
                        _ir = ((- _debitvalue - (-_debitvalue / (_fatCorrAcuant / _fatorcorrinicial))) - _iof) * this.calculaIR(ativo, _days, _stop);
                        grossup = this.calculaGrossUp(ativo, _days, _stop);
                    } else {
                        _iof = 0;
                        _ir  = 0;
                        grossup = undefined;
                        grossupcalories = undefined;
                    }
                    redResgAcum += _debitvalue / (_fatCorrAcuant) - _debitvalue;
                    _saldo += _debitvalue;
                    _saldo = _saldo < 0.009999 ? 0: _saldo;
                    _debitacumulated += _debitvalue;
                    _redCapAcu = _saldo - (_investedValue + _debitacumulated);
                    _calories = _saldo;
                    _fat = _saldo - _saldoAnt - _debitvalue + _iof + _ir;
                    _carbs = _redCapAcu;
                    if (idx !== 0) {
                        _protein = (_fatCorrAcu / _fatCorrMensal) - 1;
                        _iron = (_fatCorrAcu - 1) * 100;
                    }
                    _saldoAnt = _saldo;
                    _fatCorrMensal = _fatCorrAcuant;
                    _isdebit = 0;
                    _iof = 0;
                    _ir = 0;
                }
                _saldo = (_saldoAnt) * (_fatCorrAcu / _fatCorrMensal);
                _redCapAcu = _saldo - (_investedValue + _debitacumulated);
                _start = _datainicial;
                _stop = list.data;
                _days = diffAsDays(_start, _stop);

                if  (ativo.indexador === 'CDI') {
                    if (((idx + 1) === cotacao.length) || ((idx + 2) === cotacao.length)) {
                        if (cotacao[idx].data < _vencimento) { _days += 1; }
                    }
                }
                if (_days === 0) { _days = 1 }
                if ((_redCapAcu-redResgAcum) > 0) {
                    _iof =  (_redCapAcu - redResgAcum) * this.calculaIOF(ativo, _days, _stop);
                    _ir =   (_redCapAcu - redResgAcum - _iof) * this.calculaIR(ativo, _days + 1, _stop);
                    grossup = this.calculaGrossUp(ativo, _days + 1, _stop);
                } else {
                    grossup = undefined;
                    grossupcalories = undefined;
                }
                _calories = _saldo;
                _calories2 = _calories2 == 0 ? _calories : _calories2;
                if (!this.isVoid(grossup)) {
                    grossupcalories = (_saldoAnt) * ((((_fatCorrAcu-1) * grossup) + 1) / _fatCorrMensal) + _debitvalue - _iof - _ir;
                }
                _fat = _saldo - _investedValue - _debitacumulated - _carbsAnt;
                _carbs = _saldo - _investedValue - _debitacumulated;
                if (formatISO(list.data) === _vencimento || _saldo <= 0.01)
                {
                    _saldo = 0;
                    _iof = 0;
                    _ir = 0;
                    _calories = 0;
                }
                _iron = _saldo > 0  ? (_fatCorrAcu) : _fatCorrAcuant;
                _protein =  ((_iron / _ironAnt) - 1) * 100;
                _saldoLiquido = _saldo - _iof - _ir;
//                indexCdi /= _calories > 0 ? 1 :   (1 + (Number(list.valor) / 100));
                indexBolsa /= (_calories > 0) ||  !_bolsa[indexcotacaobolsa-1] ? 1 :   _bolsa[indexcotacaobolsa-1].valor / _bolsa[0].valor;
                indexDolar /= (_calories > 0) ||  !_dolar[indexcotacaodolar-1] ? 1 :   _dolar[indexcotacaodolar-1].valor / _dolar[0].valor;
                indexIpca = (_calories > 0) ||  !_ipca[indexcotacaoipca] ? indexIpca :
                indexcotacaoipca == 0 ?
                indexIpcaant *  (1 + (Number((_ipca[indexcotacaoipca].valor/30)*(diffAsDays(_investimento, list.data))) / 100)) :
                indexIpcaant *  (1 + (Number((_ipca[indexcotacaoipca].valor/30)*(diffAsDays(_ipca[indexcotacaoipca].data, list.data))) / 100));
                (!brief) && (itemDessert = {
                    name: _date, calories: this.numberToLocale(_calories),
                    fat: this.numberToLocale(_fat), protein: this.valorInPercent(_protein),
                    carbs: this.numberToLocale(_carbs), iron: this.valorInPercent((_iron - 1) * 100),
                    iof: this.numberToLocale(_iof), ir: this.numberToLocale(_ir),
                    saldoLiquido: this.numberToLocale(_saldoLiquido) , quotas : 0
                });
                itemDessertCalc = {
                    name: _date, calories: _calories, fat: _fat, protein: _protein,
                    calories2: _calories2, // 20230315
                    carbs: _carbs, iron: _iron, iof: _iof, ir: _ir, saldoLiquido: _saldoLiquido,
                    date: list.data, countItens: idx, cotacaoinicial: 1, cotacao: _fatCorrAcu, cotacaoant: _fatCorrAcuant,
                    quotas : 0, investedValue : 0, shareQuantity : 0, isendOfMonth : false,
                    movimentacao, indexCdi, indexBolsa, indexDolar, indexIpca
                };
                if (!this.isVoid(grossupcalories)) {
                    itemDessertCalc.grossupcalories = grossupcalories;
                    itemDessertCalc.grossup = grossup;
                    grossup = undefined;
                    grossupcalories = undefined;
                }
                mes = dta;
                _calories2 = 0;
                _fatCorrAcuant = _fatCorrAcu;
                if (_saldo <= 0) {return false;} else return true;
            });
        }
        if (!brief) {
            result.desserts.push(itemDessert);
            result.currentAtivoMovimento = this.formatMovimentos(result.currentAtivoMovimento, false);
        }
        if (!stopped) { result.dessertsCalc.push(itemDessertCalc); }
        return result;
    }
    calcularOutros(ativo, brief, result, ativoMovimentos, dataInvestimento, idx2) {
        let itemDessert = {};
        let itemDessertCalc = {};
        let mes=0;
        let _datainicial = '';
        let _fatCorrAcu = 1;
        let _fatCorrAcuant = 1;
        let _fatorcorrinicial = 1;
        let _redCapAcu = 0;
        let _investedValue = 0;
        let _carbsAnt = 0;
        let _date;
        let _saldo = 0;
        let dta;
        let _start;
        let _stop;
        let _days;
        let _debitvalue = 0;
        let _debitshareQuantity = 0;
        let _debitsharevalue = 0;
        let _debitacumulated = 0;
        let _calories = 0;
        let _calories2 = 0; // 20230315
        let _fat = 0;
        let _protein = 1;
        let _carbs = 0;
        let _iron = 0;
        let _iof = 0;
        let _ir = 0;
        let _saldoLiquido = 0;
        let _shareQuantity = 0;
        let _sharevalue = 0;
        let _shareacumulated = 0;
        let _shareComecotas = 0;
        let stopped = true;
        let datacomecotas = null;
        let comecotas = [];
        let comecotasini = 0;
        let _investimento   = formatISO(dataInvestimento);
        let _vencimento     = formatISO(ativo.vencimento);
        let movimentacao   = undefined;
        let _JCP = 0;
        let _JCPDay = 0;
        let grossup = undefined;
        let grossupcalories = undefined;
        let _cotacao = 0;
        let indexcotacao = 0;
        let indexcotacaobolsa = 0;
        let indexcotacaodolar = 0;
        let indexcotacaoipca = 0;
        let AtivoGeral = filterQuotes(ativo.cotacoes, _investimento, _vencimento)
        let _finishcot = false;
        let indexCdi = 1;
        let indexBolsa = 1;
        let indexDolar = 1;
        let indexIpca = 1;
        let indexIpcaant = 1;
        let fats =  this.cdi;
        let _bolsa = filterQuotes(this.bolsa, _investimento, _vencimento);
        let _dolar = filterQuotes(this.dolar, _investimento, _vencimento);
        let idt = new Date(ativo.dataInvestimento);
        let firstidt = formatISO(new Date(idt.getFullYear() , idt.getMonth(), 1).toISOString());
        let _ipca  = filterQuotes(this.ipca,firstidt, _vencimento);
        let _desvio = 0;
        let runnig = true;
        filterQuotes(fats, _investimento, _vencimento)
            .every((list, idx) => {
                dta         = new Date(list.data).getMonth() + 1;
                _date       = this.dateFormat(list.data);
                indexCdi   *= (1 + (Number(list.valor) / 100));
                if (_ipca[indexcotacaoipca] )
                {
                    indexIpca =  indexcotacaoipca == 0 ?
                        indexIpcaant *  (1 + (Number((_ipca[indexcotacaoipca].valor/30)*(diffAsDays(_investimento, list.data)+1)) / 100)) :
                        indexIpcaant *  (1 + (Number((_ipca[indexcotacaoipca].valor/30)*(diffAsDays(_ipca[indexcotacaoipca].data, list.data)+1)) / 100));
                }
                if (_bolsa[indexcotacaobolsa] && (list.data == _bolsa[indexcotacaobolsa].data))
                {
                    indexBolsa =   _bolsa[indexcotacaobolsa].valor / _bolsa[0].valor;
                    ++indexcotacaobolsa;
                }
                if (_dolar[indexcotacaodolar] && (list.data == _dolar[indexcotacaodolar].data))
                {
                    indexDolar =   _dolar[indexcotacaodolar].valor / _dolar[0].valor;
                    ++indexcotacaodolar;
                }
                if (AtivoGeral[indexcotacao] && (list.data == AtivoGeral[indexcotacao].data))
                {
                    _fatCorrAcu =   AtivoGeral[indexcotacao].valor;
                    ++indexcotacao;
                }
                let movimentos = ativoMovimentos.filter(m => m.investmentDate === list.data);
                if (idx === 0) {
                    if (movimentos.length <= 0) {
                            // Se a cotação não existir encerra o calculo
                            brief = true;
                            stopped  = true;
                            return false;
                    } else {
                        mes = dta; _datainicial = list.data; stopped = false;
                    }
                }
                if ((dta !== mes) || !this.isVoid(itemDessertCalc.movimentacao)) {
                    if (!result.dessertsCalc.length) {
                        itemDessertCalc.capital = ativo.movimentos[0].investedValue;
                    }
                }
                movimentacao = undefined;
                if (dta !== mes) {
                    if  (!brief) {this.dessertsPush(result.desserts, itemDessert, result.dessertsCalc);}
                    itemDessertCalc.isendOfMonth = true;
                    //_JCPDay = 0;
                    //_protein = 1;
                    _carbsAnt = itemDessertCalc.carbs;
                    if (_ipca[indexcotacaoipca] )
                    {
                        indexIpca =  indexcotacaoipca == 0 ?
                            indexIpcaant *  (1 + (Number((_ipca[indexcotacaoipca].valor/30)*(diffAsDays(_investimento, itemDessertCalc.date)+1)) / 100)) :
                            indexIpcaant *  (1 + (Number(_ipca[indexcotacaoipca].valor)) / 100);
                        indexIpcaant = indexIpca;
                        ++indexcotacaoipca;
                    }
                }
                _debitsharevalue = 0;
                _debitshareQuantity = 0;
                movimentos.forEach((i) => {
                    switch (i.tpMovimento) {
                        case 1:
                        case 2:
                            if (_datainicial === i.investmentDate) {
                                _shareQuantity = i.shareQuantity;
                                _investedValue = i.investedValue;
                                _sharevalue = _investedValue /  _shareQuantity;
                                _fatorcorrinicial = i.shareValue ? i.shareValue :  _sharevalue;
                                if (_fatCorrAcu == 1) {_fatCorrAcu = _fatorcorrinicial}
                                _fatCorrAcuant = _fatorcorrinicial;
                                if (this.isVoid(movimentacao)) { movimentacao = 0; }
                                movimentacao += i.investedValue;
                                _calories2 = _investedValue; // 20230315
                            };
                            break;
                        case 3:
                            if (((_shareQuantity + _shareacumulated + _shareComecotas) <= 0.01) || (i.participacao <= 0.01))  {break;}
                            if  (i.participacao > (_shareQuantity + _shareacumulated + _shareComecotas))
                            {
                                if (this.isVoid(movimentacao)) { movimentacao = 0; }
                                movimentacao -= (_shareQuantity + _shareacumulated + _shareComecotas) * i.investedValue/i.shareQuantity;
                                i.participacao -= (_shareQuantity + _shareacumulated + _shareComecotas);
                                _debitsharevalue += (_shareQuantity + _shareacumulated + _shareComecotas) * i.investedValue/i.shareQuantity;
                                _debitacumulated -= (_shareQuantity + _shareacumulated + _shareComecotas) * i.investedValue/i.shareQuantity;
                                _debitshareQuantity += (_shareQuantity + _shareacumulated + _shareComecotas);
                                _shareacumulated -= (_shareQuantity + _shareacumulated + _shareComecotas);
                            }
                            else
                            {
                                if (this.isVoid(movimentacao)) { movimentacao = 0; }
                                movimentacao -= i.participacao * i.investedValue /i.shareQuantity;
                                _debitacumulated -= i.participacao * i.investedValue /i.shareQuantity;
                                _debitsharevalue += i.participacao * i.investedValue /i.shareQuantity;
                                _shareacumulated -= i.participacao;
                                _debitshareQuantity += i.participacao;
                                i.participacao = 0;
                            }
                            if (comecotas && comecotas.length > 0) {
                                /* Se houver comecotas a retirada tem que diminuir o comecotas */
                                 this.getComecotas (i.investmentDate, comecotas, i.shareQuantity, true, 0);
                            }
                            break;
                        }
                    })

                    if ((ativo.idTProduto === 8) && ( ativo.formaTributacao != 'ISENTO') &&
                       (ativo.classeAmbima !== 'Fundo de Ações') && (ativo.tipo !== 'FIPREV') && (ativo.tipo !== 'FIDEBICENT')) {
                        if ((dta === 5) || (dta === 11)) {
                            let _ListDays =  filterQuotes(ativo.cotacoes, list.data, _vencimento);
                            let _ActualMonth = list.data.substring(5,7);
                            let _NextMonth   = _ListDays && _ListDays.length > 1 ? _ListDays[1].data.substring(5,7) : _ActualMonth;
                            if (_NextMonth != _ActualMonth) {
                                /*  comecotas somente é calculado nos meses 5 e 11 para alguns fundos de investimento */
                                var datacomecotasLast = this.getLast (ativo.cotacoes.filter(a =>
                                    ((new Date(a.data).getFullYear() ===  new Date(list.data).getFullYear())  &&
                                    (new Date(a.data).getMonth() ===  new Date(list.data).getMonth() ))
                                    ));
                                datacomecotas = !datacomecotas && datacomecotasLast ? datacomecotasLast.data : datacomecotas;
                                if  (datacomecotas === list.data) {
                                    comecotasini = comecotas && comecotas.length > 0  ? this.getLast(comecotas).shareValue : _fatorcorrinicial;
                                    if (_fatCorrAcu > comecotasini) {
                                        _start = _datainicial;
                                        _stop = list.data;
                                        _days = diffAsDays(_start, _stop);
                                        _debitvalue = ((_fatCorrAcu - comecotasini) * (_shareQuantity+_shareacumulated+_shareComecotas));
                                        _iof =  (_debitvalue) * this.calculaIOF(ativo, _days, _stop);
                                        _debitvalue -= _iof;
                                        /* arredondamento criado para aproximar ao cálculo do Itáu */
                                        _debitvalue *= (ativo.formaTributacao === 'S' ? 0.15 : 0.20) * 100;
                                        //  _debitvalue *= 0.15 * 100;
                                        _debitvalue = Math.trunc(_debitvalue) / 100;
                                        /* arredondamento criado para aproximar ao cálculo do Itáu */
                                        _debitshareQuantity += _debitvalue / _fatCorrAcu;
                                        _debitsharevalue += _debitvalue;
                                        this.setComecotas(list.data, _debitvalue / _fatCorrAcu, _fatCorrAcu, comecotas, result.currentAtivoMovimento,_shareQuantity +_shareacumulated+_shareComecotas);
                                        _shareComecotas -= _debitvalue / _fatCorrAcu;
                                        _debitacumulated -= _debitvalue;
                                    }
                                    datacomecotas = null;
                                }
                            }
                        }
                    }
                _saldo = (_shareQuantity + _shareacumulated + _shareComecotas) * (_fatCorrAcu);
                _saldo = _saldo < 0.01 ? 0: _saldo;
                _start = _datainicial;
                _stop  = list.data;
                _days  = diffAsDays(_start, _stop);

                if (_days === 0) { _days = 1 }
                let _fator = comecotas && comecotas.length > 0  ? this.getLast(comecotas).shareValue : _fatorcorrinicial;
                let _redatual = (_shareQuantity + _shareacumulated + _shareComecotas) * (_fatCorrAcu -_fator);
                if ((_redatual > 0) && (_saldo > 0.01) && (ativo.tipo !== 'FIPREV')  && ((ativo.idTProduto !== 8) || ((ativo.idTProduto === 8)  && (_saldo > 0.01) && ((ativo.formaTributacao !== 'ISENTO') || ((ativo.tipo && ativo.tipo === 'FIDEBICENT'))) ))) {
                    if (ativo.tipo && ativo.tipo !== 'FIDEBICENT')
                    {
                        _iof =  (_redatual) * this.calculaIOF(ativo, _days, _stop);
                        _ir  =   (_redatual - _iof) * this.calculaIR(ativo, _days + 1, _stop);
                        if (comecotas && comecotas.length > 0) {
                            /* Se tem comecotas, acrescenta a diferença de imposto não calculada */
                            _fator = this.calculaIR(ativo, _days + 1, _stop) / (ativo.formaTributacao === 'S' ? 0.15 : 0.20);
                            //  _fator = this.calculaIR(ativo, _days + 1, _stop) / 0.15;
                            _ir +=  this.getComecotas (list.data, comecotas, 0, false, _fator);
                        }
                    }
                    else {_iof = 0; _ir  = 0; }
                    grossup = this.calculaGrossUp(ativo, _days, _stop);
                } else {
                    grossup = undefined;
                    grossupcalories = undefined;
                    _iof = 0;
                    _ir  = 0;
                }
                _calories = _saldo;
                if (_calories2 == 0) {_calories2 = _saldo;} // 20230315
                if (!this.isVoid(grossup)) {
                    grossupcalories = _calories2 + ((_shareQuantity + _shareacumulated + _shareComecotas) *
                        (((_fatCorrAcu/_fatorcorrinicial-1) * grossup) + 1))  + _debitvalue - _iof - _ir;
                }
                if ((((_fatCorrAcu / _fatCorrAcuant) <= 0.6) || ((_fatCorrAcu / _fatCorrAcuant) >= 1.4)) && _desvio < 2)
                {
                     _fatCorrAcu = _fatCorrAcuant;
                     ++_desvio;
                }
                else _desvio = 0;
 // Alteração para ganhos Dividendos
                let _currentAtivoMovimento = result.currentAtivoMovimento.filter(m => m.investmentDate === list.data);
                    _currentAtivoMovimento.forEach((y) => {
                        if ((y.tpMovimentoId == 5) || (y.tpMovimentoId == 6) || (y.tpMovimentoId == 7))
                        {
                            y.shareQuantity = (_shareQuantity + _shareacumulated + _shareComecotas);
                            y.investedValue = y.shareQuantity * y.shareValue;
                            _JCP += y.investedValue;
                            _JCPDay += y.shareValue;
                        } else if (y.tpMovimentoId == 8) {
                            _currentAtivoMovimento = result.currentAtivoMovimento.filter(m => m.investmentDate > list.data);
                             runnig = _currentAtivoMovimento.length !== 0;
                            _fatCorrAcu = y.shareValue;
                            _debitacumulated += - (_shareQuantity + _shareComecotas + _shareacumulated) * _fatCorrAcu;
                            _shareacumulated += - (_shareQuantity + _shareComecotas + _shareacumulated);
                            _iof = 0;
                            _ir = 0;
                            _saldo = 0;
                            _calories = _saldo;
                            _calories2 = 0;
                        }
                    }) ;
                _redCapAcu = _saldo - (_investedValue + _debitacumulated) + _JCP;
                _fat = _redCapAcu - _carbsAnt;
//                _fatCorrAcu += _JCPDay;
                _cotacao = ((_debitsharevalue == 0) || (_debitshareQuantity + (_shareQuantity + _shareacumulated + _shareComecotas)) <= 0.01)  ?  _fatCorrAcu :
                    ((_fatCorrAcu * (_shareQuantity + _shareacumulated + _shareComecotas)) + (_debitsharevalue) ) / (_debitshareQuantity + (_shareQuantity + _shareacumulated + _shareComecotas));
                if (dta !== mes) {
                    _protein = 1;
                }
                _protein *= (_fatCorrAcu+_JCPDay) / _fatCorrAcuant;
                _protein = (_protein - 1) * 100;
// Alteração para ganhos Dividendos Iron será atualizado no final
//                _iron = 1
                _carbs = _redCapAcu;
                _saldoLiquido = _saldo - _iof - _ir;
                (!_finishcot) && (!brief) && (itemDessert = {
                    name: _date, calories: this.numberToLocale(_calories),
                    fat: this.numberToLocale(_fat), protein: this.valorInPercent(_protein),
                    carbs: this.numberToLocale(_carbs), iron: this.valorInPercent((_iron - 1) * 100),
                    iof: this.numberToLocale(_iof), ir: this.numberToLocale(_ir),
                    saldoLiquido: this.numberToLocale(_saldoLiquido), quotas : this.formatQuota(_calories / _fatCorrAcu)
                });
                if (itemDessertCalc.name) {
                    this.dessertsCalcPush(result.dessertsCalc,itemDessertCalc,ativo.idTProduto);
                    result.groupdessertsCalc[idx2-1].push(this.deepClone(itemDessertCalc));
                }
                itemDessertCalc = {
                    name: _date, calories: _calories, fat: _fat, protein: _protein,
                    calories2 : _calories2, // 20230315
                    carbs: _carbs, iron: _iron, iof: _iof, ir: _ir, saldoLiquido: _saldoLiquido,
                    date: list.data, countItens: idx, cotacaoinicial: _fatorcorrinicial, cotacao: _cotacao,
                    cotacaoant : _fatCorrAcuant, jcp: _JCPDay, quotas : _calories / (_fatCorrAcu),
                    investedValue : _investedValue, shareQuantity : _shareQuantity, isendOfMonth : false,
                    movimentacao, indexCdi, indexBolsa, indexDolar, indexIpca
                };
                if (!this.isVoid(grossupcalories)) {
                    itemDessertCalc.grossupcalories = grossupcalories;
                    itemDessertCalc.grossup = grossup;
                    grossup = undefined;
                    grossupcalories = undefined;
                }
                _fatCorrAcuant = _fatCorrAcu;
                //_fatCorrAcuant = _fatCorrAcu - _JCPDay;
                _JCPDay = 0;
                _protein = (_protein/100)+1;
                mes = dta;
                _calories2 = 0; // 20230315
                _debitsharevalue = 0;
                _finishcot = (AtivoGeral && (indexcotacao == AtivoGeral.length))  ? true : _finishcot;
                return runnig;
            });
            if (!stopped) {
                this.dessertsCalcPush(result.dessertsCalc,itemDessertCalc,ativo.idTProduto);
                /* TESTE, linha */
                result.groupdessertsCalc[idx2-1].push(this.deepClone(itemDessertCalc));
                if (!brief) {
                    this.dessertsPush(result.desserts, itemDessert, result.dessertsCalc);
                }
            }
        return result;
    }

    calcularGruposMensal(Ativos, date)
    {
        let curr;
        let currentativo=[];
        currentativo.push({
            ativo : 'TOTAL INVESTIMENTOS',
            valor :  0,
            quantidade : 0
        });
        Ativos.forEach((i) => {
        let calc=this.calcular(i,false);
        let item = calc.dessertsCalc.filter(a => (a.name === date));
        if (item[0]) {
            currentativo[0].valor += item[0].calories;
            currentativo[0].quantidade += 1;
            curr = currentativo.filter(a => (a.ativo === this.descricaotipoproduto(i.idTProduto, i.tipo)));
            if (curr.length > 0) {
                curr[0].valor += item[0].calories;
                curr[0].quantidade += 1;
            }
            else{
                currentativo.push ({
                    ativo : this.descricaotipoproduto(i.idTProduto, i.tipo),
                    valor :  item[0].calories,
                    quantidade : 1
                    });
                }
              }

        })
        currentativo.sort ((a, b) =>  (a.ativo.localeCompare(b.ativo)));
        return currentativo;
    }

    descricaotipoproduto(idTProduto, tipo) {
        switch (idTProduto) {
            case  1 : return 'CDB/RDB';
            case  2 : return 'CRI';
            case  3 : return'DEBÊNTURES';
            case  4 : return 'LCI/LCA';
            case  5 : return 'TÍTULOS PÚBLICOS';
            case  6 : return 'AÇÕES';
            case  7 : return 'FUNDOS IMOBILIÁRIOS';
            case  8 : return tipo === 'FIPREV' ? 'PREVIDÊNCIA' : 'FUNDOS DE INVESTIMENTOS';
            case 10 : return 'CRA';
            case 11 : return 'BDR';
            case 13 : return 'OUTROS';
        }

    }

    mountMovimentos(ativo) {
        let currentAtivoMovimento=[];
        let dataresgate=this.currentDate();
        ativo.movimentos.forEach((i) => {
            if (i.tpMovimento == 8) {
                dataresgate =  i.investmentDate;
            }
            currentAtivoMovimento.push ({
                shareQuantity  : i.shareQuantity,
                investmentDate : i.investmentDate,
                tpMovimentoDesc: i.tpMovimentoDesc,
                tpMovimentoId  : i.tpMovimento,
                shareValue     : i.shareValue,
                investedValue  : i.investedValue})
            });
            ativo.eventos.forEach((i) => {
                if (ativo.dataInvestimento <= i.dataCom)
                {
                    currentAtivoMovimento.push ({
                        shareQuantity  : 0,
                        investmentDate : i.data,
                        tpMovimentoDesc: i.descricao,
                        tpMovimentoId  : i.tipoEvento,
                        shareValue     : i.valor,
                        investedValue  : 0
                    })
                }
            });
            let days = diffAsDays(this.currentDate(), ativo.vencimento);
            if (days < 0)
            {
                currentAtivoMovimento.push ({
                    shareQuantity  : 0,
                    investmentDate : ativo.vencimento,
                    tpMovimentoDesc: 'Resgate Total',
                    tpMovimentoId  : 8,
                    shareValue     : 0,
                    investedValue  : 0
                })
                let mov = ativo.movimentos.filter(m => m.tpMovimento === 8);
                if (mov && mov.length == 0)
                {
                    ativo.movimentos.push({
                    shareQuantity  : 0,
                    investmentDate : ativo.vencimento,
                    tpMovimentoDesc: 'Resgate Total',
                    tpMovimento    : 8,
                    shareValue     : 0,
                    investedValue  : 0
                    })
                }
            }
            return currentAtivoMovimento.filter(m => m.investmentDate <= dataresgate);
    }

    formatMovimentos(currentAtivoMovimento, filter) {
        currentAtivoMovimento.sort((a, b) => new Date (a.investmentDate) - new Date(b.investmentDate));
        currentAtivoMovimento.forEach((i) => {
          i.shareQuantity  = this.formatQuota(i.shareQuantity);
          i.investmentDate =  this.dateFormat(i.investmentDate);
          i.shareValue     = this.formatQuota(i.shareValue);
          i.investedValue  = this.numberToLocale(i.investedValue);
        });
        return filter ?
            currentAtivoMovimento.filter (e => e.shareQuantity !== '0,000000') :
            currentAtivoMovimento;
    }

    setComecotas(datacomecotas, shareQuantity, fatCorrAcu, comecotas, currentAtivoMovimento, total) {
        if (!currentAtivoMovimento) {return;}
        let movimentos = currentAtivoMovimento.filter(a => (a.investmentDate === datacomecotas) && (a.tpMovimentoId === 4));
        if (movimentos.length === 0) {
            currentAtivoMovimento.push ({
            investmentDate : datacomecotas,
            tpMovimentoDesc: 'come cotas',
            tpMovimentoId  : 4,
            shareQuantity  : shareQuantity,
            shareValue     : fatCorrAcu,
            investedValue  : shareQuantity * fatCorrAcu
            });
        }
        else {
            movimentos.forEach((i) => {
                i.shareQuantity  += shareQuantity;
                i.investedValue  += shareQuantity * fatCorrAcu;
                i.shareValue      = i.investedValue / i.shareQuantity;
            })
        }
        comecotas.push ({
            investmentDate : datacomecotas,
            shareQuantity  : total,
            shareValue     : fatCorrAcu,
            investedValue  : shareQuantity * fatCorrAcu,
            total          : total
        });
    }

    getComecotas (dataativo, comecotas, shareQuantity, isDebit, fator) {
        let _value = 0;
        if ((!comecotas)  || (comecotas.length === 0)) {return 0;}
        comecotas.forEach((i) => {
            if ((i.investmentDate <= dataativo) && isDebit ) {i.shareQuantity-= shareQuantity;}
            /* Diminui no comecotas anteriores ao saque o número de unidades retiradas da desaplicação */
             _value += (i.investedValue * (i.shareQuantity / i.total)) * (fator-1);
            /*  Calcula o valor do imposto atual que não foi incorporado no comecotas pois
                o imposto do comecotas é 15% e o imposto de renda atual pode ser outra aliquota.
                É feito uma regra de 3 para chegar no valor do imposto que não foi calculado
            */
        })
        return _value;
    }

    dessertsCalcPush (dessertsCalc, itemDessertCalc, idTProduto) {
        let found = dessertsCalc.filter(a => a.name === itemDessertCalc.name)[0];
        if (!found) {
            dessertsCalc.push(itemDessertCalc)
        } else {
            if ((found.quotas+itemDessertCalc.quotas) > 0)
            {
                if ((found.cotacaoant !== itemDessertCalc.cotacaoant)  ||  (found.cotacao !== itemDessertCalc.cotacao)) //||
                //(found.cotacaoinicial !== itemDessertCalc.cotacaoinicial) )
                {
                    if (idTProduto != 8)
                    {
                        found.cotacaoant = ((found.cotacaoant*found.quotas) + (itemDessertCalc.cotacaoant*itemDessertCalc.quotas)) /
                            (found.quotas+itemDessertCalc.quotas);
                        found.cotacao = ((found.cotacao*found.quotas) + (itemDessertCalc.cotacao*itemDessertCalc.quotas)) /
                            (found.quotas+itemDessertCalc.quotas);
                        found.protein = ((found.protein*found.quotas) + (itemDessertCalc.protein*itemDessertCalc.quotas)) /
                            (found.quotas+itemDessertCalc.quotas);
                        //found.cotacaoinicial = ((found.cotacaoinicial*found.quotas) + (itemDessertCalc.cotacaoinicial*itemDessertCalc.quotas)) /
                        //    (found.quotas+itemDessertCalc.quotas);
                    }
                }
            }
            found.calories += itemDessertCalc.calories;
            found.calories2 += itemDessertCalc.calories2; // 20230315
            // if (itemDessertCalc.movimentacao) { // 20230315 comentado
            //     itemDessertCalc.movimentacao = itemDessertCalc.calories; // 20230315 comentado
            // } // 20230315 comentado
            // if (itemDessertCalc.capital) {
            //     item.dessertsCalc.capital = itemDessertCalc.calories;
            // }
            found.investedValue += itemDessertCalc.investedValue;
            found.shareQuantity += itemDessertCalc.shareQuantity;
            found.carbs += itemDessertCalc.carbs;
            found.iof += itemDessertCalc.iof;
            found.ir += itemDessertCalc.ir;
            found.saldoLiquido += itemDessertCalc.saldoLiquido;
            found.fat += itemDessertCalc.fat;
            found.quotas += itemDessertCalc.quotas;
            //found.jcp += itemDessertCalc.jcp;
            if (!this.isVoid(itemDessertCalc.movimentacao)) {
                found.movimentacao = (found.movimentacao || 0) + itemDessertCalc.movimentacao;
            }
        };
    }

    dessertsPush (desserts, itemDessert, dessertsCalc) {
    let desert = desserts.filter(a => a.name === itemDessert.name);
    let desertCalc = dessertsCalc.filter(a => a.name === itemDessert.name);
    if (desert.length === 0) {desserts.push(itemDessert)}
    else {
        if (desertCalc.length > 0) {
            desert.forEach((i) => {
            i.calories =  this.numberToLocale(desertCalc[0].calories);
            i.fat = this.numberToLocale(desertCalc[0].fat);
            i.carbs = this.numberToLocale(desertCalc[0].carbs);
            i.iof = this.numberToLocale(desertCalc[0].iof);
            i.ir = this.numberToLocale(desertCalc[0].ir);
            i.protein = this.valorInPercent(desertCalc[0].protein);
            i.iron =   (desertCalc[0].iron > 0) ? this.valorInPercent((desertCalc[0].iron - 1) * 100) : this.valorInPercent(0);
            i.saldoLiquido = this.numberToLocale(desertCalc[0].saldoLiquido);
            i.quotas       = this.formatQuota(desertCalc[0].quotas);
            })};
        }
    }

    calculamovimentos(ativo) {
        let idx=1;
        let movimentos = [];
        let movimento = {
            tpMovimento : 0,
            indice : 0,
            isCredit : true,
            investmentDate : 0,
            shareQuantity : 0,
            shareValue    : 0,
            investedValue : 0,
            participacao   : 0
        };
        ativo.movimentos && ativo.movimentos.filter(a => (a.tpMovimento === 1) || (a.tpMovimento === 2))
            .forEach((i) => {
                movimento = {
                tpMovimento : i.tpMovimento,
                indice : idx,
                isCredit : true,
                investmentDate : i.investmentDate,
                shareQuantity : i.shareQuantity,
                shareValue : i.shareValue,
                investedValue : i.investedValue,
                participacao  : i.shareQuantity}
                movimentos.push(movimento);
                idx+=1;
            });
        let saldo=0;
        ativo.movimentos && ativo.movimentos.filter(a => (a.tpMovimento === 3))
            .forEach((i) => {
                movimento = {
                    tpMovimento : 3,
                    indice : 0,
                    isCredit : false,
                    investmentDate : i.investmentDate,
                    shareQuantity : i.shareQuantity,
                    shareValue : i.shareValue,
                    investedValue : i.shareValue * i.shareQuantity,
                    participacao : i.shareQuantity}
                    movimentos.push(movimento);
                });
        return movimentos;
    }

    mountCotacoes (ativo) {
        let dta;
        let mes=0;
        let listcotacao=[];
        let datelast;
        let _investimento   = formatISO(ativo.dataInvestimento);
        let _vencimento     = formatISO(ativo.vencimento);

        filterQuotes(ativo.cotacoes, _investimento, _vencimento)
        .every((list , idx) => {
            if (idx === 0) {datelast = list.data;}
            dta     = new Date(list.data).getMonth()+1;
            let movimentos = ativo.movimentos.filter(m => m.investmentDate === list.data);
            if ((movimentos.length > 0) || ((idx+1) === (ativo.cotacoes.length)))
                {
                    if (listcotacao.filter(a => (a.data === list.data)).length === 0)
                        listcotacao.push(list);
                }
            if (mes !== dta)  {
                let pos = idx ? idx-1 : 0;
                if (listcotacao.filter(a => (a.data === datelast)).length === 0)
                   {listcotacao.push(ativo.cotacoes[pos]);}
            }
            datelast = list.data;
            mes = dta;
            return true;
        })
        listcotacao.sort((a, b) => new Date (a.data) - new Date(b.data));
        return listcotacao;
    }

    calcular(ativo, brief) {
        this.refresh();
        ativo.movimentos.sort ((a, b) =>  (a.investmentDate.localeCompare(b.investmentDate)));
        switch (ativo.idTProduto) {
            case 1:
            case 4:
                return this.calcularCdb(ativo, brief);
            default:
                let idx = 1;
                let movimentos = this.calculamovimentos(ativo);
                let mov = movimentos.filter(a => (a.indice === idx) || (a.indice == 0));
                let result = {
                    /* TESTE, linha */
                    groupdessertsCalc: new Array(movimentos.length),
                    dessertsCalc: [],
                    desserts: [],
                    currentAtivoMovimento:[]
                };
                for (let i = 0; i < movimentos.length; ++i) { result.groupdessertsCalc[i] = []; }
                result.currentAtivoMovimento = this.mountMovimentos(ativo);
                while (mov.length > 0) {
                    /* TESTE, idx */
                    this.calcularOutros(ativo, brief, result, mov, mov[0].investmentDate, idx);
                    idx += 1;
                    mov = movimentos.filter(a => (a.indice === idx) ||
                        ((a.indice == 0) && (a.participacao > 0)) );
                    if (mov[0] && (mov[0].indice == 0)) {break;}
                }

// Alteração para ganhos Dividendos
                result.currentAtivoMovimento.forEach((y) => {
                    if ((y.tpMovimentoId == 5) || (y.tpMovimentoId == 6) || (y.tpMovimentoId == 7))
                    {
                        let _desertcalc = result.dessertsCalc.filter(m => y.investmentDate === m.date);
                        if (_desertcalc.length > 0)
                        {
                            y.shareQuantity = _desertcalc[0].quotas;
                            y.investedValue = y.shareQuantity * y.shareValue;
                        }
                    }
                }) ;


// Alteração para média ponderada do acumulado
                let _iron = 1;
                let _ironmonth = 1;
                let _protein = 0;
                let _finish = false;

                result.dessertsCalc.forEach((i) => {
                    if (_finish && i.calories > 0)
                    {
                        _finish = false;
                        _iron = 1;
                        _ironmonth = 1;
                    }
                    if (!_finish)
                    {
                        _iron *= ((i.cotacao+i.jcp) / i.cotacaoant);
                        _protein = _iron / _ironmonth;
                        i.protein =  (_protein-1)*100;
                        if (i.isendOfMonth) {_ironmonth = _iron;}
                    }
                    _finish = i.calories <  0.0099 ? true : _finish;
                    i.iron = _iron;
                    i.saldoLiquido = i.iron < 1 ? i.calories : i.saldoLiquido ;
                    if (_protein != 0) {i.protein =  (_protein-1)*100;}
                    let desert = result.desserts.filter(a => a.name === i.name);
                    if (desert && (desert.length > 0)) {
                        desert[0].calories = this.numberToLocale(i.calories <= 0.0001 ? 0 : i.calories);
                        desert[0].fat = this.numberToLocale(i.fat) ;
                        desert[0].protein = this.numberToLocale(i.protein) ;
                        desert[0].carbs = this.numberToLocale(i.carbs) ;
                        desert[0].iron = this.valorInPercent((i.iron-1)*100) ;
                        desert[0].iof = i.iron > 1 ? this.numberToLocale(i.iof) : this.numberToLocale(0);
                        desert[0].ir = i.iron > 1 ? this.numberToLocale(i.ir) : this.numberToLocale(0);
                        desert[0].saldoLiquido = i.iron > 1 ? this.numberToLocale(i.saldoLiquido) :  this.numberToLocale(i.calories) ;
                        desert[0].quotas =  this.formatQuota(i.quotas);
                    }
                })
// Alteração para média ponderada do acumulado
                result.currentAtivoMovimento = this.formatMovimentos(result.currentAtivoMovimento, true);
                //result.dessertsCalc = result.dessertsCalc.filter (e => (e.calories >= 0.001))
                result.desserts = result.desserts.filter (e =>
                    !(((e.calories == '-0,00') && ((e.fat == '0,00') || (e.fat == '-0,00'))) ||
                    ((e.calories == '0,00')  && ((e.fat == '0,00') || (e.fat == '-0,00')))));
                // VSC ESTA PERDENDO O ÚLTIMO DIA DESSERTCALC E O ÚLTIMO MÊS NO DESSERTS
                return result;
        }
    }

    //#endregion

    //#region Rentabilidade base

    getCutDate (ativos, calculos) {
        const top    = '0000-00-00';
        const bot    = '9999-99-99';
        const values = ativos.reduce((a, e, i) => {
            const c = calculos[i];
            const l = c && c.length && c[c.length - 1] || undefined;
            const d = l && l.date.substring(0, 10) || e.dataInvestimento.substring(0, 10) || '9999-99-99';
            if (d > a[0]) {
                a[0] = d;
            }
            return a;
        }, [top, bot]);
        return (values[1] !== bot) ? values[1] :
               (values[0] !== top) ? values[0] :
              new Date().toISOString().substring(0, 10);
    }
    getCalculos (ativos, inicio, final) {
        let   calc = ativos.map((a) => {
            const { dessertsCalc: res, groupdessertsCalc: grp } = this.calcular(a, true);
            const except = a.movimentos?.filter(m => m.tpMovimento === 3 || m.tpMovimento === 8);
            except?.forEach((e) => {
                const tstamp = e.investmentDate;
                const match  = res.filter((x) => x.date === tstamp);
                match.forEach((m) => {
                    if (e.tpMovimento === 8) {
                        m.movimentacao = Number.NaN;
                        return;
                    }
                    if (this.isVoid(m.movimentacao)) {
                        m.movimentacao = (e.investedValue > 0) ? -e.investedValue : e.investedValue;
                    } else if (m.movimentacao > 0) {
                        m.movimentacao = -m.movimentacao;
                    }
                    m.mcotacaoant = m.cotacaoant; // ainda sem definição.
                });
            });
            //if (this.isVoid(grp) || (!grp.length) || (grp[0].length !== res.length)) { return res; }
            if (this.isVoid(grp) || (!grp.length) || (grp[0].length !== res.length)) {
                var bot = res.map((e,i) => [i+2, Math.abs(e.calories) > 1E-3]).filter((t) => t[1]);
                if (bot.length) {
                    const ln = bot[bot.length - 1][0];
                    if (ln <= res.length) {
                        const part = res.slice(0, ln);
                        return part;
                    }
                }
                return res;
            }
            const heads = grp.slice(1).map(a => a[0]);
            res.forEach((r, i) => {
                r.cotacaoant  = grp[0][i].cotacaoant;
                if (this.isVoid(r.movimentacao) || !this.isVoid(r.capital)) { return; }
                const tstamp = r.date.substring(0, 10);
                const found   = heads.filter((c) => c && c.date && c.date.substring(0, 10) === tstamp);
                if (found.length) { r.mcotacaoant = found[0].cotacaoant }
            });
            var end = res.map((e, i) => [i+2, Math.abs(e.calories) > 1E-3]).filter((t) => t[1]);
            if (end.length) {
                const sz = end[end.length - 1][0];
                if (sz <= res.length) {
                    const part = res.slice(0, sz);
                    return part;
                }
            }
            return res;
        });
        let   gcut   = this.getCutDate(ativos, calc);
        let   cut    = final && final < gcut ? final : gcut;
        let   extr   = '9999-99-99';
        let   gbott  = inicio && (inicio > extr) ? inicio : extr;
        let   ending = [];
        for (let i = 0; i < calc. length; ++i) {
            const p = calc[i];
            if (p.length === 0) {
                if (ativos[i].dataInvestimento < cut) {
                    cut = ativos[i].dataInvestimento.substring(0, 10);
                }
                gbott = cut;
                break;
            }
            const end = p.length - 1;
            if (p.length && p[0].date < gbott) { gbott = p[0].date.substring(0, 10) }
            let pos = end;
            while ((pos > 0) && (p[pos].date.substring(0, 10) > cut)) { --pos; }
            if (pos !== end) {
                calc[i] = p.slice(0, pos + 1);
            }
            calc.forEach((c) => c.forEach(x => x.calories = x.calories2));
            const q = calc[i][calc[i].length - 1];
            ending.push((q.date < cut) && (q.calories === 0) ? q.date.substring(0, 10) : undefined);
        }
        const bottom = inicio && gbott < inicio ? inicio : gbott;
        if (inicio && final) {
            calc         = calc.map((c, idx) => {
                const a = ativos[idx];
                a.movimentos = a.movimentos.filter((m) => {
                    const dt = m.investmentDate.substring(0, 10);
                    return dt >= bottom && dt <= cut;
                });
                var lim  = 0;
                var size = c.length;
                while ((lim < size) && c[lim].date.substring(0, 10) < bottom) { ++lim; }
                const res = c.slice(lim).filter((c) => c.date.substring(0, 10) <= cut);
                (lim < res.length) && res.forEach((r) => r.iron /= res[lim].iron);
                if (res.length && !a.movimentos.some((a) => a.tpMovimento === 1)) {
                    const top = res[0];
                    top.capital      = top.calories;
                    top.movimentacao = top.calories;
                    top.iron         = top.cotacao / top.cotacaoant;
                    a.movimentos.unshift({
                        investedValue : top.calories,
                        shareQuantity: top.quotas,
                        tpMovimento: 1
                    });
                }
                return res;
            });
        }
        return { cut, calc, bottom, ending };
    }
    rentabilidadeGrupo (ativos, calculos, finais, doIndex) {
        const ilen  = ativos.length;
        const build = () => new Array(ilen).fill(undefined);
        const info  = ativos.reduce((o, a, i) => {
            const ical                  = calculos[i];
            const tstamp                = a.dataInvestimento.substring(0, 10);
            if (tstamp < o.calculos.min) {
                o.calculos.min          = tstamp;
                o.calculos.start        = i;
            }
            if (tstamp > o.calculos.max) {
                o.calculos.max          = tstamp;
            }
            ical.forEach((c)            => {
                const istamp            = c.date.substring(0, 10);
                findOrPlaceTStamp(o.index, istamp);
                const prev              = o.calculos[istamp] || build();
                prev[i]                 = c;
                o.calculos[istamp]      = prev;

                if (!this.isVoid(c.movimentacao)) {
                    const prev              = o.movimentos[istamp] || build();
                    const cont              = prev[i] || 0;
                    prev[i]                 = Number.isNaN(c.movimentacao) ? Number.NaN : cont + c.movimentacao;
                    o.movimentos[istamp]    = prev;
                    if (istamp < o.movimentos.min) { o.movimentos.min = istamp; }
                    if (istamp > o.movimentos.max) { o.movimentos.max = istamp; }
                }
            });
            const lstamp                = ical.length &&
                ical[ical.length - 1].date.substring(0, 10) ||
                '';
            if (lstamp > o.calculos.last) {
                o.calculos.last = lstamp;
            }
            (a.movimentos && a.movimentos.length) && (o.capital += a.movimentos[0].investedValue || 0);
            return o;
        }, {
            calculos: {
                min: '9999-99',
                max: '0000-00',
                last: '0000-00-00',
                start: -1
            },
            movimentos: {
                min: '9999-99',
                max: '0000-00'
            },
            capital: 0,
            index: []
        });
        let refs    = new Array(ativos.length).fill(undefined);
        let apos    = new Array(calculos.length).fill(0);
        let agup    = new Array(ativos.length).fill(1);
        let parts   = info.index.map((tstamp, tsi) => {
            let cal = info.calculos[tstamp];
            for (let ip = 0; ip < cal.length; ++ip) {
                const ref = cal[ip];
                if (!this.isVoid(ref)) { continue; }
                const tgt = calculos[ip];
                if (!tgt.length) { continue; }
                let xpos  = apos[ip];
                if (tgt[0].date <= tstamp) {
                    while (xpos < tgt.length) {
                        if (xpos && tgt[xpos].date >= tstamp) {
                            if (tgt[xpos].date > tstamp) { --xpos; }
                            cal[ip]  = tgt[xpos];
                            apos[ip] = xpos;
                            break;
                        }
                        ++xpos;
                    }
                    apos[ip] = xpos;
                }
            }
            let mov = info.movimentos[tstamp];
            return cal.map((c, p) => {
                let itm = {
                    antes: 0, depois: 0, taxa: 0, acum: 1, tstamp,
                    nil: true, mov: 0, cap: 0, liquido: 0, valor: 0
                };
                if (this.isVoid(c)) { return itm; }
                if (tsi === 0) { itm.antes += c.capital || 0; }
                itm.nil     = false;
                itm.cap     = c.capital || 0;
                // const nrate = (Math.abs(c.calories) > 1E-3) ? (c.cotacao / c.cotacaoant - 1.0) * 100 : 0;
                // const psrc  = tsi > 0 ? info.calculos[info.index[tsi - 1]][p] : undefined;
                // itm.taxa    = psrc ? (c.iron / psrc.iron - 1) * 100 : nrate;
                itm.taxa    = c.jcp ?
                    ((c.cotacao + c.jcp) / c.cotacaoant - 1.0) * 100 :
                    (c.cotacao / c.cotacaoant - 1.0) * 100;
                // JCP REM
                // if (!this.isVoid(c.jcp) && (c.jcp !== 0)) {
                //     itm.jcp     = c.jcp;
                //     itm.jcpTaxa = ((c.cotacao-c.jcp) / c.cotacaoant - 1.0) * 100;
                // }
                itm.acum    = c.iron;
                itm.depois  = c.calories;
                itm.valor   = c.fat;
                itm.mov     = mov && mov.length && !this.isVoid(mov[p]) ? mov[p] : 0;
                itm.grossup = c.grossup;
                itm.liquido = c.saldoLiquido;
                itm.cdi     = c.indexCdi;
                itm.ipca    = c.indexIpca;
                itm.dolar   = c.indexDolar;
                itm.bolsa   = c.indexBolsa;
                const gup   = itm.grossup;
                if (!!gup) {
                    if ((agup[p] == 1) || (gup < agup[p])) {
                        agup[p] = gup
                    }
                }
                if (!this.isVoid(c.mcotacaoant)) {
                    itm["mtaxa"]  = (c.cotacao / c.mcotacaoant - 1.0) * 100;
                } else if (itm.mov) {
                    itm["mtaxa"]  = (c.cotacao / c.cotacaoant - 1.0) * 100;
                }
                return itm;
            });
        });
        parts.forEach((elem, i) => {
            if (i === 0) { return; }
            const prev = parts[i - 1];
            elem.forEach((here, j) => {
                const there = prev[j];
                const enl   = here.nil;
                if (enl && !this.isVoid(refs[j])) {
                    here.depois = refs[j].depois;
                } else {
                    refs[j] = here;
                }
                here.antes  += there.depois;
            })
        });
        let aeq     = 100;
        let neq     = 0;
        let peq     = new Array(ativos.length);
        for (let i = 0; i < peq.length; ++i) { peq[i] = { cotas: 0, valor: 100, prev: 100 } }
        let qparts  = parts.reduce((a, e, i) => {
            const tstamp = e.length && e[0].tstamp || '0000-00-00';
            const len    = doIndex ? a.index.length : a.length;
            if (!len) {
                const agg = e.reduce((b, x, xpos) => {
                    b[0] += Number.isNaN(x.mov) ? 0 : x.mov,
                    b[1] += x.depois,
                    b[2] += x.valor;
                    b[3]  = b[3] !== 0 ? b[3] : x.grossup || 0;
                    if (x.cap) {
                        let xel   = peq[xpos];
                        xel.cotas = x.mov / 100;
                        xel.valor = 100 * (1 + x.taxa/100.0);
                        b[4]     += xel.cotas * xel.valor;
                        b[5]     += xel.cotas;
                    }
                    b[6] += x.liquido;
                    b[8] += this.isVoid(x.acum) ? x.depois : x.acum * x.depois;
                    if (!this.isVoid(x.cdi) && (b[9] === 0)) {
                        b[9] = x.cdi;
                    }
                    if (!this.isVoid(x.ipca) && (b[10] === 0)) {
                        b[10] = x.ipca;
                    }
                    if (!this.isVoid(x.dolar) && (b[11] === 0)) {
                        b[11] = x.dolar;
                    }
                    if (!this.isVoid(x.bolsa) && (b[12] === 0)) {
                        b[12] = x.bolsa;
                    }
                    return b;
                }, [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
                aeq = agg[8] / agg[1] * 100;
                const nx = {
                    antes: agg[0], depois: agg[1], taxa: aeq - 100, acum: aeq/100, tstamp,
                    cart: agg[1], vq: agg[4] / agg[5], nq: agg[5],
                    grossup: agg[3], cdi: agg[9], ipca: agg[10], dolar: agg[11], bolsa: agg[12],
                    valor: agg[2], liquido: agg[6]
                };
                // e.forEach((b, pos) => {
                //     const pel   = peq[pos];
                //     b.nq        = pel.cotas;
                //     b.vq        = pel.cotas ? pel.valor : aeq;
                // });
                if (doIndex) {
                    a.index.push(tstamp);
                    a[tstamp] = nx;
                } else {
                    a.push(nx);
                }
                return a;
            }
            let num     = 0;
            let den     = 0;
            const { cpv, inr } = peq.reduce((o, a) => {
                o.cpv.push(Math.abs(a.cotas) <= 1E-3 ? 0 : a.valor);
                o.inr  &= (a.cotas === 0);
                return o;
            }, { cpv: [], inr: true });
            let valor   = 0;
            for (let j = 0; j < peq.length; ++j) {
                const pel   = peq[j];
                const tel   =  e[j];
                valor      += tel.valor;
                if (pel.cotas === 0) {
                    if (tel.cap && peq.every(p => p.cotas === 0)) {
                        pel.prev    = aeq;
                        pel.valor   = aeq;
                    } else if (!tel.cap) {
                        continue;
                    }
                }
                // JCP REM
                // const prv   = pel.preJcp // ??????????????????????????????????
                //     ? pel.valor * (1 + tel.taxa/100.0)
                //     : pel.valor;
                const prv  = pel.valor;
                pel.valor *= (1 + tel.taxa/100.0);
                // JCP REM
                // if (tel.jcp) {
                //     pel.jcpValor = prv * (1 + tel.jcpTaxa/100.0);
                // }
                // if (pel.preJcp) { // ??????????????????????????????????
                //     pel.prcalc = prv;
                //     num   += Math.abs(prv) > 1E-3 ? pel.cotas * prv / pel.preJcp : pel.cotas;
                // } else {
                //     num   += Math.abs(prv) > 1E-3 ? pel.cotas * pel.valor / prv : pel.cotas;
                // }
                num       += Math.abs(prv) > 1E-3 ? pel.cotas * pel.valor / prv : pel.cotas;
                // num       += pel.cotas * pel.valor;
                den       += pel.cotas;
            }
            const pre      = aeq;
            const defq     = inr ? aeq : 0;
            aeq            = Math.abs(den) > 1E-3 ? pre * num / den : defq;
            // aeq            = Math.abs(den) > 1E-3 ? num / den : defq;
            neq            = den;
            let pcart      = 0;
            let tcart      = 0;
            let liq        = 0;
            const fix      = aeq;
            for (let j = 0; j < peq.length; ++j) {
                const pvl  = peq[j];
                const tgt  = e[j];
                pcart     += tgt.antes;
                tcart     += tgt.depois;
                liq       += tgt.liquido;
                if (tgt.cap) {
                    cpv[j]     = fix;
                    pvl.prev   = fix;
                    pvl.cotas  = Math.abs(fix) > 1E-3 ? tgt.cap / fix : 0;
                    pvl.valor  = fix * (1 + tgt.taxa/100);
                    neq       += pvl.cotas;
                } else if (Number.isNaN(tgt.mov)) {
                    pvl.cotas = 0;
                    pvl.valor *= (1 + tgt.taxa/100);
                } else if (tgt.mov) {
                    const vmov = tgt.mov;
                    const lmov = pvl.valor * pvl.cotas;
                    const dmov = tgt.depois - vmov;
                    if (Math.abs(dmov - lmov) > 1E-3) {
                        if (tgt.depois !== 0) {
                            const hdm = Math.abs(dmov) > 1E-3;
                            pvl.cotas = hdm ? tgt.depois * pvl.cotas / dmov : 0;
                            if (!hdm) { pvl.valor = 100; }
                        } else if (tstamp === finais[j]) {
                            pvl.valor *= (1 + tgt.mtaxa/100);
                        }
                    } else {
                        cpv[j]     = fix;
                        const mvel = fix * (1 + tgt.mtaxa/100);
                        const eadd = Math.abs(fix) > 1E-3 ? vmov / fix : 0;
                        const vden = pvl.cotas + eadd;
                        pvl.valor  = Math.abs(vden) > 1E-3 ? (pvl.valor * pvl.cotas + mvel * eadd) / vden : pvl.valor;
                        pvl.cotas  = Math.abs(pvl.cotas + eadd) > 1E-3 ? pvl.cotas + eadd : 0;
                        neq        = Math.abs(neq + eadd) > 1E-3 ? neq + eadd : 0;
                    }
                }
                if (tgt.cap || tgt.mov || Number.isNaN(tgt.mov)) {
                    if (Math.abs(tgt.depois) <= 1E-3 || Math.abs(pvl.cotas) <= 1E-3) {
                        pvl.cotas     = 0;
                        pvl.valor     = 100;
                    }
                }
            }
            const prt     = peq.reduce((r, e, pid) => {
                const     prv = cpv[pid];
                // JCP REM
                // if (e.preJcp) { // ??????????????????????????????????
                //     r[0]  += Math.abs(prv) > 1E-3 ? e.cotas * e.prcalc / e.preJcp : e.cotas;
                //     delete e.prcalc;
                //     delete e.preJcp;
                // } else {
                //     r[0]  += Math.abs(prv) > 1E-3 ? e.cotas * e.valor / prv : e.cotas;
                // }
                r[0]      += Math.abs(prv) > 1E-3 ? e.cotas * e.valor / prv : e.cotas;
                // r[0]      += e.cotas * e.valor;
                r[1]      += e.cotas;
                // JCP REM
                // if (e.jcpValor) {
                //     e.preJcp    = e.valor; // ??????????????????????????????????
                //     e.valor     = e.jcpValor;
                //     delete e.jcpValor;
                // }
                return r;
            }, [0, 0]);
            aeq           = Math.abs(prt[1]) > 1E-3 ? pre * prt[0] / prt[1] : aeq;
            // aeq           = Math.abs(prt[1]) > 1E-3 ? prt[0] / prt[1] : aeq;

            let   nxrate    = pre !== 0 ? (aeq/pre - 1) * 100 : 0;
            let   idxvals   = [0, 0, 0, 0];
            e.every((c) => {
                var matches = 0;
                idxvals.forEach((x, xpos) => {
                    if (x) { ++matches; return; }
                    switch (xpos) {
                        case 0:
                            if (!this.isVoid(c.cdi  )) { idxvals[xpos]  = c.cdi;   ++matches; }
                            break;
                        case 1:
                            if (!this.isVoid(c.ipca )) { idxvals[xpos]  = c.ipca;  ++matches; }
                            break;
                        case 2:
                            if (!this.isVoid(c.dolar)) { idxvals[xpos]  = c.dolar; ++matches; }
                            break;
                        case 3:
                            if (!this.isVoid(c.bolsa)) { idxvals[xpos]  = c.bolsa; ++matches; }
                            break;
                    }
                });
                return matches < idxvals.length;
            });
            const egup      = e[info.calculos.start].grossup || 0;
            const nx = {
                antes: pcart, depois: tcart, taxa: nxrate, acum: aeq / 100, tstamp,
                cart: tcart, vq: aeq, nq: neq, liquido: liq,
                cdi: idxvals[0], ipca: idxvals[1], dolar: idxvals[2], bolsa: idxvals[3],
                valor, grossup: egup
            };
            // e.forEach((b, pos) => {
            //     const pel   = peq[pos];
            //     b.nq        = pel.cotas;
            //     b.vq        = pel.cotas ? pel.valor : aeq;
            // });
            if (doIndex) {
                findOrPlaceTStamp(a.index, tstamp);
                a[tstamp] = nx;
            } else {
                a.push(nx);
            }
            return a;
        }, doIndex ?  { index: [] } : []);
        let grp     = parts.reduce((o, p) => {
            const tstamp = p[0].tstamp;
            var elt = p.reduce((e, q) => {
                e.antes   += q.antes;
                e.depois  += q.depois;
                e.tstamp   = q.tstamp;
                if (!this.isVoid(q.grossup)) { e.grossup = q.grossup }
                e.nil      = false;
                if (this.isVoid(e.cdi  ) && !this.isVoid(q.cdi  )) { e.cdi   = q.cdi;   }
                if (this.isVoid(e.ipca ) && !this.isVoid(q.ipca )) { e.ipca  = q.ipca;  }
                if (this.isVoid(e.dolar) && !this.isVoid(q.dolar)) { e.dolar = q.dolar; }
                if (this.isVoid(e.bolsa) && !this.isVoid(q.bolsa)) { e.bolsa = q.bolsa; }
                return e;
            }, {
                antes: 0, depois: 0, taxa: 0, acum: 1, tstamp: '', nil: true, liquido: 0,
                cdi: undefined, ipca: undefined, dolar: undefined, bolsa: undefined,
                valor: 0
            });
            const prev  = doIndex ? qparts[tstamp] : qparts.find((e) => e.tstamp === tstamp);
            elt.taxa    = Math.abs(100 + prev.taxa) > 1E-3 ? prev.taxa : 0;
            elt.liquido = prev.liquido;
            o.acum = prev.acum;
            elt.valor   = prev.valor;
            elt.acum = o.acum;
            if (doIndex) {
                const idx = elt.tstamp.substring(0, 7);
                let prev = o.grupo[idx] || (o.grupo[idx] = []);
                prev.push(elt);
                o.grupo[idx] = prev;
                findOrPlaceTStamp(o.grupo.index, idx);
            } else {
                o.grupo.push(elt);
            }
            return o;
        }, { grupo: doIndex ? { index: [], lead: info.calculos.min } : [], acum: 1 });
        return {
            grupo: grp.grupo,
            items: parts,
            total: qparts,
            min: info.calculos.min,
            max: info.calculos.max,
            last: info.calculos.last,
            capital: info.capital,
            grossup: agup
        };
    }
    find12CutDate (start, calculos) {
        let left        = 0;
        let right       = calculos.length - 1;
        let middle      = 0;
        let input       = formatISO(start);
        while (left < right) {
            middle      = (left + right) >> 1;
            let item    = calculos[middle];
            let test    = formatISO(item.date);
            if (test === input) {
                return test;
            } else if (test >input) {
                right   = middle - 1;
            } else {
                left    = middle  + 1;
            }
        }
        const fst = formatISO(calculos[left].date);
        const bot = right >= 0 ? right : 0;
        const snd = formatISO(calculos[bot].date);
        let [val, idx] = (fst < snd) ? [fst, left] : [snd, bot];
        if (val > input && idx > 0) { --idx; }
        return formatISO(calculos[idx].date);
    }
    findBounds (items, tstamp, leftmost) {
        const cmp   = (l, r) => l === r ? 0 : l < r ? -1 : 1;
        let left    = 0;
        let right   = items.length - 1;
        while (left < right) {
            const middle = (left + right) >> 1;
            const item   = items[middle];
            const comp   = cmp(item.tstamp, tstamp);
            if (comp === 0) {
                return leftmost && (middle > 0) ? (middle - 1) : middle;
            } else if (comp > 0) {
                right = middle - 1;
            } else {
                left = middle + 1;
            }
        }
        const lr = cmp(items[left].tstamp, tstamp);
        return (left > 0) && ((lr > 0) || (leftmost && lr === 0)) ? left - 1 : left;
    }
    realignPart (part, inicio, final) {
        let { calculos }    = part;
        if (!(inicio && final && calculos.length)) { return calculos; }
        calculos        = calculos.map((block) => {
            const cleft     = CALCFLT.getBound(block, inicio, true);
            const cright    = CALCFLT.getBound(block, final, false);
            let slice       = block[cleft].date.substring(0, 10) > final
                ? []
                : block.slice(cleft, cright + 1);
            if (!slice.length) { return slice }
            if (slice[slice.length - 1].date.substring(0, 10) < inicio) { return [] }
            if (slice[0].date.substring(0, 10) > inicio) { return slice; }
            const {
                indexCdi, indexIpca, indexDolar, indexBolsa, iron
            }               = slice[0];
            slice           = slice.map((c) => {
                let r       = this.deepClone(c);
                r.indexCdi      /= indexCdi;
                r.indexIpca     /= indexIpca;
                r.indexDolar    /= indexDolar;
                r.indexBolsa    /= indexBolsa;
                r.iron          /= iron;
                return r;
            });
            return slice;
        });
        return calculos;
    }
    realign (rent, inicio, final) {
        if (!(inicio && final)) { return rent; }
        let { grupo, grossup, items, total } = rent;
        if (!grupo.length) { return rent; }
        const left  = this.findBounds(grupo, inicio, true);
        const right = this.findBounds(grupo, final, false);
        const skip  = grupo[left].tstamp > final;
        if (skip) { return { grupo: [], grossup: [], items: [], total: [] } }
        grupo = grupo.slice(left, right + 1);
        items = items.slice(left, right + 1);
        total = total.slice(left, right + 1);
        if (grupo[0].tstamp >= inicio) {
            return { grupo, grossup, items, total };
        }
        const gstm = grupo[0].tstamp;
        const cpNm = ['acum', 'valor', 'cdi', 'ipca', 'dolar', 'bolsa'];
        const cpFn = (o) => cpNm.reduce((r, n) => { r[n] = o[n]; return r; }, {});
        const dvFn = (l, r) => (this.isVoid(l) ? 1 : l) / (this.isVoid(r) ? 1 : r);
        const gtop = cpFn(grupo[0]);
        const itop = items[0].map(cpFn);
        if (gstm < inicio) {
            grupo.forEach((g, i) => {
                g.acum  /= gtop.acum;
                g.valor -= gtop.valor;
                g.cdi    = dvFn(g.cdi, gtop.cdi);
                g.ipca   = dvFn(g.ipca, gtop.ipca);
                g.dolar  = dvFn(g.dolar, gtop.dolar);
                g.bolsa  = dvFn(g.bolsa, gtop.bolsa);
            });
            items.forEach((itm) =>
                itm.forEach((e, i) => {
                    e.acum   /= itop[i].acum;
                    e.valor  -= itop[i].valor;
                    e.cdi     = dvFn(e.cdi, itop[i].cdi);
                    e.ipca    = dvFn(e.ipca, itop[i].ipca);
                    e.dolar   = dvFn(e.dolar, itop[i].dolar);
                    e.bolsa   = dvFn(e.bolsa, itop[i].bolsa);
                }));
        }
        return { grupo, grossup, items, total };
    }
    getLimits (seed) {
        const tdy = getDateValue(seed);
        return {
            start12Month: new Date(tdy.getFullYear() - 1, tdy.getMonth() + 1, 1)
                .toISOString()
                .substring(0, 10),
            bound12Month: new Date(tdy.getFullYear() - 1, tdy.getMonth() + 1, 0)
                .toISOString()
                .substring(0, 10),
            startMonth  : new Date(tdy.getFullYear(), tdy.getMonth(), 1)
                .toISOString()
                .substring(0, 10),
            startYear   : new Date(tdy.getFullYear(), 0, 1)
                .toISOString()
                .substring(0, 10)
        }
    }
    getStartOfMonth(test, candidate) {
        if (test <= candidate) { return test; }
        const piv = new Date(candidate);
        return new Date(piv.getFullYear(), piv.getMonth(), 1).toISOString().substring(0, 10);
    }
    fillPart (part, gqot, inicio, final, limits) {
        if (part.ativos.length === 0) { return; }
        const { ativos, cotacoes, finais, calculos: orgcalc } = part;
        const calculos = this.realignPart(part, inicio, final);
        const { grupo, grossup, items } = this.realign(
            this.rentabilidadeGrupo(ativos, orgcalc, finais),
            inicio,
            final);
        part.calculos = calculos;
        const bgup = !items.length ? { num: 0, den: 0 } : items[items.length - 1].reduce((o, a, i) => {
            o.num += !grossup[i] ? a.depois : grossup[i] * a.depois;
            //o.num += a.taxa > 0 ? grossup[i] * a.depois : a.depois;
            o.den += a.depois;
            return o;
        }, { num: 0, den: 0 });
        const vgup = bgup.den ? bgup.num / bgup.den : 1;
        const flld = calculos
            .map(c => c && c.length ? c[c.length - 1] : undefined)
            .some(c => c && Math.abs(c.calories) > 1E-3);
        const elem = ativos.reduce((o, a, i) => {
            if (gqot) {
                o.cotacoes = gqot;
                if (a.dataInvestimento < o.limit) {
                    o.limit = a.dataInvestimento.substring(0, 10);
                }
            } else {
                const qot = cotacoes[i];
                if (a.dataInvestimento < o.limit) {
                    o.limit = a.dataInvestimento.substring(0, 10);
                    o.cotacoes = qot;
                }
            }
            const dat = calculos[i];
            if (!dat.length) {
                o.items.push({
                    mensal: {
                        saldo: 0, acumulado: 1, razao: 0,
                        valor: 0, taxa: 0,
                        cdi: 1, ipca: 1, dolar: 1, ibovespa: 1
                    },
                    doze: {
                        saldo: 0, acumulado: 1, razao: 0,
                        valor: 0, taxa: 0,
                        cdi: 1, ipca: 1, dolar: 1, ibovespa: 1
                    },
                    geral: {
                        saldo: 0, acumulado: 1, razao: 0,
                        valor: 0, taxa: 0,
                        cdi: 1, ipca: 1, dolar: 1, ibovespa: 1
                    },
                    ano: {
                        saldo: 0, acumulado: 1, razao: 0,
                        valor: 0, taxa: 0,
                        cdi: 1, ipca: 1, dolar: 1, ibovespa: 1
                    },
                    items: [],
                    label: a.descricao
                });
                return o;
            }
            const end = dat.length - 1;
            let   cut = end;
            let   pdt = limits.start12Month;
            let   dlm = limits.bound12Month;
            let   cdt = this.find12CutDate(dlm, dat);
            while (cut > 0 && (dat[cut].date > pdt)) { --cut; }

            const  ylm = limits.startYear;
            let   yct = dat.length - 1;
            while (yct > 0 && (dat[yct].date > ylm)) { --yct; }
            const ycr = dat[yct];
            const usy = (!!ycr) && (ycr.date < ylm);

            const src = dat.slice(cut);
            const fcr = dat[cut];
            const scl = src.length - 1;
            let   mct = dat.length - 1;
            let   mdt = this.getStartOfMonth(limits.startMonth, dat[mct].date);
            while (mct > 0 && dat[mct].date > mdt) { --mct };
            const prt = dat.slice(mct);
            let   lcr = src[scl];
            let   trx = lcr;
            let   tri = scl;
            while (tri && (trx = src[tri]) && Math.abs(trx.calories) <= 1E-3 && trx.date >= mdt) {
                lcr = trx;
                --tri;
            }

            const nvl   = Math.abs(lcr.calories) <= 1E-3;
            const old   = dat[mct].date < mdt;

            const mrt   = nvl ? 1 :
                          old ? (lcr.iron / prt[0].iron) :
                                lcr.iron;
            const mat   = (mrt - 1) * 100;
            const rtt   = (src[0].date >= cdt) ?
                ((cut !== 0) && (dat[cut].date <= pdt) ? (lcr.iron/src[0].iron) : lcr.iron) :
                1;
            const att   = (rtt - 1) * 100;
            const ult   = (inicio && final && (scl > 0)) ||
                          ((!flld) && (scl > 0) && (Math.abs(lcr.calories) <= 1E-3)) ?
                src[scl] :
                lcr;

            const dtst  = att !== 0;
            const fcdt  = fcr && fcr.date && fcr.date.substring(0, 10) || '';

            const mcdi  = (nvl ? 1 :
                          old ? (lcr.indexCdi / prt[0].indexCdi) :
                                lcr.indexCdi) - 1;
            const dcdi  = dtst ? (fcdt && fcdt <= dlm ? (lcr.indexCdi/fcr.indexCdi) - 1 : lcr.indexCdi - 1) : 1;
            const gcdi  = lcr.indexCdi - 1;

            const mipca = (nvl ? 1 :
                          old ? (lcr.indexIpca / prt[0].indexIpca) :
                                lcr.indexIpca) - 1;
            const dipca = dtst ? (fcdt && fcdt <= cdt ? (lcr.indexIpca/fcr.indexIpca) - 1 : lcr.indexIpca - 1) : 1;
            const gipca = lcr.indexIpca - 1;

            const mdolr = (nvl ? 1 :
                          old ? (lcr.indexDolar / prt[0].indexDolar) :
                                lcr.indexDolar) - 1;
            const ddolr = dtst ? (fcdt && fcdt <= cdt ? (lcr.indexDolar/fcr.indexDolar) - 1 : lcr.indexDolar - 1) : 1;
            const gdolr = lcr.indexDolar - 1;

            const mbols = (nvl ? 1 :
                          old ? (lcr.indexBolsa / prt[0].indexBolsa) :
                                lcr.indexBolsa) - 1;
            const dbols = dtst ? (fcdt && fcdt <= cdt ? (lcr.indexBolsa/fcr.indexBolsa) - 1 : lcr.indexBolsa - 1) : 1;
            const gbols = lcr.indexBolsa - 1;

            const lbd   = src[0];
            const term  = inicio && (lbd.date.substring(0, 10) < inicio);
            const ferm  = fcr && (inicio ? (fcr.date.substring(0,10) < inicio) : (fcr.date.substring(0, 10) < pdt));
            const tail  = {
                mensal: {
                    saldo: lcr.calories, acumulado: 100*(lcr.iron-1), razao: 0,
                    valor: (mat !== 0) ? lcr.fat : 0, taxa: mat,
                    cdi: mcdi, ipca: mipca, dolar: mdolr, ibovespa: mbols
                },
                doze: {
                    saldo: lcr.calories, acumulado: att, razao: 0,
                    valor: (att !== 0) ? (ferm ? ult.carbs - fcr.carbs : ult.carbs) : 0,
                    taxa: att,
                    cdi: dcdi, ipca: dipca, dolar: ddolr, ibovespa: dbols
                },
                geral: {
                    saldo: lcr.calories, acumulado: (lcr.iron - 1) * 100, razao: 0,
                    valor: term ? ult.carbs - lbd.carbs : ult.carbs,
                    taxa: (lcr.iron - 1) * 100,
                    cdi: gcdi, ipca: gipca, dolar: gdolr, ibovespa: gbols
                },
                ano: {
                    saldo: 0, acumulado: 0, razao: 0,
                    valor: 0, taxa: 0,
                    cdi: 0, ipca: 0, dolar: 0, ibovespa: 0
                },
                items: src,
                label: a.descricao
            };
            o.v12  += tail.doze.valor;
            o.vIni += tail.geral.valor;
            o.vy   += tail.ano.valor;
            o.vM   += tail.mensal.valor;

            o.items.push(tail);
            return o;
        }, { items: [], cotacoes: undefined, limit: '9999-99-99', v12: 0, vIni: 0, vy: 0, vM: 0 });
        part.items = elem.items;
        if (!grupo.length) {
            ["mensal", "doze", "geral","ano"].forEach((n) =>
                ["cdi", "ipca", "dolar", "ibovespa"].forEach((q) =>
                    part[n][q] = elem.cotacoes[q][n]));

            part.mensal.saldo       = 0;
            part.mensal.taxa        = 0;
            part.mensal.acumulado   = 0;
            part.mensal.valor       = 0;
            part.doze.saldo         = 0;
            part.doze.taxa          = 0;
            part.doze.acumulado     = 0;
            part.doze.valor         = 0;
            part.geral.saldo        = 0;
            part.geral.liquido      = 0;
            part.geral.taxa         = 0;
            part.geral.acumulado    = 0;
            part.geral.valor        = 0;

            part.calculos.length    = 0;
            part.ativos.length      = 0;
            part.cotacoes.length    = 0;
            part.grossup            = {
                grupo: vgup,
                items: grossup
            };
            delete part.calculos;
            delete part.ativos;
            delete part.cotacoes;
            return;
        }
        let   gcut = grupo.length - 1;
        let   mcut = gcut;
        const top  = grupo[gcut];
        const ult  = grupo[gcut];
        const fld  = grupo.map((a, i) => [a, i, Math.abs(a.acum) > 1E-3]).filter((a) => a[2]);
        const glst = (Math.abs(ult.acum) <= 1E-3) && fld.length ?
            fld[fld.length - 1][0] :
            ult;
        if ((Math.abs(ult.acum) <= 1E-3) && fld.length) {
            gcut = fld[fld.length - 1][1];
        }
        const ylst   = limits.start12Month;
        const dcut   = limits.start12Month;
        const ydate  = limits.startYear;
        let   ycut   = grupo.length - 1;
        const clst   = this.getStartOfMonth(limits.startMonth, grupo[ycut].tstamp);
        const mdct   = clst;
        while (ycut > 0 && grupo[ycut].tstamp >= ydate) { --ycut; }
        const usey   = grupo[ycut].tstamp < ydate;
        const yfst   = grupo[ycut];
        const yrtt   = Math.abs(glst.acum) <= 1E-3 ? 0 :
            usey ?
                (glst.acum / yfst.acum -1) * 100 :
                (glst.acum - 1) * 100;
        mcut = this.findBounds(grupo, mdct, true);
        gcut = this.findBounds(grupo, dcut, true);
        const gfst = grupo[gcut];
        let   grtt = Math.abs(glst.acum) <= 1E-3 ? 0 :
            (gcut !== 0) ?
                (((glst.acum / gfst.acum) - 1) * 100) :
                ((glst.acum - 1) * 100);
        if (mdct < ylst) { grtt = 0; }
        const mgfs = grupo[mcut];
        const mgst = grupo[mcut + 1];
        const mgrt = (mdct >= clst) && (Math.abs(mgfs.acum) > 1E-3) ?
            ((mgfs.tstamp < mdct) ? ((glst.acum / mgfs.acum) - 1) * 100 : (glst.acum - 1) * 100) :
            0;
        ["mensal", "doze", "geral","ano"].forEach((n) =>
            ["cdi", "ipca", "dolar", "ibovespa"].forEach((q) =>
                part[n][q] = elem.cotacoes[q][n]));
        const skip              = Math.abs(mgrt) <= 1E-3 ||
                                  Math.abs(glst.depois) <= 1E-3;  // a 2a parte pode ainda estar incorreta
        part.mensal.saldo       = top.depois;
        part.mensal.taxa        = skip ? 0 : mgrt;
        part.mensal.acumulado   = skip ? 0 : mgrt;
        part.mensal.valor       = skip ? 0 : elem.vM;
        part.doze.saldo         = top.depois;
        part.doze.taxa          = grtt;
        part.doze.acumulado     = grtt;
        part.doze.valor         = (grtt !== 0) ? elem.v12 : 0;

        part.ano.saldo          = top.depois;
        part.ano.taxa           = yrtt;
        part.ano.acum           = yrtt;
        part.ano.valor          = yrtt !== 0 ? elem.vy : 0;

        part.geral.saldo        = top.depois;
        part.geral.liquido      = glst.liquido;
        part.geral.taxa         = (glst.acum - 1) * 100;
        part.geral.acumulado    = (glst.acum - 1) * 100;
        part.geral.valor        = elem.vIni;
        part.calculos.length    = 0;
        part.ativos.length      = 0;
        part.cotacoes.length    = 0;
        part.grossup            = {
            grupo: vgup,
            items: grossup
        };
        delete part.calculos;
        delete part.ativos;
        delete part.cotacoes;
        delete part.finais;
    }
    calcularCarteira (ativos, inicio, final) {
        const cTotal = () => {
            return {
                saldo: 0, acumulado: 0, razao: 0, valor: 0, taxa: 0,
                cdi: 0, ipca: 0, dolar: 0, ibovespa: 0
            }
        };
        const cPart  = () => {
            return {
                mensal: cTotal(), doze: cTotal(), geral: cTotal(), ano: cTotal(),
                items: [], calculos: [], ativos: [], cotacoes: [], finais: []
            }
        };
        const cSection = (names) => names.reduce((o, n) => {
            n && (o[n] = cPart());
            return o;
        }, {});
        const { calc, cut, bottom, ending } = this.getCalculos(ativos);
        const bound = !(this.isVoid(inicio) || this.isVoid(final));
        const left  = !bound || (inicio < bottom) ? bottom : inicio;
        const right = !bound || (final  >    cut) ? cut    : final;
        const gqot  = this.extractGenericQuotes(left, right, bound);
        let lims                = { produto: {}, estrategia: {} };
        let view = ativos.reduce((o, a, i) => {
            let   cal   = calc[i];
            let   trm   = ending[i];
            const tpr   = this.getTProduto(a.idTProduto, a.tipo);
            const tet   = this.getEstrategia(a.idEstrategia);
            let   prd   = o.produto[tpr];
            let   est   = o.estrategia[tet];
            if (!(prd && est)) {
                return o;
            }
            if (cal.length === 0) {
                [o.geral, prd, est].forEach((iob) => {
                    iob.calculos.push(cal);
                    iob.ativos.push(a);
                    iob.cotacoes.push({
                        cdi     : { mensal: 0, doze: 0, ano: 0, geral: 0 },
                        ipca    : { mensal: 0, doze: 0, ano: 0, geral: 0 },
                        dolar   : { mensal: 0, doze: 0, ano: 0, geral: 0 },
                        ibovespa: { mensal: 0, doze: 0, ano: 0, geral: 0 }
                    });
                    iob.finais.push(trm);
                });
                return o;
            }
            const aend  = cal[cal.length - 1].date.substring(0, 10);
            const alim  = aend < cut ? aend : cut;
            const qot   = this.extractQuotes(cal, formatISO(cal[0].date), bound && alim > right ? right : alim);
            if (lims.produto[tpr]) {
                let lgrp = lims.produto[tpr];
                if (a.dataInvestimento < lgrp.dataInvestimento) {
                    lgrp.dataInvestimento = a.dataInvestimento;
                }
                const end = a.vencimento || cut;
                if (end > lgrp.vencimento) {
                    lgrp.vencimento = end;
                }
            } else {
                lims.produto[tpr] = {
                    dataInvestimento: a.dataInvestimento,
                    vencimento: a.vencimento || cut,
                    indexador: undefined,
                    cotacoes: []
                }
            }
            if (lims.estrategia[tet]) {
                let lgrp = lims.estrategia[tet];
                if (a.dataInvestimento < lgrp.dataInvestimento) {
                    lgrp.dataInvestimento = a.dataInvestimento;
                }
                const end = a.vencimento || cut;
                if (end > lgrp.vencimento) {
                    lgrp.vencimento = end;
                }
            } else {
                lims.estrategia[tet] = {
                    dataInvestimento: a.dataInvestimento,
                    vencimento: a.vencimento || cut,
                    indexador: undefined,
                    cotacoes: []
                };
            }
            [o.geral, prd, est].forEach((iob) => {
                iob.calculos.push(cal);
                iob.ativos.push(a);
                iob.cotacoes.push(qot);
                iob.finais.push(trm);
            });
            return o;
        }, { geral: cPart(), produto: cSection(NPRODUTOS), estrategia: cSection(ESTRATEGIAS) });
        const limits = this.getLimits(final);
        this.fillPart(view.geral, gqot, inicio, final, limits);
        NPRODUTOS.forEach((n) => {
            const lim  = lims.produto[n];
            const bot  = lim ?
                (lim.dataInvestimento > left ? lim.dataInvestimento : left) :
                left;
            const top  = lim ?
                (lim.vencimento < right ? lim.vencimento : right) :
                right;
            const pqot = lim ?
                this.extractGenericQuotes(bot, top, bound) :
                undefined;
            this.fillPart(view.produto[n], pqot, inicio, final, limits);
        });
        ESTRATEGIAS.filter((n) => !!n).forEach((n) => {
            const lim  = lims.estrategia[n];
            const bot  = lim ?
                (lim.dataInvestimento > left ? lim.dataInvestimento : left) :
                left;
            const top  = lim ?
                (lim.vencimento < right ? lim.vencimento : right) :
                right;
            const eqot = lim ?
                this.extractGenericQuotes(bot, top, bound) :
                undefined;
            this.fillPart(view.estrategia[n], eqot, inicio, final, limits);
        });
        const conv0 = (part, div) => {
            return {
                ganho: part.taxa / 100,
                percentual: 0,
                fatia: part.taxa / 100,
                valor: part.valor,
                participacao: 1,
                vganho: part.taxa  / 100,
                vfatia: part.taxa  / 100
            };
        };
        const conv1 = (n, part) => {
            return { mensal: part.mensal[n], doze: part.doze[n], geral: part.geral[n], ano: part.ano[n] }
        };
        const conv2 = (part) => {
            return {
                cdi: conv1('cdi', part),
                ipca: conv1('ipca', part),
                dolar: conv1('dolar', part),
                ibovespa: conv1('ibovespa', part)
            };
        };
        const conv3 = (part, div) => {
            return {
                saldo: part.geral.saldo,
                liquido: part.geral.liquido,
                mensal: conv0(part.mensal, div),
                doze: conv0(part.doze, div),
                geral: conv0(part.geral, div),
                ano: conv0(part.ano, div),
                cotacoes: conv2(part),
                grossup: part.grossup
            };
        };
        const conv5 = (item) => {
            return {
                ativo: { descricao: item.label },
                evolucao: conv3(item, true)
            }
        };
        const conv6 = (part) => {
            return {
                total: conv3(part),
                items: part.items.map(conv5)
            };
        };
        const convP = (part) => {
            return NPRODUTOS.reduce((o, n) => {
                if (!n) { return o; }
                o[n] = conv6(part[n]);
                return o;
            }, {});
        };
        const convE = (part) => {
            return ESTRATEGIAS.reduce((o, n) => {
                if (!n) { return o; }
                o[n] = conv6(part[n]);
                return o;
            }, {});
        };
        const start = view.geral.items.some(e => e.items.length > 0)
            ? left
            : inicio;
        const data = {
            produto: convP(view.produto),
            estrategia: convE(view.estrategia),
            total: conv3(view.geral),
            termino: right,
            periodo: `${new Date(start + 'T00:00:00').toLocaleDateString()} a ${new Date(right + 'T00:00:00').toLocaleDateString()}`,
            bound
        };
        return new Carteira(data);
    }
    geraCotacoes (start, finish) {
        const fmt   = (v) => v.length <= 10 ? v + 'T00:00:00' : v;
        const left  = fmt(start);
        const right = fmt(finish);
        const lpt   = QUOTEFLT.getBound(this.cdi, left, true);
        const fdat  = this.cdi[lpt + 1].data;
        const ldat  = undefined;
        const fcal  = this.calcular({
            ativoId             : 0,
            idTProduto          : 4,
            idEstrategia        : 2,
            percentege          : 1,
            liquidez            : 0,
            referencia          : 'CDI',
            taxa                : 100,
            fgc                 : true,
            indexador           : 'CDI',
            value               : 100,
            dataInvestimento    : fdat,
            vencimento          : ldat,
            movimentos          : [{
                id              : 0,
                investedValue   : 100,
                shareQuantity   : 0,
                shareValue      : 0,
                investmentDate  : fdat,
                tpMovimento     : 1,
                custodianteId   : 4
            }],
            cotacoes            : [],
            eventos             : []
        });

        let store   = { cdi: void 0, ipca: void 0, dolar: void 0, bolsa: void 0 };
        return fcal.dessertsCalc.reduce((o, e, i) => {
            const tstamp = e.date.substring(0, 10);
            const month  = tstamp.substring(0, 7);
            let   carray = o.cdi[month] || (o.cdi[month] = []);
            let   cdi    = e.indexCdi;
            const cprv   = store.cdi;
            store.cdi    = {
                percent : cprv ? (cdi / cprv.index - 1) * 100 : 0,
                acum    : (cdi - 1) * 100,
                index   : cdi,
                tstamp
            };
            carray.push(store.cdi);
            let   iarray = o.ipca[month] || (o.ipca[month] = []);
            let   ipca   = e.indexIpca;
            const iprv   = store.ipca;
            store.ipca   = {
                percent : iprv ? (ipca / iprv.index - 1) * 100 : 0,
                acum    : (ipca - 1) * 100,
                index   : ipca,
                tstamp
            };
            iarray.push(store.ipca);
            let   darray = o.dolar[month] || (o.dolar[month] = []);
            let   dolar  = e.indexDolar;
            const dprv   = store.dolar;
            store.dolar  = {
                percent : dprv ? (dolar / dprv.index - 1) * 100 : 0,
                acum    : (dolar - 1) * 100,
                index   : dolar,
                tstamp
            };
            darray.push(store.dolar);
            let   barray= o.ibovespa[month] || (o.ibovespa[month] = []);
            let   bolsa = e.indexBolsa;
            const bprv  = store.bolsa;
            store.bolsa = {
                percent : bprv ? (bolsa / bprv.index - 1) * 100 : 0,
                acum    : (bolsa - 1) * 100,
                index   : bolsa,
                tstamp
            };
            barray.push(store.bolsa);
            return o;
        }, {
            cdi     : {},
            ipca    : {},
            dolar   : {},
            ibovespa: {}
        });
    }
    rentabilidadeFull (ativos) {
        if (!(ativos && ativos.length)) {
            return {
                inicio: '',
                periodo: '',
                carteira: '',
                saldo: '',
                dados: [],
                anual: []
            };
        }
        const { calc, cut, ending } = this.getCalculos(ativos);
        const rent  = this.rentabilidadeGrupo(ativos, calc, ending, true );
        const {
            grupo,
            min,
            last,
            total: cotas
        } = rent;
        const start = grupo.index[0];
        const fnsh  = cut;
        const qot   = this.geraCotacoes(grupo.lead, cut);
        let res     = [];
        let curr    = start;
        let prev    = start;
        while (curr <= fnsh) {
            const grp   = grupo[curr];
            if (grp && grp.length) {
                prev = curr;
            } else {
                grp     = grupo[prev];
            }
            const tail = grp[grp.length - 1];
            const amul = tail.acum;
            const acum = (amul - 1) * 100;
            const mult = grp.reduce((v, e) => v * (1 + e.taxa / 100), 1);
            const taxa = (mult - 1) * 100;
            const cdi  = qot.cdi[curr];
            const ipca = qot.ipca[curr];
            const dolr = qot.dolar[curr];
            const ibov = qot.ibovespa[curr];
            const fst  = (a) => a && a.length && a[0] || undefined;
            const lst  = (a) => a && a.length && a[a.length - 1] || undefined;
            const rat  = (a) => {
                if (!(a && a.length)) { return 0; }
                const val = a.reduce((v, e) => v * (1 + e.percent / 100), 1);
                return (val - 1) * 100;
            }
            const prv  = lst(res);
            res.push({
                taxa,
                acum,
                saldo: tail.depois,
                valor: tail.depois - grp[0].antes,
                cdi: {
                    taxa: rat(cdi),
                    acum: lst(cdi)?.acum || prv?.cdi.acum || 1,
                    mes: lst(cdi)?.data || curr + "-01"
                },
                ipca: {
                    taxa: rat(ipca),
                    acum: lst(ipca)?.acum || prv?.ipca.acum || 1,
                    mes: lst(ipca)?.data || curr + "-01"
                },
                dolar: {
                    taxa: rat(dolr),
                    acum: lst(dolr)?.acum || prv?.dolar.acum || 1,
                    mes: lst(dolr)?.data || curr + "-01"
                },
                ibovespa: {
                    taxa: rat(ibov),
                    acum: lst(ibov)?.acum || prv?.ibovespa.acum || 1,
                    mes: lst(ibov)?.data || curr + "-01"
                },
                tstamp: curr,
                date  : tail.tstamp
            });
            let year    = +curr.substring(0, 4);
            let month   = +curr.substring(5, 7);
            let nyear   = `${month < 12 ? year : year + 1}`;
            let nmonth  = `${month < 12 ? month + 1 : 1}`;
            curr        = `${nyear.padStart(4, 101)}-${nmonth.padStart(2, '0')}`;
        }
        const rlast     = res[res.length - 1];
        const dhead     = new Date(min + 'T00:00:00').toLocaleDateString();
        const dtail     = new Date(last + 'T00:00:00').toLocaleDateString();
        const ano       = +res[0].tstamp.substring(0,4);
        const saldo     = rlast.saldo;
        const fsaldo    = this.numberToLocale(saldo);
        let   total     = 0;
        const dados     = res.map((o, i) => {
            return {
                year: o.date.substring(0, 4),
                mes: o.date.substring(0, 7),
                carteira: {
                    taxa: o.taxa,
                    valor: o.valor,
                    total: total += o.valor, //res.slice(0, i + 1).reduce((v, e) => v + e.valor, 0),
                    saldo: o.saldo,
                    fatia: o.taxa / 100,
                    peso: 1,
                    mes: `${o.date}T00:00:00`,
                    rotulo: this.dateToLocaleMonth(o.date),
                    label: "PORTFÓLIO"
                },
                cdi: {
                    taxa: o.cdi.taxa,
                    acum: o.cdi.acum / 100,
                    mes: o.cdi.mes,
                    rotulo: this.dateToLocaleMonth(o.cdi.mes),
                    label: "CDI"
                },
                ipca: {
                    taxa: o.ipca.taxa,
                    acum: o.ipca.acum / 100,
                    mes: o.ipca.mes,
                    rotulo: this.dateToLocaleMonth(o.ipca.mes),
                    label: "IPCA"
                },
                dolar: {
                    taxa: o.dolar.taxa,
                    acum: o.dolar.acum / 100,
                    mes: o.dolar.mes,
                    rotulo: this.dateToLocaleMonth(o.dolar.mes),
                    label: "DOLAR"
                },
                ibovespa: {
                    taxa: o.ibovespa.taxa,
                    acum: o.ibovespa.acum / 100,
                    mes: o.ibovespa.mes,
                    rotulo: this.dateToLocaleMonth(o.ibovespa.mes),
                    label: "IBOVESPA"
                }
            }
        });
        const gMonth    = (v) => new Array(12).fill(v);
        const src       = dados.reduce((o, e) => {
            const mth   = e.mes.substring(0, 7);
            const [pos, prv] = o.index.length && o.index[mth] || [0, undefined];
            if (prv) {
                if (prv.mes < e.mes) {
                    o.items[pos] = e;
                    o.index[mth] = [pos, e];
                }
            } else {
                o.items.push(e);
                o.index[mth] = [o.items.length, e];
            }
            return o;
        }, { items: [], index: {} });
        const anual     = src.items.reduce((o, d) => {
            const year  = d.year;
            const month = (+d.mes.substring(5, 7)) - 1;
            let   prev  = o.index[year];
            if (!prev) {
                prev = {
                    year,
                    saldo: { ano: 0, mensal: gMonth(0) },
                    mes: { carteira: gMonth(), cdi: gMonth(), ipca: gMonth(), dolar: gMonth(), ibovespa: gMonth() },
                    anual: { carteira: 0, cdi: 0, ipca: 0, dolar: 0, ibovespa: 0 },
                    geral: { carteira: 0, cdi: 0, ipca: 0, dolar: 0, ibovespa: 0 }
                };
                o.index[year] = prev;
                o.items.push(prev);
            }
            const r2m = (v) => 1 + v / 100;
            const m2r = (v) => (v - 1) * 100;
            const adp = (o, v) => m2r(r2m(o) * r2m(v));
            prev.saldo.ano           += d.carteira.saldo;
            prev.saldo.mensal[month]  = d.carteira.saldo;
            prev.anual.carteira       = adp(prev.anual.carteira, d.carteira.taxa);
            prev.anual.cdi            = adp(prev.anual.cdi, d.cdi.taxa);
            prev.anual.ipca           = adp(prev.anual.ipca, d.ipca.taxa);
            prev.anual.dolar          = adp(prev.anual.dolar, d.dolar.taxa);
            prev.anual.ibovespa       = adp(prev.anual.ibovespa, d.ibovespa.taxa);
            prev.geral.carteira       = adp(prev.geral.carteira, d.carteira.taxa);
            prev.geral.cdi            = adp(prev.geral.cdi, d.cdi.taxa);
            prev.geral.ipca           = adp(prev.geral.ipca, d.ipca.taxa);
            prev.geral.dolar          = adp(prev.geral.dolar, d.dolar.taxa);
            prev.geral.ibovespa       = adp(prev.geral.ibovespa, d.ibovespa.taxa);
            return o;
        }, { items: [], index: {} });
        const result    = {
            inicio: `${ano}`,
            periodo: `De ${dhead} to ${dtail}`,
            carteira: `R$ ${fsaldo}`,
            saldo,
            dados,
            anual: anual.items
        };
        return result;
    }

    //#endregion

    //#region Evolucao

    calcularEvolucao(ativo, addCotacoes, addPrevious) {
        let data  = this.calcular(ativo, true);
        let input = data.dessertsCalc || [];
        let limit = input.length && (input.length - 1) || 0;
        let last  = limit >= 0 ? input[limit] : undefined;
        let start = limit > 10 ? limit - 11 : 0;
        let today = new Date();
        let mcut  = new Date(today.getFullYear() - 1, today.getMonth(), 1)
            .toISOString()
            .substring(0, 10);
        while ((start > 0) && input[start].date.substring(0, 10) >= mcut) { --start; }
        let round = input.reduce((o, r, i) => {
            let irate = (1.0 + r.protein / 100.0);
            o.geral.ganho += r.fat;
            o.geral.percentual *= irate;
            if (i >= start) { // 12 meses
                o.doze.ganho += r.fat;
                o.doze.percentual *= irate
            }
            if (i === limit) { // mensal
                o.mes.ganho += r.fat;
                o.mes.percentual *= irate
            }
            return o;
        }, {
            mes  : { ganho: 0, percentual: 1 },
            doze : { ganho: 0, percentual: 1 },
            geral: { ganho: 0, percentual: 1 }
        });
        if ((ativo.idTProduto !== 1) && (ativo.idTProduto !== 4) && input.length) {
            let dbot = input[start].iron;
            let dtop = input[limit].iron;
            round.doze.percentual = (input[start].date > mcut) ? dtop : dbot && (dtop / dbot) || 0;
            round.geral.percentual = input[limit].iron;
        }
        round.mes.percentual   -= 1.0;
        round.doze.percentual  -= 1.0;
        round.geral.percentual -= 1.0;
        return last && {
            saldo       : last.calories,
            liquido     : last.saldoLiquido,
            mensal      : {
                ganho   : last.protein / 100.0,
                percentual: 0,
                fatia   : last.protein / 100.0,
                valor   : last.fat,
                participacao: 1
            },
            doze        : {
                ganho   : round.doze.percentual,
                percentual: 0,
                fatia   : round.doze.percentual,
                valor   : round.doze.ganho,
                participacao: 1
            },
            geral       : {
                ganho   : round.geral.percentual,
                percentual: 0,
                fatia   : round.geral.percentual,
                valor   : round.geral.ganho,
                participacao: 1
            },
            cotacoes    : addCotacoes
                ? this.extractGenericQuotes(ativo.dataInvestimento, ativo.vencimento, false)
                : {
                    cdi     : { mensal: 0, doze: 0, geral: 0 },
                    ipca    : { mensal: 0, doze: 0, geral: 0 },
                    dolar   : { mensal: 0, doze: 0, geral: 0 },
                    ibovespa: { mensal: 0, doze: 0, geral: 0 }
                }
        } || {
            saldo       : 0,
            liquido     : 0,
            mensal      : { ganho: 0, percentual: 0, fatia: 0, valor: 0, participacao: 1 },
            doze        : { ganho: 0, percentual: 0, fatia: 0, valor: 0, participacao: 1 },
            geral       : { ganho: 0, percentual: 0, fatia: 0, valor: 0, participacao: 1 },
            cotacoes    : {
                cdi     : { mensal: 0, doze: 0, geral: 0 },
                ipca    : { mensal: 0, doze: 0, geral: 0 },
                dolar   : { mensal: 0, doze: 0, geral: 0 },
                ibovespa: { mensal: 0, doze: 0, geral: 0 }
            }
        };
    }
    extrairEvolucao (ativo, calculos, addCotacoes, addPrevious) {
        let input = calculos;
        let limit = input.length && (input.length - 1) || 0;
        let last  = limit >= 0 ? input[limit] : undefined;
        let start = limit > 10 ? limit - 11 : 0;
        let round = input.reduce((o, r, i) => {
            let irate = (1.0 + r.protein / 100.0);
            o.geral.ganho += r.fat;
            o.geral.percentual *= irate;
            if (i >= start) { // 12 meses
                o.doze.ganho += r.fat;
                o.doze.percentual *= irate
            }
            if (i === limit) { // mensal
                o.mes.ganho += r.fat;
                o.mes.percentual *= irate
            }
            return o;
        }, {
            mes  : { ganho: 0, percentual: 1 },
            doze : { ganho: 0, percentual: 1 },
            geral: { ganho: 0, percentual: 1 }
        });
        round.mes.percentual   -= 1.0;
        round.doze.percentual  -= 1.0;
        round.geral.percentual -= 1.0;
        return last && {
            saldo       : last.calories,
            liquido     : last.saldoLiquido,
            mensal      : {
                ganho   : last.protein / 100.0,
                percentual: 0,
                fatia   : last.protein / 100.0,
                valor   : last.fat,
                participacao: 1
            },
            doze        : {
                ganho   : round.doze.percentual,
                percentual: 0,
                fatia   : round.doze.percentual,
                valor   : round.doze.ganho,
                participacao: 1
            },
            geral       : {
                ganho   : round.geral.percentual,
                percentual: 0,
                fatia   : round.geral.percentual,
                valor   : round.geral.ganho,
                participacao: 1
            },
            cotacoes    : addCotacoes
                ? this.extractGenericQuotes(ativo.dataInvestimento, ativo.vencimento, false)
                : {
                    cdi     : { mensal: 0, doze: 0, geral: 0 },
                    ipca    : { mensal: 0, doze: 0, geral: 0 },
                    dolar   : { mensal: 0, doze: 0, geral: 0 },
                    ibovespa: { mensal: 0, doze: 0, geral: 0 }
                }
        } || {
            saldo       : 0,
            liquido     : 0,
            mensal      : { ganho: 0, percentual: 0, fatia: 0, valor: 0, participacao: 1 },
            doze        : { ganho: 0, percentual: 0, fatia: 0, valor: 0, participacao: 1 },
            geral       : { ganho: 0, percentual: 0, fatia: 0, valor: 0, participacao: 1 },
            cotacoes    : {
                cdi     : { mensal: 0, doze: 0, geral: 0 },
                ipca    : { mensal: 0, doze: 0, geral: 0 },
                dolar   : { mensal: 0, doze: 0, geral: 0 },
                ibovespa: { mensal: 0, doze: 0, geral: 0 }
            }
        };
    }

    //#endregion

    //#region Carteira

    getTProduto(idTProduto, tipo) {
        if (idTProduto < 0) { idTProduto = 0; }
        else if (idTProduto === 10) { idTProduto = 2; }
        else if (idTProduto === 11) { idTProduto = 6; }
        if (idTProduto === 8 && tipo === 'FIPREV') { return "previdencia"; }
        return idTProduto < TPRODUTOS.length
            ? TPRODUTOS[idTProduto]
            : undefined;
    }
    getEstrategia(idEstrategia) {
        if (idEstrategia < 0) { idEstrategia = 0; }
        return idEstrategia < ESTRATEGIAS.length
            ? ESTRATEGIAS[idEstrategia]
            : undefined;
    }
    getDefaultPart () {
        return {
            total           : {
                saldo       : 0,
                liquido     : 0,
                mensal      : { ganho: 0, percentual: 0, fatia: 0, valor: 0, participacao: 1 },
                doze        : { ganho: 0, percentual: 0, fatia: 0, valor: 0, participacao: 1 },
                geral       : { ganho: 0, percentual: 0, fatia: 0, valor: 0, participacao: 1 },
                cotacoes    : {
                    cdi     : { mensal: 0, doze: 0, geral: 0 },
                    ipca    : { mensal: 0, doze: 0, geral: 0 },
                    dolar   : { mensal: 0, doze: 0, geral: 0 },
                    ibovespa: { mensal: 0, doze: 0, geral: 0 }
                }
            },
            items: []
        }
    }
    deepClone (obj) {
        if (this.isVoid(obj)) { return obj; }
        return JSON.parse(JSON.stringify(obj));
    }
    expandirMeses (data) {
        if (!data) { return []; }
        let edate   = new Date();
        let etime   = edate.toISOString().substring(0, 7);
        let syear   = +data.substring(0, 4);
        let smonth  = (+data.substring(5, 7)) - 1;;
        let stime   = data.substring(0, 7);
        let index   = 0;
        let curr    = { ano: syear, mes: smonth, tstamp: stime, saldo: 0, index };
        let result  = [curr];
        while (stime < etime) {
            smonth  = (smonth + 1) % 12;
            if (smonth === 0) { ++syear; }
            let pmonth = `${smonth + 1}`;
            stime   = `${syear}-${pmonth.padStart(2, '0')}`;
            ++index;
            result.push({ ano: syear, mes: smonth, tstamp: stime, saldo: 0, index });
        }
        return result;
    }
    wrap (dadosCarteira) {
        return new Carteira(dadosCarteira);
    }

    //#endregion

    //#region Liquidez

    selecionarFaixa (view, ativo) {
        // Títulos públicos, ações, fundos imobiliários = "um".
        if ([5, 6, 7].indexOf(ativo.idTProduto) >= 0) {
            return view.um;
        }
        // Liquidez diárias ou sem vencimento = "zero".
        if ((ativo.liquidez === 0) || this.isVoid(ativo.vencimento)) {
            return view.zero;
        }
        // Faixas pelo vencimento
        let days = diffAsDays(this.currentDate(), ativo.vencimento);
        return days <    1               ? view.zero
            :  days >=   1 && days <  31 ? view.um
            :  days >=  31 && days <  91 ? view.trinta
            :  days >=  91 && days < 181 ? view.noventa
            :  days >= 181 && days < 361 ? view.cento
            :                              view.anos;
    }
    calcularLiquidez (ativos) {
        let total = 0;
        let view = ativos.reduce((r, a) => {
            let evo = this.calcularEvolucao(a);
            let rec = {
                ativo   : a,
                evolucao: evo
            };
            total            += a.value;
            let band          = this.selecionarFaixa(r, a);
            band.saldo       += rec.evolucao.saldo;
            band.liquido     += rec.evolucao.liquido;
            band.items.push(rec);
            band.participacao = band.items.reduce((r, e) => r + (e.ativo.value || 0), 0);
            band.ganho       += rec.evolucao.geral.ganho;
            band.fatia       += rec.evolucao.geral.fatia;
            band.valor       += rec.evolucao.saldo;
            r.total.items.push(rec);
            r.total.saldo    += rec.evolucao.saldo;;
            r.total.liquido  += rec.evolucao.liquido;
            r.total.ganho    += rec.evolucao.geral.ganho;
            r.total.fatia    += rec.evolucao.geral.fatia;
            r.total.valor    += rec.evolucao.saldo;

            return r;
        }, {
            zero            : {
                title       : "0 dias",
                items       : [],
                saldo       : 0,
                liquido     : 0,
                participacao: 0,
                ganho       : 0,
                fatia       : 0,
                valor       : 0
            },
            um              : {
                title       : "de 1 a 30 dias",
                items       : [],
                saldo       : 0,
                liquido     : 0,
                participacao: 0,
                ganho       : 0,
                fatia       : 0,
                valor       : 0
            },
            trinta          : {
                title       : "de 31 a 90 dias",
                items       : [],
                saldo       : 0,
                liquido     : 0,
                participacao: 0,
                ganho       : 0,
                fatia       : 0,
                valor       : 0
            },
            noventa         : {
                title       : "de 91 a 180 dias",
                items       : [],
                saldo       : 0,
                liquido     : 0,
                participacao: 0,
                ganho       : 0,
                fatia       : 0,
                valor       : 0
            },
            cento           : {
                title       : "de 181 a 360 dias",
                items       : [],
                saldo       : 0,
                liquido     : 0,
                participacao: 0,
                ganho       : 0,
                fatia       : 0,
                valor       : 0
            },
            anos            : {
                title       : "acima de 361 dias",
                items       : [],
                saldo       : 0,
                liquido     : 0,
                participacao: 0,
                ganho       : 0,
                fatia       : 0,
                valor       : 0
            },
            total           : {
                title       : "Total",
                items       : [],
                saldo       : 0,
                liquido     : 0,
                participacao: 1.0,
                ganho       : 0,
                fatia       : 0,
                valor       : 0
            },
            collection      : []
        });
        if (total) {
	        view.zero.participacao    /= total;
	        view.um.participacao      /= total;
	        view.trinta.participacao  /= total;
	        view.noventa.participacao /= total;
	        view.cento.participacao   /= total;
	        view.anos.participacao    /= total;
        }
        view.collection = [
            view.zero, view.um, view.trinta, view.noventa,
            view.cento, view.anos, view.total
        ];
        let current          = view.zero;
        view.zero.percent    = view.zero.participacao;
        view.zero.acumulado  = view.zero.liquido;
        view.total.percent   = 1.0;
        view.total.acumulado = view.total.liquido;
        for (let i = 1; i < view.collection.length - 1; ++i) {
            let next         = view.collection[i];
            next.valor      += current.valor;
            next.percent     = next.participacao + current.percent;
            next.acumulado   = next.liquido + current.acumulado;
            current          = next;
        }
        return view;
    }

    //#endregion

    //#region Rentabilidade

    calcularRentabilidade (ativos) {
        let carteira            = this.calcularCarteira(ativos);
        let saldo               = carteira.total.saldo;
        let est                 = carteira.estrategia;
        let strat               = [
            100.0 * carteira.estrategia.jurosPre.total.geral.ganho || 0,
            100.0 * carteira.estrategia.jurosPosCdi.total.geral.ganho || 0,
            100.0 * carteira.estrategia.jurosPosInflacao.total.geral.ganho || 0,
            100.0 * carteira.estrategia.rendaVariavel.total.geral.ganho || 0,
            100.0 * carteira.estrategia.cambio?.total.geral.ganho || 0,
            100.0 * carteira.estrategia.livre?.total.geral.ganho || 0
        ];
        let sfull               = strat.reduce((r, v) => v + r, 0);
        let stot                = 100.0 * carteira.total.geral?.ganho || 0;
        let svals               = strat.map((v) => stot * v / sfull);
        let view                = {
            rentabilidade       : {},
            resultado           : {},
            atribuicao          : {
                jurosPre        : svals[0],
                jurosPosCdi     : svals[1],
                jurosPosInflacao: svals[2],
                rendaVariavel   : svals[3],
                cambio          : svals[4],
                livre           : svals[5],
                total           : stot
            },
            alocacao            : {
                jurosPre        : saldo && est.jurosPre?.total.saldo / saldo || 0,
                jurosPosCdi     : saldo && est.jurosPosCdi?.total.saldo / saldo || 0,
                jurosPosInflacao: saldo && est.jurosPosInflacao.total.saldo / saldo || 0,
                rendaVariavel   : saldo && est.rendaVariavel.total.saldo / saldo || 0,
                cambio          : saldo && est.cambio?.total.saldo / saldo || 0,
                livre           : saldo && est.livre?.total.saldo / saldo || 0
            },
            desempenho          : {}
        };

        return view;
    }

    //#endregion

    //#region Mes a mes

    calcular12UltimosMeses(ativo, brief) {
        let calculo = this.calcular(ativo, brief);
        let data    = calculo.dessertsCalc;
        let size    = data.length;
        let cut     = data.length > 12 ? size - 12 : 0;
        let end     = new Date(data[size - 1].date.substring(0, 10) + 'T00:00:00');
        let begin   = new Date(end.getFullYear() - 1, end.getMonth() + 1, 1)
            .toISOString()
            .substring(0, 10);
        while (cut > 0 && data[cut].date >= begin) { --cut; }
        let actual  = cut > 0 ? data.slice(cut + 1) : data;
        return actual;
    }
    expandir (cotacoes, isXtra) {
        let r                   = cotacoes.length && cotacoes[0];
        let result              = cotacoes.map((g, i) => {
                return {
                    label       : this.dateToLocaleMonth(g.data),
                    data        : this.dateFormat(g.data),
                    time        : g.data.substring(0, 7),
                    valor       : g.valor,
                    percent     : isXtra ? (g.percent * 100) : g.percent,
                    acumulado   : isXtra ? ((g.fatCorrAcu - r.fatCorrAcu) / r.fatCorrAcu) : g.fatCorrAcu - 1
                };
            });
        return result;
    }
    getDateFilter (c) {
        let b = c.date.substring(0, 7);
        return ((x) => x.data.substring(0, 7) === b);
    }
    getTimeFilter (c) {
        let b = c.date.substring(0, 7);
        return ((x) => x.time === b);
    }
    calcular12UltCarteira (ativos) {
        const { calc, ending }  = this.getCalculos(ativos);
        const full              = this.rentabilidadeGrupo(ativos, calc, ending);
        const rent              = full.grupo;
        let   rcut              = rent.length > 12 ? rent.length - 12 : 0;
        let   rend              = rent.length ? new Date(rent[rent.length - 1].tstamp + 'T00:00:00') : new Date;
        let   rbeg              = new Date(rend.getFullYear() - 1, rend.getMonth() + 1, 1)
            .toISOString()
            .substring(0, 10);
        while (rcut > 0 && rent[rcut].tstamp > rbeg) { --rcut; }
        const rdat              = rcut > 0 ? rent.slice(rcut + 1) : rent;
        const cpye              = (e, tstamp) => {
            return {
                antes       : e.antes,
                depois      : e.depois,
                taxa        : e.taxa,
                acum        : e.acum,
                nil         : false,
                valor       : 0,
                tstamp
            }
        };
        const racm              = rdat.reduce((a, e, i) => {
            const tstamp        = e.tstamp.substring(0, 7);
            const fatIni        = calc.reduce((v, c) => {
                const tgt = c.filter(e => e.date.substring(0, 7) === tstamp);
                const val = tgt.length && tgt[tgt.length - 1].fat || 0;
                return v + val;
            }, 0);
            if (i === 0) {
                const cpy = cpye(e, tstamp);
                cpy.valor = fatIni;
                a.push(cpy);
                return a;
            }
            let prev            = a[a.length - 1];
            if (prev.tstamp !== tstamp) {
                const cpy = cpye(e, tstamp);
                cpy.valor = fatIni;
                a.push(cpy);
                return a;
            }
            prev.depois     = e.depois;
            prev.acum       = e.acum;
            prev.taxa       = ((1 + prev.taxa/100) * (1 + e.taxa/100) - 1) * 100;
            return a;
        }, []);

        let   catv              = {
            dataInvestimento    : racm.length && racm[0].tstamp || undefined,
            vencimento          : undefined,
            cotacoes            : []
        };
        let cdi                 = this.calculaCotacaoMes(catv, catv.indexador = "CDI", true, true);
        let ecdi                = this.expandir(cdi);
        rcut                    = cdi.length > 12 ? cdi.length - 12 : 0;
        while (rcut > 0 && cdi[rcut].data > rbeg) { --rcut; }
        if (rcut > 0) {
            cdi                 = cdi.slice(rcut);
            ecdi                = ecdi.slice(rcut);
        }

        let ipca                = this.calculaCotacaoMes(catv, catv.indexador = "IPCA", true, true);
        let eipca               = this.expandir(ipca);
        rcut                    = ipca.length > 12 ? ipca.length - 12 : 0;
        while (rcut > 0 && ipca[rcut].data > rbeg) { --rcut; }
        if (rcut > 0) {
            ipca                = ipca.slice(rcut);
            eipca               = eipca.slice(rcut);
        }

        let dolar               = this.calculaCotacaoMes(catv, catv.indexador = "DOLAR", true, true);
        let edolar              = this.expandir(dolar);
        rcut                    = dolar.length > 12 ? dolar.length - 12 : 0;
        while (rcut > 0 && dolar[rcut].data > rbeg) { --rcut; }
        if (rcut > 0) {
            dolar               = dolar.slice(rcut);
            edolar              = edolar.slice(rcut);
        }

        let ibovespa            = this.calculaCotacaoMes(catv, catv.indexador = "IBOVESPA", true, true);
        let eibovespa           = this.expandir(ibovespa);
        rcut                    = ibovespa.length > 12 ? ibovespa.length - 12 : 0;
        while (rcut > 0 && ibovespa[rcut].data > rbeg) { --rcut; }
        if (rcut > 0) {
            ibovespa            = ibovespa.slice(rcut);
            eibovespa           = eibovespa.slice(rcut);
        }

        const gdate             = (e) => {
            const tstamp = e.tstamp.substring(0, 7);
            return (o) => o.data.substring(0, 7) === tstamp;
        };
        const gtime             = (e) => {
            const tstamp = e.tstamp.substring(0, 7);
            return (o) => o.time.substring(0, 7) === tstamp;
        }
        const capit             = (s) => {
            if (!s) { return s }
            return s.substring(0, 1).toUpperCase() + s.substring(1);
        }
        let grps                = racm.map((o, i) => {
            const rcdi          = cdi.filter(gdate(o));
            const recdi         = ecdi.filter(gtime(o));
            const tcdi          = recdi.length && recdi[0].percent || 0;
            const vcdi          = o.antes + o.antes * tcdi;
            const ripca         = ipca.filter(gdate(o));
            const reipca        = eipca.filter(gtime(o));
            const tipca         = reipca.length && reipca[0].percent || 0;
            const vipca         = o.antes + o.antes * tipca;
            const rdolar        = dolar.filter(gdate(o));
            const redolar       = edolar.filter(gtime(o));
            const tdolar        = redolar.length && redolar[0].percent || 0;
            const vdolar        = o.antes + o.antes * tdolar;
            const ribovespa     = ibovespa.filter(gdate(o));
            const reibovespa    = eibovespa.filter(gtime(o));
            const tibovespa     = reibovespa.length && reibovespa[0].percent || 0;
            const vibovespa     = o.antes + o.antes * tibovespa;
            const mes           = o.tstamp;
            const rotulo        = capit(this.dateToLocaleMonth(o.tstamp));
            const label         = "PORTIFOLIO";
            return          {
                mes,
                calculo     : o,
                cotacoes    : { cdi: rcdi, ipca: ripca, dolar: rdolar, ibovespa: ribovespa },
                comparacao  : {
                    cdi     : { taxa: tcdi, valor: vcdi },
                    ipca    : { taxa: tipca, valor: vipca },
                    dolar   : { taxa: tdolar, valor: vdolar },
                    ibovespa: { taxa: tibovespa, valor: vibovespa },
                },
                carteira    : {
                    taxa    : o.taxa,
                    valor   : o.depois - o.antes,
                    saldo   : o.depois,
                    mes,
                    rotulo,
                    label
                },
                cdi         : {
                    taxa    : tcdi,
                    valor   : vcdi,
                    mes,
                    rotulo,
                    label   : "CDI"
                },
                ipca        : {
                    taxa    : tipca,
                    valor   : vipca,
                    mes,
                    rotulo,
                    label   : "IPCA"
                },
                dolar       : {
                    taxa    : tdolar,
                    valor   : vdolar,
                    mes,
                    rotulo,
                    label   : "DÓLAR"
                },
                ibovespa    : {
                    taxa    : tibovespa,
                    valor   : vibovespa,
                    mes,
                    rotulo,
                    label   : "IBOVESPA"
                }
            }
        });

        return grps;
    }

    //#endregion

    //#region Monthly report

    gerarDados (ativos) {
        if (!(ativos && ativos.length)) {
            return {
                ativos,
                cut: void 0,
                calc: { desserts: [], dessertsCalc: [] },
                bottom: void 0,
                ending: []
            }
        }
        const { cut, calc, bottom, ending } = this.getCalculos(ativos);
        return {
            ativos,
            cut,
            calc,
            bottom,
            ending
        }
    }
    reportRentabilidade (block, dataInicial, custodians) {
        const { ativos, calc, ending } = block;
        const cut   = typeof dataInicial === 'string' ?
            dataInicial :
            dataInicial.toISOString().substring(0, 10);
        const rent  = this.rentabilidadeGrupo(ativos, calc, ending, true );
        const {
            grupo,
            min,
            last,
            total: cotas
        } = rent;
        const start = grupo.index[0];
        const fnsh  = cut;
        const qot   = this.geraCotacoes(grupo.lead, cut);
        let res     = [];
        let curr    = start;
        let prev    = start;
        while (curr <= fnsh) {
            let grp     = grupo[curr];
            if (grp && grp.length) {
                prev    = curr;
            } else {
                grp     = grupo[prev];
            }
            const tail = grp[grp.length - 1];
            const amul = tail.acum;
            const acum = (amul - 1) * 100;
            const mult = grp.reduce((v, e) => v * (1 + e.taxa / 100), 1);
            const taxa = (mult - 1) * 100;
            const cdi  = qot.cdi[curr];
            const ipca = qot.ipca[curr];
            const dolr = qot.dolar[curr];
            const ibov = qot.ibovespa[curr];
            const fst  = (a) => a && a.length && a[0] || undefined;
            const lst  = (a) => a && a.length && a[a.length - 1] || undefined;
            const rat  = (a) => {
                if (!(a && a.length)) { return 0; }
                const val = a.reduce((v, e) => v * (1 + e.percent / 100), 1);
                return (val - 1) * 100;
            }
            const prv  = lst(res);
            res.push({
                taxa,
                acum,
                saldo: tail.depois,
                valor: tail.depois - grp[0].antes,
                cdi: {
                    taxa: rat(cdi),
                    acum: lst(cdi)?.acum || prv?.cdi.acum || 1,
                    mes: lst(cdi)?.data || curr + "-01"
                },
                ipca: {
                    taxa: rat(ipca),
                    acum: lst(ipca)?.acum || prv?.ipca.acum || 1,
                    mes: lst(ipca)?.data || curr + "-01"
                },
                dolar: {
                    taxa: rat(dolr),
                    acum: lst(dolr)?.acum || prv?.dolar.acum || 1,
                    mes: lst(dolr)?.data || curr + "-01"
                },
                ibovespa: {
                    taxa: rat(ibov),
                    acum: lst(ibov)?.acum || prv?.ibovespa.acum || 1,
                    mes: lst(ibov)?.data || curr + "-01"
                },
                tstamp: curr,
                date  : tail.tstamp
            });
            let year    = +curr.substring(0, 4);
            let month   = +curr.substring(5, 7);
            let nyear   = `${month < 12 ? year : year + 1}`;
            let nmonth  = `${month < 12 ? month + 1 : 1}`;
            curr        = `${nyear.padStart(4, 101)}-${nmonth.padStart(2, '0')}`;
        }
        const rlast     = res[res.length - 1];
        const dhead     = new Date(min + 'T00:00:00').toLocaleDateString();
        const dtail     = new Date(last + 'T00:00:00').toLocaleDateString();
        const ano       = +res[0].tstamp.substring(0,4);
        const saldo     = rlast.saldo;
        const fsaldo    = this.numberToLocale(saldo);
        let   total     = 0;
        const dados     = res.map((o, i) => {
            return {
                year: o.date.substring(0, 4),
                mes: o.date.substring(0, 7),
                carteira: {
                    taxa: o.taxa,
                    valor: o.valor,
                    total: total += o.valor, //res.slice(0, i + 1).reduce((v, e) => v + e.valor, 0),
                    saldo: o.saldo,
                    fatia: o.taxa / 100,
                    peso: 1,
                    mes: `${o.date}T00:00:00`,
                    rotulo: this.dateToLocaleMonth(o.date),
                    label: "PORTFÓLIO"
                },
                cdi: {
                    taxa: o.cdi.taxa,
                    acum: o.cdi.acum / 100,
                    mes: o.cdi.mes,
                    rotulo: this.dateToLocaleMonth(o.cdi.mes),
                    label: "CDI"
                },
                ipca: {
                    taxa: o.ipca.taxa,
                    acum: o.ipca.acum / 100,
                    mes: o.ipca.mes,
                    rotulo: this.dateToLocaleMonth(o.ipca.mes),
                    label: "IPCA"
                },
                dolar: {
                    taxa: o.dolar.taxa,
                    acum: o.dolar.acum / 100,
                    mes: o.dolar.mes,
                    rotulo: this.dateToLocaleMonth(o.dolar.mes),
                    label: "DOLAR"
                },
                ibovespa: {
                    taxa: o.ibovespa.taxa,
                    acum: o.ibovespa.acum / 100,
                    mes: o.ibovespa.mes,
                    rotulo: this.dateToLocaleMonth(o.ibovespa.mes),
                    label: "IBOVESPA"
                }
            }
        });
        const gMonth    = (v) => new Array(12).fill(v);
        const src       = dados.reduce((o, e) => {
            const mth   = e.mes.substring(0, 7);
            const [pos, prv] = o.index.length && o.index[mth] || [0, undefined];
            if (prv) {
                if (prv.mes < e.mes) {
                    o.items[pos] = e;
                    o.index[mth] = [pos, e];
                }
            } else {
                o.items.push(e);
                o.index[mth] = [o.items.length, e];
            }
            return o;
        }, { items: [], index: {} });
        const anual     = src.items.reduce((o, d) => {
            const year  = d.year;
            const month = (+d.mes.substring(5, 7)) - 1;
            let   prev  = o.index[year];
            if (!prev) {
                prev = {
                    year,
                    saldo: { ano: 0, mensal: gMonth(0) },
                    mes: { carteira: gMonth(), cdi: gMonth(), ipca: gMonth(), dolar: gMonth(), ibovespa: gMonth() },
                    anual: { carteira: 0, cdi: 0, ipca: 0, dolar: 0, ibovespa: 0 },
                    geral: { carteira: 0, cdi: 0, ipca: 0, dolar: 0, ibovespa: 0 }
                };
                o.index[year] = prev;
                o.items.push(prev);
            }
            const r2m = (v) => 1 + v / 100;
            const m2r = (v) => (v - 1) * 100;
            const adp = (o, v) => m2r(r2m(o) * r2m(v));
            prev.saldo.ano           += d.carteira.saldo;
            prev.saldo.mensal[month]  = d.carteira.saldo;
            prev.anual.carteira       = adp(prev.anual.carteira, d.carteira.taxa);
            prev.anual.cdi            = adp(prev.anual.cdi, d.cdi.taxa);
            prev.anual.ipca           = adp(prev.anual.ipca, d.ipca.taxa);
            prev.anual.dolar          = adp(prev.anual.dolar, d.dolar.taxa);
            prev.anual.ibovespa       = adp(prev.anual.ibovespa, d.ibovespa.taxa);
            prev.geral.carteira       = adp(prev.geral.carteira, d.carteira.taxa);
            prev.geral.cdi            = adp(prev.geral.cdi, d.cdi.taxa);
            prev.geral.ipca           = adp(prev.geral.ipca, d.ipca.taxa);
            prev.geral.dolar          = adp(prev.geral.dolar, d.dolar.taxa);
            prev.geral.ibovespa       = adp(prev.geral.ibovespa, d.ibovespa.taxa);
            return o;
        }, { items: [], index: {} });
        total = total && total > 0 ? total: 1;
        let custodiantes = [];
        let report      = ativos.reduce((r, a, idx) => {
            let items   = calc[idx]
                .filter(cc => {
                    const fmt = `${cc.name.substring(6,10)}-${cc.name.substring(3,5)}-${cc.name.substring(0,2)}`;
                    return fmt <= cut;
                });
            let item    = items.length ? items[items.length - 1] : undefined;
            if (item) {
                r[0].valor += item.calories;
                r[0].percentual = (r[0].valor / saldo);
                r[0].quantidade += (item.calories > 0) ? 1 : 0;
                let curr = r.filter(e => (e.ativo === this.descricaotipoproduto(a.idTProduto, a.tipo)));
                if (curr.length > 0)  {
                    curr[0].valor += item.calories;
                    curr[0].percentual = (curr[0].valor / saldo);
                    curr[0].quantidade += (item.calories > 0) ? 1 : 0;
                } else if (item.calories > 0) {
                    r.push ({
                        tipo : this.descricaotipoproduto(a.idTProduto, a.tipo) + '0',
                        ativo : this.descricaotipoproduto(a.idTProduto, a.tipo),
                        valor : item.calories,
                        quantidade : 1,
                        isParent: 1,
                        emissor : '',
                        estrategia : '',
                        percentual : (item.calories / saldo),
                        vencimento : '',
                        liquidez : '',
                        fgc : ''
                });
                }
                if (custodians && custodians.length > 0)
                {
                    let custodianteIds = a.movimentos.filter((m) => m.custodianteId && (m.custodianteId > 0));
                    let custodianteId  =  (custodianteIds && custodianteIds.length > 0) ? custodianteIds[0].custodianteId : 0;
                    let cc = custodians.filter((c) => c.entityId ===  custodianteId);
                    let custodiante = cc && cc.length > 0 ? cc[0].fullName : 'Não Preenchido';
                    let entId = cc && cc.length ? cc[0].entityId : 0;
                    let curr2 = custodiantes.filter(e => (e.ativo === custodiante));
                    if (curr2.length > 0) {
                        curr2[0].valor += item.calories;
                        curr2[0].percentual = (curr2[0].valor / saldo);
                        curr2[0].quantidade += (item.calories > 0) ? 1 : 0;
                    } else if (item.calories > 0){
                        custodiantes.push ({
                            aid: 0,
                            eid: entId,
                            tipo : custodiante + '0',
                            ativo : custodiante,
                            valor : item.calories,
                            quantidade : 1,
                            isParent: 1,
                            emissor : '',
                            estrategia : '',
                            percentual : (item.calories / saldo),
                            vencimento : '',
                            liquidez : '',
                            fgc : ''
                        });
                    }
                    if (item.calories > 0) {
                        custodiantes.push ({
                        aid: a.ativoId,
                        eid: entId,
                        tipo : custodiante +'1',
                        ativo : a.descricao,
                        valor : item.calories,
                        quantidade : 1,
                        emissor : a.emissor,
                        estrategia : a.estrategia,
                        percentual : (item.calories / saldo),
                        vencimento : a.vencimento ? a.vencimento : '',
                        liquidez : a.vencimento ? diffAsDays(this.currentDate(), a.vencimento) : 0,
                        fgc : a.fgc
                    });
                    }
                }
                if (item.calories > 0) {
                    r.push ({
                        tipo : this.descricaotipoproduto(a.idTProduto, a.tipo)+'1',
                        ativo : a.descricao,
                        valor : item.calories,
                        quantidade : 1,
                        emissor : a.emissor,
                        estrategia : a.estrategia,
                        percentual : (item.calories / saldo),
                        vencimento : a.vencimento ? a.vencimento : '',
                        liquidez : a.vencimento ? diffAsDays(this.currentDate(), a.vencimento) : 0,
                        fgc : a.fgc
                    });
                }
            }
            return r;
        }, [{
            ativo : 'TOTAL INVESTIMENTOS',
            valor :  0,
            quantidade : 0,
            isParent: 1,
            emissor : '',
            estrategia : '',
            percentual : 0,
            vencimento : '',
            liquidez : '',
            fgc : ''
}]);
        const cmth      = cut.substring(0, 7);
        let movimentos  = ativos.reduce((r, a, idx) => {
            const desc  = this.descricaotipoproduto(a.idTProduto, a.tipo);
            const mov   = a.movimentos &&
                a.movimentos.length &&
                a.movimentos.filter((m) => m.investmentDate.startsWith(cmth)) ||
                [];
            mov.forEach((m) => {
                r[0].quantidade += 1;
                r[0].valor      += m.tpMovimento !== 3 && m.tpMovimento !== 8 ?  m.investedValue:0;
                r[0].valor1     += m.tpMovimento === 3 || m.tpMovimento === 8 ?  Math.abs(m.investedValue):0;
                let tgt          = r.filter((e) => e.ativo === desc);
                if (tgt.length) {
                    const curr   = tgt[0];
                    curr.quantidade += 1;
                    curr.valor      += m.tpMovimento !== 3 && m.tpMovimento !== 8 ? m.investedValue:0;
                    curr.valor1     += m.tpMovimento === 3 || m.tpMovimento === 8 ? Math.abs(m.investedValue):0;
                } else {
                    r.push({
                        ativo: desc,
                        quantidade: 1,
                        valor: m.tpMovimento !== 3 && m.tpMovimento !== 8 ? m.investedValue:0,
                        valor1: m.tpMovimento === 3 || m.tpMovimento === 8 ? Math.abs(m.investedValue):0
                    });
                }
            });
            return r;
        }, [{
            ativo: 'TOTAL',
            quantidade: 0,
            valor: 0,
            valor1:0
        }]);
        custodiantes.sort ((a, b) =>  (a.tipo.localeCompare(b.tipo)));
        report.sort ((a, b) =>  (a.tipo.localeCompare(b.tipo)));
        movimentos.sort ((a, b) =>  (a.ativo.localeCompare(b.ativo)));
        const result    = {
            inicio: `${ano}`,
            periodo: `De ${dhead} to ${dtail}`,
            carteira: `R$ ${fsaldo}`,
            saldo,
            dados,
            anual: anual.items,
            ativos,
            calculos: calc,
            report,
            movimentos,
            custodiantes
        };
        return result;
    }

    //#endregion
}
