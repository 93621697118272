<template>
  <div class="top-graph">
    <div class="title">
      <span>Desempenho da carteira</span>
    </div>
    <div class="indices">
        <div class="indice">
            <div class="title-indice"><span>CDI</span></div>
        </div>
        <div class="indice">
            <div class="title-indice"><span>IPCA</span></div>
        </div>
        <div class="indice">
            <div class="title-indice"><span>DÓLAR</span></div>
        </div>
        <div class="indice">
            <div class="title-indice"><span>IBOVESPA</span></div>
        </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style  scoped>
.top-graph {
  padding: 0.5rem 1.3rem 1.5rem 1.3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.title span {
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.15px;

  color: #000000;
}
.indices{
    display: flex;
    flex-wrap: wrap;
}
.indices>div{
    margin: .5rem .8rem;
    padding: 0 0 0 .3rem;
}
.indices>div:nth-child(1){
    border-left: 25px solid #35BAFC;
}
.indices>div:nth-child(2){
    border-left: 25px solid #29BD1A;
}
.indices>div:nth-child(3){
    border-left: 25px solid #C520FF;
}
.indices>div:nth-child(4){
    border-left: 25px solid #FA5807;
}
@media screen and (max-width: 700px) {
    .indices > div{ flex: 0 0 40%; }
}
</style>