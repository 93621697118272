<template>
  <div class="fundos-investimento">
    <v-row class="flex-column" align="start">
      <v-row class="d-flex flex-wrap flexx">
        <div class="bloco">
          <v-col class="d-flex flex-column align-left py-0">
            <span class="text-filelds">{{ formTitle }}</span>
          </v-col>
          <v-col>
            <v-autocomplete @change="changeActive"
                            :loading="ativosFull.length==0"
                            :items="ativosFull"
                            :disabled=loading
                            return-object
                            item-text="code"
                            :menu-props="{ bottom: true, offsetY: true }"
                            v-model="ativo"
                            placeholder="Selecione Fundo"
                            color="secondary"
                            required
                            dense
                            outlined
                            rounded
                            clearable
                            attach=""
                            :filter="filterObject"
                            class="select"
            ></v-autocomplete>
          </v-col>
        </div>

        <v-row class="d-flex flex-wrap flexx">
          <div class="bloco">
            <v-col class="d-flex flex-column align-left py-0">
              <span class="text-filelds">Custodiante</span>
            </v-col>
            <v-col>
              <v-autocomplete :items="custodians"
                                :disabled=loading
                              :loading="loading"
                              item-text="fullName"
                              item-value="entityId"
                              v-model="custodiante"
                              placeholder="Insira o custodiante"
                              color="secondary"
                              required
                              outlined
                              dense
                              rounded
                              height="10"
                              @change="saveCustodiante"
                              class="select"
                              clearable
                              attach=""
                              :filter="filterObject"
                              :rules="[(v) => v != null || 'Campo obrigatório']"
              ></v-autocomplete>
            </v-col>
          </div>
        </v-row>

        <div class="bloco">
          <v-col class="d-flex flex-column align-left py-0">
            <span class="text-filelds">Gestor</span>
          </v-col>
          <v-col class="column">
            <span class="btn"> {{gestor ? gestor : ""}} </span>
          </v-col>
        </div>
      </v-row>

      <v-row class="d-flex flex-wrap flexx">
        <div class="bloco">
          <v-col class="d-flex flex-column align-left py-0">
            <span class="text-filelds">Estratégia</span>
          </v-col>
          <v-col cols="1" class="column">
            <span class="btn">
              {{ estrategia ? estrategia : "Automático" }}
            </span>
          </v-col>
        </div>

        <div class="bloco">
          <v-col class="d-flex flex-column align-left py-0">
            <span class="text-filelds">Indice referência</span>
          </v-col>
          <v-col cols="1" class="column">
            <span class="btn">
              {{ indice ? indice : "Automático" }}
            </span>
          </v-col>
        </div>

        <div class="bloco">
          <v-col class="d-flex flex-column align-left py-0">
            <span class="text-filelds">Forma de Tributação</span>
          </v-col>
          <v-col cols="1" class="column">
            <span class="btn">{{formaTributacao}} </span>
          </v-col>
        </div>
        <div class="bloco">
          <v-col class="d-flex flex-column align-left py-0">
            <span class="text-filelds">Classe ANBIMA</span>
          </v-col>
          <v-col cols="1" class="column">
            <span class="btn"> {{classeAmbima}} </span>
          </v-col>
        </div>

        <div class="bloco">
          <v-col class="d-flex flex-column align-left py-0">
            <span class="text-filelds">Formas de Gestão</span>
          </v-col>
          <v-col cols="1" class="column">
            <span class="btn"> {{formasGestao}} </span>
          </v-col>
        </div>
        <div class="bloco">
          <v-col class="d-flex flex-column align-left py-0">
            <span class="text-filelds">FGC</span>
          </v-col>
          <v-col cols="1" class="column">
            <span class="btn"> {{FGCDesc}} </span>
          </v-col>
        </div>

        <div class="bloco">
          <v-col class="d-flex flex-column align-left py-0">
            <span class="text-filelds">Cota Aplicação (D +)</span>
          </v-col>
          <v-col cols="1" class="column">
            <span class="btn"> {{cotaAplicacao}} </span>
          </v-col>
        </div>

        <div class="bloco">
          <v-col class="d-flex flex-column align-left py-0">
            <span class="text-filelds">Cota Resgate (D +)</span>
          </v-col>
          <v-col cols="1" class="column">
            <span class="btn"> {{cotaResgate}} </span>
          </v-col>
        </div>

        <div class="bloco">
          <v-col class="d-flex flex-column align-left py-0">
            <span class="text-filelds">Crédito do resgate (D +)</span>
          </v-col>
          <v-col cols="1" class="column">
            <span class="btn"> {{creditoResgate}} </span>
          </v-col>
        </div>

        <div class="bloco">
          <v-col class="d-flex flex-column align-left py-0">
            <span class="text-filelds">Data Investimento</span>
          </v-col>
          <v-col class="py-0 flex-grow-0 flex-shrink-1" style="padding: 0">
            <DatePiker @change="saveDataInvestimento" />
          </v-col>
        </div>
        <div class="bloco">
          <v-col class="d-flex flex-column align-left py-0">
            <span class="text-filelds">Valor da Cota na data</span>
          </v-col>
          <v-col cols="1" class="column">
            <span class="btn">{{currencyHelper.formatValue(vPrecoUnitario)}}</span>
          </v-col>
        </div>
        <div class="bloco">
          <v-col class="d-flex flex-column align-left py-0">
            <span class="text-filelds">Nº de Cotas</span>
          </v-col>
          <v-col class="py-0" style="padding: 0">
            <ValorMonetario :value="vQtd"  @change="saveShareQuantity" />
          </v-col>
        </div>

        <div class="bloco">
          <v-col class="d-flex flex-column align-left py-0">
            <span class="text-filelds">Total Investido(R$)</span>
          </v-col>
          <v-col class="py-0" style="padding: 0">
            <ValorMonetario :value="vPrecoUnitarioQtd" @change="saveInvestedValue" />
          </v-col>
        </div>
      </v-row>
    </v-row>
  </div>
</template>

<script lang="js">
import ApiService from "../../../../../services/ApiService";
import DatePiker from "../componentes/Date-Piker.vue";
import currencyHelper from "../../../../../Utils/CurrencyHelper";
import ValorMonetario from "../componentes/Valor-Monetario.vue";
import textUtil from "../../../../../Utils/TextUtil.js"

export default {
  data() {
    return {
      items: [],
      textUtil,
      currencyHelper,
      date: "",
      menu: "",
      user: "",
      tipoInvestimento: "",
      ativos: [],
      ativosFull: [],
      fundosInvestimento: [],
      ativo: {},
      estrategia: "",
      indice: "",
      percentual: null,
      api: new ApiService(),

      // userToAtivo: this.userToAtivos,
      userToAtivo: {},
      totaInvestido: "",

      listativos:[],
      razaoSocialEntidade:"",
      gestor:"",
      description:"",
      custodiante:"",
      custodians: [],
      loading: false,
      formaTributacao:"",
      formasGestao:"",
      cotaAplicacao:"",
      cotaResgate:"",
      creditoResgate:"",
      classeAmbima:"",
      FGCDesc:"",
      filtertFundo:"",
      searchFundo:"",

      vPrecoUnitarioQtd :0,
      vPrecoUnitario:0,
      vQtd:0,
    };
  },

  created() {
    this.api.getListEntity(this.$store,8)
      .then((resp) => {
        this.custodians= resp;
      })
      .catch ((error)=> {
        this.botaoCompraClicado = false;
        this.$toast.error(error.Error);
      });
    this.api.getListAtivosFunInv(this.$store,8,this.formType)
      .then((resp) => {
        this.ativosFull= resp;
      })
      .catch ((error)=> {
        this.botaoCompraClicado = false;
        this.$toast.error(error.Error);
      });
    this.userToAtivo.ShareValue = 0.00;
    this.userToAtivo.ShareQuantity= 0.00;
    this.userToAtivo.InvestedValue = 0.00;
    this.save();
  },

  components: {
    DatePiker,
    ValorMonetario,

  },

  props: {
    respGetAtiTipUserId_prop: { type: Array },
    userToAtivos: { type: Object },
    closing: { type: Boolean },
    formTitle: { type: String },
    formType: { type: String }
  },

  methods: {
    filterObject(item, queryText, itemText) {
      if (!queryText|| queryText === '') {
        return true;
      }
      return this.textUtil.includesCIAI(item.code||item.fullName,queryText);
    },
    filteredListFundo() {
        return this.ativosFull.filter(post => {
          return post.codigo.toLowerCase().includes(this.searchFundo.toLowerCase())
      });
    },

    changeActive(data) {
      if (data) {
        this.$emit('buy:activeId', data.activeId);
        this.userToAtivo.ativoId = data.activeId;
        this.estrategia = data.strategyDescription;
        this.indice = data.referenceDescription;
        this.indexador = data.indexerDescription;
        this.FGCDesc=data.creditGuaranteeFundDescription;
        this.gestor = data.managerFullname;
        this.classeAmbima = data.anbimaClass ;
        this.formaTributacao = data.taxationForm ;
        this.formasGestao = data.managementForm;
        this.cotaAplicacao = data.applicationQuota ;
        this.cotaResgate = data.redemptionQuota ;
        this.creditoResgate = data.redemptionCredit;
        this.userToAtivo.TpMovimento =1;
      } else {
        this.$emit('buy:activeId', undefined);
        this.userToAtivo.ativoId = undefined;
        this.estrategia = undefined;
        this.indice = undefined;
        this.indexador =undefined;
        this.FGCDesc=undefined;
        this.gestor =undefined;
        this.classeAmbima = undefined;
        this.formaTributacao = undefined;
        this.formasGestao = undefined;
        this.cotaAplicacao = undefined;
        this.cotaResgate = undefined;
        this.creditoResgate = undefined;
        this.userToAtivo.TpMovimento=undefined;
      }
      this.userToAtivo.ShareValue = 0.00;
      this.userToAtivo.ShareQuantity= 0.00;
      this.userToAtivo.InvestedValue = 0.00;
    },
    getFilterFundo(){
        this.testef=this.filtertFundo;
      if ( this.filtertFundo.length>0){
          this.ativos= this.ativosFull.filter((f) => {
              return f.codigo.substr(0, this.filtertFundo.length) ==  this.filtertFundo;
            });
            this.testef='Ok'
      }else{
         this.ativos= this.ativosFull;
          this.testef='ativosFull'
      }


    },
    save() {

    },
    saveDescription() {
      this.userToAtivo.Description=this.description;

      this.$emit("save", this.userToAtivo);
    },
    saveCustodiante() {
      this.userToAtivo.CustodianteId  = this.custodiante;
      this.$emit("save", this.userToAtivo);
    },
    saveNumCotas(nunCotas) {
      this.userToAtivo.ShareQuantity = nunCotas;
      this.saveTotalValue();
      this.$emit("save", this.userToAtivo);
    },
    saveInvestedValue(InvestedValue){
       this.userToAtivo.InvestedValue=parseFloat(InvestedValue);
       this.userToAtivo.ShareQuantity=0;
     this.saveTotalValue();
    },
    saveShareValue(ShareValue) {
      this.userToAtivo.ShareValue=parseFloat(ShareValue);
      if( this.userToAtivo.ShareQuantity>0){
        this.userToAtivo.InvestedValue=0;
      }
      this.saveTotalValue()
    },
    saveShareQuantity(ShareQuantity) {

      this.userToAtivo.ShareQuantity=parseFloat(ShareQuantity);
      if(this.userToAtivo.ShareValue >0 ){
        this.userToAtivo.InvestedValue=0;
      }
       this.saveTotalValue()
    },
    saveTotalValue() {

      if (this.userToAtivo.ShareValue >0 && this.userToAtivo.ShareQuantity >0){
        this.userToAtivo.InvestedValue= this.userToAtivo.ShareValue  *  this.userToAtivo.ShareQuantity;
        this.$emit("save", this.userToAtivo);
      }//else if (this.userToAtivo.InvestedValue >0 &&  this.userToAtivo.ShareQuantity >0){
        //this.userToAtivo.ShareValue = this.userToAtivo.InvestedValue /  this.userToAtivo.ShareQuantity;
       // this.$emit("save", this.userToAtivo);
      //}
      else if (this.userToAtivo.ShareValue >0 && this.userToAtivo.InvestedValue >0){
        this.userToAtivo.ShareQuantity=(this.userToAtivo.InvestedValue / this.userToAtivo.ShareValue)
        this.$emit("save", this.userToAtivo);
      }

     // this.vPrecoUnitario=this.userToAtivo.ShareValue;
      this.vQtd= this.userToAtivo.ShareQuantity;
      this.vPrecoUnitarioQtd=this.userToAtivo.InvestedValue;
    },
    saveValorCota(valorCota) {
      this.userToAtivo.ShareValue = valorCota;
      this.$emit("save", this.userToAtivo);
    },
    saveDataInvestimento(data) {
      this.userToAtivo.investmentDate = data;
      this.api.getCotacao( this.userToAtivo.ativoId ,data)
        .then((resp) => {
          this.userToAtivo.ShareValue  =  resp.valor;
          this.vPrecoUnitario=this.userToAtivo.ShareValue;
        })
        .catch ((error)=> {
          this.botaoCompraClicado = false;
          this.$toast.error(error.Error);
        });
      this.$emit("save", this.userToAtivo);
    }
  },

  watch: {
    respGetAtiTipUserId_prop(nv){
        this.loading = true;
     // this.ativosFull=nv[0].listActive;
      this.usersActive=nv[0].usersActive;
     // this.custodians= nv[0].custodian;
       this.loading = false;
    },


    userToAtivos(nv, ov) {
      if (nv) {
        this.userToAtivo = nv;
      }
    },

    formTitle(_) {
      this.api.getListAtivosFunInv(this.$store,8,this.formType)
        .then((resp) => {
          this.ativosFull= resp;
        })
        .catch ((error)=> {
          this.botaoCompraClicado = false;
          this.$toast.error(error.Error);
        });
      this.userToAtivo.ShareValue = 0.00;
      this.userToAtivo.ShareQuantity= 0.00;
      this.userToAtivo.InvestedValue = 0.00;
      this.save();
    }
  },

  computed: {
    userFormated() {
      return this.$store.getters.$userLogged;
    },

    typeUser() {
      return this.$store.getters.$userType;
    },
  },
};
</script>

<style  scoped>
.fundos-investimento {
  margin-top: -20px !important;
  width: 100%;
  padding-top: 0 !important;
  padding-bottom: 5rem;
}
.flexx {
  margin: 0 auto;
  width: 95%;
}
.bloco {
  flex: 1 0 180px;
  width: 100%;
}
.bloco .btn {
  height: 40px;
  background: #f6f6f6;
  border-radius: 30px;
  color: #9f9f9f;
  padding: 0.5rem 3rem 0.5rem 1rem;
  width: 100%;
  font-size: clamp(0.45em, 0.85em, 1em);
  display: flex;
  align-items: center;
}
.column {
  min-width: 100% !important;
}
.text-filelds {
  font-size: 14px;
}
.select2{ width: 50%;
  border: 1px solid red;}
.date-piker input {
  width: 100%;
  border: 1px solid #e6e6e6;
  color: #a4a4a4;
  border-radius: 40px;
  padding: 0.5rem 0.5rem 0.5rem 2.5rem;
  outline: none;
  position: relative;
}
</style>