<template>
  <div class="pagina-usuario">
    <div class="btn-voltar" @click="goToGestaoUsuario">
      <img src="@/assets/icons/arrow_back_ios.svg" alt="Botão voltar" />
      <span> Voltar para gestão de usuários</span>
    </div>

    <div class="infos-usuario" v-if="userTeste">

    <v-card class="perfil-usuario">
      <div class="section-user profile">
        <figure class="img">
          <img
            :src="require('@/assets/empty' + this.id + '.png')"
            alt=""
          />
        </figure>
        <div class="nome-usuario">
          <div class="title">
            <span>Nome</span>
          </div>
          <div class="nome">
            <span>{{ userTeste.name || "Eleanor pena" }}</span>
          </div>
        </div>
      </div>

      <div class="section-user email center">
        <div class="title">
          <span>Email</span>
        </div>
        <div class="email">
          <span class="light">{{ userTeste.email }}</span>
        </div>
      </div>

      <div class="section-user telefone center">
        <div class="title">
          <span>Telefone</span>
        </div>
        <div class="telefone">
          <span class="light">{{
            userTeste.phoneNumber || "(84) 92508-9833"
          }}</span>
        </div>
      </div>

      <div class="section-user dataCadastro center">
        <div class="title"><span>Data de cadastro</span></div>
        <div class="data-cadastro">
          <span class="light">{{
            formatDate(userTeste.created_at) || "22/09/2021"
          }}</span>
        </div>
      </div>

      <div class="section-user btns">
        <div
          class="btn-editar"
          @click="showModal('editarUsuario', $event)"
        >
          <img
            src="../../../assets/icons/edit_icon.svg"
            alt="Botão de editar"
          />
        </div>
        <div class="btn-deletar" @click="deletItem">
          <img
            src="../../../assets/icons/delete_outline_icon.svg"
            alt="Botão de deletar"
          />
        </div>
      </div>

      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card class="card-dialog">
          <v-card-title class="text-h5"
            ><span>Deletar usuário?</span></v-card-title
          >
          <v-card-text class="descricao-dialog">
            Isso irá apagar permanentemente todas as informações e transações
            anteriormente cadastradas
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              class="btn-cancel-dialog"
              text
              @click="closeDelete"
              ><span>Cancelar</span></v-btn
            >
            <v-btn
              color="blue darken-1"
              class="btn-delete-dialog"
              text
              @click="deleteUsuario"
              :loading="loading"
              ><span>Deletar</span></v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
          <div class="btn-close-dialog" @click="closeDialog">
            <v-img src="@/assets/icons/clear_icon.svg" />
          </div>
        </v-card>
      </v-dialog>
    </v-card>

    <div class="cards">
      <div class="card">
        <span class="title">Saldo Bruto</span>
        <span class="valor">R$ 1.369.771,86</span>
      </div>
      <div class="card">
        <span class="title">Saldo Liquido</span>
        <span class="valor">R$ 1.369.771,86</span>
      </div>
      <div class="card">
        <span class="title">Rendimento</span>
        <span class="valor">R$ 1.369.771,86</span>
      </div>
      <div class="card">
        <span class="title">Rendimento Histórico</span>
        <span class="valor">12,63%</span>
      </div>
    </div>

    <div class="infos">
      <div class="bloco1">
        <div class="title-bloco">
          <span>Título do gráfico</span>
        </div>
        <div class="grafico" v-if="arrTeste && arrTeste2">
          <LineChart
            :chartData="arrTeste"
            :chartData2="arrTeste2"
            :options="chartOptions"
            label="Grafico mocado"
          />
        </div>
      </div>

      <div class="bloco2">
        <div class="title-bloco">
          <span>Transações recentes</span>
        </div>

        <!-- LISTA COM UM V-FOR EM CADA TRANSAÇÃO -->
        <ul class="lista">
          <li>
            <div class="textos">
              <div class="title-item">
                <span>Compra - novo ativo</span>
              </div>
              <div class="sub-title-item">
                <span>Fundo Imobiliário</span>
              </div>
              <div class="data-item">
                <span>22 de Setembro de 2021</span>
              </div>
            </div>
            <div class="preco-item">
              <span>R$ 1000,00</span>
            </div>
          </li>

          <li class="teste">
            <div class="textos">
              <div class="title-item">
                <span>Venda - ativo existente</span>
              </div>
              <div class="sub-title-item">
                <span>Fundo Imobiliário</span>
              </div>
              <div class="data-item">
                <span>22 de Setembro de 2021</span>
              </div>
            </div>
            <div class="preco-item">
              <span>R$ 1000,00</span>
            </div>
          </li>

          <li>
            <div class="textos">
              <div class="title-item">
                <span>Compra - novo ativo</span>
              </div>
              <div class="sub-title-item">
                <span>Fundo Imobiliário</span>
              </div>
              <div class="data-item">
                <span>22 de Setembro de 2021</span>
              </div>
            </div>
            <div class="preco-item">
              <span>R$ 1000,00</span>
            </div>
          </li>

          <li>
            <div class="textos">
              <div class="title-item">
                <span>Compra - novo ativo</span>
              </div>
              <div class="sub-title-item">
                <span>Fundo Imobiliário</span>
              </div>
              <div class="data-item">
                <span>22 de Setembro de 2021</span>
              </div>
            </div>
            <div class="preco-item">
              <span>R$ 1000,00</span>
            </div>
          </li>

          <li>
            <div class="textos">
              <div class="title-item">
                <span>Compra - novo ativo</span>
              </div>
              <div class="sub-title-item">
                <span>Fundo Imobiliário</span>
              </div>
              <div class="data-item">
                <span>22 de Setembro de 2021</span>
              </div>
            </div>
            <div class="preco-item">
              <span>R$ 1000,00</span>
            </div>
          </li>

          <li class="teste">
            <div class="textos">
              <div class="title-item">
                <span>Venda - ativo existente</span>
              </div>
              <div class="sub-title-item">
                <span>Fundo Imobiliário</span>
              </div>
              <div class="data-item">
                <span>22 de Setembro de 2021</span>
              </div>
            </div>
            <div class="preco-item">
              <span>R$ 1000,00</span>
            </div>
          </li>

          <li class="teste">
            <div class="textos">
              <div class="title-item">
                <span>Venda - ativo existente</span>
              </div>
              <div class="sub-title-item">
                <span>Fundo Imobiliário</span>
              </div>
              <div class="data-item">
                <span>22 de Setembro de 2021</span>
              </div>
            </div>
            <div class="preco-item">
              <span>R$ 1000,00</span>
            </div>
          </li>

          <li>
            <div class="textos">
              <div class="title-item">
                <span>Compra - novo ativo</span>
              </div>
              <div class="sub-title-item">
                <span>Fundo Imobiliário</span>
              </div>
              <div class="data-item">
                <span>22 de Setembro de 2021</span>
              </div>
            </div>
            <div class="preco-item">
              <span>R$ 1000,00</span>
            </div>
          </li>

          <li>
            <div class="textos">
              <div class="title-item">
                <span>Compra - novo ativo</span>
              </div>
              <div class="sub-title-item">
                <span>Fundo Imobiliário</span>
              </div>
              <div class="data-item">
                <span>22 de Setembro de 2021</span>
              </div>
            </div>
            <div class="preco-item">
              <span>R$ 1000,00</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import ApiService from "../../../services/ApiService";
import * as Mock from "@/components/mock/mock.js";
import EventBus from "../../Bus/bus";
import moment from "moment";
import LineChart from "@/components/graph/LineChart.vue";
export default {
  data() {
    return {
      loading: false,
      // userTeste: this.$store.getters.$currentUsser,
      userTeste: undefined,
      userId: this.$route.params.id.trim(),
      successOnEditUser: this.$store.getters.$successOnEditUser,
      datas: 0,
      arrTeste: [],
      arrTeste2: [],
      apiService: new ApiService(),
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          labels: {
            usePointStyle: true,
          },
          padding: 10,
        },
      },
      userToBeDeleted: "",
      dialogDelete: false,
    };
  },
  components: {
    LineChart,
  },
  created() {
    for (let d of Mock.mock1) {
      let data = moment(d.date, "DDMMYYYY").format("DD MMM");

      this.arrTeste.push({ data, valors: d });
    }
    for (let d of Mock.mock2) {
      let data = moment(d.date, "DDMMYYYY").format("DD MMM");

      this.arrTeste2.push({ data, valors: d });
    }
    this.init()
  },
  methods: {
    async init(){

      try{
        let req = await this.apiService.get(`user/${this.userId}`)
        this.userTeste = req.content
      }catch(error){
        this.$toast.error('Erro ao acessar informações do usuário')
      }

    },
    goToGestaoUsuario() {
      this.$router.push("/gestao-usuario");
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    showModal(e, evt) {
      this.mostrarModal = true;
      EventBus.$emit("editarUsuario", this.userTeste);

      if (e === evt.currentTarget.id) {
        if (!this.element) {
          this.element = evt.currentTarget;
        } else {
          this.element = evt.currentTarget;
        }
      }
      let obj = { show: this.mostrarModal, view: e };
      EventBus.$emit("mostrarModal", obj);

      setTimeout(() => {
        this.controller = false;
      }, 1000);
    },
    deletItem() {
      this.userToBeDeleted = this.userTeste;
      this.dialogDelete = true;

    },
    closeDelete() {
      this.dialogDelete = false;
    },
    closeDialog() {
      this.dialogDelete = false;
    },
    deleteItemConfirm() {
      this.closeDelete();
    },
    async deleteUsuario() {

      try {
        this.loading = true
        let req = await this.apiService.delete("user/delete", {
          id: this.userToBeDeleted.id,
        });

        if (req.statusCode == 200) {
          this.$toast.success('Usuário excluido com sucesso')
          this.dialogDelete = false;
          this.loading = false;

          setTimeout(() => {
            this.goToGestaoUsuario()
          }, 1000);
        }
      } catch (error) {
        this.dialogDelete = false;
        this.loading = false;
        this.$toast.error('Erro ao exclucir usuário')
      }
    },
  },
  computed:{
    ...mapGetters(['$successOnEditUser'])
  },
  watch:{
    $successOnEditUser(nv, ov){
      if(nv){
        this.init()
      }
    }
  }
};
</script>

<style scoped>
.btn-voltar {
  cursor: pointer;
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.15px;
  color: #494949;
  display: flex;
  align-items: center;
}
.btn-voltar img {
  width: 8px;
  margin-right: 0.5rem;
}
.pagina-usuario {
  padding: 1.8rem;

}
.title span {
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: #494949;
}
.perfil-usuario {
  background: white;
  border-radius: 15px;
  padding: 0.8rem;
  margin: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  box-shadow: -4px 0px 10px rgba(0, 0, 0, 0.05) !important;
}
.perfil-usuario > div {
  width: 100%;
  height: 100%;
  flex: 1 1 200px;
  padding: 0.5rem 0;
  margin: 0 1rem;
}
.profile {
  display: flex;
  align-items: center;
}
.profile .img {
  width: 68px;
  height: 68px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 1.5rem;
}
.profile .img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.light {
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.15px;
  transition: 0.2s ease-in-out;
  color: #494949;
}
.btns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.btns > div {
  margin: 0 0.5rem;
  cursor: pointer;
}
.cards {
  /* padding: 1rem; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100vw;
  flex-wrap: wrap;
}
.cards .card {
  background: white;
  padding: 0.8rem;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  /* width: 100%; */
  flex: 1 1 300px;
  margin: 0.5rem;
  box-shadow: -4px 0px 10px rgba(0, 0, 0, 0.05);
}
.card .title {
  font-size: 15px !important;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0.15px;
  padding-bottom: 0.5rem;
}
.card .valor {
  font-weight: normal;
  font-size: 25px;
  line-height: 37px;
}
.infos {
  margin-top: 1rem;
  max-height: 70vh;
  min-height: 70vh;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  gap: 0.5rem;
  grid-template-areas: "grafico grafico transacao";
}
.infos > div {
  /* border: 1px solid red; */
  border-radius: 20px;
  padding: 0.6rem;
  /* max-height: 70vh; */
  background: white;
}
.bloco1 {
  grid-area: grafico;
  /* border: 1px solid red; */
}
.bloco1 .grafico {
  /* border: 1px solid red; */
  height: auto;
  padding: 0.5rem;
}
.bloco2 {
  grid-area: transacao;
  background: white;
  overflow: hidden;
}
.title-bloco {
  font-weight: 600;
  font-size: 20px;
  line-height: 33px;
  letter-spacing: 0.15px;
  color: #000000;
  padding: 0.8rem;
}
.lista {
  padding: 0 0 1.6rem 0;
  overflow-y: auto;
  max-height: 100%;
}
.lista::-webkit-scrollbar {
  width: 7px;
}
/* .lista::-webkit-scrollbar-track {
  background: #f1f1f1;
} */
.lista::-webkit-scrollbar-thumb {
  background: #f1f1f1;
  border-radius: 20px;
}

.lista li {
  padding: 0.2rem 0.4rem;
  list-style: none;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  border-left: 3px solid var(--ok);
  margin: 0.8rem 0;
}
.teste {
  border-left: 3px solid var(--error) !important;
}
.lista li > div {
  flex: 1 1 150px;
}
.title-item span {
  font-weight: 600;
  font-size: 15px;
  line-height: 33px;
  letter-spacing: 0.15px;
}
.sub-title-item span {
  font-weight: 400;
  font-size: 13px;
  line-height: 27px;
  letter-spacing: 0.15px;
}
.data-item span {
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: #000000;
}
.preco-item {
  text-align: right;
}
.preco-item span {
  font-weight: 600;
  font-size: 15px;
  line-height: 33px;
  letter-spacing: 0.15px;
}

/* DIAOLG */
.btn-close-dialog {
  position: absolute;
  width: 20px;
  height: 20px;
  right: 25px;
  top: 25px;
  cursor: pointer;
}
.card-dialog .text-h5 {
  text-align: center !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.descricao-dialog {
  margin: 0.5rem 0;
}
.btn-delete-dialog {
  background: #f25a5a;
  border-radius: 20px;
  padding: 0 1rem !important;
}
.btn-delete-dialog span {
  color: white;
}
.btn-cancel-dialog {
  border-radius: 20px;
  padding: 0 1rem !important;
  border: 1px solid #e4e4e4;
}
.btn-cancel-dialog span {
  color: #e4e4e4;
}

/* FIM DIALOG */

@media screen and (max-width: 750px) {
  .infos {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 0.5rem;
    grid-template-areas:
      "grafico"
      "transacao";
  }
  .infos > div {
    /* background: yellow; */
    min-height: 65vh;
    min-width: 10vw;
  }
}

@media screen and (max-width: 650px) {
  .light {
    font-size: 15px !important;
  }
  .infos > div {
    min-height: 73vh;

  }
  .pagina-usuario{
    height: auto;
    min-height: 280vh !important;
    max-height: 100%;
  }
}
</style>