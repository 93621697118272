<script>
import { Bar } from "vue-chartjs";
export default {
  extends: Bar,
  props: {
    label: { type: String },
    label2: { type: String },
    chartData: { type: Array },
    options: { type: Object },
    chartData2: { type: Array },
    plugins: { type: Array },
    dates: { type: Array },
    colors: { type: Array },
  },

  computed: {
    $dados() {
      return this.chartData;
    },
    $dados2() {
      return this.chartData2;
    },
  },

  methods: {
    $numberToLocale(val) {
      let formato = { minimumFractionDigits: 2, currency: "BRL" };
      return val.toLocaleString("pt-BR", formato);
    },
    renderizarChart() {
      const dates = this.dates;
      const valors = this.$dados;
      const valors2 = this.$dados2;

      this.renderChart(
        {
          labels: dates,
          datasets: [
            {
              type: "line",
              label: this.label,
              backgroundColor: "#c8c8c8",
              borderColor: "#c8c8c8",
              data: valors2,
              pointRadius: 0,
              datalabels: {
                anchor: "end",
                align: "top",
                display: true,
                formatter: (val) => {
                  return `${val}%`;
                },
              },
              yAxisID: "percentage",
            },
            {
              label: this.label2,
              data: valors,
              backgroundColor: this.colors,
              borderRadius: 10,
              barPercentage: 0.5,
              datalabels: {
                anchor: "center",
                align: "top",
                display: true,
              },
            },
          ],
        },
        this.options,
        this.plugins
      );
    },
  },

  mounted() {
    this.renderizarChart();
  },

  watch: {
    chartData2() {
      this.renderizarChart();
    },
  },
};
</script>