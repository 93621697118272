<template>
    <div class="cotacoes container-fluid">
        <div class="title">
            <span>Verificar e atualizar cotações de ativos.</span>
        </div>
        <div class="subtitle">
            <span>Escolha um ativo para a operação.</span>
        </div>

        <div class="select">
            <v-autocomplete :items="selection"
                            no-data-text="Sem registros" v-model="ativo"
                            @change="changeAtivo($event)" :item-text="getTitle" return-object
                            item-value="id" :menu-props="{ bottom: true, offsetY: true }"
                            placeholder="Selecione um ativo existente"
                            color="secondary" required outlined rounded class="input">
            </v-autocomplete>
        </div>

        <div class="upload">
            <input type="file" ref="uploadBox" @change="uploadQuotes" :disabled="disableSelection()" />
        </div>

        <div class="message" v-if="message">{{ message }}</div>
        <div class="error" v-if="errorMessage">{{ errorMessage }}</div>
    </div>
</template>

<script>
import ApiService from '../../../services/ApiService';
import EventBus from "../../Bus/bus";

export default {
    data () {
        return {
            api: new ApiService(),
            working: false,
            idAtivo: void 0,
            ativo: {},
            hasPrevious: false,
            selection: [],
            message: '',
            errorMessage: ''
        }
    },
    methods: {
        updateLoading       (msg, isError) {
            if (isError) {
                this.errorMessage = msg;
                this.message = '';
            } else {
                this.errorMessage = '';
                this.message = msg;
            }
        },
        async getAtivos     () {
            if (this.working) { return; }
            this.working        = true;
            this.updateLoading('Carregando...');

            const model         = {
                userId          : this.$store.getters.$tokenUserSelected,
                byStrategy      : false,
                brief           : true
            };

            try {
                const response  = await this.api.post("userToAtivo/user", model);
                this.selection  = response.content.all.items
                    .filter((a) => a.idTProduto === 13);
                this.idAtivo    = void 0;
                this.updateLoading(`${this.selection.length} ativos carregado(s).`);
            } catch (r) {
                this.updateLoading(r.message, 1);
            } finally {
                this.working    = false;
            }
        },
        async getPrevious   () {
            if (this.working) { return; }
            this.working            = true;
            this.updateLoading('Carregando...');

            try {
                const core          = `${this.idAtivo}/${this.$store.getters.$tokenUserSelected}`;
                const response      = await this.api.get(`userToAtivo/${core}/cotacoes`);
                this.hasPrevious    = response.content.success;
                this.updateLoading( this.hasPrevious ? 'Existem cotações para o ativo.' : 'Sem cotações.');
            } catch (r) {
                this.updateLoading(r.message, 1);
            } finally {
                this.working        = false;
            }
        },
        async uploadQuotes  () {
            if (this.working) { return; }
            var skip = !(
                this.$refs.uploadBox &&
                this.$refs.uploadBox.files &&
                this.$refs.uploadBox.files.length);
            if (skip) {
                this.updateLoading('Escolha um arquivo (.xls ou .xlsx) de cotações para enviar.');
                return;
            }
            this.working            = true;
            this.updateLoading('Carregando...');

            try {
                const file          = this.$refs.uploadBox.files[0];
                const core          = `${this.idAtivo}/${this.$store.getters.$tokenUserSelected}`;
                const files         = [{ name: file.name, file }];
                const response      = await this.api.uploadFiles(`userToAtivo/${core}/cotacoes`, files);
                this.updateLoading(response.message);
                this.$store.commit('SET_USERTOATIVOUSERProduto', undefined);
                this.$store.commit('SET_USERTOATIVOUSEREstrategia', undefined);
            } catch (r) {
                this.updateLoading(r.message || r.body.message || 'Erro não identificado', 1);
            } finally {
                this.working        = false;
            }
        },
        async changeAtivo (e) {
            this.ativo    = e;
            this.idAtivo  = e && e.ativoId || void 0;
            this.$emit("onChangeAtivo", e);
            await this.getPrevious();
        },
        disableSelection () {
            return (this.selection.length === 0) || (!this.idAtivo);
        },
        ebReload (data) {
            if (!data) { return; }
            return this.getAtivos();
        },
        getTitle(item) {
            if (!item) { return '???'; }
            return `${item.title} - ${item.descricao}`;
        }
    },
    async created () {
        await this.getAtivos();
        EventBus.$on('reloadPagesForCallActivesAgain', this.ebReload);
    },
    beforeDestroy () {
        EventBus.$off('reloadPagesForCallActivesAgain', this.ebReload);
    }
}
</script>

<style scoped>
.cotacoes {
    padding: 1rem;
}
.message {
    background-color: white;
    color: green;
    font-weight: bold;
    width: 50vw;
}
.error {
    color: white;
    font-weight: bold;
    width: 50vw;
}
.title {
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.15px;
  color: #494949;
  margin: 1rem 0 0 0;
}
.select {
  margin: 0.5rem;
  max-width: 50vw;
}
.upload {
    margin: 0.5rem;
    max-width: 50vw;
}
</style>