<template>
  <div>
    <div class="mt-5 mx-3">
      <h4 style="color: #4f4f4f">MEUS EXAMES TOXICOLÓGICOS</h4>
    </div>
    <v-row class="mx-2 my-4" v-if="loading">
      <v-col class="my-5 d-flex justify-center align-center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-col>
    </v-row>
    <v-row class="mx-2 my-4" v-if="!loading">
      <v-col
        class="mb-5 mx-1 pa-0"
        v-for="exam in exams"
        :key="exam.code"
        @click="redirect(exam)"
      >
        <Exam :exam="exam" />
      </v-col>
    </v-row>
  </div>
</template>

<style scoped></style>

<script>
import ApiService from "../../../../services/ApiService";
import Exam from "./Exam.vue";

export default {
  name: "ExamList",

  created() {
    this.searchExams();
  },

  data: () => ({
    apiService: new ApiService(),
    loading: false,
    exams: [],
  }),

  components: {
    Exam,
  },

  methods: {
    searchExams() {
      this.loading = true;

      const user = JSON.parse(sessionStorage.getItem("user"));

      this.apiService
        .post("user/exams", user)
        .then((response) => {
          this.loading = false;
          this.exams = response;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    redirect(exam) {
      this.$store.commit("SET_CURRENT_EXAMS", JSON.stringify(this.exams));
      this.$store.commit("SET_CURRENT_EXAM", JSON.stringify(exam));
      this.$router.push("/exam");
    },
  },
};
</script>
