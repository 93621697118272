import { render, staticRenderFns } from "./EditarCodigoInvestimento.vue?vue&type=template&id=1ebf9b82&scoped=true"
import script from "./EditarCodigoInvestimento.vue?vue&type=script&lang=js"
export * from "./EditarCodigoInvestimento.vue?vue&type=script&lang=js"
import style0 from "./EditarCodigoInvestimento.vue?vue&type=style&index=0&id=1ebf9b82&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ebf9b82",
  null
  
)

export default component.exports