<template>
  <v-container fluid>  
   <div v-if="!objetoEhVazio(tipoAtivo)" class="mock">


      <v-row class="d-flex flex-wrap pb-16 row">
        <div class="bloco">
          <v-col class="d-flex flex-column align-left py-0">
            <span class="text-filelds">Estratégia</span>
          </v-col>
          <v-col cols="1" class="column">
            <span class="btn"> Automático </span>
          </v-col>
        </div>

        <div class="bloco">
          <v-col class="d-flex flex-column align-left py-0">
            <span class="text-filelds">Indice referência</span>
          </v-col>
          <v-col cols="1" class="column">
            <span class="btn"> Automático </span>
          </v-col>
        </div>

        <div class="bloco">
          <v-col class="d-flex flex-column align-left py-0">
            <span class="text-filelds">Forma de Tributação</span>
          </v-col>
          <v-col cols="1" class="column">
            <span class="btn">SIM </span>
          </v-col>
        </div>
        <div class="bloco">
          <v-col class="d-flex flex-column align-left py-0">
            <span class="text-filelds">Classe ANBIMA</span>
          </v-col>
          <v-col cols="1" class="column">
            <span class="btn"> Automático </span>
          </v-col>
        </div>

        <div class="bloco">
          <v-col class="d-flex flex-column align-left py-0">
            <span class="text-filelds">Formas de Gestão</span>
          </v-col>
          <v-col cols="1" class="column">
            <span class="btn"> Automático </span>
          </v-col>
        </div>
        <div class="bloco">
          <v-col class="d-flex flex-column align-left py-0">
            <span class="text-filelds">FGC</span>
          </v-col>
          <v-col cols="1" class="column">
            <span class="btn"> Automático </span>
          </v-col>
        </div>


        <div class="bloco">
          <v-col class="d-flex flex-column align-left py-0">
            <span class="text-filelds">Total</span>
          </v-col>
          <v-col cols="1" class="column">
            <span class="btn"> R$ 500,00 </span>
          </v-col>
        </div>
      </v-row>
   </div>
  
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      items: ["Item 1", "Item 2", "Item 3"],
    };
  },
  props:{
    tipoAtivo: { type: Object },
  },
  methods:{
    objetoEhVazio(e) {
      return Object.keys(e).length === 0;
    },
  }
};
</script>

<style scoped>
.title span {
  font-weight: 300;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.15px;
}

.bnts-tipo-ativo {
  margin-top: 1.5rem;
  width: 100%;
  display: flex;
}
.bnts-tipo-ativo > div {
  margin-right: 0.8rem;
  border-radius: 30px;
  padding: 0.3rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.btn-novo-ativo {
  background: rgba(73, 73, 73, 0.5);
  color: white;
}
.btn-ativo-existente {
  border: 2px solid rgba(73, 73, 73, 0.5);
  color: rgba(73, 73, 73, 0.5);
}


.bloco {
  flex: 1 0 180px;
  width: 100%;
  /* border: 1px solid red; */
  /* margin-top: ; */
}
.bloco .btn {
  height: 40px;
  background: #f6f6f6;
  border-radius: 30px;
  color: #9f9f9f;
  padding: 0.5rem 3rem 0.5rem 1rem;
  width: 100%;
  display: block;
  /* border: 1px solid red; */
}
.column {
  min-width: 100% !important;
}
.text-filelds {
  font-size: 14px;
}
.row{
  padding-bottom: 5rem !important;
}
</style>