<template>
    <div class="float-modal" :style="windowCls">
        <component :is="spec.view" :args="args" @onResult="onResult"></component>
    </div>
</template>

<style scoped>
.float-modal {
    display   : block;
    position  : fixed;
    width     : var(--wWdt);
    min-width : var(--wWdt);
    max-width : var(--wWdt);
    height    : var(--wHgt);
    min-height: var(--wHgt);
    max-height: var(--wHgt);
    top       : var(--wTop);
    left      : var(--wLft);
    background: #ffffff;
    box-shadow: -4px 0px 10px rgba(0, 0, 0, 0.05);
    z-index   : 10;
    overflow-y: auto;
    border    : 2px outset rgba(128,128,128,0.5);
    padding   : 1em;
}
</style>

<script lang="js">
import EditarCodigoInvestimento from './actions/EditarCodigoInvestimento.vue';
import EditarAtivo from './actions/EditarAtivo.vue';
import EmptyAction from './actions/EmptyAction.vue';

export default {
    props : {
        spec: { type: Object },
        args: { type: Object }
    },
    components: {
        EditarCodigoInvestimento,
        EmptyAction,
        EditarAtivo
    },
    data () {
        return {
            wdisplay: 'none'
        }
    },
    methods : {
        onResult        (data) {
            this.wdisplay = 'none';
            this.spec.after && this.spec.after(data);
            this.$emit('onModalResult');
        }
    },
    computed : {
        windowCls       () {
            if (window.innerWidth < 750) {
                return {
                    '--wHgt' : '80%',
                    '--wWdt' : '90%',
                    '--wTop' : '10%',
                    '--wLft' : '5%',
                    'display': this.wdisplay
                };
            }
            const hres = (window.innerHeight - this.spec.width ) / 2;
            const wres = (window.innerWidth  - this.spec.height) / 2;
            return {
                '--wHgt' : `${this.spec.height}px`,
                '--wWdt' : `${this.spec.width}px`,
                '--wTop' : `${hres}px`,
                '--wLft' : `${wres}px`,
                'display': this.wdisplay
            };
        }
    },
    watch: {
        spec: {
            handler (nv,ov) {
                this.wdisplay = nv.display;
            },
            deep: true
        }
    },
    created () {
    },
    mounted () {
    }
}
</script>