<template>
  <v-row class="linha-acc" v-if="!switchView">
    <v-col md="2" xs="12" class="coluna">
      <div class="descricao title-desc">
        <span> {{ item.title }} </span>
      </div>
    </v-col>
    <v-col md="1" xs="12" class="coluna" :title="totalParticipacao">
      <div class="descricao hide">
        <span>
          {{ percentageHelper.formatPercentage(totalParticipacao) }}
        </span>
      </div>
    </v-col>
    <v-col md="2" xs="12" class="coluna">
      <div class="descricao hide">
        <span> </span>
      </div>
    </v-col>
    <v-col md="2" xs="12" class="coluna">
      <div class="descricao hide">
        <span> </span>
      </div>
    </v-col>
    <v-col md="1" xs="12" class="coluna">
      <div class="descricao hide">
        <span> </span>
      </div>
    </v-col>
    <v-col md="2" xs="12" class="coluna">
      <div class="descricao hide">
        <span></span>
      </div>
    </v-col>
    <v-col md="2" xs="12" class="coluna" :title="totalSaldo">
      <div class="descricao">
        <span>  {{ currencyHelper.formatValue(totalSaldo) }} </span>
      </div>
    </v-col>
  </v-row>

  <v-row class="linha-2-acc" v-else>
    <v-col md="2" xs="12" class="coluna"  :style="color" >
      <v-col md="10" xs="12" class="coluna"  :style="color" >
        <div class="descricao title-desc">
          <span> {{ item.title }} </span>
       </div>
      </v-col>
      <v-col md="2" xs="12" class="coluna"  :style="color" >
        <div class="descricao title-desc">
          <span> {{ percentageHelper.formatPercentage(totalParticipacao)}} </span>
       </div>
      </v-col>
    </v-col>
    <v-col md="1" xs="12" class="coluna coluna-composta" :title="totalSaldo">
      <div class="descricao">
        <span> {{ currencyHelper.formatValue(totalSaldo) }} </span>
      </div>
    </v-col>
    <v-col md="3" xs="12" class="coluna coluna-composta">
      <div class="colunas">
        <v-row>
          <v-col md="3" xs="12" class="column hide" :title="mensalGanho">
            <div class="hide"><span>{{ mensalGanho }}</span></div>
          </v-col>

          <v-col md="3" xs="12" class="column hide" :title="mensalPercent">
            <div><span>{{ mensalPercent }}</span></div>
          </v-col>

          <v-col md="6" xs="12" class="column hide" :title="mensalValor">
            <div><span>{{ mensalValor }}</span></div>
          </v-col>
        </v-row>
      </div>
    </v-col>

    <v-col md="3" xs="12" class="coluna coluna-composta">
      <div class="colunas">
        <v-row>
          <v-col md="3" xs="12" class="column hide" :title="dozeGanho">
            <div class=""><span>{{ dozeGanho }}</span></div>
          </v-col>

          <v-col md="3" xs="12" class="column hide" :title="dozePercent">
            <!-- <div><span>{{ dozePercent }}</span></div> -->
            <div><span>{{ '' }}</span></div>
          </v-col>

          <v-col md="6" xs="12" class="column hide" :title="dozeValor">
            <div><span>{{ dozeValor }}</span></div>
          </v-col>
        </v-row>
      </div>
    </v-col>

    <v-col md="3" xs="12" class="coluna coluna-composta">
      <div class="colunas">
        <v-row>
          <v-col md="3" xs="12" class="column hide" :title="geralGanho">
            <div class=""><span>{{ percentageHelper.formatPercentage(geralGanho) }}</span></div>
          </v-col>

          <v-col md="3" xs="12" class="column hide" :title="geralPercent">
            <!-- <div><span>{{ percentageHelper.formatPercentage(geralPercent) }}</span></div> -->
            <div><span>{{ '' }}</span></div>
          </v-col>
          <v-col md="6" xs="12" class="column hide" :title="geralValor">
            <div><span>{{ currencyHelper.formatValue(geralValor) }}</span></div>
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import percentageHelper from "../../../../Utils/PercentageHelper.js";
import currencyHelper from "../../../../Utils/CurrencyHelper.js";

export default {
  name: "Header",

  props: {
    item: { type: Object },
    evolucao: { type: Object },
    switchView: { type: Boolean },
    saldototal: {type : Number},
    cor: { type: String, default: "" },
    bound: { type: Boolean }
  },

  data() {
    return {
      percentageHelper,
      currencyHelper,
      color: {
        backgroundColor: this.cor,
      },
    };
  },
  computed : {
    totalParticipacao() {
      return this.evolucao && this.evolucao.total && this.saldototal && (this.evolucao.total.saldo/ this.saldototal)  || 0;
    },
    totalSaldo() {
      return this.evolucao && this.evolucao.total && this.evolucao.total.saldo || 0;
    },
    mensalGanho() {
      return this.bound ? "---" :
        this.percentageHelper.formatPercentage(
          this.evolucao && this.evolucao.total && this.evolucao.total.mensal.fatia || 0);
    },
    mensalPercent() {
      return this.bound ? "---" :
        this.percentageHelper.formatPercentage(
          this.evolucao && this.evolucao.total && this.evolucao.total.mensal.percentual || 0);
    },
    mensalValor() {
      return this.bound ? "---" :
        this.currencyHelper.formatValue(
          this.evolucao && this.evolucao.total && this.evolucao.total.mensal.valor || 0);
    },
    dozeGanho() {
      return this.bound ? "---" :
        this.percentageHelper.formatPercentage(
          this.evolucao && this.evolucao.total && this.evolucao.total.doze.fatia || 0);
    },
    dozePercent() {
      return this.bound ? "---" :
        this.percentageHelper.formatPercentage(
          this.evolucao && this.evolucao.total && this.evolucao.total.doze.percentual || 0);
    },
    dozeValor() {
      return this.bound ? "---" :
        this.currencyHelper.formatValue(
          this.evolucao && this.evolucao.total && this.evolucao.total.doze.valor || 0);
    },
    geralGanho() {
      return this.evolucao && this.evolucao.total && this.evolucao.total.geral.fatia || 0;
    },
    geralPercent() {
      return this.evolucao && this.evolucao.total && this.evolucao.total.geral.percentual || 0;
    },
    geralValor() {
      return this.evolucao && this.evolucao.total && this.evolucao.total.geral.valor || 0;
    }
  }
};
</script>

<style lang="scss" scoped>
.linha-acc .coluna {
  margin: 0 !important;
  padding: 0.2rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.linha-acc .coluna > div {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  padding: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.linha-acc .coluna > div span {
  font-size: 0.85rem;
}

.linha-2-acc {
  margin-left: -12px;
  margin-bottom: 0.1rem;
  margin-top: 0.1rem;
}

.linha-2-acc .coluna {
  background : #B7DF42;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  padding: 0.5rem;
}

.linha-2-acc .coluna > div {
  border-radius: 40px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.linha-2-acc .coluna > div span {
  font-size: 0.80rem;
  padding: 0.2rem;
}
.linha-2-acc .coluna-composta  {
  background: white;
  border: 2pt !important;
  border-color: #B7DF42 !important;
  color: black;
}
.linha-2-acc .coluna-composta .column > div {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.linha-2-acc .coluna-composta .column {
  width: 100% !important;
}

.title-desc span{
font-size: clamp(.5em, .73em, 1em) !important;
}
@media screen and (max-width: 950px) {
  /*.linha-2-acc .coluna .colunas .column {
  }*/
  .hide {
    display: none !important;
  }
  .title-desc {
    padding-left: 1.8em !important;
  }
}

@media screen and (max-width: 1200px) {
  .linha-2-acc .coluna > div span {
    font-size: 0.55rem;
  }
}

@media screen and (max-width: 950px) {
  .linha-2-acc .coluna > div span {
    font-size: 0.75rem;
  }
}

@media screen and (max-width: 950px) {
  /*.linha-acc {
  }*/
  .hide {
    display: none !important;
  }
  .title-desc {
    padding-left: 1.8em !important;
  }
}
</style>
