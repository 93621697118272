<template>
  <v-container class="background">
    <router-view />
  </v-container>
</template>

<style scoped>
.background {
  max-width: none;
  background: var(--principal-azul);
  padding: 0 !important;
}
</style>

<script>
export default {
  name: "ExternalLayout",
};
</script>
