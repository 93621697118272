import Layout from "../../Internal/Layout.vue";
import Base from "./Base.vue";

const CarteiraMovimentacaoRoutes = {
  component: Layout,
  children: [
    {
      name: "carteira",
      path: "/",
      component: Base,
      meta: {
        requiresAuth: true,
        requiresBasic: false,
        requiresPattern: true,
        requiresSenior: true,
        requiresExpert: true,
        requiresProfessional: true,
      },
    },
  ],
};

export default CarteiraMovimentacaoRoutes;
