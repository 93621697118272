<template>
  <DetalhedExam />
</template>

<script>
import DetalhedExam from "./components/DetalhedExam.vue";
export default {
  name: "ExamStatus",

  components: {
    DetalhedExam,
  },
};
</script>

<style></style>
