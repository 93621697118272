<template>
  <div class="container">
    <div class="date-piker">
      <v-text-field
        rounded
        outlined
        dense
        type="date"
        :disabled="disabled"
        name=""
        id=""
        v-model="vData"
        @change="change"
        @focus="onCtrlFocus"
        @blur="onCtrlBlur"
        :rules="[(v) => !!v || 'Campo obrigatório']"
      ></v-text-field>
    </div>
    <!-- {{ data }} -->
  </div>
</template>

<script>
export default {
  name: "DatePiker",

  props: {
    value: String,
    disabled: Boolean,
  },

  created() {
    if (this.value) {
      this.vData = this.value;
    }
  },

  data() {
    return {
      data: "",
      items: [],
      user: "",
      //vData: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      vData:"",
      menu: false,
      modal: false,
      menu2: false
    };
  },

  methods: {
    change() {
      this.$emit("change", this.vData);
    },
    onCtrlFocus () {
      this.$emit("edit:enter", this.vData);
    },
    onCtrlBlur () {
      this.$emit("edit:leave", this.vData);
    }
  },

  watch: {
    value() {

      this.vData = undefined;
      this.vData = this.value;
      this.$emit("change", this.vData);

    },
  },
};
</script>

<style scoped>
.menu {
  border-radius: 50px;
}
/* .date-piker { margin-top: 0.5rem; } */
.date-piker input {
  width: 100%;
  border: 1px solid #e6e6e6;
  color: #a4a4a4;
  border-radius: 40px;
  padding: 0.5rem 0.5rem 0.5rem 2.5rem;
  outline: none;
  position: relative;
}
.date-piker input::-webkit-calendar-picker-indicator {
  color: #a4a4a4 !important;
  background: url("../../../../../assets/icons/calendar_icon.svg") no-repeat;
  position: absolute;
  left: -10px;
}
</style>