<template>
  <div class="dado" :style="color">

    <div class="title">
      <span>{{cell.title}}</span>
    </div>
    <div class="value">
      <small>{{cellValue}}</small>
    </div>
  </div>
</template>

<script>
import percentageHelper from '@/Utils/PercentageHelper';
export default {
    props:{
        cell:{type: Object}
    },
    data(){
      return{
        color:{
          backgroundColor: this.cell?.color
        }
      }
    },
    computed: {
      cellValue () {
        return this.cell
          && (this.cell.value != null)
          && (this.cell.value !== undefined)
          && percentageHelper.formatPercentage(this.cell.value / 100)
          || "0,00";
      }
    }
};
</script>

<style  scoped>
.dado{
    width: 100%;
    height: 15vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.title span{
    font-size: 14px;
}


@media screen and (max-width:1100px){
  .title span{
    font-size: 11px !important;
  }
}

@media screen and (max-width:700px){
  .dado{
    padding: 0 .2rem;
  }
}
</style>