import Layout from "../../Internal/Layout.vue";
import GestaoUsuarios from "./GestaoUsuarios.vue";

const GestaoUsuariosRoutes = {
    component: Layout,
    children: [
        {
            name: "gestaoUsuarios",
            path: "/",
            component: GestaoUsuarios,
            meta: {
                requiresAuth: true,
                requiresBasic: true,
                requiresPattern: true,
                requiresSenior: true,
                requiresExpert: true,
                requiresProfessional: true,
            },
        }, 
        {
            name: "paginaUsuario",
            path: ":id",
            component: () => import('./PaginaUsuario.vue'),
            meta: {
                requiresAuth: true,
                requiresBasic: true,
                requiresPattern: true,
                requiresSenior: true,
                requiresExpert: true,
                requiresProfessional: true,        
            }   
        }
    ],
};

export default GestaoUsuariosRoutes;
