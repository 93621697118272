<template>
     <div>
      <div class="title">
        <span
          >Cadastrar
          <span style="color: var(--principal-azul)">Usuário</span></span
        >
      </div>
      <div class="btn-close" @click="$emit('closeModal')">
        <v-img src="@/assets/icons/clear_icon.svg" />
      </div>
      <keep-alive>
        <component
          :is="formularioNovoUsuario"
          v-bind:tipoAtivo="currentPropertie"
          v-bind:botaoRegistrarUsuario="botaoRegistrarUsuarioClicado"
        ></component>
      </keep-alive>

      <div class="btn-cadastrar-usuario">
        <v-btn
          large
          rounded
          color="primary"
          @click="$emit('execRegisterUser')"
          :loading="loading"
        >
          <p style="color: white; margin-bottom: 0px">Cadastrar</p>
        </v-btn>
      </div>
    </div>
</template>

<script>
import FormularioNovoUsuario from '../components/NovoUsuario.vue'
    export default {
        components:{
            FormularioNovoUsuario
        },
        props:{
             currentPropertie: { type: Object },
             loading: {type: Boolean},
             botaoRegistrarUsuarioClicado:{type: Boolean}
        },
        data(){
          return{
              formularioNovoUsuario: 'FormularioNovoUsuario'
            }
        }
    }
</script>

<style  scoped>
.title span {
  font-weight: 300;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.15px;
}
.btn-close {
  cursor: pointer;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 25px;
  top: 40px;
}
.btn-close img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.btn-cadastrar-usuario {
  position: fixed;
  width: 100%;
  max-width: 497px;
  right: 0;
  bottom: 0;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  -webkit-box-shadow: 3px 2px 17px -1px rgba(0, 0, 0, 0.3);
  box-shadow: 3px 2px 17px -1px rgba(0, 0, 0, 0.3);
  background: white;
}
.btn-cadastrar-usuario span {
  border-radius: 20px;
  padding: 0.5rem 1rem;
  color: white;
  background: var(--principal-azul);
  cursor: pointer;
}
.btn-cadastrar-usuario span:hover {
  background: #6fa9f5;
}
.btn-cadastrar-usuario .v-btn {
  background: var(--principal-azul);
  border: 1px solid red;
}
</style>