<template>
    <v-container class="strat-editor">
        <div class="title">
            <span>Editar <span style="color: #b5e546">Estratégia(s)</span></span>
        </div>

        <div class="bnts-tipo-ativo">
        </div>

        <div class="btn-close" @click="$emit('closeModal')">
            <v-img src="@/assets/icons/clear_icon.svg" />
        </div>

        <div v-if="loading" class="d-flex justify-center align-center pa-8">
            <v-progress-circular :size="35"
                                 color="primary"
                                 indeterminate />
        </div>
        <div>&nbsp;</div>

        <div class="row">
            <div class="label">Tipo:</div>
            <div>
                <v-select :items="filterTipos"
                          v-model="tipoSel"
                          placeholder="Tipo de ativo"
                          color="secondary"
                          item-text="name"
                          return-object
                          required
                          dense
                          outlined
                          rounded
                          :disabled="loading"
                          @change="$emit('onChange', $event)"
                          class="select-tipo">
                </v-select>
            </div>
        </div>
        <div class="row">
            <div class="label">Ativo:</div>
            <div>
                <v-select :items="ativos"
                          v-model="ativoSel"
                          placeholder="Ativo"
                          color="secondary"
                          item-text="code"
                          return-object
                          required
                          dense
                          outlined
                          rounded
                          :disabled="notdefined"
                          class="select-ativo">
                </v-select>
            </div>
        </div>
        <div class="row">
            <div class="label">Estratégia corrente:</div>
            <div class="display">&nbsp;{{ itemStrat }}</div>
            <div class="label">Nova estratégia:</div>
            <div>
                <v-select :items="filterStrats"
                          v-model="stratSel"
                          placeholder="Estratégia"
                          color="secondary"
                          item-text="descricao"
                          return-object
                          required
                          dense
                          outlined
                          rounded
                          :disabled="notprepared"
                          class="select-ativo">
                </v-select>
            </div>
        </div>

        <div class="conteudo">
            <div :class="cmdcls">
                <span @click="commitChanges">Editar</span>
            </div>
        </div>
    </v-container>
</template>

<style scoped>
.strat-editor .row {
    margin: 1rem;
    display: flex;
    flex-direction: column;
}
.strat-editor .row > div {
    flex-basis: 100%;
    width: 100%;
    min-width: 100%;
}
.strat-editor .row > div.display {
    border-radius: 20px;
    background-color: rgba(196,196,196,0.5);
    padding: 0.5em;
}
.strat-editor .row > div.label {
    padding: 0.5em;
}
.btn-close {
  cursor: pointer;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 25px;
  top: 40px;
}
.conteudo {
  position: relative;
  padding: 1.5rem;
}
.btn-edit {
  position: fixed;
  width: 100%;
  max-width: 497px;
  right: 0;
  bottom: 0;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  -webkit-box-shadow: 3px 2px 17px -1px rgba(0, 0, 0, 0.3);
  box-shadow: 3px 2px 17px -1px rgba(0, 0, 0, 0.3);
  background: white;
}
.btn-edit span {
  border-radius: 20px;
  padding: 0.5rem 1rem;
  color: white;
  background: #f25a5a;
  cursor: pointer;
}
.btn-edit.cmd-disabled span {
  border-radius: 20px;
  padding: 0.5rem 1rem;
  color: white;
  background: #CCCCCC;
  cursor: default;
}
</style>

<script lang="js">
import ApiService from '../../../../services/ApiService';
import EventBus from '../../../Bus/bus';

export default {
    props                   : {
        showModal           : { type: Object },
        tipos               : { type: Array },
        ativos              : { type: Array }
    },
    data                    () {
        return              {
            loading         : false,
            apiService      : undefined,
            tipoSel         : undefined,
            filterTipos     : [],
            ativoSel        : undefined,
            strats          : [],
            filterStrats    : [],
            stratSel        : undefined
        }
    },
    methods                 : {
        getStrats           () {
            this.loading = true;
            return this.apiService.getAllStrats().then(
                    (data)   => {
                        this.loading = false;
                        if (!data.success) {
                            this.$toast.error(data.message);
                            return;
                        }
                        this.strats         = data.content;
                        this.filterStrats   = data.content;
                        this.strats.sort((a, b) => a.descricao.localeCompare(b.descricao));
                    },
                    (reason) => {
                        this.loading = false;
                        this.$toast.error(reason.message);
                    });
        },
        commitChanges       () {
            let filled  = (!this.loading) && this.ativoSel && this.stratSel;
            if (!filled) { return; }
            let aid     = this.ativoSel.activeId;
            let sid     = this.stratSel.idEstrategia;
            return this.apiService
                .updateStrategy(aid, sid)
                .then((data) => {
                    if (!data.success) {
                        this.$toast.error(data.message);
                    } else {
                        this.$toast.info('Alteração efetuada com sucesso.');
                        this.$store.commit("SET_USERTOATIVOUSEREstrategia", undefined);
                        this.$store.commit("SET_USERTOATIVOUSERRentabilidade", undefined);
                        this.$store.commit("SET_USERTOATIVOUSERLiquidez", undefined);
                        this.$store.commit("SET_USERTOATIVOUSERProduto", undefined);
                        this.$store.commit("SET_LISTAATIVOSFUNINV", undefined);
                        EventBus.$emit('reloadPagesForCallActivesAgain', true);
                        this.$emit('closeModal');
                    }
                });
        }
    },
    computed                : {
        itemStrat           () {
            return this.ativoSel ? this.ativoSel.strategyDescription : '';
        },
        notdefined          () {
            return this.loading || !this.tipoSel;
        },
        notprepared         () {
            return this.loading || !(this.tipoSel && this.ativoSel);
        },
        cmdcls              () {
            let filled = (!this.loading) && this.ativoSel && this.stratSel;
            return 'btn-edit' + (filled ? ' cmd-active' : ' cmd-disabled');
        }
    },
    watch                   : {
        tipoSel () {
            this.ativoSel       = undefined;
            this.stratSel       = undefined;
        },
        ativoSel (nv) {
            this.stratSel       = undefined;
            this.filterStrats   = this.strats;
            if (!nv) { return; }
            this.filterStrats   = this.strats
                .filter(s => s.descricao !== nv.strategyDescription);
        }
    },
    async created           () {
        this.apiService     = new ApiService();
        this.filterTipos    = this.tipos.filter(f => f.id !== 1);
        await this.getStrats();
    }
}
</script>