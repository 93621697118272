<template>
  <div class="navbar">
    <!-- ************** TIPO USUARIO 1 ****************** -->
    <div class="nav">
      <v-app-bar app color="white" max-height="70px" height="70px">
        <v-app-bar-nav-icon
          @click.stop="drawer = !drawer"
          class="btn-drawer"
        ></v-app-bar-nav-icon>

        <v-spacer></v-spacer>

        <v-list class="itens-nav">
          <div
            class="pesquisar-usuario"
            v-if="user.userProfile == 1 && appearInNavbar"
          >
            <div class="ctrl-mock">{{ selectedUserName }}</div>
            <!-- <span>Pesquisar usuário</span> -->
          </div>

          <v-list-item class="item-nav">
            <v-btn
              id="SejaPro"
              class="btn-be-pro"
              @click="bePro($event)"
              ref="btnBePro"
            >
              SEJA PRO!
            </v-btn>

            <v-dialog
              content-class="dialog-container"
              v-model="dialogBePro"
              max-width="90%"
            >
              <v-card class="card-dialog">
                <v-card-title class="title-dialog">
                  <span class="strong"
                    >Essa ação é apenas para usuários premium ou gold</span
                  >
                  <span
                    >Escolha o seu plano
                    <span class="strong">Kristal Connect</span></span
                  >
                </v-card-title>

                <div class="btns-capa">
                  <div
                    class="mensal"
                    @click="changeMensalOuAnual"
                    :class="{ clicado: anualOuMensal }"
                  >
                    <span>Mensal</span>
                  </div>
                  <div
                    class="anual"
                    @click="changeMensalOuAnual"
                    :class="{ clicado: !anualOuMensal }"
                  >
                    <span>Anual</span>
                  </div>
                </div>
                <v-card-actions class="cards-dialog">
                  <div class="card-d">
                    <CardBasic
                      titleBtn="Continuar com KristalBasic"
                      :showBtn="false"
                    />
                    <div class="text">
                      <span v-if="user.planType == 0">Seu plano !</span>
                    </div>
                  </div>
                  <div class="card-d">
                    <CardPremium
                      titleBtn="Escolher KristalPadrão"
                      :showBtn="false"
                    />
                    <div class="text">
                      <span v-if="user.planType == 1">Seu plano !</span>
                    </div>
                  </div>

                  <div class="card-d">
                    <CardGold
                      titleBtn="Escolher KristalSênior"
                      :showBtn="false"
                    />
                    <div class="text">
                      <span v-if="user.planType == 2">Seu plano !</span>
                    </div>
                  </div>
                </v-card-actions>
                <div class="btn-close-dialog" @click="closeDialog">
                  <v-img src="@/assets/icons/clear_icon.svg" />
                </div>

                <div class="btn-plano-diferente" @click="goChoosePlan">
                  <span> Escolher um plano diferente </span>
                </div>
              </v-card>
            </v-dialog>
          </v-list-item>

          <v-list-item class="item-nav">
            <v-tooltip bottom color="blue" v-if="appearInNavbar">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  id="CadastrarCompra"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  class="tooltip"
                  @click="showModal('CadastrarCompra', $event)"
                >
                  <img
                    src="@/assets/icons/up-and-down-arrow.svg"
                    alt="dashboard"
                    class="icon"
                  />
                </v-btn>
              </template>
              <span>Movimentar Ativo</span>
            </v-tooltip>
          </v-list-item>

          <v-list-item class="item-nav">
            <v-tooltip bottom color="blue" v-if="appearInNavbar">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  id="CadastrarVenda"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  class="tooltip"
                  @click="showModal('CadastrarVenda', $event)"
                >
                  <img
                    src="@/assets/icons/close.svg"
                    alt="dashboard"
                    class="icon"
                  />
                </v-btn>
              </template>
              <span>Excluir Ativo</span>
            </v-tooltip>
          </v-list-item>

          <v-list-item class="item-nav">
            <v-tooltip bottom color="blue" v-if="appearInNavbar">
              <template v-slot:activator="{ on, attrs }">
                <v-btn id="EditarEstrategia" icon v-bind="attrs" v-on="on"
                       class="tooltip" @click="showModal('EditarEstrategia', $event)">
                  <img src="@/assets/icons/edit_icon.svg" alt="Strategy" class="icon" />
                </v-btn>
              </template>
              <span>Editar estratégia</span>
            </v-tooltip>
          </v-list-item>

          <v-list-item v-if="user.userProfile == 1" class="item-nav">
            <v-tooltip bottom color="blue">
              <template v-slot:activator="{ on, attrs }">
                <router-link to="/gestao-usuario">
                  <v-btn icon v-bind="attrs" v-on="on" class="tooltip">
                    <img
                      src="@/assets/icons/gestao_usuario_icon.svg"
                      alt="perfil"
                      class="icon"
                    />
                  </v-btn>
                </router-link>
              </template>
              <span>Gestão de usuários</span>
            </v-tooltip>
          </v-list-item>

          <v-list-item class="item-nav">
            <v-tooltip bottom color="blue">
              <template v-slot:activator="{ on, attrs }">
                <router-link to="/perfil">
                  <v-btn icon v-bind="attrs" v-on="on" class="tooltip">
                    <img
                      src="@/assets/icons/profile_icon.svg"
                      alt="perfil"
                      class="icon"
                    />
                  </v-btn>
                </router-link>
              </template>
              <span>Perfil</span>
            </v-tooltip>
          </v-list-item>

          <v-list-item class="item-nav">
            <v-tooltip bottom color="blue">
              <template v-slot:activator="{ on, attrs }">
                <router-link to="/importacao">
                  <v-btn id="ImportarAtivo" icon v-bind="attrs" v-on="on" class="tooltip">
                    <img src="@/assets/icons/import.svg"
                        alt="dashboard"
                        class="icon" />
                  </v-btn>
                </router-link>
              </template>
              <span>Importar Ativo(s).</span>
            </v-tooltip>
          </v-list-item>

          <v-list-item class="item-nav">
            <v-tooltip bottom color="blue">
              <template v-slot:activator="{ on, attrs }">
                <router-link to="/visao-geral">
                  <v-btn icon v-bind="attrs" v-on="on" class="tooltip">
                    <img
                      src="@/assets/icons/dashboard_icon.svg"
                      alt="dashboard"
                      class="icon"
                    />
                  </v-btn>
                </router-link>
              </template>
              <span>Visão Geral</span>
            </v-tooltip>
          </v-list-item>

          <v-list-item class="item-nav">
            <v-tooltip bottom color="blue">
              <template v-slot:activator="{ on, attrs }">
                <router-link to="/rentabilidade">
                  <v-btn icon v-bind="attrs" v-on="on" class="tooltip">
                    <img
                      src="@/assets/icons/pizza_icon.svg"
                      alt=""
                      class="icon"
                    />
                  </v-btn>
                </router-link>
              </template>
              <span>Rentabilidade</span>
            </v-tooltip>
          </v-list-item>

          <v-list-item class="item-nav">
            <v-tooltip bottom color="blue">
              <template v-slot:activator="{ on, attrs }">
                <router-link to="/liquidez">
                  <v-btn icon v-bind="attrs" v-on="on" class="tooltip">
                    <img
                      src="@/assets/icons/money_icon.svg"
                      alt=""
                      class="icon"
                    />
                  </v-btn>
                </router-link>
              </template>
              <span>Liquidez</span>
            </v-tooltip>
          </v-list-item>

          <v-list-item class="item-nav">
            <v-tooltip bottom color="blue">
              <template v-slot:activator="{ on, attrs }">
                <router-link to="/carteira">
                  <v-btn icon v-bind="attrs" v-on="on" class="tooltip">
                    <img
                      src="@/assets/icons/wallet_icon.svg"
                      alt=""
                      class="icon"
                    />
                  </v-btn>
                </router-link>
              </template>
              <span>Carteira</span>
            </v-tooltip>
          </v-list-item>

          <v-list-item class="item-nav">
            <v-tooltip bottom color="blue">
              <template v-slot:activator="{ on, attrs }">
                <router-link to="/report-tables">
                  <v-btn icon v-bind="attrs" v-on="on" class="tooltip" >
                    <img
                      src="@/assets/icons/relatorio.svg"
                      alt=""
                      class="icon"
                    />
                  </v-btn>

                </router-link>

              </template>
              <span>Relatório</span>
            </v-tooltip>
          </v-list-item>

          <v-list-item class="item-nav">
            <v-tooltip bottom color="blue">
              <template v-slot:activator="{ on, attrs }">
                <router-link to="/ReportMonth">
                  <v-btn icon v-bind="attrs" v-on="on" class="tooltip" >
                    <img
                      src="@/assets/icons/informacoes.svg"
                      alt=""
                      class="icon"
                    />
                  </v-btn>

                </router-link>

              </template>
              <span>Informações</span>
            </v-tooltip>
          </v-list-item>

          <div class="text-center">
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-width="200"
              offset-y
              rounded="lg"
              max-width="300"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-card-actions>
                  <v-list-item class="grow">
                    <v-list-item-avatar>
                      <v-img
                        class="elevation-6 img-profila-web"
                        alt=""
                        :src="imagemPerfil"
                        v-bind="attrs"
                        v-on="on"
                      ></v-img>
                    </v-list-item-avatar>
                  </v-list-item>
                </v-card-actions>
              </template>

              <v-card class="card-profile mx-auto" max-width="300">
                <v-list>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="text-lg name"
                        ><span>{{
                          userFormated.name || "Nome não registrado"
                        }}</span></v-list-item-title
                      >
                      <v-list-item-subtitle class="email">
                        <span>{{ userFormated.email }}</span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>

                <v-divider style="border-color: #f6f6f6 !important"></v-divider>

                <v-list>
                  <v-list-item
                    @click="goToConfig"
                    class="item d-flex justify-center align-center p-5"
                  >
                    <v-img src="@/assets/icons/config_icon.svg" class="img" />
                    <v-list-item-title class="title"
                      ><span>Configurações</span></v-list-item-title
                    >
                  </v-list-item>

                  <v-divider
                    style="border-color: #f6f6f6 !important"
                  ></v-divider>

                  <v-list-item
                    @click="logout"
                    class="item d-flex justify-center align-center p-5"
                  >
                    <v-img src="@/assets/icons/sair_icon.svg" class="img" />
                    <v-list-item-title class="title"
                      ><span>Sair</span></v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </div>
        </v-list>
      </v-app-bar>

      <v-navigation-drawer
        v-model="drawer"
        fixed
        temporary
        class="navigation-drawer-wrapper"
      >
        <v-list nav dense>
          <v-list-item-group
            v-model="group"
            active-class="deep-purple--text text--accent-4"
          >
            <div
              class="drawer-mobile"
              v-if="user.userProfile == 1 && appearInNavbar"
            >
              <div class="ctrl-mock">{{ selectedUserName }}</div>
            </div>

            <v-list-item>
              <v-list-item-title>
                <v-btn  v-if="appearInNavbar"
                  id="CadastrarCompra"
                  icon
                  class="tooltip"
                  @click="showModal('CadastrarCompra', $event)"
                >
                  <img
                    src="@/assets/icons/up-and-down-arrow.svg"
                    alt="dashboard"
                    class="icon"
                  />
                  <span class="name-action">Movimentar Ativo</span>
                </v-btn>
              </v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-title>
                <v-btn  v-if="appearInNavbar"
                  id="CadastrarVenda"
                  icon
                  class="tooltip"
                  @click="showModal('CadastrarVenda', $event)"
                >
                  <img
                    src="@/assets/icons/close.svg"
                    alt="dashboard"
                    class="icon"
                  />
                  <span class="name-action">Excluir ativo</span>
                </v-btn>
              </v-list-item-title>
            </v-list-item>

            <v-list-item class="item-nav">
              <v-list-item-title>
                <v-btn
                  id="CadastrarVenda"
                  icon
                  class="tooltip"
                  @click="goToGestaoUsuario"
                >
                  <img
                    src="@/assets/icons/gestao_usuario_icon.svg"
                    alt="perfil"
                    class="icon"
                  />
                  <span class="name-action">Gestão de usuários</span>
                </v-btn>
              </v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-title>
                <v-btn icon class="tooltip" @click="goToVisaoGeral">
                  <img
                    src="@/assets/icons/dashboard_icon.svg"
                    alt="dashboard"
                    class="icon"
                  />
                  <span class="name-action">Visão Geral</span>
                </v-btn>
              </v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-title>
                <v-btn icon class="tooltip" @click="goToRentabilidade">
                  <img
                    src="@/assets/icons/pizza_icon.svg"
                    alt=""
                    class="icon"
                  />
                  <span class="name-action">Rentabilidade</span>
                </v-btn>
              </v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-btn icon class="tooltip" @click="goToLiquidez">
                <img src="@/assets/icons/money_icon.svg" alt="" class="icon" />
                <span class="name-action">Liquidez</span>
              </v-btn>
            </v-list-item>

            <v-list-item>
              <v-btn icon class="tooltip" @click="goToCarteira">
                <img src="@/assets/icons/wallet_icon.svg" alt="" class="icon" />
                <span class="name-action">Carteira</span>
              </v-btn>
            </v-list-item>

            <v-list-item>
              <v-btn icon class="tooltip" @click="goToReport">
                <img src="@/assets/icons/upload.svg" alt="" class="icon" />
                <span class="name-action">Relatório</span>
              </v-btn>
            </v-list-item>

            <v-list-item>
              <div class="card-profile-mobile">
                <div class="card-profile-mobile-header">
                  <v-list-item-avatar>
                    <v-img
                      class="elevation-6"
                      alt=""
                      :src="imagemPerfil"
                    ></v-img>
                  </v-list-item-avatar>

                  <v-list-item-title>
                    <span>{{ user.name || "Alexandre Mendes" }}</span>
                  </v-list-item-title>
                </div>
                <div class="card-profile-mobile-configs">
                  <v-list>
                    <v-divider
                      style="border-color: #f6f6f6 !important"
                    ></v-divider>
                    <v-list-item
                      @click="goToConfig"
                      class="item d-flex justify-center align-center p-5"
                    >
                      <v-img src="@/assets/icons/config_icon.svg" class="img" />
                      <v-list-item-title class="title"
                        ><span>Configurações</span></v-list-item-title
                      >
                    </v-list-item>

                    <v-list-item class="item" @click="logout">
                      <v-img src="@/assets/icons/sair_icon.svg" class="img" />
                      <v-list-item-title class="title"
                        ><span>Sair</span></v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </div>
              </div>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
    </div>

    <!-- *************** TIPO USUARIO 0 ******************  -->
    <!-- <div class="nav" v-if="typeUser == 0">
      <v-app-bar app color="white" max-height="70px" height="70px">
        <v-app-bar-nav-icon
          @click.stop="drawer = !drawer"
          class="btn-drawer"
        ></v-app-bar-nav-icon>

        <v-spacer></v-spacer>

        <v-list class="itens-nav">
          <v-list-item class="item-nav">
            <v-tooltip bottom color="blue">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  id="CadastrarCompraAcessor"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  class="tooltip"
                  @click="showModal('CadastrarCompraAcessor', $event)"
                >
                  <img
                    src="@/assets/icons/add_circle_icon.svg"
                    alt="dashboard"
                    class="icon"
                  />
                </v-btn>
              </template>
              <span>Incluir Ativo</span>
            </v-tooltip>
          </v-list-item>

          <v-list-item class="item-nav">
            <v-tooltip bottom color="blue">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  id="CadastrarVendaAcessor"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  class="tooltip"
                  @click="showModal('CadastrarVendaAcessor', $event)"
                >
                  <img
                    src="@/assets/icons/remove_circle_icon.svg"
                    alt="dashboard"
                    class="icon"
                  />
                </v-btn>
              </template>
              <span>Excluir Ativo</span>
            </v-tooltip>
          </v-list-item>


          <v-list-item class="item-nav">
            <v-tooltip bottom color="blue">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" class="tooltip">
                  <router-link to="/home"><img src="@/assets/icons/person_icon.svg" alt="" class="icon" /></router-link>
                </v-btn>
              </template>
              <span>Gestão de usuário</span>
            </v-tooltip>
          </v-list-item>

          <div class="text-center">
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-width="200"
              offset-y
              rounded="lg"
              max-width="300"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-card-actions>
                  <v-list-item class="grow">
                    <v-list-item-avatar>
                      <v-img
                        class="elevation-6 img-profila-web"
                        alt=""
                        src="@/assets/empty.png"
                        v-bind="attrs"
                        v-on="on"
                      ></v-img>
                    </v-list-item-avatar>
                  </v-list-item>
                </v-card-actions>
              </template>

              <v-card class="card-profile mx-auto" max-width="300">
                <v-list>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="text-lg name"
                        ><span>{{userFormated.user.name || 'Nome não registrado'}}</span></v-list-item-title
                      >
                      <v-list-item-subtitle class="email">
                        <span>{{userFormated.user.email}}</span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>

                <v-divider style="border-color: #f6f6f6 !important"></v-divider>

                <v-list>
                  <v-list-item
                    @click="goToConfig"
                    class="item d-flex justify-center align-center p-5"
                  >
                    <v-img src="@/assets/icons/config_icon.svg" class="img" />
                    <v-list-item-title class="title"
                      ><span>Configurações</span></v-list-item-title
                    >
                  </v-list-item>

                  <v-divider style="border-color: #f6f6f6 !important"></v-divider>

                  <v-list-item
                    @click="logout"
                    class="item d-flex justify-center align-center p-5"
                  >
                    <v-img src="@/assets/icons/sair_icon.svg" class="img" />
                    <v-list-item-title class="title"
                      ><span>Sair</span></v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </div>
        </v-list>
      </v-app-bar>

      <v-navigation-drawer
        v-model="drawer"
        absolute
        temporary
        class="navigation-drawer-wrapper"
      >
        <v-list nav dense>
          <v-list-item-group
            v-model="group"
            active-class="deep-purple--text text--accent-4"
          >
            <v-list-item>
              <v-list-item-title>
                <v-btn
                  id="CadastrarCompraAcessor"
                  icon
                  class="tooltip"
                  @click="showModal('CadastrarCompraAcessor', $event)"
                >
                  <img
                    src="@/assets/icons/add_circle_icon.svg"
                    alt="dashboard"
                    class="icon"
                  />
                  <span class="name-action">Incluir Ativo</span>
                </v-btn>
              </v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-title>
                <v-btn
                  id="CadastrarVendaAcessor"
                  icon
                  class="tooltip"
                  @click="showModal('CadastrarVendaAcessor', $event)"
                >
                  <img
                    src="@/assets/icons/remove_circle_icon.svg"
                    alt="dashboard"
                    class="icon"
                  />
                  <span class="name-action">Vender</span>
                </v-btn>
              </v-list-item-title>
            </v-list-item>

            <v-list-item>
              <div class="card-profile-mobile">
                <div class="card-profile-mobile-header">
                  <v-list-item-avatar>
                    <v-img
                      class="elevation-6"
                      alt=""
                      src="@/assets/empty.png"
                    ></v-img>
                  </v-list-item-avatar>

                  <v-list-item-title>
                    <span>Alexandre Mendes</span>
                  </v-list-item-title>
                </div>
                <div class="card-profile-mobile-configs">
                  <v-list>
                    <v-divider
                      style="border-color: #f6f6f6 !important"
                    ></v-divider>
                    <v-list-item
                      @click="goToConfig"
                      class="item d-flex justify-center align-center p-5"
                    >
                      <v-img src="@/assets/icons/config_icon.svg" class="img" />
                      <v-list-item-title class="title"
                        ><span>Configurações</span></v-list-item-title
                      >
                    </v-list-item>

                    <v-list-item class="item" @click="logout">
                      <v-img src="@/assets/icons/sair_icon.svg" class="img" />
                      <v-list-item-title class="title"
                        ><span>Sair</span></v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </div>
              </div>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
    </div> -->
  </div>
</template>

<style scoped>
.btn-drawer {
  display: none;
}
.btn-be-pro {
  border: 2px solid var(--principal-azul);
  border-radius: 20px;
  box-shadow: none;
  background: none !important;
  color: var(--principal-azul) !important;
  visibility: hidden;
}
.btn-be-pro:hover {
  background: var(--principal-azul) !important;
  color: white !important;
}
.btns-capa {
  display: flex;
  align-items: center;
  justify-content: center;
}
.btns-capa > div {
  border-radius: 20px;
  padding: 0.5rem 1.5rem;
  cursor: pointer;
  margin: 1rem 0.5rem;
  border: 1px solid #a4a4a4;
  color: #a4a4a4;
}
.clicado {
  background: var(--principal-azul) !important;
  color: white !important;
  border: none !important;
}
.active-dialog {
  background: var(--principal-azul) !important;
  color: white !important;
  border: none !important;
}
.navigation-drawer-wrapper {
  height: 100vh !important;
}

/* DIALOG */
* >>> .v-dialog {
  border-radius: 20px !important;
}
* >>> .v-dialog::-webkit-scrollbar {
  width: 0px !important;
}

.card-dialog {
  position: relative;
  border-radius: 20px !important;
  padding: 2.5rem 0;
}
.btn-close-dialog {
  position: absolute;
  width: 20px;
  height: 20px;
  overflow: hidden;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.btn-close-dialog img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.title-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center !important;
}
.title-dialog span {
  font-weight: 400;
  margin: 0.4rem 0;
}
.title-dialog .strong {
  font-weight: 700 !important;
}
.cards-dialog {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 1.5rem;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
}
.card-dialog  .card-d{
  position: relative;
  margin: 1rem 0;
}
.card-dialog > .text {
  text-align: center;
}
.card-dialog .text{
   margin-top: 1rem;
   text-align: center;
   position: absolute;
   left: -50%;
    right: -50%;
    bottom: -40px;
}
.cards-dialog > div .text span {

  font-weight: bold;
  font-size: 1.2em;
  line-height: 34px;
  letter-spacing: 0.15px;
  color: #579af2;
}
.btn-plano-diferente {
  border: 1px solid var(--principal-azul);
  margin: 2.5rem auto;
  max-width: 30%;
  padding: 0.5rem;
  border-radius: 20px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: var(--principal-azul);
  cursor: pointer;
  transition: 0.2s ease-in-out;
}
.btn-plano-diferente:hover {
  background: var(--principal-azul);
  color: white;
}

/* FIM DIALOG */

.itens-nav {
  display: flex;
  align-items: center;
  height: 100%;
}
.item-nav {
  padding: 0 0.3rem;
}
a.router-link-active {
  position: relative;
}
a.router-link-active::after {
  content: " ";
  width: 45px;
  height: 4px;
  background: var(--principal-azul);
  position: absolute;
  bottom: -12px;
  left: 2px;
}
a.router-link-active img {
  filter: invert(35%) sepia(15%) saturate(2082%) hue-rotate(178deg)
    brightness(99%) contrast(91%);
}
.active {
  position: relative;
}
.active::after {
  content: " ";
  width: 100%;
  height: 4px;
  background: var(--principal-azul);
  position: absolute;
  bottom: -12px;
}
.active img {
  filter: invert(35%) sepia(15%) saturate(2082%) hue-rotate(178deg)
    brightness(99%) contrast(91%);
}
.navbar {
  z-index: 15;
}
.nav {
  z-index: 15;
}
/* .v-app-bar {
  border: 1px solid red !important;
  position: relative;
} */
.pesquisar-usuario {
  /* border: 1px solid red; */
  position: absolute;
  top: 15px;
  left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 250px;
}
.ctrl-mock {
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-radius: 28px;
  padding: 0.6em 1em;
}
/* .v-list {
  border: 1px solid blue;
  width: 100%;
} */
/* .v-list > div {
  border: 1px solid green;
} */
.v-app-bar {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05) !important;
}
.v-tooltip__content {
  border-radius: 30px !important;
  top: 60px !important;
  z-index: 20 !important;
}
.v-tooltip__content::after {
  content: " ";
  position: absolute;
  bottom: 100%; /* At the top of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent var(--principal-azul) transparent;
}
.name {
  text-align: center;
}
.name span {
  font-size: 20px !important;
  font-weight: 400;
  line-height: 22.5px;
}
.email {
  text-align: center;
  margin-top: 0.2rem;
}
.email span {
  font-size: 13px !important;
  font-weight: 300;
  line-height: 15px;
}
.img-profila-web {
  cursor: pointer;
}
.item {
  padding: 1rem 0.5rem;
}
.item .img {
  width: 20px;
}
.item .title {
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 0.15px;
}
.item:hover .title span {
  cursor: pointer;
  color: var(--principal-azul);
}
.item:hover .img {
  filter: invert(35%) sepia(15%) saturate(2082%) hue-rotate(178deg)
    brightness(99%) contrast(91%);
}
.title span {
  margin-left: 10px;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: #c8c8c8;
}
.tooltip:hover img {
  filter: invert(35%) sepia(15%) saturate(2082%) hue-rotate(178deg)
    brightness(99%) contrast(91%);
}
.cookies {
  top: 0;
  height: 10%;
}
@media only screen and (max-width: 600px) {
  .cookies {
    top: 0;
    height: 200px;
  }

  .cookiesFlexDiv {
    display: block !important;
  }

  .cookiesFlexDiv span {
    text-align: center;
  }
  .btn-plano-diferente {
    max-width: 70%;
  }

  .tooltip {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .tooltip img {
    margin-right: 0.5rem;
  }
  .v-list-item:hover .name-action {
    color: var(--principal-azul);
  }
  .v-list-item:hover img {
    filter: invert(35%) sepia(15%) saturate(2082%) hue-rotate(178deg)
      brightness(99%) contrast(91%);
    background: none;
  }
  .card-profile-mobile {
    margin-top: 1.5rem;
    width: 100%;
  }
  .card-profile-mobile-header {
    display: flex;
    align-items: center;
    max-width: 100%;
  }
  .ctrl-mock {
    margin: 1em;
  }
}

@media screen and (max-width: 930px) {
  .itens-nav {
    display: none;
  }
  .pesquisar-usuario {
    display: none;
  }
  .btn-drawer {
    display: block;
  }
}
@media screen and (max-width: 1140px) {
  .tooltip img {
    width: 24px;
    height: 24px;
  }
  .item-nav button.v-btn.tooltip {
    width: 24px;
    height: 24px;
    margin: 6px;
  }
  .item-nav {
    padding: 0;
  }
  a.router-link-active::after {
    width: 33px;
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import EventBus from "../Bus/bus";
import CardBasic from "@/views/pages/SelecaoAssinatura/components/cards/acessor/CardBasic.vue";
import CardPremium from "@/views/pages/SelecaoAssinatura/components/cards/acessor/CardPremium.vue";
import CardGold from "@/views/pages/SelecaoAssinatura/components/cards/acessor/CardGold.vue";
import ApiService from "@/services/ApiService";

export default {
  name: "AppBar",
  components: {
    CardBasic,
    CardPremium,
    CardGold
  },
  props: {
    closeModal: { type: Object },
  },
  data: () => ({
    dialogBePro: false,
    anualOuMensal: true,
    drawer: false,
    group: null,
    fav: true,
    menu: false,
    message: false,
    hints: true,
    mostrarModal: false,
    element: undefined,
    group: null,
    user: undefined,
    selectedUserName: undefined,
    api: new ApiService()
  }),
  created() {
    this.user = this.$store.getters.$userLogged;
    EventBus.$on(     "mostrarModal", this.ebModal);
    EventBus.$on(    "editarUsuario", this.ebUser);
    EventBus.$on("showModalCarteira", this.ebPortfolio);
    EventBus.$on( "abrirModalPlanos", this.ebOptions);
    EventBus.$on( 'newUserSelection', this.ebUserChange);
  },
  mounted () {
    this.ebUserChange();
  },
  beforeDestroy () {
    EventBus.$off(     "mostrarModal", this.ebModal);
    EventBus.$off(    "editarUsuario", this.ebUser);
    EventBus.$off("showModalCarteira", this.ebPortfolio);
    EventBus.$off( "abrirModalPlanos", this.ebOptions);
  },
  methods: {
    goChoosePlan() {
      this.api.ping().then(() => {
        let accountType = "";
        if (this.user.userType == 0) {
          accountType = { type: "investidor", id: this.user.userType };
        }
        if (this.user.userType == 1) {
          accountType = { type: "acessor", id: this.user.userType };
        }
        this.$store.dispatch("PUT_PROFILE_TYPE", accountType);
        this.$router.push({ name: "selecaoAssinatura" });
      });
    },
    bePro(e) {
      this.api.ping().then(() => {
        this.dialogBePro = true;
        e.currentTarget.classList.add("active-dialog");
      });
    },
    closeDialog() {
      let btn = document.querySelector("#SejaPro");
      btn.classList.remove("active-dialog");
      this.dialogBePro = false;
    },
    showModal(e, evt) {
      this.api.ping();
      this.mostrarModal = true;
      if (e === evt.currentTarget.id) {
        if (!this.element) {
          this.element = evt.currentTarget;
          evt.currentTarget.classList.add("active");
        } else {
          EventBus.$emit("zerarModalCompraAcessor", true);
          this.element.classList.remove("active");
          this.element = evt.currentTarget;
          evt.currentTarget.classList.add("active");
        }
      }
      let obj = { show: this.mostrarModal, view: e };
      this.$emit("mostrarModal", obj);
    },
    changeMensalOuAnual() {
      this.api.ping().then(() => {
        this.anualOuMensal == true
          ? (this.anualOuMensal = false)
          : (this.anualOuMensal = true);
      });
    },
    logout() {
      this.api.logoff()
        .then((resp) => {
          if (resp.success) {
            sessionStorage.removeItem("user");
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("expires");
            sessionStorage.removeItem("profileType");
            sessionStorage.removeItem("preUserRegister");
            sessionStorage.removeItem("planType");
            sessionStorage.removeItem("tokenUserSelected");
          }
          this.$router.push("/auth");
        });
    },
    goToCarteira() {
      this.api.ping().then(() => {
        if (this.$route.name != "carteira") {
          this.$router.push("/carteira");
        }
      });
    },
    goToReport() {
      this.api.ping().then(() => {
        if (this.$route.name != "report-tables") {
          this.$router.push("/report-tables");
        }
      });
    },
    goToGestaoUsuario() {
      this.api.ping().then(() => {
        if (this.$route.name != "gestaoUsuarios")
          this.$router.push("/gestao-usuario");
      });
    },
    goToLiquidez() {
      this.api.ping().then(() => {
        if (this.$route.name != "liquidez") {
          this.$router.push("/liquidez");
        }
      });
    },
    goDashboard() {
      if (this.$route.name != "visaoGeral") {
        this.$router.push("/visao-geral");
      }
    },
    goToConfig() {
      this.api.ping().then(() => {
        this.$router.push("/configuracoes");
      });
    },
    goToVisaoGeral() {
      this.api.ping().then(() => {
        this.$router.push({ name: "visaoGeral" });
      });
    },
    goToRentabilidade() {
      this.api.ping().then(() => {
        if (this.$route.name != "rentabilidade") {
       this.$router.push({ name: "rentabilidade" });

        }
      });
    },
    wrapPrtData (data) { return { show: true, view: "CadastrarCompra", dados: data }; },
    ebModal     (data) { this.$emit("mostrarModal", data); },
    ebUser      (data) { this.$emit("dadosUsuarioEditar", data); },
    ebPortfolio (data) { this.$emit("mostrarModal", this.wrapPrtData(data)); },
    ebOptions   (data) { data && (this.dialogBePro = data); },
    ebUserChange() {
      this.selectedUserName = this.$store.getters.$userSelected  ?
        this.$store.getters.$userSelected.name :
        'Não identificado';
    }
  },
  watch: {
    group() {
      this.drawer = false;
    },
    closeModal(newValue, oldValue) {
      if (this.mostrarModal != newValue.valor) {
        this.$emit("mostrarModal", newValue);
        this.element && this.element.classList.remove("active");
      } else {
        this.$emit("mostrarModal", oldValue);
      }
      this.mostrarModal = newValue.valor;
    },
    dialogBePro(value) {
      if (!value) {
        this.closeDialog();
      }
    },
    userLoggedVuex: {
      handler(nv, ov) {
        if (nv) {
          this.user = nv;
        }
      },
      deep: true,
    },
  },
  computed: {
    appearInNavbar() {
      let routeCadastro = this.$route.name != "cadastro"
      let routeAlterarSenha = this.$route.name != "alterarSenhaPerfil"
      let routeCancelPlano = this.$route.name != "cancelarPlano"
      let routeCancelConfirm = this.$route.name != "cancelarPlanoConfirmacao"
      let routeGestao = this.$route.name != "gestaoUsuarios"

      if (routeGestao && routeCancelConfirm && routeCancelPlano && routeAlterarSenha && routeCadastro) return true
      else return false
    },
    userFormated() {
      return this.$store.getters.$userLogged;
    },
    typeUser() {
      return this.$store.getters.$userType;
    },

    imagemPerfil() {
      return this.user.profileImage != null
        ? `data:image/png;base64,${this.user.profileImage}`
        : require('@/assets/empty.png');
    },
    ...mapGetters({
      userLoggedVuex: "$userLogged",
    }),
  },
};
</script>