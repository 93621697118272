import Layout from "../../Internal/Layout.vue";
import ReportTables from "./ReportTables.vue";

const ReportTablesRoutes = {
    component: Layout,
    children: [
        {
            name: "ReportTables",
            path: "/",
            component: ReportTables,
            meta: {
                requiresAuth: true,
                requiresBasic: true,
                requiresPattern: true,
                requiresSenior: true,
                requiresExpert: true,
                requiresProfessional: true,
            },
        } 

    ],
};
 
export default ReportTablesRoutes;

