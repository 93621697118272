import Layout from "../../Internal/Layout.vue";
import MyDataPage from "./MyData.vue";

const MyDataRoutes = {
  component: Layout,
  children: [
    {
      name: "data",
      path: "/",
      component: MyDataPage,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
      },
    },
  ],
};

export default MyDataRoutes;
