<template>
  <div class="rentabilidade-component">
    <div class="descricoes">
      <!-- <div class="title">
        <h1>Resultado Financeiro</h1>
        <span
          >Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis ullamco
          cillum dolor. Voluptate exercitation incididunt aliquip deserunt
          reprehenderit elit laborum.
        </span>
      </div> -->
    </div>

    <v-card flat class="grafico-wrapper">
      <div class="grafico-desempenho">
        <div class="top-grafico">
          <div class="top">
            <div class="title-grafico">
              <span>Resultado Financeiro</span>
            </div>
          </div>
        </div>

        <div class="grafico">
          <BarAndLine
            :chartData="testeObj"
            :chartDataCDI="arrCDI"
            :options="options"
            :plugins="plugins"
          />
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import ChartDataLabels from "chartjs-plugin-datalabels";
import BarAndLine from "./graficos/BarAndLine-resultadoFinanceiro.vue";
import percentageHelper from "@/Utils/PercentageHelper";
export default {
  components: {
    BarAndLine,
  },
  props: ["calculos", "dozeMeses"],
  data() {
    return {
      testeDatas: undefined,
      testeValores: undefined,
      testeObj: {},
      arrPrincipal: [],
      dadosGraficos: [],
      arrCDI: [],
      arrIPCA: [],
      arrDOLAR: [],
      arrIBOVESPA: [],
      dadosCDI: undefined,
      plugins: [ChartDataLabels],
      options: {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 15,
            right: 15,
          },
        },
        legend: {
          labels: {
            usePointStyle: true,
          },
          position: "top",
          padding: 20,
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              }
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
                drawBorder: false,
              },
              beginAtZero: true,
              ticks: {
                callback: (label, index, labels) => {
                  return this.$numberToLocale2(label);
                },
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            color: (context) => {
              return context.dataset.bg ? context.dataset.bg[0] : "#363636";
            },
            font: {
              weight: "bold",
              size: 12,
            },
            formatter: (val) => {
              return this.$numberToLocale2(val);
            },
          },
        },
        tooltips:{
          callbacks:{
            label: (item, all) =>{
              return all.datasets[item.datasetIndex].label + ': ' + this.$numberToLocale2(item.yLabel);
            }
          }
        }
      },
    };
  },
  methods: {
    $numberToLocale2(val) {
      let res = val;
      let mod = Math.abs(val);
      let unt = "";
      if (mod >= 1000 && mod < 1000000) {
        res /= 1000;
        unt = "K";
      } else if (mod >= 1000000 && mod < 1000000000) {
        res /= 1000000;
        unt = "M";
      } else if (mod > 1000000000) {
        res /= 1000000000;
        unt = "B"
      }
      return `${percentageHelper.format2(res)}${unt}`;
    },
    switchChanged(e) {
      if (e.state) {
        switch (e.valor) {
          case "CDI":
            this.addCDI();
            break;
          case "IPCA":
            this.addIPCA();
            break;
          case "DÓLAR":
            this.addDoLAR();
            break;
          case "IBOVESPA":
            this.addIBOVESPA();
            break;
        }
      } else {
        switch (e.valor) {
          case "CDI":
            this.removerCDI();
            break;
          case "IPCA":
            this.removerIPCA();
            break;
          case "DÓLAR":
            this.removerDoLAR();
            break;
          case "IBOVESPA":
            this.removerIBOVESPA();
            break;
        }
      }
    },
    addCDI() {
      let dataSetCDI = {
        type: "line",
        label: "CDI",
        data: this.arrCDI.map((d) => d.valores.valor),
        borderColor: "#E55275",
        backgroundColor: "#E55275",
        fill: false,
        bg: this.arrCDI.map((d) => d.valores.bg),
        datalabels: {
          anchor: "end",
          align: "bottom",
          display: true,
        },
        borderRadius: 10,
        barPercentage: 0.5,
        slug: "cdi",
      };
      this.dadosGraficos.unshift(dataSetCDI);
      this.initDefault();
    },
    addDoLAR() {
      let dataSetDOLAR = {
        type: "line",
        label: "DÓLAR",
        data: this.arrDOLAR.map((d) => d.valores.valor),
        borderColor: "#B6E552",
        fill: false,
        backgroundColor: "#B6E552",
        bg: this.arrDOLAR.map((d) => d.valores.bg),
        datalabels: {
          anchor: "end",
          align: "bottom",
          display: true,
        },
        borderRadius: 10,
        barPercentage: 0.5,
        slug: "dolar",
      };
      this.dadosGraficos.unshift(dataSetDOLAR);
      this.initDefault();
    },
    addIPCA() {
      let dataSetIPCA = {
        type: "line",
        label: "IPCA",
        data: this.arrIPCA.map((d) => d.valores.valor),
        bg: this.arrIPCA.map((d) => d.valores.bg),
        borderColor: "#F3D63C",
        fill: false,
        backgroundColor: "#F3D63C",
        datalabels: {
          anchor: "end",
          align: "bottom",
          display: true,
        },
        borderRadius: 10,
        barPercentage: 0.5,
        slug: "ipca",
      };
      this.dadosGraficos.unshift(dataSetIPCA);
      this.initDefault();
    },
    addIBOVESPA() {
      let dataSeIbovespa = {
        type: "line",
        label: "IBOVESPA",
        data: this.arrIBOVESPA.map((d) => d.valores.valor),
        bg: this.arrIBOVESPA.map((d) => d.valores.bg),
        borderColor: "#F37458",
        fill: false,
        backgroundColor: "#F37458",
        datalabels: {
          anchor: "end",
          align: "bottom",
          display: true,
        },
        borderRadius: 10,
        barPercentage: 0.5,
        slug: "ibovespa",
      };

      this.dadosGraficos.unshift(dataSeIbovespa);
      this.initDefault();
    },
    removerCDI() {
      for (const [index, value] of this.dadosGraficos.entries()) {
        if (value.slug == "cdi") {
          this.dadosGraficos.splice(index, 1);
        }
      }
      this.initDefault();
    },
    removerDoLAR() {
      for (const [index, value] of this.dadosGraficos.entries()) {
        if (value.slug == "dolar") {
          this.dadosGraficos.splice(index, 1);
        }
      }
      this.initDefault();
    },
    removerIPCA() {
      for (const [index, value] of this.dadosGraficos.entries()) {
        if (value.slug == "ipca") {
          this.dadosGraficos.splice(index, 1);
        }
      }
      this.initDefault();
    },
    removerIBOVESPA() {
      for (const [index, value] of this.dadosGraficos.entries()) {
        if (value.slug == "ibovespa") {
          this.dadosGraficos.splice(index, 1);
        }
      }
      this.initDefault();
    },
    initDataGrahph() {
      this.testeDatas = this.arrPrincipal.map((d) => d.data);
      this.testeValores = this.arrPrincipal.map((d) => d.valores.valor);

      this.dadosGraficos.push({
        label: "Carteira",
        data: this.testeValores,
        backgroundColor: "#579AF2",
        borderRadius: 10,
        barPercentage: 0.5,
        datalabels: {
          anchor: "end",
          align: "top",
          display: true,
        },
      });
      this.initDefault();
    },
    initDefault() {
      this.testeObj = {
        labels: this.testeDatas,
        datasets: this.dadosGraficos,
      };
    },
  },
  created() {
    if (this.dozeMeses && this.dozeMeses.length) {
      this.arrPrincipal = this.dozeMeses.map((e) => {
        return { data: e.carteira.rotulo, valores: { valor: e.calculo.valor } };
      }) || [];
      this.arrCDI = this.dozeMeses.map((e) => {
        return { data: e.carteira.rotulo, valores: { valor: e.cdi.valor } };
      }) || [];
      this.arrIPCA = this.dozeMeses.map((e) => {
        return { data: e.carteira.rotulo, valores: { valor: e.ipca.valor } };
      }) || [];
      this.arrDOLAR = this.dozeMeses.map((e) => {
        return { data: e.carteira.rotulo, valores: { valor: e.dolar.valor } };
      }) || [];
      this.arrIBOVESPA = this.dozeMeses.map((e) => {
        return { data: e.carteira.rotulo, valores: { valor: e.ibovespa.valor } };
      }) || [];
    } else {
      this.arrPrincipal = [];
      this.arrCDI = [];
      this.arrIPCA = [];
      this.arrDOLAR = [];
      this.arrIBOVESPA = [];
    }
    this.initDataGrahph();
  },
};
</script>

<style  scoped>
/* .rentabilidade-component {
} */

.descricoes {
  margin-bottom: 1.5rem;
}
.descricoes .title {
  max-width: 50%;
}
.descricoes .title h1 {
  font-size: 25px;
  line-height: 37px;
  font-weight: 500;
  letter-spacing: 0.15px;
  color: #000000;
}
.descricoes .title span {
  font-weight: 300;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.15px;
  color: #000000;
}
.grafico-wrapper {
  border-radius: 20px;
  padding-bottom: 0.5rem;
}

.grafico-wrapper .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 1rem 0 0 1rem;
}
.grafico {
  position: relative;
}
.title-grafico span {
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.15px;
  color: #000000;
}
.grafico .descricao-grafico {
  transform: rotate(-90deg);
  max-width: 150px;
  position: absolute;
  top: 50%;
  left: -35px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  letter-spacing: 0.15px;

  color: #000000;
}
.grafico-wrapper .btns-ativos {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin: 0.5rem 0;
}
.grafico-wrapper .btns-ativos > div {
  margin: 0 0.8rem;
}
.grafico-wrapper .btns-ativos > div span {
  padding: 0 0.2rem;
}
/* .grafico-wrapper .title-grafico {
} */
@media screen and (max-width: 700px) {
  .descricoes .title {
    max-width: 100% !important;
  }
}
</style>