import { render, staticRenderFns } from "./top-graph.vue?vue&type=template&id=6badfb0b&scoped=true"
import script from "./top-graph.vue?vue&type=script&lang=js"
export * from "./top-graph.vue?vue&type=script&lang=js"
import style0 from "./top-graph.vue?vue&type=style&index=0&id=6badfb0b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6badfb0b",
  null
  
)

export default component.exports