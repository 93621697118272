<template>
  <div>
    <Accordion :cor="'none'" class="accordion">
      <template v-slot:header>
        <Header :switchView="switchVisualizacao"
                :item="dessertsJurosPosCdi"
                :cor="'#35BAFC'"
                :evolucao="evolucao.estrategia.jurosPosCdi"
                :saldototal="evolucao.total.saldo"
                :bound="bound" />
        <!-- <LinhaJurosPos v-if="!switchVisualizacao" />
        <LinhaJurosPosEvolucao v-if="switchVisualizacao" /> -->
      </template>

      <template v-slot:body>
        <div
          class="text-center pa-4"
          v-if="dessertsJurosPosCdi.items.length == 0"
        >
          Nenhum investimento encontrado para esta estratégia
        </div>
        <div v-if="!switchVisualizacao">
          <div v-if="telaMobile">
            <card1
              v-for="ativo in dessertsJurosPosCdi.items"
              :key="ativo.title"
              :dataRecieved="ativo"
              v-show="showItem(ativo)"
              @cardClicked="goCarteira(ativo)"
              :bound="bound" />
          </div>
          <div v-if="!telaMobile">
            <div
              class="row-expansion"
              v-for="ativo in dessertsJurosPosCdi.items"
              :key="ativo.title"
              @click="goCarteira(ativo)"
            >
              <Body :switchView="switchVisualizacao"
                    :ativo="ativo"
                    v-show="showItem(ativo)"
                    :bound="bound" />
            </div>
          </div>
        </div>
        <div v-else>
          <div v-if="telaMobile">
            <card2
              v-for="ativo in dessertsJurosPosCdi.items"
              :key="ativo.title"
              :dataRecieved="ativo"
              v-show="showItem(ativo)"
              @cardClicked="goCarteira(ativo)"
              :bound="bound" />
          </div>
          <div v-if="!telaMobile">
            <v-row
              class="row-expansion switchedVisualizacao"
              v-for="ativo in dessertsJurosPosCdi.items"
              :key="ativo.title"
              v-show="showItem(ativo)"
              @click="goCarteira(ativo)"
            >
              <v-col sm="12" md="2" xs="12" class="coluna">
                <div class="descricao">
                  <span> {{ ativo.title }}</span>
                </div>
              </v-col>
              <v-col sm="12" md="1" xs="12" class="coluna" :title="ativo.value">
                <div class="descricao">
                  <span> {{ currencyHelper.formatValue(ativo.value) }} </span>
                </div>
              </v-col>

              <v-col sm="12" md="9" xs="12" class="coluna">
                <div class="colunas">
                  <v-row class="row-expansion-2">
                    <v-col sm="12" md="1" xs="12" class="coluna-2" :title="ativo.mesAtual * 100">
                      <div class="">
                        <span>{{ bound ? "---" : percentageHelper.formatPercentage(ativo.mesAtual) }}</span>
                      </div>
                    </v-col>

                    <v-col sm="12" md="1" xs="12" class="coluna-2" :title="ativo.percent1 * 100">
                      <div>
                        <span>{{ bound ? "---" : percentageHelper.formatPercentage(ativo.percent1) }}</span>
                      </div>
                    </v-col>

                    <v-col sm="12" md="2" xs="12" class="coluna-2" :title="ativo.valor1">
                      <div>
                        <span>{{ bound ? "---" : currencyHelper.formatValue(ativo.valor1) }}</span>
                      </div>
                    </v-col>

                    <v-col sm="12" md="1" xs="12" class="coluna-2" :title="ativo.dozeMeses * 100">
                      <div class="">
                        <span>{{ bound ? "---" : percentageHelper.formatPercentage(ativo.dozeMeses) }}</span>
                      </div>
                    </v-col>

                    <v-col sm="12" md="1" xs="12" class="coluna-2" :title="ativo.percent2 * 100">
                      <div>
                        <span>{{ bound ? "---" : percentageHelper.formatPercentage(ativo.percent2) }}</span>
                      </div>
                    </v-col>

                    <v-col sm="12" md="2" xs="12" class="coluna-2" :title="ativo.valor2">
                      <div>
                        <span>{{ bound ? "---" : currencyHelper.formatValue(ativo.valor2) }}</span>
                      </div>
                    </v-col>

                    <v-col sm="12" md="1" xs="12" class="coluna-2" :title="ativo.desdeInicio * 100">
                      <div class="">
                        <span>{{ percentageHelper.formatPercentage(ativo.desdeInicio) }}</span>
                      </div>
                    </v-col>

                    <v-col sm="12" md="1" xs="12" class="coluna-2" :title="ativo.percent3 * 100">
                      <div>
                        <span>{{ percentageHelper.formatPercentage(ativo.percent3) }}</span>
                      </div>
                    </v-col>

                    <v-col sm="12" md="2" xs="12" class="coluna-2" :title="ativo.valor3">
                      <div>
                        <span>{{ currencyHelper.formatValue(ativo.valor3) }}</span>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </template>
    </Accordion>

    <Accordion :cor="'none'" class="accordion">
      <template v-slot:header>
        <Header
          :switchView="switchVisualizacao"
          :item="dessertsJurosPosInflacao"
          :cor="'#2679ED'"
          :evolucao="evolucao.estrategia.jurosPosInflacao"
          :saldototal="evolucao.total.saldo"
          :bound="bound" />
        <!-- <LinhaPosInflacao v-if="!switchVisualizacao" />
        <LinhaPosInflacaoEvolucao v-if="switchVisualizacao" /> -->
      </template>

      <template v-slot:body>
        <div
          class="text-center pa-4"
          v-if="dessertsJurosPosInflacao.items.length == 0"
        >
          Nenhum investimento encontrado para esta estratégia
        </div>
        <div v-if="!switchVisualizacao">
          <div v-if="telaMobile">
            <card1
              v-for="ativo in dessertsJurosPosInflacao.items"
              :key="ativo.title"
              :dataRecieved="ativo"
              v-show="showItem(ativo)"
              @cardClicked="goCarteira(ativo)"
              :bound="bound" />
          </div>
          <div v-if="!telaMobile">
            <div
              class="row-expansion"
              v-for="ativo in dessertsJurosPosInflacao.items"
              :key="ativo.title"
              @click="goCarteira(ativo)"
            >
              <Body :switchView="switchVisualizacao"
                    :ativo="ativo"
                    v-show="showItem(ativo)"
                    :bound="bound" />
            </div>
          </div>
        </div>

        <div v-else>
          <div v-if="telaMobile">
            <card2
              v-for="ativo in dessertsJurosPosInflacao.items"
              :key="ativo.title"
              :dataRecieved="ativo"
              v-show="showItem(ativo)"
              @cardClicked="goCarteira(ativo)"
              :bound="bound" />
          </div>
          <div v-if="!telaMobile">
            <v-row
              class="row-expansion switchedVisualizacao"
              v-for="ativo in dessertsJurosPosInflacao.items"
              :key="ativo.title"
              v-show="showItem(ativo)"
              @click="goCarteira(ativo)"
            >
              <v-col sm="12" md="2" xs="12" class="coluna">
                <div class="descricao">
                  <span>{{ ativo.title }}</span>
                </div>
              </v-col>
              <v-col sm="12" md="1" xs="12" class="coluna" :title="ativo.value">
                <div class="descricao">
                  <span> {{ currencyHelper.formatValue(ativo.value) }} </span>
                </div>
              </v-col>

              <v-col sm="12" md="9" xs="12" class="coluna">
                <div class="colunas">
                  <v-row class="row-expansion-2">
                    <v-col sm="12" md="1" xs="12" class="coluna-2" :title="ativo.mesAtual * 100">
                      <div class="">
                        <span>{{ bound ? "---" : percentageHelper.formatPercentage(ativo.mesAtual) }}</span>
                      </div>
                    </v-col>

                    <v-col sm="12" md="1" xs="12" class="coluna-2" :title="ativo.percent1 * 100">
                      <div>
                        <span>{{ bound ? "---" : percentageHelper.formatPercentage(ativo.percent1) }}</span>
                      </div>
                    </v-col>

                    <v-col sm="12" md="2" xs="12" class="coluna-2" :title="ativo.valor1">
                      <div>
                        <span>{{ bound ? "---" : currencyHelper.formatValue(ativo.valor1) }}</span>
                      </div>
                    </v-col>

                    <v-col sm="12" md="1" xs="12" class="coluna-2" :title="ativo.dozeMeses * 100">
                      <div class="">
                        <span>{{ bound ? "---" : percentageHelper.formatPercentage(ativo.dozeMeses) }}</span>
                      </div>
                    </v-col>

                    <v-col sm="12" md="1" xs="12" class="coluna-2" :title="ativo.percent2 * 100">
                      <div>
                        <span>{{ bound ? "---" : percentageHelper.formatPercentage(ativo.percent2) }}</span>
                      </div>
                    </v-col>

                    <v-col sm="12" md="2" xs="12" class="coluna-2" :title="ativo.valor2">
                      <div>
                        <span>{{ bound ? "---" : currencyHelper.formatValue(ativo.valor2) }}</span>
                      </div>
                    </v-col>

                    <v-col sm="12" md="1" xs="12" class="coluna-2" :title="ativo.desdeInicio * 100">
                      <div class="">
                        <span>{{ percentageHelper.formatPercentage(ativo.desdeInicio) }}</span>
                      </div>
                    </v-col>

                    <v-col sm="12" md="1" xs="12" class="coluna-2" :title="ativo.percent3 * 100">
                      <div>
                        <span>{{ percentageHelper.formatPercentage(ativo.percent3) }}</span>
                      </div>
                    </v-col>

                    <v-col sm="12" md="2" xs="12" class="coluna-2" :title="ativo.valor3">
                      <div>
                        <span>{{ currencyHelper.formatValue(ativo.valor3) }}</span>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </template>
    </Accordion>

    <Accordion :cor="'none'" class="accordion">
      <template v-slot:header>
        <Header :switchView="switchVisualizacao"
                :item="dessertsJurosPre"
                :cor="'#29BD1A'"
                :evolucao="evolucao.estrategia.jurosPre"
                :saldototal="evolucao.total.saldo"
                :bound="bound" />
        <!-- <LinhaPreFixo v-if="!switchVisualizacao" />
        <LinhaPreFixoEvolucao v-if="switchVisualizacao" /> -->
      </template>

      <template v-slot:body>
        <div class="text-center pa-4" v-if="dessertsJurosPre.items.length == 0">
          Nenhum investimento encontrado para esta estratégia
        </div>
        <div v-if="!switchVisualizacao">
          <div v-if="telaMobile">
            <card1
              v-for="ativo in dessertsJurosPre.items"
              :key="ativo.title"
              :dataRecieved="ativo"
              v-show="showItem(ativo)"
              @cardClicked="goCarteira(ativo)"
              :bound="bound" />
          </div>
          <div v-if="!telaMobile">
            <div
              class="row-expansion"
              v-for="ativo in dessertsJurosPre.items"
              :key="ativo.title"
              @click="goCarteira(ativo)"
            >
              <Body :switchView="switchVisualizacao"
                    :ativo="ativo"
                    v-show="showItem(ativo)"
                    :bound="bound" />
            </div>
          </div>
        </div>

        <div v-else>
          <div v-if="telaMobile">
            <card2
              v-for="ativo in dessertsJurosPre.items"
              :key="ativo.title"
              :dataRecieved="ativo"
              v-show="showItem(ativo)"
              @cardClicked="goCarteira(ativo)"
              :monthLabel="monthLabel"
              :bound="bound" />
          </div>
          <div v-if="!telaMobile">
            <v-row
              class="row-expansion switchedVisualizacao"
              v-for="ativo in dessertsJurosPre.items"
              :key="ativo.title"
              v-show="showItem(ativo)"
              @click="goCarteira(ativo)"
            >
              <v-col sm="12" md="2" xs="12" class="coluna">
                <div class="descricao">
                  <span> {{ ativo.title }}</span>
                </div>
              </v-col>
              <v-col sm="12" md="1" xs="12" class="coluna" :title="ativo.value">
                <div class="descricao">
                  <span> {{ currencyHelper.formatValue(ativo.value) }} </span>
                </div>
              </v-col>

              <v-col sm="12" md="9" xs="12" class="coluna">
                <div class="colunas">
                  <v-row class="row-expansion-2">
                    <v-col sm="12" md="1" xs="12" class="coluna-2" :title="ativo.mesAtual * 100">
                      <div class="">
                        <span>{{ bound ? "---" : percentageHelper.formatPercentage(ativo.mesAtual) }}</span>
                      </div>
                    </v-col>

                    <v-col sm="12" md="1" xs="12" class="coluna-2" :title="ativo.percent1 * 100">
                      <div>
                        <span>{{ bound ? "---" : percentageHelper.formatPercentage(ativo.percent1) }}</span>
                      </div>
                    </v-col>

                    <v-col sm="12" md="2" xs="12" class="coluna-2" :title="ativo.valor1">
                      <div>
                        <span>{{ bound ? "---" : currencyHelper.formatValue(ativo.valor1) }}</span>
                      </div>
                    </v-col>

                    <v-col sm="12" md="1" xs="12" class="coluna-2" :title="ativo.dozeMeses * 100">
                      <div class="">
                        <span>{{ bound ? "---" : percentageHelper.formatPercentage(ativo.dozeMeses) }}</span>
                      </div>
                    </v-col>

                    <v-col sm="12" md="1" xs="12" class="coluna-2" :title="ativo.percent2 * 100">
                      <div>
                        <span>{{ bound ? "---" : percentageHelper.formatPercentage(ativo.percent2) }}</span>
                      </div>
                    </v-col>

                    <v-col sm="12" md="2" xs="12" class="coluna-2" :title="ativo.valor2">
                      <div>
                        <span>{{ bound ? "---" : currencyHelper.formatValue(ativo.valor2) }}</span>
                      </div>
                    </v-col>

                    <v-col sm="12" md="1" xs="12" class="coluna-2" :title="ativo.desdeInicio * 100">
                      <div class="">
                        <span>{{ percentageHelper.formatPercentage(ativo.desdeInicio) }}</span>
                      </div>
                    </v-col>

                    <v-col sm="12" md="1" xs="12" class="coluna-2" :title="ativo.percent3 * 100">
                      <div>
                        <span>{{ percentageHelper.formatPercentage(ativo.percent3) }}</span>
                      </div>
                    </v-col>

                    <v-col sm="12" md="2" xs="12" class="coluna-2" :title="ativo.valor3">
                      <div>
                        <span>{{ currencyHelper.formatValue(ativo.valor3) }}</span>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </template>
    </Accordion>

    <Accordion :cor="'none'" class="accordion">
      <template v-slot:header>
        <Header :switchView="switchVisualizacao"
                :item="dessertsLivre"
                :cor="'#FAA90F'"
                :evolucao="evolucao.estrategia.livre"
                :saldototal="evolucao.total.saldo"
                :bound="bound" />
        <!-- <LinhaRendaVariavel v-if="!switchVisualizacao" />
        <LinhaRendaVariavelEvolucao v-if="switchVisualizacao" /> -->
      </template>

      <template v-slot:body>
        <div class="text-center pa-4" v-if="dessertsLivre.items.length == 0">
          Nenhum investimento encontrado para esta estratégia
        </div>
        <div v-if="!switchVisualizacao">
          <div v-if="telaMobile">
            <card1
              v-for="ativo in dessertsLivre.items"
              :key="ativo.title"
              :dataRecieved="ativo"
              v-show="showItem(ativo)"
              @cardClicked="goCarteira(ativo)"
              :bound="bound" />
          </div>
          <div v-if="!telaMobile">
            <div
              class="row-expansion"
              v-for="ativo in dessertsLivre.items"
              :key="ativo.title"
              @click="goCarteira(ativo)"
            >
              <Body :switchView="switchVisualizacao"
                    :ativo="ativo"
                    v-show="showItem(ativo)"
                    :bound="bound" />
            </div>
          </div>
        </div>
        <div v-else>
          <div v-if="telaMobile">
            <card2
              v-for="ativo in dessertsLivre.items"
              :key="ativo.title"
              :dataRecieved="ativo"
              v-show="showItem(ativo)"
              @cardClicked="goCarteira(ativo)"
              :bound="bound" />
          </div>
          <div v-if="!telaMobile">
            <v-row
              class="row-expansion switchedVisualizacao"
              v-for="ativo in dessertsLivre.items"
              :key="ativo.title"
              v-show="showItem(ativo)"
              @click="goCarteira(ativo)"
            >
              <v-col sm="12" md="2" xs="12" class="coluna">
                <div class="descricao">
                  <span>{{ ativo.title }}</span>
                </div>
              </v-col>
              <v-col sm="12" md="1" xs="12" class="coluna" :title="ativo.value">
                <div class="descricao">
                  <span> {{ currencyHelper.formatValue(ativo.value) }} </span>
                </div>
              </v-col>

              <v-col sm="12" md="9" xs="12" class="coluna">
                <div class="colunas">
                  <v-row class="row-expansion-2">
                    <v-col sm="12" md="1" xs="12" class="coluna-2" :title="ativo.mesAtual * 100">
                      <div class="">
                        <span>{{ bound ? "---" : percentageHelper.formatPercentage(ativo.mesAtual) }}</span>
                      </div>
                    </v-col>

                    <v-col sm="12" md="1" xs="12" class="coluna-2" :title="ativo.percent1 * 100">
                      <div>
                        <span>{{ bound ? "---" : percentageHelper.formatPercentage(ativo.percent1) }}</span>
                      </div>
                    </v-col>

                    <v-col sm="12" md="2" xs="12" class="coluna-2" :title="ativo.valor1">
                      <div>
                        <span>{{ bound ? "---" : currencyHelper.formatValue(ativo.valor1) }}</span>
                      </div>
                    </v-col>

                    <v-col sm="12" md="1" xs="12" class="coluna-2" :title="ativo.dozeMeses * 100">
                      <div class="">
                        <span>{{ bound ? "---" : percentageHelper.formatPercentage(ativo.dozeMeses) }}</span>
                      </div>
                    </v-col>

                    <v-col sm="12" md="1" xs="12" class="coluna-2" :title="ativo.percent2 * 100">
                      <div>
                        <span>{{ bound ? "---" : percentageHelper.formatPercentage(ativo.percent2) }}</span>
                      </div>
                    </v-col>

                    <v-col sm="12" md="2" xs="12" class="coluna-2" :title="ativo.valor2">
                      <div>
                        <span>{{ bound ? "---" : currencyHelper.formatValue(ativo.valor2) }}</span>
                      </div>
                    </v-col>

                    <v-col sm="12" md="1" xs="12" class="coluna-2" :title="ativo.desdeInicio * 100">
                      <div class="">
                        <span>{{ percentageHelper.formatPercentage(ativo.desdeInicio) }}</span>
                      </div>
                    </v-col>

                    <v-col sm="12" md="1" xs="12" class="coluna-2" :title="ativo.percent3 * 100">
                      <div>
                        <span>{{ percentageHelper.formatPercentage(ativo.percent3) }}</span>
                      </div>
                    </v-col>

                    <v-col sm="12" md="2" xs="12" class="coluna-2" :title="ativo.valor3">
                      <div>
                        <span>{{ currencyHelper.formatValue(ativo.valor3) }}</span>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </template>
    </Accordion>

    <Accordion :cor="'none'" class="accordion">
      <template v-slot:header>
        <Header
          :switchView="switchVisualizacao"
          :item="dessertsRendaVariavel"
          :cor="'#FA5807'"
          :evolucao="evolucao.estrategia.rendaVariavel"
          :saldototal="evolucao.total.saldo"
          :bound="bound" />
        <!-- <LinhaCambio v-if="!switchVisualizacao" />
        <LinhaCambioEvolucao v-if="switchVisualizacao" /> -->
      </template>

      <template v-slot:body>
        <div
          class="text-center pa-4"
          v-if="dessertsRendaVariavel.items.length == 0"
        >
          Nenhum investimento encontrado para esta estratégia
        </div>
        <div v-if="!switchVisualizacao">
          <div v-if="telaMobile">
            <card1
              v-for="ativo in dessertsRendaVariavel.items"
              :key="ativo.title"
              :dataRecieved="ativo"
              v-show="showItem(ativo)"
              @cardClicked="goCarteira(ativo)"
              :bound="bound" />
          </div>
          <div v-if="!telaMobile">
            <div
              class="row-expansion"
              v-for="ativo in dessertsRendaVariavel.items"
              :key="ativo.title"
              @click="goCarteira(ativo)"
            >
              <Body :switchView="switchVisualizacao"
                    :ativo="ativo"
                    v-show="showItem(ativo)"
                    :bound="bound" />
            </div>
          </div>
        </div>
        <div v-else>
          <div v-if="telaMobile">
            <card2
              v-for="ativo in dessertsRendaVariavel.items"
              :key="ativo.title"
              :dataRecieved="ativo"
              v-show="showItem(ativo)"
              @cardClicked="goCarteira(ativo)"
              :bound="bound" />
          </div>
          <div v-if="!telaMobile">
            <v-row
              class="row-expansion switchedVisualizacao"
              v-for="ativo in dessertsRendaVariavel.items"
              :key="ativo.title"
              v-show="showItem(ativo)"
              @click="goCarteira(ativo)"
            >
              <v-col sm="12" md="2" xs="12" class="coluna">
                <div class="descricao">
                  <span>{{ ativo.title }}</span>
                </div>
              </v-col>
              <v-col sm="12" md="1" xs="12" class="coluna" :title="ativo.value">
                <div class="descricao">
                  <span> {{ currencyHelper.formatValue(ativo.value) }} </span>
                </div>
              </v-col>

              <v-col sm="12" md="9" xs="12" class="coluna">
                <div class="colunas">
                  <v-row class="row-expansion-2">
                    <v-col sm="12" md="1" xs="12" class="coluna-2" :title="ativo.mesAtual * 100">
                      <div class="">
                        <span>{{ bound ? "---" : percentageHelper.formatPercentage(ativo.mesAtual) }}</span>
                      </div>
                    </v-col>

                    <v-col sm="12" md="1" xs="12" class="coluna-2" :title="ativo.percent1 * 100">
                      <div>
                        <span>{{ bound ? "---" : percentageHelper.formatPercentage(ativo.percent1) }}</span>
                      </div>
                    </v-col>

                    <v-col sm="12" md="2" xs="12" class="coluna-2" :title="ativo.valor1">
                      <div>
                        <span>{{ bound ? "---" : currencyHelper.formatValue(ativo.valor1) }}</span>
                      </div>
                    </v-col>

                    <v-col sm="12" md="1" xs="12" class="coluna-2" :title="ativo.dozeMeses * 100">
                      <div class="">
                        <span>{{ bound ? "---" : percentageHelper.formatPercentage(ativo.dozeMeses) }}</span>
                      </div>
                    </v-col>

                    <v-col sm="12" md="1" xs="12" class="coluna-2" :title="ativo.percent2 * 100">
                      <div>
                        <span>{{ bound ? "---" : percentageHelper.formatPercentage(ativo.percent2) }}</span>
                      </div>
                    </v-col>

                    <v-col sm="12" md="2" xs="12" class="coluna-2" :title="ativo.valor2">
                      <div>
                        <span>{{ bound ? "---" : currencyHelper.formatValue(ativo.valor2) }}</span>
                      </div>
                    </v-col>

                    <v-col sm="12" md="1" xs="12" class="coluna-2" :title="ativo.desdeInicio * 100">
                      <div class="">
                        <span>{{ percentageHelper.formatPercentage(ativo.desdeInicio) }}</span>
                      </div>
                    </v-col>

                    <v-col sm="12" md="1" xs="12" class="coluna-2" :title="ativo.percent3 * 100">
                      <div>
                        <span>{{ percentageHelper.formatPercentage(ativo.percent3) }}</span>
                      </div>
                    </v-col>

                    <v-col sm="12" md="2" xs="12" class="coluna-2" :title="ativo.valor3">
                      <div>
                        <span>{{  currencyHelper.formatValue(ativo.valor3) }}</span>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </template>
    </Accordion>

    <Accordion :cor="'none'" class="accordion">
      <template v-slot:header>
        <Header :switchView="switchVisualizacao"
                :item="dessertsCambio"
                :cor="'#C520FF'"
                :evolucao="evolucao.estrategia.cambio"
                :saldototal="evolucao.total.saldo"
                :bound="bound" />
        <!-- <LinhaLivres v-if="!switchVisualizacao" />
        <LinhaLivresEvolucao v-if="switchVisualizacao" /> -->
      </template>

      <template v-slot:body>
        <div class="text-center pa-4" v-if="dessertsCambio.items.length == 0">
          Nenhum investimento encontrado para esta estratégia
        </div>
        <div v-if="!switchVisualizacao">
          <div v-if="telaMobile">
            <card1
              v-for="ativo in dessertsCambio.items"
              :key="ativo.title"
              :dataRecieved="ativo"
              v-show="showItem(ativo)"
              @cardClicked="goCarteira(ativo)"
              :bound="bound" />
          </div>
          <div v-if="!telaMobile">
            <div
              class="row-expansion"
              v-for="ativo in dessertsCambio.items"
              :key="ativo.title"
              @click="goCarteira(ativo)"
            >
              <Body :switchView="switchVisualizacao"
                    :ativo="ativo"
                    v-show="showItem(ativo)"
                    :bound="bound" />
            </div>
          </div>
        </div>
        <div v-else>
          <div v-if="telaMobile">
            <card2
              v-for="ativo in dessertsCambio.items"
              :key="ativo.title"
              :dataRecieved="ativo"
              v-show="showItem(ativo)"
              @cardClicked="goCarteira(ativo)"
              :bound="bound" />
          </div>
          <div v-if="!telaMobile">
            <v-row
              class="row-expansion switchedVisualizacao"
              v-for="ativo in dessertsCambio.items"
              :key="ativo.title"
              v-show="showItem(ativo)"
              @click="goCarteira(ativo)"
            >
              <v-col sm="12" md="2" xs="12" class="coluna">
                <div class="descricao">
                  <span>{{ ativo.title }}</span>
                </div>
              </v-col>
              <v-col sm="12" md="1" xs="12" class="coluna" :title="ativo.value">
                <div class="descricao">
                  <span> {{ currencyHelper.formatValue(ativo.value) }} </span>
                </div>
              </v-col>

              <v-col sm="12" md="9" xs="12" class="coluna">
                <div class="colunas">
                  <v-row class="row-expansion-2">
                    <v-col sm="12" md="1" xs="12" class="coluna-2" :title="ativo.mesAtual * 100">
                      <div class="">
                        <span>{{ bound ? "---" : percentageHelper.formatPercentage(ativo.mesAtual) }}</span>
                      </div>
                    </v-col>

                    <v-col sm="12" md="1" xs="12" class="coluna-2" :title="ativo.percent1 * 100">
                      <div>
                        <span>{{ bound ? "---" : percentageHelper.formatPercentage(ativo.percent1) }}</span>
                      </div>
                    </v-col>

                    <v-col sm="12" md="2" xs="12" class="coluna-2" :title="ativo.valor1">
                      <div>
                        <span>{{ bound ? "---" : currencyHelper.formatValue(ativo.valor1) }}</span>
                      </div>
                    </v-col>

                    <v-col sm="12" md="1" xs="12" class="coluna-2" :title="ativo.dozeMeses * 100">
                      <div class="">
                        <span>{{ bound ? "---" : percentageHelper.formatPercentage(ativo.dozeMeses) }}</span>
                      </div>
                    </v-col>

                    <v-col sm="12" md="1" xs="12" class="coluna-2" :title="ativo.percent2 * 100">
                      <div>
                        <span>{{ bound ? "---" : percentageHelper.formatPercentage(ativo.percent2) }}</span>
                      </div>
                    </v-col>

                    <v-col sm="12" md="2" xs="12" class="coluna-2" :title="ativo.valor2">
                      <div>
                        <span>{{ bound ? "---" : currencyHelper.formatValue(ativo.valor2) }}</span>
                      </div>
                    </v-col>

                    <v-col sm="12" md="1" xs="12" class="coluna-2" :title="ativo.desdeInicio * 100">
                      <div class="">
                        <span>{{ percentageHelper.formatPercentage(ativo.desdeInicio) }}</span>
                      </div>
                    </v-col>

                    <v-col sm="12" md="1" xs="12" class="coluna-2" :title="ativo.percent3 * 100">
                      <div>
                        <span>{{ percentageHelper.formatPercentage(ativo.percent3) }}</span>
                      </div>
                    </v-col>

                    <v-col sm="12" md="2" xs="12" class="coluna-2" :title="ativo.valor3">
                      <div>
                        <span>{{ currencyHelper.formatValue(ativo.valor3) }}</span>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </template>
    </Accordion>
  </div>
</template>

<script>
import Accordion from "../components/Accordion.vue";
import card1 from "../components/cards/card1.vue";
import card2 from "../components/cards/card2.vue";
import Header from "../components/Header.vue";
import Body from "../components/Body.vue";
import currencyHelper from "../../../../Utils/CurrencyHelper";
import percentageHelper from "../../../../Utils/PercentageHelper";
import dateHelper from "../../../../Utils/DateHelper";

// import LinhaPreFixo from "../components/porEstrategia/pre-fixo/Linha-acc.vue";
// import LinhaPreFixoEvolucao from "../components/porEstrategia/pre-fixo/Linha2-acc.vue";
// import LinhaJurosPos from "../components/porEstrategia/juros-pos/Linha-acc.vue";
// import LinhaJurosPosEvolucao from "../components/porEstrategia/juros-pos/Linha2-acc.vue";
// import LinhaPosInflacao from "../components/porEstrategia/pos-inflacao/Linha-acc.vue";
// import LinhaPosInflacaoEvolucao from "../components/porEstrategia/pos-inflacao/Linha2-acc.vue";
// import LinhaRendaVariavel from "../components/porEstrategia/renda-variavel/Linha-acc.vue";
// import LinhaRendaVariavelEvolucao from "../components/porEstrategia/renda-variavel/Linha2-acc.vue";
// import LinhaCambio from "../components/porEstrategia/cambio/Linha-acc.vue";
// import LinhaCambioEvolucao from "../components/porEstrategia/cambio/Linha2-acc.vue";
// import LinhaLivres from "../components/porEstrategia/livres/Linha-acc.vue";
// import LinhaLivresEvolucao from "../components/porEstrategia/livres/Linha2-acc.vue";

export default {
  components: {
    Accordion,
    card1,
    card2,
    Header,
    Body,
    // LinhaPreFixo,
    // LinhaPreFixoEvolucao,
    // LinhaJurosPos,
    // LinhaJurosPosEvolucao,
    // LinhaPosInflacao,
    // LinhaPosInflacaoEvolucao,
    // LinhaRendaVariavel,
    // LinhaRendaVariavelEvolucao,
    // LinhaCambio,
    // LinhaCambioEvolucao,
    // LinhaLivres,
    // LinhaLivresEvolucao,
  },
  props: {
    switchVisualizacao: Boolean,
    telaMobile: Boolean,
    dessertsJurosPre: Object,
    dessertsJurosPosCdi: Object,
    dessertsJurosPosInflacao: Object,
    dessertsLivre: Object,
    dessertsRendaVariavel: Object,
    dessertsCambio: Object,
    ativo: { type: Object },
    switchView: { type: Boolean },
    evolucao: Object,
    monthLabel: String,
    showAll: Boolean,
    bound: Boolean
  },

  data() {
    return {
      currencyHelper,
      percentageHelper,
      dateHelper,
    };
  },
  methods: {
    goCarteira(e) {
      let obj = {
        item: e,
        show: true,
      };
      this.$router.push({ name: "carteira", params: obj });
    },
    showItem (item) {
      return this.showAll || Math.abs(item.value) > 1E-3;
    }
  }
};
</script>

<style scoped>
.accordions {
  padding: 0 !important;
  margin: 0rem 0 0 0;
  width: 100% !important;
}
.linha {
  margin-top: 1rem;
}
.linha .coluna {
  margin: 0 !important;
  padding: 0.5rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.linha .coluna > div {
  background: white;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  padding: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.linha .coluna > div span {
  font-size: 0.9rem;
}
.linha-2 {
  margin-top: 1rem;
}
.linha-2 .coluna {
  margin: 0 !important;
  padding: 0.0rem !important;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.linha-2 .coluna > div {
  background: white;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  padding: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.linha-2 .coluna > div span {
  font-size: 0.9rem;
}

/* FIM DO TESTE DO ROW */

.itens-1 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.itens-1 .select {
  min-width: 40vw;
  margin: 1.5rem 2rem 0 0;
}
.itens-1 .btn-evolucao {
  display: flex;
  align-items: center;
  border: 1px solid var(--principal-azul);
  justify-content: center;
  padding: 0.5rem 1.5rem;
  border-radius: 25px;
}
.descricao-evolucao {
  margin: 0 1.5rem 0 0;
}
.descricao-evolucao span {
  font-weight: bold;
  font-size: 18px;
  line-height: 19px;
  letter-spacing: 0.15px;
  color: var(--principal-azul);
}
.btns-ativos {
  display: flex;
  flex-wrap: wrap;
}
.btns-ativos > div {
  margin: 0 0.3rem;
}
.btns-ativos > div span {
  padding: 0 0.8rem;
}
/* CARDS DO TOPO */

.cards > div {
  padding: 0.5rem 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 10px;
}
.carteira-produto {
  grid-area: carteira-produto;
}
.participacao {
  grid-area: participacao;
}
.emissor-gestor {
  grid-area: emissor-gestor;
}
.resultado {
  grid-area: resultado;
  display: flex;
  flex-direction: column;
  padding: 0.5rem !important;
}
.custodiante {
  grid-area: custodiante;
}
.liqui-em-dias {
  grid-area: liqui-em-dias;
}
.vencimento {
  grid-area: vencimento;
}
.saldo-bruto {
  grid-area: saldo-bruto;
}
.resultado .items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.resultado .title-result {
  border-bottom: 3px solid #c6c6c6;
  width: 100%;
  padding: 0.2rem 0;
}
.items .mes {
  color: #a9d08e;
}
.items .meses {
  color: #f3a03c;
}
.items .inicio {
  color: var(--principal-azul);
}
.item span {
  font-weight: bold;
}

/* FIM DOS CARDS DO TOPO */

/* CONTEUDOS DO BODY DOS ACCORDIONS */

.row-expansion {
  margin-top: 1rem;
  padding: 0 !important;
  width: 100% !important;
  margin: 0 auto !important;
}
.row-expansion .col {
  padding: 0 0.6rem !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}

.row-expansion .col > div {
  background: white;
  width: 100% !important;
  height: 100%;
  padding: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 10vh;
  border-bottom: 1px solid rgba(73, 73, 73, 0.1);
}
.row-expansion .col > div span {
  font-size: 0.79rem;
}
.row-expansion .col:first-child {
  justify-content: flex-start !important;
  padding-left: 0.2rem !important;
}
.row-expansion .col:last-child {
  padding-right: 0 !important;
}
.row-expansion:last-child .col > div {
  border-radius: 0 0 20px 20px;
}

.row-expansion:last-child .coluna .colunas .row-expansion-2 .coluna-2 {
  overflow: hidden;
}
.row-expansion:last-child .coluna .colunas .row-expansion-2 .coluna-2 > div {
  border: none !important;
  border-radius: none !important;
}

/* FIM DOS CONTEUDOS DO BODY DOS ACCORDIONS */

/** COMPONETNS ED ACCORDIONS DINACIMOS*/
.accordion {
  margin-bottom: 0.2rem;
  margin-right: 2rem;
}
/**FIM DOS COMPONENTES DE ACCORDIOS DINAMICOS */
/*  ********** PAINEIS ************** */
.header-cdb-rdb {
  background: #d0cece;
  font-weight: bold !important;
}
.header-cri-cra {
  background: #e2efda;
  font-weight: bold !important;
}
.header-debentures {
  background: #FFC000;
  font-weight: bold !important;
}
.header-lci-lca {
  background: #fff2cc;
  font-weight: bold !important;
}
.header-titulos-publicos {
  background: #fce4d6;
  font-weight: bold !important;
}
.header-acoes {
  background: #8ea9db;
  font-weight: bold !important;
}
.header-fundos-imobiliarios {
  background: #ffd966;
  font-weight: bold !important;
}
.header-fundos-investimento {
  background: #a9d08e;
  font-weight: bold !important;
}
.header-panel {
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  grid-template-rows: 1fr;
  grid-template-areas: "carteira carteira porcentagem porcentagem porcentagem porcentagem porcentagem porcentagem valor";
}
.header-panel .titles {
  grid-area: carteira;
}
.header-panel .percent {
  grid-area: porcentagem;
}
.header-panel .valor {
  grid-area: valor;
}
/*.header-cdb-rdb {
}*/
.header-cdb-rdb .linha {
  margin-top: -0.1%;
  max-height: 100% !important;
}
.header-cdb-rdb .linha .coluna-header {
  text-align: center;
}

/*  ********** FIM PAINEIS ************** */
.cards-top .result-line {
  position: relative;
}
.cards-top {
  position: relative;
}
@media screen and (max-width: 950px) {
  .btns-ativos > div {
    flex: 1 1 150px;
  }
  .elements .cards-top,
  .cards-top-2 {
    display: none;
  }
  .btn-evolucao {
    margin-bottom: 0.5rem;
  }

  .cards-top {
    display: none;
  }
  /** fim barra de carteira */

  /**itens dos accordions */
  .row-expansion .col {
    padding: 0 !important;
  }
  /*.row-expansion .col > div {
  }*/
  /**fim dos itens dos accordions */
}
</style>