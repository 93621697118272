<script>
import { Line } from "vue-chartjs";
import percentageHelper from "@/Utils/PercentageHelper";

export default {
    extends: Line,
    props : {
        input: { type: Array },
        options: { type: Object },
        plugins: { type: Object },
        aplicacao: { type: String }
    },
    data () {
        return {
            min: 0,
            max: 0
        };
    },
    methods : {
        getOptions () {
            let defOpt = {
                responsive: true,
                maintainAspectRatio: false,
                title: { display: false },
                elements: {
                    line: { fill: false, tension: 0 }
                },
                legend: {
                    labels: { usePointStyle: true },
                    position: "top",
                    padding: 10
                },
                scales: {
                    xAxes: [
                        {
                            type: "category",
                            position: "bottom",
                            gridLines: { display: true }
                        },
                    ],
                    yAxes: [
                        {
                            gridLines: { display: true, drawBorder: false },
                            ticks: {
                                beginAtZero: true,
                                min: Math.floor(this.min),
                                max: Math.ceil(this.max),
                                callback: function (value) {
                                    return value + "%";
                                },
                            },
                        },
                    ],
                },
                tooltips: {
                    callbacks: {
                        label: (val, data) => {
                            let title = data.datasets[val.datasetIndex].label || '';
                            return `${title}: ${percentageHelper.formatPercentage(val.yLabel / 100)}`;
                        }
                    }
                }
            };
            if (this.options) {

            }
            return defOpt;
        },
        build () {
            let direct  = this.input.slice(0);
            let size    = this.input.length;
            this.min    = size && Number.MAX_VALUE || 0;
            this.max    = size && Number.MIN_VALUE || 1;
            const abbrev = [
                "01", "02", "03", "04",
                "05", "06", "07", "08",
                "09", "10", "11", "12"];
            let names  = direct.map((o) => +o.ano);
            let labels = names.flatMap((y) => abbrev.map((n) => `${y}-${n}`));
            let last   = names.length && names[names.length - 1] || (new Date().getUTCFullYear());
            labels.push(`${last + 1}-01`);
            let full    = direct.flatMap((o) => o.items).filter((o) => !!o);
            let points  = full.map((o) => {
                if (o.taxa < this.min) { this.min = o.taxa }
                if (o.taxa > this.max) { this.max = o.taxa }
                return { x: o.mes.substring(0, 7), y: o.taxa };
            });
            full        = direct.flatMap((o) => o.indice).filter((o) => !!o);
            let index   = full.map((o) => {
                if (o.taxa < this.min) { this.min = o.taxa }
                if (o.taxa > this.max) { this.max = o.taxa }
                return { x: o.mes.substring(0, 7), y: o.taxa };
            });
            if (this.aplicacao === "ipca" && index.length && index[index.length - 1].y === 0) {
                index.pop();
            }
            let plen    = points.length;
            let start   = plen ? points[0].x : new Date().toISOString().substring(0, 7);
            let finish  = plen ? points[plen - 1].x : start;
            labels      = labels.filter((l) => l >= start && l <= finish);
            let result = {
                labels,
                datasets: [
                    {
                        type: "line",
                        label: "Carteira",
                        data: points,
                        fill: true,
                        backgroundColor: "#AED1E6",
                        borderColor: "#AED1E6",
                        pointRadius: 2
                    },
                    {
                        type: "line",
                        label: this.aplicacao && this.aplicacao.toUpperCase() || "",
                        data: index,
                        fill: false,
                        backgroundColor: "red",
                        borderColor: "red",
                        pointRadius: 2
                    }
                ]
            };
            return result;
        },
        render () { this.renderChart(this.build(), this.getOptions(), this.plugins); }
    },
    watch : {
        input () { this.render(); },
        options () { this.render(); },
        aplicacao () { this.render(); }
    },
    mounted () {
        this.render();
    }
}
</script>