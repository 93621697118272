const   PTBR_2CASAS = new Intl.NumberFormat('pt-BR', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2
});
const   Q_FORMAT    = new Intl.NumberFormat('pt-BR', {
    maximumFractionDigits: 6,
    currency: 'BRL',
    percent: 'BRL'
});

export default {
    formatPercentage(percentage) {
        return percentage && (PTBR_2CASAS.format(percentage * 100 ) + '%') || "0,00%"
    },
    format2(value) {
        return value &&  PTBR_2CASAS.format(value) || "0,00";
    },
    formatStandard(value) {
        return value && PTBR_2CASAS.format(value) + '%' || "0,00%";
    },
    formatQuota(val) {
        return Q_FORMAT.format(val);
    },
    calcPercentage (percentage1,percentage2) {
//        return percentage2;
        if  ((percentage1 === 0) && (percentage2 === 0)) { return 0 };
        if  (percentage2 === 0) { return 1; }
        if  (percentage1 === 0) { return -1; }
        if  (((percentage1 > 0)  && (percentage2 > 0)) || ((percentage1 < 0)  && (percentage2 < 0))) {
            return (percentage1 / percentage2);
        }
        let p1 = 0;
        let p2 = 0;
        if (Math.abs(percentage1) > Math.abs(percentage2)) {
            p1 = Math.abs(percentage1);
            p2 = Math.abs(percentage2);
        } else {
            p1 = Math.abs(percentage2);
            p2 = Math.abs(percentage1);
        }
        if  (percentage1 < 0) { return -((p1/p2) + 1) }
        else { return (p1/p2) + 1 }
    },
    showPercent(p1, p2) {
        return this.formatPercentage(this.calcPercentage(p1, p2));
    }
}