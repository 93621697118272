<template>
  <div class="container-graph" v-if="ordenedArrays">
    <column
      class="column"
      v-for="column in ordenedArrays"
      :key="column.id"
      :year="column.year"
    >
      <div class="celulas" v-for="cell in column.actives" :key="cell.id">
        <cell :cell="cell" />
      </div>
    </column>
  </div>
</template>

<script>
import column from "./column-graph.vue";
import cell from "./cell-graph.vue";
export default {
  components: {
    column,
    cell,
  },
  props: {
    dataColumns: { type: Array, default: undefined },
  },
  methods: {
    orderArrayActives(data) {
      let ordened = data.sort((a, b) => {
        if (a.value > b.value) return -1;
        if (a.value < b.value) return 1;
        return 0;
      });
      return ordened;
    },
    orderArray(data) {
      let ordened = data.sort((a, b) => {
        return +a.year < +b.year ? -1 : +a.year > +b.year ? 1 : 0;
      });
      return ordened;
    },
  },
  computed: {
    ordenedArrays() {
      let father = this.orderArray(this.dataColumns);

      let total = [];
      father.forEach((element) => {
        let ord = this.orderArrayActives(element.actives);
        let obj = { ...element, ord };
        total.push(obj);
      });
      return total;
    },
  },
};
</script>

<style scoped>
.container-graph {
  width: 100%;
  height: auto;
  padding: 0 2.5rem;
  display: flex;
  overflow-x: auto;
}
.container-graph::-webkit-scrollbar {
  height: 10px;
  border-radius: 20px;
}
.container-graph::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 20px;
  margin: 0.5rem 0;
}
.container-graph::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 20px;
}

.container-graph .column {
  margin: 0 0.2rem;
}
</style>