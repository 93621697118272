<template>
  <div class="card" @click="goCarteira">
    <div class="carteira">
      <span class="titulo">Carteira</span>
      <span class="descricao">{{ data.title }}</span>
    </div>
    <div class="emissor">
      <span class="titulo">Emissor/Gestor</span>
      <span class="descricao">{{ data.emissor }}</span>
    </div>
    <div class="saldo" :title="data.value">
      <span class="titulo">Saldo Bruto</span>
      <span class="descricao">{{ currencyHelper.formatValue(data.value) }}</span>
    </div>
    <div class="mesAtual" :title="data.mesAtual * 100">
      <span class="titulo">{{monthLabel}}</span>
      <span class="descricao">{{ bound ? "---" : percentageHelper.formatPercentage(data.mesAtual) }}</span>
    </div>
    <div class="percent-1" :title="data.percent1 * 100">
      <span class="titulo">%</span>
      <span class="descricao">{{ bound ? "---" : percentageHelper.formatPercentage(data.percent1) }}</span>
    </div>
     <div class="valor-1" :title="data.valor1">
      <span class="titulo">R$</span>
      <span class="descricao">{{ currencyHelper.formatValue(data.valor1) }}</span>
    </div>
    <div class="doze-meses" :title="data.dozeMeses * 100">
      <span class="titulo">12 meses</span>
      <span class="descricao">{{ bound ? "---" : percentageHelper.formatPercentage(data.dozeMeses) }}</span>
    </div>
    <div class="percent-2" :title="data.percent2 * 100">
      <span class="titulo">%</span>
      <span class="descricao">{{ bound ? "---" : percentageHelper.formatPercentage(data.percent2) }}</span>
    </div>
    <div class="valor-2" :title="data.valor2">
      <span class="titulo">R$</span>
      <span class="descricao">{{ bound ? "---" : currencyHelper.formatValue(data.valor2) }}</span>
    </div>
    <div class="desde-inicio" :title="data.desdeInicio * 100">
      <span class="titulo">Desde o início</span>
      <span class="descricao">{{ percentageHelper.formatPercentage(data.desdeInicio) }}</span>
    </div>
    <div class="percent-3" :title="data.percent3 * 100">
      <span class="titulo">%</span>
      <span class="descricao">{{ percentageHelper.formatPercentage(data.percent3) }}</span>
    </div>
    <div class="valor-3" :title="data.valor3">
      <span class="titulo">R$</span>
      <span class="descricao">{{ currencyHelper.formatValue(data.valor3) }}</span>
    </div>


  </div>
</template>

<script>

import currencyHelper from "../../../../../Utils/CurrencyHelper";
import percentageHelper from "../../../../../Utils/PercentageHelper";

export default {
  props: {
    dataRecieved: { type: Object, default: undefined },
    monthLabel: { type: String },
    bound: { type: Boolean }
  },
  data() {
    return {
      currencyHelper,
      percentageHelper,
      dessertsCdbRdb: [],
      data: this.dataRecieved
    };
  },
  methods:{
       goCarteira(){
      this.$emit('cardClicked')
    }
  }
};
</script>

<style scoped>
.cards {
  width: 100%;
}
.card {
  width: 100%;
  background: white;
  padding: 0.5rem;
  margin-bottom: 0.4rem;
}
.card span {
  display: block;
}
.card > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.2rem 0;
  padding: 0.8rem 0;
}

.card > div:not(:last-child) {
  border-bottom: 1px solid #f6f6f6;
}
.card > div .titulo {
  font-weight: 600;
}
.mesAtual .titulo{
    color: #A9D08E;
}
.doze-meses .titulo{
    color: #F3A03D;
}
.desde-inicio .titulo{
    color: #76ADF4;
}
</style>