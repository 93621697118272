<template>
  
  <div class="home">

  <!-- <PaginaUsuario/> -->
  <!-- <ForaDoAr/> -->
  <!-- <GestarUsuarios/> -->

  </div>
</template>

<script>

import GestarUsuarios from '../GestaoUsuarios/GestaoUsuarios.vue'
import PaginaUsuario from '../GestaoUsuarios/PaginaUsuario.vue'
import ForaDoAr from '../ForaDoAr/SelecaoAssinatura.vue'
export default {
  components:{
   // GestarUsuarios,
   //PaginaUsuario
    //ForaDoAr

  }
};
</script>

<style  scoped>
.cards {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100vw;
  flex-wrap: wrap;
}
.cards .card {
  background: white;
  padding: 0.8rem;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  /* width: 100%; */
  flex: 1 1 400px;
  margin: 0.5rem;
  box-shadow: -4px 0px 10px rgba(0, 0, 0, 0.05);
}
.card .title {
  font-size: 15px !important;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: 0.15px;
  padding-bottom: 0.5rem;
}
.card .valor {
  font-weight: normal;
  font-size: 25px;
  line-height: 37px;
}
.home{
  padding: 1.8rem;
}
</style>