<template>
  <div>
    <div class="title">
      <span>Excluir <span style="color: #f25a5a">Ativo</span></span>
    </div>
    <div class="btn-close" @click="$emit('closeModal')">
      <v-img src="@/assets/icons/clear_icon.svg" />
    </div>
    <div v-if="checkDeleteAtivo == 0">
    <div class="tipo-ativo" >
      <v-row class="flex-column mt-7" align="start">
        <v-col class="d-flex flex-column align-left py-0">
          <span class="text-filelds">Tipo de ativo </span>
        </v-col>
        <v-col>
          <v-select
            @change="$emit('onChange', $event)"
            return-object
            :items="items"
            item-text="name"
            :menu-props="{ bottom: true, offsetY: true }"
            placeholder="Selecione"
            color="secondary"
            required
            outlined
            rounded
            dense
            class="input"

              attach=""
          ></v-select>
        </v-col>
      </v-row>
    </div>
    <keep-alive >
      <v-form ref="form">
        <component
          :is="venda"
          v-bind:tipoAtivo="currentPropertie"
          :respGetAtiTipUserId_prop=respGetAtiTipUserId
          @save="save"
        ></component>
      </v-form>
    </keep-alive>

    <div class="btn-venda" v-if="!objetoEhVazio(currentPropertie)">
      <span  @click="checkDelete">Excluir Ativo </span>
    </div>
    </div>
    <div v-if="checkDeleteAtivo != 0">
          <span>confirmar exclusão do ativo {{dadosDoAtivo.codigo}} - {{dadosDoAtivo.descricao}}</span>
      <div class="btn-venda" v-if="!objetoEhVazio(currentPropertie)">
      <span class="span-ok" @click="deleteAtivo">Sim</span>
      <span  @click="$emit('closeModal')" >Não</span>
    </div>

    </div>

  </div>
</template>

<script>
import CadastrarVenda from "../components/CadastrarVenda.vue";
export default {
  props: {
    showModal: { type: Object },
    currentPropertie: { type: Object },
    items: { type: Array },
   respGetAtiTipUserId_prop: { type: Array },
  },
  components: {
    CadastrarVenda,
  },
  data() {
    return {
       venda: "CadastrarVenda",
       ativos: [],
       checkDeleteAtivo:0,
        respGetAtiTipUserId: [],
    };
  },
  methods: {
    objetoEhVazio(e) {
      return Object.keys(e).length === 0;
    },
    checkDelete(){
       this.checkDeleteAtivo=this.dadosDoAtivo.ativoId
    },
    deleteAtivo() {
      this.$emit("deleteCompraAtivo", this.dadosDoAtivo);
    },
    save(dadosDoAtivo) {

     this.dadosDoAtivo = dadosDoAtivo;

    },

  },
  watch: {
    respGetAtiTipUserId_prop(nv) {

      this.respGetAtiTipUserId=[nv[0]];

    },
  },
};
</script>

<style scoped>
.title span {
  font-weight: 300;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.15px;
}
.btn-close {
  cursor: pointer;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 25px;
  top: 40px;

}
.btn-close img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.btn-venda {
  position: fixed;
  width: 100%;
  max-width: 497px;
  right: 0;
  bottom: 0;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  -webkit-box-shadow: 3px 2px 17px -1px rgba(0, 0, 0, 0.3);
  box-shadow: 3px 2px 17px -1px rgba(0, 0, 0, 0.3);
  background: white;
}

.btn-venda .span-ok {

  border-radius: 20px;
  padding: 0.5rem 1rem;
   margin-right:20rem;
  color: white;
  background: rgb(141, 211, 37);
  cursor: pointer;
}
.btn-venda span {
  border-radius: 20px;
  padding: 0.5rem 1rem;
  color: white;
  background: #f25a5a;
  cursor: pointer;
}
.input .v-select .dropdown-toggle {
  border: 1px solid red;
}
.input .v-select-select option {
  border: 1px solid red !important;
}
</style>