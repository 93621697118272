<template>
  <div class="fora-do-ar">
    <div class="conteudo">
      <div class="textos">
        <div class="title">
          <h1>404</h1>
        </div>

        <div class="sub-titles">
          <div class="btn-voltar" @click="goHome">
            <span>Voltar para página inicial</span>
          </div>
          <div class="divider"></div>

          <div class="textos-sub-titles">
            <span style="font-weight: bold">Ocorreu um error 404!</span>
            <span>A página que você tentou acessar não esta disponível.</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    methods:{
        goHome(){
            this.$router.push('/home ')
        }
    }
}
</script>

<style  scoped>
.fora-do-ar {
  overflow-y: auto;
  background: var(--principal-azul);
  min-height: 100vh;
}
.conteudo {
  height: 100%;
  max-width: 1100px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.textos {
  color: white;
}
.textos > div {
  padding: 0.5rem;
  margin: 2.5rem 0;

  height: auto;
}
.textos .title {
}
.textos .title h1 {
  font-size: 9rem;
  height: auto;
}
.sub-titles {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  transition: 0.2s ease-in-out;
}
.btn-voltar {
  border: 1px solid;
  padding: 0.5rem 0.7rem;
  border-radius: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.divider {
  border: 1px solid;
  /* padding: .1rem; */
  background: white;
  border-radius: 20px;
  height: 50px;
  margin: 0 0.5rem;
}
.textos-sub-titles span {
  padding: 0.2rem;
  display: block;
}

@media screen and (max-width: 720px) {
  .sub-titles > div {
    margin-top: 0.8rem;
  }
  .divider {
    display: none;
  }
}
</style>