<template>
    <v-card class="card">
      <div class="d-flex justify-center align-center pa-4" v-if="loading || emErro">
        <v-progress-circular
          :size="35"
          color="primary"
          indeterminate
          v-if="loading"></v-progress-circular>
        <div style="color: red; font-weight: bold;" v-if="emErro">{{ this.errorMessage }}</div>
      </div>

      <v-data-table
        v-else
        :headers="headers"
        :items="calculos"
        :item-class="rowBackground"
        hide-default-footer
        class="custom_table_class"
      >
        <template v-slot:[`item.title`]="{ item }">
          <v-chip
            :color="getColor(item.title)"
            :class="{ total: item.title == 'Total' }"
            class="chip"
          >
            {{ item.title }}
          </v-chip>
        </template>

        <template v-slot:[`item.participacao`]="{ item }">
          <span>{{
            percentageHelper.formatPercentage(item.participacao)
          }}</span>
        </template>

        <template v-slot:[`item.saldo`]="{ item }">
          {{ currencyHelper.formatValue(item.saldo) }}
        </template>
        <template v-slot:[`item.liquido`]="{ item }">
          {{ currencyHelper.formatValue(item.liquido) }}
        </template>
        <template v-slot:[`item.valor`]="{ item }">
          {{ currencyHelper.formatValue(item.valor) }}
        </template>
        <template v-slot:[`item.percent`]="{ item }">
          {{ percentageHelper.formatPercentage(item.percent) }}
        </template>
        <template v-slot:[`item.acumulado`]="{ item }">
          {{ currencyHelper.formatValue(item.acumulado) }}
        </template>
      </v-data-table>
    </v-card>
</template>

<script>
import currencyHelper from "../../../../Utils/CurrencyHelper";
import percentageHelper from "../../../../Utils/PercentageHelper";

export default {
    props: {
        loading: Boolean,
        emErro: Boolean,
        errorMessage: String,
        calculos: Array
    },
    data() {
        return {
            currencyHelper,
            percentageHelper,
            headers: [
                {
                    text: "Período",
                    align: "center",
                    filterable: false,
                    sortable: false,
                    value: "title",
                },
                { text: "Valor Bruto", value: "saldo", sortable: false, align: "right" },
                { text: "Valor Líquido ", value: "liquido", sortable: false, align: "right" },
                {
                    text: "% da Carteira",
                    value: "participacao",
                    sortable: false,
                    align: "right"
                },
                { text: "Valor acumulado bruto", value: "valor", sortable: false, align: "right" },
                { text: "% da carteira acumulado", value: "percent", sortable: false, align: "right" },
                { text: "Valor acumulado liquido", value: "acumulado", sortable: false, align: "right" },
            ],
        }
    },
    methods: {
        getColor(valor) {
            switch (valor) {
                case "0 dias": return "#D9E1F2";
                case "de 1 a 30 dias": return "#FCE4D6";
                case "de 31 a 90 dias": return "#fff2cc";
                case "de 91 a 180 dias": return "#e2efda";
                case "de 181 a 360 dias": return "#f8cbad";
                case "acima de 361 dias": return "#c9c9c9";
                case "Total": return "transparent";
            }
        },
        rowBackground(valor) {
            if (valor.title === "Total") {
                return "row-total";
            }
        },
    }
}
</script>

<style scoped>
.card {
  border-radius: 20px;
  padding: 1rem;
  margin: 1.5rem 0;
  box-shadow: none !important;
}
.v-data-table >>> td {
  border: transparent !important;
}
.v-data-table >>> tbody {
  border-radius: 20px !important;
}
.v-data-table >>> tbody tr:not(:last-child) {
  background: none !important;
}
.v-data-table >>> tbody .row-total {
  background: #f6f6f6;
  font-weight: bold;
}
.v-data-table >>> tbody .row-total td:first-child {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.v-data-table >>> tbody .row-total td:last-child {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.v-data-table >>> tbody .row-total:hover td:first-child {
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
}
.v-data-table >>> tbody .row-total:hover td:last-child {
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
}
.v-data-table >>> tbody .row-total td span {
  color: black !important;
}
@media screen and (max-width: 575px) {
  .v-data-table >>> tr {
    border-bottom: 1px solid #d1d1d1 !important;
  }
}
</style>