<template>
    <v-container class="gp-modal" :style="windowCls">
        <div class="tree-panel">
            <button type="button"
                    class="close-modal"
                    @click="sendCloseTree"><v-icon light>mdi-close-box</v-icon></button>
            <div class="user-search">
                <v-text-field placeholder="Pesquisar"
                            outlined
                            rounded
                            dense
                            prepend-inner-icon="mdi-magnify"
                            v-model="searchUser"></v-text-field>
            </div>
            <v-treeview :items="tree"
                        ref="user_tree"
                        activatable
                        hoverable
                        dense
                        :search="searchUser"
                        :filter="filterUser">
                <template v-slot:prepend="{}">
                    <v-icon>mdi-account</v-icon>
                </template>
                <template v-slot:label="{ item }">
                    <v-row @click="changeUser(item)" :style="getItemCls(item)">
                        <v-col>{{ item.name }}</v-col>
                        <v-col>{{ item.email }}</v-col>
                        <v-col>{{ idFormatter.format(item.document) }}</v-col>
                    </v-row>
                </template>
            </v-treeview>
        </div>
    </v-container>
</template>

<style scoped>
.gp-modal {
    position        : fixed;
    width           : 100%;
    height          : 100%;
    min-width       : 100%;
    min-height      : 100%;
    width           : 100%;
    height          : 100%;
    top             : 0;
    left            : 0;
    overflow        : hidden;
    background-color: rgba(0, 0, 0, 0.25);
    z-index         : 1000;
}
.gp-modal .tree-panel {
    position        : fixed;
    width           : var(--wWdt);
    min-width       : var(--wWdt);
    max-width       : var(--wWdt);
    height          : var(--wHgt);
    min-height      : var(--wHgt);
    max-height      : var(--wHgt);
    top             : var(--wTop);
    left            : var(--wLft);
    background      : #ffffff;
    box-shadow      : -4px 0px 10px rgba(0, 0, 0, 0.05);
    z-index         : 10;
    overflow-y      : auto;
    border          : 2px outset rgba(128,128,128,0.5);
    border-radius   : 10px;
    padding         : 1em;
    z-index         : 1001;
    font-size       : 0.75em;
}
.gp-modal .tree-panel .close-modal {
    width           : 26px;
    height          : 24px;
    position        : fixed;
    top             : var(--bTop);
    left            : var(--bLft);
    z-index         : 1002;
}
.gp-modal .tree-panel .v-treeview .row .col {
    cursor: pointer;
}
.gp-modal .tree-panel .user-search {
    margin-right: 2em;
}
</style>

<script lang="js">
import EventBus     from '../../../Bus/bus';
import idFormatter  from '../../../../Utils/IdValueFormatter';
import debouncer    from '../../../../Utils/debounce';

function clone (obj) {
    return JSON.parse(JSON.stringify(obj));
}

export default          {
    props               : {
        items           : { type:   Array },
        spec            : { type:  Object }
    },
    data                : () => ({
        expandedTree    : false,
        tree            : [],
        searchUser      : undefined,
        idFormatter,
        debouncer,
        expander        : undefined
    }),
    methods             : {
        getTreeList     () {
            let result  = this.items && this.items.map(clone) || [];
            result.sort((l, r) => {
                let result = l.level === r.level ? 0 : l.level < r.level ? -1 : 1;
                if (result !== 0) { return result; }
                return l.path.localeCompare(r.path);
            });
            return result;
        },
        buildTree       () {
            this.tree.length    = 0;
            const source        = this.getTreeList();
            const root          = source.length ? source[0] : undefined;
            if (!root) { return undefined; }
            const map           = { root };
            map[`${root.path}`] = { root };
            for (let i = 1; i < source.length; ++i) {
                let   next      = source[i];
                const parts     = next.path.split('.');
                let   index     = 0;
                let   path      = `${parts[index++]}.`;
                let   parent    = map[path];

                if (!parent) { throw new Error('Sem pai'); }

                while (next.level > parent.root.level + 1) {
                    path        = `${path}${parts[index++]}.`;
                    parent      = parent[path];
                    if (!parent) { throw new Error('Sem pai'); }
                }

                const children  = parent.root.children || (parent.root.children = []);
                path            = next.path;
                parent[path]    = { root: next };
                children.push(next);
            }
            root && this.tree.push(root);
            return root;
        },
        sendCloseTree   () {
            EventBus.$emit('closeTreeModal', true);
        },
        changeUser      (e) {
            this.$store.commit("SET_TOKEN_USER_SELECTED", e.userId || e.id);
            this.$store.commit("SET_USER_SELECTED", e);
            EventBus.$emit('newUserSelection', e);
            this.$router.push('/visao-geral');
        },
        toggleExpand    (state) {
            this.$refs.user_tree.updateAll(this.expandedTree = state);
        },
        foundIn         (l, r) {
            return !!l && l.toLowerCase().indexOf(r.toLowerCase()) >= 0;
        },
        isSearchMatch   (item, search) {
            return !!item &&
                   !!search &&
                   search.length >= 3 &&
                   (this.foundIn(item.name, search) || this.foundIn(item.email, search));
        },
        getItemCls      (item) {
            return this.isSearchMatch(item, this.searchUser) ?
                { 'color': '#886622', 'background-color': '#FFEECC' } :
                {};
        },
        filterUser      (item, search) {
            const size  = search && search.length || 0;
            if (size < 3) {
                return false;
            }
            const result = this.isSearchMatch(item, search);
            return result;
        }
    },
    computed            : {
        windowCls       () {
            if (window.innerWidth < 750) {
                return {
                    '--wHgt' : '80%',
                    '--wWdt' : '90%',
                    '--wTop' : '10%',
                    '--wLft' :  '5%',
                    '--bTop' : '11%',
                    '--bLft' : '87%',
                    'display': this.wdisplay
                };
            }
            const hval = this.spec.width  * window.innerHeight;
            const wval = this.spec.height * window.innerWidth;
            const hres = (1 - this.spec.width ) * window.innerHeight / 2;
            const wres = (1 - this.spec.height) * window.innerWidth  / 2;
            const tval = hres + 10;
            const rval = window.innerWidth - wres - 10 - 26;
            return {
                '--wHgt' : `${hval}px`,
                '--wWdt' : `${wval}px`,
                '--wTop' : `${hres}px`,
                '--wLft' : `${wres}px`,
                '--bTop' : `${tval}px`,
                '--bLft' : `${rval}px`,
                'display': this.wdisplay
            };
        }
    },
    watch               : {
        searchUser      (nv) {
            this.expander(nv);
        }
    },
    created             () {
    },
    mounted             () {
        this.expander   = this.debouncer((search) => {
            const root = this.tree && this.tree.length && this.tree[0];
            if (this.items.every((itm) => !this.isSearchMatch(itm, search) || itm.id !== root.id)) {
                this.toggleExpand(true);
            } else {
                this.toggleExpand(false);
            }
        }, 300);
        this.buildTree();
    }
}
</script>