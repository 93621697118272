import Layout from "../../Internal/Layout.vue";
import Liquidez from "./Liquidez.vue";

const LiquidezRoutes = {
  component: Layout,
  children: [
    {
      name: "liquidez",
      path: "/",
      component: Liquidez,
      meta: {
        requiresAuth: true,
        requiresBasic: false,
        requiresPattern: false,
        requiresSenior: true,
        requiresExpert: true,
        requiresProfessional: true,
      },
    },
  ],
};

export default LiquidezRoutes;
