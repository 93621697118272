<template>
  <div>
    <LinhaClasseProduto
      v-if="!switchVisualizacao && selectedType == 'porClasseEProduto'"
    />
    <LinhaClasseProdutoEvolucao
      v-if="switchVisualizacao && selectedType == 'porClasseEProduto'"
      class="result-line" :monthLabel="monthLabel" :timeLabel="timeLabel" :indice="indice"
    />

    <LinhaEstrategia
      v-if="!switchVisualizacao && selectedType == 'porEstrategia'"
    />
    <LinhaEstrategiaEvolucao
      v-if="switchVisualizacao && selectedType == 'porEstrategia'"
      :monthLabel="monthLabel" :timeLabel="timeLabel" :indice="indice"
    />

    <small v-if="switchVisualizacao" class="indice"></small>
  </div>
</template>

<script>
import LinhaClasseProduto from "../components/Linha.vue";
import LinhaClasseProdutoEvolucao from "../components/Linha2.vue";
import LinhaEstrategia from "../components/Linha-estrategia.vue";
import LinhaEstrategiaEvolucao from "../components/Linha2-estrategia.vue";
export default {
  components: {
    LinhaClasseProduto,
    LinhaClasseProdutoEvolucao,
    LinhaEstrategia,
    LinhaEstrategiaEvolucao,
  },
  props:{
      switchVisualizacao: Boolean,
      selectedType: String,
      indice: String,
      monthLabel: String,
      timeLabel: String
  }
};
</script>
<style scoped>
.indice {
  position: absolute;
  right: 15px;
  top: 15px;
  font-weight: 500;
}
</style>