import { render, staticRenderFns } from "./atribuicao-estrategia.vue?vue&type=template&id=41772c03&scoped=true"
import script from "./atribuicao-estrategia.vue?vue&type=script&lang=js"
export * from "./atribuicao-estrategia.vue?vue&type=script&lang=js"
import style0 from "./atribuicao-estrategia.vue?vue&type=style&index=0&id=41772c03&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41772c03",
  null
  
)

export default component.exports