import Layout from "../../Internal/Layout.vue";
import ExamStatusPage from "./ExamStatus.vue";

const ExamStatusRoutes = {
  component: Layout,
  children: [
    {
      name: "status",
      path: "/",
      component: ExamStatusPage,
      meta: {
        requiresAuth: true,
        requiresAdmin: true,
      },
    },
  ],
};

export default ExamStatusRoutes;
