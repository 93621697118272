//import axios from "axios";
import Vue from "vue";
import moment from "moment";
import CalculadorAtivo from "./Calculador";

const mockedStore = {
  commit: () => {},
};

export default class ApiService {
  constructor() {
    this.calculador = new CalculadorAtivo().setDefaultLoaders();
  }

  getVueStore () {
    const app = document.querySelector('#app');
    return (app && app.$store) || (app.__vue__ && app.__vue__.$store) || mockedStore;
  }

  setHeaders(removeContentType = false, showLoading = true, isLogoff = false) {
    const headersConfig = {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
      "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
      //"X-Request-Id": UUID.UUID(),
      showLoading: showLoading ? "1" : "0", // tem q ser string
    };

    var token = sessionStorage.getItem("token");
    if (isLogoff) {
      headersConfig['X-Spent-Token'] = token;
    } else if (token) {
      headersConfig.Authorization = `Bearer ${token}`;
    }

    if (removeContentType) {
      delete headersConfig["Content-Type"];
    }

    return headersConfig;
  }

  setFileToBody(body, files = []) {
    let bodyToSend = body;

    if (files && files.length > 0) {
      let formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("file", files[i], files[i].fileName || files[i].name);
      }

      // temos q dar o append numa chave e nao append de cada tipo por vez,
      // senao ele adiciona tudo como tipo string e isso gera inconsistencias
      formData.append("json", JSON.stringify(body));
      bodyToSend = formData;
    }

    return bodyToSend;
  }

  getListEntity(store,type){
    return new Promise((resolve, reject) => {
      if (type == undefined  || type>0){
        var entity = store.getters.$listEntity;
        if (entity.length==0  ){
          this.get('entity')
              .then((resp) => {
                entity=resp.content;
                store.commit("SET_ENTIY", JSON.stringify(entity));
                if (type != undefined  && type>0){
                  resolve(entity.filter(p => this.entityRole(p.entityRole,type)))
                }
              })
              .catch ((error)=> {
                reject({Error:"Erro na carga da entidades"});
          });
        } else if (entity.length>0 && type != undefined && type>0) {
          this.ping().then(() =>
            resolve(entity.filter(p => this.entityRole(p.entityRole,type))));
        }
      }
    })
  }

  getEstrategias(productId){
    //debugger
    return new Promise((resolve, reject) => {
        this.get(`estrategia/product/${productId}`)
        .then((resp) => {
         resolve( resp.content.estrategia);
        })
        .catch ((error)=> {
        reject({Error:"Erro na carga da estrategia"});
        });
    })
  }

  getListAtivosFunInv(store,productId,fType){
    return new Promise((resolve, reject) => {
      var listAtivosFunInv    = store.getters.$listAtivosFunInv;
      var listAtivosFunInvTp  = store.getters.$listAtivosFunInvTp;
      if (listAtivosFunInvTp && (listAtivosFunInvTp !== fType)) {
        listAtivosFunInv      = [];
      }
      if (listAtivosFunInv.length == 0 ) {
        const upath = fType ?
          `ativos/product/${productId}?cType=${fType}` :
          `ativos/product/${productId}`;
        this.get(upath)
          .then((resp) => {
            listAtivosFunInv=resp.content.listActive
            store.commit("SET_LISTAATIVOSFUNINV", JSON.stringify(listAtivosFunInv));
            store.commit("SET_LISTAATIVOSFUNINVTP", JSON.stringify(fType));
            resolve(listAtivosFunInv);
          })
          .catch ((error)=> {
            reject({Error:"Erro na carga da ativos"});
          });
      } else {
        this.ping().then(() =>
          resolve(listAtivosFunInv));
      }
    })
  }

  getListAtivos(productId){

    return new Promise((resolve, reject) => {
    this.get(`ativos/product/${productId}`)
    .then((resp) => {

        resolve(resp.content.listActive);
    })
    .catch ((error)=> {
        reject({Error:"Erro na carga dos ativos"});
    });
    })
  }

  getUsersActive(store,productId, type){
    return new Promise((resolve, reject) => {
      let addr = type ?
        `ativos/user/${store.getters.$tokenUserSelected}?productId=${productId}&cType=${type}` :
        `ativos/user/${store.getters.$tokenUserSelected}?productId=${productId}`;
      this.get(addr)
      .then((resp) => {
        resolve(resp.content.usersActive); })
      .catch ((error)=> {
          reject({Error:"Erro na carga dos ativos do usuário"});
      });
    })
  }
  getUserToAtivoUserRentabilidade(byStrategy,store){
    return new Promise((resolve, reject) => {

      var _userToAtivoUser=store.getters.$userToAtivoUserRentabilidade;
      if (_userToAtivoUser.calculos.length==0 ){
        let model = {
          userId:store.getters.$tokenUserSelected,
          byStrategy: byStrategy,
        };
          this.post("userToAtivo/user", model)
          .then((resp) => {
            _userToAtivoUser={calculos:this.calculador.calcularRentabilidade(resp.content.all.items),
                              dozeMeses:this.calculador.calcular12UltCarteira(resp.content.all.items)};
              store.commit("SET_USERTOATIVOUSERRentabilidade", JSON.stringify(_userToAtivoUser));
              resolve(_userToAtivoUser);
          })
          .catch ((error)=> {

              reject({Error:"Erro na carga dos ativos"});
              });
      } else {
        this.ping().then(() =>
          resolve(_userToAtivoUser));
      }
    })
  }
  getUserToAtivoUserLiquidez(store){
    return new Promise((resolve, reject) => {

      var _userToAtivoUser=store.getters.$userToAtivoUserLiquidez;
      if (_userToAtivoUser.content.length==0 ){
          this.get(`userToAtivo/liquidez/${store.getters.$tokenUserSelected}`)
          .then((resp) => {
            _userToAtivoUser={content:resp.content,evolucao:this.calculador.calcularLiquidez(resp.content.all.items, true)};
              store.commit("SET_USERTOATIVOUSERLiquidez", JSON.stringify(_userToAtivoUser));
              resolve(_userToAtivoUser);
          })
          .catch ((error)=> {

              reject({Error:"Erro na carga dos ativos"});
              });
      } else {
        this.ping().then(() =>
          resolve(_userToAtivoUser));
      }
    })
  }
  getUserToAtivoUser(byStrategy,store, range){
    return new Promise((resolve, reject) => {

      if(byStrategy){
        var _userToAtivoUser=store.getters.$userToAtivoUserProduto;
        if (range || _userToAtivoUser.content.length==0) {
          let model = {
            userId:store.getters.$tokenUserSelected,
            byStrategy: byStrategy,
          };
            this.post("userToAtivo/user", model)
            .then((resp) => {
              const evolucao = range ?
                this.calculador.calcularCarteira(resp.content.all.items, ...range) :
                this.calculador.calcularCarteira(resp.content.all.items);
              _userToAtivoUser = {content: resp.content, evolucao };
              store.commit("SET_USERTOATIVOUSERProduto", JSON.stringify(range ? { content:[] } : _userToAtivoUser));
              resolve(_userToAtivoUser);
            })
            .catch ((error)=> {

                reject({Error:"Erro na carga dos ativos"});
                });
        } else {
          this.ping().then(() =>
            resolve(_userToAtivoUser));
        }
      }else{
        var _userToAtivoUser=store.getters.$userToAtivoUserEstrategia;
        if (range || _userToAtivoUser.content.length==0 ){
          let model = {
            userId:store.getters.$tokenUserSelected,
            byStrategy: byStrategy,
          };
            this.post("userToAtivo/user", model)
            .then((resp) => {
              const evolucao = range ?
                this.calculador.calcularCarteira(resp.content.all.items, ...range) :
                this.calculador.calcularCarteira(resp.content.all.items);
              _userToAtivoUser = { content: resp.content, evolucao };
                store.commit("SET_USERTOATIVOUSEREstrategia", JSON.stringify(range ? { content:[] } : _userToAtivoUser));
                resolve(_userToAtivoUser);
            })
            .catch ((error)=> {

                reject({Error:"Erro na carga dos ativos"});
                });
        } else {
          this.ping().then(() =>
            resolve(_userToAtivoUser));
        }
      }
    })

  }

  calcPercentage (cart, index) {
    let taxa;
    if  ((cart === 0) && (index === 0)) { return 0 };
    if  (index === 0) { return 0; }
    if  (cart === 0) { return 0; }
    taxa = Math.abs(cart / index);
    taxa = (cart > 0) ?  taxa : - taxa;
    return taxa;
  }

  getProcess(dados,calculos,comparacao,aplicacao, acumulado) {
    let ld     = dados.length === 0;
    if(ld) {
      let dd     = [];
      let dt     = [];
      let di     = [];
      let dc     = [];
      let cd     = [];
      let cc     = [];
      let aa     = [];
      let ai     = [];
      let size   = calculos.dados.length && calculos.dados.length - 1;
      let prev   = calculos.inicio;
      let cparc  = 1;
      let iparc  = 1;
      let cacum  = 1;
      let iacum  = 1;
      let xacum  = {
        cdi      : 1,
        ipca     : 1,
        dolar    : 1,
        ibovespa : 1
      };
      calculos.dados.forEach((item, idx) => {
          let next  = item.carteira;
          cacum    *= (1 + next.taxa/100);
          let month = (+item.mes.substring(5, 7)) - 1;
          xacum.cdi = 1 + item.cdi.acum;
          xacum.ipca = 1 + item.ipca.acum;
          xacum.dolar = 1 + item.dolar.acum;
          xacum.ibovespa = 1 + item.ibovespa.acum;
          let igrp  = {
              cdi             : {
                  indice      : item.cdi,
                  comparador  : {
                      taxa    : 100 * this.calcPercentage(next.taxa / 100, item.cdi.taxa / 100),
                      valor   : 0,
                      mes     : next.mes,
                      rotulo  : next.rotulo,
                      label   : "% CDI",
                      calc    : xacum.cdi
                  }
              },
              ipca            : {
                  indice      : item.ipca,
                  comparador  : {
                      taxa    : item.ipca.taxa ?
                        100 * this.calcPercentage(next.taxa / 100, item.ipca.taxa / 100) :
                        undefined,
                      valor   : 0,
                      mes     : next.mes,
                      rotulo  : next.rotulo,
                      label   : "% IPCA",
                      calc    : xacum.ipca
                  }
              },
              dolar           : {
                  indice      : item.dolar,
                  comparador  : {
                      taxa    : 100 * this.calcPercentage(next.taxa / 100, item.dolar.taxa / 100),
                      valor   : 0,
                      mes     : next.mes,
                      rotulo  : next.rotulo,
                      label   : "% DOLAR",
                      calc    : xacum.dolar
                  }
              },
              ibovespa        : {
                  indice      : item.ibovespa,
                  comparador  : {
                      taxa    : 100 * this.calcPercentage(next.taxa / 100, item.ibovespa.taxa / 100),
                      valor   : 0,
                      mes     : next.mes,
                      rotulo  : next.rotulo,
                      label   : "% IBOVESPA",
                      calc    : xacum.ibovespa
                  }
              }
          };
          let src   = igrp[aplicacao];
          iacum     = xacum[aplicacao];
          let comp  = src.indice;
          let rate  = src.comparador;
          let title = item.mes.substring(0, 4);
          if (title !== prev) {
              let cdat  = dt.reduce((v, o) => o && (v * (1 + o.taxa / 100)) || v, 1);
              let isrc  = di.filter(x => !!x);
              let iprog = isrc[isrc.length - 1].acum + 1;
              let idat  = iprog;
              if (dd.length) {
                let fsrc = dd[0].indice.filter((x) => !!x);
                let fdat = fsrc[fsrc.length - 1].acum + 1;
                idat /= fdat;
              }
              let xdat  = 100 * this.calcPercentage(cdat - 1, idat - 1);
              let cprog = (cdat * cparc);
              let rdat  = 100 * this.calcPercentage(cprog - 1, iprog - 1);
              let citms = cd.reduce((o, e) => {
                o.cdi.indice.push(e && e.cdi.indice);
                o.cdi.comparado.push(e && e.cdi.comparador);
                o.ipca.indice.push(e && e.ipca.indice);
                o.ipca.comparado.push(e && e.ipca.comparador);
                o.dolar.indice.push(e && e.dolar.indice);
                o.dolar.comparado.push(e && e.dolar.comparador);
                o.ibovespa.indice.push(e && e.ibovespa.indice);
                o.ibovespa.comparado.push(e && e.ibovespa.comparador);
                return o;
              }, {
                  cdi     : { indice: [], comparado: [] },
                  ipca    : { indice: [], comparado: [] },
                  dolar   : { indice: [], comparado: [] },
                  ibovespa: { indice: [], comparado: [] }
              });
              if ((cc.length === 0) || (citms.cdi.indice.length < 12)) {
                  while (citms.cdi.indice.length < 12) {
                      citms.cdi.indice.push(undefined);
                      citms.cdi.comparado.push(undefined);
                      citms.ipca.indice.push(undefined);
                      citms.ipca.comparado.push(undefined);
                      citms.dolar.indice.push(undefined);
                      citms.dolar.comparado.push(undefined);
                      citms.ibovespa.indice.push(undefined);
                      citms.ibovespa.comparado.push(undefined);
                      di.push(undefined);
                      dt.push(undefined);
                      dc.push(undefined);
                  }
              }
              cc.unshift({
                  ano: prev,
                  items: citms,
              });
              dd.unshift({
                  ano: prev,
                  items: dt,
                  indice: di,
                  comparado: dc,
                  anual: {
                      carteira: (cdat - 1) * 100,
                      indice  : (idat - 1) * 100,
                      comparado: xdat
                  },
                  total: {
                      carteira: (cprog - 1) * 100,
                      indice  : (iprog - 1) * 100,
                      comparado: rdat
                  }
              });
              acumulado.push({
                ano: prev,
                items: aa,
                indice: ai
              });
              cparc   = cdat * cparc;
              iparc   = idat * iparc;
              dt      = [next];
              di      = [comp];
              dc      = [rate];
              cd      = [igrp];
              aa      = [{ mes: `${next.mes.substring(0, 7)}`, taxa: (cacum - 1) * 100 }];
              ai      = [{ mes: `${next.mes.substring(0, 7)}`, taxa: (iacum - 1) * 100 }];
              prev    = title;
          } else {
              while (dt.length < month) {
                dt.push(undefined);
                di.push(undefined);
                dc.push(undefined);
                cd.push(undefined);
              }
              dt.push(next);
              di.push(comp);
              dc.push(rate);
              cd.push(igrp);
              aa.push({ mes: `${next.mes.substring(0, 7)}`, taxa: (cacum - 1) * 100 });
              ai.push({ mes: `${next.mes.substring(0, 7)}`, taxa: (iacum - 1) * 100 });
          }
          if (idx === size) {
              let cdat  = dt.reduce((v, o) => o && (v * (1 + o.taxa / 100)) || v, 1);
              let isrc  = di.filter(x => !!x);
              let iprog = isrc[isrc.length - 1].acum + 1;
              let idat  = iprog;
              if (dd.length) {
                let fsrc = dd[0].indice.filter((x) => !!x);
                let fdat = fsrc[fsrc.length - 1].acum + 1;
                idat /= fdat;
              }
              let xdat  = 100 * this.calcPercentage(cdat - 1, idat - 1);
              let cprog = (cdat * cparc);
              let rdat  = 100 * this.calcPercentage(cprog - 1, iprog - 1);
              let citms = cd.reduce((o, e) => {
                o.cdi.indice.push(e && e.cdi.indice);
                o.cdi.comparado.push(e && e.cdi.comparador);
                o.ipca.indice.push(e && e.ipca.indice);
                o.ipca.comparado.push(e && e.ipca.comparador);
                o.dolar.indice.push(e && e.dolar.indice);
                o.dolar.comparado.push(e && e.dolar.comparador);
                o.ibovespa.indice.push(e && e.ibovespa.indice);
                o.ibovespa.comparado.push(e && e.ibovespa.comparador);
                return o;
              }, {
                  cdi     : { indice: [], comparado: [] },
                  ipca    : { indice: [], comparado: [] },
                  dolar   : { indice: [], comparado: [] },
                  ibovespa: { indice: [], comparado: [] }
              });
              while (citms.cdi.indice.length < 12) {
                citms.cdi.indice.push(undefined);
                citms.cdi.comparado.push(undefined);
                citms.ipca.indice.push(undefined);
                citms.ipca.comparado.push(undefined);
                citms.dolar.indice.push(undefined);
                citms.dolar.comparado.push(undefined);
                citms.ibovespa.indice.push(undefined);
                citms.ibovespa.comparado.push(undefined);
                di.push(undefined);
                dt.push(undefined);
                dc.push(undefined);
              }
              cc.unshift({
                  ano: prev,
                  items: citms
              });
              dd.unshift({
                  ano: prev,
                  items: dt,
                  indice: di,
                  comparado: dc,
                  anual: {
                      carteira: (cdat - 1) * 100,
                      indice  : (idat - 1) * 100,
                      comparado: xdat
                  },
                  total: {
                      carteira: (cprog - 1) * 100,
                      indice  : (iprog - 1) * 100,
                      comparado: rdat
                  }
              });
              acumulado.push({
                ano: prev,
                items: aa,
                indice: ai
              });
              cparc   = cdat * cparc;
              iparc   = idat * iparc;
          }
      });
      dados.length = 0;
      dados.push(...dd);
      comparacao.length = 0;
      comparacao.push(...cc);
    } else {
      let iparc  = 1;
      let prev   = dados.length ? dados[0].ano : "";
      let dtlen  = dados.length;
      for (let index = dtlen - 1; index >= 0; --index) {
          let item                = dados[index];
          let group               = comparacao[index];
          let source              = group.items[aplicacao];
          if (!source) { return; }
          let itaxa               = source.indice
              .reduce((v, o) => o && v * (1 + o.taxa / 100) || v, 1);
          source.comparado.forEach((o) => {
            if (!o) { return; }
            let test = o.mes.substring(0, 7);
            let year = test.substring(0, 4);
            acumulado.every((w) => {
              if (w.ano !== year) { return true; }
              w.indice.every((x) => {
                if (x.mes !== test) { return true; }
                x.taxa = (o.calc - 1) * 100;
                return false;
              });
              return false;
            });
          });
          iparc                  *= itaxa;
          let iisrc               = source.indice.filter((x) => !!x);
          let itot                = iisrc[iisrc.length - 1].acum + 1;
          if ((aplicacao === "ipca")) {
            if (iisrc.length > 1) {
              itot                = iisrc[iisrc.length - 2].acum + 1;
            } else if (index < dtlen - 1) {
              iisrc = comparacao[index + 1].items[aplicacao].indice.filter((x) => !!x);
              itot                = iisrc[iisrc.length - 1].acum + 1;
            } else {
              itot                = 1;
            }
          }
          item.indice             = source.indice;
          item.comparado          = source.comparado;
          item.total.indice       = (itot  - 1) * 100;
          item.total.comparado    = 100 * this.calcPercentage(item.total.carteira/100, item.total.indice/100);
          item.anual.indice       = (iparc - 1) * 100;
          item.anual.comparado    = 100 * this.calcPercentage(item.anual.carteira/100, item.anual.indice/100);
          if (item.ano !== prev) {
              iparc               = 1;
          }
      }
    }
    return {dados,comparacao}
  }

  getCotacao(id,date){

    return new Promise((resolve, reject) => {
      this.get(`ativos/${id}/cotacao?date=${date}`)
      .then((resp) => {
        resolve(resp.content); })
      .catch ((error)=> {
          reject({Error:"Cotação de ativo não encontrada"});
      });
      })
  }

  entityRole(val,type){
    let buff= new Buffer.from(val,'base64');
    return ((buff[1] & type) !== 0);
  }

  callWS(
    method,
    url,
    data,
    files = null,
    showException = true,
    showLoading = true,
    isLogoff = false
  ) {
    return new Promise((resolve, reject) => {
      let bodyToSend  = this.setFileToBody(data, files);
      let headers     = this.setHeaders(!!files, showLoading, isLogoff);
      const store     = this.getVueStore();

      const event = Object.assign({}, bodyToSend);
      event.showLoading = showLoading;
      event.requestId = new Date().getTime();

      if (Vue.$globalEvent) {
        Vue.$globalEvent.$emit("httpRequestStart", event);
      }

      var environment = process.env.VUE_APP_BASE_URL;
      Vue.http({
        method: method,
        url: `${environment}${url}`,
        body: bodyToSend,
        headers: headers,
      })
        .then((resp) => {
          if (Vue.$globalEvent) {
            Vue.$globalEvent.$emit("httpRequestEnd", resp);
          }
          event.response = resp;
          const auth1 = resp.headers.map['x-set-authorization'];
          const auth2 = resp.headers.map['x-set-authorization-valid-to'];
          if (auth1 && auth1.length) {
            store.commit('SET_CURRENT_TOKEN', auth1[0]);
            store.commit('SET_EXPIRES_TOKEN', auth2[0]);
          }
          resolve(resp.body || resp);
        })
        .catch((err) => {
          if (Vue.$globalEvent) {
            Vue.$globalEvent.$emit("httpRequestEnd", err);
          }
          this.handleError(err, method, showException);
          event.response = err;

          return reject(err);
        });
    });
  }
  compCotacao (a, b) {
    let aVoid = (a === null) || (a === undefined) || !('data' in a);
    let bVoid = (b === null) || (b === undefined) || !('data' in b);
    if (aVoid) { return bVoid ? 0 : -1; }
    return bVoid ? 1 : a.data.localeCompare(b.data);
  }
  setCotacoes(cotacoes) {
      let CDI = [];
      let IGPM = [];
      let IBOVESPA = [];
      let IPCA = [];
      let DOLAR = [];
      cotacoes && cotacoes.forEach((c) => {
          switch (c.idAtivo) {
              case 4220:CDI.push(c); break;
              case 4219:IGPM.push(c); break;
              case 4204:IBOVESPA.push(c); break;
              case 4222:IPCA.push(c); break;
              case 4221:DOLAR.push(c); break;
          }
      });
      CDI.sort(this.compCotacao);
      IGPM.sort(this.compCotacao);
      IBOVESPA.sort(this.compCotacao);
      IPCA.sort(this.compCotacao);
      DOLAR.sort(this.compCotacao);
      localStorage.setItem("CDI",JSON.stringify(CDI));
      localStorage.setItem("IGPM",JSON.stringify(IGPM));
      localStorage.setItem("IBOVESPA",JSON.stringify(IBOVESPA));
      localStorage.setItem("IPCA",JSON.stringify(IPCA));
      localStorage.setItem("DOLAR",JSON.stringify(DOLAR));
  }
  getValidarData(date){
    return JSON.parse(localStorage.getItem("CDI")).filter(listF=> (moment(listF.data).format("YYYY-MM-DD") == moment(date).format("YYYY-MM-DD"))).length > 0;
  }
  getCotacoesDia(tipo,dia){
    var cotacoes=JSON.parse(localStorage.getItem(tipo));
    var _dia = moment(dia).format("YYYY-MM");
    var _val=1;
    cotacoes.filter(listF=> (moment(listF.data).format("YYYY-MM")==_dia)).map(i=>{
    if (tipo=='IPCA')
      {_val =(i.valor/100)+1;}
    else
    {_val =(i.valor)+1;}
    //val = Math.pow(Math.pow(((i.valor/100)+1), (1/30)), (contador+1));
    });
    return _val;
  }

  getCotacoes(tipo){
   return JSON.parse(localStorage.getItem(tipo));
  }

  getCotacoesMes(tipo,start,stop,idTProduto){

    var cotacoes=JSON.parse(localStorage.getItem(tipo));
    var _return;
    var mes="00";
    var count=-1;
    if (tipo=="CDI") {
      var fatCorrAcuCDI=1;
      var fatCorrAcuCDIMes=1;
      var CDI=[];

      cotacoes.filter(listF=> (listF.data>=start && listF.data<=stop)).map(i=>{
        fatCorrAcuCDI=((i.valor/100)+1)*fatCorrAcuCDI;
        var dta=moment(i.data).format("MM");
        if (mes=="00"){
          mes=dta;
//          if (idTProduto == 1 || idTProduto == 4 || idTProduto == 8)  {fatCorrAcuCDIMes=((i.valor/100)+1);}
        if (idTProduto == 8)  {fatCorrAcuCDIMes=((i.valor/100)+1);}
        /* VSC 01/09/2023 codigo tem que ser revisado CDB / CRI / CRA / Fundos começa a calcular no segundo dia */
        } else  if (mes !=dta ){
                  CDI[count].fimMes=true;
                  CDI[count].fatCorrAcuMes=(CDI[count].fatCorrAcu/fatCorrAcuCDIMes);
                  CDI[count].percent=(((CDI[count].fatCorrAcu/fatCorrAcuCDIMes)-1)*100)
                  fatCorrAcuCDIMes=CDI[count].fatCorrAcu;
                  mes=dta;
        }
        count+=1;
          var _ret={data:i.data, idAtivo:i.idAtivo,   idAtivoNavigation: i.idAtivoNavigation, idCotacao: i.idCotacao,  tipo:i.tipo , valor:i.valor, fatCorrAcu:fatCorrAcuCDI, fimMes:false, fatCorrAcuMes:0 ,percent:0}  ;
          CDI.push(_ret);
        });
        CDI[count].fimMes=true;
        CDI[count].fatCorrAcuMes=CDI[count].fatCorrAcu/fatCorrAcuCDIMes;
        CDI[count].percent=(((CDI[count].fatCorrAcu/fatCorrAcuCDIMes)-1)*100);
        var _return=CDI.filter(listF=>(listF.fimMes==true));

        return _return;
    }else if (tipo=="DOLAR" || tipo =="IBOVESPA"){
      var valorDolarMes=1;
      var DOLAR=[];
      cotacoes.filter(listF=> (listF.data>=start && listF.data<=stop)).map(i=>{
        var dta=moment(i.data).format("MM");
        if (mes=="00"){
          mes=dta;
          valorDolarMes=i.valor;
        } else  if (mes !=dta ){
          DOLAR[count].fimMes=true;
          DOLAR[count].percent=((DOLAR[count].valor/valorDolarMes)-1)*100;
          valorDolarMes=DOLAR[count].valor;
          mes=dta;
        }
        count+=1;
          var _ret={data:i.data, idAtivo:i.idAtivo,   idAtivoNavigation: i.idAtivoNavigation, idCotacao: i.idCotacao,  tipo:i.tipo , valor:i.valor, fatCorrAcu:0,fimMes:false,fatCorrAcuMes:0 ,percent:0}  ;
          DOLAR.push(_ret);

      })
      DOLAR[count].fimMes=true;
      DOLAR[count].percent=((DOLAR[count].valor/valorDolarMes)-1)*100;
      var _return=DOLAR.filter(listF=>(listF.fimMes==true));

      return _return;

    } else if (tipo=="IPCA"){
      var percent=0;
      var diasMes=0;
      var IPCA=[];
      var startday = parseInt(moment(start).format("DD"));
      var stopday =  parseInt(moment(stop).format("DD"));
      var _start=moment(start).subtract(startday-1, 'days').format("YYYY-MM-DD");
      var _stop=moment(stop).subtract(stopday-1, 'days').add(1, 'months').format("YYYY-MM-DD");
      var _stopmonth = moment(stop).format("MMYYYY");
      var _datamonth;
      cotacoes.filter(listF=> (listF.data>=_start && listF.data<_stop)).map(i=>{
        diasMes= parseInt(moment(i.data).add(1, 'months').subtract(1, 'days').format("DD"));
        _datamonth = moment(i.data).format("MMYYYY");
        if (count==-1&& startday<diasMes){
          percent=(i.valor/diasMes)*(diasMes-startday+1);
        }else{
          percent=i.valor;
        }
        count+=1;
        var _ret={data:i.data, idAtivo:i.idAtivo,   idAtivoNavigation: i.idAtivoNavigation, idCotacao: i.idCotacao,  tipo:i.tipo , valor:i.valor, fatCorrAcu:0,fimMes:true,fatCorrAcuMes:0 ,percent:percent}  ;
        IPCA.push(_ret);
      })
      if (stopday<diasMes &&  _stopmonth == _datamonth){   //VSC este calculo somente é valido se for no mesmo mês
        IPCA[count].percent = idTProduto === 1 || idTProduto === 4 || idTProduto === 8 ?
          (IPCA[count].percent/diasMes)*(stopday-1) :
          (IPCA[count].percent/diasMes)*stopday;
      }
      var _return=IPCA.filter(listF=>(listF.fimMes==true));
      return _return;
    }else{
      return cotacoes;
    }
  }

  get(url, showException = true, showLoading = true) {
    return this.callWS("GET", url, null, null, showException, showLoading);
  }

  post(url, data, files = null, showException = true, showLoading = true, isLogoff = false) {
    return this.callWS("POST", url, data, files, showException, showLoading, isLogoff);
  }

  put(url, data, files = null, showException = true, showLoading = true) {
    return this.callWS("PUT", url, data, files, showException, showLoading);
  }

  delete(url, data, showException = true, showLoading = true) {
    return this.callWS("DELETE", url, data, showException, showLoading);
  }

  handleError(error, operation = "operation", showException = true) {
    if (error) {
      error.operation = operation;
      error.showException = showException;
    //  if (Vue.$globalEvent) {
   //     Vue.$globalEvent.$emit("httpError", error);
    //  }
    }
  }

  async downLoadFile(
    path,
    fileName = "export.csv",
    contentType = "text/csv; charset=utf-8"
  ) {
    //localStorage.setItem(" HTTP_2_"+url,`${process.env.VUE_APP_BASE_URL}${path}` );
    await Vue.http
      .get(`${process.env.VUE_APP_BASE_URL}${path}`, {
        responseType: "arraybuffer",
        headers: this.setHeaders(false, false, false),
      })
      .then((response) => {
        if (response.data) {
          this.makeDownLoadFile(response.data, fileName, contentType);
        }
      });
  }

  /**
   * Methodo usado para fazer download do Arquivo
   * @param data - Array Buffer do arquivo
   * @param type - tipo do arquivo.
   */
  makeDownLoadFile(data, fileName, type) {
    const a = document.createElement("a");
    document.body.appendChild(a);

    const blob = new Blob([data], { type: type });
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = fileName;
    a.click();

    setTimeout(() => {
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, 0);

    //const pwa = window.open(url);
    // if (!pwa || pwa.closed || typeof pwa.closed == "undefined") {
    //   alert("Please disable your Pop-up blocker and try again.");
    // }
  }

  async uploadFiles (url, files) {
    const method = 'POST';
    const headers = {
      "Content-Type"                : "multipart/form-data",
      "Accept"                      : "application/json",
      "Access-Control-Allow-Origin" : "*",
      "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
      "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
      "showLoading"                 : "1"
    };
    const token                     = sessionStorage.getItem("token");
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    let data = new FormData();
    const event = Object.assign({}, data);
    try {
      files.forEach((f) => data.append('upload', f.file, f.name))
      event.showLoading = true;
      event.requestId = new Date().getTime();
      if (Vue.$globalEvent) {
        Vue.$globalEvent.$emit("httpRequestStart", event);
      }
      var environment = process.env.VUE_APP_BASE_URL;
      let req   = {
        method  : method,
        url     : `${environment}${url}`,
        body    : data,
        headers : headers
      };

      let resp = await Vue.http(req);

      if (Vue.$globalEvent) {
        Vue.$globalEvent.$emit("httpRequestEnd", resp.body || resp);
      }
      event.response = resp;

      return resp.body || resp;
    } catch (r) {
      this.handleError(r, method, true);
      event.response = r;

      throw r;
    }
  }

  createPluggyToken (userId) {
    return this.post('integration/pluggy/connect_token', JSON.stringify(userId)).then(
      (data) => {
        return { success: true, content: data.content }
      },
      (reason) => {
        return { sucess: false, reason, message: reason.body.message || reason.body.title || 'Falha ao gerar token de acesso' }
      });
  }

  getPluggyFullInvestments (item, userId) {
    return this.post(`integration/pluggy/investments/full/${userId}`, item)
      .then(
        (data) => {
          return { success: true, content: data.content }
        },
        (reason) => {
          return { sucess: false, reason, message: reason.message }
        });
  }

  getStoredInvestments (userId) {
    return this.get(`integration/pluggy/investments/stored/${userId}`)
      .then(
        (data) => {
          return { success: true, content: data.content }
        },
        (reason) => {
          return { sucess: false, reason, message: reason.message }
        });
  }

  updateInvestmentStatus (userId, item, investment, status) {
    var request = {
      investmentId: investment.investmentId,
      itemId: item.id,
      authorization: status
    };
    return this.post(`integration/pluggy/investments/${userId}`, request)
      .then(
        (_) => {
          return { success: true, content: "Atualizado com sucesso" }
        },
        (reason) => {
          return { sucess: false, reason, message: reason.message }
        });
  }

  updateStrategy  (idAtivo, idEstrategia) {
    return this.post(`userToAtivo/${idAtivo}/estrategia`, idEstrategia)
      .then(
        (_)       => { return { success:  true, content: 'Atualizado com sucesso' } },
        (reason)  => { return { success: false,  reason,  message: reason.body.message || reason.body.title } });
  }

  getAllStrats () {
    return new Promise((acc) => {
      this.get('estrategia')
        .then(resp    =>
          acc({ success: true , content: resp.content }))
        .catch(reason =>
          acc({ success: false, message: reason.message, reason }));
    });
  }

  importPluggyItem (itemId) {
    return this.post('integration/pluggy/item/import', { id: itemId })
      .then(
        (data)   => data.content,
        (reason) => {
          return { success: false, message: reason.body.message || reason.body.title, reason }
        });
  }

  updateInvestmentCode (investment) {
    return this.post('integration/pluggy/investments/code', investment)
      .then(
        (data)   => data.content,
        (reason) => { return { success: false, message: reason.body.message || reason.body.title, reason } });
  }

  updateCustodiante (movimento, idAtivo, userId) {
    return this.post(`userToAtivo/${idAtivo}/${userId}/custodiante`, movimento)
    .then(
      (data)   =>
        data.content,
      (reason) => {
        return { success: false, message: reason.body.message || reason.body.title, reason }
      });
  }

  logoff () {
    return this.get(`user/token/free`)
      .then(
        (r) =>
          r.content,
        (r) => (
          { success: false, message: r.body.message || r.body.title, reason: r })
      );
  }

  logout (router) {
    return this.post('user/token/clean', undefined, undefined, true, true, true)
      .then(
        (r) =>
          r,
        (r) =>
          r)
      .then(() => {
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("expires");
        sessionStorage.removeItem("user");
        if (!(window && window.location.pathname === '/auth')) {
          router.push("/auth");
        }
      });
  }

  acceptTerms () {
    const store   = this.getVueStore();
    if (!(store && store.getters)) {
      this.$toast.error('Falha de inicialização do cliente de serviço.');
      return Promise.resolve();
    }
    const user    = store.getters.$currentUser;
    if (!user) {
      this.$toast.error('Falha de resgate de dados do usuário.');
      return Promise.resolve();
    }
    const userId  = user.id;
    if (!userId) {
      this.$toast.error('Falha de resgate de dados do usuário.');
      return Promise.resolve();
    }
    return this.post(`user/terms/${userId}`)
      .then(
        (r)    => {
          if (r.statusCode === 200) {
            user.acceptTerms = true;
            sessionStorage.setItem('user', JSON.stringify(user));
          }
          return r;
        },
        (r)  => (
          { success: false, message: r.body.message || r.body.title, reason: r }));
  }

  ping () {
    return this.get('health/ping')
      .then(
        (r) =>
          r,
        (r) =>
          r);
  }
}
