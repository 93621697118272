<template>
  <div>
    <v-container class="container" fluid >
      <component
        :is="novoUsuario"
         v-bind:btnRegisterClicado="btnRegistrarClicado"
      ></component>
    </v-container>
  </div>
</template>

<script>
import novoUsuario from '../novoUsuario/novo-usuario.vue'
export default {
    data(){
        return{
            currentForm: "",
            novoUsuario: 'novoUsuario',
            btnRegistrarClicado: false,
        }
    },
    props:{
        botaoRegistrarUsuario:{ type: Boolean}
    },
    components:{
        novoUsuario

    },
    watch:{
         botaoRegistrarUsuario(nv, ov){
                if(nv == true){
                    this.btnRegistrarClicado = true
                    setTimeout(() =>{this.btnRegistrarClicado = false}, 100)
                }
            }
    }
};
</script>

<style  scoped>
</style>