import Layout from "../../Internal/Layout.vue";
//import VisaoGeral from "./VisaoGeral.vue";
//import RentabilidadeAnual from "./RentabilidadeAnual.vue";
import Base from "./Base.vue";

const VisaoGeralRoutes = {
  component: Layout,
  children: [
    {
      name: "visaoGeral",
      path: "/",
      component: Base,
      meta: {
        requiresAuth: true,
        requiresBasic: true,
        requiresPattern: true,
        requiresSenior: true,
        requiresExpert: true,
        requiresProfessional: true,
      },
    },
  ],
};

export default VisaoGeralRoutes;
