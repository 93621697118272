const EscolhaPerfil = () => import('./EscolhaPerfil.vue');
const CadastroPerfil = () => import('./CadastroPerfil.vue')
const Layout = () => import("../../External/Layout.vue");

const EscolhaPerfilRoutes = {
    component: Layout,
    children:[
        {
            path: '/',
            name: 'escolhaPerfil',
            component: EscolhaPerfil,
            meta: {
                requiresAuth: false,
               requiresAdmin: false,
              },
        },
        {
            path: 'cadastro',
            name: 'cadastroPerfil',
            component: CadastroPerfil
        }
    ]
   
}

export default EscolhaPerfilRoutes