export default {
    codec: new TextEncoder(),
    getCsv (obj, label, name, sep, culture) {
        let lines = this.extract(obj, sep || ";", culture || 'pt-BR');
        return this.encode(lines, label || "Salvar CSV...", name || "data.csv")
    },
    buildPath (...segs) {
        if (!(segs && segs.length)) { return ""; }
        return segs.filter((s) => !!s).map((s) => s.replace(/(^\.+|\.+$)/g, "")).join(".");
    },
    expandTitle (title) {
        let result = {};
        let stack = [["", title]];
        while (stack.length) {
            let [path, obj] = stack.pop();
            let names = this.getPropNames(obj);
            for (let i = 0; i < names.length; ++i) {
                let name  = names[i];
                let part  = `${path}.${name}`;
                let value = obj[name];
                switch (typeof value) {
                    case "string": result[part] = value; break;
                    case "object": (value !== null) && stack.push([part, value]); break;
                }
            }
        }
        return result;
    },
    testExclusions (exclude, path) {
        const check = (exp) => (exp instanceof RegExp) ? !exp.test(path) : exp !== path;
        return (exclude.length > 0) && !exclude.every(check);
    },
    getTail (path) {
        let parts = path && path.split(".") || [];
        return parts.length && parts[parts.length - 1] || path;
    },
    getFormatter (title, format) {
        let map = this.expandTitle(title);
        return (path) => (format && format(path)) || (map && map[path]) || this.getTail(path);
    },
    getFilter (exclude, filter) {
        return (path) => (filter && filter(path)) ||
            (exclude && (this.testExclusions(exclude, path))) || false;
    },
    buildArr (info) {
        let { next, indent, res, sep, culture, name, path, formatter, filter } = info;
        let opts = next.reduce((o, v) => {
            let iv = this.isVoid(v);
            let tr = iv ? "undefined" : typeof v;
            let tp = tr;
            o.types.push(tp);
            if (o.type && ((!iv) && o.type !== tp)) {
                tp = "mixed";
            }
            if (tr !== "object") {
                ++o.std;
            } else if (v instanceof Array) {
                tp = "array";
            } else {
                let names = this.getPropNames(v)
                    .filter((name) => filter(this.buildPath(path, name)));
                let props = this.getDescriptors(names, v);
                let pvals = props.map((tup) => this.getValue(tup[1], v));
                pvals.forEach((x, i) => {
                    let name = names[i];

                    let prv  = o.props.indexOf(name) + 1;
                    if (!prv) { o.props.push(name); }

                    let icl  = prv && (o.col.indexOf(name) + 1) || 0;
                    let irw  = prv && (o.row.indexOf(name) + 1) || 0;
                    let iuk  = prv && (o.unk.indexOf(name) + 1) || 0;
                    let ivd  = prv && (o.vod.indexOf(name) + 1) || 0;
                    let itp  = (ivd || this.isVoid(x)) ? "vod" :
                        (icl || (typeof x === "object")) ? "col" :
                        iuk ? "unk" : "row";
                    switch (itp) {
                        case "vod":
                            if (!(ivd || icl || irw || iuk)) { o.vod.push(name); }
                            break;
                        case "col":
                            if (ivd)  { o.vod.splice(ivd - 1, 1); }
                            if (irw)  {
                                o.row.splice(irw - 1, 1);
                                o.unk.push(name);
                                break;
                            }
                            if (iuk)  { break; }
                            if (!icl) { o.col.push(name); }
                            break;
                        case "row":
                            if (ivd)  { o.vod.splice(ivd - 1, 1); }
                            if (icl)  {
                                o.col.splice(irw - 1, 1);
                                o.unk.push(name);
                                break;
                            }
                            if (iuk)  { break; }
                            if (!irw) { o.row.push(name); }
                            break;
                    }
                });
            }
            o.type = tp;
            return o;
        }, { types: [], type: "", props: [], std: 0, row: [], col: [], unk: [], vod: [] });
        let hrows = (opts.row || []).concat(info.vod || []);
        let hcols = (opts.col || []).concat(info.unk || []);
        if ((!hcols.length) && hrows.length) {
            res.push(indent + hrows.map((r) => formatter(this.buildPath(path, r) || r)).join(sep));
            next.forEach((v) =>
                res.push(indent +
                    hrows.map((n) =>
                        this.format(
                            v && this.getValue(Object.getOwnPropertyDescriptor(v, n), v)))
                                .join(sep)));
        } else {
            next.forEach((v) => {
                let [head, tail] = hrows
                    .reduce((a, name, pos) => {
                        let top = formatter(this.buildPath(path, name)) || name;
                        a[0] = [a[0], top].join(pos ? sep : "");
                        a[1] = [
                            a[1],
                            this.format(
                                this.getValue(Object.getOwnPropertyDescriptor(v, name),
                                    v))]
                    .join(pos ? sep : "");
                    return a;
                }, [indent, indent]);
                if (hrows.length) { res.push(head, tail); }
                hcols.forEach((name) => {
                    let tit = formatter(this.buildPath(path, name)) || name;
                    res.push(indent + tit);
                    let oval = this.getValue(Object.getOwnPropertyDescriptor(v, name), v);
                    if (this.isVoid(oval)) { return; }
                    let ninfo = {
                        next: oval,
                        indent: indent + sep,
                        res,
                        sep,
                        culture,
                        name,
                        path: this.buildPath(path, name),
                        formatter,
                        filter
                    };
                    if (oval instanceof Array) {
                        this.buildArr(ninfo);
                        return;
                    }
                    this.buildObj(ninfo);
                });
            });
        }
    },
    buildObj (info, omit) {
        let { next, indent, res, sep, culture, name, path, formatter, filter } = info;
        let pnames = this.getPropNames(next).filter((n) => filter(this.buildPath(path, n)));
        let props  = this.getDescriptors(pnames, next);
        let split  = this.splitDesc(props, next);
        let [head, tail] = split.row.reduce((a, tup, pos) => {
            let val = this.format(this.getValue(tup[1], next), culture);
            let top = formatter(this.buildPath(path, tup[0])) || tup[0];
            a[0] = [a[0], top].join(pos ? sep : "");
            a[1] = [a[1], val].join(pos ? sep : "") ;
            return a;
        }, [indent, indent]);
        if (split.row.length && !omit) { res.push(head); }
        if (split.row.length) { res.push(tail); }
        split.col.forEach((tup) => {
            let ovl = this.getValue(tup[1], next);
            let lft = formatter(this.buildPath(path, tup[0])) || tup[0];
            res.push(indent + lft);
            if (this.isVoid(ovl)) { return; }
            let ninfo = {
                next: ovl,
                indent: indent + sep,
                res,
                sep,
                culture,
                name: tup[0],
                path: this.buildPath(path, tup[0]),
                formatter,
                filter
            };
            if (ovl instanceof Array) {
                this.buildArr(ninfo);
                return;
            }
            this.buildObj(ninfo);
        });
    },
    build (content, sep, culture, formatter, filter) {
        let res         = [];
        if (this.isVoid(content)) { return res; }
        let indent      = "";
        let stack       = [{ name: "", path: "", next: content, indent }];
        while (stack.length) {
            let { name, path, next, indent } = stack.pop();
            let simp    = this.format(next);
            switch (typeof next) {
                case "bigint":
                case "number":
                case "boolean":
                case "string": res.push(indent + simp); break;
                case "object":
                    if (next === null) { break; }
                    let info = { next, indent, res, sep, culture, name, path, formatter, filter };
                    if ((next instanceof Array) && next.length) {
                        this.buildArr(info);
                        break;
                    }
                    this.buildObj(info)
                    break;
            }
        }
        return res;
    },
    formatCsv (obj, options) {
        let { label, name, sep, culture, title, exclude, format, filter } = {
            label  : options?.label      || "Salvar Csv...",
            name   : options?.name       || "data.csv",
            sep    : options?.separator  || ";",
            culture: options?.culture    || "pt-BR",
            title  : options?.title      || {},
            exclude: options?.exclude    || [],
            format : options?.format,
            filter : options?.filter
        };
        let formatter = this.getFormatter(title, format);
        let selector  = this.getFilter(exclude, filter);
        let lines     = this.build(obj, sep, culture, formatter, selector);
        return this.encode(lines, label || "Salvar CSV...", name || "data.csv")
    },
    getJson (obj, label, name) {
        let lines = JSON.stringify(obj)
            .replace(/\{/g, "\n  {")
            .replace(/\[/g, "\n  [")
            .trim();
        return this.encode([lines], label || "Salvar JSON...", name || "data.json", "application/json");
    },
    getFileName (original, isJson) {
        let mime = isJson ?
            { description: "Arquivo .JSON", accept: { "application/json": [".json"] } } :
            { description: "Arquivo .CSV", accept: { "text/csv": [".csv"] } };
        return window.showSaveFilePicker({ suggestedName: original, types: [mime] });
    },
    getPropNames (obj) {
        return obj ?
            Object.getOwnPropertyNames(obj)
                .filter((n) => n && !(n.startsWith("_") || n.startsWith("$"))) :
            [];
    },
    getDescriptors(names, obj) {
        return names.map((n) => [n, Object.getOwnPropertyDescriptor(obj, n)]);
    },
    isQuoted(text) {
        return text && text.startsWith('"') && text.endsWith('"') && text.length > 1 || false;
    },
    escapeQuotes(text) {
        let res = "";
        let inp = text && text.normalize('NFC') || "";
        let prv = "";
        for (let pos = 0; pos < inp.length; ++pos) {
            let nxt = inp.charAt(pos);
            switch (nxt) {
                case "\r": res += "\\r"; break;
                case "\n": res += "\\n"; break;
                case "\t": res += "\\t"; break;
                case '"' : res += (prv !== '\\') ? '\\"' : '"'; break;
                default  : res += nxt; break;
            }
            prv = nxt;
        }
        return res;
    },
    getValue (pdesc, obj) {
        if (this.isVoid(obj) || this.isVoid(pdesc)) { return undefined; }
        if ("value" in pdesc) {
            let res = pdesc.value;
            if ((typeof res) === "function") {
                res = pdesc.value(obj);
            }
            if ((typeof res === "string") && !this.isQuoted(res)) { res = `"${this.escapeQuotes(res)}"`; }
            return res;
        } else {
            let val = pdesc.get();
            if ((typeof val === "string") && !this.isQuoted(val)) { val = `"${this.escapeQuotes(val)}"`; }
            return val;
        }
    },
    splitDesc (pdescs, obj) {
        return pdescs.reduce((o, p) => {
            let { row, col } = o;
            let [_, prop]    = p;
            let pvl          = this.getValue(prop, obj);
            if ((pvl === null) || (pvl === undefined)) {
                row.push(p);
            } else if ((pvl instanceof Array) || ((typeof pvl) === "object")) {
                col.push(p);
            } else {
                row.push(p);
            }
            return o;
        }, { row: [], col: [] });
    },
    format (content, culture) {
        if (content === null || content === undefined) { return undefined; }
        switch (typeof content) {
            case "string": return this.isQuoted(content) ? content : `"${this.escapeQuotes(content)}"`;
            case "number":
            case "bigint": return content.toLocaleString(culture);
            case "boolean": return content ? "true" : "false";
            default: return content;
        }
    },
    isVoid (x) { return (x === null) || (x === undefined) },
    expandObj (obj, res, indent, sep, culture, omit) {
        if (!obj) { return; }
        let pnames = this.getPropNames(obj);
        let props  = this.getDescriptors(pnames, obj);
        let split  = this.splitDesc(props, obj);
        let [head, tail] = split.row.reduce((a, tup, pos) => {
            let val = this.format(this.getValue(tup[1], obj), culture);
            a[0] = [a[0], tup[0]].join(pos ? sep : "");
            a[1] = [a[1], val].join(pos ? sep : "") ;
            return a;
        }, [indent, indent]);
        if (split.row.length && !omit) { res.push(head); }
        if (split.row.length) { res.push(tail); }
        split.col.forEach((tup) => {
            let ovl = this.getValue(tup[1], obj);
            res.push(indent + tup[0]);
            if (this.isVoid(ovl)) { return; }
            if (ovl instanceof Array) {
                this.expandArr(ovl, res, indent + sep, sep, culture);
                return;
            }
            this.expandObj(ovl, res, indent + sep, sep, culture);
        });
    },
    expandArr (arr, res, indent, sep, culture) {
        if (!(arr && arr.length)) { return; }
        let info = arr.reduce((o, v) => {
            let iv = this.isVoid(v);
            let tp = iv ? "undefined" : typeof v;
            o.types.push(tp);
            if (o.type && ((!iv) && o.type !== tp)) {
                tp = "mixed";
            }
            if (tp !== "object") {
                ++o.std;
            } else if (v instanceof Array) {
                tp = "array";
            } else {
                let names = this.getPropNames(v);
                let props = this.getDescriptors(names, v);
                let pvals = props.map((tup) => this.getValue(tup[1], v));
                pvals.forEach((x, i) => {
                    let name = names[i];

                    let prv  = o.props.indexOf(name) + 1;
                    if (!prv) { o.props.push(name); }

                    let icl  = prv && (o.col.indexOf(name) + 1) || 0;
                    let irw  = prv && (o.row.indexOf(name) + 1) || 0;
                    let iuk  = prv && (o.unk.indexOf(name) + 1) || 0;
                    let ivd  = prv && (o.vod.indexOf(name) + 1) || 0;
                    let itp  = (ivd || this.isVoid(x)) ? "vod" :
                        (icl || (typeof x === "object")) ? "col" :
                        iuk ? "unk" : "row";
                    switch (itp) {
                        case "vod":
                            if (!(ivd || icl || irw || iuk)) { o.vod.push(name); }
                            break;
                        case "col":
                            if (ivd)  { o.vod.splice(ivd - 1, 1); }
                            if (irw)  {
                                o.row.splice(irw - 1, 1);
                                o.unk.push(name);
                                break;
                            }
                            if (iuk)  { break; }
                            if (!icl) { o.col.push(name); }
                            break;
                        case "row":
                            if (ivd)  { o.vod.splice(ivd - 1, 1); }
                            if (icl)  {
                                o.col.splice(irw - 1, 1);
                                o.unk.push(name);
                                break;
                            }
                            if (iuk)  { break; }
                            if (!irw) { o.row.push(name); }
                            break;
                    }
                });
            }
            o.type = tp;
            return o;
        }, { types: [], type: "", props: [], std: 0, row: [], col: [], unk: [], vod: [] });
        let hrows = info.row.concat(info.vod);
        let hcols = info.col.concat(info.unk);
        if ((!hcols.length) && hrows.length) {
            res.push(indent + hrows.join(sep));
            arr.forEach((v) =>
                res.push(indent +
                    hrows.map((n) =>
                        this.format(
                            v && this.getValue(Object.getOwnPropertyDescriptor(v, n), v)))
                                .join(sep)));
        } else {
            arr.forEach((v) => {
                let [head, tail] = hrows
                    .reduce((a, name, pos) => {
                        a[0] = [a[0], name].join(pos ? sep : "");
                        a[1] = [
                            a[1],
                            this.format(
                                this.getValue(Object.getOwnPropertyDescriptor(v, name),
                                    v))]
                    .join(pos ? sep : "");
                    return a;
                }, [indent, indent]);
                if (hrows.length) { res.push(head, tail); }
                hcols.forEach((name) => {
                    res.push(indent + name);
                    let oval = this.getValue(Object.getOwnPropertyDescriptor(v, name), v);
                    if (this.isVoid(oval)) { return; }
                    if (oval instanceof Array) {
                        this.expandArr(oval, res, indent + sep, sep, culture);
                        return;
                    }
                    this.expandObj(oval, res, indent + sep, sep, culture);
                });
            });
        }
    },
    extract(content, sep, culture) {
        let res     = [];
        if (this.isVoid(content)) { return res; }
        let indent  = "";
        let stack   = [{ next: content, indent }];
        while (stack.length) {
            let { next, indent } = stack.pop();
            if (this.isVoid(next)) { continue; }
            let smp = this.format(next);
            switch (typeof next) {
                case "bigint":
                case "number":
                case "boolean":
                case "string": res.push(indent + smp); break;

                case "object":
                    if (next instanceof Array) {
                        this.expandArr(next, res, indent, sep, culture);
                        break;
                    }
                    this.expandObj(next, res, indent, sep, culture);
                    break;
            }
        }
        return res;
    },
    encode (lines, label, name, mime) {
        let input           = lines && lines.length && lines.join("\r\n") || "";
        let file            = new File(
            [input],
            name,
            { type: mime || "text/csv", lastModified: new Date().getTime() });
        let addr            = URL.createObjectURL(file);
        let link            = document.createElement("a");
        link.appendChild(document.createTextNode(label));
        link.href           = addr;
        link.download       = name;
        return { file, link }
    }
}