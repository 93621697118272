<template>
<div class="wrapper-movimentacao">

  <!--<div class="movimentacao-content">
        <div class="card-movimentacao">
          <div class="title">
            <span>Movimentação</span>
          </div>
        </div>
        <div class="card-movimentacao">
          <div class="item-movimentacao">
            <span class="title-movimentacao">Data</span>
          </div>
          <div class="item-movimentacao">
            <span class="title-movimentacao">Histórico</span>
          </div>
           <div class="item-movimentacao">
            <span class="descricao-movimentacaoValor">Valor unitário</span>
          </div>
          <div class="item-movimentacao">
            <span class="descricao-movimentacaoValor">Valor total</span>
          </div>
        </div>
        <div class="card-movimentacao" v-for="event in ativo_prop.movimentos" v-bind:key="event.id">
          <div class="item-movimentacao">
            <span class="descricao-movimentacao">{{dateHelper.formatDate(event.investmentDate)}}</span>
          </div>
          <div class="item-movimentacao">
            <span class="descricao-movimentacao">{{event.tpMovimentoDesc}}</span>
          </div>
           <div class="item-movimentacao">
            <span class="descricao-movimentacaoValor">{{ currencyHelper.formatValue(getselectValue(event)) }}</span>
          </div>
          <div class="item-movimentacao">
           <span class="descricao-movimentacaoValor">{{ currencyHelper.formatValue(event.investedValue) }}</span>
          </div>

        </div>


      </div>-->

      <div class="tabela-movimentacao">
        <div class="title">
          <span>Movimentação</span>
        </div>
        <v-data-table
          :headers="headers2"
          height="25vh"
          :items="ativo_prop"
          class="elevation-1"
          sortable="false"
          :items-per-page="100000"
          hide-default-footer
        >
        </v-data-table>

      </div>


      <div class="tabela-movimentacao">
        <div class="title">
          <span>Desempenho</span>
        </div>
        <v-data-table
          :headers="headers"
          fixed-header
           height="60vh"
          :items="desserts"
          class="elevation-1"
          sortable="false"
          :items-per-page="100000"
          hide-default-footer
        >
          <template v-slot:header.name="{ header }">
          {{ header.text.toUpperCase() }}
         </template>
        </v-data-table>
      </div>
</div>
</template>
<script>
import dateHelper from "../../../../Utils/DateHelper";
import currencyHelper from "../../../../Utils/CurrencyHelper";


export default {
    props:{
        headers: Array,
        headers2: Array,
        desserts: Array,
        ativo_prop: { type: Array },
        ativo_propMovimento : Array,

    },
      created: function() {
    // console.log (JSON.stringify(this.ativo_prop));
  },
   data() {
    return {
      dateHelper,
      currencyHelper,
    };
  },
  methods: {
    getselectValue (event) {
      return event.shareValue > 0 ? event.shareValue : 0;
    }
  }
}
</script>


<style scoped>

.title {
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.15px;
  color: #494949;
  margin: 1rem 0 0 0;
  text-align: center;
  /*border: 1px solid red; */
}

.elevation-1
{
  text-align: left;
  justify-content: center;
  align-items: center;
}

.card-movimentacao {

  border-radius: 20px;
  background: white;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0rem;
  width: 100%;
  margin:0; padding:0;
}
.card-movimentacao > div {

  display: flex;
  flex-direction: column;
  margin: 0.5rem 0;
  flex: 1 1 200px;
  margin:0; padding:0;
}
.title-movimentacao {
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: #494949;
  text-align: center;
}
.descricao-movimentacao {
  font-size: 15px;
  line-height: 30px;
  letter-spacing: 0.15px;
  color: #494949;
  text-align: center;
}
.tabela-movimentacao {
  border-radius: 20px;
  background: white;
  text-align: center;

}
.tabela-movimentacao .title {
  /*padding: 1rem 0 0 1rem;*/
  text-align: center;
}

.descricao-movimentacaoValor {
  text-align: right;
}

.v-data-table {
  border-radius: 20px;
  /*color: red;
  text-align: center ;
  padding: 1rem;*/
  box-shadow: none !important;
}
.v-data-table::v-deep .v-data-table__wrapper::-webkit-scrollbar {
  width: 8px !important;
  border-radius: 20px !important;
}
.v-data-table::v-deep .v-data-table__wrapper::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
  border-radius: 20px;
}
.v-data-table::v-deep .v-data-table__wrapper::-webkit-scrollbar-thumb {
  background: rgb(197, 196, 196) !important;
  border-radius: 20px;
}
</style>