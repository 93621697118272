<template>
  <div class="rentabilidade-component">
    <v-card flat class="grafico-wrapper">
      <div class="grafico-desempenho">
        <div class="top-grafico">
          <div class="top">
            <div class="title-grafico">
              <span>Rentabilidade da carteira</span>
            </div>
            <div class="btns-ativos">
              <div class="btn-cdi">
                <v-switch @click="switchChanged('CDI')"
                  v-model="stateCDI"
                  label="CDI"
                  bgColor="#35BAFC" />
              </div>

              <div class="btn-ipca">
                <v-switch @click="switchChanged('IPCA')"
                  v-model="stateIPCA"
                  label="IPCA"
                  bgColor="#29BD1A" />
              </div>

              <div class="btn-dolar">
                <v-switch @click="switchChanged('DOLAR')"
                  v-model="stateDolar"
                  label="DÓLAR"
                  bgColor="#C520FF" />
              </div>

              <div class="btn-ibovespa">
                <v-switch @click="switchChanged('IBOVESPA')"
                  v-model="stateIBovespa"
                  label="IBOVESPA"
                  bgColor="#FA5807" />
              </div>
            </div>
          </div>
        </div>

        <div class="grafico">
          <Bar
            :chartData="testeObj"
            :options="options"
            label="Carteira"
            label2="Rentabilidade mensal"
          />

          <div class="descricao-grafico">
            <span>Rentabilidade (%)</span>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import moment from "moment";
import * as Mock from "@/components/mock/mock.js";
import Bar from "./graficos/Bar-rentabilidade.vue";
import percentageHelper from "@/Utils/PercentageHelper";
export default {
  components: {
    Bar,
  },
  props: {
    calculos: { type: Object },
    dozeMeses: { type: Array }
  },
  data() {
    return {
      testeDatas: undefined,
      testeValores: undefined,
      testeObj: {},
      arrPrincipal: [],
      dadosGraficos: [],
      arrCDI: [],
      arrIPCA: [],
      arrDOLAR: [],
      arrIBOVESPA: [],
      dadosCDI: undefined,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 30,
            right: 15,
          },
        },
        legend: {
          labels: {
            usePointStyle: true,
          },
          position: "top",
          padding: 10,
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: true,
                drawBorder: false,
              },
              beginAtZero: true,
              ticks: {
                stepSize: 5,
              },
            },
          ],
        },
        tooltips: {
          mode: "index",
          intersect: false,
          callbacks: {
            label: (val, data) => {
              let title = data.datasets[val.datasetIndex].label || '';
              //console.log(val, data);
              return `${title}: ${percentageHelper.formatPercentage(val.yLabel / 100)}`;
            },
          },
        },
      },
      stateCDI: false,
      stateIPCA: false,
      stateDolar: false,
      stateIBovespa: false
    };
  },
  methods: {
    switchChanged(tipo) {
      if (tipo !== 'CDI' && this.stateCDI) {
        this.stateCDI = false;
        this.removerCDI();
      }
      if (tipo !== 'IPCA' && this.stateIPCA) {
        this.stateIPCA = false;
        this.removerIPCA();
      }
      if (tipo !== 'DOLAR' && this.stateDolar) {
        this.stateDolar = false;
        this.removerDoLAR();
      }
      if (tipo !== 'IBOVESPA' && this.stateIBovespa) {
        this.stateIBovespa = false;
        this.removerIBOVESPA();
      }
      let active = this.stateCDI || this.stateIPCA || this.stateDolar || this.stateIBovespa;
      if (active) {
        switch (tipo) {
          case "CDI": this.addCDI(); break;
          case "IPCA": this.addIPCA(); break;
          case "DOLAR": this.addDoLAR(); break;
          case "IBOVESPA": this.addIBOVESPA(); break;
        }
      } else {
        switch (tipo) {
          case "CDI": this.removerCDI(); break;
          case "IPCA": this.removerIPCA(); break;
          case "DOLAR": this.removerDoLAR(); break;
          case "IBOVESPA": this.removerIBOVESPA(); break;
        }
      }
    },
    addCDI() {
      this.removerCDI();
      let dataSetCDI = {
        label: "CDI",
        data: this.arrCDI.map((d) => d.valores.valor),
        backgroundColor: "#35BAFC",
        borderRadius: 10,
        barPercentage: 0.5,
        slug: "cdi",
      };
      this.dadosGraficos.push(dataSetCDI);
      this.initDefault();
    },
    addDoLAR() {
      this.removerDoLAR();
      let dataSetDOLAR = {
        label: "DÓLAR",
        data: this.arrDOLAR.map((d) => d.valores.valor),
        backgroundColor: "#C520FF",
        borderRadius: 10,
        barPercentage: 0.5,
        slug: "dolar",
      };
      this.dadosGraficos.push(dataSetDOLAR);
      this.initDefault();
    },
    addIPCA() {
      this.removerIPCA();
      let dataSetIPCA = {
        label: "IPCA",
        data: this.arrIPCA.map((d) => d.valores.valor),
        backgroundColor: "#29BD1A",
        borderRadius: 10,
        barPercentage: 0.5,
        slug: "ipca",
      };
      this.dadosGraficos.push(dataSetIPCA);
      this.initDefault();
    },
    addIBOVESPA() {
      this.removerIBOVESPA();
      let dataSeIbovespa = {
        label: "IBOVESPA",
        data: this.arrIBOVESPA.map((d) => d.valores.valor),
        backgroundColor: "#FA5807",
        borderRadius: 10,
        barPercentage: 0.5,
        slug: "ibovespa",
      };

      this.dadosGraficos.push(dataSeIbovespa);
      this.initDefault();
    },
    removerCDI() {
      for (const [index, value] of this.dadosGraficos.entries()) {
        if (value.slug == "cdi") {
          this.dadosGraficos.splice(index, 1);
        }
      }
      this.initDefault();
    },
    removerDoLAR() {
      for (const [index, value] of this.dadosGraficos.entries()) {
        if (value.slug == "dolar") {
          this.dadosGraficos.splice(index, 1);
        }
      }
      this.initDefault();
    },
    removerIPCA() {
      for (const [index, value] of this.dadosGraficos.entries()) {
        if (value.slug == "ipca") {
          this.dadosGraficos.splice(index, 1);
        }
      }
      this.initDefault();
    },
    removerIBOVESPA() {
      for (const [index, value] of this.dadosGraficos.entries()) {
        if (value.slug == "ibovespa") {
          this.dadosGraficos.splice(index, 1);
        }
      }
      this.initDefault();
    },
    removerCarteira() {
      for (const [index, value] of this.dadosGraficos.entries()) {
        if (value.label == "Carteira") {
          this.dadosGraficos.splice(index, 1);
        }
      }
      this.initDefault();
    },
    initDataGrahph() {
      this.testeDatas = this.arrPrincipal.map((d) => d.data);
      this.testeValores = this.arrPrincipal.map((d) => d.valores.valor);
      this.removerCarteira();
      this.dadosGraficos.push({
        label: "Carteira",
        data: this.testeValores,
        backgroundColor: "#579AF2",
        borderRadius: 10,
        barPercentage: 0.5,
      });
      this.initDefault();
    },
    initDefault() {
      this.testeObj = {
        labels: this.testeDatas,
        datasets: this.dadosGraficos,
      };
    },
  },
  created() {
    if (this.dozeMeses && this.dozeMeses.length) {
      this.arrPrincipal = this.dozeMeses.map((e) => {
        return { data: e.carteira.rotulo, valores: { valor: e.carteira.taxa } };
      }) || [];
      this.arrCDI = this.dozeMeses.map((e) => {
        return { data: e.carteira.rotulo, valores: { valor: e.cdi.taxa } };
      }) || [];
      this.arrIPCA = this.dozeMeses.map((e) => {
        return { data: e.carteira.rotulo, valores: { valor: e.ipca.taxa } };
      }) || [];
      this.arrDOLAR = this.dozeMeses.map((e) => {
        return { data: e.carteira.rotulo, valores: { valor: e.dolar.taxa * 100 } };
      }) || [];
      this.arrIBOVESPA = this.dozeMeses.map((e) => {
        return { data: e.carteira.rotulo, valores: { valor: e.ibovespa.taxa * 100 } };
      }) || [];
    } else {
      this.arrPrincipal = [];
      this.arrCDI = [];
      this.arrIPCA = [];
      this.arrDOLAR = [];
      this.arrIBOVESPA = [];
    }
    this.initDataGrahph();
  },
  mounted() {
  },
  watch: {
    dozeMeses : {
      handler(nv, ov) {
        if (this.dozeMeses && this.dozeMeses.length) {
          this.arrPrincipal = this.dozeMeses.map((e) => {
            return { data: e.carteira.rotulo, valores: { valor: e.carteira.taxa } };
          }) || [];
          this.arrCDI = this.dozeMeses.map((e) => {
            return { data: e.carteira.rotulo, valores: { valor: e.cdi.taxa } };
          }) || [];
          this.arrIPCA = this.dozeMeses.map((e) => {
            return { data: e.carteira.rotulo, valores: { valor: e.ipca.taxa } };
          }) || [];
          this.arrDOLAR = this.dozeMeses.map((e) => {
            return { data: e.carteira.rotulo, valores: { valor: e.dolar.taxa * 100.0 } };
          }) || [];
          this.arrIBOVESPA = this.dozeMeses.map((e) => {
            return { data: e.carteira.rotulo, valores: { valor: e.ibovespa.taxa * 100.0 } };
          }) || [];
        } else {
          this.arrPrincipal = [];
          this.arrCDI = [];
          this.arrIPCA = [];
          this.arrDOLAR = [];
          this.arrIBOVESPA = [];
        }
        this.initDataGrahph();
      }
    }
  }
};
</script>

<style  scoped>
/* .rentabilidade-component {
} */

.descricoes {
  margin-bottom: 1.5rem;
}
.descricoes .title {
  max-width: 50%;
}
.descricoes .title h1 {
  font-size: 25px;
  line-height: 37px;
  font-weight: 500;
  letter-spacing: 0.15px;
  color: #000000;
}
.descricoes .title span {
  font-weight: 300;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.15px;
  color: #000000;
}
.grafico-wrapper {
  border-radius: 20px;
  padding-bottom: 0.5rem;
}

.grafico-wrapper .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 1rem 0 0 1rem;
}
.grafico {
  position: relative;
}
.title-grafico span {
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: 0.15px;
  color: #000000;
}
.grafico .descricao-grafico {
  transform: rotate(-90deg);
  max-width: 150px;
  position: absolute;
  top: 50%;
  left: -35px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  letter-spacing: 0.15px;

  color: #000000;
}
.grafico-wrapper .btns-ativos {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin: 0.5rem 0;
}
.grafico-wrapper .btns-ativos > div {
  margin: 0 0.8rem;
}
.grafico-wrapper .btns-ativos > div span {
  padding: 0 0.2rem;
}
/* .grafico-wrapper .title-grafico {
} */
@media screen and (max-width: 700px) {
  .descricoes .title {
    max-width: 100% !important;
  }
  .grafico-wrapper .btns-ativos > div {
    flex: 1 1 40%;
  }
}
</style>