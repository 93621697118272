<template>
  <v-container
    fluid
    class="d-flex flex-column align-center justify-center mt-3"
  >
    <div
      class="text--secondary d-flex justify-start align-center mb-3"
      style="width: 100%"
    >
      <v-btn class="ml-4" icon href="/exams">
        <v-icon color="#ccc">mdi-chevron-left</v-icon>
        <span style="font-size: 0.7rem; color: #999">inicio</span>
      </v-btn>
    </div>

    <Alert type="success" :message="message" v-show="showSuccess" />

    <v-card class="pa-5" min-width="40%">
      <div class="my-4">
        <h3 style="color: #f78129">MEUS DADOS</h3>
      </div>

      <v-form class="pa-5">
        <v-row justify="start" align="center">
          <span class="text">Nome: </span>

          <v-text-field
            class="mx-6 input"
            v-model="user.paC_NOME"
            disabled
          ></v-text-field>

          <strong class="notEditable"
            >NÃO <br />
            EDITAVEL</strong
          >
        </v-row>
        <v-divider class="my-5"></v-divider>

        <v-row justify="start" align="center">
          <span class="text">CPF: </span>

          <v-text-field
            class="mx-6 input"
            v-mask="['###.###.###-##']"
            v-model="user.paC_NUMCPF"
            disabled
          ></v-text-field>

          <strong class="notEditable"
            >NÃO <br />
            EDITAVEL</strong
          >
        </v-row>
        <v-divider class="my-5 mx-5"></v-divider>

        <v-row justify="start" align="center">
          <span class="text">Celular: </span>

          <v-text-field
            v-mask="['(##) ####-####', '(##) #####-####']"
            class="mx-6 input"
            v-model="user.paC_CELULAR"
          ></v-text-field>
        </v-row>
        <v-divider class="my-5"></v-divider>

        <v-row justify="start" align="center">
          <span class="text">E-mail: </span>

          <v-text-field
            class="mx-6 input"
            v-model="user.paC_Email"
          ></v-text-field>
        </v-row>
        <v-divider class="my-5"></v-divider>

        <v-row justify="start" align="center">
          <span class="text"
            >Data <br />
            Nasc.:
          </span>

          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                class="mx-6 input"
                :value="dateFormated"
                clearable
                v-bind="attrs"
                v-on="on"
                @click:clear="date = null"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="user.paC_NASC"
              @change="menu1 = false"
            ></v-date-picker>
          </v-menu>
        </v-row>
        <v-divider class="my-5"></v-divider>
      </v-form>

      <v-row justify="center" align="center" class="mb-2">
        <v-btn color="primary" class="button" :loading="loading" @click="update"
          >Salvar Alterações</v-btn
        >
      </v-row>
    </v-card>
  </v-container>
</template>

<style scoped>
.text {
  color: #888;
  font-size: 0.7rem;
}

.notEditable {
  color: #f78129;
  font-size: 0.6rem;
}

.input {
  font-size: 0.8rem;
  font-weight: bold;
}

.button {
  font-size: 0.7rem;
}

@media (max-width: 372px) {
  .input {
    max-width: 46%;
  }
}
</style>

<script>
import Alert from "../../../components/Alert.vue";
import moment from "moment";
import ApiService from "../../../services/ApiService";

export default {
  name: "MyData",

  created() {
    this.user = JSON.parse(sessionStorage.getItem("user"));
    this.user.paC_NASC = moment(this.user.paC_NASC).format("YYYY-MM-DD");
  },

  data: () => ({
    user: {},
    menu: null,
    loading: false,
    apiService: new ApiService(),
    message: "",
    showSuccess: false,
  }),

  components: {
    Alert,
  },

  computed: {
    dateFormated() {
      return this.user.paC_NASC
        ? moment(this.user.paC_NASC).format("DD/MM/YYYY")
        : "";
    },
  },

  methods: {
    update() {
      this.loading = true;

      let phone = this.user.paC_CELULAR.replace("(", "");
      phone = phone.replace(")", "");
      phone = phone.replace("-", "");
      phone = phone.replace(" ", "");
      this.user.paC_CELULAR = phone;

      this.apiService
        .post("user/edit", this.user)
        .then(() => {
          const user = JSON.stringify(this.user);
          this.$store.commit("SET_LOGGED_USER", user);
          this.$store.commit("SET_TOKEN_USER_SELECTED", user.userId);
          this.$store.commit("SET_USER_SELECTED", user);
          this.message = "Usuario atualizado";
          this.showSuccess = true;
          this.loading = false;

          setTimeout(() => {
            this.showSuccess = false;
          }, 4000);
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
