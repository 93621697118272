import { render, staticRenderFns } from "./Cotacoes.vue?vue&type=template&id=79e18a9a&scoped=true"
import script from "./Cotacoes.vue?vue&type=script&lang=js"
export * from "./Cotacoes.vue?vue&type=script&lang=js"
import style0 from "./Cotacoes.vue?vue&type=style&index=0&id=79e18a9a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79e18a9a",
  null
  
)

export default component.exports