<template>
  <v-container>
    <p class="text-body header">
      <b>Termos e Condi&ccedil;&otilde;es de Uso</b>
    </p>
    <p class="text-body">
      Estes s&atilde;o
      os Termos e Condi&ccedil;&otilde;es de Uso (&quot;Termos&quot;) da
      plataforma de software (&quot;Plataforma&quot;) desenvolvida pela
      KRISTAL EMPREENDIMENTOS E ASSESSORIA LTDA, pessoa jur&iacute;dica de
      direito privado inscrita no CNPJ/MF sob n&ordm; 49.200.391/0001-87,
      com sede na Cidade de Belo Horizonte, Estado de Minas Gerais, na Rua
      Gon&ccedil;alves Dias, n&ordm; 142, sala 902 B, Funcion&aacute;rios,
      CEP 31.140-090 (&ldquo;KRISTAL EMPREENDIMENTOS E ASSESSORIA
      LTDA&rdquo;,&quot;KRISTAL&quot;, &quot;N&oacute;s&quot;, &quot;Nossos&quot;),
      e regulam todas as condi&ccedil;&otilde;es aplic&aacute;veis ao uso
      dos Nossos servi&ccedil;os (&quot;Servi&ccedil;os&quot;).
    </p>
    <p class="text-body">
      Ao usar
      nossos Servi&ccedil;os, voc&ecirc; (&quot;Voc&ecirc;&quot;, &quot;Seu&quot;,
      &quot;Usu&aacute;rio&quot;) estar&aacute; sujeito aos presentes
      Termos. Leia-os com aten&ccedil;&atilde;o.
    </p>
    <p class="text-body">
      KriCon &eacute;
      um aplicativo desenvolvido para consolidar as aplica&ccedil;&otilde;es
      financeiras feitas em v&aacute;rias institui&ccedil;&otilde;es em um
      &uacute;nico local, fornecendo uma vis&atilde;o consolidada e
      comparativa das aplica&ccedil;&otilde;es financeiras segundo
      diferentes estrat&eacute;gias de aloca&ccedil;&atilde;o.
    </p>
    <p class="text-body">
      Criada
      para funcionar como uma solu&ccedil;&atilde;o &uacute;nica e moderna,
      com foco em escalabilidade, seguran&ccedil;a e performance para voc&ecirc;,
      seu acesso e uso ao nosso Site ou &agrave; Plataforma KriCon
      (respectivamente Site e Plataforma) s&atilde;o regidos pelos
      presentes termos e condi&ccedil;&otilde;es (&ldquo;Termos de Uso&rdquo;),
      em conjunto com nossa Pol&iacute;tica de Privacidade, que &eacute; o
      documento que regula o tratamento e prote&ccedil;&atilde;o de seus
      dados pessoais.
    </p>
    <p class="text-body">
      N&oacute;s
      somos uma plataforma de c&aacute;lculo, consolida&ccedil;&atilde;o e
      controle de carteira on-line. LEIA ATENTAMENTE O PRESENTE DOCUMENTO.
      SE VOC&Ecirc; N&Atilde;O CONCORDAR COM QUALQUER CONDI&Ccedil;&Atilde;O
      DESTES TERMOS OU DE NOSSA POL&Iacute;TICA DE PRIVACIDADE, N&Atilde;O
      CLIQUE EM &ldquo;ACEITO&rdquo; AO FINAL DO TEXTO. AO ACEITAR, VOC&Ecirc;
      RECONHECE A INTEGRIDADE DESTES TERMOS DE USO, EM CONJUNTO COM NOSSA
      POL&Iacute;TICA DE PRIVACIDADE, E CONSENTE COM O SEU TEOR DE FORMA
      LIVRE E ESPONT&Acirc;NEA.
    </p>
    <ol>
      <li>
        <p class="text-body">
          Das Defini&ccedil;&otilde;es
        </p>
      </li>
    </ol>
    <p class="text-body inset">
      Os termos
      constantes nestes Termos de Uso, sempre que usados com a primeira
      letra em mai&uacute;sculo, ter&atilde;o o significado estabelecido
      abaixo, seja no plural ou no singular:
    </p>
    <p class="text-body inset">
      <b>Institui&ccedil;&otilde;es</b>
      : Bancos, institui&ccedil;&otilde;es financeiras, seguradoras,
      corretoras de seguros, agentes reguladores, entre outros.
    </p>
    <p class="text-body inset">
      <b>Aceitar ou Aceite</b>
      : &eacute; o ato pelo qual o usu&aacute;rio declara, ao clicar na caixa
      de di&aacute;logo dos Termos de Uso e Pol&iacute;tica de Privacidade
      dispostas no Site ou na Plataforma, que leu e aceitou todos os termos
      e pol&iacute;tica de privacidade nele previstos. Sendo o ato
      considerado como um consentimento pr&eacute;vio e expresso em rela&ccedil;&atilde;o
      a todo o disposto em tais documentos.
    </p>
    <p class="text-body inset">
      <b>Conte&uacute;do</b>
      : significa toda e qualquer informa&ccedil;&atilde;o disponibilizada
      pelo ou por meio do Site ou da Plataforma, tais como textos, dados,
      software, imagens, v&iacute;deos, &aacute;udios, recursos
      interativos, n&uacute;meros, etc., incluindo-se os c&oacute;digos
      fontes empregados para exibi&ccedil;&atilde;o desses Conte&uacute;dos,
      como aqueles em linguagem HTML, CSS, PHP, entre outros.
    </p>
    <p class="text-body inset">
      <b>Conta KriCon</b>
      :
      significa a conta de acesso do Usu&aacute;rio &agrave; ferramenta de
      consolida&ccedil;&atilde;o de investimentos
      <b>KriCon</b>.
    </p>
    <p class="text-body inset">
      <b>Consultores Financeiros</b>
      : significa quaisquer profissionais e consultores financeiros, sejam
      estes aut&ocirc;nomos ou empregados, funcion&aacute;rios ou parceiros
      de um escrit&oacute;rio de investimentos, respons&aacute;vel pela
      consultoria de investimentos de clientes, que possam auxiliar,
      aconselhar, prestar informa&ccedil;&otilde;es ou interagir com
      Usu&aacute;rios no &acirc;mbito do Site ou Plataforma.
    </p>
    <p class="text-body inset">
      <b>Dados de Conta</b>
      : significa qualquer informa&ccedil;&atilde;o que o Usu&aacute;rio
      forne&ccedil;a &agrave; KRISTAL EMPREENDIMENTOS E ASSESSORIA LTDA,
      tais como, mas n&atilde;o se limitando, a dados da institui&ccedil;&atilde;o
      financeira, n&uacute;mero de ag&ecirc;ncia banc&aacute;ria, n&uacute;mero
      de conta, senha de acesso online da institui&ccedil;&atilde;o
      banc&aacute;ria, bem como quaisquer dados de contas correntes,
      investimentos, im&oacute;veis, bens, contas poupan&ccedil;a e/ou
      cart&otilde;es de cr&eacute;dito dos Usu&aacute;rios.
    </p>
    <p class="text-body inset">
      <b>Dados Financeiros Pessoais</b>
      : significa todos os dados do usu&aacute;rio obtidos pela KRISTAL
      EMPREENDIMENTOS E ASSESSORIA LTDA diretamente nos sites das Institui&ccedil;&otilde;es
      ou junto aos Consultores Financeiros, em rela&ccedil;&atilde;o &agrave;
      situa&ccedil;&atilde;o financeira dos usu&aacute;rios, al&eacute;m de
      outras necess&aacute;rias para o exerc&iacute;cio das atividades de
      gerenciamento dos investimentos do Usu&aacute;rio na Conta KriCon.
    </p>
    <p class="text-body inset">
      <b>Dados Pessoais</b>
      : significa qualquer informa&ccedil;&atilde;o que possa identificar o
      Usu&aacute;rio, bem como qualquer outra pessoa natural, direta ou
      indiretamente (tais como, mas n&atilde;o se limitando a, nome,
      endere&ccedil;o, n&uacute;mero de telefone, n&uacute;mero de fax ou
      endere&ccedil;o de e-mail, dados relacionados a pagamentos como dados
      de cart&atilde;o de cr&eacute;dito e Dados Pessoais Financeiros
      quando pass&iacute;veis de identificar o Usu&aacute;rio).
    </p>
    <p class="text-body inset">
      <b>Legisla&ccedil;&atilde;o Aplic&aacute;vel de Prote&ccedil;&atilde;o de Dados</b>
      : trata-se da Lei Geral de Prote&ccedil;&atilde;o de Dados Pessoais
      (Lei N&ordm; 13.709/2018 ou &ldquo;LGPD&rdquo;), suas altera&ccedil;&otilde;es
      posteriores, e quaisquer outras leis e regulamentos em rela&ccedil;&atilde;o
      ao Tratamento, prote&ccedil;&atilde;o e privacidade de Dados Pessoais
      que sejam aplic&aacute;veis e, se aplic&aacute;veis, todas as
      orienta&ccedil;&otilde;es, normas, regras, portarias, regulamentos e
      c&oacute;digos de pr&aacute;tica e conduta emitidos pela Autoridade
      Nacional de Prote&ccedil;&atilde;o de Dados (ANPD) ou outra
      autoridade de supervis&atilde;o ou prote&ccedil;&atilde;o de dados
      pertinente.
    </p>
    <p class="text-body inset">
      <b>Plataforma</b>
      : significa o software de propriedade exclusiva da KRISTAL
      EMPREENDIMENTOS E ASSESSORIA LTDA, que utiliza o servi&ccedil;o de
      parceiros para a coleta e processamento de dados, que possuem
      pol&iacute;ticas de uso e privacidade pr&oacute;prias. Recomendamos
      que voc&ecirc; leia, entenda e concorde com essas pol&iacute;ticas
      antes de aceitar os Servi&ccedil;os. &Eacute; utilizado o sistema de
      Open Finance, APIs e outros, para manejar todos os dados dos
      investimentos e movimenta&ccedil;&otilde;es financeiras do Usu&aacute;rio,
      sempre de maneira automatizada, por meio da Conta KriCon, e que ser&aacute;
      acessado pelos Usu&aacute;rios.
    </p>
    <p class="text-body inset emph">
      Pol&iacute;tica
      de Privacidade: significa a pol&iacute;tica de privacidade que rege
      as disposi&ccedil;&otilde;es sobre a utiliza&ccedil;&atilde;o dos
      dados do Usu&aacute;rio que pode ser encontrada no seguinte link.
      XXXXXXXXXXXXXX
    </p>
    <p class="text-body inset">
      <b>Site</b>
      : &eacute; o endere&ccedil;o eletr&ocirc;nico
      https://kristalconnect.com.br/kricon/ ou qualquer outro que vier a
      substitu&iacute;-lo.
    </p>
    <p class="text-body inset">
      <b>Subscri&ccedil;&atilde;o</b>
      : significa uma assinatura paga de subscri&ccedil;&atilde;o que
      possibilitar&aacute; ao Usu&aacute;rio ter acesso a funcionalidades
      exclusivas dentro da Plataforma;
    </p>
    <p class="text-body inset">
      <b>Taxa de Subscri&ccedil;&atilde;o</b>
      : significa a taxa paga pelo Usu&aacute;rio para contratar uma
      Subscri&ccedil;&atilde;o;
    </p>
    <p class="text-body inset">
      <b>Tratamento</b>
      : qualquer opera&ccedil;&atilde;o ou conjunto de opera&ccedil;&otilde;es
      realizadas com seus Dados Pessoais ou conjuntos de Dados Pessoais,
      seja por meios automatizados ou n&atilde;o, tais como, mas n&atilde;o
      se limitando a, coleta, uso, acesso, organiza&ccedil;&atilde;o,
      consulta, produ&ccedil;&atilde;o, altera&ccedil;&atilde;o, recep&ccedil;&atilde;o,
      classifica&ccedil;&atilde;o, utiliza&ccedil;&atilde;o, reprodu&ccedil;&atilde;o,
      comunica&ccedil;&atilde;o, transmiss&atilde;o, distribui&ccedil;&atilde;o,
      processamento, arquivamento, registro, estrutura&ccedil;&atilde;o,
      armazenamento, adapta&ccedil;&atilde;o, recupera&ccedil;&atilde;o,
      transfer&ecirc;ncia, disponibiliza&ccedil;&atilde;o, combina&ccedil;&atilde;o,
      restri&ccedil;&atilde;o, elimina&ccedil;&atilde;o, avalia&ccedil;&atilde;o
      ou controle, modifica&ccedil;&atilde;o, elimina&ccedil;&atilde;o ou
      extra&ccedil;&atilde;o.
    </p>
    <p class="text-body inset">
      <b>Usu&aacute;rio</b>
      : significa uma pessoa f&iacute;sica, maior de idade, ou jur&iacute;dica,
      com plena capacidade de contratar, que busca o aux&iacute;lio,
      aconselhamento, informa&ccedil;&otilde;es ou interagir com o Site ou
      Plataforma e realiza o seu cadastro pessoal de modo a usufruir das
      funcionalidades oferecidas pela KRISTAL EMPREENDIMENTOS E ASSESSORIA
      LTDA por meio de sua Conta KriCon, aderindo desta forma
      automaticamente aos Termos de Uso do Usu&aacute;rio e &agrave; nossa
      Pol&iacute;tica de Privacidade.
    </p>
    <p class="text-body inset">
      <b>Open Finance</b>
      : o compartilhamento padronizado de dados e servi&ccedil;os por meio de
      APIs (Application Programming Interfaces) por parte de institui&ccedil;&otilde;es
      financeiras e demais institui&ccedil;&otilde;es autorizadas a
      funcionar pelo Banco Central do Brasil. No caso de dados de clientes
      (pessoa f&iacute;sica ou jur&iacute;dica) &eacute; o cliente que
      decidir&aacute; quando e com quem ele deseja compartilh&aacute;-los
      no escopo do Open Finance, desde que seja com finalidades espec&iacute;ficas
      e prazos determinados.
    </p>
    <ol start="2">
      <li>
        <p class="text-body">
          Da Ades&atilde;o
        </p>
      </li>
    </ol>
    <p class="text-body inset">
      Para a
      ades&atilde;o e utiliza&ccedil;&atilde;o das funcionalidades da
      Plataforma, &eacute; obrigat&oacute;ria a leitura, compreens&atilde;o
      e Aceite dos presentes Termos de Uso e da Pol&iacute;tica de
      Privacidade pelo Usu&aacute;rio. A partir do momento em que o Usu&aacute;rio
      Aceitar os Termos de Uso e a Pol&iacute;tica de Privacidade, as
      regras neles contidas ir&atilde;o regular plenamente a rela&ccedil;&atilde;o
      entre a KRISTAL EMPREENDIMENTOS E ASSESSORIA LTDA e o Usu&aacute;rio.
    </p>
    <p class="text-body inset">
      Ser&aacute;
      dever do Usu&aacute;rio manter-se atento a poss&iacute;veis
      atualiza&ccedil;&otilde;es dos Termos de Uso e da Pol&iacute;tica de
      Privacidade, que podem ocorrer a qualquer tempo.
    </p>
    <ol start="3">
      <li>
        <p class="text-body">
          Comunica&ccedil;&atilde;o
          com a KRISTAL EMPREENDIMENTOS E ASSESSORIA LTDA
        </p>
      </li>
    </ol>
    <p class="text-body inset">
      Para qualquer quest&atilde;o relacionada a estes Termos de Uso, &agrave;
      Pol&iacute;tica de Privacidade ou ao uso do Site/Conte&uacute;do/Software,
      o Usu&aacute;rio deve entrar em contato com a KRISTAL EMPREENDIMENTOS
      E ASSESSORIA LTDA pelo e-mail contato@kristalconnect.com.br.
    </p>
    <ol start="4">
      <li>
        <p class="text-body">
          Utiliza&ccedil;&atilde;o da Plataforma
        </p>
      </li>
    </ol>
    <p class="text-body inset">
      Estes Termos de Uso concedem ao Usu&aacute;rio uma licen&ccedil;a pessoal,
      mundial, revog&aacute;vel, n&atilde;o exclusiva e intransfer&iacute;vel
      para uso do software relacionado &agrave; Plataforma, sem cobran&ccedil;a
      de royalties. O Usu&aacute;rio n&atilde;o pode utilizar ou permitir o
      uso deste software para qualquer finalidade n&atilde;o expressamente
      prevista nestes Termos de Uso. &Eacute; proibido ao Usu&aacute;rio
      copiar, modificar, distribuir, vender ou alugar os servi&ccedil;os
      disponibilizados pela KRISTAL EMPREENDIMENTOS E ASSESSORIA LTDA
      atrav&eacute;s da Plataforma.
    </p>
    <p class="text-body inset">
      Ao contratar o aplicativo desenvolvido para consolidar as aplica&ccedil;&otilde;es
      financeiras KriCon, o Usu&aacute;rio fornecer&aacute; ou permitir&aacute;
      o acesso da KRISTAL EMPREENDIMENTOS E ASSESSORIA LTDA aos seus Dados
      Pessoais para a cria&ccedil;&atilde;o de um nome de usu&aacute;rio
      (&ldquo;Login&rdquo;) e senha, que ser&atilde;o utilizados para
      acessar a Conta KriCon do Usu&aacute;rio.
    </p>
    <p class="text-body inset">
      Para usar o Site ou a Plataforma, o Usu&aacute;rio deve ser maior de idade
      conforme a legisla&ccedil;&atilde;o brasileira e capaz de exercer
      direitos e deveres de acordo com o C&oacute;digo Civil. &Eacute;
      proibido o acesso de menores de 18 anos n&atilde;o emancipados e de
      incapazes ao Site ou &agrave; Plataforma.
    </p>
    <p class="text-body inset">
      O Usu&aacute;rio
      &eacute; respons&aacute;vel pela veracidade, validade e precis&atilde;o
      de todos os Dados Pessoais, incluindo Dados de Conta, fornecidos para
      o cadastro, bem como por manter o cadastro atualizado com um endere&ccedil;o
      de e-mail v&aacute;lido para medidas de seguran&ccedil;a e valida&ccedil;&atilde;o
      de acesso eventualmente utilizadas pela KRISTAL EMPREENDIMENTOS E
      ASSESSORIA LTDA.
    </p>
    <p class="text-body inset">
      A KRISTAL
      EMPREENDIMENTOS E ASSESSORIA LTDA n&atilde;o tem obriga&ccedil;&atilde;o
      de policiar, validar ou fiscalizar os Dados Pessoais ou de Conta
      fornecidos pelos Usu&aacute;rios, mas poder&aacute;, a seu crit&eacute;rio,
      excluir informa&ccedil;&otilde;es que pare&ccedil;am inver&iacute;dicas,
      ofensivas ou que possam expor a KRISTAL EMPREENDIMENTOS E ASSESSORIA
      LTDA, seus parceiros e demais Usu&aacute;rios a riscos.
    </p>
    <p class="text-body inset">
      A senha e o Login criados pelo Usu&aacute;rio para acesso &agrave; sua Conta
      KriCon s&atilde;o confidenciais e de responsabilidade exclusiva do
      Usu&aacute;rio, que deve entrar em contato com a KRISTAL
      EMPREENDIMENTOS E ASSESSORIA LTDA imediatamente em caso de
      comprometimento do sigilo. A KRISTAL EMPREENDIMENTOS E ASSESSORIA
      LTDA n&atilde;o recomenda o uso da mesma senha para diversos sites ou
      a inser&ccedil;&atilde;o de Dados Pessoais ou Dados de Conta como
      parte da senha.
    </p>
    <p class="text-body inset">
      Ao fornecer seus Dados Pessoais e Dados de Conta &agrave; KRISTAL
      EMPREENDIMENTOS E ASSESSORIA LTDA, o Usu&aacute;rio autoriza a
      KRISTAL EMPREENDIMENTOS E ASSESSORIA LTDA a utilizar o servi&ccedil;o
      de parceiros para a coleta e processamento de seus Dados Financeiros
      Pessoais junto a cada institui&ccedil;&atilde;o financeira,
      Consultores Financeiros e/ou corretora informada pelo Usu&aacute;rio.
      A KRISTAL EMPREENDIMENTOS E ASSESSORIA LTDA utilizar&aacute; a
      Plataforma para organizar esses dados, visando a presta&ccedil;&atilde;o
      de servi&ccedil;os e oferta de produtos ao Usu&aacute;rio, sempre de
      acordo com os Termos de Uso, a Legisla&ccedil;&atilde;o Aplic&aacute;vel
      de Prote&ccedil;&atilde;o de Dados e a Pol&iacute;tica de
      Privacidade.
    </p>
    <p class="text-body inset">
      O uso da
      Plataforma pelo Usu&aacute;rio &eacute; pessoal e intransfer&iacute;vel,
      exclusivamente para fins l&iacute;citos relacionados ao prop&oacute;sito
      da Plataforma, conforme descrito nestes Termos de Uso. Em nenhuma
      hip&oacute;tese, o Usu&aacute;rio ter&aacute; acesso ao c&oacute;digo
      fonte do software que hospeda a Plataforma, de propriedade exclusiva
      da KRISTAL EMPREENDIMENTOS E ASSESSORIA LTDA.
    </p>
    <ol start="5">
      <li>
        <p class="text-body">
          Dados de Conta
        </p>
      </li>
    </ol>
    <p class="text-body inset">
      Para a funcionalidade completa do Site e da Plataforma, o Usu&aacute;rio
      far&aacute; o acesso pessoalmente as suas contas obtendo seus Dados
      Financeiros Pessoais dispon&iacute;veis no Open Finance, APIs ou
      outros disponibilizados pelas Institui&ccedil;&otilde;es.
    </p>
    <p class="text-body inset">
      Todas as
      senhas e/ou c&oacute;digos de autoriza&ccedil;&atilde;o, para esta
      funcionalidade, n&atilde;o ser&atilde;o solicitados pela KRISTAL
      EMPREENDIMENTOS E ASSESSORIA LTDA. O acesso aos dados dispon&iacute;veis
      no Open Finance do Usu&aacute;rio ser&atilde;o utilizados apenas
      conforme os termos da Pol&iacute;tica de Privacidade.
    </p>
    <p class="text-body inset">
      A KRISTAL
      EMPREENDIMENTOS E ASSESSORIA LTDA n&atilde;o se responsabiliza pela
      revis&atilde;o, veracidade e integridade dos Dados de Conta
      fornecidos pelo Usu&aacute;rio, bem como pelos Dados Financeiros
      Pessoais obtidos nos sites das Institui&ccedil;&otilde;es ou via Open Finance
      , no que se refere &agrave; precis&atilde;o dos dados, legalidade ou
      amea&ccedil;a de viola&ccedil;&atilde;o devido ao compartilhamento
      desses dados.
    </p>
    <p class="text-body inset">
      O Usu&aacute;rio
      reconhece que a KRISTAL EMPREENDIMENTOS E ASSESSORIA LTDA n&atilde;o
      &eacute; uma extens&atilde;o ou associa&ccedil;&atilde;o das Institui&ccedil;&otilde;es
      de onde os Dados Financeiros Pessoais s&atilde;o extra&iacute;dos, n&atilde;o
      sendo respons&aacute;vel pelos produtos e/ou servi&ccedil;os
      oferecidos por eles.
    </p>
    <p class="text-body inset">
      O acesso
      aos Dados de Conta e aos Dados Financeiros Pessoais do Usu&aacute;rio
      depende de servi&ccedil;os prestados pelas Institui&ccedil;&otilde;es.
      A KRISTAL EMPREENDIMENTOS E ASSESSORIA LTDA n&atilde;o assume
      responsabilidade quanto &agrave; pontualidade, precis&atilde;o,
      integridade, n&atilde;o entrega ou falha na obten&ccedil;&atilde;o
      desses dados devido a problemas t&eacute;cnicos ou dificuldades de
      conex&atilde;o.
    </p>
    <p class="text-body inset">
      O Usu&aacute;rio
      concorda em pagar a taxa da Subscri&ccedil;&atilde;o conforme
      indicado na Plataforma para utiliza&ccedil;&atilde;o da importa&ccedil;&atilde;o
      autom&aacute;tica dos Dados Financeiros Pessoais do Usu&aacute;rio de
      sua Conta KriCon.
    </p>
    <ol start="6">
      <li>
        <p class="text-body">
          Limita&ccedil;&otilde;es de Uso e Interfer&ecirc;ncia
        </p>
        <ol>
          <li>
            <p class="text-body">
              &Eacute; vedado ao Usu&aacute;rio:
            </p>
            <ol>
              <li>
                <p class="text-body">
                  - Utilizar o Site ou a Plataforma para divulgar informa&ccedil;&otilde;es
                  que violem normas aplic&aacute;veis no Brasil, direitos da KRISTAL
                  EMPREENDIMENTOS E ASSESSORIA LTDA ou de terceiros, incluindo, sem
                  limita&ccedil;&atilde;o, a viola&ccedil;&atilde;o de direitos de
                  propriedade intelectual, direitos autorais e de privacidade, ou
                  divulgar conte&uacute;do ilegal, imoral, inapropriado ou ofensivo.
                </p>
              </li>
              <li>
                <p class="text-body">
                  - Copiar, ceder, sublicenciar, vender, dar em loca&ccedil;&atilde;o,
                  reproduzir, doar, alienar, transferir, total ou parcialmente,
                  gratuita ou onerosamente, provis&oacute;ria ou permanentemente, o
                  software da Plataforma, assim como seus m&oacute;dulos, partes,
                  manuais ou quaisquer outros direitos de propriedade intelectual da
                  KRISTAL EMPREENDIMENTOS E ASSESSORIA LTDA.
                </p>
              </li>
              <li>
                <p class="text-body">
                  - Promover ou permitir descompila&ccedil;&atilde;o, desmontagem ou
                  engenharia reversa do software da Plataforma, desenvolver novos
                  softwares ou aplicativos baseados em informa&ccedil;&otilde;es
                  extra&iacute;das do Site ou da Plataforma, ou em dados inseridos
                  no Site ou na Plataforma.
                </p>
              </li>
              <li>
                <p class="text-body">
                  - Utilizar softwares, t&eacute;cnicas e/ou artif&iacute;cios para
                  usar indevidamente o Site, o software ou a Plataforma para
                  pr&aacute;ticas nocivas &agrave; KRISTAL EMPREENDIMENTOS E
                  ASSESSORIA LTDA ou a terceiros.
                </p>
              </li>
              <li>
                <p class="text-body">
                  - Reproduzir, adaptar, modificar e/ou usar, no todo ou em parte, o
                  software ou qualquer Conte&uacute;do do Site ou da Plataforma sem
                  autoriza&ccedil;&atilde;o expressa da KRISTAL EMPREENDIMENTOS E
                  ASSESSORIA LTDA.
                </p>
              </li>
              <li>
                <p class="text-body">
                  -
                  Publicar ou transmitir arquivos que contenham v&iacute;rus, worms,
                  cavalos de troia ou qualquer outro programa contaminante ou
                  destrutivo, ou que interfiram no bom funcionamento do Site,
                  Plataforma ou software da Plataforma.
                </p>
              </li>
              <li>
                <p class="text-body">
                  - Utilizar o software, Site ou Plataforma para finalidade diversa
                  daquela para a qual foi disponibilizado pela KRISTAL
                  EMPREENDIMENTOS E ASSESSORIA LTDA.
                </p>
              </li>
              <li>
                <p class="text-body">
                  - Divulgar, utilizar ou modificar indevidamente os dados de outros
                  Usu&aacute;rios.
                </p>
              </li>
              <li>
                <p class="text-body">
                  - Permitir o uso n&atilde;o autorizado da Plataforma, do software ou
                  do Site para benef&iacute;cio de terceiros.
                </p>
              </li>
            </ol>
          </li>
        </ol>
      </li>
      <li>
        <p class="text-body">
          Pol&iacute;tica de Privacidade
        </p>
      </li>
    </ol>
    <p class="text-body inset">
      Ao se cadastrar na KriCon, o Usu&aacute;rio concorda com a Pol&iacute;tica
      de Privacidade. O Aceite da Pol&iacute;tica de Privacidade autoriza a
      KRISTAL EMPREENDIMENTOS E ASSESSORIA LTDA a usar os Dados Pessoais do
      Usu&aacute;rio conforme descrito no respectivo documento.
    </p>
    <ol start="8">
      <li>
        <p class="text-body">
          Propriedade Intelectual
        </p>
      </li>
    </ol>
    <p class="text-body inset">
      Os Usu&aacute;rios n&atilde;o adquirem, por meio destes Termos de Uso ou
      da Pol&iacute;tica de Privacidade, nenhum direito de propriedade
      intelectual sobre os softwares, a Plataforma e/ou o Site, que s&atilde;o
      de propriedade exclusiva da KRISTAL EMPREENDIMENTOS E ASSESSORIA
      LTDA.
    </p>
    <p class="text-body inset">
      Caso o Usu&aacute;rio ou qualquer outro venha a desenvolver um novo m&oacute;dulo,
      aprimoramento, melhoria ou produto semelhante ou complementar aos
      softwares da KRISTAL EMPREENDIMENTOS E ASSESSORIA LTDA, ao Site ou &agrave;
      Plataforma, todos os direitos de propriedade intelectual ser&atilde;o
      incorporados pela KRISTAL EMPREENDIMENTOS E ASSESSORIA LTDA, com uso
      condicionado a estas cl&aacute;usulas contratuais. O Usu&aacute;rio
      se compromete a assinar os documentos necess&aacute;rios e fornecer
      informa&ccedil;&otilde;es solicitadas pela KRISTAL EMPREENDIMENTOS E
      ASSESSORIA LTDA para formalizar a titularidade desses direitos pela
      KRISTAL EMPREENDIMENTOS E ASSESSORIA LTDA.
    </p>
    <p class="text-body inset">
      O Usu&aacute;rio est&aacute; autorizado a fazer download ou imprimir uma c&oacute;pia
      das informa&ccedil;&otilde;es, planilhas e gr&aacute;ficos presentes
      no Site ou na Plataforma para uso pessoal, sendo vedado qualquer
      outro tipo de uso sem o consentimento pr&eacute;vio e expresso da
      KRISTAL EMPREENDIMENTOS E ASSESSORIA LTDA.
    </p>
    <p class="text-body inset">
      Todo Conte&uacute;do disponibilizado no Site ou na Plataforma, como
      textos, gr&aacute;ficos, imagens, logos, &iacute;cones, fotografias,
      conte&uacute;do editorial, notifica&ccedil;&otilde;es, softwares e
      qualquer outro material, pertence exclusivamente &agrave; KRISTAL
      EMPREENDIMENTOS E ASSESSORIA LTDA e &eacute; protegido pela lei
      brasileira de propriedade intelectual e direitos autorais.
    </p>
    <p class="text-body inset">
      Quanto aos Dados Pessoais, incorporados aos Dados de Conta ou Dados
      Financeiros Pessoais, o Usu&aacute;rio entende e concorda que a
      KRISTAL EMPREENDIMENTOS E ASSESSORIA LTDA atuar&aacute; como seu
      representante perante os sites das Institui&ccedil;&otilde;es e
      Consultores Financeiros.
    </p>
    <p class="text-body inset">
      O aceite dos Termos de Uso e da Pol&iacute;tica de Privacidade implica no
      consentimento expresso do Usu&aacute;rio para que a KRISTAL
      EMPREENDIMENTOS E ASSESSORIA LTDA acesse e utilize seus Dados
      Pessoais, incluindo Dados de Conta e Dados Financeiros Pessoais
      fornecidos atrav&eacute;s desses sites ou inseridos na Plataforma ou
      no Site.
    </p>
    <p class="text-body inset">
      Caso o
      Usu&aacute;rio identifique que qualquer conte&uacute;do dispon&iacute;vel
      atrav&eacute;s da Plataforma ou do Site viola direitos autorais de
      sua propriedade, deve entrar em contato com a KRISTAL EMPREENDIMENTOS
      E ASSESSORIA LTDA atrav&eacute;s do e-mail
      <u><a href="mailto:contato@kristalconnect.com.br">
          contato@kristalconnect.com.br</a></u>.
    </p>
    <ol start="9">
      <li>
        <p class="text-body">
          Suspens&otilde;es,
          Restri&ccedil;&otilde;es e Modifica&ccedil;&otilde;es no Site
        </p>
      </li>
    </ol>
    <p class="text-body adjust">
      9.1 A KRISTAL EMPREENDIMENTOS E ASSESSORIA LTDA reserva-se o direito
      exclusivo de, a qualquer momento e sem necessidade de aviso pr&eacute;vio
      ao Usu&aacute;rio:
    </p>
    <p class="text-body adjust">
      9.1.1 - Encerrar, modificar ou suspender total ou parcialmente o acesso do
      Usu&aacute;rio ao Site ou &agrave; Plataforma, caso o acesso ou uso
      viole as condi&ccedil;&otilde;es estabelecidas nos Termos de Uso e/ou
      na Pol&iacute;tica de Privacidade.
    </p>
    <p class="text-body adjust">
      9.1.2 - Excluir total ou parcialmente informa&ccedil;&otilde;es cadastradas
      pelo Usu&aacute;rio que n&atilde;o estejam em conformidade com as
      disposi&ccedil;&otilde;es dos Termos de Uso.
    </p>
    <p class="text-body adjust">
      9.1.3 - Adicionar, excluir ou modificar o Conte&uacute;do oferecido pela
      KRISTAL EMPREENDIMENTOS E ASSESSORIA LTDA, bem como as
      funcionalidades dispon&iacute;veis no Site ou Plataforma, com o
      objetivo de melhorar a qualidade dos servi&ccedil;os prestados. A
      KRISTAL EMPREENDIMENTOS E ASSESSORIA LTDA n&atilde;o se obriga a
      manter ativa qualquer funcionalidade ou servi&ccedil;o oferecido
      anteriormente.
    </p>
    <p class="text-body adjust">
      9.2 Adicionalmente,
      a KRISTAL EMPREENDIMENTOS E ASSESSORIA LTDA poder&aacute;, a seu
      crit&eacute;rio exclusivo e mediante aviso pr&eacute;vio ao Usu&aacute;rio:
    </p>
    <p class="text-body adjust">
      9.2.1 - Suspender, modificar ou encerrar as atividades do Site ou da
      Plataforma, exceto em casos de caso fortuito ou for&ccedil;a maior.
    </p>
    <p class="text-body adjust">
      9.2.2 - Definir pre&ccedil;os para determinados conte&uacute;dos e/ou
      servi&ccedil;os, mesmo que inicialmente oferecidos gratuitamente, ou
      precificar novos ambientes ou funcionalidades. O uso continuado pelo
      Usu&aacute;rio ap&oacute;s o aviso ser&aacute; considerado como
      concord&acirc;ncia com os novos pre&ccedil;os.
    </p>
    <p class="text-body adjust">
      10. A subscri&ccedil;&atilde;o no KriCon implica na ades&atilde;o a um
      plano espec&iacute;fico mediante o pagamento das Taxas de Subscri&ccedil;&atilde;o
      correspondentes. Essas taxas devem ser quitadas antecipadamente,
      utilizando cart&atilde;o de cr&eacute;dito ou outro meio dispon&iacute;vel
      na plataforma, podendo ser mensal, anual ou em outra periodicidade
      conforme o plano selecionado.
    </p>
    <ol start="11">
      <li>
        <p class="text-body">
          A KRISTAL EMPREENDIMENTOS E ASSESSORIA LTDA reserva-se o direito de
          ajustar as Taxas de Subscri&ccedil;&atilde;o mediante aviso pr&eacute;vio
          aos usu&aacute;rios.
        </p>
      </li>
      <li>
        <p class="text-body">
          Os planos de Subscri&ccedil;&atilde;o renovam-se automaticamente ao
          t&eacute;rmino de cada per&iacute;odo contratado, exceto se houver
          cancelamento pelo usu&aacute;rio com anteced&ecirc;ncia. N&atilde;o
          h&aacute; reembolso caso o cancelamento ocorra ap&oacute;s a
          cobran&ccedil;a para o novo per&iacute;odo.
        </p>
      </li>
      <li>
        <p class="text-body">
          Durante a vig&ecirc;ncia do plano contratado, &eacute; poss&iacute;vel
          migrar entre planos, sem direito a reembolso pelos dias n&atilde;o
          utilizados. O acesso &agrave;s funcionalidades &eacute; liberado em
          at&eacute; 72 horas ap&oacute;s confirma&ccedil;&atilde;o do
          pagamento.
        </p>
      </li>
      <li>
        <p class="text-body">
          A KRISTAL EMPREENDIMENTOS E ASSESSORIA LTDA n&atilde;o assume
          responsabilidade por decis&otilde;es financeiras tomadas com base
          nas informa&ccedil;&otilde;es do site ou plataforma, nem por falhas
          de seguran&ccedil;a n&atilde;o sob seu controle direto.
        </p>
      </li>
      <li>
        <p class="text-body">
          Esses Termos de Uso s&atilde;o regidos pelas leis brasileiras, com foro
          exclusivo na Comarca de Belo Horizonte, e foram atualizados pela
          &uacute;ltima vez em 06 de junho de 2024.
        </p>
      </li>
    </ol>

    <hr />

    <p class="text-body header">
      <span>Política de Privacidade</span>
    </p>
    <p class="text-body extra">
      <span>Na
        Kristal, a privacidade e segurança são prioridades e
        nos comprometemos com a transparência do tratamento de dados
        pessoais dos nossos usuários/clientes. Por isso, esta presente
        Política de Privacidade estabelece como é feita a
        coleta, uso e transferência de informações de
        clientes ou outras pessoas que acessam ou usam nosso Consolidador.</span>
    </p>
    <p class="text-body extra">
      <span>Ao
        utilizar nossos serviços, você entende que coletaremos e
        usaremos suas informações pessoais nas formas descritas
        nesta Política, sob as normas da Constituição
        Federal de 1988 (art. 5º, LXXIX; e o art. 22º, XXX –
        incluídos pela EC 115/2022), das normas de Proteção
        de Dados (LGPD, Lei Federal 13.709/2018), das disposições
        consumeristas da Lei Federal 8078/1990 e as demais normas do
        ordenamento jurídico brasileiro aplicáveis.</span>
    </p>
    <p class="text-body extra">
      <span>
        Dessa
        forma, a </span>KRISTAL EMPREENDIMENTOS E ASSESSORIA LTDA<span>,
        doravante denominada simplesmente como “Kristal”,
        inscrita no CNPJ/MF sob o nº 49.200.391/0001-87, no papel de
        Controladora de Dados, obriga-se ao disposto na presente Política
        de Privacidade.
      </span>
    </p>
    <br />
    <br />
    <p class="text-body extra">
      <span>1.
        Quais dados coletamos sobre você e para qual finalidade?</span>
    </p>
    <p class="text-body extra">
      <span>Nosso
        consolidador coleta e utiliza alguns dados pessoais e financeiros
        seus, de forma a viabilizar a prestação de serviços
        e aprimorar a experiência de uso.</span>
    </p>
    <p class="text-body extra">
      <span>1.1.
        Dados pessoais fornecidos pelo titular</span>
    </p>
    <ul>
      <li>
        <p class="text-body extra">
          <span>Dados
            cadastrais como nome, CPF e comprovante de residência para
            fins de cadastro.</span>
        </p>
      </li>
    </ul>
    <p class="text-body extra">
      <span>1.2.
        Dados financeiros coletados automaticamente</span>
    </p>
    <ul>
      <li>
        <p class="text-body extra">
          <span>Dados
            financeiros como investimentos em instituições
            financeiras reguladas pelo Banco Central do Brasil </span>
        </p>
      </li>
    </ul>
    <p class="text-body extra">
      <span>2.
        Como coletamos os seus dados?</span>
    </p>
    <ul>
      <li>
        <p class="text-body extra">
          <span>Os
            dados serão coletados automaticamente através do “open
            finance” pela “Instituição
            transmissora/receptora de dados” para fins de consolidação
            dos investimentos.</span>
        </p>
      </li>
    </ul>
    <p class="text-body extra">
      <span>2.1.
        Consentimento</span>
    </p>
    <ul>
      <li>
        <p class="text-body extra">
          <span>O
            seu consentimento é dado para a instituição de
            origem dos dados. O consentimento é a manifestação
            livre, informada e inequívoca pela qual você autoriza a
            “Instituição transmissora/receptora de dados”
            a coletar seus dados e transferir para a Kristal onde iremos
            consolidar os dados no consolidador KriCon.</span>
        </p>
      </li>
    </ul>
    <p class="text-body extra">
      <span>Assim,
        em consonância com a Lei Geral de Proteção de
        Dados, seus dados só serão coletados, tratados e
        armazenados mediante prévio e expresso consentimento. </span>
    </p>
    <p class="text-body extra">
      <span>
        O seu consentimento será obtido de forma específica para
        cada finalidade acima descrita, evidenciando o compromisso de
        transparência e boa-fé da
      </span>
      KRISTAL EMPREENDIMENTOS E ASSESSORIA LTDA
      <span>
        para com seus usuários/clientes, seguindo as regulações
        legislativas pertinentes.
      </span>
    </p>
    <p class="text-body extra">
      <span>Ao
        utilizar os serviços da </span>KRISTAL EMPREENDIMENTOS E
      ASSESSORIA LTDA<span> e fornecer
        seus dados pessoais, você está ciente e consentindo com
        as disposições desta Política de Privacidade,
        além de conhecer seus direitos e como exercê-los.</span>
    </p>
    <p class="text-body extra">
      <span>A
        qualquer tempo e sem nenhum custo, você poderá revogar
        seu consentimento.</span>
    </p>
    <p class="text-body extra">
      <span>É
        importante destacar que a revogação do consentimento
        para o tratamento dos dados pode implicar a impossibilidade da
        performance adequada de alguma funcionalidade do site que dependa da
        operação. Tais consequências serão
        informadas previamente.</span>
    </p>
    <p class="text-body extra">
      <span>3.
        Quais são os seus direitos?</span>
    </p>
    <p class="text-body extra">
      <span>A
      </span>KRISTAL EMPREENDIMENTOS E ASSESSORIA LTDA<span>
        assegura a seus usuários/clientes seus direitos de titular
        previstos no artigo 18 da Lei Geral de Proteção de
        Dados. Dessa forma, você pode, de maneira gratuita e a qualquer
        tempo:</span>
    </p>
    <p class="text-body extra">
      <span>Confirmar
        a existência de tratamento de dados, de maneira simplificada ou
        em formato claro e completo.</span>
    </p>
    <p class="text-body extra">
      <span>Acessar
        seus dados, podendo solicitá-los em uma cópia legível
        sob forma impressa ou por meio eletrônico, seguro e idôneo.</span>
    </p>
    <p class="text-body extra">
      <span>Corrigir
        seus dados, ao solicitar a edição, correção
        ou atualização destes.</span>
    </p>
    <p class="text-body extra">
      <span>Limitar
        seus dados quando desnecessários, excessivos ou tratados em
        desconformidade com a legislação através da
        anonimização, bloqueio ou eliminação.</span>
    </p>
    <p class="text-body extra">
      <span>Solicitar
        a portabilidade de seus dados, através de um relatório
        de dados cadastrais que a </span>KRISTAL EMPREENDIMENTOS E ASSESSORIA
      LTDA<span> trata a seu respeito.</span>
    </p>
    <p class="text-body extra">
      <span>Eliminar
        seus dados tratados a partir de seu consentimento, exceto nos casos
        previstos em lei.</span>
    </p>
    <p class="text-body extra">
      <span>Revogar
        seu consentimento, desautorizando o tratamento de seus dados.</span>
    </p>
    <p class="text-body extra">
      <span>Informar-se
        sobre a possibilidade de não fornecer seu consentimento e
        sobre as consequências da negativa.</span>
    </p>
    <p class="text-body extra">
      <span>4.
        Como você pode exercer seus direitos de titular?</span>
    </p>
    <p class="text-body extra">
      <span>Para
        exercer seus direitos de titular, você deve entrar em contato
        com a </span>KRISTAL EMPREENDIMENTOS E ASSESSORIA LTDA<span>
        através dos seguintes meios disponíveis:</span>
    </p>
    <p class="text-body extra">
      <span>contato@kricom.com.br</span>
    </p>
    <p class="text-body extra">
      <span>De
        forma a garantir a sua correta identificação como
        titular dos dados pessoais objeto da solicitação, é
        possível que solicitemos documentos ou demais comprovações
        que possam comprovar sua identidade. Nessa hipótese, você
        será informado previamente.</span>
    </p>
    <p class="text-body extra">
      <span>5.
        Como e por quanto tempo seus dados serão armazenados?</span>
    </p>
    <p class="text-body extra">
      <span>Seus
        dados pessoais coletados pela </span>KRISTAL EMPREENDIMENTOS E
      ASSESSORIA LTDA<span> serão
        utilizados e armazenados durante o tempo necessário para a
        prestação do serviço ou para que as finalidades
        elencadas na presente Política de Privacidade sejam atingidas,
        considerando os direitos dos titulares dos dados e dos controladores.</span>
    </p>
    <br />
    <br />
    <p class="text-body extra">
      <span>De
        modo geral, seus dados serão mantidos enquanto a relação
        contratual entre você e a </span>KRISTAL EMPREENDIMENTOS E
      ASSESSORIA LTDA<span> perdurar.
        Findado o período de armazenamento dos dados pessoais, estes
        serão excluídos de nossas bases de dados ou
        anonimizados, ressalvadas as hipóteses legalmente previstas no
        artigo 16 lei geral de proteção de dados, a saber:</span>
    </p>
    <p class="text-body extra">
      <span>I
        – cumprimento de obrigação legal ou regulatória
        pelo controlador;</span>
    </p>
    <p class="text-body extra">
      <span>II
        – estudo por órgão de pesquisa, garantida, sempre
        que possível, a anonimização dos dados pessoais;</span>
    </p>
    <p class="text-body extra">
      <span>III
        – transferência a terceiro, desde que respeitados os
        requisitos de tratamento de dados dispostos nesta Lei; ou</span>
    </p>
    <p class="text-body extra">
      <span>IV
        – uso exclusivo do controlador, vedado seu acesso por terceiro,
        e desde que anonimizados os dados.</span>
    </p>
    <p class="text-body extra">
      <span>Isto
        é, informações pessoais sobre você que
        sejam imprescindíveis para o cumprimento de determinações
        legais, judiciais e administrativas e/ou para o exercício do
        direito de defesa em processos judiciais e administrativos serão
        mantidas, a despeito da exclusão dos demais dados. </span>
    </p>
    <p class="text-body extra">
      <span>O
        armazenamento de dados coletados pela KriCon reflete o nosso
        compromisso com a segurança e privacidade dos seus dados.
        Empregamos medidas e soluções técnicas de
        proteção aptas a garantir a confidencialidade,
        integridade e inviolabilidade dos seus dados. Além disso,
        também contamos com medidas de segurança apropriadas
        aos riscos e com controle de acesso às informações
        armazenadas.</span>
    </p>
    <p class="text-body extra">
      <span>6.
        O que fazemos para manter seus dados seguros?</span>
    </p>
    <p class="text-body extra">
      <span>Para
        mantermos suas informações pessoais seguras, usamos
        ferramentas físicas, eletrônicas e gerenciais orientadas
        para a proteção da sua privacidade.</span>
    </p>
    <p class="text-body extra">
      <span>Aplicamos
        essas ferramentas levando em consideração a natureza
        dos dados pessoais coletados, o contexto e a finalidade do tratamento
        e os riscos que eventuais violações gerariam para os
        direitos e liberdades do titular dos dados coletados e tratados.</span>
    </p>
    <p class="text-body extra">
      <span>Apenas
        pessoas autorizadas têm acesso a seus dados pessoais</span>
    </p>
    <p class="text-body extra">
      <span>O
        acesso a seus dados pessoais é feito somente após o
        compromisso de confidencialidade</span>
    </p>
    <p class="text-body extra">
      <span>Seus
        dados pessoais são armazenados em ambiente seguro e idôneo.</span>
    </p>
    <p class="text-body extra">
      <span>A
      </span>KRISTAL EMPREENDIMENTOS E ASSESSORIA LTDA<span>
        se compromete a adotar as melhores posturas para evitar incidentes de
        segurança. Contudo, é necessário destacar que
        nenhuma página virtual é inteiramente segura e livre de
        riscos. É possível que, apesar de todos os nossos
        protocolos de segurança, problemas de culpa exclusivamente de
        terceiros ocorram, como ataques cibernéticos de hackers, ou
        também em decorrência da negligência ou
        imprudência do próprio usuário/cliente.</span>
    </p>
    <p class="text-body extra">
      <span>Em
        caso de incidentes de segurança que possa gerar risco ou dano
        relevante para você ou qualquer um de nossos usuários/clientes,
        comunicaremos aos afetados e a Autoridade Nacional de Proteção
        de Dados sobre o ocorrido, em consonância com as disposições
        da Lei Geral de Proteção de Dados.</span>
    </p>
    <br />
    <br />
    <br />
    <br />
    <p class="text-body extra">
      <span>7.
        Com quem seus dados podem ser compartilhados?</span>
    </p>
    <p class="text-body extra">
      <span>Tendo
        em vista a preservação de sua privacidade, </span>KRISTAL
      EMPREENDIMENTOS E ASSESSORIA LTDA<span>
        não compartilhará seus dados pessoais com nenhum
        terceiro não autorizado. </span>
    </p>
    <p class="text-body extra">
      <span>Além
        disso, também existem outras hipóteses em que seus
        dados poderão ser compartilhados, que são:</span>
    </p>
    <p class="text-body extra">
      <span>I
        – Determinação legal, requerimento, requisição
        ou ordem judicial, com autoridades judiciais, administrativas ou
        governamentais competentes.</span>
    </p>
    <p class="text-body extra">
      <span>II
        – Caso de movimentações societárias, como
        fusão, aquisição e incorporação,
        de forma automática</span>
    </p>
    <p class="text-body extra">
      <span>III
        – Proteção dos direitos da KriCon em qualquer
        tipo de conflito, inclusive os de teor judicial.</span>
    </p>
    <p class="text-body extra">
      <span>8.
        Alteração desta Política de Privacidade</span>
    </p>
    <p class="text-body extra">
      <span>A
        atual versão da Política de Privacidade foi formulada e
        atualizada pela última vez em: 01 de Junho de 2024.</span>
    </p>
    <p class="text-body extra">
      <span>Reservamos
        o direito de modificar essa Política de Privacidade a qualquer
        tempo, principalmente em função da adequação
        a eventuais alterações feitas em nosso site ou em
        âmbito legislativo. Recomendamos que você a revise com
        frequência.</span>
    </p>
    <p class="text-body extra">
      <span>Eventuais
        alterações entrarão em vigor a partir de sua
        publicação em nosso site e sempre lhe notificaremos
        acerca das mudanças ocorridas.</span>
    </p>
    <p class="text-body extra">
      <span>Ao
        utilizar nossos serviços e fornecer seus dados pessoais após
        tais modificações, você as consente. </span>
    </p>
    <p class="text-body extra">
      <span>9.
        Responsabilidade</span>
    </p>
    <p class="text-body extra">
      <span>A
      </span>KRISTAL EMPREENDIMENTOS E ASSESSORIA LTDA<span>
        prevê a responsabilidade dos agentes que atuam nos processos de
        tratamento de dados, em conformidade com os artigos 42 ao 45 da Lei
        Geral de Proteção de Dados.</span>
    </p>
    <p class="text-body extra">
      <span>Nos
        comprometemos em manter esta Política de Privacidade
        atualizada, observando suas disposições e zelando por
        seu cumprimento.</span>
    </p>
    <p class="text-body extra">
      <span>Além
        disso, também assumimos o compromisso de buscar condições
        técnicas e organizativas seguramente aptas a proteger todo o
        processo de tratamento de dados.</span>
    </p>
    <p class="text-body extra">
      <span>Caso
        a Autoridade Nacional de Proteção de Dados exija a
        adoção de providências em relação
        ao tratamento de dados realizado pela KriCon, comprometemo-nos a
        segui-las. </span>
    </p>
    <p class="text-body extra">
      <span>10.
        Isenção de responsabilidade</span>
    </p>
    <p class="text-body extra">
      <span>Conforme
        mencionado no Tópico 6, embora adotemos elevados padrões
        de segurança a fim de evitar incidentes, não há
        nenhuma página virtual inteiramente livre de riscos. Nesse
        sentido, a </span>KRISTAL EMPREENDIMENTOS E ASSESSORIA LTDA<span>
        não se responsabiliza por:</span>
    </p>
    <p class="text-body extra">
      <span>I
        – Quaisquer consequências decorrentes da negligência,
        imprudência ou imperícia dos usuários em relação
        a seus dados individuais. Garantimos e nos responsabilizamos apenas
        pela segurança dos processos de tratamento de dados e do
        cumprimento das finalidades descritas no presente instrumento.</span>
    </p>
    <p class="text-body extra">
      <span>Destacamos
        que a responsabilidade em relação à
        confidencialidade dos dados de acesso é do usuário.</span>
    </p>
    <p class="text-body extra">
      <span>II
        – Ações maliciosas de terceiros, como ataques de
        hackers, exceto se comprovada conduta culposa ou deliberada da
        KriCon.</span>
    </p>
    <p class="text-body extra">
      <span>Destacamos
        que em caso de incidentes de segurança que possam gerar risco
        ou dano relevante para você ou qualquer um de nossos
        usuários/clientes, comunicaremos aos afetados e a Autoridade
        Nacional de Proteção de Dados sobre o ocorrido e
        cumpriremos as providências necessárias.</span>
    </p>
    <p class="text-body extra">
      <span>III
        – Inveracidade das informações inseridas pelo
        usuário/cliente nos registros necessários para a
        utilização dos serviços da KriCon; quaisquer
        consequências decorrentes de informações falsas
        ou inseridas de má-fé são de inteiramente
        responsabilidade do usuário/cliente.</span>
    </p>
    <p class="text-body extra">
      <span>11.
        Encarregado de Proteção de Dados</span>
    </p>
    <p class="text-body extra">
      <span>A
        KriCon disponibiliza os seguintes meios para que você possa
        entrar em contato conosco para exercer seus direitos de titular: </span>
    </p>
    <p class="text-body extra">
      <span>contato@kricon.com.br</span>
    </p>

    <v-form v-if="!previous">
      <v-row v-if="loading" class="justify-center align-center">
        <v-progress-circular :size="35" color="primary" indeterminate />
      </v-row>
      <v-row class="form-controls">
        <v-col>
          <v-checkbox v-model="agree" label="Concordo e aceito os termos especificados acima." />
        </v-col>
        <v-col class="text-center align-content-center">
          <v-btn class="d-print-block" @click="acceptTerms" :disabled="!agree">Aceitar</v-btn>
        </v-col>
        <v-col class="text-center align-content-center">
          <v-btn class="d-print-block" @click="getOut">Cancelar</v-btn>
        </v-col>
      </v-row>
    </v-form>

    <p v-if="previous">
      <a href="#" @click="getOut">Voltar</a>
    </p>
  </v-container>
</template>

<style scoped>
@media screen and (max-width: 750px) {
    .form-controls {
        flex-direction: column;
    }
}
</style>

<style>
#app, #app > div, #app > div > div {
    background-color: white;
}
@page {
    size         	: 21cm 29.7cm;
    margin-left  	: 3cm;
    margin-right 	: 3cm;
    margin-top   	: 2.5cm;
    margin-bottom	: 2.5cm
}
p {
    line-height  	: 115%;
    text-align   	: left;
    orphans      	: 2;
    widows       	: 2;
    margin-bottom	: 0.25cm;
    direction    	: ltr;
    background   	: transparent
}
p.text-body {
    text-align		: justify;
    line-height		: 0.61cm;
    margin-bottom	: 0.42cm;
    background		: #ffffff;
    color			: #2b2b2b;
    font-size		: 8pt;
}
p.inset {
    margin-left		: 0.64cm;
}
p.header {
    font-size		: 12pt;
}
p.emph   {
    color: #ff0000;
}
p.adjust {
    margin-left: 1.25cm;
    text-indent: -0.61cm;
}
p.extra {
    font-size: 10pt;
}
a:link {
    color          	: #0000ff;
    text-decoration	: underline
}
</style>

<script type="js">
import ApiService from '../../../services/ApiService';

export default {
    data            :  () => ({
        loading     : false,
        agree       : false,
        previous    : false,
        client      : new ApiService()
    }),
    methods         :  {
        getOut      () {
            this.client.logoff()
                .then((r) => {
                    if (r.success) {
                        sessionStorage.removeItem('user');
                        sessionStorage.removeItem('token');
                        sessionStorage.removeItem('expires');
                        sessionStorage.removeItem("profileType");
                        sessionStorage.removeItem("preUserRegister");
                        sessionStorage.removeItem("planType");
                        sessionStorage.removeItem("tokenUserSelected");
                    }
                    this.$router.push('/auth');
                });
        },
        async acceptTerms () {
            const resp = await this.client.acceptTerms();
            if (resp.statusCode !== 200) {
                this.$toast.error("Falha ao atualizar registro.");
                return;
            }
            this.$toast.success(resp.message || 'Registro efetuado, redirecionando...');
            setTimeout(() => this.$router.push('/gestao-usuario'), 1500);
        }
    },
    mounted         () {
        const   user    = this.$store.getters.$currentUser;
        this.previous   = !user || user.acceptTerms || false;
    }
}
</script>