<template>
  <div class="container">
    <div class="date-piker">
      <v-text-field
        rounded
        dense
        outlined
        type="number"
        v-model="data"
        placeholder="0,00"
        @input="save"
        @change="change"
        :disabled="disabled"
        :rules="[(v) => !!v || 'Campo obrigatório']"
      ></v-text-field>
    </div>
  </div>
</template>

<script>
export default {
  name: "ValorMonetario",

  props: {
    value: { type: Number },
    disabled: { type: Boolean },
  },

  created() {
    if (this.value) {
      this.data = this.value;
    }
  },

  data() {
    return {
      data: null,
    };
  },

  methods: {
    change(){
      this.$emit("change", this.data);
    }
    ,
    save() {
      this.$emit("save", this.data);
    },
  },

  watch: {
    value() {
      this.data = this.value;
      this.$emit("save", this.data);
    },
  },
};
</script>

<style scoped>
.menu {
  border-radius: 50px;
}
.date-piker input {
  width: 100%;
  border: 1px solid #e6e6e6;
  color: #a4a4a4;
  border-radius: 40px;
  padding: 0.5rem 0.5rem 0.5rem 2.5rem;
  outline: none;
  position: relative;
}
/* .date-piker input::-webkit-calendar-picker-indicator {
  color: #a4a4a4 !important;
  background: url("../../../../../assets/icons/calendar_icon.svg") no-repeat;
  position: absolute;
  left: -10px;
} */
</style>